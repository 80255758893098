import React from 'react'
import PropTypes from 'prop-types'
import AdsEditDialog from './AdsEditDialog'
import AdsCreateDialog from './AdsCreateDialog'
import AdsCopyDialog from './AdsCopyDialog'

function AdsDialogs(props) {
  const { setting } = props
  const {
    editOpen,
    createOpen,
    copyOpen,
    datas,
    checkedList,
    provider,
    activetab,
    preview,
    deliveryEstimate,
    selectSet,
    handleSetChange,
    handleEditClose,
    handleCreateClose,
    handleCopyClose,
  } = setting

  return (
    <>
      {checkedList.length === 1 && (
        <AdsEditDialog
          show={editOpen}
          setting={{
            size: 'xl',
            active: datas[activetab].find((i) => i.id === checkedList[0]),
            provider,
            activetab,
            preview,
            deliveryEstimate,
            handleClose: handleEditClose,
            selectSet,
            handleSetChange,
          }}
        />
      )}
      {datas && (
        <AdsCreateDialog
          show={createOpen}
          setting={{
            datas,
            selectSet,
            handleSetChange,
            handleCreateClose,
            setCreativePreview: () => {},
            getCreativePreview: () => {},
            getFetchedData: () => [],
          }}
        />
      )}
      {datas && (
        <AdsCopyDialog
          show={copyOpen}
          setting={{
            datas,
            checkedList,
            selectSet,
            activetab,
            handleSetChange,
            handleCopyClose,
          }}
        />
      )}
    </>
  )
}

AdsDialogs.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AdsDialogs
