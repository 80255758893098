import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useScreenshot } from 'use-react-screenshot'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faTimesCircle,
  faCheckCircle,
  faEdit,
  faLink,
  // faArrowUpRightFromSquare,
  faLinkSlash,
  faCopy,
  faPrint,
} from '@fortawesome/free-solid-svg-icons'
import { Card } from 'react-bootstrap'
import { SocketContext, ToastContext } from '../components/ContextProvider'
import {
  InfoCard,
  ConfirmForm,
  LoadingButton,
  SelectBar,
  VirtualAccount,
} from '../components'
import apiService from '../services/apiService'
import { platforms, getCookie, getFixedForm } from '../services/lucaFunctions'

function ApplyForm(props) {
  const { setting } = props
  const { data, nohover = true } = setting

  const forms = {
    個人: [
      '姓名',
      '身分證字號',
      '戶籍地址',
      '聯絡地址',
      '交易憑證',
      '聯絡電話',
      '聯絡信箱',
    ],
    公司行號: [
      '公司全名',
      '統一編號',
      '身分別',
      '公司登記地址',
      '公司聯絡地址',
      '聯絡姓名',
      '聯絡電話',
      '聯絡信箱',
      '交易憑證',
    ],
    代理商: [
      '公司全名',
      '統一編號',
      '身分別',
      '公司登記地址',
      '公司聯絡地址',
      '聯絡姓名',
      '聯絡電話',
      '聯絡信箱',
      '交易憑證',
    ],
  }

  return (
    <Card
      className={`infoCards-content ${
        !nohover ? 'infoCards-hover-content' : ''
      } h-100 p-3`}
      // title={tip}
      // onClick={handleEdit}
    >
      {/* {handleEdit && <FontAwesomeIcon icon={faEdit} title="編 輯" />} */}
      <Card.Body className="h-100">
        <Card.Title>客戶資料</Card.Title>
        <Card.Text className="text-center">Custom Info</Card.Text>
        {(forms[data['身份別']] || []).map((key) => (
          <Card.Text className="lh-lg">
            {key}： {data[key]}
          </Card.Text>
        ))}
      </Card.Body>
    </Card>
  )
}

function PrintModal(props) {
  const { setting } = props
  const { show, size, handleClose, name, business_id, bankCode } = setting
  const { settoast } = useContext(ToastContext)
  const ref = useRef(null)

  const [printing, setprinting] = useState(false)
  const [image, takeScreenshot] = useScreenshot()
  const print = () => {
    const link = document.createElement('a')
    link.setAttribute('href', image)
    link.setAttribute('download', 'image.png')
    document.body.appendChild(link)

    link.click()
    link.remove()
    setprinting(false)
  }
  useEffect(() => {
    if (!image) return
    print()
  }, [image])
  useEffect(() => {
    if (printing) {
      if (!image) takeScreenshot(ref.current)
      else print()
    }
  }, [printing])

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      size={size}
      onHide={handleClose}
    >
      <Modal.Header closeButton style={{ height: '3rem' }} />
      <Modal.Body className="px-5 pt-3 pb-5" ref={ref}>
        <Row className="lh-lg pb-3">
          <Col xs="4" />
          <Col xs="4" className="text-start text-lucaDark h5 px-0">
            匯入匯款指示書
          </Col>
          <Col
            xs="4"
            className={`text-end h5 d-flex ${printing ? 'd-none' : ''}`}
          >
            <FontAwesomeIcon
              className="ms-auto align-self-center h5 px-2 text-lucaLink4"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                navigator.clipboard.writeText('28012922835116')
                settoast({ show: true, text: '已 複 製' })
              }}
              icon={faCopy}
              title="複 製 虛 擬 帳 號"
            />
            <FontAwesomeIcon
              className="mx-2 align-self-center h5 text-lucaLink4"
              style={{
                cursor: 'pointer',
              }}
              icon={faPrint}
              title="列 印"
              // eslint-disable-next-line no-restricted-globals
              onClick={() => {
                settoast({ show: true, text: '截 圖 中' })
                setprinting(true)
              }}
            />
          </Col>
        </Row>
        <div style={{ borderRadius: '0.5rem', border: '1px solid #ddd' }}>
          <Table striped bordered className="mb-0 text-lucaDark">
            <tbody>
              <tr>
                <td>銀行名詞</td>
                <td>富邦銀行</td>
              </tr>
              <tr>
                <td>銀行代碼</td>
                <td>012</td>
              </tr>
              <tr>
                <td>分行名稱</td>
                <td>安和分行</td>
              </tr>
              <tr>
                <td>分行代碼</td>
                <td>712</td>
              </tr>
              <tr>
                <td>戶名</td>
                <td>潮網科技股份有限公司</td>
              </tr>
              <tr>
                <td>帳號</td>
                <td>
                  <VirtualAccount
                    setting={{
                      business_id,
                      bankCode,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>幣別</td>
                <td>新台幣</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="fs-6 text-lucaDark pt-3 text-center">
          ※ 此帳號為&ensp;
          <span
            className="text-luca"
            style={{ borderBottom: '1px dashed #317985' }}
          >
            {name}
          </span>
          &ensp;專屬
        </div>
      </Modal.Body>
    </Modal>
  )
}

function OAuthDialog(props) {
  const { setting } = props
  const { show, size, providers, getProviders } = setting
  const [platform, setplatform] = useState('')
  const [status, setstatus] = useState('selecting')

  const handleClose = () => {
    setplatform('')
    setstatus('selecting')
    setting.handleClose()
  }
  const socket = useContext(SocketContext)
  useEffect(() => {
    if (socket !== null) {
      socket.on('OAuth', (message) => {
        setstatus(message.status ? '綁定成功' : '綁定失敗')
        getProviders()
      })
    }
  }, [socket])

  const handleDebind = async () => {
    const res = await apiService.data({
      path: `auth/${platform.toLowerCase()}`,
      method: 'delete',
    })
    setstatus(res.data.status ? '解除綁定成功' : '解除綁定失敗')
    getProviders()
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header
        className="AccFormModal justify-content-start text-start pt-4"
        closeButton
      >
        <Modal.Title>
          <h5>綁定媒體平台</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex py-5">
        {status === 'selecting' && (
          <div className="h-100 w-100 d-flex flex-column pb-5">
            <FontAwesomeIcon
              className="mx-auto mt-auto mb-3 text-luca align-self-center py-4"
              style={{
                fontSize: '4.5rem',
              }}
              icon={faLink}
            />
            <div className="w-100 d-flex mx-auto px-4">
              <Form.Group className="w-100 px-5 lh-md">
                <Form.Label className="mb-0 d-flex">已綁定媒體平台</Form.Label>
                <Form.Control
                  value={
                    providers && providers.length > 0
                      ? providers.join(', ')
                      : '未綁定'
                  }
                  readOnly
                />
              </Form.Group>
            </div>
            <div className="w-100 d-flex mx-auto px-4">
              <Form.Group className="w-100 px-5 lh-md">
                <Form.Label className="mt-3 mb-0 d-flex">
                  選擇欲綁定的媒體平台
                </Form.Label>
              </Form.Group>
            </div>
            <div className="d-flex">
              <div className="w-50 ms-auto">
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    method: (e) => {
                      setplatform(e.target.value)
                    },
                    placeholder: '選擇媒體平台類型',
                    content: platforms,
                  }}
                />
              </div>
              <Button
                variant="luca"
                className="me-auto"
                as="a"
                target="_blank"
                href={`/api/auth/${platform.toLowerCase()}?token=${getCookie(
                  'token'
                )}`}
              >
                前往綁定
              </Button>
            </div>
          </div>
        )}
        {status === '解除綁定' && (
          <div className="h-100 w-100 d-flex flex-column pb-5">
            <FontAwesomeIcon
              className="mx-auto mt-auto mb-3 text-grey align-self-center py-4"
              style={{
                fontSize: '4.5rem',
              }}
              icon={faLinkSlash}
            />
            <div className="w-100 d-flex mx-auto px-4">
              <Form.Group className="w-100 px-5 lh-md">
                <Form.Label className="mb-0 d-flex">已綁定媒體平台</Form.Label>
                <Form.Control
                  value={
                    providers && providers.length > 0
                      ? providers.join(', ')
                      : '未綁定'
                  }
                  readOnly
                />
              </Form.Group>
            </div>
            <div className="w-100 d-flex mx-auto px-4">
              <Form.Group className="w-100 px-5 lh-md">
                <Form.Label className="mt-3 mb-0 d-flex">
                  選擇欲解除綁定的媒體平台
                </Form.Label>
              </Form.Group>
            </div>
            <div className="d-flex">
              <div className="w-50 ms-auto">
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    method: (e) => {
                      setplatform(e.target.value)
                    },
                    placeholder: '選擇媒體平台類型',
                    content: platforms,
                  }}
                />
              </div>
              <Button variant="luca" className="me-auto" onClick={handleDebind}>
                解除綁定
              </Button>
            </div>
          </div>
        )}
        {(status === '綁定成功' || status === '解除綁定成功') && (
          <div className="h-100 w-100 d-flex flex-column">
            <FontAwesomeIcon
              className="mx-auto mt-auto mb-3 text-luca align-self-center py-3"
              style={{
                fontSize: '4.5rem',
              }}
              icon={faCheckCircle}
            />
            <h3 className="mx-auto mb-auto text-luca pb-4">{status}</h3>
          </div>
        )}
        {(status === '綁定失敗' || status === '解除綁定失敗') && (
          <div className="h-100 w-100 d-flex flex-column">
            <FontAwesomeIcon
              className="mx-auto mt-auto mb-3 text-luca align-self-center py-3"
              style={{
                fontSize: '4.5rem',
              }}
              icon={faTimesCircle}
            />
            <h3 className="mx-auto mb-auto text-luca pb-4">{status}</h3>
          </div>
        )}
      </Modal.Body>
      {status === 'selecting' && (
        <Modal.Footer className="d-flex sendForm justify-content-center py-4">
          <span
            className="mx-auto"
            aria-hidden
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setstatus('解除綁定')}
          >
            前往解除綁定
          </span>
        </Modal.Footer>
      )}
      {status === '解除綁定' && (
        <Modal.Footer className="d-flex sendForm justify-content-center py-4">
          <span
            className="mx-auto"
            aria-hidden
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setstatus('selecting')}
          >
            前往綁定
          </span>
        </Modal.Footer>
      )}
      {status !== 'selecting' && status !== '解除綁定' && (
        <Modal.Footer className="d-flex sendForm justify-content-center py-4">
          <Button variant="luca" className="mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

function InfoEditDialog(props) {
  const { setting } = props
  const {
    size,
    show,
    form,
    formData,
    handleClose,
    handleDataChange,
    btnText,
    action,
    handleActionChange,
    lastCheck,
    // providers,
  } = setting

  const { title, titleEng, content } = form
  const names = {
    currentPass: '舊密碼',
    password: '新密碼',
    confirmPass: '確認密碼',
    name: '姓名',
    englishName: '英文姓名',
    phone: '電話',
    email: '帳號',
    depart: '所屬單位',
  }
  const constrainsts = {
    currentPass: {
      value: !lastCheck.verified,
      message:
        lastCheck.status === 'waiting'
          ? '驗證中...'
          : '請確認所輸入的舊密碼是否正確',
    },
    password: {
      value: formData.content.password !== formData.content.confirmPass,
      message: '請確認二次輸入密碼相同',
    },
    confirmPass: {
      value: formData.content.password !== formData.content.confirmPass,
      message: '請確認二次輸入密碼相同',
    },
  }
  const pivot =
    action === 'data' ? Math.ceil(content.length / 2) : content.length

  return (
    <Modal
      className="text-lucaDark"
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        {title && (
          <Modal.Title>
            <h4>{title}</h4>
            <p>{titleEng}</p>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <div className="d-flex mx-auto">
          <Form className="py-3">
            {content.slice(0, pivot).map((c) => (
              <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                <Form.Label className="my-2">{names[c.name]}</Form.Label>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    constrainsts[c.name] && constrainsts[c.name].value ? (
                      <Tooltip
                        className="userTip"
                        style={{
                          zIndex: '9999',
                        }}
                      >
                        {constrainsts[c.name].message}
                      </Tooltip>
                    ) : (
                      <Tooltip
                        className="userTip"
                        style={{
                          zIndex: '9999',
                          display: 'none',
                        }}
                      />
                    )
                  }
                >
                  <Form.Control
                    name={c.name}
                    type={c.type}
                    value={formData.content[c.name]}
                    placeholder={c.placeholder}
                    onChange={handleDataChange}
                    isInvalid={
                      constrainsts[c.name] && constrainsts[c.name].value
                    }
                  />
                </OverlayTrigger>
              </Form.Group>
            ))}
          </Form>
          <Form className="py-3">
            {content.slice(pivot, content.length).map((c) => {
              // const error = fvError.find((e) => e.key === c.name)
              const error = {}
              return (
                <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                  <Form.Label className="my-2">{names[c.name]}</Form.Label>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      error && error.error ? (
                        <Tooltip
                          className="userTip"
                          style={{
                            zIndex: '9999',
                          }}
                        >
                          {error && error.error}
                        </Tooltip>
                      ) : (
                        <Tooltip
                          className="userTip"
                          style={{
                            zIndex: '9999',
                            display: 'none',
                          }}
                        />
                      )
                    }
                  >
                    <Form.Control
                      name={c.name}
                      type={c.type}
                      value={formData.content[c.name]}
                      placeholder={c.placeholder}
                      onChange={handleDataChange}
                      isInvalid={error && error.error}
                      disabled={['email', 'depart'].includes(c.name)}
                    />
                  </OverlayTrigger>
                </Form.Group>
              )
            })}
            {action === 'data' && (
              <Form.Group className="mb-1 px-5 lh-md">
                <Form.Label className="my-2">密碼</Form.Label>
                <div className="d-flex">
                  <h5>******-******-******</h5>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="btn-lucaIcon fs-5 ms-auto"
                    variant="luca"
                    onClick={() => handleActionChange('password')}
                    title="修 改 密 碼"
                  />
                </div>
              </Form.Group>
            )}
          </Form>
        </div>
        {/* <hr className="w-75 hrClass-dotted my-3 mx-auto" />
        <div className="w-75 d-flex mx-auto">
          <Form.Group className="w-100 mb-1 px-4 lh-md">
            <Form.Label className="my-2 d-flex">
              <span className="mt-auto">已綁定媒體平台</span>
              <Button
                variant="luca"
                size="sm"
                className="ms-auto"
                onClick={() => handleActionChange('OAuth')}
              >
                綁 定&ensp;
                <FontAwesomeIcon
                  className="m-auto"
                  icon={faArrowUpRightFromSquare}
                />
              </Button>
            </Form.Label>
            <Form.Control
              value={
                providers && providers.length > 0
                  ? providers.join(', ')
                  : '未綁定'
              }
              readOnly
            />
          </Form.Group>
        </div> */}
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-4">
        <Button variant="secondary" onClick={() => handleClose()}>
          取 消
        </Button>
        <LoadingButton
          variant="luca"
          onClick={() => handleClose(true)}
          disabled={
            action !== 'data' &&
            Object.keys(constrainsts).some((key) => constrainsts[key].value)
          }
          btnText={btnText || '送 出'}
        />
      </Modal.Footer>
    </Modal>
  )
}

function Info(props) {
  const { auth, checkToken, handleLogOut } = props
  const location = useLocation()

  const [show, setshow] = useState(false)
  const [OAuthShow, setOAuthShow] = useState(false)
  const [action, setaction] = useState('data')
  const [form, setform] = useState({ content: [] })
  const [formData, setformData] = useState({ content: { changed: true } })
  const handleDataChange = async (event) => {
    setformData({
      content: {
        ...formData.content,
        [event.target.name]: event.target.value,
      },
    })
  }

  // check providers
  const [providers, setproviders] = useState(null)
  const getProviders = async () => {
    const res = await apiService.data({
      path: `luca/insight/list/user/check/${auth.auth.user_id}`,
      method: 'get',
    })
    setproviders(res)
  }
  useEffect(() => {
    getProviders()
  }, [])

  // check current pass
  const [lastCheck, setlastCheck] = useState({
    checking: '',
    status: 'done',
    verified: false,
  })
  useEffect(() => {
    const checkpass = async (pass) => {
      const res = await apiService.data({
        path: `luca/user/check/${auth.auth.user_id}`,
        method: 'post',
        data: { pass },
      })
      setlastCheck({
        checking: pass,
        status: 'done',
        verified: res[pass],
      })
    }
    if (formData.content.currentPass && formData.content.currentPass !== '') {
      if (formData.content.currentPass !== lastCheck.checking) {
        if (lastCheck.status === 'done') {
          checkpass(formData.content.currentPass)
        }
        setlastCheck({
          checking: formData.content.currentPass,
          status: 'waiting',
          verified: false,
        })
      }
    }
  }, [formData.content.currentPass, lastCheck])

  const [user, setuser] = useState({
    title: '使 用 者 資 料',
    titleEng: 'User information',
    form: {},
  })
  const handleEdit = (keys) => {
    const tempForm = keys
    const content = { ...formData.content }
    tempForm.forEach((c) => {
      content[c.name] = c.value || ''
    })
    setformData({ content })
    setform({
      pageLimit: 1,
      content: tempForm,
    })
    setshow(true)
  }
  const [BM, setBM] = useState({
    title: '企 業 資 料',
    titleEng: 'Business information',
    form: {},
  })

  useEffect(() => {
    if (!auth.auth.user_id) return
    const getUser = async () => {
      const res = await apiService.data({
        path: `luca/user/${auth.auth.user_id}`,
        method: 'get',
      })
      setuser({
        ...user,
        origin: res,
        form: {
          name: res.name,
          password: '********',
          email: res.email,
          ...res.setting,
        },
      })
    }
    const getBM = async () => {
      const res = await apiService.data({
        path: `luca/business/${auth.account.business_id}`,
        method: 'get',
      })
      setBM({
        ...BM,
        form: {
          ...res,
          ...res.setting,
          bankCode: '012',
          bankAccount: true,
        },
      })
    }
    getUser()
    if (auth.account.business_id) getBM()
  }, [auth.auth, auth.account])

  console.log(BM)
  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
    hasFooter: true,
    warn: (
      <span className="text-center text-lucaDark">
        <div className="d-flex mb-3">
          <FontAwesomeIcon
            className="mx-auto display-1 mb-2 text-luca"
            icon={faExclamationTriangle}
          />
        </div>
        <h5>確定要修改{action === 'data' ? '資料' : '密碼'}嗎？</h5>
      </span>
    ),
  })
  const handleShowWarn = (value) => {
    setshowWarn({
      ...showWarn,
      ...value,
      state: 'confirm',
      show: true,
      shouldReturn: true,
    })
  }

  // timer
  const [timer, settimer] = useState(0)
  useEffect(() => {
    const count = (value) =>
      setTimeout(() => {
        settimer(value)
      }, 100)
    if (timer > 0) count(timer - 0.1)
    if (timer < 0) handleLogOut()
  }, [timer])

  const handleWarnClose = async (value) => {
    if (value) {
      const data = { setting: user.origin.setting, password: {} }
      Object.keys(formData.content).forEach((key) => {
        switch (key) {
          case 'name':
            data.name = formData.content.name
            data.setting[key] = formData.content[key]
            break
          case 'email':
            data.email = formData.content.email
            break
          case 'password':
          case 'currentPass':
          case 'confirmPass':
            data.password[key] = formData.content[key]
            break
          default:
            data.setting[key] = formData.content[key]
        }
      })
      const res = await apiService.data({
        path: `luca/user/${auth.auth.user_id}`,
        method: 'put',
        data: { ...data, setting: JSON.stringify(data.setting) },
      })
      setuser({
        ...user,
        origin: res,
        form: {
          name: res.name,
          password: '********',
          email: res.email,
          ...res.setting,
        },
      })
      const needReset = action === 'password' || res.email !== user.form.email
      if (needReset) {
        settimer(3)
      } else {
        checkToken()
      }
      setshowWarn({
        ...showWarn,
        state: 'done',
        hasFooter: !needReset,
        warn: (
          <span className="text-center">
            <div className="d-flex mb-3">
              <FontAwesomeIcon
                className="mx-auto display-1 text-luca"
                icon={faCheckCircle}
              />
            </div>
            <h5>修改完成{needReset && '，請重新登入'}！</h5>
          </span>
        ),
        footer: !needReset && (
          <LoadingButton
            variant="lucaLight"
            onClick={() => handleWarnClose(false)}
            btnText="確 定"
          />
        ),
      })
    } else {
      setshowWarn({
        ...showWarn,
        show: false,
      })
    }
  }
  const handleClose = (value) => {
    const actions = {
      data: '確定要修改資料嗎？',
      password: '確定要修改密碼嗎？',
      OAuth: '確定要進行綁定嗎',
    }
    setshow(false)
    setOAuthShow(false)
    if (value)
      handleShowWarn({
        hasFooter: true,
        warn: (
          <span className="text-center text-lucaDark">
            <div className="d-flex mb-3">
              <FontAwesomeIcon
                className="mx-auto display-1 mb-2 text-luca"
                icon={faExclamationTriangle}
              />
            </div>
            <h5>{actions[action]}</h5>
          </span>
        ),
      })
  }
  const handleActionChange = (value) => {
    if (value === 'password') {
      const keys = ['currentPass', 'password', 'confirmPass']
      handleEdit(
        keys.map((key) => ({
          name: key,
          type: 'password',
          value: user.form[key],
        }))
      )
      setaction(value)
    } else if (value === 'OAuth') {
      setshow(false)
      setOAuthShow(true)
      setaction(value)
    } else {
      const keys = ['name', 'englishName', 'phone', 'email', 'depart']
      handleEdit(
        keys.map((key) => ({
          name: key,
          type: 'text',
          value: user.form[key],
        }))
      )
      setaction(value)
    }
  }

  useEffect(() => {
    if (location.search === '?action=edit') {
      location.search = ''
      handleActionChange('OAuth')
    }
  })

  const actionType = {
    data: {
      title: '編 輯 資 料',
      titleEng: 'Edit Profile',
    },
    password: {
      title: '編 輯 資 料',
      titleEng: 'Edit Profile',
    },
  }

  const [printShow, setprintShow] = useState(false)

  const fixed = getFixedForm(auth)
  console.log(fixed)

  return (
    <Container fluid className="card p-4">
      <Row className="p-4 pageTitle">
        <h4>會 員 資 料</h4>
        <p>The area of member</p>
        <p className="mt-2 mb-0 pageSubtitle">
          請 點 擊 右 上 角 編 輯 您 的 會 員 或 企 業 資 料
        </p>
      </Row>
      <Row className="p-4 infoCards">
        <div
          className="h-100"
          style={{
            cursor: handleEdit ? 'pointer' : 'not-allowed',
          }}
        >
          <ApplyForm
            setting={{
              data: user.form,
            }}
          />
        </div>
        {/* <InfoCard
          setting={{ ...BM, tip: '', showPrint: () => setprintShow(true) }}
        /> */}
        <InfoCard
          setting={{
            ...user,
            providers,
            tip: '編 輯 個 人 資 料',
            handleEdit: () => handleActionChange('data'),
          }}
        />
      </Row>
      <InfoEditDialog
        setting={{
          size: 'lg',
          show,
          form: {
            ...actionType[action],
            size: 'xl',
            pageLimit: form.pageLimit,
            content: form.content,
          },
          providers,
          formData,
          handleClose,
          handleDataChange,
          btnText: '確 定',
          action,
          handleActionChange,
          lastCheck,
        }}
      />
      <OAuthDialog
        setting={{
          size: 'md',
          show: OAuthShow,
          handleClose,
          providers,
          getProviders,
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          title: '確 定 修 改',
          titleEng: 'Profile Edit Confirm',
          warning: showWarn.warn,
          handleClose: handleWarnClose,
          timer,
        }}
      />
      <PrintModal
        setting={{
          show: printShow,
          size: 'md',
          name: auth.account.name,
          business_id: auth.account.business_id,
          bankCode: '012',
          handleClose: () => setprintShow(false),
        }}
      />
    </Container>
  )
}

Info.propTypes = {
  auth: PropTypes.shape().isRequired,
  checkToken: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
}

InfoEditDialog.propTypes = {
  setting: PropTypes.shape().isRequired,
}

OAuthDialog.propTypes = {
  setting: PropTypes.shape().isRequired,
}

PrintModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ApplyForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Info
