import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import ManagedList from './ManagedList'

function PermissionDialog(props) {
  const { setting } = props
  const {
    size,
    title,
    titleEng,
    itemTitle,
    assignedTitle,
    imgSrc,
    show,
    handleClose,
    managed,
    assigned,
    handleAddAccount,
    handleDeleteAccount,
    handlePermissionChange,
    permissionType,
    filterField,
  } = setting

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        {imgSrc && <Image src={imgSrc} fluid />}
        {title && (
          <Modal.Title>
            <h4>{title}</h4>
            <p>{titleEng}</p>
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body className="AccformCard">
        <ManagedList
          setting={{
            itemTitle,
            assignedTitle,
            items: managed,
            assigned,
            filterField,
            handleAdd: handleAddAccount,
            handleDelete: handleDeleteAccount,
            handlePermissionChange,
            permissionType,
          }}
        />
      </Modal.Body>

      <Modal.Footer className="sendForm justify-content-center py-4" />
    </Modal>
  )
}

PermissionDialog.propTypes = {
  setting: PropTypes.shape(),
}

PermissionDialog.defaultProps = {
  setting: {
    title: '申請廣告帳戶',
    titleEng: 'Open the advertising account',
    pageLimit: 2,
    content: [
      { name: '用戶名稱', type: 'fixed', value: 'Kevin' },
      { name: '帳戶名稱', type: 'text', value: '' },
      { name: '公司統編', type: 'text', value: '' },
      { name: '電子信箱', type: 'email', value: '' },
    ],
  },
}
export default PermissionDialog
