import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Lists from '../AdsManager/Lists'

function ReportTable(props) {
  const { setting } = props
  const {
    datas,
    associatedInfo,
    whiteHeight,
    whiteRef,
    setwhiteHeight,
    checkedList,
    handleCheck,
  } = setting
  const { campaign, adset, ad } = datas
  console.log(checkedList)

  return (
    <>
      <Row
        className="pt-2 AdsManagerForm w-100 flex-nowrap"
        style={{ height: 'auto' }}
      >
        <Col style={{ height: '60px' }}>
          <Card
            className={`py-2 ms-2 tab1 `}
            style={{ height: '60px' }}
            title="點 擊 可 切 換 廣 告 目 錄"
          >
            <Card.Title>廣 告 活 動</Card.Title>
            <p>Campaign</p>
          </Card>
        </Col>
        <Col style={{ height: '60px' }}>
          <Card
            className={`py-2 tab2 `}
            style={{ height: '60px' }}
            title="點 擊 可 切 換 廣 告 目 錄"
          >
            <Card.Title>廣 告 設 定</Card.Title>
            <p>Advertising Set</p>
          </Card>
        </Col>
        <Col style={{ height: '60px' }}>
          <Card
            className={`py-2 me-2 tab3 `}
            style={{ height: '60px' }}
            title="點 擊 可 切 換 廣 告 目 錄"
          >
            <Card.Title>廣 告 內 容</Card.Title>
            <p>Advertising</p>
          </Card>
        </Col>
      </Row>
      {!(campaign && adset && ad) ? (
        <Row style={{ height: '35vh' }}>
          <div className="m-auto d-flex justify-content-center my-5">
            <Spinner
              className="my-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        </Row>
      ) : (
        <Row
          className="pt-3 pb-2 AdsManagerForm w-100 overflow-hidden flex-grow-1"
          style={{ oveflowY: 'auto', height: '35vh' }}
        >
          <Col
            className="position-relative"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            {associatedInfo.campaign && (
              <>
                {associatedInfo.associatedCampaign.length > 0 && (
                  <div
                    className="w-95 mx-2 position-absolute"
                    style={{
                      height: `${
                        (whiteHeight.height + whiteHeight.marginBottom) *
                          associatedInfo.associatedCampaign.length -
                        whiteHeight.marginBottom
                      }px`,
                      transition: '.3s',
                      transitionTimingFunction: 'ease-in',
                      backgroundColor: 'transparent',
                      border: '3px solid #fff',
                      borderRadius: '0.25rem',
                      opacity: '0.8',
                      zIndex: '100',
                      pointerEvents: 'none',
                    }}
                  />
                )}
                <Lists
                  setting={{
                    isActive: true,
                    disableCheckBox: true,
                    datas: associatedInfo.associatedCampaign.concat(
                      associatedInfo.campaign
                    ),
                    // .filter((a) => wordSearch(a.name, search)),
                    setactiveid: (id) => handleCheck(id, 'campaign'),
                    ref: whiteRef,
                    whiteHeight,
                    setwhiteHeight,
                    checkedList: Object.keys(checkedList.campaign).filter(
                      (key) => checkedList.campaign[key]
                    ),
                    handleCheck: (id) => handleCheck(id, 'campaign'),
                  }}
                />
              </>
            )}
          </Col>
          <Col
            className="position-relative"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            {associatedInfo.adset && (
              <>
                {associatedInfo.associatedAdset.length > 0 && (
                  <div
                    className="w-95 mx-2 position-absolute"
                    style={{
                      height: `${
                        (whiteHeight.height + whiteHeight.marginBottom) *
                          associatedInfo.associatedAdset.length -
                        whiteHeight.marginBottom
                      }px`,
                      transition: '.3s',
                      transitionTimingFunction: 'ease-in',
                      backgroundColor: 'transparent',
                      border: '3px solid #fff',
                      borderRadius: '0.25rem',
                      opacity: '0.8',
                      zIndex: '100',
                      pointerEvents: 'none',
                    }}
                  />
                )}
                <Lists
                  setting={{
                    isActive: true,
                    disableCheckBox: true,
                    datas: associatedInfo.associatedAdset.concat(
                      associatedInfo.adset
                    ),
                    // .filter((a) => wordSearch(a.name, search)),
                    setactiveid: (id) => handleCheck(id, 'adset'),
                    checkedList: Object.keys(checkedList.adset).filter(
                      (key) => checkedList.adset[key]
                    ),
                    handleCheck: (id) => handleCheck(id, 'adset'),
                  }}
                />
              </>
            )}
          </Col>
          <Col
            className="position-relative"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            {associatedInfo.ad && (
              <>
                {associatedInfo.associatedAd.length > 0 && (
                  <div
                    className="w-95 mx-2 position-absolute"
                    style={{
                      height: `${
                        (whiteHeight.height + whiteHeight.marginBottom) *
                          associatedInfo.associatedAd.length -
                        whiteHeight.marginBottom
                      }px`,
                      transition: '.3s',
                      transitionTimingFunction: 'ease-in',
                      backgroundColor: 'transparent',
                      border: '3px solid #fff',
                      borderRadius: '0.25rem',
                      opacity: '0.8',
                      zIndex: '100',
                      pointerEvents: 'none',
                    }}
                  />
                )}
                <Lists
                  setting={{
                    isActive: true,
                    datas: associatedInfo.associatedAd.concat(
                      associatedInfo.ad
                    ),
                    // .filter((a) => wordSearch(a.name, search)),
                    setactiveid: (id) => handleCheck(id, 'ad'),
                    checkedList: Object.keys(checkedList.ad).filter(
                      (key) => checkedList.ad[key]
                    ),
                    handleCheck: (id) => handleCheck(id, 'ad'),
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

ReportTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportTable
