import React from 'react'
import { Container, Row } from 'react-bootstrap'

function Tutorio() {
  // const { auth, handleLoginShow } = props
  // const navigate = useNavigate()
  // const handleWelcomeClick = () => {
  //   if (auth.status === 'authed') navigate('/')
  //   else handleLoginShow()
  // }

  return (
    <Container
      fluid
      className="welcomePage d-flex h-100"
      // onClick={handleWelcomeClick}
      style={{ cursor: 'pointer' }}
    >
      <Row>Tutorio</Row>
    </Container>
  )
}
// Error.propTypes = {
//   auth: PropTypes.shape().isRequired,
//   handleLoginShow: PropTypes.func.isRequired,
// }

export default Tutorio
