const GoogleGeoTargets = require('./GoogleGeoTargets')

const GoogleInsihgtsEnums = {
  'segments.click_type': {
    6: 'Phone calls',
    22: 'Sitelink',
    25: 'Headline',
    30: 'Website',
  },
  'segments.slot': {
    3: 'Google search: Top',
    4: 'Google search: Other',
    5: 'Google Display Network',
  },
  'segments.ad_network_type': {
    2: 'Google Search',
    4: 'Display Network',
    6: 'YouTube Videos',
  },
  'segments.device': {
    2: 'Mobile',
    3: 'Tablet',
    4: 'Desktop',
    6: 'Other',
  },
  'ad_group_criterion.gender.type': {
    10: 'Male',
    11: 'Female',
    20: 'Undetermined',
  },
  'ad_group_criterion.age_range.type': {
    503001: '18–24',
    503002: '25–34',
    503003: '35–44',
    503004: '45–54',
    503005: '55–64',
    503006: '65 or more',
    503999: 'Undetermined',
  },
  'ad_group_criterion.parental_status.type': {
    300: 'Parent',
    301: 'Not a parent',
    302: 'Undetermined',
  },
  'search_term_view.status': {
    2: 'Added',
    3: 'Excluded',
    4: 'Added Excluded',
    5: 'None',
  },
  'detail_placement_view.placement_type': {
    2: 'Web page',
    4: 'Mobile Application',
    5: 'YouTube Channel',
  },
  'campaign.advertising_channel_type': {
    2: 'Search Only',
    3: 'Display Only',
    6: 'Video',
  },
  'campaign.advertising_channel_sub_type': {
    7: 'Google Mail',
    10: 'Website conversions',
    11: 'Standard',
  },
  'campaign.status': {
    2: 'Enabled',
    3: 'Paused',
    4: 'Removed',
  },
  'campaign.bidding_strategy_type': {
    3: 'CPC (enhanced)',
    4: '',
    6: 'Target CPA',
    10: '',
    13: 'Manual CPV',
    14: '',
  },
  'ad_group.status': {
    2: 'Enabled',
    3: 'Paused',
    4: 'Removed',
  },
  'ad_group.type': {
    2: 'Standard',
    3: 'Ad Exchange',
    9: 'In-stream',
    11: 'Standard',
  },
  'ad_group_ad.status': {
    2: 'Enabled',
    3: 'Paused',
    4: 'Disabled',
  },
  'ad_group_ad.ad.type': {
    3: 'Expanded_text_ad',
    9: 'Shopping_smart_ad',
    12: 'Video_ad',
    13: 'Gmail_ad',
    14: 'Image_ad',
    15: 'Responsive_search_ad',
    16: 'Legacy_responsive_display_ad',
    19: 'Responsive_display_ad',
    21: 'HTML5_ad',
  },
  'ad_group_criterion.status': {
    2: 'Enabled',
    3: 'Paused',
    4: 'Removed',
  },
  'ad_group_criterion.keyword.match_type': {
    2: 'Exact',
    3: 'Phrase',
    4: 'Broad',
  },
  'feed_placeholder_view.placeholder_type': {
    2: 'Sitelinks extension',
    3: 'Call extension',
    8: 'Structure snippet extension',
  },
  'extension_feed_item.status': {
    2: 'Enabled',
  },
  'segments.conversion_action_category': {
    2: 'Other',
  },
  'segments.external_conversion_source': {
    2: 'Website',
  },
  'segments.conversion_lag_bucket': {
    1: 'Unknown',
    2: '<1 day',
  },
  'segments.conversion_or_adjustment_lag_bucket': {
    2: '<1 day (impr. to conv.)',
    41: 'Unknown (impr. to conv.)',
  },
  'geographic_view.country_criterion_id': (target) => GoogleGeoTargets[target],
  'segments.geo_target_region': (target) => {
    if (!target) {
      return target
    }
    const code = target.toString().replace('geoTargetConstants/', '')
    return GoogleGeoTargets[code]
  },
  'segments.geo_target_metro': (target) => {
    if (!target) {
      return target
    }
    const code = target.toString().replace('geoTargetConstants/', '')
    return GoogleGeoTargets[code]
  },
  'segments.geo_target_city': (target) => {
    if (!target) {
      return target
    }
    const code = target.toString().replace('geoTargetConstants/', '')
    return GoogleGeoTargets[code]
  },
  'user_location_view.country_criterion_id': (target) =>
    GoogleGeoTargets[target],
  'geographic_view.location_type': {
    2: 'Location of interest',
    3: 'Physical location',
  },
  'segments.day_of_week': {
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
    8: 'Sunday',
  },
}

module.exports = GoogleInsihgtsEnums
