import React from 'react'
import PropTypes from 'prop-types'
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedAreaSeries,
  AnimatedAreaStack,
  XYChart,
  Tooltip,
} from '@visx/xychart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { enums } from '../../services/insightProcesser'

function StackChart(props) {
  const { setting } = props
  const {
    width,
    height,
    datas,
    selectedField,
    colors = ['#F2907B', '#72C5D1', '#F2D57B', '#F77F00', '#D62828', '#59C3C3'],
    fillOpacity = 0.6,
    hideLabels,
    nameField,
    provider,
  } = setting

  const accessors = {
    nameAccessor: (d) => d[nameField] || d.name,
    xAccessor: (d) => d[enums[provider].criterionLabels.date],
    yAccessor: (d) => parseInt(d[selectedField], 10),
  }

  // const tipColors = ['#3fc3ca', '#b961a9', '#88bc58', '#6d74ae', '#f1a72f']

  return (
    <div className="position-relative">
      <XYChart
        width={width}
        height={height}
        margin={
          hideLabels ? { top: 10, right: 10, bottom: 10, left: 10 } : undefined
        }
        xScale={{ type: 'band' }}
        yScale={{ type: 'linear' }}
      >
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="rgb(255,255,255,0.3)"
        />
        {!hideLabels && (
          <>
            <AnimatedAxis
              stroke="#317985"
              tickLabelProps={() => ({
                fill: '#21515a',
                fontFamily: 'Arial',
                fontSize: 12,
                textAnchor: 'middle',
                angle: -45,
                dy: '1em',
              })}
              tickFormat={(v) => moment(v).format('YY-MM-DD')}
              orientation="bottom"
            />
            <AnimatedAxis
              stroke="#317985"
              tickLabelProps={() => ({
                fill: '#21515a',
                fontFamily: 'Arial',
                fontSize: 12,
                dx: '0.2em',
                // textAnchor: 'middle',
              })}
              numTicks={5}
              // label={selectedField}
              // labelOffset={0}
              // labelProps={{
              //   x: 0,
              //   y: -10,
              // }}
              orientation="left"
            />
            <AnimatedGrid columns={false} numTicks={4} />
          </>
        )}
        <AnimatedAreaStack>
          {datas.map((data, i) => (
            <AnimatedAreaSeries
              key={i}
              dataKey={`AREA ${i}`}
              //   enableEvents
              data={data}
              fillOpacity={fillOpacity}
              fill={colors[i % 5]}
              xAccessor={accessors.xAccessor}
              yAccessor={accessors.yAccessor}
            />
          ))}
        </AnimatedAreaStack>
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData }) => (
            <div className="d-flex flex-column-reverse">
              {Object.keys(tooltipData.datumByKey).map((key, i) => (
                <span
                  className="lh-lg fw-normal d-flex"
                  key={key}
                  style={{
                    color: colors[i % 5],
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="my-auto"
                    style={{
                      fontSize: '.5rem',
                    }}
                  />
                  &ensp;
                  {accessors.nameAccessor(tooltipData.datumByKey[key].datum)}
                  {', '}
                  {accessors.xAccessor(tooltipData.datumByKey[key].datum)}
                  {', '}
                  {accessors.yAccessor(tooltipData.datumByKey[key].datum)}
                </span>
              ))}
            </div>
          )}
        />
      </XYChart>
    </div>
  )
}

StackChart.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default StackChart
