import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faPenAlt,
  faShare,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import SelectBar from '../SelectBar'
import Datatable from '../DataTable'
import SearchBar from '../SearchBar'
import { wordSearch } from '../../services/lucaFunctions'

function AccountRule(props) {
  const { setting } = props
  const {
    size,
    show,
    rules,
    newRule,
    setNewRule,
    handleRuleEdit,
    handleClose,
    campaigns,
  } = setting

  const [type, settype] = useState('account')
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    platform: '',
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    search: '',
  })
  const { sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  const [mockup, setMockup] = useState({ account: [], campaign: [] })
  useEffect(() => {
    setMockup(
      rules
        ? {
            account: rules.account.sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            }),
            campaign: rules.campaign
              .filter((c) => {
                const target = campaigns.find(
                  (campaign) => campaign.id === c.id
                )
                return wordSearch(target.name, search)
              })
              .sort((a, b) => {
                const { field, order } = JSON.parse(sort)
                return order === 'aesc'
                  ? a[field] - b[field] ||
                      new Date(a[field]) - new Date(b[field])
                  : b[field] - a[field] ||
                      new Date(b[field]) - new Date(a[field])
              }),
          }
        : { account: [], campaign: [] }
    )
  }, [rules, search, sort, campaigns])

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        <Modal.Title>
          <h4>廣 告 規 則 設 定</h4>
          <p>Advertise rule settings</p>
        </Modal.Title>
      </Modal.Header>
      {campaigns ? (
        <Modal.Body className="AccountRule mx-4">
          <Row className="mb-3">
            <Col className="d-flex">
              <h6 className="my-auto">
                {type === 'account'
                  ? `全體規則（${mockup.account.length}）`
                  : `行銷活動規則（${mockup.campaign.length}）`}
              </h6>
            </Col>
            <Col className="d-flex ms-auto">
              <Button
                variant="luca"
                className="ms-auto my-auto"
                onClick={() => {
                  settype(type === 'account' ? 'campaign' : 'account')
                }}
              >
                <FontAwesomeIcon icon={faShare} className="me-2" />
                {type === 'account'
                  ? `行銷活動規則 ( ${mockup.campaign.length} ) `
                  : `全體規則 ( ${mockup.account.length} ) `}
              </Button>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} className="BmsAccountSelector">
              <SelectBar
                setting={{
                  method: (e) =>
                    handleFilterChange({
                      target: {
                        name: 'sort',
                        value:
                          e.target.value ||
                          JSON.stringify({ field: 'name', order: 'desc' }),
                      },
                    }),
                  name: 'sort',
                  value: sort,
                  placeholder: '依照日期排序',
                  content: [
                    {
                      name: '排序 － 由最新至最舊',
                      value: JSON.stringify({
                        field: 'start',
                        order: 'desc',
                      }),
                    },
                    {
                      name: '排序 － 由最舊至最新',
                      value: JSON.stringify({
                        field: 'start',
                        order: 'aesc',
                      }),
                    },
                  ],
                }}
              />
            </Col>
            <Col xs={6} className="ps-0">
              <SearchBar
                setting={{
                  title: '請輸入搜尋關鍵字...',
                  name: 'search',
                  id: 'defaultBorder',
                  // action: <FontAwesomeIcon icon={faSearch} />,
                  tempSearch,
                  settempSearch,
                  method: () =>
                    handleFilterChange({
                      target: {
                        name: 'search',
                        value: tempSearch,
                      },
                    }),
                }}
              />
            </Col>
          </Row>
          {type === 'account' && (
            <Datatable
              setting={{
                pageSize: 5,
                hasPagination: true,
                headers: [
                  '開 始 日 期',
                  '結 束 日 期',
                  '行 銷 活 動',
                  '轉 換 率',
                  '移 除',
                ],
                content: mockup[type]
                  .concat(
                    mockup[type].length === 0
                      ? [
                          {
                            start: '- -',
                            end: '- -',
                            id: '- -',
                            value: '- -',
                            method: '- -',
                          },
                        ]
                      : []
                  )
                  .concat(
                    Array(
                      mockup[type].length % 5 > 0
                        ? 5 - (mockup[type].length % 5)
                        : 0
                    ).fill({
                      start: '- -',
                      end: '- -',
                      id: '- -',
                      value: '- -',
                      method: '- -',
                    })
                  )
                  .map((m, index) => {
                    const campaign = campaigns.find((c) => c.id === m.id)
                    let name = '- -'
                    let method = '- -'
                    if (campaign) {
                      name = campaign.name
                    } else if (m.id === '') {
                      name = '所有'
                    }
                    if (m.method !== '- -')
                      method = (
                        <FontAwesomeIcon
                          onClick={() => handleRuleEdit('delete', type, index)}
                          className="m-auto align-self-center text-lucaLink me-2"
                          icon={faMinusCircle}
                          title="移 除"
                        />
                      )
                    return {
                      start: { value: m.start },
                      end: { value: m.end },
                      id: { value: name, title: name },
                      value: { value: m.value },
                      delete: {
                        value: method,
                      },
                    }
                  }),
              }}
            />
          )}
          {type === 'campaign' && (
            <Datatable
              setting={{
                pageSize: 5,
                hasPagination: true,
                headers: [
                  '開 始 日 期',
                  '結 束 日 期',
                  '行 銷 活 動',
                  '轉 換 率',
                  '移 除',
                ],
                content: mockup[type]
                  .concat(
                    mockup[type].length === 0
                      ? [
                          {
                            start: '- -',
                            end: '- -',
                            id: '- -',
                            value: '- -',
                            method: '- -',
                          },
                        ]
                      : []
                  )
                  .concat(
                    Array(
                      mockup[type].length % 5 > 0
                        ? 5 - (mockup[type].length % 5)
                        : 0
                    ).fill({
                      start: '- -',
                      end: '- -',
                      id: '- -',
                      value: '- -',
                      method: '- -',
                    })
                  )
                  .map((m, index) => {
                    const campaign = campaigns.find((c) => c.id === m.id)
                    let name = '- -'
                    let method = '- -'
                    if (campaign) {
                      name = campaign.name
                    } else if (m.id === '') {
                      name = '所有'
                    }
                    if (m.method !== '- -')
                      method = (
                        <FontAwesomeIcon
                          onClick={() => handleRuleEdit('delete', type, index)}
                          className="m-auto align-self-center text-lucaLink me-2"
                          icon={faMinusCircle}
                          title="移 除"
                        />
                      )
                    return {
                      start: { value: m.start },
                      end: { value: m.end },
                      id: { value: name, title: name },
                      value: { value: m.value },
                      delete: {
                        value: method,
                      },
                    }
                  }),
              }}
            />
          )}
          <Form.Label className="h6">
            <FontAwesomeIcon
              className="m-auto align-self-center text-lucaDark me-2"
              icon={faPenAlt}
              title="新 增"
            />
            建立新規則
          </Form.Label>
          <Row className="AccountRule text-left">
            <Datatable
              setting={{
                pageSize: 1,
                hasPagination: false,
                content: [
                  {
                    start: {
                      value: (
                        // <DatePicker
                        //   value={newRule.start}
                        //   onChange={(value) =>
                        //     setNewRule({
                        //       ...newRule,
                        //       start: moment(value).format('yyyy-MM-DD'),
                        //     })
                        //   }
                        //   format="YYYY-MM-DD"
                        // />
                        <div className="d-flex">
                          <FontAwesomeIcon
                            className="fs-5 my-auto mx-2 text-lucaLight ms-0"
                            icon={faCalendarAlt}
                          />
                          <DatePicker
                            value={newRule.start}
                            onChange={(value) =>
                              setNewRule({
                                ...newRule,
                                start: moment(value).format('yyyy-MM-DD'),
                              })
                            }
                          />
                          <FontAwesomeIcon
                            className="fs-5 my-auto mx-2 text-luca"
                            icon={faAngleRight}
                          />
                          <DatePicker
                            value={newRule.end}
                            onChange={(value) =>
                              setNewRule({
                                ...newRule,
                                end: moment(value).format('yyyy-MM-DD'),
                              })
                            }
                          />
                        </div>
                      ),
                      colSpan: '2',
                    },
                    // end: {
                    //   value: (
                    //     <DatePicker
                    //       value={newRule.end}
                    //       onChange={(value) =>
                    //         setNewRule({
                    //           ...newRule,
                    //           end: moment(value).format('yyyy-MM-DD'),
                    //         })
                    //       }
                    //       format="YYYY-MM-DD"
                    //     />
                    //   ),
                    // },
                    id: {
                      value: (
                        <SelectBar
                          setting={{
                            // name: c.name,
                            // subtype: c.subtype,
                            placeholder: '所有',
                            value: newRule.id,
                            method: (event) =>
                              setNewRule({
                                ...newRule,
                                id: event.target.value,
                              }),
                            noInvalid: true,
                            content: campaigns,
                          }}
                        />
                      ),
                    },
                    value: {
                      value: (
                        <Form.Control
                          label="Mockup"
                          onChange={(event) =>
                            setNewRule({
                              ...newRule,
                              value: event.target.value,
                            })
                          }
                          value={newRule.value}
                          placeholder="value"
                        />
                      ),
                    },
                    delete: {
                      value: (
                        <Button
                          onClick={() => {
                            handleRuleEdit('add')
                            settype(newRule.id === '' ? 'account' : 'campaign')
                          }}
                          variant="luca"
                          className="w-100"
                        >
                          <FontAwesomeIcon
                            className="m-auto align-self-center text-light me-2"
                            icon={faPlusCircle}
                            title="新 增"
                          />
                          新 增
                        </Button>
                      ),
                    },
                  },
                ],
              }}
            />
          </Row>
        </Modal.Body>
      ) : (
        <div />
      )}
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取 消
        </Button>
        <Button variant="luca" onClick={() => handleClose(true)}>
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AccountRule.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AccountRule
