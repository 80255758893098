/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as emptyMark } from '@fortawesome/free-regular-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

function CostsTable({ setting }) {
  const { sheet = [], fields, setfields, sheetType } = setting

  const onDragEnd = (result) => {
    if (!result.destination) return
    setfields((prevState) => {
      const newState = Array.from(prevState)
      const startIndex = newState.findIndex(
        (f) => f.index === fields[result.source.index].index
      )
      const endIndex = newState.findIndex(
        (f) => f.index === fields[result.destination.index].index
      )
      const [removed] = newState.splice(startIndex, 1)
      newState.splice(endIndex, 0, removed)
      return newState
    })
  }
  const grid = 0
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%',
    // height: '270px',
  })
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // minWidth: isDragging ? '' : '32%',

    // change background colour if dragging
    // background: isDragging ? 'white' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  return (
    <Row
      className="text-lucaDark ps-1"
      style={{
        height: '68vh',
      }}
    >
      <Col className="h-100 d-flex flex-column px-2">
        <div className="h-100 colBorder ISWithH100">
          {sheetType === 'cost' || sheetType === 'account_cost' ? (
            <div
              className="w-100 h-100 fs-7 p-0 d-flex flex-column flex-grow-1 mx-0 overflow-scroll scrollbarShow scrollbarShowBottom"
              style={{
                background: 'white',
              }}
            >
              {sheet.map((cost, i) => (
                <Row
                  key={i}
                  className="p-0 mx-0 w-100 justift-content-end flex-nowrap"
                  style={
                    i
                      ? {}
                      : {
                          position: 'sticky',
                          top: 0,
                          backgroundColor: 'White',
                          zIndex: '3',
                        }
                  }
                >
                  {cost.map((c, j) => (
                    <Col
                      key={`${i}_${j}`}
                      xs={j === 0 ? 1 : 4}
                      className="py-1 d-flex colBorder App-oneLineEllipsis"
                    >
                      {c ? (
                        <span className="d-flex App-oneLineEllipsis pe-0">
                          {c}
                        </span>
                      ) : (
                        <span className="App-oneLineEllipsis">- -</span>
                      )}
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="1" direction="horizontal">
                {(dropProvided, dropSnapshot) => (
                  <div
                    {...dropProvided.droppableProps}
                    ref={dropProvided.innerRef}
                    style={getListStyle(dropSnapshot.isDraggingOver)}
                    className="w-100 h-100 d-flex overflow-scroll scrollbarShow scrollbarShowBottom"
                  >
                    {fields.map(({ index, ...field }, position) => (
                      <Draggable
                        key={`${index}`}
                        draggableId={`${index}`}
                        index={position}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            // style={getItemStyle(
                            //   dragSnapshot.isDragging,
                            //   dragProvided.draggableProps.style
                            // )}
                            className="fs-7 p-0 d-flex flex-column flex-grow-1 mx-0"
                            style={{
                              background: 'white',
                              position: field.sticky ? 'sticky' : '',
                              left: field.sticky ? '0px' : '',
                              zIndex: !index ? '3' : field.sticky ? '2' : '1',
                              ...getItemStyle(
                                dragSnapshot.isDragging,
                                dragProvided.draggableProps.style
                              ),
                            }}
                          >
                            <Row className="p-0 mx-0 w-100 colBorder justift-content-end flex-nowrap">
                              <Col className="py-1 d-flex colBorder App-oneLineEllipsis">
                                <span className="d-flex w-100 App-oneLineEllipsis pe-0">
                                  {field.label}
                                  <FontAwesomeIcon
                                    style={{
                                      color: '#317985',
                                      cursor: 'pointer',
                                    }}
                                    className="ms-3 align-self-center"
                                    icon={field.sticky ? faBookmark : emptyMark}
                                    title={
                                      field.sticky ? '取 消 釘 選' : '釘 選'
                                    }
                                    onClick={() =>
                                      setfields((prevState) =>
                                        prevState.map((p) =>
                                          p.index === index
                                            ? { ...p, sticky: !p.sticky }
                                            : p
                                        )
                                      )
                                    }
                                  />
                                </span>
                              </Col>
                            </Row>
                            {sheet.slice(1, sheet.length).map((cost, i) => (
                              <Row
                                key={i}
                                className="p-0 mx-0 w-100 colBorder justift-content-end flex-nowrap"
                                style={
                                  i
                                    ? {}
                                    : {
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor: 'White',
                                        zIndex: '3',
                                      }
                                }
                              >
                                <Col
                                  key={`${i}`}
                                  className={`py-1 d-flex colBorder App-oneLineEllipsis ${
                                    field.type === 'currency'
                                      ? 'justify-content-end'
                                      : 'justify-content-start'
                                  }`}
                                >
                                  {cost[index] ? (
                                    <span
                                      className={`d-flex App-oneLineEllipsis pe-0 ${
                                        field.type === 'currency'
                                          ? 'ms-auto'
                                          : ''
                                      }`}
                                    >
                                      {field.type === 'currency' &&
                                      cost[index] !== '- -'
                                        ? numeral(cost[index]).format('$0.00')
                                        : cost[index]}
                                    </span>
                                  ) : (
                                    <span className="App-oneLineEllipsis">
                                      - -
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </Col>
    </Row>
  )
}

CostsTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default CostsTable
