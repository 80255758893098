import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import DropdownButton from 'react-bootstrap/esm/DropdownButton'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularFluidMeter } from 'fluid-meter'
import { SearchBar, SelectBar, Datatable, ConfirmForm } from '../components'
import apiService from '../services/apiService'

function Summary({ setting }) {
  const { summary } = setting
  const ref = useRef()
  const [meter, setmeter] = useState(false)
  const initMeter = (target) => {
    if (meter) meter.dispose()
    const inited = new CircularFluidMeter(target, {
      initialProgress: 75,
      use3D: false,
      borderColor: '#fff',
      backgroundColor: '#e2e2e2',
      dropShadow: false,
      borderWidth: 15,
      fontSize: 48,
      padding: 0,
      textShadowColor: '#ddd',
      fluidConfiguration: {
        color: '#317985',
        waveSpeed: 10,
        horizontalSpeed: 10,
      },
    })
    setmeter(inited)
  }
  useEffect(() => {
    if (ref.current && !meter) {
      initMeter(ref.current)
    }
  }, [ref.current])

  const { type, currency, total, used } = summary
  return (
    <div className="d-flex w-75 mx-auto h-100">
      <div
        ref={ref}
        style={{
          height: '250px',
          width: '250px',
        }}
      />
      <div className="d-flex flex-column flex-fill text-lucaDark p-3 ps-5 fs-5">
        <div className="d-flex flex-fill">
          <div className="text-luca d-flex">
            <span className="mx-auto fw-bold">類型｜</span>
            <span className="fw-4">{type}</span>
          </div>
          <DropdownButton
            className="ps-2 ms-auto"
            title="所有媒體平台&ensp;"
            size="sm"
            variant="outline-luca"
          >
            <DropdownItem eventKey="1">Meta</DropdownItem>
            <DropdownItem eventKey="2">Google</DropdownItem>
          </DropdownButton>
        </div>
        <hr className="hrClass-dotted" />
        <div className="d-flex flex-fill fw-bold">
          <span>預算使用狀況</span>
          <span className="ms-auto text-luca">{currency}</span>
        </div>
        <div className="d-flex flex-fill">
          <span>總預算</span>
          <span className="ms-auto fw-bold">{total}</span>
        </div>
        <div className="d-flex flex-fill">
          <span>已花費</span>
          <span className="ms-auto fw-bold">{used}</span>
        </div>
        <div className="d-flex flex-fill">
          <span>餘額</span>
          <span className="ms-auto fw-bold">{total - used}</span>
        </div>
      </div>
    </div>
  )
}

function Billing({ auth }) {
  const [bills, setbills] = useState({
    summary: {},
    data: [],
    prepared: false,
  })
  const { summary, data, prepared } = bills
  useEffect(() => {
    const getBills = async () => {
      const res = await apiService.data({
        path: `luca/budget/summary/${auth.account.business_id}`,
        method: 'get',
      })
      setbills({
        ...res,
        prepared: true,
      })
    }
    getBills()
  }, [])

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  // const handleShowWarn = (value, target) => {
  //   setshowWarn({
  //     ...value,
  //     show: true,
  //     target,
  //   })
  // }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      // todo
    }
  }

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    sort: JSON.stringify({ field: 'date', order: 'desc' }),
    search: '',
    platform: '',
  })
  const { sort, search, platform } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  const filteredrecord = useMemo(
    () =>
      data
        .filter(
          (r) =>
            (!platform || r.platform === platform) &&
            (!search || r.adaccount.includes(search))
        )
        .sort((a, b) => {
          const { field, order } = JSON.parse(sort)
          return order === 'aesc'
            ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
            : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
        }),
    [data, sort, search, platform]
  )

  return (
    <Container fluid className="authManage card px-2 overflow-auto">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1">預 算 與 花 費 總 覽</h4>
        <p>Budget and expense overview</p>
      </Row>
      {!prepared ? (
        <Row className="flex-fill">
          <div className="m-auto d-flex justify-content-center h-100 my-0">
            <Spinner
              className="my-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        </Row>
      ) : (
        <>
          <Row
            className="pb-5"
            style={{
              height: '50vh',
            }}
          >
            <Summary
              setting={{
                summary,
              }}
            />
          </Row>
          <hr className="hrClass-dotted" />
          <Row className="p-4 pageTitle">
            <h4 className="pt-4 pb-1">預 算 花 費 明 細</h4>
            <p>Budget and expense log</p>
          </Row>
          <Row className="pt-3 pb-0 px-4">
            <Col xs="3">
              <SelectBar
                setting={{
                  method: (e) => {
                    handleFilterChange({
                      target: {
                        name: 'platform',
                        value: e.target.value || '',
                      },
                    })
                  },
                  placeholder: '選擇媒體平台類型',
                  content: [
                    { name: 'Meta', value: 'Meta' },
                    { name: 'Google', value: 'Google' },
                  ],
                }}
              />
            </Col>
            <Col xs="3" className="ps-0">
              <SelectBar
                setting={{
                  method: (e) =>
                    handleFilterChange({
                      target: {
                        name: 'sort',
                        value:
                          e.target.value ||
                          JSON.stringify({ field: 'name', order: 'desc' }),
                      },
                    }),
                  value: sort,
                  placeholder: '選擇排序',
                  content: [
                    {
                      name: '排序 － 依花費高至低',
                      value: JSON.stringify({
                        field: 'amount',
                        order: 'desc',
                      }),
                    },
                    {
                      name: '排序 － 依花費低至高',
                      value: JSON.stringify({
                        field: 'amount',
                        order: 'aesc',
                      }),
                    },
                    {
                      name: '排序 － 由最新至最舊',
                      value: JSON.stringify({
                        field: 'date',
                        order: 'desc',
                      }),
                    },
                    {
                      name: '排序 － 由最舊至最新',
                      value: JSON.stringify({
                        field: 'date',
                        order: 'aesc',
                      }),
                    },
                  ],
                }}
              />
            </Col>
            <Col xs="6" className="ps-0">
              <SearchBar
                setting={{
                  title: '請輸入搜尋關鍵字...',
                  name: 'search',
                  tempSearch,
                  settempSearch,
                  method: () =>
                    handleFilterChange({
                      target: {
                        name: 'search',
                        value: tempSearch,
                      },
                    }),
                }}
              />
            </Col>
          </Row>
          <Row
            className="px-4 pt-3 pb-2 tableLayout-adj tableW-adj overflow-auto"
            style={{
              minHeight: '50vh',
              height: '50vh',
            }}
          >
            <Datatable
              setting={{
                hasCheckBox: false,
                hasButton: false,
                hasPagination: true,
                pageSize: 5,
                headers: [
                  '媒 體 平 台',
                  '廣 告 帳 戶 名 稱',
                  '出 帳 日 期',
                  '花 費 金 額',
                ],
                content: filteredrecord
                  .concat(
                    Array(
                      filteredrecord.length % 5 > 0 ||
                        filteredrecord.length === 0
                        ? 5 - (filteredrecord.length % 5)
                        : 0
                    ).fill({
                      fill: true,
                    })
                  )
                  .map((m) =>
                    m.fill
                      ? {
                          platform: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          adaccount: { value: '- -' },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          amount: { value: '- -' },
                        }
                      : {
                          platform: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">{m.platform}</span>
                              </div>
                            ),
                          },
                          adaccount: { value: m.adaccount },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">{m.date}</span>
                              </div>
                            ),
                          },
                          amount: { value: m.amount },
                        }
                  ),
              }}
            />
          </Row>
        </>
      )}
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="my-4">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
    </Container>
  )
}

Billing.propTypes = {
  auth: PropTypes.shape().isRequired,
}

Summary.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Billing
