import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import LoadingButton from '../LoadingButton'
import apiService from '../../services/apiService'

function RForm(props) {
  //   const [action, setAction] = useState('login')
  const { setting } = props
  const {
    show,
    handleClose,
    handleGoLogin,
    // handleRequestReset,
    // requestError,
    // setrequestError,
  } = setting

  const [type, settype] = useState('')
  const forms = {
    個人: [
      '姓名',
      '身分證字號',
      '戶籍地址',
      '聯絡地址',
      '交易憑證',
      '聯絡電話',
      '聯絡信箱',
    ],
    公司行號: [
      '公司全名',
      '統一編號',
      '身分別',
      '公司登記地址',
      '公司聯絡地址',
      '聯絡姓名',
      '聯絡電話',
      '聯絡信箱',
      '交易憑證',
    ],
    代理商: [
      '公司全名',
      '統一編號',
      '身分別',
      '公司登記地址',
      '公司聯絡地址',
      '聯絡姓名',
      '聯絡電話',
      '聯絡信箱',
      '交易憑證',
    ],
  }

  const placeholders = {
    交易憑證: '統一發票/invoice',
    身分別: '請選擇身分',
    公司全名: '發票抬頭',
  }

  const types = {
    聯絡信箱: 'email',
    交易憑證: '',
    聯絡電話: 'tel',
  }

  const [data, setdata] = useState({})
  useEffect(() => {
    if (type)
      setdata(forms[type].reduce((prev, cur) => ({ ...prev, [cur]: '' }), {}))
    else setdata({})
  }, [type])
  const handleDataChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const [done, setdone] = useState(false)
  const handleDone = async () => {
    await apiService.data({
      path: 'luca/form/mail',
      method: 'post',
      data: {
        fields: {
          身份別: type,
          ...forms[type].reduce(
            (prev, key) => ({ ...prev, [key]: data[key] }),
            {}
          ),
        },
        html: `<div style="width:100%">
      <div style="width:100%;background-color:#0c5394;color:#fff;border:inset 1px;">
        申請人資訊
      </div>
      <div class="flex-wrap" style="width:100%;border:inset 1px">
        <div class="flex-nowrap" style="width:100%;display:flex;border:inset 1px">
          <div style="width:30%;border:inset 1px">
            身份別
          </div>
          <div style="width:70%;border:inset 1px">
            ${type}
          </div>
        </div>
        ${forms[type]
          .map(
            (key) =>
              `<div class="flex-nowrap" style="width:100%;display:flex;border:inset 1px">
          <div style="width:30%;border:inset 1px">
            ${key}
          </div>
          <div style="width:70%;border:inset 1px">
            ${data[key]}
          </div>
        </div>`
          )
          .join('')}
        </div>`,
        subject: `LUCA帳戶申請`,
        files: [],
      },
    })
    setdone(true)
  }

  return done ? (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h4>申請已送出</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex AccformCard">
        <div className="h-100 w-100 d-flex flex-column py-5">
          <FontAwesomeIcon
            className="h1 mx-auto mt-auto mb-3 text-luca align-self-center"
            icon={faCheckCircle}
          />
          <h3 className="mx-auto mb-auto text-luca h5">帳號申請審核中</h3>
        </div>
      </Modal.Body>

      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          返 回
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="p-2"
    >
      <Modal.Body id="LoginModal">
        <Modal.Title>申請帳號</Modal.Title>
        <p>Register</p>
        <Form.Label>身分</Form.Label>
        <Form.Select
          className="gpt-form-select mb-2"
          size="sm"
          type="text"
          placeholder="請選擇身分"
          name="style"
          onChange={(e) => settype(e.target.value)}
          value={type}
          title="請選擇身分"
        >
          <option value="" disabled>
            請選擇身分
          </option>
          <option value="個人">個人</option>
          <option value="公司行號">公司行號</option>
          <option value="代理商">代理商</option>
        </Form.Select>
        {type ? (
          forms[type].map((key) => {
            switch (key) {
              case '身分別':
                return (
                  <>
                    <Form.Label>身分</Form.Label>
                    <Form.Select
                      className="gpt-form-select mb-2"
                      size="sm"
                      type="text"
                      placeholder={placeholders[key]}
                      name={key}
                      onChange={handleDataChange}
                      value={data[key]}
                      title="請選擇身分別"
                    >
                      <option value="" disabled>
                        請選擇身分別
                      </option>
                      <option value="國內公司">國內公司</option>
                      <option value="國外公司">國外公司</option>
                    </Form.Select>
                  </>
                )
              default:
                return (
                  <>
                    <Form.Label>{key}</Form.Label>
                    <Form.Control
                      className="gpt-form-select mb-2"
                      size="sm"
                      type={types[key]}
                      placeholder={placeholders[key]}
                      name={key}
                      onChange={handleDataChange}
                      value={data[key]}
                    />
                  </>
                )
            }
          })
        ) : (
          <div />
        )}
        <LoadingButton
          variant="luca"
          onClick={handleDone}
          disabled={type ? forms[type].some((key) => data[key] === '') : true}
          btnText="送出"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ boxShadow: 'none', color: '#317985' }}
          variant="link"
          onClick={handleGoLogin}
        >
          登入
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

RForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default RForm
