/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-promise-executor-return */
import React, { useEffect, useState, useMemo, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import numeral from 'numeral'
import ExcelJS from 'exceljs'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Dropdown from 'react-bootstrap/Dropdown'
import Spinner from 'react-bootstrap/esm/Spinner'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCircleExclamation,
  faCircleMinus,
  faCirclePlus,
  faEdit,
  faExclamationCircle,
  faMinus,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Accordion, Table } from 'react-bootstrap'
import CostsTable from './CostsTable'
import TrueCostsTable from './TrueCostsTable'
import SelectBar from '../SelectBar'
import apiService from '../../services/apiService'
import LoadingButton from '../LoadingButton'
import { AuthContext } from '../ContextProvider'
import LucaDatePicker from '../LucaDatePicker'
import { getOriginIcon } from '../../services/lucaFunctions'
import InvoiceTable from './InvoiceTable'
// import AllCostsTable from './AllCostsTable'
import SearchBar from '../SearchBar'

const defaultFields = [
  {
    from: 'erp',
    label: '業務員',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案代號',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案名稱',
    show: true,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: '客戶名稱',
    show: true,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  { from: 'erp', label: 'AE', show: true, type: 'text', xs: 1, sticky: false },
  { from: 'erp', label: 'AM', show: true, type: 'text', xs: 1, sticky: false },
  {
    from: 'erp',
    label: '部門主管',
    show: false,
    type: 'text',
    xs: 1,
    sticky: false,
  },
  {
    from: 'erp',
    label: '案型',
    show: false,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '新客舊客',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '客戶來源',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案預定成本',
    show: true,
    type: 'currency',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案預定收入',
    show: false,
    type: 'currency',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '合約金額',
    show: true,
    type: 'currency',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '合約預計成本',
    show: true,
    type: 'currency',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '走期開始日期',
    show: true,
    type: 'text',
    sticky: false,
  },
  {
    from: 'erp',
    label: '走期結束日期',
    show: true,
    type: 'text',
    sticky: false,
  },
  {
    from: 'media',
    label: '媒體',
    show: true,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'media',
    label: '帳戶名稱',
    show: true,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'media',
    label: '帳戶id',
    show: false,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'media',
    label: '花費',
    show: true,
    type: 'currency',
    xs: 2,
    sticky: false,
  },
  {
    from: 'media',
    label: '幣別',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'media',
    label: '案號（incomplete）',
    show: false,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'media',
    label: '廣告活動',
    show: false,
    type: 'text',
    xs: 4,
    sticky: false,
  },
  {
    from: 'media',
    label: '廣告活動id',
    show: false,
    type: 'text',
    xs: 3,
    sticky: false,
  },
  {
    from: 'ragic',
    label: '活動名稱',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'ragic',
    label: '廣告執行金額(含境外稅)',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'ragic',
    label: '服務費 AC %',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },

  {
    from: 'ragic',
    label: '服務費 AC',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'ragic',
    label: '付款條件',
    show: true,
    type: 'text',
    xs: 2,
    sticky: false,
  },

  {
    from: 'media',
    label: '曝光',
    show: false,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'media',
    label: '點擊',
    show: false,
    type: 'text',
    xs: 2,
    sticky: false,
  },
  {
    from: 'media',
    label: '觸及',
    show: false,
    type: 'text',
    xs: 2,
    sticky: false,
  },
].map((item, index) => ({
  ...item,
  index,
}))

function Custom({ setting }) {
  const { fields } = setting
  // const auth = useContext(AuthContext)
  // const { setcustom, fields } = setting
  // const [operations, setOperations] = useState([])
  // const [adding, setAdding] = useState('')
  // const [focus, setfocus] = useState(false)
  // const [value, setValue] = useState(1)

  const [editing, setediting] = useState(-1)
  const [show, setshow] = useState(false)
  const [customes, setcustomes] = useState(setting.customs || [])

  const handleClose = () => {
    setting.setcustom(customes)
    setshow(false)
  }

  return (
    <>
      <Button variant="luca" onClick={() => setshow(true)}>
        自訂欄位
      </Button>
      <Modal
        style={{
          zIndex: '99999',
        }}
        show={show}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header className="d-flex" closeButton>
          <h4>自訂欄位</h4>
          <Button
            variant="luca"
            className="ms-auto me-3 mt-2"
            onClick={() => {
              let id = 0
              customes.forEach((c) => {
                id = Math.max(id, c.id)
              })
              setcustomes((prevState) => [
                ...prevState,
                { id: id + 1, name: '新欄位', path: [] },
              ])
            }}
          >
            新 增
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="p-3 border">
            <Col xs={2}>欄位名稱</Col>
            <Col xs={10}>計算過程</Col>
          </Row>
          {customes.map(({ id, name, path }) =>
            editing === id ? (
              <Row className="p-3 border">
                <Col xs={2} className="d-flex">
                  <Form.Control
                    className="my-auto"
                    value={name}
                    onChange={(e) =>
                      setcustomes((prevState) =>
                        prevState.map((ps) =>
                          ps.id === id ? { ...ps, name: e.target.value } : ps
                        )
                      )
                    }
                  />
                </Col>
                <Col xs={8}>
                  <Row className="pt-2">現在公式</Row>
                  <Row className="border-bottom py-2 overflow-scroll flex-nowrap">
                    {path.map(({ value, operator }) => (
                      <>
                        <Col className="text-nowrap">{value}</Col>
                        <Col>{operator || '='}</Col>
                      </>
                    ))}
                  </Row>
                  {path.map(({ type, value, operator }, i) => (
                    <>
                      {!i && (
                        <Row className="pt-2 px-2">
                          <Col xs={3} className="d-flex">
                            格式
                          </Col>
                          <Col xs={6} className="d-flex">
                            內容
                          </Col>
                          <Col xs={3} className="d-flex">
                            運算子
                          </Col>
                        </Row>
                      )}
                      <Row className="border-bottom py-2">
                        <Col xs={3} className="d-flex">
                          <SelectBar
                            value={type}
                            setting={{
                              method: (e) => {
                                setcustomes((prevState) =>
                                  prevState.map((ps) =>
                                    ps.id === id
                                      ? {
                                          ...ps,
                                          path: ps.path.map((psp, j) =>
                                            i === j
                                              ? {
                                                  ...psp,
                                                  type: e.target.value,
                                                }
                                              : psp
                                          ),
                                        }
                                      : ps
                                  )
                                )
                              },
                              placeholder: '新增',
                              content: [
                                {
                                  name: '欄位',
                                  value: 'field',
                                },
                                {
                                  name: '自訂',
                                  value: 'text',
                                },
                              ],
                            }}
                          />
                        </Col>
                        <Col xs={6} className="d-flex">
                          {type === 'field' ? (
                            <SelectBar
                              value={value}
                              setting={{
                                method: (e) => {
                                  setcustomes((prevState) =>
                                    prevState.map((ps) =>
                                      ps.id === id
                                        ? {
                                            ...ps,
                                            path: ps.path.map((psp, j) =>
                                              i === j
                                                ? {
                                                    ...psp,
                                                    value: e.target.value,
                                                  }
                                                : psp
                                            ),
                                          }
                                        : ps
                                    )
                                  )
                                },
                                placeholder: '選擇欄位',
                                content: fields.map((f) => ({
                                  ...f,
                                  name: f.label,
                                })),
                              }}
                            />
                          ) : (
                            <Form.Control
                              className="w-40"
                              value={value}
                              onChange={(e) =>
                                setcustomes((prevState) =>
                                  prevState.map((ps) =>
                                    ps.id === id
                                      ? {
                                          ...ps,
                                          path: ps.path.map((psp, j) =>
                                            i === j
                                              ? {
                                                  ...psp,
                                                  value: e.target.value,
                                                }
                                              : psp
                                          ),
                                        }
                                      : ps
                                  )
                                )
                              }
                            />
                          )}
                        </Col>
                        <Col xs={3} className="d-flex">
                          <SelectBar
                            value={operator || '+'}
                            setting={{
                              method: (e) => {
                                setcustomes((prevState) =>
                                  prevState.map((ps) =>
                                    ps.id === id
                                      ? {
                                          ...ps,
                                          path: ps.path.map((psp, j) =>
                                            i === j
                                              ? {
                                                  ...psp,
                                                  operator: e.target.value,
                                                }
                                              : psp
                                          ),
                                        }
                                      : ps
                                  )
                                )
                              },
                              placeholder: '=',
                              content: [
                                {
                                  name: '+',
                                  value: '+',
                                },
                                {
                                  name: '-',
                                  value: '-',
                                },
                                {
                                  name: '*',
                                  value: '*',
                                },
                                {
                                  name: '/',
                                  value: '/',
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Row className="py-2">
                    <Button
                      variant="outlined"
                      className="w-100 my-auto d-flex justify-content-center"
                      onClick={() => {
                        setcustomes((prevState) =>
                          prevState.map((ps) =>
                            ps.id === id
                              ? {
                                  ...ps,
                                  path: [
                                    ...ps.path,
                                    { type: '欄位', value: '' },
                                  ],
                                }
                              : ps
                          )
                        )
                      }}
                    >
                      新增欄位
                      <FontAwesomeIcon
                        className="my-auto px-1"
                        icon={faCirclePlus}
                      />
                    </Button>
                  </Row>
                </Col>
                <Col xs={2} className="d-flex">
                  <Button
                    variant="outlined"
                    className="w-100 my-auto d-flex"
                    onClick={() => setediting(-1)}
                  >
                    <FontAwesomeIcon
                      className="m-auto my-auto"
                      icon={faCheck}
                    />
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className="p-3 border">
                <Col xs={2}>{name}</Col>
                <Col xs={8}>
                  <Row className="overflow-scroll flex-nowrap">
                    {path.map(({ value, operator }) => (
                      <>
                        <Col>{value}</Col>
                        <Col>{operator || '='}</Col>
                      </>
                    ))}
                  </Row>
                </Col>
                <Col xs={2} className="d-flex">
                  <Button
                    variant="outlined"
                    className="w-50 my-auto d-flex"
                    onClick={() => setediting(id)}
                  >
                    <FontAwesomeIcon className="m-auto my-auto" icon={faEdit} />
                  </Button>
                  <Button
                    variant="outlined"
                    className="w-50 my-auto d-flex"
                    onClick={() => {
                      setcustomes((prevState) =>
                        prevState.map((ps) =>
                          ps.id === id
                            ? {
                                ...ps,
                                path: ps.path.filter((psp) => psp.id !== id),
                              }
                            : ps
                        )
                      )
                    }}
                  >
                    <FontAwesomeIcon
                      className="m-auto my-auto"
                      icon={faTimes}
                    />
                  </Button>
                </Col>
              </Row>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="luca">
            確 定
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function Fix({ setting }) {
  // const auth = useContext(AuthContext)
  const { ctp, setctp, handleSettingEdit, campaigns, revenue } = setting
  const [search, setsearch] = useState('')
  // const [loaded, setloaded] = useState(100)
  const NTF = useMemo(() => {
    if (!revenue) return []
    return revenue.np.campaigns.reduce(
      (prev, cur) => (prev.includes(cur.id) ? prev : [...prev, cur.id]),
      []
    )
  }, [revenue])

  console.log(campaigns)
  console.log(ctp)

  const clickRef = useRef(null)
  console.log(NTF)

  return (
    <Dropdown className="my-auto select-default" align="end">
      <Dropdown.Toggle
        ref={clickRef}
        className="select-dropdown"
        id="dropdown-basic"
      >
        規則設定
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="px-3"
        style={{
          width: '1000px',
          height: '70vh',
        }}
      >
        <div className="d-flex flex-column w-100 h-100">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="my-2 w-96 border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="搜 尋..."
              aria-label="搜 尋..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋"
              value={search}
              onChange={(event) => setsearch(event.target.value)}
            />
          </InputGroup>
          <div className="w-100 overflow-scroll my-2">
            <Accordion defaultActiveKey="0">
              {campaigns
                .filter(
                  (campaign) =>
                    !search ||
                    campaign.campaign_name.includes(search) ||
                    campaign.account_name.includes(search)
                )
                .map((campaign) => (
                  <Accordion.Item eventKey={campaign.campaign_id}>
                    <Accordion.Header>
                      <Row className="d-flex px-3 w-100">
                        <Col xs={3}>
                          <span className="App-oneLineEllipsis w-100">
                            {campaign.account_name}
                          </span>
                        </Col>
                        <Col xs={6}>
                          <span className="App-oneLineEllipsis w-100">
                            {campaign.campaign_name}
                          </span>
                        </Col>
                        {ctp[campaign.campaign_id] &&
                          ctp[campaign.campaign_id].length !== 0 && (
                            <Col xs={2}>
                              <span className="App-oneLineEllipsis w-100">
                                已有 {ctp[campaign.campaign_id].length} 筆設定
                              </span>
                            </Col>
                          )}
                        <Col xs={1} className="ms-auto">
                          {NTF.includes(campaign.campaign_id) && (
                            <FontAwesomeIcon
                              style={{ color: 'red', width: '20px' }}
                              className="fs-4 my-auto me-1"
                              icon={faExclamationCircle}
                            />
                          )}
                        </Col>
                      </Row>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row className="py-2 w-100 text-lucaDark fw-bold text-center">
                        <Col xs={2} className="my-auto">
                          <h6>條件</h6>
                        </Col>
                        <Col xs={7} className="my-auto">
                          <h6>日期區間</h6>
                        </Col>
                        <Col xs={2} className="my-auto">
                          <h6>數值</h6>
                        </Col>
                        <Col xs={1} className="pe-0 my-auto">
                          <Button
                            size="sm"
                            variant="lucaIcon"
                            className="ms-auto me-0 my-auto d-flex justify-content-center fs-8 fw-bold"
                            onClick={() =>
                              setctp({
                                ...ctp,
                                [campaign.campaign_id]: [
                                  ...(ctp[campaign.campaign_id] || []),
                                  {
                                    type: '',
                                    since: '',
                                    until: '',
                                    value: '',
                                  },
                                ],
                              })
                            }
                          >
                            新增
                            <FontAwesomeIcon
                              className="my-auto px-1"
                              icon={faCirclePlus}
                            />
                          </Button>
                        </Col>
                      </Row>
                      {(ctp[campaign.campaign_id] || []).map((tc, j) => (
                        <Row key={j} className="w-100 flex-nowrap">
                          <Col
                            className="d-flex Dropdown-border-outline DropdownFullWidth"
                            xs={2}
                          >
                            <SelectBar
                              setting={{
                                method: (e) => {
                                  setctp({
                                    ...ctp,
                                    [campaign.campaign_id]: ctp[
                                      campaign.campaign_id
                                    ].map((c, k) =>
                                      j === k
                                        ? {
                                            ...c,
                                            type: e.target.value,
                                          }
                                        : c
                                    ),
                                  })
                                },
                                value: tc.type,
                                placeholder: '選擇',
                                content: [
                                  {
                                    name: '案號',
                                    value: 'project_id',
                                  },
                                  {
                                    name: '服務費AC',
                                    value: 'AC',
                                  },
                                  {
                                    name: 'Markup',
                                    value: 'Markup',
                                  },
                                  {
                                    name: '媒體回饋金',
                                    value: 'media_return',
                                  },
                                  {
                                    name: '公司回饋金',
                                    value: 'campany_return',
                                  },
                                ],
                              }}
                            />
                          </Col>
                          <Col className="d-flex" xs={7}>
                            <LucaDatePicker
                              setting={{
                                iconClassName: 'text-luca',
                                since: tc.since
                                  ? moment(tc.since).toDate()
                                  : Date.now(),
                                until: tc.until
                                  ? moment(tc.until).toDate()
                                  : Date.now(),
                                setSince: (date) => {
                                  setctp({
                                    ...ctp,
                                    [campaign.campaign_id]: ctp[
                                      campaign.campaign_id
                                    ].map((c, k) =>
                                      j === k
                                        ? {
                                            ...c,
                                            since: date,
                                          }
                                        : c
                                    ),
                                  })
                                },
                                setUntil: (date) => {
                                  setctp({
                                    ...ctp,
                                    [campaign.campaign_id]: ctp[
                                      campaign.campaign_id
                                    ].map((c, k) =>
                                      j === k
                                        ? {
                                            ...c,
                                            until: date,
                                          }
                                        : c
                                    ),
                                  })
                                },
                              }}
                            />
                          </Col>
                          <Col className="d-flex" xs={2}>
                            <Form.Control
                              className="my-auto"
                              size="sm"
                              value={tc.value}
                              onChange={(e) => {
                                setctp({
                                  ...ctp,
                                  [campaign.campaign_id]: ctp[
                                    campaign.campaign_id
                                  ].map((c, k) =>
                                    j === k
                                      ? {
                                          ...c,
                                          value: e.target.value,
                                        }
                                      : c
                                  ),
                                })
                              }}
                            />
                          </Col>
                          <Col className="d-flex" xs={1}>
                            <FontAwesomeIcon
                              style={{ cursor: 'pointer' }}
                              className="m-auto align-self-center btn-lucaIcon2"
                              icon={faCircleMinus}
                              onClick={() =>
                                setctp({
                                  ...ctp,
                                  [campaign.campaign_id]: ctp[
                                    campaign.campaign_id
                                  ].filter((c, k) => j !== k),
                                })
                              }
                              title="移除此規則"
                            />
                          </Col>
                        </Row>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div className="d-flex">
            <LoadingButton
              className="ms-auto"
              variant="lucaLight"
              onClick={async (e) => {
                await handleSettingEdit(e)
                clickRef.current.click()
              }}
              btnText="確 定"
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function FieldsSelect({ setting }) {
  const { fields, setfields } = setting

  const [search, setsearch] = useState('')

  const filteredItems = useMemo(
    () => fields.filter(({ label }) => !search || label.includes(search)),
    [fields, search]
  )

  const onDragEnd = (result) => {
    setfields((prevState) => {
      const newState = Array.from(prevState)
      const startIndex = newState.findIndex(
        (f) => f.index === fields[result.source.index].index
      )
      const endIndex = newState.findIndex(
        (f) => f.index === fields[result.destination.index].index
      )
      const [removed] = newState.splice(startIndex, 1)
      newState.splice(endIndex, 0, removed)
      return newState
    })
  }
  const grid = 0
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    width: '100%',
  })
  const getItemStyle = (isDragging, draggableStyle) => ({
    // userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    cursor: isDragging ? 'grab' : 'pointer',
    ...draggableStyle,
  })

  return (
    <Dropdown className="my-auto">
      <Dropdown.Toggle className="select-dropdown" id="dropdown-basic">
        顯示欄位
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="px-3"
        style={{
          width: '300px',
        }}
      >
        <div className="mb-2">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="my-2 w-96 border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="搜 尋 欄 位..."
              aria-label="搜 尋 欄 位..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋 欄 位"
              value={search}
              onChange={(event) => setsearch(event.target.value)}
            />
          </InputGroup>
          <div className="d-flex">
            <ListGroup
              id="scrollTarget"
              className="selectGroup scrollbarShow"
              style={{ maxHeight: '25rem', overflowY: 'auto' }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="1" direction="vertical">
                  {(dropProvided, dropSnapshot) => (
                    <div
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                      style={getListStyle(dropSnapshot.isDraggingOver)}
                      className="w-100 d-flex flex-column"
                    >
                      {filteredItems.map(({ index, ...item }, position) => (
                        <Draggable
                          key={`${index}`}
                          draggableId={`${index}`}
                          index={position}
                          onClick={() => console.log('this works')}
                        >
                          {(dragProvided, dragSnapshot) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              // style={getItemStyle(
                              //   dragSnapshot.isDragging,
                              //   dragProvided.draggableProps.style
                              // )}
                              className="fs-7 p-0 d-flex flex-column flex-grow-1 mx-0 selectGroup"
                              style={{
                                cursor: 'pointer',
                                ...getItemStyle(
                                  dragSnapshot.isDragging,
                                  dragProvided.draggableProps.style
                                ),
                              }}
                              onClick={() => {
                                setfields((prevState) =>
                                  prevState.map((p) =>
                                    index === p.index
                                      ? { ...p, show: !p.show }
                                      : p
                                  )
                                )
                              }}
                              aria-hidden
                            >
                              <ListGroupItem
                                className="d-flex"
                                style={{
                                  minHeight: '2.5rem',
                                  height: '2.5rem',
                                  width: '300px',
                                  // maxWidth: '20rem',
                                }}
                                key={item.label}
                                active={item.show}
                              >
                                <span className="App-oneLineEllipsis">
                                  {item.label}
                                </span>
                              </ListGroupItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ListGroup>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function AccountSelect({ setting }) {
  const {
    users,
    adaccounts,
    selectedAdAccounts,
    handleAdAccountClick,
    handleSettingEdit,
  } = setting

  const [costSetting, setcostSetting] = useState({
    accounts: [],
    users: [],
  })
  const getCostSetting = async () => {
    const res = await apiService.data({
      path: `luca/user/costSettings`,
      method: 'get',
    })
    setcostSetting(res)
  }
  useEffect(() => {
    getCostSetting()
  }, [])
  const [search, setsearch] = useState('')
  console.log(setsearch)
  const [loaded, setloaded] = useState(100)

  const filteredItems = useMemo(
    () =>
      adaccounts
        ? adaccounts
            .filter((item) => !search || item.name.includes(search))
            .map((item) => ({
              ...item,
              id: item.setting.account_id,
            }))
        : [],
    [(adaccounts, search)]
  )

  const clickRef = useRef(null)

  return (
    <Dropdown className="my-auto select-default">
      <Dropdown.Toggle
        ref={clickRef}
        className="select-dropdown"
        id="dropdown-basic"
      >
        廣告帳號
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="ps-3 pe-2"
        style={{
          width: '1000px',
        }}
      >
        <div className="mb-2">
          <Row className="d-flex w-100 h-100">
            <Col xs={6} style={{ borderRight: '1px dashed #7db8b2' }}>
              <Row className="mt-1 mb-2">
                <Col xs={6} className="d-flex px-0">
                  <h6 className="my-auto text-luca fw-bold ps-3">未選擇</h6>
                </Col>
                <Col xs={4} className="d-flex px-0">
                  <SearchBar
                    setting={{
                      title: '關鍵字搜尋...',
                      name: 'search',
                      id: 'defaultBorder',
                      // action: <FontAwesomeIcon icon={faSearch} />,
                      tempSearch: search,
                      settempSearch: setsearch,
                      method: () => {},
                      hasIcon: false,
                    }}
                  />
                </Col>
                <Col xs={2} className="d-flex px-1">
                  <div className="w-100">
                    <Button
                      className="ms-1"
                      variant="luca"
                      onClick={() => {}}
                      title="選取全部"
                    >
                      全選
                    </Button>
                    {/* <Button
                      className="mx-1"
                      variant="secondary"
                      onClick={() => {}}
                      title="取消選取全部"
                    >
                      清除
                    </Button> */}
                  </div>
                </Col>
              </Row>
              <ListGroup
                id="scrollTarget"
                className="selectGroup scrollbarShow w-100"
                style={{ maxHeight: '25rem', overflowY: 'auto' }}
              >
                <InfiniteScroll
                  dataLength={loaded}
                  next={() => setloaded(loaded + 20)}
                  hasMore={loaded < adaccounts.length}
                  loader={<div />}
                  endMessage={<p />}
                  scrollableTarget="scrollTarget"
                >
                  {filteredItems
                    .filter((item) => !selectedAdAccounts.includes(item.id))
                    .slice(0, loaded)
                    .map((item) => (
                      <Row
                        className="w-100 accountSelector"
                        style={{
                          height: '3.25rem',
                        }}
                        onClick={() => handleAdAccountClick([item.id])}
                      >
                        <Col
                          className="d-flex"
                          xs={5}
                          style={{ height: '3.25rem' }}
                        >
                          {item.provider && (
                            <span className="my-auto me-2">
                              {getOriginIcon(item.provider, '22px', 'auto')}
                            </span>
                          )}
                          <span className="App-oneLineEllipsis my-auto">
                            {item.name}
                          </span>
                        </Col>
                        <Col
                          className="scrollbarShowBottom d-flex flex-nowrap"
                          style={{ overflowX: 'auto', overflowY: 'hidden' }}
                          xs={6}
                        >
                          {(
                            costSetting.accounts[item.setting.account_id] || []
                          ).map((user_id) =>
                            costSetting.users[user_id] ? (
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    className="quesTip"
                                    style={{
                                      width: '300px',
                                      zIndex: '9999',
                                    }}
                                  >
                                    {costSetting.users[user_id].map(
                                      (account_id) => (
                                        <Row className="flex-nowrap">
                                          <span className="text-nowrap">
                                            {
                                              adaccounts.find(
                                                (adaccount) =>
                                                  adaccount.setting
                                                    .account_id === account_id
                                              )?.name
                                            }
                                          </span>
                                        </Row>
                                      )
                                    )}
                                  </Tooltip>
                                }
                              >
                                <Badge
                                  key={user_id}
                                  className="user-badge py-2 px-2 m-auto text-lucaDark position-relative"
                                  bg="lucaMid2"
                                >
                                  {
                                    users.find(
                                      (user) => user.user_id === user_id
                                    )?.name
                                  }
                                  <FontAwesomeIcon
                                    className="ms-auto my-auto align-self-center position-absolute"
                                    style={{
                                      cursor: 'pointer',
                                      top: '-10px',
                                      right: '-10px',
                                      width: '20px',
                                      height: '20px',
                                      display: 'none',
                                    }}
                                    icon={faCirclePlus}
                                    title="加入該使用者的所有帳號"
                                    onClick={(e) => {
                                      handleAdAccountClick(
                                        costSetting.users[user_id]
                                      )
                                      e.stopPropagation()
                                    }}
                                  />
                                </Badge>
                              </OverlayTrigger>
                            ) : (
                              <Badge
                                key={user_id}
                                className="py-2 px-2 m-auto text-lucaDark"
                                bg="lucaMid2"
                              >
                                {
                                  users.find((user) => user.user_id === user_id)
                                    ?.name
                                }
                              </Badge>
                            )
                          )}
                        </Col>
                        <Col className="d-flex" xs={1}>
                          <FontAwesomeIcon
                            style={{ color: 'transparent', cursor: 'pointer' }}
                            className="ms-auto my-auto align-self-center"
                            icon={faPlus}
                            title="選 擇 ／ 新 增 廣 告 帳 號"
                          />
                        </Col>
                      </Row>
                    ))}
                </InfiniteScroll>
              </ListGroup>
            </Col>
            <Col xs={6} className="pe-0">
              <Row className="mt-1 mb-2">
                <Col xs={6} className="d-flex px-0">
                  <h6 className="my-auto text-luca fw-bold ps-3">已選擇</h6>
                </Col>
                <Col xs={4} className="d-flex px-0">
                  <SearchBar
                    setting={{
                      title: '關鍵字搜尋...',
                      name: 'search',
                      id: 'defaultBorder',
                      // action: <FontAwesomeIcon icon={faSearch} />,
                      tempSearch: '',
                      settempSearch: '',
                      method: () => {},
                      hasIcon: false,
                    }}
                  />
                </Col>
                <Col xs={2} className="d-flex px-0">
                  <div className="w-100">
                    {/* <Button
                      className="ms-1"
                      variant="luca"
                      onClick={() => {}}
                      title="選取全部"
                    >
                      全選
                    </Button> */}
                    <Button
                      className="ms-1"
                      variant="secondary"
                      onClick={() => {}}
                      title="取消選取全部"
                    >
                      清除
                    </Button>
                  </div>
                </Col>
              </Row>
              <ListGroup
                id="scrollTarget"
                className="selectGroup scrollbarShow w-100"
                style={{ maxHeight: '25rem', overflowY: 'auto' }}
              >
                {adaccounts
                  .filter((item) =>
                    selectedAdAccounts.includes(item.setting.account_id)
                  )
                  .map((item) => (
                    <Row
                      className="w-100 accountSelector"
                      style={{
                        height: '3.25rem',
                      }}
                      onClick={() =>
                        handleAdAccountClick([item.setting.account_id], false)
                      }
                    >
                      <Col
                        className="d-flex"
                        xs={5}
                        style={{ height: '3.25rem' }}
                      >
                        {item.provider && (
                          <span className="my-auto me-2">
                            {getOriginIcon(item.provider, '22px', 'auto')}
                          </span>
                        )}
                        <span className="App-oneLineEllipsis my-auto">
                          {item.name}
                        </span>
                      </Col>
                      <Col
                        className="scrollbarShowBottom d-flex flex-nowrap"
                        style={{ overflowX: 'auto', overflowY: 'hidden' }}
                        xs={6}
                      >
                        {(
                          costSetting.accounts[item.setting.account_id] || []
                        ).map((user_id) =>
                          costSetting.users[user_id] ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  className="costTip"
                                  style={{
                                    zIndex: '9999',
                                  }}
                                >
                                  {costSetting.users[user_id].map(
                                    (account_id) => (
                                      <Row>
                                        {
                                          adaccounts.find(
                                            (adaccount) =>
                                              adaccount.setting.account_id ===
                                              account_id
                                          )?.name
                                        }
                                      </Row>
                                    )
                                  )}
                                </Tooltip>
                              }
                            >
                              <Badge
                                key={user_id}
                                className="user-badge py-2 px-2 m-auto text-lucaDark position-relative"
                                bg="lucaMid2"
                              >
                                {
                                  users.find((user) => user.user_id === user_id)
                                    ?.name
                                }
                                <FontAwesomeIcon
                                  className="ms-auto my-auto align-self-center position-absolute"
                                  style={{
                                    cursor: 'pointer',
                                    top: '-10px',
                                    right: '-10px',
                                    width: '20px',
                                    height: '20px',
                                    display: 'none',
                                  }}
                                  icon={faCircleMinus}
                                  title="移除該使用者的所有帳號"
                                  onClick={(e) => {
                                    handleAdAccountClick(
                                      costSetting.users[user_id],
                                      false
                                    )
                                    e.stopPropagation()
                                  }}
                                />
                              </Badge>
                            </OverlayTrigger>
                          ) : (
                            <Badge
                              key={user_id}
                              className="py-2 px-2 m-auto text-lucaDark"
                              bg="lucaMid2"
                            >
                              {
                                users.find((user) => user.user_id === user_id)
                                  ?.name
                              }
                            </Badge>
                          )
                        )}
                      </Col>
                      <Col className="d-flex" xs={1}>
                        <FontAwesomeIcon
                          style={{ color: 'transparent', cursor: 'pointer' }}
                          className="ms-auto my-auto align-self-center"
                          icon={faMinus}
                          title="取 消 選 擇 廣 告 帳 號"
                        />
                      </Col>
                    </Row>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </div>
        <div className="d-flex">
          <LoadingButton
            className="ms-auto"
            variant="lucaLight"
            onClick={async () => {
              await handleSettingEdit()
              console.log('should waited')
              clickRef.current.click()
              getCostSetting()
            }}
            btnText="套用"
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function MediaCost(props) {
  const { setting } = props
  const { show, handleClose } = setting
  const auth = useContext(AuthContext)
  const [adaccounts, setadaccounts] = useState([])
  const [users, setusers] = useState([])

  const [timeRange, settimeRange] = useState({
    since: moment().startOf('year').toDate(),
    until: moment().toDate(),
  })
  const { since, until } = timeRange

  // filter settings
  const [costSetting, setcostSetting] = useState(
    auth.auth.setting.costSetting || {
      filterType: 'adaccounts',
      selectedAdAccounts: [],
      ctp: {},
      custom: [],
      fields: defaultFields,
    }
  )

  const [custom, setcustom] = useState(costSetting.custom || [])
  if (false) console.log(setcustom)
  const fixedCtp = {}
  Object.keys(costSetting.ctp || {}).forEach((key) => {
    fixedCtp[key] = (costSetting.ctp || {})[key].map
      ? (costSetting.ctp || {})[key]
      : []
  })
  const [ctp, setctp] = useState(fixedCtp)
  const [fields, setfields] = useState(costSetting.fields || defaultFields)

  const [selectedAdAccounts, setselectedAdAccounts] = useState(
    costSetting.selectedAdAccounts || []
  )
  const handleAdAccountClick = (ids, add = true) => {
    setselectedAdAccounts((prevState) => {
      const merged = add
        ? Array.from(new Set([...prevState, ...ids]))
        : prevState.filter((p) => !ids.includes(p))
      return merged
    })
  }
  const clearAll = () => setselectedAdAccounts([])
  const [showedAccounts, setshowedAccounts] = useState(
    costSetting.selectedAdAccounts || []
  )

  const handleSettingEdit = async () => {
    await apiService.data({
      path: `luca/user/${auth.auth.user_id}`,
      method: 'put',
      data: {
        setting: JSON.stringify({
          ...auth.auth.setting,
          costSetting: {
            selectedAdAccounts,
            ctp,
            custom,
            fields,
          },
        }),
      },
    })
    setshowedAccounts(selectedAdAccounts)
    setcostSetting({
      selectedAdAccounts,
      ctp,
      custom,
      fields,
    })
  }

  useEffect(() => handleSettingEdit(), [fields])

  // preparing data
  const [campaigns, setcampaigns] = useState([])
  const getCampaigns = async () => {
    const res = await apiService.data({
      path: `luca/insight/campaigns`,
      method: 'get',
      params: {
        adaccounts: showedAccounts,
        since,
        until,
      },
    })
    setcampaigns(res)
  }
  const [invoices, setinvoices] = useState([])
  const getInvoices = async () => {
    const res = await apiService.data({
      path: `luca/insight/invoice`,
      method: 'get',
    })
    setinvoices(res)
  }
  useEffect(() => {
    getInvoices()
  }, [])

  useEffect(() => {
    getCampaigns()
  }, [showedAccounts])

  const [erp, seterp] = useState([])
  const getErp = async (params) => {
    const res = await apiService.data({
      path: `luca/insight/erp`,
      method: 'get',
      params,
    })
    seterp(res[1])
    getCampaigns()
  }
  useEffect(() => {
    if (false)
      getErp({
        since: moment(since).format('yyyy-MM-DD'),
        until: moment(until).format('yyyy-MM-DD'),
      })
  }, [since, until])

  const [revenue, setrevenue] = useState(false)
  const getRevenue = async () => {
    setrevenue(false)
    const res = await apiService.data({
      path: `luca/insight/revenue`,
      method: 'post',
      data: {
        ctp,
        adaccounts: showedAccounts,
        since: moment(since).format('yyyy-MM-DD'),
        until: moment(until).format('yyyy-MM-DD'),
      },
    })
    console.log(res)
    if (revenue && !revenue.error) setrevenue(res)
  }
  useEffect(() => {
    if (showedAccounts.length) getRevenue()
  }, [since, until, showedAccounts, costSetting])

  const [sheetType, setsheetType] = useState('erp')

  // const [costsErp, setcostsErp] = useState([])
  // const getcostsErp = async (params) => {
  //   const res = await apiService.data({
  //     path: `luca/insight/costErp`,
  //     method: 'get',
  //     params,
  //   })
  //   setcostsErp(res)
  // }
  // useEffect(() => {
  //   if (sheetType === 'allcosts') {
  //     getcostsErp({
  //       since: moment(since).format('yyyy-MM-DD'),
  //       until: moment(until).format('yyyy-MM-DD'),
  //     })
  //   }
  // }, [sheetType, since, until])

  useEffect(() => {
    const getAll = async () => {
      const allAdAccounts = await apiService.data({
        path: `luca/account`,
        method: 'get',
      })
      setadaccounts(allAdAccounts.accounts)
      const allUsers = await apiService.data({
        path: `luca/user`,
        method: 'get',
      })
      setusers(allUsers.users)
    }
    getAll()
  }, [])

  const [filter, setfilter] = useState({
    platform: '',
    month: '',
  })
  const { platform, month } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
    })

  const sheet = useMemo(() => {
    const empty = Array.from({
      length: 16,
    }).map(() => '')

    const cheader = fields
      .filter((f) => f.show)
      .map((f) => f.label)
      .concat(['總花費'])
      .concat(custom.map((c) => c.name))
      .concat(['備註'])

    const mergedCampaigns = {}
    campaigns
      .filter(
        (c) =>
          !showedAccounts.length ||
          showedAccounts.includes(`${c.account_id}`.replace('act_', ''))
      )
      .forEach((campaign) => {
        const id = campaign.campaign_id || campaign.id
        const name = campaign.campaign_name || campaign.name
        const project =
          name.indexOf('【') !== -1
            ? name.substring(name.indexOf('【') + 1, name.indexOf('】'))
            : 'no project name'
        if (mergedCampaigns[id]) {
          const merged = {}
          Object.keys(mergedCampaigns[id]).forEach((key) => {
            if (Number.isFinite(parseFloat(mergedCampaigns[id][key])))
              merged[key] =
                parseFloat(mergedCampaigns[id][key]) + parseFloat(campaign[key])
            else merged[key] = mergedCampaigns[id][key]
          })
          mergedCampaigns[id] = merged
        } else {
          mergedCampaigns[id] = {
            ...campaign,
            campaign_name: name,
            campaign_id: id,
            media: campaign.provider,
            account_id: campaign.account_id,
            account_name: campaign.account_name,
            project,
          }
        }
      })
    const all = []
    Object.keys(mergedCampaigns).forEach((key) =>
      all.push(mergedCampaigns[key])
    )

    if (sheetType === 'media') {
      const projects = all.map((t) => {
        const formatted = [
          t.media,
          t.account_name,
          t.account_id,
          t.spend,
          t.currency,
          t.project,
          t.campaign_name,
          t.campaign_id,
          t.impressions || t['metric.impressions'],
          t.clicks || t['metric.clicks'],
          t.reach || t['metric.reachs'],
        ]
        const target = erp.find(
          (e) => e[1] === t.project || e[1] === ctp[t.campaign_id]
        )
        const origin = (target || empty).concat(formatted)
        const formattedCustom = custom.map(({ path }) => {
          const culc = path.reduce((prevC, curC, j) => {
            if (curC.type === 'text') {
              if (!path[j - 1]) return parseInt(curC.target, 10)
              if (path[j - 1] && path[j - 1].operator === '+')
                return prevC + parseInt(curC.value, 10)
              if (path[j - 1] && path[j - 1].operator === '-')
                return prevC - parseInt(curC.value, 10)
              if (path[j - 1] && path[j - 1].operator === '*')
                return prevC * parseInt(curC.value, 10)
              if (path[j - 1] && path[j - 1].operator === '/')
                return prevC / parseInt(curC.value, 10)
            }
            if (curC.type === 'field') {
              const index = fields.findIndex((f) => f.label === curC.target)
              if (!path[j - 1]) return parseInt(origin[index], 10)
              if (path[j - 1] && path[j - 1].operator === '+')
                return prevC + parseInt(origin[index], 10)
              if (path[j - 1] && path[j - 1].operator === '-')
                return prevC - parseInt(origin[index], 10)
              if (path[j - 1] && path[j - 1].operator === '*')
                return prevC * parseInt(origin[index], 10)
              if (path[j - 1] && path[j - 1].operator === '/')
                return prevC / parseInt(origin[index], 10)
            }
            return prevC
          }, 0)
          return culc
        })
        return origin.concat(formattedCustom)
      })
      return [cheader].concat(projects)
    }

    if (sheetType === 'cost') {
      const projects = all
        .filter((t) => !platform || t.media === platform)
        .map((t) => {
          const formatted = [
            t.media,
            t.account_name,
            t.campaign_name,
            // t.campaign_id,
            // t.account_id,
            t.spend,
            // t.currency,
            t.project,
            t.impressions || t['metric.impressions'],
            t.clicks || t['metric.clicks'],
            t.reach || t['metric.reachs'],
          ]
          return formatted
        })
      const header = [
        '媒體',
        '帳戶名稱',
        '活動名稱',
        // '帳戶ID',
        '花費',
        // '幣別',
        '案號',
        // '活動ID',
        '曝光',
        '點擊',
        '觸及',
      ]
      return [header].concat(projects)
    }
    if (sheetType === 'account_cost') {
      const reduced = all
        .filter((t) => !platform || t.media === platform)
        .reduce(
          (prevt, curt) =>
            prevt.find((p) => p.account_id === curt.account_id)
              ? prevt.map((p) =>
                  p.account_id === curt.account_id
                    ? {
                        ...p,
                        spend: parseFloat(p.spend) + parseFloat(curt.spend),
                        clicks: parseFloat(p.clicks) + parseFloat(curt.clicks),
                        impressions:
                          parseFloat(p.impressions) +
                          parseFloat(curt.impressions),
                      }
                    : p
                )
              : [...prevt, curt],
          []
        )
      const projects = reduced.map((t) => {
        const formatted = [
          t.media,
          t.account_name,
          // t.campaign_name,
          // t.campaign_id,
          // t.account_id,
          t.spend,
          // t.currency,
          // t.project,
          t.impressions || t['metric.impressions'],
          t.clicks || t['metric.clicks'],
          t.reach || t['metric.reachs'],
        ]
        return formatted
      })
      const header = [
        '媒體',
        '帳戶名稱',
        // '活動名稱',
        // '帳戶ID',
        '花費',
        // '幣別',
        // '案號',
        // '活動ID',
        '曝光',
        '點擊',
        '觸及',
      ]
      return [header].concat(projects)
    }

    return []
  }, [
    fields,
    // costs,
    // costSetting,
    // invoices,
    custom,
    sheetType,
    erp,
    campaigns,
    platform,
  ])

  const [AllCostfile] = useState([])

  const createFile = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('利潤表')
    if (sheetType === 'allcosts') {
      AllCostfile.forEach((row) => worksheet.addRow(row))
    } else {
      sheet.forEach((row) => worksheet.addRow(row))
    }

    const file = await workbook.xlsx.writeBuffer({
      base64: true,
    })
    const data = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `消耗量.xlsx`)
    document.body.appendChild(link)
    link.click()
  }

  const campaignsForFix = useMemo(() => {
    if (!revenue) return []
    const names = {}
    const accountnames = {}
    const temp = Object.keys(revenue).reduce(
      (prev, cur) => [
        ...prev,
        ...revenue[cur].campaigns.map(
          ({ account_name, name, id, campaign_name, campaign_id }) => {
            names[id || campaign_id] = name || campaign_name || ''
            accountnames[id || campaign_id] = account_name
            return id || campaign_id
          }
        ),
      ],
      []
    )
    return Array.from(new Set(temp)).map((campaign_id) => ({
      campaign_id,
      campaign_name: names[campaign_id],
      account_name: accountnames[campaign_id],
      projects: [],
      returns: [],
    }))
  }, [revenue])

  const [inspect, setinspect] = useState({})
  const handleInspect = (e) =>
    setinspect({
      ...inspect,
      [e]: !inspect[e],
    })

  const inspected = useMemo(() => {
    console.log(revenue)
    // const departs = {

    // }
    if (revenue) {
      const arrayed = Object.keys(revenue)
        .filter((key) => key !== 'np')
        .map((key) => revenue[key])
      console.log(users)
      const dtu = users.reduce(
        (prev, cur) => ({
          ...prev,
          [cur.name]: cur.setting.depart,
        }),
        {}
      )
      return {
        依AE: Object.entries(
          arrayed.reduce(
            (prev, cur) => ({
              ...prev,
              [cur.PDL01C]: [...(prev[cur.PDL01C] || []), ...cur.campaigns],
            }),
            {}
          )
        ).map(([label, cs]) => ({
          label,
          spend: cs.reduce((prev, cur) => prev + parseFloat(cur.spend), 0),
        })),
        依AM: Object.entries(
          arrayed.reduce(
            (prev, cur) => ({
              ...prev,
              [cur.PDL02C]: [...(prev[cur.PDL02C] || []), ...cur.campaigns],
            }),
            {}
          )
        ).map(([label, cs]) => ({
          label,
          spend: cs.reduce((prev, cur) => prev + parseFloat(cur.spend), 0),
        })),
        依客戶: Object.entries(
          arrayed.reduce(
            (prev, cur) => ({
              ...prev,
              [cur.PARTNER_NAME]: [
                ...(prev[cur.PARTNER_NAME] || []),
                ...cur.campaigns,
              ],
            }),
            {}
          )
        ).map(([label, cs]) => ({
          label,
          spend: cs.reduce((prev, cur) => prev + parseFloat(cur.spend), 0),
        })),
        依部門: Object.entries(
          arrayed.reduce(
            (prev, cur) => ({
              ...prev,
              [dtu[cur.PDL02C]]: [
                ...(prev[dtu[cur.PDL02C]] || []),
                ...cur.campaigns,
              ],
            }),
            {}
          )
        ).map(([label, cs]) => ({
          label,
          spend: cs.reduce((prev, cur) => prev + parseFloat(cur.spend), 0),
        })),
      }
    }
    return {
      依AE: [],
      依AM: [],
      依客戶: [],
      依部門: [],
    }
  }, [revenue])

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      fullscreen
      onHide={handleClose}
    >
      <Modal.Header closeButton className="text-lucaDark h5">
        <span className="fw-bold">媒體消耗量</span>
      </Modal.Header>
      <Modal.Body className="px-4 pt-3 pb-3">
        <Row
          style={{
            height: '2rem',
          }}
        >
          <div className="d-flex">
            <Form.Check
              className="small flex-fill my-auto"
              label="依專案表（程數部）"
              checked={sheetType === 'erp'}
              onChange={() => setsheetType('erp')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="依Google sheet（程數部）"
              checked={sheetType === 'google'}
              onChange={() => setsheetType('google')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="依實際花費（整合部）"
              checked={sheetType === 'media'}
              onChange={() => setsheetType('media')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="活動消耗量"
              checked={sheetType === 'cost'}
              onChange={() => setsheetType('cost')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="帳戶消耗量"
              checked={sheetType === 'account_cost'}
              onChange={() => setsheetType('account_cost')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="對帳表"
              checked={sheetType === 'allcosts'}
              onChange={() => setsheetType('allcosts')}
            />
            <Form.Check
              className="small flex-fill my-auto"
              label="粗估收益"
              checked={sheetType === 'invoices'}
              onChange={() => setsheetType('invoices')}
            />
          </div>
        </Row>
        {sheetType !== 'google' && sheetType !== 'allcosts' && (
          <Row
            className="mx-0"
            style={{
              height: '3rem',
            }}
          >
            {['allcosts', 'invoices'].includes(sheetType) ? (
              <Col
                xs={6}
                className="px-0 d-flex"
                style={{
                  zIndex: '100',
                }}
              >
                <span className="my-auto">月份：</span>
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    method: handleFilterChange,
                    name: 'month',
                    value: month,
                    placeholder: '未選擇',
                    content: [
                      { name: '1月', value: '202401' },
                      { name: '2月', value: '202402' },
                      { name: '3月', value: '202403' },
                      { name: '4月', value: '202404' },
                      { name: '5月', value: '202405' },
                      { name: '6月', value: '202406' },
                      { name: '7月', value: '202407' },
                    ],
                  }}
                />
              </Col>
            ) : (
              <Col
                xs={6}
                className="px-0 d-flex"
                style={{
                  zIndex: '100',
                }}
              >
                <span className="my-auto">日期區間：</span>
                <LucaDatePicker
                  setting={{
                    iconClassName: 'text-luca',
                    since,
                    until,
                    setSince: (date) => {
                      settimeRange({ ...timeRange, since: date })
                    },
                    setUntil: (date) => {
                      settimeRange({ ...timeRange, until: date })
                    },
                  }}
                />
              </Col>
            )}
            {['cost', 'account_cost'].includes(sheetType) && (
              <Col xs="auto" className="px-1 d-flex p-1 select-default">
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    method: handleFilterChange,
                    name: 'platform',
                    value: platform,
                    placeholder: '媒體平台',
                    content: [
                      { name: 'Facebook', value: 'facebook' },
                      { name: 'Google', value: 'google' },
                      { name: 'Twitter', value: 'twitter' },
                      { name: 'Criteo', value: 'criteo' },
                      { name: 'LinkedIn', value: 'linkedin' },
                    ],
                  }}
                />
              </Col>
            )}
            <Col xs="auto" className="px-1 d-flex ms-auto">
              <Dropdown className="my-auto select-default" align="end">
                <Dropdown.Toggle
                  className="select-dropdown"
                  id="dropdown-basic"
                >
                  分析設定
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="px-3"
                  style={{
                    width: '500px',
                    height: '30vh',
                  }}
                >
                  <ListGroup>
                    {['依AE', '依AM', '依客戶', '依部門'].map((e) => (
                      <ListGroupItem
                        action
                        active={inspect[e]}
                        onClick={() => handleInspect(e)}
                      >
                        {e}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {['erp', 'media'].includes(sheetType) && (
              <Col xs="auto" className="px-1 d-flex">
                <Fix
                  setting={{
                    ctp,
                    setctp,
                    revenue,
                    handleSettingEdit,
                    campaigns: campaignsForFix,
                  }}
                />
              </Col>
            )}
            <Col xs="auto" className="px-1 d-flex">
              <AccountSelect
                setting={{
                  adaccounts,
                  users,
                  selectedAdAccounts,
                  handleAdAccountClick,
                  handleSettingEdit,
                  clearAll,
                }}
              />
            </Col>
            <Col
              xs={2}
              className="d-flex my-auto ps-1 pe-0"
              style={{ zIndex: '150' }}
            >
              <Button
                title="更 新"
                variant="luca"
                className="w-100 me-1"
                onClick={getCampaigns}
              >
                更新
              </Button>
              <Button
                title="下 載"
                variant="luca"
                className="w-100"
                onClick={createFile}
                // disabled={!costs || costs.refreshing}
              >
                下載
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          {Object.keys(inspect)
            .filter((key) => inspect[key])
            .map((key) => (
              <Col key={key}>
                <Table className="text-lucaDark ps-1 costtable overflow-scroll">
                  <thead>
                    <tr>
                      <th>{key.replace('依', '')}</th>
                      <th>花費</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspected[key].map((r, i) => (
                      <tr key={i}>
                        <td>{r.label}</td>
                        <td>${r.spend.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ))}
        </Row>
        {sheetType === 'google' ? (
          <Row
            style={{
              height: '75vh',
            }}
          >
            <iframe
              title="sheet"
              src="https://docs.google.com/spreadsheets/d/1fyjjGYKhAtckC3lXwwF1N7s1sYuxBT7L9PcVgf5jZpY/edit#gid=1985257592"
            />
          </Row>
        ) : sheetType === 'allcosts' ? (
          // <AllCostsTable
          //   setting={{
          //     invoices,
          //     costsErp,
          //     month: filter.month,
          //     setFile: setAllCostfile,
          //   }}
          // />
          <iframe
            width="100%"
            height="100%"
            title="allcosts"
            src="https://alert.punwave.com?q=1&t=wave"
          />
        ) : sheetType === 'invoices' ? (
          <InvoiceTable
            setting={{
              invoices,
            }}
          />
        ) : campaigns && revenue ? (
          sheetType === 'erp' ? (
            // revenue.np &&
            // revenue.np.campaigns &&
            // revenue.np.campaigns.length
            false ? (
              <div style={{ height: '73vh' }} className="py-3">
                <Row className="fs-5 my-auto py-3 px-3 fw-bold text-luca">
                  <div className="px-0">
                    <FontAwesomeIcon
                      style={{ color: '#317985', width: '20px' }}
                      className="fs-5 fw-bolder my-auto me-1"
                      icon={faCircleExclamation}
                    />
                    系統通知: 以下 2 則廣告帳號 （共含
                    {revenue.np.campaigns.length} 筆資料）
                    尚未完成資料對應，請點擊上方「規則設定」進行分配。
                  </div>
                </Row>
                <Row className="pb-5 px-3">
                  {revenue.np.campaigns
                    .reduce(
                      (prev, cur) =>
                        prev.includes(cur.name) ? prev : [...prev, cur.name],
                      []
                    )
                    .map((n) => (
                      <Row>{n}</Row>
                    ))}
                </Row>
              </div>
            ) : (
              <TrueCostsTable
                setting={{
                  revenue,
                  costSetting,
                  since,
                  until,
                }}
              />
            )
          ) : (
            <CostsTable
              setting={{
                search: '',
                sheet: [...sheet],
                // type: active,
                fields: fields.filter((f) => f.show),
                setfields,
                sheetType,
              }}
            />
          )
        ) : (
          <Row
            className="m-auto"
            style={{
              height: '73vh',
            }}
          >
            <div className="m-auto d-flex my-5">
              {showedAccounts.length ? (
                <>
                  <Spinner
                    className="ms-auto my-auto"
                    animation="border"
                    variant="luca"
                    size="sm"
                    style={{
                      animation: 'spinner-border 1.5s linear infinite',
                    }}
                  />
                  <h5 className="text-luca my-auto me-auto ms-2">
                    資料載入中...
                  </h5>
                </>
              ) : (
                <h5 className="text-lucaLight my-auto mx-auto">
                  請先選擇廣告帳號
                </h5>
              )}
            </div>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  )
}

Custom.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Fix.propTypes = {
  setting: PropTypes.shape().isRequired,
}

FieldsSelect.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AccountSelect.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MediaCost.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MediaCost
