import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import fv from '../services/formValidation'

function RegisterForm(props) {
  const { setting } = props
  const { fields, onDataChange, registerData, handleRegister } = setting
  const names = {
    Name: '姓 名',
    Department: '所 屬 單 位',
    Email: '帳 號',
    Password: '密 碼',
  }

  const fvError = fv.validate(fields, registerData)
  const [touched, settouched] = useState([])

  return (
    <Form className="py-2 d-flex flex-column justify-content-center h-100">
      {fields.map((field, i) => {
        const error = fvError.find((e) => e.key === field.name)
        const isInvalid = error && error.error && touched.includes(i)
        return (
          <Form.Group key={field.name} className="mb-3">
            <Form.Label>{names[field.label]}</Form.Label>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                isInvalid ? (
                  <Tooltip
                    className="userTip"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    {error && error.error}
                  </Tooltip>
                ) : (
                  <Tooltip
                    className="userTip"
                    style={{
                      zIndex: '9999',
                      display: 'none',
                    }}
                  />
                )
              }
            >
              <Form.Control
                name={field.name}
                type={field.type}
                onChange={onDataChange}
                onFocus={() => settouched([...touched, i])}
                value={registerData[field.name]}
                placeholder={field.placeholder}
                isInvalid={isInvalid}
              />
            </OverlayTrigger>
          </Form.Group>
        )
      })}
      <div className="d-flex">
        <Button
          variant="luca"
          className="mx-auto mt-2"
          onClick={() => handleRegister(registerData)}
          disabled={
            Object.keys(registerData).some((key) => registerData[key] === '') ||
            fvError.some((e) => e.error)
          }
        >
          新 增
        </Button>
      </div>
    </Form>
  )
}

RegisterForm.propTypes = {
  setting: PropTypes.shape(),
}

RegisterForm.defaultProps = {
  setting: {
    fields: [
      {
        label: 'Email address',
        name: 'email',
        type: 'email',
        placeholder: 'Enter Email...',
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
        placeholder: 'Enter Password...',
      },
    ],
    onDataChange: () => {},
  },
}

export default RegisterForm
