import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Image from 'react-bootstrap/Image'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTimes,
  faAngleRight,
  faHourglassEnd,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import ManagedList from './ManagedList'
import RegisterForm from './RegisterForm'
import { accTypeGoogle, accTypeMeta } from '../assets'
import { wordSearch, getAvatar } from '../services/lucaFunctions'

function Tips(props) {
  const { setting } = props
  const { name, email, avatar_id, permission, facebook, google, createdAt } =
    setting

  return (
    <Container className="BmsManager-Hover text-light py-3">
      <Row>
        <Col xs={4} className="py-4 px-1 my-auto">
          <Row>
            <Image
              src={getAvatar({ avatarId: avatar_id, permission })}
              className="mx-auto w-50"
              alt="頭 像"
            />
          </Row>
          <Row className="d-flex justify-content-center">{name}</Row>
        </Col>
        <Col xs={8} className="text-start my-auto py-2">
          <Row className="mb-2">
            電子郵件
            <br />
            {email}
          </Row>
          <Row>
            建立時間
            <br /> {moment(createdAt).format('YYYY-MM-DD')}
          </Row>
          <Row className="AccType-sm">
            {facebook ? <Image src={accTypeMeta} /> : ''}
            {google ? <Image src={accTypeGoogle} /> : ''}
          </Row>
        </Col>
      </Row>
      {/* <hr />
      <Row className="d-flex">
        <Col xs={12} className="text-start my-auto">
          <small>{remark}</small>
        </Col>
      </Row> */}
    </Container>
  )
}

function UserList(props) {
  const { setting } = props
  const {
    items,
    assigned,
    handleAdd,
    handleDelete,
    handleUserChange,
    handleRegister,
    registerData,
    onRegisterDataChange,
    status,
  } = setting
  // search
  const [search, setsearch] = useState('')
  const [assignedSearch, setassignedSearch] = useState('')
  const assignedIds = assigned.map((a) => a.user_id)

  const filteredItems = items.filter(
    (i) =>
      (wordSearch(i.name, search) || wordSearch(i.email, search)) &&
      !assignedIds.includes(i.user_id)
  )
  const filteredAssigned = assigned.filter(
    (a) =>
      wordSearch(a.name, assignedSearch) || wordSearch(a.email, assignedSearch)
  )
  const usedEmails = items.map((i) => i.email)

  // permission key name
  const permissions = {
    isAdmin: 'ADM管理者',
    isAdsManager: '投放優化師',
    isAnalyst: '成效分析師',
    isFinance: '財務人員',
  }

  return (
    <Row className="px-0 mx-0 position-relative">
      {(status.waiting || status.time > 0) && (
        <Col
          xs={12}
          className="position-absolute h-100 flex-grow-1"
          style={{ left: '0', zIndex: '999' }}
        >
          <Modal.Dialog className="h-100 w-100 mw-100 d-flex m-0 py-3 border-0 WhiteOut">
            <Modal.Body className="d-flex">
              {status.waiting && (
                <div className="m-auto d-flex flex-column spinner-border border-0 LoadingIcon">
                  <FontAwesomeIcon
                    className="h0 mx-auto mt-auto mb-3 text-lucaLight align-self-center"
                    icon={faHourglassEnd}
                    title="新 增"
                  />
                  <h3 className="mx-auto mb-auto text-luca">{status.result}</h3>
                </div>
              )}
              {!status.waiting && status.success && (
                <div className="h-100 w-100 d-flex flex-column">
                  <FontAwesomeIcon
                    className="h0 mx-auto mt-auto mb-3 text-luca align-self-center"
                    icon={faCheckCircle}
                  />
                  <h3 className="mx-auto mb-auto text-luca">{status.result}</h3>
                </div>
              )}
              {!status.waiting && !status.success && (
                <div className="h-100 w-100 d-flex flex-column">
                  <FontAwesomeIcon
                    className="h0 mx-auto mt-auto mb-3 text-error align-self-center"
                    icon={faTimesCircle}
                  />
                  <h3 className="mx-auto mb-auto text-luca">{status.result}</h3>
                </div>
              )}
            </Modal.Body>
          </Modal.Dialog>
        </Col>
      )}
      <Col xs={3} className="flex-grow-1">
        <Modal.Dialog className="h-100 d-flex my-0 py-3">
          <Modal.Header
            id="form-dialog-title"
            className="m-auto border-0 h6 text-lucaDark"
          >
            新 增 使 用 者
          </Modal.Header>
          <Modal.Body>
            <RegisterForm
              setting={{
                fields: [
                  {
                    label: 'Name',
                    name: 'name',
                    type: 'text',
                    placeholder: 'Enter Name...',
                    required: true,
                  },
                  {
                    label: 'Department',
                    name: 'department',
                    type: 'text',
                    placeholder: 'Enter Department...',
                    required: true,
                  },
                  {
                    label: 'Email',
                    name: 'email',
                    type: 'email',
                    placeholder: 'Enter Email...',
                    required: true,
                    constraint: {
                      text: 'Email 已被使用',
                      method: (value) => !usedEmails.includes(value),
                    },
                  },
                  {
                    label: 'Password',
                    name: 'password',
                    type: 'password',
                    placeholder: 'Enter Password...',
                    required: true,
                  },
                ],
                onDataChange: onRegisterDataChange,
                registerData,
                handleRegister,
              }}
            />
          </Modal.Body>
        </Modal.Dialog>
      </Col>
      <Col className="d-flex flex-grow-0 justify-content-center">
        <FontAwesomeIcon
          className="my-auto fs-2 text-luca"
          icon={faAngleRight}
        />
      </Col>
      <Col xs={3} className="flex-grow-1">
        <Modal.Dialog className="h-100 d-flex m-0 py-3">
          <Modal.Header
            id="form-dialog-title"
            className="m-auto border-0 h6 text-lucaDark"
          >
            使 用 者 帳 戶 列 表
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="text"
              className="mb-3"
              value={search}
              placeholder="輸入關鍵字搜尋..."
              onChange={(event) => setsearch(event.target.value)}
            />
            <ListGroup
              className="selectGroup BmsSelector"
              style={{ maxHeight: '22rem', overflowY: 'auto' }}
            >
              {filteredItems.map((i) => (
                <OverlayTrigger
                  key={i.user_id}
                  placement="right"
                  delay={{ show: 350, hide: 350 }}
                  overlay={
                    <Tooltip
                      className="userTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <Tips setting={i} />
                    </Tooltip>
                  }
                >
                  <Row
                    onClick={() => handleAdd(i.user_id)}
                    className="p-2 px-3"
                  >
                    <Col xs={3} className="px-1">
                      <Row className="pt-2 d-flex justify-content-center w-100 m-0">
                        <Image
                          className="d-flex justify-content-center align-self-center p-0 m-0 w-45"
                          src={getAvatar({
                            avatarId: i.avatar_id,
                            permission: i.permission,
                          })}
                          alt="頭 像"
                        />
                      </Row>
                      <Row
                        title={i.name}
                        className="d-block text-center pt-1 text-lucaDark small App-oneLineEllipsis w-100 m-0"
                      >
                        {i.name}
                      </Row>
                    </Col>
                    <Col xs={8} className="d-flex text-lucaDark p-0 m-0">
                      <p
                        title={i.email}
                        className="d-block my-auto px-1 small App-oneLineEllipsis w-100"
                      >
                        {i.email}
                      </p>
                    </Col>
                    <Col xs={1} className="ms-auto ps-0 d-flex">
                      <FontAwesomeIcon
                        style={{ color: 'transparent' }}
                        className="m-auto ms-0 align-self-center"
                        icon={faPlus}
                        title="新 增"
                      />
                    </Col>
                  </Row>
                </OverlayTrigger>
              ))}
            </ListGroup>
          </Modal.Body>
        </Modal.Dialog>
      </Col>
      <Col className="d-flex flex-grow-0 justify-content-center">
        <FontAwesomeIcon
          className="m-auto fs-2 text-luca"
          icon={faAngleRight}
        />
      </Col>
      <Col xs={3} className="flex-grow-1">
        <Modal.Dialog className="h-100 d-flex m-0 py-3">
          <Modal.Header
            id="form-dialog-title"
            className="m-auto border-0 h6 text-lucaDark"
          >
            已 指 派 帳 戶 （ {assigned.length} ）
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="text"
              className="mb-3"
              value={assignedSearch}
              placeholder="輸入關鍵字搜尋..."
              onChange={(event) => setassignedSearch(event.target.value)}
            />

            <ListGroup
              className="selectGroup h-100"
              style={{
                maxHeight: '22rem',
                overflowY: 'auto',
                // overflowX: 'hidden',
              }}
            >
              {filteredAssigned.map((a) => (
                <OverlayTrigger
                  key={a.user_id}
                  placement="left"
                  delay={{ show: 350, hide: 350 }}
                  overlay={
                    <Tooltip
                      className="userTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <Tips setting={a} />
                    </Tooltip>
                  }
                >
                  <Row key={a.user_id} className="p-2 px-3">
                    <Col xs={3} className="px-1">
                      <Row className="pt-2 d-flex justify-content-center w-100 m-0">
                        <Image
                          className="d-flex justify-content-center align-self-center p-0 m-0 w-45"
                          src={getAvatar({
                            avatarId: a.avatar_id,
                            permission: a.permission,
                          })}
                          alt="頭 像"
                        />
                      </Row>
                      <Row
                        title={a.name}
                        className="d-block text-center pt-1 text-lucaDark small App-oneLineEllipsis w-100 m-0"
                      >
                        {a.name}
                      </Row>
                    </Col>
                    <Col xs={5} className="d-flex text-lucaDark p-0 m-0">
                      <Row
                        title={a.email}
                        className="d-block my-auto px-1 small App-oneLineEllipsis w-100"
                      >
                        {a.email}
                      </Row>
                    </Col>
                    <Col xs={3} className="d-flex mx-auto p-0">
                      <Dropdown className="my-auto">
                        <Dropdown.Toggle
                          className="btn-outline-lucaLight px-1 fs-8"
                          id="dropdown-basic"
                          size="sm"
                        >
                          權限類型
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="px-3">
                          {[
                            'isAdmin',
                            'isAdsManager',
                            'isAnalyst',
                            'isFinance',
                          ].map((key) => (
                            <Form.Switch
                              className="small"
                              key={key}
                              label={permissions[key]}
                              checked={a.setting[key] || a.setting.isAdmin}
                              disabled={a.setting.isAdmin && key !== 'isAdmin'}
                              onChange={() =>
                                handleUserChange(a.user_id, {
                                  ...a.setting,
                                  [key]: !a.setting[key],
                                })
                              }
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col xs={1} className="d-flex p-0">
                      <FontAwesomeIcon
                        onClick={() => handleDelete(a.user_id)}
                        className="m-auto align-self-center btn-lucaIcon3"
                        icon={faTimes}
                        title="取 消"
                      />
                    </Col>
                  </Row>
                </OverlayTrigger>
              ))}
            </ListGroup>
          </Modal.Body>
        </Modal.Dialog>
      </Col>
    </Row>
  )
}

function BMManager(props) {
  const { setting } = props
  const {
    show,
    handleClose,
    dialogClassName,
    size,
    title,
    titleEng,
    items,
    assigned,
    handleAdd,
    handleDelete,
    action,
    handleUserChange,
    handlePermissionChange,
    registerData,
    onRegisterDataChange,
    handleRegister,
    status,
  } = setting

  const actions = {
    accounts: (
      <ManagedList
        setting={{
          itemTitle: '廣 告 帳 戶 列 表',
          assignedTitle: '已 指 派 帳 戶',
          items,
          assigned,
          filterField: 'account_id',
          handleAdd,
          handleDelete,
        }}
      />
    ),
    bms: (
      <ManagedList
        setting={{
          itemTitle: 'ADM 列 表',
          assignedTitle: '已 指 派 ADM',
          items,
          assigned,
          filterField: 'business_id',
          handleAdd,
          handleDelete,
          handlePermissionChange,
          permissionType: 'adm',
        }}
      />
    ),
    users: (
      <UserList
        setting={{
          items,
          assigned,
          handleAdd,
          handleDelete,
          handleUserChange,
          registerData,
          onRegisterDataChange,
          handleRegister,
          status,
        }}
      />
    ),
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      dialogClassName={dialogClassName || ''}
      size={size}
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        {title && (
          <Modal.Title>
            <h4>{title}</h4>
            <p>{titleEng}</p>
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body className="AccformCard px-4">
        <div className="assPermis w-100">{actions[action]}</div>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-4">
        <Button variant="secondary" onClick={handleClose}>
          關 閉
        </Button>
        <Button variant="luca" onClick={handleClose}>
          完 成
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

BMManager.propTypes = {
  setting: PropTypes.shape(),
}

BMManager.defaultProps = {
  setting: {
    title: '申請廣告帳戶',
    titleEng: 'Open the advertising account',
    pageLimit: 2,
    content: [
      { name: '用戶名稱', type: 'fixed', value: 'Kevin' },
      { name: '帳戶名稱', type: 'text', value: '' },
      { name: '公司統編', type: 'text', value: '' },
      { name: '電子信箱', type: 'email', value: '' },
    ],
  },
}

UserList.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Tips.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BMManager
