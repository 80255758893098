import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'

function AnnounceBar(props) {
  const { setting } = props
  const { content } = setting
  return (
    <Card className="text-start p-2">
      <div className="App-oneLineEllipsis text-lucaDark" title={content}>
        <FontAwesomeIcon className="me-3" icon={faBullhorn} />
        {content}
      </div>
    </Card>
  )
}

AnnounceBar.propTypes = {
  setting: PropTypes.shape(),
}

AnnounceBar.defaultProps = {
  setting: {
    content: 'New ! 系統版本資訊： LUCA系統目前版本為 v3.7版',
  },
}

export default AnnounceBar
