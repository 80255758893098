import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import lucaLogoOnly from '../assets/images/lucaLogo-0.gif'

function Showcase(props) {
  const { children, setting } = props
  const { title, titleEng, hasIcon } = setting
  return (
    <Card className="p-0 bg-transp text-lucaDark">
      <Card.Body className="row d-flex">
        {hasIcon && (
          <Col xs={3} className="my-auto ps-4 pe-0">
            <Image width="60%" style={{ opacity: '0.9' }} src={lucaLogoOnly} />
          </Col>
        )}
        <Col
          className={`my-auto ${
            hasIcon ? 'text-start fs-6' : 'text-center fs-5 text-light'
          } `}
        >
          {title && <Card.Title className="mb-0 fs-5 px-4">{title}</Card.Title>}
          {titleEng && <Card.Text className="m-0 px-4">{titleEng}</Card.Text>}
          {children}
        </Col>
      </Card.Body>
    </Card>
  )
}

Showcase.propTypes = {
  setting: PropTypes.shape(),
  children: PropTypes.instanceOf(Object),
}

Showcase.defaultProps = {
  setting: {
    title: 'Hi, Welcome to LUCA !',
  },
  children: <div />,
}

export default Showcase
