import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LucaDatePicker from '../LucaDatePicker'
import ReportFloatButton from './ReportFloatButton'
import FacebookInsightFields from '../../configure/FacebookInsightFields'
import GoogleInsightFields from '../../configure/GoogleInsightFields'
import { t } from '../../services/lucaFunctions'
import { AuthContext } from '../ContextProvider'

function ReportTools(props) {
  const { setting } = props
  const {
    provider,
    views,
    // groups,
    timeRanges,
    presetStatus,
    // breakdown,
    dateBreakdown,
    breakdowns = {},
    fields = [],
    handleReportChange,
    extraData = [],
    // handleAdClusterChange,
    reportName,
    // description,
    currency = '$',
    rate = 1,
    mockup = 1,
  } = setting
  const { auth } = useContext(AuthContext)
  console.log(auth)
  const viewOptions = [
    {
      label: '橫式',
      value: 'horizonal',
    },
    {
      label: '總計',
      value: 'total',
    },
    {
      label: '廣告預覽圖',
      value: 'preview',
    },
    {
      label: '走期／數據',
      value: 'Data',
    },
    {
      label: '折線圖',
      value: 'LineChart',
    },
    {
      label: '趨勢圖',
      value: 'StackChart',
    },
    {
      label: '圓餅圖',
      value: 'PieChart',
    },
  ]

  const breakdownOptions = [
    { label: '預設', value: '' },
    { label: 'age', value: 'age' },
    { label: 'gender', value: 'gender' },
    { label: 'age,gender', value: 'age,gender' },
    { label: 'country', value: 'country' },
    { label: 'region', value: 'region' },
    { label: 'device', value: 'device' },
    { label: 'platform', value: 'platform' },
  ]
  const dateFormats = [
    { label: 'all_days', value: 'all_days' },
    { label: 'daily', value: 'daily' },
  ]
  const currencyOptions = [
    { label: '$', value: '$' },
    { label: '¥', value: '¥' },
  ]

  const filterFields = () => {
    if (provider === 'facebook') return FacebookInsightFields
    if (provider === 'google') return GoogleInsightFields
    return FacebookInsightFields
  }

  console.log(extraData)

  return (
    <Row className="text-start h-100 w-100 m-auto text-lucaDark">
      <Col xs={3} className="pt-3 pb-4 ps-4 h-100">
        <h6 className="pb-2 text-lucaDark fw-bolder">報 表 設 定</h6>
        <div className="d-flex">
          <p className="text-nowrap my-auto pe-2">分頁名稱</p>
          <Form.Control
            type="text"
            value={reportName}
            placeholder="分頁名稱"
            size="sm"
            onChange={(e) => handleReportChange({ reportName: e.target.value })}
          />
        </div>
        {/* <div className="d-flex pt-1">
          <p className="text-nowrap my-auto pe-2">描&emsp;&emsp;述</p>
          <Form.Control
            type="text"
            value={description}
            placeholder="描述"
            size="sm"
            onChange={(e) =>
              handleReportChange({ description: e.target.value })
            }
          />
        </div> */}
        <h6 className="py-2 text-lucaDark fw-bolder">數 據 設 定</h6>
        <div className="d-flex py-2">
          <p className="text-nowrap my-auto pe-2 w-25">幣別</p>
          <Form.Select
            type="text"
            value={currency}
            placeholder="設定幣值"
            size="sm"
            onChange={(e) => handleReportChange({ currency: e.target.value })}
          >
            {currencyOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex">
          <p className="text-nowrap my-auto pe-2 w-25">貨幣兌換</p>
          <Form.Control
            type="text"
            value={rate}
            placeholder="輸入數值"
            size="sm"
            onChange={(e) => handleReportChange({ rate: e.target.value })}
          />
        </div>
        <div className="d-flex">
          <p className="text-nowrap my-auto pe-2 w-25">mockup</p>
          <Form.Control
            type="text"
            value={mockup}
            placeholder="輸入數值"
            size="sm"
            onChange={(e) => handleReportChange({ mockup: e.target.value })}
          />
        </div>
      </Col>
      <Col
        xs={3}
        className="py-3 ps-4 h-100 datePicker-noBorder overflow-hidden"
      >
        <h6 className="pb-2 text-lucaDark fw-bolder">日 期 區 間</h6>
        <LucaDatePicker
          setting={{
            since: moment(timeRanges.since).toDate(),
            until: moment(timeRanges.until).toDate(),
            setSince: (date) => {
              handleReportChange({ timeRanges: { ...timeRanges, since: date } })
            },
            setUntil: (date) =>
              handleReportChange({
                timeRanges: { ...timeRanges, until: date },
              }),
          }}
        />
        <h6 className="pt-3 pb-1 text-lucaDark fw-bolder">日 期 格 式</h6>
        <Row>
          {dateFormats.map(({ label, value }, index) => (
            <Col xs={6} key={index}>
              <Form.Check
                type="radio"
                value={value}
                label={t(label)}
                id={index}
                checked={dateBreakdown === value}
                onChange={() => {}}
                onClick={() => handleReportChange({ format: value })}
              />
            </Col>
          ))}
        </Row>
        <h6 className="pt-3 pb-1 pe-3 text-lucaDark fw-bolder d-flex">
          附 加 資 料
          <FontAwesomeIcon
            icon={faPlusCircle}
            // style={{ transform: 'scaleX(-1)' }}
            className="ms-auto my-auto fs-7 px-0"
          />
        </h6>
        <Row className="h-50 overflow-scroll">
          {extraData.map((d) => (
            <Row key={d.key}>
              <Form.Label>{d.key}</Form.Label>
              <Form.Control value={d.value} />
            </Row>
          ))}
        </Row>
      </Col>
      <Col xs={3} className="ps-4 h-100">
        <h6 className="pb-2 text-lucaDark fw-bolder">顯 示 設 定</h6>
        <Row>
          {viewOptions.map(({ label, value }, index) => (
            <Col xs={6} key={index}>
              <Form.Check
                type="radio"
                value={value}
                label={label}
                id={index}
                checked={views[value]}
                onChange={() => {}}
                onClick={() =>
                  handleReportChange({
                    views: { ...views, [value]: !views[value] },
                  })
                }
              />
            </Col>
          ))}
        </Row>
        <h6 className="pt-3 pb-1 text-lucaDark fw-bolder">分 析 項 目</h6>
        <Row>
          {breakdownOptions.map(({ label, value }, index) => (
            <Col xs={6} key={index}>
              <Form.Check
                className="text-nowrap"
                type="radio"
                value={value}
                label={t(label)}
                id={index}
                checked={breakdowns[value]}
                onChange={() => {}}
                onClick={() =>
                  handleReportChange({
                    breakdowns: { ...breakdowns, [value]: !breakdowns[value] },
                  })
                }
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col xs={3} className="py-3 ps-4 h-75">
        <h6 className="pb-2 text-lucaDark fw-bolder">欄 位 設 定</h6>
        <div className="d-flex w-100">
          <Form.Check
            className="full_label w-80"
            type="radio"
            value="preset"
            checked={presetStatus === 'preset'}
            onChange={() => handleReportChange({ presetStatus: 'preset' })}
            label={
              <DropdownButton
                className="d-flex"
                align="start"
                title="選擇既有欄位組合..."
                id="dropmenu"
                variant="light"
                size="sm"
              >
                {(auth.setting.presets || []).map(({ name, values }) => (
                  <Dropdown.Item
                    key={name}
                    eventKey="1"
                    size="sm"
                    className="py-0 w-100"
                    onClick={() => {
                      console.log(values)
                    }}
                    // disabled={presetStatus !== 'preset'}
                  >
                    {name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            }
            // id={part}
            // checked={dateBreakdown === b}
            // onChange={handledatebreakdownSelect}
          />
        </div>
        <div
          className="d-flex w-100 h-100 pt-1"
          style={{ overflowY: 'hidden' }}
        >
          <Form.Check
            className="h-100"
            type="radio"
            value="custom"
            checked={presetStatus === 'custom'}
            onChange={() => handleReportChange({ presetStatus: 'custom' })}
          />
          <div className="d-flex flex-column w-100 h-100 ps-2 overflowY-hidden">
            <div className="position-relative w-80">
              {presetStatus === 'custom' && (
                <ReportFloatButton
                  setting={{
                    icon: faPlusCircle,
                    title: '建立新組合',
                    onClick: () => {},
                    style: {
                      width: '35px',
                      height: '35px',
                      right: '-35px',
                    },
                  }}
                />
              )}
              <Form.Control
                type="text"
                style={{
                  height: '27px',
                  // color: '#21515a',
                  border: '0.5px solid white',
                }}
                // value={value}
                placeholder={
                  presetStatus === 'custom' ? '建立新組合' : '自選欄位項目...'
                }
                size="sm"
                disabled={!presetStatus === 'custom'}
                // checked={dateBreakdown === b}
                // onChange={handledatebreakdownSelect}
              />
            </div>
            <div
              className="selectGroup flex-grow-1 w-100"
              style={{ overflowY: 'auto' }}
            >
              {true &&
                filterFields().map((field) => (
                  <ListGroupItem
                    className="d-flex py-1 my-auto w-80 App-textSmall"
                    style={{
                      height: '29px',
                      backgroundColor: fields.includes(field.name)
                        ? '#c3ebe5'
                        : 'white',
                      color: '#21515a',
                      border: '0.5px solid white',
                    }}
                    title={t(field.name)}
                    onClick={() =>
                      handleReportChange({
                        fields: fields.includes(field.name)
                          ? fields.filter((f) => f !== field.name)
                          : [...fields, field.name],
                      })
                    }
                    active={fields.includes(field.name)}
                  >
                    <p className="w-90 overflow-hidden App-oneLineEllipsis">
                      {t(field.name)}
                    </p>
                    {fields.includes(field.name) && (
                      <p className="ms-auto text-luca">
                        {fields.indexOf(field.name) + 1}
                      </p>
                    )}
                  </ListGroupItem>
                ))}
            </div>
          </div>
        </div>
        {/* <div className="d-flex w-20 h-100 pt-1" style={{ overflowY: 'hidden' }}>
          <FontAwesomeIcon
            className="m-auto align-self-center text-light"
            style={{
              cursor: 'pointer',
            }}
            icon={faPlusCircle}
            title="建立新組合"
            onClick={() => {}}
          />
        </div> */}
      </Col>
    </Row>
  )
}

ReportTools.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportTools
