/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer(props) {
  const { setting } = props
  const { handleShowCl, handleShowLg, logs } = setting
  return (
    <Navbar bg="lucaMid" expand="lg" style={{ height: '8vh' }}>
      <Container className="w-100 justify-content-center text-light App-textMid pb-2">
        <Row className="d-flex w-100">
          <Col
            style={{ width: '45%', flex: 'auto' }}
            className="mx-auto text-end pe-0"
          >
            Copyright © 2023 Wavenet. all rights reserved.
          </Col>
          <Col
            className="text-center px-0"
            title="點擊查看各版本更新資訊"
            onClick={handleShowCl}
            style={{ cursor: 'pointer', width: '18%', flex: 'auto' }}
          >
            ｜ &emsp; 版本資訊 v{logs && logs[0] ? logs[0].setting.version : ''}{' '}
            &emsp; ｜
          </Col>
          <Col
            className="d-flex text-start px-0"
            style={{ width: '35%', flex: 'auto' }}
          >
            <div
              className="d-flex"
              style={{ cursor: 'pointer' }}
              onClick={handleShowLg}
              title="切 換 語 言"
            >
              <FontAwesomeIcon
                icon={faEarthAmericas}
                className="text-light h6 my-auto ps-0 pe-2"
              />
              繁體中文
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  )
}

Footer.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Footer
