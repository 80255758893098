/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import DatePicker from 'react-datepicker'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faCalendarAlt,
  faChartLine,
  faCircleInfo,
  faCloudArrowDown,
  faLaptopFile,
} from '@fortawesome/free-solid-svg-icons'

function ReportSettings(props) {
  const { setting } = props
  const {
    name,
    subscribeStatus,
    frequency,
    subscribeTimeRange,
    emails,
    handleReportSetting,
  } = setting

  const frequencyOptions = [
    {
      label: '每日',
      value: 'daily',
    },
    {
      label: '每週',
      value: 'weekly',
    },
    {
      label: '每月',
      value: 'monthly',
    },
  ]

  const weekOptions = [
    {
      label: '週一',
      value: 'Monday',
    },
    {
      label: '週二',
      value: 'Tuesday',
    },
    {
      label: '週三',
      value: 'W',
    },
    {
      label: '週四',
      value: 'Tur',
    },
    {
      label: '週五',
      value: 'Fri',
    },
    {
      label: '週六',
      value: 'Sat',
    },
    {
      label: '週日',
      value: 'Sun',
    },
  ]

  return (
    <Row className="text-start h-100 w-100 m-auto text-lucaDark">
      <Col xs={6} className="px-4 h-100 datePicker-noBorder">
        <h6 className="pt-3 pb-1 text-lucaDark fw-bolder">訂 閱 設 定</h6>
        <Row className="d-flex">
          <Col className="d-flex">
            <Form.Switch
              className="my-auto"
              label="啟用報表訂閱"
              checked={subscribeStatus}
              onChange={() =>
                handleReportSetting({ isActive: !subscribeStatus })
              }
            />
            <OverlayTrigger
              placement="right"
              delay={{ show: 350, hide: 250 }}
              overlay={
                <Tooltip
                  className="quesTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  使用者可藉由此功能，在選擇的日期區間內、於指定頻率，在電子信箱內收到廣告投放成效報表之最新動態。
                </Tooltip>
              }
            >
              <span className="d-flex ps-2">
                <FontAwesomeIcon
                  className="text-lucaLink3 my-auto"
                  icon={faCircleInfo}
                />
              </span>
            </OverlayTrigger>
          </Col>
          <Col xs={6} className="d-flex">
            <DropdownButton
              align="start"
              title={frequency || '選擇寄送頻率...'}
              id="dropmenu"
              variant="light"
              size="sm"
              className="w-100 flex-column"
            >
              {frequencyOptions.map((option) => (
                <Dropdown.Item
                  size="sm"
                  className="py-0"
                  onClick={() =>
                    handleReportSetting({ frequency: option.value })
                  }
                  // disabled={presetStatus !== 'preset'}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>
        {frequency === 'weekly' && (
          <Row>
            {weekOptions.map(({ label, value }, index) => (
              <Col xs={3} key={index}>
                <Form.Check
                  className="text-nowrap"
                  type="radio"
                  value={value}
                  label={label}
                  id={index}
                  // checked={breakdown === value}
                  onChange={() => {}}
                  // onClick={() => handleReportChange({ breakdown: value })}
                />
              </Col>
            ))}
          </Row>
        )}
        {frequency === 'monthly' && <Row />}
        <h6 className="py-3 pb-1 text-lucaDark fw-bolder">日 期 區 間</h6>
        <div className="d-flex pt-2 reportDatePicker">
          <FontAwesomeIcon
            className="fs-5 my-auto mx-2 text-luca ms-0"
            icon={faCalendarAlt}
          />
          <DatePicker
            selected={moment(subscribeTimeRange.since).toDate()}
            onChange={(date) => {
              handleReportSetting({
                startDate: date,
              })
            }}
          />
          <FontAwesomeIcon
            className="fs-5 my-auto mx-2 text-luca"
            icon={faAngleRight}
          />
          <DatePicker
            selected={moment(subscribeTimeRange.until).toDate()}
            onChange={(date) =>
              handleReportSetting({
                endDate: date,
              })
            }
          />
        </div>
        <h6 className="py-3 pt-4 pb-2 text-lucaDark fw-bolder">
          訂 閱 信 箱 ( 可 複 填 )
        </h6>
        <ReactMultiEmail
          className="w-100 h-25"
          placeholder="輸入信箱(可複填)..."
          emails={emails}
          onChange={(e) => {
            handleReportSetting({
              emails: e,
            })
          }}
          validateEmail={(e) => isEmail(e)}
          getLabel={(e, index, removeEmail) => (
            <div data-tag key={index}>
              {e}
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
                aria-hidden
              >
                x
              </span>
            </div>
          )}
        />
      </Col>

      <Col xs={6} className="pt-3 pb-4 px-4 h-100">
        <h6 className="pb-1 text-lucaDark fw-bolder">匯 出 設 定</h6>
        <div className="d-flex py-3">
          <FontAwesomeIcon
            className="ms-auto my-auto text-center text-white py-4"
            style={{ fontSize: '2.2rem' }}
            icon={faChartLine}
          />
          <FontAwesomeIcon
            className="my-auto text-center text-white py-4 px-1"
            style={{ fontSize: '4.7rem' }}
            icon={faLaptopFile}
          />
          <FontAwesomeIcon
            className="me-auto my-auto text-center text-white py-4"
            style={{ fontSize: '2.5rem' }}
            icon={faCloudArrowDown}
          />
        </div>
        <div className="d-flex pt-2">
          <p className="text-nowrap my-auto pe-2">檔案名稱</p>
          <Form.Control
            type="text"
            value={name}
            placeholder="請輸入名稱..."
            size="sm"
            onChange={(e) => handleReportSetting({ name: e.target.value })}
          />
        </div>
        <div className="d-flex pt-1 w-100">
          <p className="text-nowrap my-auto pe-2">語言設定</p>
          <DropdownButton
            align="start"
            title="選擇語言..."
            id="dropmenu"
            variant="light"
            size="sm"
            className="DropdownFullWidth w-100"
          >
            <Dropdown.Item
              eventKey="1"
              size="sm"
              className="py-0"
              // disabled={presetStatus !== 'preset'}
            >
              中文
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              size="sm"
              className="py-0"
              // disabled={presetStatus !== 'preset'}
            >
              英文
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              size="sm"
              className="py-0"
              // disabled={presetStatus !== 'preset'}
            >
              日文
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </Col>
    </Row>
  )
}

ReportSettings.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportSettings
