import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

function MenuCard(props) {
  const { setting } = props
  const { auth, handleLogOut } = setting
  return (
    <Card className="text-center p-0 border-0" style={{ width: '200px' }}>
      <Card.Body>
        <Card.Title>{auth.auth.setting.name || auth.auth.name}</Card.Title>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-around bg-light">
        <Link to="/info" className="align-item-start">
          <Button variant="lucaLight">會 員</Button>
        </Link>
        <Button
          variant="lucaLight"
          className="align-item-end"
          onClick={handleLogOut}
        >
          登 出
        </Button>
      </Card.Footer>
    </Card>
  )
}

MenuCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MenuCard
