/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/esm/Dropdown'
import DropdownButton from 'react-bootstrap/esm/DropdownButton'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularFluidMeter } from 'fluid-meter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCircle,
  faCirclePlus,
  faEye,
  faPenSquare,
  faCircleDollarToSlot,
  faCircleCheck,
  faChartPie,
  faQuestionCircle,
  faDownload,
  faCircleInfo,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import {
  faGrinBeamSweat,
  faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons'
import {
  SearchBar,
  SelectBar,
  Datatable,
  ConfirmForm,
  InvoiceModal,
  BillModal,
  BudgetModal,
} from '../components'
import PieChart from '../components/Insights/PieChart'
import apiService from '../services/apiService'
import { getIcon, wordSearch } from '../services/lucaFunctions'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function Chart({ setting }) {
  const { title, breakdowns, labels, datas, selectedField } = setting
  const pieRef = useRef(null)
  const [svgSize, setsvgSize] = useState(false)
  const getSize = () => {
    if (pieRef.current) {
      const style = getComputedStyle(pieRef.current)
      const height =
        pieRef.current.clientHeight -
        parseFloat(style.paddingTop) -
        parseFloat(style.paddingBottom)
      const width = pieRef.current.clientWidth
      return { width, height }
    }
    return false
  }
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const size = getSize()
      if (size.width !== svgSize.width || size.height !== svgSize.height)
        setsvgSize(size)
    })
    observer.observe(pieRef.current)
    return () => pieRef.current && observer.unobserve(pieRef.current)
  }, [])

  return (
    <div className="d-flex text-lucaDark fs-5 h-100">
      <div className="d-flex flex-column w-50 h-100">
        <div className="h-90" ref={pieRef}>
          <PieChart
            setting={{
              ...svgSize,
              hasLabels: false,
              breakdowns,
              labels,
              datas,
              showControl: false,
              selectedField,
              padding: 0,
              donutThickness: 40,
              colors: [
                '#168AAD',
                '#34A0A4',
                '#52B69A',
                '#76C893',
                '#99D98C',
                '#d9ed92',
              ],
              backgroundColor: 'transparent',
              margin: { top: 20, right: 20, bottom: 0, left: 20 },
            }}
          />
        </div>
        <p className="fs-7 ps-4 text-white">{title}</p>
      </div>
      <div className="d-flex flex-column flex-fill flex-wrap overflow-auto mt-auto">
        {datas[0].map((d, i) => {
          const total = datas[0].reduce(
            (prev, cur) =>
              parseInt(prev, 10) + parseInt(cur[selectedField], 10),
            0
          )
          return (
            <div
              className="d-flex lh-md fw-normal text-lucaDark text-start ps-3 fs-7 App-oneLineEllipsis"
              style={{ maxWidth: '100%', minHeight: '3vh' }}
              key={i}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className="my-auto m-2"
                style={{
                  fontSize: '.8rem',
                  color: [
                    '#168AAD',
                    '#34A0A4',
                    '#52B69A',
                    '#76C893',
                    '#99D98C',
                    '#d9ed92',
                  ][i],
                }}
              />
              <div className="flex-fill overflow-hidden App-oneLineEllipsis">
                {d[breakdowns[0]]}:{' '}
              </div>
              <p className="ms-auto w-20 text-end">
                {Math.round((d[selectedField] / total) * 100)}%
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Tools({ setting }) {
  const {
    tab,
    handleFilterChange,
    isAdmin,
    handleShowBudget,
    tempSearch,
    sort,
    settempSearch,
    period,
    accountStatus,
  } = setting
  return (
    <>
      <Col xs="auto" className="px-1">
        <SelectBar
          setting={{
            method: (e) => {
              handleFilterChange({
                target: {
                  name: 'platform',
                  value: e.target.value || '',
                },
              })
            },
            placeholder: '媒體平台',
            content: [
              { name: 'Meta', value: 'Meta' },
              { name: 'Google', value: 'Google' },
            ],
          }}
        />
      </Col>
      <Col xs="auto" className="px-1">
        <SelectBar
          setting={{
            method: (e) => {
              handleFilterChange({
                target: {
                  name: 'accountStatus',
                  value: e.target.value || 'all',
                },
              })
            },
            value: accountStatus,
            placeholder: '狀態',
            content: [
              { name: '全部', value: 'all' },
              { name: '警示中', value: 'warning' },
            ],
          }}
        />
      </Col>
      <Col xs="auto" className="px-1">
        <Dropdown className="my-auto">
          <Dropdown.Toggle className="select-dropdown fs-7" id="dropdown-basic">
            月份
          </Dropdown.Toggle>

          <Dropdown.Menu className="px-3">
            {['本期', '上期', '上上期', '四個月前', '五個月前', '六個月前'].map(
              (key) => (
                <Form.Check
                  className="small"
                  key={key}
                  label={key}
                  checked={period[key]}
                  onChange={() =>
                    handleFilterChange({
                      target: {
                        name: 'period',
                        value: { ...period, [key]: !period[key] },
                      },
                    })
                  }
                />
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs="auto" className="px-1">
        <SelectBar
          setting={{
            method: (e) =>
              handleFilterChange({
                target: {
                  name: 'sort',
                  value:
                    e.target.value ||
                    JSON.stringify({ field: 'name', order: 'desc' }),
                },
              }),
            value: sort,
            placeholder: '選擇排序',
            content: [
              {
                name: '依高至低',
                value: JSON.stringify({
                  field: 'amount',
                  order: 'desc',
                }),
              },
              {
                name: '依低至高',
                value: JSON.stringify({
                  field: 'amount',
                  order: 'aesc',
                }),
              },
              {
                name: '依新至舊',
                value: JSON.stringify({
                  field: 'date',
                  order: 'desc',
                }),
              },
              {
                name: '依舊至新',
                value: JSON.stringify({
                  field: 'date',
                  order: 'aesc',
                }),
              },
            ],
          }}
        />
      </Col>
      <Col className="px-1 flex-grow-1">
        <SearchBar
          setting={{
            title: '關鍵字...',
            name: 'search',
            tempSearch,
            settempSearch,
            method: () =>
              handleFilterChange({
                target: {
                  name: 'search',
                  value: tempSearch,
                },
              }),
          }}
        />
      </Col>
      {!isAdmin && tab === 'Budgets' && (
        <Col xs={3} className="px-0 d-flex">
          <Button
            className="d-flex w-100 text-center"
            variant="luca"
            onClick={() => handleShowBudget()}
          >
            <span className="mx-auto">
              儲值申請&ensp;
              <FontAwesomeIcon icon={faCirclePlus} />
            </span>
          </Button>
        </Col>
      )}
    </>
  )
}

function Levels({ setting }) {
  const { show, business, managedList, adaccounts, handleClose } = setting
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    sort: '',
    search: '',
  })
  const { search, sort } = filter
  const handleFilterChange = (newFilter) => {
    setfilter({ ...filter, ...newFilter })
  }

  const [path, setpath] = useState({ ...setting.path })
  const handlePathChange = ({ key, value }) => {
    switch (key) {
      case 'account':
        if (value.account_id === path.account.account_id)
          setpath({ ...path, account: {} })
        else
          setpath({
            ...path,
            adm:
              business.find(
                (b) =>
                  managedList[b.business_id] &&
                  managedList[b.business_id].find(
                    (m) => m.account_id === value.account_id
                  ) !== undefined
              ) || {},
            media: value.provider === 'facebook' ? 'meta' : value.provider,
            account: value,
          })
        break
      case 'media':
        if (value === path.media) {
          setpath({ ...path, media: '', account: {} })
        } else {
          setpath({ ...path, media: value, account: {} })
        }
        break
      case 'adm':
        if (value.business_id === path.adm.business_id) {
          setpath({ ...path, adm: {}, media: '', account: {} })
        } else {
          setpath({ ...path, adm: value, media: '', account: {} })
        }
        break
      default:
        break
    }
  }

  const [loaded, setloaded] = useState(20)

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="xl"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center pt-4"
        closeButton
      >
        <div className="w-100 text-lucaDark">
          <h5>選擇花費顯示層級</h5>
        </div>
      </Modal.Header>
      <Modal.Body className="AccformCard">
        <div className="d-flex w-100 py-2">
          <div className="ms-auto" style={{ width: '33%', paddingRight: '1%' }}>
            <SelectBar
              setting={{
                id: 'defaultBorder',
                method: (e) =>
                  handleFilterChange({
                    target: {
                      name: 'sort',
                      value:
                        e.target.value ||
                        JSON.stringify({ field: 'name', order: 'desc' }),
                    },
                  }),
                value: sort,
                placeholder: '選擇排序',
                content: [
                  {
                    name: '依高至低',
                    value: JSON.stringify({
                      field: 'amount',
                      order: 'desc',
                    }),
                  },
                  {
                    name: '依低至高',
                    value: JSON.stringify({
                      field: 'amount',
                      order: 'aesc',
                    }),
                  },
                  {
                    name: '依新至舊',
                    value: JSON.stringify({
                      field: 'date',
                      order: 'desc',
                    }),
                  },
                  {
                    name: '依舊至新',
                    value: JSON.stringify({
                      field: 'date',
                      order: 'aesc',
                    }),
                  },
                ],
              }}
            />
          </div>
          <div className="px-1" style={{ width: '33%' }}>
            <SearchBar
              setting={{
                id: 'defaultBorder',
                title: '關鍵字...',
                name: 'search',
                tempSearch,
                settempSearch,
                method: () => handleFilterChange({ search: tempSearch }),
              }}
            />
          </div>
        </div>
        <div className="h-100 m-0 py-3 modal-content d-flex">
          <div className="h-100 m-0 pt-2 d-flex flex-row text-luca">
            <h6 className="text-center w-100">ADM（企業管理平台）</h6>
            <FontAwesomeIcon
              className="m-auto fs-4 text-luca"
              icon={faAngleRight}
            />
            <h6 className="text-center w-100">媒體平台</h6>
            <FontAwesomeIcon
              className="m-auto fs-4 text-luca"
              icon={faAngleRight}
            />
            <h6 className="text-center w-100">廣告帳戶</h6>
          </div>
          <div className="h-100 m-0 pb-3 d-flex flex-row">
            <div
              style={{
                borderRight: '1px solid #bbb',
                width: '33%',
              }}
            >
              <Modal.Body>
                <ListGroup
                  id="scrollTarget"
                  className="selectGroup BmsSelector scrollbarShow"
                  style={{ maxHeight: '22rem', overflowY: 'auto' }}
                >
                  <InfiniteScroll
                    dataLength={loaded}
                    next={() => setloaded(loaded + 20)}
                    hasMore={loaded < business.length}
                    loader={<div />}
                    endMessage={<p />}
                    scrollableTarget="scrollTarget"
                  >
                    {business
                      .filter((b) => wordSearch(b.name, search))
                      .slice(0, loaded)
                      .map((b) => (
                        <ListGroupItem
                          onClick={() =>
                            handlePathChange({ key: 'adm', value: b })
                          }
                          className="d-flex my-auto"
                          style={{ minHeight: '3rem', height: '3rem' }}
                          key={b.business_id}
                          title={b.name}
                          active={b.business_id === path.adm.business_id}
                        >
                          <span className="my-auto App-oneLineEllipsis">
                            {b.name}
                          </span>
                        </ListGroupItem>
                      ))}
                  </InfiniteScroll>
                </ListGroup>
              </Modal.Body>
            </div>
            {path.adm.name && (
              <div
                style={{
                  borderRight: '1px solid #bbb',
                  width: '33%',
                }}
              >
                <Modal.Body>
                  <ListGroup
                    id="scrollTarget"
                    className="selectGroup BmsSelector scrollbarShow"
                    style={{ maxHeight: '22rem', overflowY: 'auto' }}
                  >
                    {['Meta', 'Google', 'Criteo', 'Twitter', 'Yahoo'].map(
                      (m) => (
                        <ListGroupItem
                          onClick={() =>
                            handlePathChange({
                              key: 'media',
                              value: m.toLowerCase(),
                            })
                          }
                          className="d-flex my-auto"
                          style={{ minHeight: '3rem', height: '3rem' }}
                          key={m}
                          title={m}
                          active={m.toLowerCase() === path.media}
                        >
                          <span className="my-auto App-oneLineEllipsis">
                            {m}
                          </span>
                        </ListGroupItem>
                      )
                    )}
                  </ListGroup>
                </Modal.Body>
              </div>
            )}
            {path.media && (
              <div
                style={{
                  width: '33%',
                }}
              >
                <Modal.Body>
                  <ListGroup
                    id="scrollTarget"
                    className="selectGroup BmsSelector scrollbarShow"
                    style={{ maxHeight: '22rem', overflowY: 'auto' }}
                  >
                    <InfiniteScroll
                      dataLength={loaded}
                      next={() => setloaded(loaded + 20)}
                      hasMore={loaded < business.length}
                      loader={<div />}
                      endMessage={<p />}
                      scrollableTarget="scrollTarget"
                    >
                      {(path.adm.business_id
                        ? managedList[path.adm.business_id] || []
                        : adaccounts
                      )
                        .filter(
                          (a) =>
                            (path.media === '' ||
                              a.provider === path.media ||
                              (a.provider === 'facebook' &&
                                path.media === 'meta')) &&
                            wordSearch(a.name, search)
                        )
                        .slice(0, loaded)
                        .map((a) => (
                          <ListGroupItem
                            onClick={() =>
                              handlePathChange({ key: 'account', value: a })
                            }
                            className="d-flex my-auto"
                            style={{ minHeight: '3rem', height: '3rem' }}
                            key={a.account_id}
                            title={a.name}
                            active={a.account_id === path.account.account_id}
                          >
                            <span className="my-auto App-oneLineEllipsis">
                              {a.name}
                            </span>
                          </ListGroupItem>
                        ))}
                    </InfiniteScroll>
                  </ListGroup>
                </Modal.Body>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center m-2">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取 消
        </Button>
        <Button variant="luca" onClick={() => handleClose(path)}>
          選 擇
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function Summary({ setting }) {
  const {
    isAdmin,
    business,
    managedList,
    summary,
    adaccounts,
    dangerousAccounts,
    path,
    handlePathChange,
  } = setting
  const { adm, media, account } = path
  const ref = useRef()
  const [meter, setmeter] = useState(false)
  const initMeter = (target) => {
    if (meter) meter.dispose()
    const inited = new CircularFluidMeter(target, {
      initialProgress: 75,
      use3D: false,
      borderColor: '#fff',
      backgroundColor: 'rgb(255,255,255,0.3)',
      dropShadow: false,
      borderWidth: 0,
      fontSize: 48,
      padding: 30,
      textColor: '#64babc',
      textShadowColor: '#ddd',
      // showBubbles: false,
      fluidConfiguration: {
        color: 'rgb(255,255,255,0.3)',
        waveSpeed: 10,
        horizontalSpeed: 10,
      },
      // progressFormatter: (value) => {
      //   if (!value) return ''
      //   return value
      // },
    })
    setmeter(inited)
  }
  useEffect(() => {
    if (ref.current && !meter) {
      initMeter(ref.current)
    }
  }, [ref.current])

  const [showChart, setshowChart] = useState(false)
  const fakeData = [
    {
      platform: 'meta',
      currency: '美元(USD)',
      total: 2000,
      used: 300,
      accounts: [
        {
          name: 'F0172_豐禾開發',
          total: 800,
          used: 140,
        },
        {
          name: 'F0473_豐禾開發',
          total: 200,
          used: 10,
        },
        {
          name: 'F0173_豐禾開發',
          total: 800,
          used: 140,
        },
        {
          name: 'F0474_豐禾開發',
          total: 200,
          used: 10,
        },
      ],
    },
    {
      platform: 'google',
      currency: '美元(USD)',
      total: 2000,
      used: 600,
      accounts: [
        {
          name: 'G0110_豐禾開發',
          total: 1800,
          used: 1600,
        },
        {
          name: 'G0430_尚品國際',
          total: 200,
          used: 140,
        },
      ],
    },
    {
      platform: 'criteo',
      currency: '美元(USD)',
      total: 200,
      used: 100,
      accounts: [
        {
          name: 'C0110_庫里',
          total: 1800,
          used: 1600,
        },
        {
          name: 'C0430_提歐',
          total: 500,
          used: 500,
        },
      ],
    },
    {
      platform: 'twitter',
      currency: '美元(USD)',
      total: 5000,
      used: 500,
      accounts: [
        {
          name: 'T0110_推推',
          total: 400,
          used: 400,
        },
        {
          name: 'T0430_特特',
          total: 200,
          used: 140,
        },
      ],
    },
    {
      platform: 'yahoo',
      currency: '美元(USD)',
      total: 200,
      used: 200,
      accounts: [
        {
          name: 'Y0110_奇摩',
          total: 300,
          used: 300,
        },
        {
          name: 'Y0430_MA',
          total: 200,
          used: 140,
        },
      ],
    },
  ]

  const { currency, total, used } = summary
  const platformData = useMemo(
    () =>
      media ? fakeData.find((fd) => fd.platform === media) || [] : fakeData,
    [media]
  )

  useEffect(() => {
    if (!meter) return
    meter.progress = media
      ? Math.floor(
          ((platformData.total - platformData.used) / platformData.total) * 100
        )
      : 75
  }, [media])

  const [showLevels, setshowLevels] = useState(false)
  const handleLevelClose = (value) => {
    if (value) handlePathChange(value)
    setshowLevels(false)
  }

  return (
    <div
      className="mx-auto w-80 position-relative"
      style={{ height: '42vh' }}
      // style={{ overflowX: 'hidden' }}
    >
      <div
        className="d-flex h-100"
        style={{
          transition: 'width .5s',
          transitionTimingFunction: 'ease-in',
          width: `${showChart ? '100' : '168'}%`,
        }}
      >
        <div
          ref={ref}
          style={{
            height: '250px',
            width: '250px',
          }}
          className="my-auto"
        />
        <div className="d-flex flex-column flex-fill text-lucaDark py-4 px-0 ps-3 fs-5 lh-md my-auto">
          <div className="d-flex mb-2">
            {isAdmin ? (
              <Button variant="luca" onClick={() => setshowLevels(true)}>
                {account.name || media || adm.name || '所有ADM'}
              </Button>
            ) : (
              <DropdownButton
                className="ps-2 pe-1 text-center"
                style={{
                  width: '210px',
                  minWidth: '210px',
                }}
                title={
                  <p className="w-100 text-center">
                    {account.name || media || '所有媒體平台'}
                  </p>
                }
                size="sm"
                variant="outline-luca"
              >
                <DropdownItem
                  eventKey="0"
                  onClick={
                    () => handlePathChange({ adm: '', media: '', account: '' })
                    // handlePathChange({ adm: '' })
                  }
                >
                  所有媒體平台
                </DropdownItem>
                {['Meta', 'Google', 'Criteo', 'Twitter', 'Yahoo'].map(
                  (p, i) => (
                    <DropdownItem
                      name={p}
                      className="position-relative hoverableDropdown"
                      key={i}
                      eventKey={i + 1}
                      onClick={(e) => {
                        if (e.target.name !== p) return
                        handlePathChange({
                          // adm: name,
                          media: p.toLowerCase(),
                          account: '',
                        })
                      }}
                    >
                      {p}
                      {managedList.filter(
                        (m) =>
                          m.provider === p.toLowerCase() ||
                          (m.provider === 'facebook' && p === 'Meta')
                      ).length > 0 && (
                        <div
                          className="dropdown-menu dropdown-submenu"
                          style={{
                            display: 'none',
                            position: 'absolute',
                            left: '100%',
                            top: '-7px',
                            zIndex: '99999',
                          }}
                        >
                          {managedList
                            .filter(
                              (m) =>
                                m.provider === p.toLowerCase() ||
                                (m.provider === 'facebook' && p === 'Meta')
                            )
                            .map((a, j) => (
                              <DropdownItem
                                key={`${i}_${j}`}
                                eventKey={j + 1}
                                onClick={() => {
                                  handlePathChange({
                                    media: p.toLowerCase(),
                                    account: a,
                                  })
                                }}
                              >
                                {a.name}
                              </DropdownItem>
                            ))}
                        </div>
                      )}
                    </DropdownItem>
                  )
                )}
              </DropdownButton>
            )}
            <Button
              className="ms-auto"
              variant="outline-luca"
              size="sm mb-auto"
              onClick={() => setshowChart(!showChart)}
            >
              <FontAwesomeIcon
                className="ms-auto align-self-center h5 p-0"
                icon={faChartPie}
                title="顯 示 花 費 比 例 分 佈"
              />
            </Button>
          </div>
          <hr className="hrClass-dotted" />
          <div className="d-flex fw-bold">
            <span>儲值幣值</span>
            <span className="ms-auto text-luca">
              {media ? platformData.currency : currency}
            </span>
          </div>
          <div className="d-flex">
            <span>總儲值金額</span>
            <span className="ms-auto fw-bold">
              {media ? platformData.total : total}
            </span>
          </div>
          <div className="d-flex">
            <span>實際花費金額</span>
            <Question
              setting={{ remark: '依實際帳單扣除，每月25日前更新。' }}
            />
            <span className="ms-auto fw-bold">
              {media ? platformData.used : used}
            </span>
          </div>
          <div className="d-flex flex-fill">
            <span>儲值餘額</span>
            <span className="ms-auto fw-bold">
              {media ? platformData.total - platformData.used : total - used}
            </span>
          </div>
          {dangerousAccounts && dangerousAccounts.length !== 0 && (
            <div
              className="text-start text-warn h7 pt-2"
              style={{ cursor: 'pointer' }}
              title="點 擊 此 處 前 往 確 認 預 算 狀 況"
              // onClick={toTheWarningFilter}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
              &ensp;
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="budgetTip"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    {dangerousAccounts.slice(0, 10).map((da) => (
                      <p>{da.adaccount}</p>
                    ))}
                    {dangerousAccounts.length > 10 && <p>......</p>}
                  </Tooltip>
                }
              >
                <h7>
                  Oops! 您有 {dangerousAccounts.length}{' '}
                  則廣告帳號餘額即將耗盡，請點擊此處即刻查看。
                </h7>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div
          className="h-100 d-flex flex-column text-lucaDark py-3 ps-4 pe-0 fs-5 text-start"
          style={{
            width: '40%',
          }}
        >
          {showChart && (
            <>
              <div className="h-50">
                <Chart
                  setting={{
                    title: media ? '各帳戶預算儲值分佈' : '各媒體預算儲值分佈',
                    breakdowns: [media ? 'name' : 'platform'],
                    labels: [media ? 'name' : 'platform'],
                    datas: [media ? platformData.accounts : fakeData],
                    selectedField: 'total',
                  }}
                />
              </div>
              <div className="h-50">
                <Chart
                  setting={{
                    title: media ? '各帳戶預算花費分佈' : '各媒體預算花費分佈',
                    breakdowns: [media ? 'name' : 'platform'],
                    labels: [media ? 'name' : 'platform'],
                    datas: [media ? platformData.accounts : fakeData],
                    selectedField: 'used',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isAdmin && (
        <Levels
          setting={{
            show: showLevels,
            handleClose: handleLevelClose,
            business,
            managedList,
            adaccounts,
            path,
          }}
        />
      )}
    </div>
  )
}

function Budgets({ setting }) {
  const { isAdmin, handleShowBudget, handleShowBill, filteredrecord } = setting
  return (
    <div className="tableW-bill-adj">
      <Datatable
        setting={{
          hasCheckBox: false,
          hasButton: false,
          hasPagination: true,
          pageSize: 5,
          headers: isAdmin
            ? [
                '單 號',
                '媒 體',
                'ADM 名 稱',
                '申 請 單 位',
                '申 請 日 期',
                '業 務 類 型',
                '匯 款 總 額',
                '儲 值 金 額',
                '審 核 狀 態',
                '入 帳 狀 態',
                '審 核',
              ]
            : [
                '單 號',
                '媒 體',
                '申 請 單 位',
                '申 請 日 期',
                '業 務 類 型',
                '匯 款 總 額',
                '儲 值 金 額',
                '審 核 狀 態',
                '入 帳 狀 態',
                '查 看',
              ],
          content: filteredrecord
            .concat(
              Array(
                filteredrecord.length % 5 > 0 || filteredrecord.length === 0
                  ? 5 - (filteredrecord.length % 5)
                  : 0
              ).fill({
                fill: true,
              })
            )
            .map((m) =>
              isAdmin
                ? m.fill
                  ? {
                      serial: { value: '- -' },
                      user: { value: '- -' },
                      name: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      platform: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      date: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      type: { value: '- -' },
                      amount: { value: '- -' },
                      charged: { value: '- -' },
                      status: { value: '- -' },
                      billStatus: { value: '- -' },
                      funs: { value: '- -' },
                    }
                  : {
                      serial: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {m.created_on
                                .substring(4, 16)
                                .replaceAll('-', '')
                                .replaceAll(':', '')}
                              {m.budget_id}
                            </span>
                          </div>
                        ),
                      },
                      platform: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {getIcon(
                                m.platform,
                                '20px',
                                '20px',
                                1,
                                'mx-auto'
                              )}
                            </span>
                          </div>
                        ),
                      },
                      name: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto App-oneLineEllipsis">
                              {m.business_name}
                            </span>
                          </div>
                        ),
                      },
                      user: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">{m.user_name}</span>
                          </div>
                        ),
                      },
                      date: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {moment(m.created_on).format('yyyy-MM-DD')}
                            </span>
                          </div>
                        ),
                      },
                      type: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">{m.type}</span>
                          </div>
                        ),
                      },
                      amount: {
                        value:
                          parseInt(m.amount, 10) + parseInt(m.fee || 0, 10),
                      },
                      charged: { value: m.amount },
                      status: { value: m.status },
                      billStatus: { value: m.billStatus },
                      funs: {
                        value: (
                          <div className="formsTool">
                            <Button
                              variant="lucaIcon"
                              onClick={() =>
                                handleShowBudget({
                                  ...m,
                                  action: 'review',
                                })
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faPenSquare}
                                title="審 核"
                              />
                            </Button>
                          </div>
                        ),
                      },
                    }
                : m.fill
                ? {
                    serial: { value: '- -' },
                    user: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">- -</span>
                        </div>
                      ),
                    },
                    platform: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">- -</span>
                        </div>
                      ),
                    },
                    date: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">- -</span>
                        </div>
                      ),
                    },
                    type: { value: '- -' },
                    amount: { value: '- -' },
                    charged: { value: '- -' },
                    status: { value: '- -' },
                    billStatus: { value: '- -' },
                    funs: { value: '- -' },
                  }
                : {
                    serial: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">
                            {m.created_on
                              .substring(4, 16)
                              .replaceAll('-', '')
                              .replaceAll(':', '')}
                            {m.budget_id}
                          </span>
                        </div>
                      ),
                    },
                    platform: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">
                            {getIcon(m.platform, '20px', '20px', 1, 'mx-auto')}
                          </span>
                        </div>
                      ),
                    },
                    user: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">{m.user_name}</span>
                        </div>
                      ),
                    },
                    date: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">
                            {moment(m.created_on).format('yyyy-MM-DD')}
                          </span>
                        </div>
                      ),
                    },
                    type: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">{m.type}</span>
                        </div>
                      ),
                    },
                    amount: {
                      value: parseInt(m.amount, 10) + parseInt(m.fee || 0, 10),
                    },
                    charged: { value: m.amount },
                    status: { value: m.status },
                    billStatus: { value: m.billStatus },
                    funs: {
                      value: (
                        <div className="formsTool">
                          <Button
                            variant="lucaIcon"
                            onClick={() => handleShowBudget(m)}
                          >
                            <FontAwesomeIcon
                              className="ms-auto align-self-center h5"
                              icon={faEye}
                              title="明 細"
                            />
                          </Button>
                          <Button
                            variant="lucaIcon"
                            onClick={() => handleShowBill(m)}
                          >
                            <FontAwesomeIcon
                              className="ms-auto align-self-center h5"
                              icon={faCircleDollarToSlot}
                              title="繳 費"
                            />
                          </Button>
                        </div>
                      ),
                    },
                  }
            ),
        }}
      />
    </div>
  )
}

function Cashflow({ setting }) {
  const { isAdmin, filteredrecord } = setting
  return (
    <Datatable
      setting={{
        hasCheckBox: false,
        hasButton: false,
        hasPagination: true,
        pageSize: 5,
        headers: isAdmin
          ? [
              '所 屬 ADM',
              '虛 擬 帳 號',
              '匯 款 日 期',
              '匯 款 方 式',
              '匯 款 總 金 額',
            ]
          : ['虛 擬 帳 號', '匯 款 日 期', '匯 款 方 式', '匯 款 總 金 額'],
        content: filteredrecord
          .concat(
            Array(
              filteredrecord.length % 5 > 0 || filteredrecord.length === 0
                ? 5 - (filteredrecord.length % 5)
                : 0
            ).fill({
              fill: true,
            })
          )
          .map((m) =>
            isAdmin
              ? m.fill
                ? {
                    name: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">- -</span>
                        </div>
                      ),
                    },
                    va: { value: '- -' },
                    date: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">- -</span>
                        </div>
                      ),
                    },
                    gateway: { value: '- -' },
                    amount: { value: '- -' },
                  }
                : {
                    name: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">{m.name}</span>
                        </div>
                      ),
                    },
                    va: { value: m.paymentDetail.accountNumber },
                    date: {
                      value: (
                        <div className="formsTool w-75 d-flex mx-auto">
                          <span className="m-auto">
                            {moment(m.tradeDate).format('yyyy-MM-DD')}
                          </span>
                        </div>
                      ),
                    },
                    gateway: { value: m.paymentGateway },
                    amount: { value: m.amount },
                  }
              : m.fill
              ? {
                  va: { value: '- -' },
                  date: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">- -</span>
                      </div>
                    ),
                  },
                  gateway: { value: '- -' },
                  amount: { value: '- -' },
                }
              : {
                  va: { value: m.paymentDetail.accountNumber },
                  date: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">
                          {moment(m.tradeDate).format('yyyy-MM-DD')}
                        </span>
                      </div>
                    ),
                  },
                  gateway: { value: m.paymentGateway },
                  amount: { value: m.amount },
                }
          ),
      }}
    />
  )
}

function Bills({ setting }) {
  const { filteredrecord, handleShowInvoice } = setting

  return (
    <Datatable
      setting={{
        hasCheckBox: false,
        hasButton: false,
        hasPagination: true,
        pageSize: 5,
        headers: [
          '編 號',
          '花 費 日 期',
          '媒 體',
          '廣 告 帳 戶',
          '層 級',
          <div className="d-flex">
            <p style={{ whiteSpace: 'nowrap' }}>本 期 花 費</p>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className="quesTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  可能收到來自媒體平台退還或回饋之款項
                </Tooltip>
              }
            >
              <div className="my-auto ms-2 h-100">
                <FontAwesomeIcon
                  className="fs-7 btn-lucaIcon"
                  style={{ right: '10', top: '50', bottom: '50' }}
                  icon={faCircleInfo}
                />
              </div>
            </OverlayTrigger>
          </div>,
          '總 花 費 情 況',
          // '帳 戶 餘 額',
          '幣 別',
          '功 能',
        ],
        content: filteredrecord
          .concat(
            Array(
              filteredrecord.length % 5 > 0 || filteredrecord.length === 0
                ? 5 - (filteredrecord.length % 5)
                : 0
            ).fill({
              fill: true,
            })
          )
          .map((m) =>
            m.fill
              ? {
                  serial: { value: '- -' },
                  date: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">- -</span>
                      </div>
                    ),
                  },

                  platform: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">- -</span>
                      </div>
                    ),
                  },
                  adaccount: { value: '- -' },
                  level: { value: '- -' },
                  amount: { value: '- -' },
                  spendCap: { value: '- -' },
                  // balance: { value: '- -' },
                  currency: { value: '- -' },
                  func: { value: '- -' },
                }
              : {
                  serial: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">{`${m.platform[0]}${m.date
                          .split('T')[0]
                          .replaceAll('-', '')}${parseInt(
                          Math.random() * 1000,
                          10
                        )}`}</span>
                      </div>
                    ),
                  },
                  date: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">{m.date.split('T')[0]}</span>
                      </div>
                    ),
                  },
                  platform: {
                    value: (
                      <div className="formsTool w-75 d-flex mx-auto">
                        <span className="m-auto">
                          {getIcon(m.platform, '20px', '20px', 1, 'mx-auto')}
                        </span>
                      </div>
                    ),
                  },
                  adaccount: {
                    value: (
                      <div className="formsTool w-100 d-flex text-start overflow-hidden">
                        <span
                          className="m-auto App-oneLineEllipsis"
                          title={m.adaccount}
                        >
                          {m.adaccount}
                        </span>
                      </div>
                    ),
                  },
                  level: { value: m.level },
                  amount: {
                    value: (
                      <div className="d-flex text-end">
                        <div className="w-25 text-end">
                          {m.amount > 0 ? '-' : '+'}
                        </div>
                        <div className="w-75">
                          {numeral(Math.abs(m.amount)).format('0,0.00')}
                        </div>
                      </div>
                    ),
                  },
                  spendCap: {
                    value: (
                      <div className="d-flex text-end w-100">
                        <div className="fw-bold" style={{ width: '44%' }}>
                          {numeral(m.spendCap).format('0,0.00')}
                        </div>
                        <div
                          className="ps-2 text-center"
                          style={{ width: '4%' }}
                        >
                          ／
                        </div>
                        <div
                          className={
                            m.amountSpent / m.spendCap > 0.95 ? 'text-warn' : ''
                          }
                          style={{
                            width: '44%',
                          }}
                        >
                          {numeral(m.amountSpent).format('0,0.00')}
                        </div>
                        {m.amountSpent / m.spendCap > 0.95 && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip
                                className="quesTip"
                                style={{
                                  zIndex: '9999',
                                  pointerEvents: 'none',
                                }}
                              >
                                餘額即將用盡，請點擊此處即時儲值。
                              </Tooltip>
                            }
                          >
                            <div
                              className="d-flex my-auto px-2 h-100 text-warn"
                              style={{ width: '8%' }}
                            >
                              <FontAwesomeIcon
                                className="fs-7 m-auto"
                                style={{
                                  right: '10',
                                  top: '50',
                                  bottom: '50',
                                  cursor: 'pointer',
                                }}
                                icon={faCircleQuestion}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    ),
                  },
                  // balance: { value: m.balance },
                  currency: { value: m.currency },
                  funs: {
                    value: (
                      <div className="formsTool">
                        <Button
                          variant="lucaIcon"
                          onClick={() => {
                            handleShowInvoice(m)
                          }}
                        >
                          <FontAwesomeIcon
                            className="ms-auto align-self-center h5"
                            icon={faEye}
                            title="檢 視"
                          />
                        </Button>
                        <Button
                          as="a"
                          href={m.download_uri}
                          variant="lucaIcon"
                          onClick={() => {}}
                        >
                          <FontAwesomeIcon
                            className="ms-auto align-self-center h5"
                            icon={faDownload}
                            title="下 載"
                          />
                        </Button>
                      </div>
                    ),
                  },
                }
          ),
      }}
    />
  )
}

function BillRecords({ auth, action }) {
  const isAdmin = auth.account.business_id === 1 && action === 'bills'
  // const [level, setlevel] = useState(isAdmin ? 'ADM' : 'Account')
  const [bills, setbills] = useState({
    summary: {},
    business: [],
    adaccounts: [],
    managedList: {},
    data: {
      Budgets: [],
      Cashflow: [],
      Bills: [],
    },
    prepared: false,
  })
  useEffect(() => {
    const getBills = async () => {
      const business = isAdmin
        ? await apiService.data({
            path: `luca/business`,
            method: 'get',
          })
        : []
      const allAdAccounts = await apiService.data({
        path: isAdmin
          ? `luca/account`
          : `luca/account/${auth.account.business_id}`,
        method: 'get',
      })
      const res = await apiService.data({
        path: `luca/budget/${auth.account.business_id}`,
        method: 'get',
      })
      const summary = await apiService.data({
        path: `luca/budget/summary/${auth.account.business_id}`,
        method: 'get',
      })
      const invoice = await apiService.data({
        path: isAdmin
          ? `luca/insight/invoice`
          : `luca/insight/invoice/${auth.account.business_id}`,
        // path: `luca/insight/invoice`,
        method: 'get',
      })
      const adaccounts = isAdmin ? allAdAccounts.accounts : allAdAccounts

      const facebook = await apiService.data({
        path: `luca/insight/list/facebook`,
        method: 'get',
      })
      const balancedRecord = invoice
        .map((f) => {
          const target = facebook.find(
            (a) => a.account_id === f.ad_account_ids[0]
          )
          return {
            ...f,
            balance: target
              ? Math.max(
                  (target.spend_cap - target.amount_spent) / 100 +
                    target.balance / 100,
                  0
                )
              : '0',
            spendCap: target ? target.spend_cap / 100 : '0',
            amountSpent: target ? target.amount_spent / 100 : '0',
            warning: target
              ? target.amount_spent / target.spend_cap > 0.95
              : false,
          }
        })
        .sort((a, b) => b.balance - a.balance)
      const dangerousAccounts = balancedRecord.filter((b) => b.warning)

      setbills({
        ...res,
        business,
        managedList: isAdmin
          ? allAdAccounts.managedList
          : { [auth.account.business_id]: allAdAccounts },
        adaccounts,
        dangerousAccounts,
        summary: summary.summary,
        data: {
          Budgets: res.data,
          Cashflow: summary.deposit,
          Bills: balancedRecord,
        },
        prepared: true,
      })
    }
    getBills()
  }, [])
  const {
    summary,
    business,
    managedList,
    adaccounts,
    dangerousAccounts,
    prepared,
  } = bills

  const [tab, settab] = useState('Budgets')
  const names = {
    Budgets: '儲 值 申 請 紀 錄',
    Cashflow: '入 帳 總 紀 錄',
    Bills: '實 際 花 費 明 細',
  }
  // const engNames = {
  //   Budgets: isAdmin
  //     ? 'The budget application review'
  //     : 'The budget application log',
  //   Cashflow: 'Deposite and withdrawal log',
  //   Bills: 'Budget and expense log',
  // }

  const [path, setpath] = useState({
    adm: isAdmin ? {} : auth.account,
    media: '',
    account: {},
  })
  const handlePathChange = (newPath) => {
    setpath({ ...path, ...newPath })
  }

  const data = useMemo(() => {
    const filteredAccounts = adaccounts
      .filter(
        (a) =>
          (!path.adm.name ||
            (managedList[path.adm.business_id] &&
              managedList[path.adm.business_id].find(
                (m) => m.account_id === a.account_id
              ) !== undefined)) &&
          (path.media === '' ||
            a.provider === path.media ||
            (a.provider === 'facebook' && path.media === 'meta')) &&
          (!path.account.name || a.account_id === path.account.account_id)
      )
      .map((a) => a.setting.account_id)
    return tab === 'Bills'
      ? bills.data[tab].filter((b) =>
          filteredAccounts.includes(b.ad_account_ids[0])
        )
      : bills.data[tab]
  }, [bills, tab, path])

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    sort: JSON.stringify({ field: 'date', order: 'desc' }),
    search: '',
    platform: '',
    period: {
      本期: true,
      上期: false,
      上上期: false,
      四個月前: false,
      五個月前: false,
      六個月前: false,
    },
    accountStatus: 'all',
  })
  const { sort, search, platform, period, accountStatus } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  const filteredrecord = useMemo(
    () =>
      data.error
        ? []
        : data
            .filter(
              (r) =>
                (!platform ||
                  r.platform === platform ||
                  (r.setting && r.setting.platform === platform)) &&
                (!search || r.adaccount.includes(search)) &&
                (accountStatus === 'all' || r.warning)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? parseInt(a[field], 10) - parseInt(b[field], 10) ||
                    new Date(a[field]) - new Date(b[field])
                : parseInt(b[field], 10) - parseInt(a[field], 10) ||
                    new Date(b[field]) - new Date(a[field])
            })
            .map((d) => ({ ...d.setting, ...d })),
    [data, sort, search, platform, path, period, accountStatus]
  )

  const [showInvoice, setshowInvoice] = useState({
    show: false,
    formData: {},
  })
  const handleShowInvoice = async (formData) => {
    const getVA = async (id, bankCode) => {
      const res = await apiService.data({
        path: `luca/budget/account/${id}`,
        method: 'get',
      })
      return res.virtualAccounts.find((a) => a.bankCode === bankCode)
    }
    const va = await getVA(formData.business_id, '012')
    setshowInvoice({
      show: true,
      formData: { ...formData, account: va ? va.accountNumber : '' },
    })
  }
  const handleCloseInvoice = () => {
    setshowInvoice({
      ...showInvoice,
      show: false,
    })
  }

  const [showBill, setshowBill] = useState({
    show: false,
    formData: null,
  })
  const handleShowBill = async (formData) => {
    const getVA = async (id, bankCode) => {
      const res = await apiService.data({
        path: `luca/budget/account/${id}`,
        method: 'get',
      })
      return res.virtualAccounts.find((a) => a.bankCode === bankCode)
    }
    const va = await getVA(formData.business_id, '012')
    setshowBill({
      show: true,
      formData: { ...formData, account: va ? va.accountNumber : '' },
    })
  }

  const [showBudget, setshowBudget] = useState({
    show: false,
    formData: null,
  })
  const handleShowBudget = (formData) => {
    setshowBudget({
      show: true,
      formData,
    })
  }
  const [reason, setreason] = useState('')
  const handleWarnClose = async () => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
  }
  const handleCloseBudget = async (value) => {
    let res = null
    if (value) {
      switch (value.action) {
        case 'approve':
          handleShowWarn({
            img: faExclamationCircle,
            title: ' ',
            closeButton: true,
            warn: (
              <div className="pt-3 d-flex flex-column">
                <h4 className="text-center">確定要批准此款項入帳嗎?</h4>
              </div>
            ),
            shouldReturn: true,
            footer: (
              <>
                <Button
                  variant="secondary"
                  onClick={() => handleWarnClose(false)}
                >
                  取 消
                </Button>
                <Button
                  variant="luca"
                  onClick={() =>
                    handleCloseBudget({
                      ...value,
                      action: 'confirmApprove',
                    })
                  }
                >
                  確 定
                </Button>
              </>
            ),
          })
          break
        case 'confirmApprove':
          res = await apiService.data({
            path: `luca/budget/${value.budget_id}`,
            method: 'put',
            data: value,
          })
          if (!res.error) {
            setbills({
              ...bills,
              data: {
                ...bills.data,
                Budgets: bills.data.Budgets.map((b) =>
                  b.budget_id === res.budget_id
                    ? { ...b, setting: { ...b.setting, ...res.setting } }
                    : b
                ),
              },
              prepared: true,
            })
            handleShowWarn({
              img: faCircleCheck,
              title: ' ',
              closeButton: true,
              warn: (
                <div className="pt-3 d-flex flex-column">
                  <h4 className="text-center">
                    本款項已成功加入{value.business_name}
                    ADM潮錢包，謝謝！
                  </h4>
                </div>
              ),
              shouldReturn: true,
              footer: (
                <Button variant="luca" onClick={() => handleWarnClose(false)}>
                  Ｏ Ｋ
                </Button>
              ),
            })
          }
          break
        case 'reject':
          handleShowWarn({
            title: '退 回 預 算 申 請',
            formClassName: 'pb-0',
            warn: (
              <div
                className="pt-3 d-flex flex-column"
                style={{
                  height: '20vh',
                }}
              >
                <h6 className="text-start text-grey">退件說明：</h6>
                <Form.Control
                  className="flex-grow-1"
                  as="textarea"
                  name="退件說明"
                  onChange={(e) => setreason(e.target.value)}
                />
              </div>
            ),
            shouldReturn: true,
            footer: (
              <>
                <Button
                  variant="secondary"
                  onClick={() => handleWarnClose(false)}
                >
                  取 消
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    handleCloseBudget({
                      ...value,
                      action: 'confirmReject',
                    })
                  }
                >
                  退 件
                </Button>
              </>
            ),
          })
          break
        case 'confirmReject':
          res = await apiService.data({
            path: `luca/budget/${value.budget_id}`,
            method: 'put',
            data: {
              ...value,
              reason,
            },
          })
          if (!res.error) {
            setbills({
              ...bills,
              data: {
                ...bills.data,
                Budgets: bills.data.Budgets.map((b) =>
                  b.budget_id === res.budget_id
                    ? { ...b, setting: { ...b.setting, ...res.setting } }
                    : b
                ),
              },
              prepared: true,
            })
            handleShowWarn({
              img: faGrinBeamSweat,
              title: ' ',
              closeButton: true,
              warn: (
                <div className="pt-3 d-flex flex-column">
                  <h4 className="text-center">此審核已退件</h4>
                </div>
              ),
              shouldReturn: true,
              footer: (
                <Button variant="luca" onClick={() => handleWarnClose(false)}>
                  Ｏ Ｋ
                </Button>
              ),
            })
            setreason('')
          }
          break
        case 'send':
          res = await apiService.data({
            path: `luca/budget/${auth.account.business_id}`,
            method: 'post',
            data: {
              ...value,
              status: '未審核',
              billStatus: '未入帳',
            },
          })
          if (!res.error) {
            setbills({
              ...bills,
              data: {
                ...bills.data,
                Budgets: [res, ...bills.data.Budgets],
              },
            })
            handleShowWarn({
              img: faCircleCheck,
              title: ' ',
              closeButton: true,
              warn: (
                <div className="pt-3 d-flex flex-column">
                  <h4 className="text-center">您的申請已送出 !</h4>
                </div>
              ),
              shouldReturn: true,
              footer: (
                <Button variant="luca" onClick={() => handleWarnClose(false)}>
                  Ｏ Ｋ
                </Button>
              ),
            })
          }
          break
        default:
          break
      }
    }
    setshowBudget({
      ...showBudget,
      show: false,
    })
  }
  const handleCloseBill = (value) => {
    if (value) {
      console.log(value)
    }
    setshowBill({
      ...showBill,
      show: false,
    })
  }

  return (
    <Container fluid className="authManage card px-2 overflow-auto">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1">潮 錢 包 總 覽</h4>
        <p>Budget and expense overview</p>
      </Row>
      {!prepared ? (
        <Row className="flex-fill">
          <div className="m-auto d-flex justify-content-center h-100 my-0">
            <Spinner
              className="my-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        </Row>
      ) : (
        <>
          <Row
            style={{
              // height: '48vh',
              marginBottom: '0.5rem',
              paddingTop: '0',
            }}
          >
            <Summary
              setting={{
                isAdmin,
                business,
                managedList: isAdmin ? managedList : adaccounts,
                adaccounts,
                dangerousAccounts,
                summary,
                path,
                handlePathChange,
              }}
            />
          </Row>
          {/* <hr className="hrClass-dotted" />
          <Row className="p-4 pageTitle">
            <h4 className="pt-4 pb-1">儲 值 申 請 管 理</h4>
            <p>{engNames.Budgets}</p>
          </Row> */}
          <Row>
            <div className="d-flex px-5 w-100 mx-auto justify-content-center">
              <div
                style={{
                  transition: 'width .5s',
                  transitionTimingFunction: 'ease-in',
                  width: '12%',
                  // width: `${path.campaign ? titleWidth - 1 : 0}%`,
                  cursor: 'pointer',
                }}
                aria-hidden
                className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
              >
                {isAdmin && path.adm ? path.adm.name : auth.account.name}
              </div>
              <FontAwesomeIcon
                className="fs-5 my-auto text-lucaLighter"
                icon={faAngleRight}
                style={{
                  transition: 'width .5s',
                  transitionTimingFunction: 'ease-in',
                  width: path.media ? '5%' : 0,
                }}
              />
              <div
                style={{
                  transition: 'width .5s',
                  transitionTimingFunction: 'ease-in',
                  width: `${path.media ? 12 : 0}%`,
                  cursor: 'pointer',
                }}
                aria-hidden
                className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
              >
                {path.media}
              </div>
              <FontAwesomeIcon
                className="fs-5 my-auto text-lucaLighter"
                icon={faAngleRight}
                style={{
                  transition: 'width .5s',
                  transitionTimingFunction: 'ease-in',
                  width: path.account.name ? '5%' : 0,
                }}
              />
              <div
                style={{
                  transition: 'width .5s',
                  transitionTimingFunction: 'ease-in',
                  width: `${path.account.name ? 12 : 0}%`,
                  cursor: 'pointer',
                }}
                aria-hidden
                className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
              >
                {path.account.name}
              </div>
            </div>
          </Row>
          <Row className="pt-3 pb-0 px-4">
            <Col xs={5} className="pe-0">
              <Nav
                variant="pills"
                className="w-100 flex-nowrap flex-row w-100 overflow-hidden"
              >
                {['Budgets', 'Cashflow', 'Bills'].map((name) => (
                  <Nav.Item
                    key={name}
                    style={{
                      width: '33%',
                    }}
                    className={`flex-shrink-1 overflow-hidden text-nowrap ${
                      name === tab ? 'selectedReportTabs' : 'budgetTabs'
                    }`}
                  >
                    <Nav.Link
                      className="App-oneLineEllipsis px-2"
                      style={{ height: '2.75rem' }}
                      onClick={() => settab(name)}
                    >
                      {names[name]}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col xs={7} className="ps-0">
              <Row className="w-100 mx-auto">
                <Tools
                  setting={{
                    tab,
                    handleFilterChange,
                    isAdmin,
                    handleShowBudget,
                    tempSearch,
                    sort,
                    settempSearch,
                    period,
                    accountStatus,
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row
            className="px-4 pt-0 pb-2 h-100 tableLayout-adj"
            style={{
              minHeight: '55vh',
              height: '55vh',
            }}
          >
            {tab === 'Budgets' && (
              <Budgets
                setting={{
                  isAdmin,
                  handleShowBill,
                  handleShowBudget,
                  filteredrecord,
                }}
              />
            )}
            {tab === 'Cashflow' && (
              <Cashflow
                setting={{
                  isAdmin,
                  handleShowBill,
                  handleShowBudget,
                  filteredrecord,
                }}
              />
            )}
            {tab === 'Bills' && (
              <Bills
                setting={{
                  handleShowInvoice,
                  filteredrecord,
                }}
              />
            )}
          </Row>
        </>
      )}
      {prepared && (
        <>
          <BudgetModal
            setting={{
              ...showBudget,
              handleClose: handleCloseBudget,
            }}
          />
          <BillModal
            setting={{
              ...showBill,
              handleClose: handleCloseBill,
            }}
          />
          <InvoiceModal
            setting={{
              ...showInvoice,
              handleClose: handleCloseInvoice,
            }}
          />
        </>
      )}
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              {showWarn.img && (
                <div className="my-4">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={showWarn.img}
                  />
                </div>
              )}
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
    </Container>
  )
}

BillRecords.propTypes = {
  auth: PropTypes.shape().isRequired,
  action: PropTypes.string.isRequired,
}

Bills.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Budgets.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Cashflow.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Summary.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Levels.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Tools.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Chart.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Question.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BillRecords
