import React, { useState, useEffect, useMemo, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faFolderOpen,
  faEllipsis,
  faSquarePollVertical,
  faArrowUpWideShort,
  faArrowDownWideShort,
  faMagnifyingGlassChart,
  faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { SocketContext } from '../ContextProvider'
import {
  enums,
  fetchAccounts,
  arrangeData,
  getCriterions,
  toInsights,
} from '../../services/insightProcesser'
import LucaDatePicker from '../LucaDatePicker'
import Insights from '../Insights'
import ReportPercent from './ReportPercent'
import {
  getOriginIcon,
  getTimeRanges,
  wordSearch,
  t,
} from '../../services/lucaFunctions'
import apiService from '../../services/apiService'

function ChargptModal({ setting }) {
  const { show } = setting
  const initFile = {
    file: null,
    text: '',
  }
  const [file, setfile] = useState(initFile)
  const handleClose = () => {
    setfile(initFile)
    setting.handleClose()
  }

  const csvToArray = (csv) => csv.split('\n').map((r) => r.split(','))
  const ref = useRef(null)

  const handleCsvUpload = () => {
    if (!ref.current) return
    const reader = new FileReader()

    reader.onload = (csv) => {
      const text = csv.target.result
      setfile({
        file: ref.current.files[0],
        text,
      })
    }

    reader.readAsText(ref.current.files[0])
  }

  const [gptMessage, setgptMessage] = useState('')
  const socket = useContext(SocketContext)
  useEffect(() => {
    if (socket !== null) {
      socket.on('gpt', (message) => {
        if (message === 'done') return
        if (message) setgptMessage((prevState) => `${prevState}${message}`)
      })
    }
  }, [])

  const [objective, setobjective] = useState('impressions')
  const objectives = {
    impressions: '曝光',
    reach: '觸及相關',
    actions: '轉換行動',
  }
  const analyze = () => {
    apiService.data({
      path: `chatgpt/csv?csv=${file.text}&objective=${objectives[objective]}`,
      method: 'get',
    })
  }

  return (
    <Modal
      className="m-auto"
      style={{ zIndex: '1501' }}
      size={file.file ? 'xl' : 'md'}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title className="w-100 d-flex">
          <h5 className="text-start">
            <span className="fw-bold">ChatGPT</span> 報 表 分 析
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="text-center p-4"
        style={{
          height: file.file ? '75vh' : '35vh',
        }}
      >
        {file.file ? (
          <>
            <div
              className="d-flex"
              style={{
                height: '15%',
              }}
            >
              <ButtonGroup size="md" className="px-3 my-auto flex-fill h-50">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 350, hide: 150 }}
                  overlay={
                    <Tooltip
                      className="quesTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <span>
                        以最大化曝光人數或是觸及次數為主進行推播，將廣告投遞給最有可能記住品牌或該廣告的受眾。
                      </span>
                    </Tooltip>
                  }
                >
                  <Button
                    className="rounded-end"
                    variant={
                      objective === 'impressions'
                        ? 'lucaSwitch-active'
                        : 'lucaSwitch'
                    }
                    value="impressions"
                    name="selectedObject"
                    onClick={() => setobjective('impressions')}
                  >
                    品牌認知
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 350, hide: 150 }}
                  overlay={
                    <Tooltip
                      className="quesTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <span>
                        以投放流量為主進行推播，含括使用者互動，按讚、留言、分享、活動回覆、應用程式安裝等。
                      </span>
                    </Tooltip>
                  }
                >
                  <Button
                    variant={
                      objective === 'reach' ? 'lucaSwitch-active' : 'lucaSwitch'
                    }
                    value="reach"
                    name="selectedObject"
                    onClick={() => setobjective('reach')}
                  >
                    觸動考量
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 350, hide: 150 }}
                  overlay={
                    <Tooltip
                      className="quesTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <span>
                        主要目的為促使受眾執行特定行為，如：加入購物車、開始結帳、購買等行為，並以此為目標進行廣告投遞（注意：部分功能需解鎖並埋設Facebook
                        Pixel）。
                      </span>
                    </Tooltip>
                  }
                >
                  <Button
                    className="rounded-start"
                    variant={
                      objective === 'actions'
                        ? 'lucaSwitch-active'
                        : 'lucaSwitch'
                    }
                    value="actions"
                    name="selectedObject"
                    onClick={() => setobjective('actions')}
                  >
                    轉換行動
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <Button
                className="ms-auto my-auto me-2"
                variant="secondary"
                onClick={analyze}
              >
                分 析
              </Button>
              <Button
                className="my-auto me-2"
                variant="secondary"
                onClick={() => setfile({ file: null, text: '' })}
              >
                清 除
              </Button>
              <Button className="my-auto" variant="luca" onClick={() => {}}>
                下 載
              </Button>
            </div>
            <div
              style={{
                height: '85%',
              }}
            >
              <div className="h-100 d-flex">
                <Col xs={6} className="h-100 me-2">
                  <h6 className="py-2 text-luca fw-bold">
                    檔名: {file.file.name}
                  </h6>
                  <div className="h-88 overflow-scroll border rounded p-2 ms-1 text-start">
                    {csvToArray(file.text).map((row) => (
                      <Row className="overflow-hidden flex-nowrap">
                        {row.map((text) => (
                          <div
                            className="App-oneLineEllipsis"
                            style={{
                              width: '33%',
                            }}
                          >
                            {text.replaceAll('"', '')}
                          </div>
                        ))}
                      </Row>
                    ))}
                  </div>
                </Col>
                <Col xs={6} className="h-100">
                  <h6 className="py-2 text-luca fw-bold">分析結果 Result</h6>
                  <pre
                    className="d-block text-start h-88 w-100 border rounded p-2 ms-1"
                    style={{
                      overflowX: 'hidden',
                      overflowY: 'scroll',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {gptMessage.indexOf('·') >= 0
                      ? gptMessage
                          .substring(gptMessage.indexOf('·'), gptMessage.length)
                          .replaceAll('·', '\n·')
                          .substring(1)
                      : ''}
                  </pre>
                </Col>
              </div>
              <hr />
            </div>
          </>
        ) : (
          <>
            <input
              ref={ref}
              style={{
                visibility: 'hidden',
                width: '0',
                height: '0',
              }}
              type="file"
              inputId="csv"
              id="csv"
              name="csv"
              accept=".csv"
              onChange={handleCsvUpload}
            />
            <Button
              className="w-100 h-100"
              variant="lucaLighter3"
              onClick={() => ref.current.click()}
            >
              <h5 className="fw-bolder" title="上 傳 檔 案">
                Upload CSV file
                <FontAwesomeIcon icon={faCloudArrowUp} className="fs-4 ms-2" />
              </h5>
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

function Progress({ setting }) {
  const { prepared } = setting
  const [percent, setpercent] = useState(0)
  useEffect(() => {
    if (!prepared) setpercent(0)
  }, [prepared])
  useEffect(() => {
    if (prepared) return
    setTimeout(() => {
      const progress = Math.floor(Math.random() * 30)
      setpercent(Math.min(percent + progress, 99))
    }, Math.random() * 2000)
  }, [prepared, percent])
  return (
    <div className="m-auto d-flex justify-content-center card flex-row my-0">
      <Spinner
        className="my-auto"
        animation="border"
        variant="luca"
        size="sm"
        style={{
          animation: 'spinner-border 1.5s linear infinite',
        }}
      />
      <h5 className="text-luca my-auto ms-2">載入中...{percent}%</h5>
    </div>
  )
}

function ADMReporter(props) {
  const { setting } = props
  const { auth, handleActionChange } = setting
  const [accounts, setaccounts] = useState([])
  const [provider, setprovider] = useState('facebook')
  const [selected, setselected] = useState('')
  const [search, setsearch] = useState('')
  const account = useMemo(
    () =>
      accounts.find((a) => a.setting.account_id === selected) || {
        name: selected,
      },
    [selected]
  )
  const defaultFields = {
    facebook: {
      impressions: true,
      clicks: true,
      reach: true,
      spend: true,
    },
    google: {
      impressions: true,
      clicks: true,
      cost_micros: true,
    },
  }

  const names = useMemo(
    () =>
      accounts.reduce(
        (prev, cur) => ({ ...prev, [cur.setting.account_id]: cur.name }),
        {}
      ),
    [accounts]
  )

  const initTimeRanges = {
    since: moment().subtract(7, 'days').toDate(),
    until: moment().toDate(),
    preset: 3,
  }
  const [timeRange, settimeRange] = useState(initTimeRanges)
  const [selectedField, setselectedField] = useState('impressions')
  const [selectedSeperateField, setselectedSeperateField] =
    useState('impressions')

  useEffect(() => {
    if (auth.auth.user_id !== undefined) {
      const getData = async () => {
        const data = await fetchAccounts({
          user_id: auth.auth.user_id,
          business_id: auth.account.business_id,
        })
        const filteredAccounts = data.filter((d) => d.provider === provider)
        setaccounts(filteredAccounts)
        setselected(
          filteredAccounts[0] ? filteredAccounts[0].setting.account_id : ''
        )
      }
      getData()
    }
  }, [auth, provider])

  const handlePlatformChange = (platform) => {
    setselected(false)
    setprovider(platform)
  }

  const fields = useMemo(() => Object.keys(defaultFields[provider]), [provider])
  const [summaryFields, setsummaryFields] = useState(fields)

  const [accountsInsight, setaccountsInsight] = useState(false)
  useEffect(() => {
    const getInsight = async () => {
      await setaccountsInsight(false)
      const res = await arrangeData({
        business_id: auth.account.business_id,
        provider,
        since: moment(timeRange.since).format('yyyy-MM-DD'),
        until: moment(timeRange.until).format('yyyy-MM-DD'),
        level: 'ad',
        fields: summaryFields,
        criterions: [],
      })
      if (!res.error) setaccountsInsight(res.datas)
    }
    getInsight()
  }, [timeRange, summaryFields])

  const [accountInsight, setaccountInsight] = useState(false)
  useEffect(() => {
    if (!selected) return
    const getInsight = async () => {
      const res = await arrangeData({
        account_id: selected,
        business_id: auth.account.business_id,
        provider,
        since: moment(timeRange.since).format('yyyy-MM-DD'),
        until: moment(timeRange.until).format('yyyy-MM-DD'),
        level: 'ad',
        fields,
        criterions: [enums[provider].criterions.campaign],
      })
      if (!res.error) setaccountInsight(res.datas[selected])
    }
    getInsight()
  }, [timeRange, selected])

  const colors = [
    '#B10905',
    '#F7CC72',
    '#44778D',
    '#F2907B',
    '#72C5D1',
    '#F2D57B',
    '#F77F00',
    '#D62828',
    '#59C3C3',
  ]

  const [totalSetting, settotalSetting] = useState({
    view: {
      TWD: true,
      USD: true,
    },
    rate: '31',
  })
  const [sort, setsort] = useState(false)
  const [accountSort, setaccountSort] = useState(false)

  const printRef = useRef()

  const [admReport, setadmReport] = useState({
    summaryInsights: [],
    totalInsights: {},
    selectedInsights: {},
    prepared: false,
  })
  const { prepared, summaryInsights, totalInsights, selectedInsights } =
    admReport
  useEffect(() => {
    if (!selected) return
    const prepareInsights = async () => {
      const summaryCriterion = getCriterions({
        type: 'StackChart',
        dateBreakdown: 'all_days',
        provider,
        breakdowns: [],
      })
      const totalCriterions = [
        getCriterions({
          type: 'PieChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        getCriterions({
          type: 'LineChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        getCriterions({
          type: 'StackChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        getCriterions({
          type: 'CrossChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
      ]
      const selectedCriterions = [
        getCriterions({
          type: 'PieChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        getCriterions({
          type: 'LineChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        getCriterions({
          type: 'StackChart',
          dateBreakdown: 'all_days',
          provider,
          breakdowns: [],
        }),
        [['campaign_id']],
        [['adset_id']],
      ]

      const summary = await arrangeData({
        business_id: auth.account.business_id,
        provider,
        since: moment(timeRange.since).format('yyyy-MM-DD'),
        until: moment(timeRange.until).format('yyyy-MM-DD'),
        level: 'campaign',
        selected,
        fields,
        criterions: [summaryCriterion[0]],
      })
      const totalt = await arrangeData({
        business_id: auth.account.business_id,
        provider,
        since: moment(timeRange.since).format('yyyy-MM-DD'),
        until: moment(timeRange.until).format('yyyy-MM-DD'),
        level: 'campaign',
        selected,
        fields,
        criterions: [[]],
      })
      const totals = await Promise.all(
        totalCriterions.map(async (criterions) => {
          const data = await Promise.all(
            criterions.map((c) =>
              arrangeData({
                business_id: auth.account.business_id,
                provider,
                since: moment(timeRange.since).format('yyyy-MM-DD'),
                until: moment(timeRange.until).format('yyyy-MM-DD'),
                level: 'campaign',
                selected,
                fields,
                criterions: [c],
              })
            )
          )
          return data
        })
      )
      const selectedt = await arrangeData({
        account_id: selected,
        business_id: auth.account.business_id,
        provider,
        since: moment(timeRange.since).format('yyyy-MM-DD'),
        until: moment(timeRange.until).format('yyyy-MM-DD'),
        level: 'campaign',
        selected,
        fields,
        criterions: [[]],
      })
      const selecteds = await Promise.all(
        selectedCriterions.map(async (criterions) => {
          const data = await Promise.all(
            criterions.map((c) =>
              arrangeData({
                account_id: selected,
                business_id: auth.account.business_id,
                provider,
                since: moment(timeRange.since).format('yyyy-MM-DD'),
                until: moment(timeRange.until).format('yyyy-MM-DD'),
                level: 'campaign',
                selected,
                fields,
                criterions: [c],
              })
            )
          )
          return data
        })
      )
      setadmReport({
        summaryInsights: toInsights({
          type: 'StackChart',
          provider,
          origin: [summary],
          gt: totalt,
        }),
        totalInsights: {
          PieChart: toInsights({
            type: 'PieChart',
            provider,
            origin: totals[0],
            gt: totalt,
          }),
          LineChart: toInsights({
            type: 'LineChart',
            provider,
            origin: totals[1],
            gt: totalt,
          }),
          StackChart: toInsights({
            type: 'StackChart',
            provider,
            origin: totals[2],
            gt: totalt,
          }),
          CrossChart: toInsights({
            type: 'CrossChart',
            provider,
            origin: totals[3],
            gt: totalt,
          }),
        },
        selectedInsights: {
          PieChart: toInsights({
            account_id: selected,
            type: 'PieChart',
            provider,
            origin: selecteds[0],
            gt: selectedt,
          }),
          LineChart: toInsights({
            account_id: selected,
            type: 'LineChart',
            provider,
            origin: selecteds[1],
            gt: selectedt,
          }),
          StackChart: toInsights({
            account_id: selected,
            type: 'StackChart',
            provider,
            origin: selecteds[2],
            gt: selectedt,
          }),
          Campaign: toInsights({
            criterion: ['campaign_id'],
            account_id: selected,
            type: 'PieChart',
            provider,
            origin: selecteds[3],
            gt: selectedt,
          }),
          Adset: toInsights({
            criterion: ['adset_id'],
            account_id: selected,
            type: 'PieChart',
            provider,
            origin: selecteds[4],
            gt: selectedt,
          }),
        },
        prepared: true,
      })
    }
    setadmReport({
      ...admReport,
      prepared: false,
    })
    prepareInsights()
  }, [selected, timeRange])

  const [chatgptShow, setchatgptShow] = useState(false)
  const handleShowChatgpt = () => setchatgptShow(true)
  const handleCloseChatgpt = () => setchatgptShow(false)

  return (
    <Container
      className="px-4 pb-4 w-100 d-flex flex-column flex-grow-1"
      style={{ overflowY: 'auto' }}
      ref={printRef}
    >
      <Row className="pageTitle pe-2 pb-2 pt-4 lh-sm">
        <Col xs="3" className="me-auto text-start pb-1">
          <h5 className="pt-4 pb-0 mb-0">廣 告 投 放 數 據 總 覽</h5>
          {/* <small>Advertisement insights overview</small> */}
        </Col>
        <Col xs="auto" className="d-flex pb-1">
          <div className="d-flex mt-auto px-2 justify-content-end">
            {[
              'facebook',
              'google',
              'Twitter',
              'LinkedIn',
              'Line',
              'Criteo',
              'TTD',
              'YahooMA',
              'YahooDSP',
            ].map((platform, i) => (
              <span
                key={i}
                className="d-flex ps-3"
                title={platform}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  platform === 'facebook' || platform === 'google'
                    ? handlePlatformChange(platform)
                    : {}
                }
                aria-hidden
              >
                {getOriginIcon(platform, '22px', 'auto')}
              </span>
            ))}
          </div>
        </Col>
        <Col xs="5" className="d-flex pe-0">
          <Button
            className="w-100 mt-auto"
            variant="lucaWhite"
            title="建 立 新 報 表"
            onClick={() => handleActionChange({ action: 'AdsReporter' })}
            size="sm"
          >
            新 建
            <FontAwesomeIcon icon={faPlusCircle} className="fs-6 ms-2" />
          </Button>
          <Button
            className="w-100 mt-auto ms-2 me-1"
            variant="lucaWhite"
            title="既 有 報 表 管 理"
            onClick={() => handleActionChange({ action: 'ReportList' })}
            size="sm"
          >
            報 表 管 理
            <FontAwesomeIcon icon={faFolderOpen} className="fs-6 ms-2" />
          </Button>
          <Button
            className="w-100 mt-auto ms-2 me-1"
            variant="lucaWhite"
            title="ChatGPT 報 表 分 析"
            onClick={handleShowChatgpt}
            size="sm"
          >
            報 表 分 析
            <FontAwesomeIcon
              icon={faMagnifyingGlassChart}
              className="fs-6 ms-2"
            />
          </Button>
        </Col>
      </Row>
      <Card
        className="pb-4 pt-0 px-5"
        style={{
          borderRadius: '0.25rem 0rem 0.25rem 0rem',
        }}
      >
        <Row className="pt-4 pb-1 pageTitle d-flex">
          <Col xs="2" className="me-auto d-flex">
            <FontAwesomeIcon
              icon={faSquarePollVertical}
              className="fs-2 my-auto me-2 pt-1 text-luca"
            />
            <h3 className="my-auto text-luca fw-bolder">OVERVIEW</h3>
          </Col>
          <Col
            xs="4"
            className="py-3 px-0 my-auto ms-auto d-flex datePicker-noBorder"
          >
            <LucaDatePicker
              setting={{
                iconClassName: 'text-light',
                since: moment(timeRange.since).toDate(),
                until: moment(timeRange.until).toDate(),
                setSince: (date) => {
                  settimeRange({ ...timeRange, since: date, preset: -1 })
                },
                setUntil: (date) =>
                  settimeRange({ ...timeRange, until: date, preset: -1 }),
              }}
            />
          </Col>
          <Col xs="2" className="d-flex my-auto ps-0 pe-2 me-0">
            <DropdownButton
              className="DropdownFullWidth w-100 ms-3 my-auto"
              align="start"
              title={t(
                getTimeRanges()[timeRange.preset]
                  ? getTimeRanges()[timeRange.preset].label
                  : '- -'
              )}
              id="dropmenu"
              variant="light"
              size="sm"
            >
              {getTimeRanges().map((tr, i) => (
                <Dropdown.Item
                  key={i}
                  size="sm"
                  className="py-0"
                  onClick={() => settimeRange({ ...tr.timeRange, preset: i })}
                >
                  {t(tr.label)}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={1} className="d-flex my-auto px-0 ps-1">
            <DropdownButton
              className="DropdownFullWidth w-100 mx-1 my-auto"
              align="start"
              title="下載"
              id="dropmenu"
              variant="light"
              size="sm"
            >
              <Dropdown.Item size="sm" className="py-0" onClick={() => {}}>
                整份下載
              </Dropdown.Item>
              <Dropdown.Item size="sm" className="py-0" onClick={() => {}}>
                僅下載概覽
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        {!prepared ? (
          <Row
            style={{
              height: '50vh',
            }}
          >
            <Progress setting={{ prepared }} />
          </Row>
        ) : (
          <>
            <Row className="card p-3 pt-0 pe-0">
              <Row className="py-0 my-0">
                <h6 className="text-luca mt-auto fw-bolder w-25 mb-0 pb-1 text-start">
                  數據總和
                </h6>
                <DropdownButton
                  className="ms-auto DropdownFullWidth text-lucaLink noDropdownIcon bg-transp"
                  style={{
                    width: 'auto',
                  }}
                  align="start"
                  title={
                    <FontAwesomeIcon
                      className="fs-5"
                      title="依選擇排序顯示指標數據"
                      icon={faEllipsis}
                    />
                  }
                  id="dropmenu"
                  variant="light"
                  size="sm"
                >
                  <InputGroup
                    style={{ borderRadius: '.25rem' }}
                    className="m-2 w-90 border rounded-lg"
                    size="sm"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search Field..."
                      aria-label="Search Field..."
                      aria-describedby="btnGroupAddon"
                      title="欄位"
                      value={search}
                      onChange={(event) => setsearch(event.target.value)}
                    />
                  </InputGroup>
                  <div
                    className="overflow-auto scrollbarShow selectGroup"
                    style={{ height: '35vh' }}
                  >
                    {enums[provider].options.map((option) => (
                      <ListGroupItem
                        className="py-1 my-auto App-textSmall"
                        style={{
                          height: '29px',
                          color: '#21515a',
                        }}
                        key={option.name}
                        title={t(option.name)}
                        onClick={() =>
                          setsummaryFields(
                            summaryFields.includes(option.name)
                              ? summaryFields.filter((sf) => sf !== option.name)
                              : [...summaryFields, option.name]
                          )
                        }
                        active={summaryFields.includes(option.name)}
                      >
                        {t(option.name)}
                      </ListGroupItem>
                    ))}
                  </div>
                </DropdownButton>
              </Row>
              <Row
                className="d-flex position-relative pb-0 flex-nowrap overflow-auto"
                style={{ height: '13vh' }}
              >
                {summaryFields.map((f, i) => (
                  <Col xs={3} key={i} className="h-100 px-1 flex-grow-1">
                    <div
                      className="card px-2 pt-1"
                      style={{
                        borderRadius: '0.25rem 0.25rem 0 0',
                        height: '6vh',
                      }}
                    >
                      <h6 className="text-start">{t(f)}</h6>
                      <h5 className="text-end">
                        {accountsInsight.total && accountsInsight.total[0]
                          ? enums[provider].formatter(f)(
                              accountsInsight.total[0][f]
                            ) || 'No Data'
                          : 'No Data'}
                      </h5>
                    </div>
                    <div style={{ height: '7vh' }}>
                      <Insights
                        setting={{
                          insight: summaryInsights,
                          fields,
                          breakdowns: [],
                          dateBreakdown: 'all_days',
                          setcsvData: () => {},
                          provider,
                          checkedList: [],
                          setactiveid: () => {},
                          xs: 12,
                          insightType: 'StackChart',
                          selectedField: f,
                          setselectedField,
                          criterion: [[enums[provider].date]],
                          timeRange,
                          labelType: 'hide',
                          hideAxis: true,
                          style: {
                            borderRadius: '0 0 0.25rem 0.25rem',
                          },
                          colors: [colors[i % fields.length]],
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Row>
            <Row className="card p-3 pt-0 pe-0 mt-2">
              <Row className="py-0 my-0">
                <h6 className="text-luca mt-auto fw-bolder w-25 mb-0 text-start">
                  指標數據
                </h6>
                <DropdownButton
                  className="ms-auto DropdownFullWidth noDropdownIcon bg-transp"
                  style={{
                    width: 'auto',
                  }}
                  align="start"
                  title={
                    <FontAwesomeIcon
                      className="fs-5"
                      title="選擇指摽"
                      icon={faEllipsis}
                    />
                  }
                  id="dropmenu"
                  variant="light"
                  size="sm"
                >
                  <InputGroup
                    style={{ borderRadius: '.25rem' }}
                    className="m-2 w-90 border rounded-lg"
                    size="sm"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search Field..."
                      aria-label="Search Field..."
                      aria-describedby="btnGroupAddon"
                      title="搜尋欄位"
                      value={search}
                      onChange={(event) => setsearch(event.target.value)}
                    />
                  </InputGroup>
                  <div
                    className="overflow-auto scrollbarShow"
                    style={{
                      maxHeight: '35vh',
                    }}
                  >
                    {enums[provider].options.map((option, i) => (
                      <Dropdown.Item
                        key={i}
                        size="sm"
                        className="py-0 App-oneLineEllipsis"
                        onClick={() => setselectedField(option.name)}
                      >
                        {t(option.name)}
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
              </Row>
              <Row
                className="position-relative pt-0"
                style={{ height: '80vh' }}
              >
                {['PieChart', 'LineChart', 'StackChart'].map((insightType) => (
                  <Col
                    key={insightType}
                    xs={6}
                    className="h-50 flex-grow-1 my-1 mx-0 px-1"
                  >
                    <Insights
                      setting={{
                        insight: totalInsights[insightType],
                        auth,
                        fields,
                        breakdowns: [],
                        dateBreakdown: 'all_days',
                        setcsvData: () => {},
                        provider,
                        checkedList: [],
                        setactiveid: () => {},
                        xs: 12,
                        insightType,
                        selectedField,
                        timeRange,
                        style: {
                          borderRadius: '0.25rem',
                        },
                      }}
                    />
                  </Col>
                ))}
                <Col xs={6} className="h-50 my-1 mx-0 px-1">
                  <Insights
                    setting={{
                      insight: totalInsights.CrossChart,
                      auth,
                      fields,
                      breakdowns: [],
                      dateBreakdown: 'all_days',
                      setcsvData: () => {},
                      provider,
                      checkedList: [],
                      setactiveid: () => {},
                      xs: 12,
                      insightType: 'CrossChart',
                      selectedField,
                      style: {
                        borderRadius: '0.25rem',
                      },
                      timeRange,
                    }}
                  />
                </Col>
              </Row>
            </Row>
            <Row className="card p-3 pt-2 pe-0 mt-2">
              <Row className="py-0 my-0">
                <h6 className="text-luca mt-auto fw-bolder w-25 mb-0 text-start">
                  預算花費比例
                </h6>
                <div className="w-75 d-flex">
                  <div className="d-flex ms-auto">
                    <span className="mt-auto ms-auto pe-1 flex-nowrap text-luca fw-bolder">
                      匯率
                    </span>
                    <Form.Control
                      className="h-67 w-50 mt-auto"
                      value={totalSetting.rate}
                      onChange={(e) =>
                        settotalSetting({
                          ...totalSetting,
                          rate: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex mt-auto px-5 text-luca fw-bolder">
                    {['TWD', 'USD'].map((c) => (
                      <>
                        <Form.Check
                          className="px-1"
                          checked={totalSetting.view[c]}
                          onChange={() =>
                            settotalSetting({
                              ...totalSetting,
                              view: {
                                ...totalSetting.view,
                                [c]: !totalSetting.view[c],
                              },
                            })
                          }
                        />
                        {c}
                      </>
                    ))}
                  </div>
                  <div
                    className="d-flex mt-auto text-lucaLink4 fw-bolder"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => setsort(!sort)}
                    aria-hidden
                  >
                    <FontAwesomeIcon
                      className="fs-5 ms-auto my-auto pe-1"
                      title="選擇排序"
                      icon={sort ? faArrowUpWideShort : faArrowDownWideShort}
                    />
                    {sort ? '由低至高排序' : '由高至低排序'}
                  </div>
                </div>
              </Row>
              <Row>
                <Col>
                  <ReportPercent
                    setting={{
                      header: enums[provider].money,
                      criterion: 'account_id',
                      names,
                      rate: totalSetting.rate,
                      datas: Object.keys(accountsInsight)
                        .filter((key) => key !== 'total')
                        .filter((key) => {
                          const target = accounts.find(
                            (a) => a.setting.account_id === key
                          )
                          return (
                            target && totalSetting.view[target.setting.currency]
                          )
                        })
                        .map((key) => {
                          const target = accounts.find(
                            (a) => a.setting.account_id === key
                          )
                          return {
                            [enums[provider].money]:
                              accountsInsight[key][0][enums[provider].money] ===
                              '- -'
                                ? 0
                                : accountsInsight[key][0][
                                    enums[provider].money
                                  ],
                            account_id: key,
                            currency: target ? target.setting.currency : 'TWD',
                          }
                        })
                        .sort((a, b) => {
                          const header = enums[provider].money
                          const getMoney = (value) =>
                            value.currency === 'USD'
                              ? value[header] * 31
                              : value[header]
                          return sort
                            ? getMoney(a) - getMoney(b)
                            : getMoney(b) - getMoney(a)
                        }),
                      formatter: (value) =>
                        enums[provider].formatter('spend')(value),
                    }}
                  />
                </Col>
              </Row>
            </Row>
          </>
        )}
      </Card>

      <hr className="hrClass-dotted my-4" />

      {accountsInsight && prepared && (
        <Card
          className="pb-4 pt-0 px-5"
          style={{
            borderRadius: '0.25rem 0rem 0.25rem 0rem',
          }}
        >
          <Row className="d-flex mt-4 mb-2">
            <Col className="mt-auto">
              <h5 className="text-start text-luca fw-bolder">
                個 別 數 據 總 覽
              </h5>
            </Col>
            <Col xs={9} className="d-flex my-auto ms-auto ps-0 pe-1">
              <DropdownButton
                className="DropdownFullWidth w-25 ms-auto my-auto me-2"
                align="start"
                title={t(selectedSeperateField) || '選擇欄位'}
                id="dropmenu"
                variant="light"
                size="sm"
              >
                <InputGroup
                  style={{ borderRadius: '.25rem' }}
                  className="m-2 w-90 border rounded-lg"
                  size="sm"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search AdAccount..."
                    aria-label="Search AdAccount..."
                    aria-describedby="btnGroupAddon"
                    title="搜尋欄位"
                    value={search}
                    onChange={(event) => setsearch(event.target.value)}
                  />
                </InputGroup>
                <div
                  className="overflow-auto scrollbarShow"
                  style={{
                    maxHeight: '35vh',
                  }}
                >
                  {enums[provider].options.map((option, i) => (
                    <Dropdown.Item
                      key={i}
                      size="sm"
                      className="py-0 App-oneLineEllipsis"
                      onClick={() => setselectedSeperateField(option.name)}
                    >
                      {t(option.name)}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
              <DropdownButton
                className="my-auto w-50"
                align="start"
                title={<p className="App-oneLineEllipsis">{account.name}</p>}
                id="dropmenu"
                variant="light"
                size="sm"
              >
                <InputGroup
                  style={{ borderRadius: '.25rem' }}
                  className="m-2 w-90 border rounded-lg"
                  size="sm"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search AdAccount..."
                    aria-label="Search AdAccount..."
                    aria-describedby="btnGroupAddon"
                    title="搜尋廣告帳戶"
                    value={search}
                    onChange={(event) => setsearch(event.target.value)}
                  />
                </InputGroup>
                {accounts
                  .filter((a) => wordSearch(a.name, search))
                  .map((a, i) => (
                    <Dropdown.Item
                      key={i}
                      size="sm"
                      className="py-0"
                      onClick={() => setselected(a.setting.account_id)}
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </Col>
          </Row>
          {selected && (
            <>
              <Row className="card p-3 pt-2 pe-0 mt-2">
                <h6 className="text-luca mt-auto fw-bolder w-25 mb-0 text-start">
                  指標數據
                </h6>
                <Row
                  className="position-relative pt-0"
                  style={{ height: '80vh' }}
                >
                  {['LineChart', 'StackChart', 'PieChart'].map(
                    (insightType) => (
                      <Col
                        key={insightType}
                        xs={6}
                        className="h-50 flex-grow-1 px-1 my-1"
                      >
                        <Insights
                          setting={{
                            insight: selectedInsights[insightType],
                            auth,
                            account_id: selected,
                            fields,
                            breakdowns: [],
                            dateBreakdown: 'all_days',
                            setcsvData: () => {},
                            provider,
                            checkedList: [],
                            setactiveid: () => {},
                            xs: 12,
                            timeRange,
                            insightType,
                            labelType:
                              insightType === 'StackChart' && 'flex-nowrap',
                            selectedField: selectedSeperateField,
                          }}
                        />
                      </Col>
                    )
                  )}
                </Row>
              </Row>
              <Row className="card p-3 pt-0 pb-5 pe-0 mt-2">
                <Row className="py-0 mb-0 mt-2">
                  <h6 className="text-luca mt-auto fw-bolder w-25 mb-0 text-start">
                    預算花費比例
                  </h6>
                </Row>
                <Row
                  className="position-relative pt-0"
                  style={{ height: '40vh' }}
                >
                  <Col xs={6} className="h-100 flex-grow-1 my-1 mx-0 px-1">
                    <Insights
                      setting={{
                        insight: selectedInsights.Campaign,
                        auth,
                        account_id: selected,
                        fields,
                        breakdowns: [],
                        dateBreakdown: 'all_days',
                        setcsvData: () => {},
                        provider,
                        checkedList: [],
                        setactiveid: () => {},
                        xs: 12,
                        timeRange,
                        criterion: [['campaign_id']],
                        insightType: 'PieChart',
                        labelType: 'flex-nowrap',
                        selectedField: selectedSeperateField,
                      }}
                    />
                  </Col>
                  <Col xs={6} className="h-100 flex-grow-1 my-1 mx-0 px-1">
                    <Insights
                      setting={{
                        insight: selectedInsights.Adset,
                        auth,
                        account_id: selected,
                        fields,
                        breakdowns: [],
                        dateBreakdown: 'all_days',
                        setcsvData: () => {},
                        provider,
                        checkedList: [],
                        setactiveid: () => {},
                        xs: 12,
                        timeRange,
                        criterion: [['adset_id']],
                        insightType: 'PieChart',
                        labelType: 'flex-nowrap',
                        selectedField: selectedSeperateField,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col xs={12} className="d-flex">
                        <div
                          className="ms-auto mt-3 text-lucaLink4 fw-bolder"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => setaccountSort(!accountSort)}
                          aria-hidden
                        >
                          <FontAwesomeIcon
                            className="fs-5"
                            title="選擇排序"
                            icon={
                              accountSort
                                ? faArrowUpWideShort
                                : faArrowDownWideShort
                            }
                          />
                          {accountSort ? '由低至高排序' : '由高至低排序'}
                        </div>
                      </Col>
                    </Row>
                    {accountInsight && (
                      <ReportPercent
                        setting={{
                          header:
                            provider === 'google' ? 'cost_micros' : 'spend',
                          criterion: 'campaign_name',
                          datas: accountInsight
                            .map((at) => {
                              const target = accounts.find(
                                (a) => a.setting.account_id === selected
                              )
                              return {
                                ...at,
                                currency: target
                                  ? target.setting.currency
                                  : 'TWD',
                              }
                            })
                            .sort((a, b) => {
                              const header = enums[provider].money
                              const getMoney = (value) =>
                                value.currency === 'USD'
                                  ? value[header] * 31
                                  : value[header]
                              return accountSort
                                ? getMoney(a) - getMoney(b)
                                : getMoney(b) - getMoney(a)
                            }),
                          formatter: (value) =>
                            enums[provider].formatter('spend')(value),
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </Card>
      )}
      <ChargptModal
        setting={{
          show: chatgptShow,
          handleClose: handleCloseChatgpt,
        }}
      />
    </Container>
  )
}

ChargptModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Progress.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ADMReporter.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ADMReporter
