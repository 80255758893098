const optimizationGoals = new Set([
  'APP_INSTALLS',
  'BRAND_AWARENESS',
  'AD_RECALL_LIFT',
  'CLICKS',
  'ENGAGED_USERS',
  'EVENT_RESPONSES',
  'IMPRESSIONS',
  'LEAD_GENERATION',
  'QUALITY_LEAD',
  'LINK_CLICKS',
  'OFFER_CLAIMS',
  'OFFSITE_CONVERSIONS',
  'PAGE_ENGAGEMENT',
  'PAGE_LIKES',
  'POST_ENGAGEMENT',
  'QUALITY_CALL',
  'REACH',
  'SOCIAL_IMPRESSIONS',
  'APP_DOWNLOADS',
  'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
  'LANDING_PAGE_VIEWS',
  'VISIT_INSTAGRAM_PROFILE',
  'VALUE',
  'THRUPLAY',
  'REPLIES',
  'DERIVED_EVENTS',
])

const customEventType = new Set([
  'ADD_PAYMENT_INFO',
  'ADD_TO_CART',
  'ADD_TO_WISHLIST',
  'COMPLETE_REGISTRATION',
  'CONTENT_VIEW',
  'INITIATED_CHECKOUT',
  'LEAD',
  'PURCHASE',
  'SEARCH',
  'CONTACT',
  'CUSTOMIZE_PRODUCT',
  'DONATE',
  'FIND_LOCATION',
  'SCHEDULE',
  'START_TRIAL',
  'SUBMIT_APPLICATION',
  'SUBSCRIBE',
  'LISTING_INTERACTION',
  'FACEBOOK_SELECTED',
  'OTHER',
])

const bidStrategy = {
  LOWEST_COST_WITHOUT_CAP: [
    'APP_INSTALLS',
    'CONVERSIONS',
    'EVENT_RESPONSES',
    'LEAD_GENERATION',
    'LINK_CLICKS',
    'MESSAGES',
    'PAGE_LIKES',
    'POST_ENGAGEMENT',
    'PRODUCT_CATALOG_SALES',
    'REACH',
    'STORE_VISITS',
    'VIDEO_VIEWS',
  ],
  LOWEST_COST_WITH_BID_CAP: [
    'APP_INSTALLS',
    'CONVERSIONS',
    'EVENT_RESPONSES',
    'LEAD_GENERATION',
    'LINK_CLICKS',
    'MESSAGES',
    'PAGE_LIKES',
    'POST_ENGAGEMENT',
    'PRODUCT_CATALOG_SALES',
    'REACH',
    'STORE_VISITS',
    'VIDEO_VIEWS',
  ],
  COST_CAP: [
    'APP_INSTALLS',
    'CONVERSIONS',
    'EVENT_RESPONSES',
    'LEAD_GENERATION',
    'LINK_CLICKS',
    'MESSAGES',
    'PAGE_LIKES',
    'POST_ENGAGEMENT',
    'PRODUCT_CATALOG_SALES',
    'VIDEO_VIEWS',
  ],
  LOWEST_COST_WITH_MIN_ROAS: [
    'APP_INSTALLS',
    'CONVERSIONS',
    'PRODUCT_CATALOG_SALES',
  ],
}

const billingEvent = new Set([
  'IMPRESSIONS',
  'THRUPLAY',
  'LINK_CLICKS',
  'PURCHASE',
  'POST_ENGAGEMENT',
  'CLICKS',
  'APP_INSTALLS',
  'LISTING_INTERACTION',
  'NONE',
  'OFFER_CLAIMS',
  'PAGE_LIKES',
])

const callToActionType = new Set([
  'OPEN_LINK',
  'LIKE_PAGE',
  'SHOP_NOW',
  'PLAY_GAME',
  'INSTALL_APP',
  'USE_APP',
  'CALL',
  'CALL_ME',
  'VIDEO_CALL',
  'INSTALL_MOBILE_APP',
  'USE_MOBILE_APP',
  'MOBILE_DOWNLOAD',
  'BOOK_TRAVEL',
  'LISTEN_MUSIC',
  'WATCH_VIDEO',
  'LEARN_MORE',
  'SIGN_UP',
  'DOWNLOAD',
  'WATCH_MORE',
  'NO_BUTTON',
  'VISIT_PAGES_FEED',
  'CALL_NOW',
  'APPLY_NOW',
  'CONTACT',
  'BUY_NOW',
  'GET_OFFER',
  'GET_OFFER_VIEW',
  'BUY_TICKETS',
  'UPDATE_APP',
  'GET_DIRECTIONS',
  'BUY',
  'MESSAGE_PAGE',
  'DONATE',
  'SUBSCRIBE',
  'SAY_THANKS',
  'SELL_NOW',
  'SHARE',
  'DONATE_NOW',
  'GET_QUOTE',
  'CONTACT_US',
  'ORDER_NOW',
  'START_ORDER',
  'ADD_TO_CART',
  'VIDEO_ANNOTATION',
  'MOMENTS',
  'RECORD_NOW',
  'REFER_FRIENDS',
  'REQUEST_TIME',
  'GET_SHOWTIMES',
  'LISTEN_NOW',
  'WOODHENGE_SUPPORT',
  'SOTTO_SUBSCRIBE',
  'FOLLOW_USER',
  'EVENT_RSVP',
  'WHATSAPP_MESSAGE',
  'FOLLOW_NEWS_STORYLINE',
  'SEE_MORE',
  'FIND_A_GROUP',
  'FIND_YOUR_GROUPS',
  'PAY_TO_ACCESS',
  'PURCHASE_GIFT_CARDS',
  'FOLLOW_PAGE',
  'SEND_A_GIFT',
  'SWIPE_UP_SHOP',
  'SWIPE_UP_PRODUCT',
  'SEND_GIFT_MONEY',
])

const callToActionTarget = new Set([
  'app_destination',
  'app_link',
  'application',
  'event_id',
  'lead_gen_form_id',
  'link',
  'link_caption',
  'link_format',
  'page',
  'product_link',
])

const emptySet = {
  campaignId: '',
  purpose: 'BRAND_AWARENESS',
  promoted_object: { page_id: 'empty' },
  adsetId: '',
  creativeId: '',
  name: '',
  adId: '',
  adImageHash: '',
  adVideoId: '',
  link: '',
  message: '',
  objectStoryId: '',
  postId: '',
  lifetime_budget: '',
  daily_budget: '',
  bid_strategy: '',
  bid_amount: '',
  start_date: null,
  end_date: null,
  video_id: '',
  image_url: '',
  billing_event: 'IMPRESSIONS',
  call_to_action: { type: '', target: '', value: '' },
}

export {
  optimizationGoals,
  customEventType,
  bidStrategy,
  billingEvent,
  callToActionType,
  callToActionTarget,
  emptySet,
}
