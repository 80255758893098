import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import apiService from '../services/apiService'

function Rate({ setting }) {
  const { rate, names, key, handleChange } = setting
  const [editing, setediting] = useState(false)
  const [value, setvalue] = useState(rate[key])
  useEffect(() => setvalue(rate[key]), [rate])
  return (
    <div className="d-flex p-3 justify-content-center">
      <p className="my-auto w-25 pe-2 text-center">{names[key]}</p>
      <Form.Control
        className="my-auto w-50"
        name={key}
        value={value}
        onChange={(e) => setvalue(e.target.value)}
        disabled={!editing}
        isRequired
        isInvalid={!value}
      />
      <FontAwesomeIcon
        className="my-auto px-3 text-luca fs-3"
        title={(!value && '此欄位為必填') || (editing ? '確定修改' : '編輯')}
        style={{
          cursor: !value ? 'not-allowed' : 'pointer',
        }}
        icon={editing ? faCheckSquare : faPenSquare}
        onClick={() => {
          if (!value) return
          if (editing) handleChange(key, value)
          setediting(!editing)
        }}
        disabled={!value}
      />
    </div>
  )
}

function RateModal(props) {
  const { setting } = props
  const { show, handleClose } = setting

  const [rate, setrate] = useState({})
  useEffect(() => {
    const getRate = async () => {
      const res = await apiService.data({
        path: '/luca/content/public/Rate',
        method: 'get',
      })
      setrate(res.setting)
    }
    if (show) getRate()
  }, [show])

  const handleChange = async (key, value) => {
    const res = await apiService.data({
      path: '/luca/content/public/Rate',
      method: 'put',
      data: { ...rate, [key]: value },
    })
    setrate(res.setting)
  }

  const names = {
    USD: '美元 (USD)',
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="md"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h5>各幣別匯率預設值管理</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column AccformCard py-4">
        {Object.keys(rate).map((key) => (
          <Rate
            key={key}
            setting={{
              rate,
              names,
              key,
              value: rate[key],
              handleChange,
            }}
          />
        ))}
      </Modal.Body>
    </Modal>
  )
}

RateModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Rate.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default RateModal
