import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserPlus,
  faExclamationTriangle,
  faCheckCircle,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  UserCard,
  SearchBar,
  SelectBar,
  PermissionDialog,
  ConfirmForm,
  Datatable,
  LucaDatePicker,
} from '../components'
import apiService from '../services/apiService'
import { wordSearch, t, getIcon } from '../services/lucaFunctions'

function Remark({ setting }) {
  const { remark, handleRemarkChange = () => {} } = setting
  const [editing, setediting] = useState(false)
  window.document.onkeydown = (event) => {
    if (event.key === 'Enter' && !event.isComposing) {
      handleRemarkChange(editing)
      setediting(false)
    }
  }
  const showEdit = useMemo(() => typeof editing === 'string', [editing])
  return (
    <Row className="text-lucaDark d-flex">
      {showEdit ? (
        <Form.Control
          className="w-75"
          as="textarea"
          placeholder=" "
          value={editing}
          onChange={(e) => setediting(e.target.value)}
          onBlur={() => setediting(false)}
          rows={2}
        />
      ) : (
        <p className="App-threeEllipsis px-0 w-75" title={remark}>
          {remark || '- -'}
        </p>
      )}
      <FontAwesomeIcon
        className="btn-lucaIcon ps-2 mx-0 my-auto"
        icon={showEdit ? faCheckCircle : faEdit}
        title="編 輯 備 註"
        onClick={() => {
          if (showEdit) {
            handleRemarkChange(editing)
            setediting(false)
          } else setediting(' ')
        }}
      />
    </Row>
  )
}

function SummaryModal({ setting }) {
  const { show, handleClose } = setting
  const [timeRange, settimeRange] = useState({
    since: moment().subtract(7, 'days').toDate(),
    until: moment().toDate(),
  })
  const { since, until } = timeRange
  const [facebook, setfacebook] = useState(false)
  useEffect(() => {
    const getFacebook = async () => {
      const res = await apiService.data({
        path: `luca/insight/list/facebook`,
        method: 'get',
        params: {
          fields: [
            'name',
            'campaigns{daily_budget,lifetime_budget,effective_status,start_time,stop_time}',
          ],
        },
      })
      setfacebook(res)
    }
    getFacebook()
  }, [])

  const summary = useMemo(() => {
    const res = {
      active: 0,
      campaigns: 0,
      CBO: 0,
      CBOcampaigns: 0,
      list: [],
    }
    if (!facebook) return res
    facebook.forEach((f) => {
      const active = f.campaigns
        ? f.campaigns.data.filter(
            (c) =>
              moment(c.start_time).isBefore(until) &&
              moment(c.stop_time).isAfter(since)
          )
        : []
      const CBO = active.filter((a) => a.daily_budget || a.lifetime_budget)
      if (active.length > 0) {
        res.active += 1
        res.list.push({
          ...f,
          active: active.length,
          CBO: (CBO.length / active.length).toFixed(2) * 100,
        })
      }
      if (CBO.length > 0) res.CBO += 1
      res.campaigns += active.length
      res.CBOcampaigns += CBO.length
    })
    res.list = res.list.sort((a, b) => b.CBO - a.CBO)
    return res
  }, [facebook, since, until])
  const { active, campaigns, CBO, CBOcampaigns, list } = summary
  return (
    <Modal
      className="m-auto"
      style={{ zIndex: '1501' }}
      size="lg"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title className="w-100 d-flex">
          <h4 className="text-start">
            <span className="fw-bold">廣 告 帳 戶 總 覽</span>
          </h4>
        </Modal.Title>
      </Modal.Header>
      {facebook ? (
        <Modal.Body
          className="text-center pt-2 pb-4 px-4"
          style={{
            height: '82vh',
          }}
        >
          <div
            className="d-flex"
            style={{
              height: '10%',
            }}
          >
            <Col
              xs="4"
              className="px-0 my-auto ms-auto d-flex datePicker-hasBorder"
            >
              <LucaDatePicker
                setting={{
                  since: moment(timeRange.since).toDate(),
                  until: moment(timeRange.until).toDate(),
                  setSince: (date) => {
                    settimeRange({ ...timeRange, since: date, preset: -1 })
                  },
                  setUntil: (date) =>
                    settimeRange({ ...timeRange, until: date, preset: -1 }),
                }}
              />
            </Col>
            <Button className="my-auto ms-2" variant="luca" onClick={() => {}}>
              下 載
            </Button>
          </div>
          {/* <hr className="hrClass-dashed my-2" /> */}
          <div
            style={{
              height: '70%',
            }}
          >
            <div className="h-100 text-luca text-start">
              <div className="bordered">
                <Row>
                  <Col xs={2} />
                  <Col xs={5} className="py-2 fw-bold h5 mt-auto">
                    廣告帳戶總數
                  </Col>
                  <Col xs={3} className="py-2 mb-0 fw-bold h3 text-end">
                    {active}
                  </Col>
                  <Col xs={2} />
                </Row>
                <Row>
                  <Col xs={2} />
                  <Col xs={5} className="py-2 fw-bold h5 mt-auto">
                    活動中廣告活動
                  </Col>
                  <Col xs={3} className="py-2 mb-0 fw-bold h3 text-end">
                    {campaigns}
                  </Col>
                  <Col xs={2} />
                </Row>
                <Row>
                  <Col xs={2} />
                  <Col xs={5} className="py-2 fw-bold h5 mt-auto">
                    行銷活動最佳化帳戶數
                  </Col>
                  <Col xs={3} className="py-2 mb-0 fw-bold h3 text-end">
                    {CBO}&ensp;
                    {`(${(CBO / active).toFixed(2) * 100}%)`}
                  </Col>
                  <Col xs={2} />
                </Row>
                <Row>
                  <Col xs={2} />
                  <Col xs={5} className="py-2 fw-bold h5 mt-auto">
                    行銷活動最佳化廣告活動數
                  </Col>
                  <Col xs={3} className="py-2 mb-0 fw-bold h3 text-end">
                    {CBOcampaigns}&ensp;
                    {`(${(CBOcampaigns / campaigns).toFixed(2) * 100}%)`}
                  </Col>
                  <Col xs={2} />
                </Row>
              </div>
              <div className="h-75 overflow-scroll border rounded ms-1 mt-4 text-start users_ads_overview">
                <Datatable
                  setting={{
                    pageSize: 5,
                    hasPagination: true,
                    headers: [
                      '帳 戶',
                      '廣 告 活 動 數',
                      '最 佳 化 啟 用 比 率',
                    ],
                    content: list.map((l) =>
                      l.fill
                        ? {
                            // type: { value: '- -' },
                            name: { value: '- -' },
                            campaigns: { value: '- -' },
                            CBO: { value: '- -' },
                          }
                        : {
                            // type: { value: l.provider },
                            name: {
                              value: l.name,
                              // method: () => setaccount(a),
                            },
                            campaigns: { value: l.active },
                            CBO: { value: `${l.CBO}%` },
                          }
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="d-flex">
          <div className="m-auto d-flex my-5">
            <Spinner
              className="m-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        </Modal.Body>
      )}
    </Modal>
  )
}

function Users(props) {
  const { auth } = props
  const isAdmin = auth.account.business_id === 1

  const [showCBO, setshowCBO] = useState(false)

  const [users, setusers] = useState(false)
  const [filteredUser, setfilteredUser] = useState(users)
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    permit: '',
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    accountSort: JSON.stringify({ field: 'updated_on', order: 'desc' }),
    status: '',
    search: '',
    media: '',
    bm: '',
  })
  const { permit, sort, accountSort, status, search, media, bm } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })
  useEffect(() => {
    if (!auth.account.business_id) return
    if (auth.account.type !== 'BM') window.location.replace('/')
    apiService
      .data({
        path: `luca/business/${auth.account.business_id}/users`,
        method: 'get',
      })
      .then((value) => {
        setusers(value)
        setfilteredUser(value)
      })
  }, [auth.account])
  useEffect(() => {
    setfilteredUser(
      users
        ? users
            .filter(
              (user) =>
                (wordSearch(user.name, search) ||
                  wordSearch(user.email, search) ||
                  wordSearch(user.setting.remark || '', search)) &&
                (permit === '' || user.setting[permit])
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : []
    )
  }, [search, users, sort, permit])

  const handleUserChange = async (id, setting) => {
    const saved = await apiService.data({
      path: `luca/businessUser/${id}/${auth.account.business_id}`,
      method: 'put',
      data: { setting },
    })
    if (saved && !saved.error) {
      setusers(
        users.map((u) =>
          u.user_id === id ? { ...u, setting: saved.setting } : u
        )
      )
    }
  }

  const [adaccounts, setadaccounts] = useState([])
  useEffect(() => {
    const getAll = async () => {
      const allAdAccounts = await apiService.data({
        path: `luca/account`,
        method: 'get',
      })
      setadaccounts(
        allAdAccounts.accounts.map((a) => ({
          ...a,
          admNumber: allAdAccounts.managedAccounts[a.account_id]
            ? allAdAccounts.managedAccounts[a.account_id].length
            : 0,
          adm: allAdAccounts.managedAccounts[a.account_id] || [],
        }))
      )
    }
    getAll()
  }, [])

  const [showPermissionForm, setshowPermissionForm] = useState(false)

  const [permissionProfile, setpermissionProfile] = useState({
    type: 'users',
    title: '權 限 管 理',
    titleEng: 'Assign setting',
    itemTitle: '廣 告 帳 戶 列 表',
    assignedTitle: '已 指 派 帳 戶',
    filterField: 'account_id',
  })
  const [managed, setmanaged] = useState(false)
  const [assigned, setassigned] = useState({
    user: '',
    account: '',
    assigned: [],
  })
  const getManaged = async (id, type) => {
    if (type === 'users') {
      const assignedRes = await apiService.data({
        path: `luca/accountUser/${id}/${auth.account.business_id}`,
        method: 'get',
      })
      setassigned({
        user: id,
        account: '',
        assigned: assignedRes,
      })
      if (managed === false || type !== permissionProfile.type) {
        const managedRes = await apiService.data({
          path: `luca/account/${auth.account.business_id}`,
          method: 'get',
        })
        setmanaged(managedRes)
      }
      setpermissionProfile({
        type: 'users',
        title: '權 限 管 理',
        titleEng: 'Assign setting',
        itemTitle: '廣 告 帳 戶 列 表',
        assignedTitle: '已 指 派 帳 戶',
        filterField: 'account_id',
      })
    } else if (type === 'accounts') {
      const target = adaccounts.find((a) => a.account_id === id)
      if (!target) return
      const assignedRes = await apiService.data({
        path: `luca/accountUser/users/${id}/${auth.account.business_id}`,
        method: 'get',
      })
      setassigned({
        user: '',
        account: id,
        assigned: assignedRes,
      })
      if (managed === false || type !== permissionProfile.type) {
        const managedRes = await apiService.data({
          path: `luca/business/${auth.account.business_id}/users`,
          method: 'get',
        })
        setmanaged(managedRes)
      }
      setpermissionProfile({
        type: 'accounts',
        title: `權 限 管 理(${target.name})`,
        titleEng: 'Assign setting',
        itemTitle: '使 用 者 列 表',
        assignedTitle: '已 指 派 使 用 者',
        filterField: 'user_id',
      })
    }
    setshowPermissionForm(true)
  }

  const handlePermissionFormClose = () => setshowPermissionForm(false)

  // add and delete features
  const handleAddAccount = async (id) => {
    const res = await apiService.data({
      path: `luca/accountUser/${assigned.user || id}/${
        assigned.account || id
      }/${auth.account.business_id}`,
      method: 'post',
      data: { setting: 'Read' },
    })
    if (!res.error) {
      setassigned((prevState) => ({
        ...prevState,
        assigned: [
          ...prevState.assigned,
          managed.find((m) => m[permissionProfile.filterField] === id),
        ],
      }))
    }
  }
  const handleDeleteAccount = async (id) => {
    const res = await apiService.data({
      path: `luca/accountUser/${assigned.user || id}/${
        assigned.account || id
      }/${auth.account.business_id}`,
      method: 'delete',
    })
    if (!res.error) {
      setassigned({
        ...assigned,
        assigned: assigned.assigned.filter(
          (a) => a[permissionProfile.filterField] !== id
        ),
      })
    }
  }

  const handlePermissionChange = async (id, setting) => {
    const res = await apiService.data({
      path: `luca/accountUser/${assigned.user}/${id}/${auth.account.business_id}`,
      method: 'put',
      data: { setting },
    })
    if (!res.error) {
      setassigned({
        ...assigned,
        assigned: assigned.assigned.map((a) =>
          a.user_id !== id ? a : { ...a, setting: res.setting }
        ),
      })
    }
  }

  const [tempRemark, settempRemark] = useState({ index: 0, value: null })
  const editRemark = async () => {
    const res = await apiService.data({
      path: `luca/businessUser/${users[tempRemark.index].user_id}/${
        auth.account.business_id
      }`,
      method: 'put',
      data: {
        setting: {
          ...users[tempRemark.index].setting,
          remark: tempRemark.value.trim(),
        },
      },
    })
    if (!res.error) {
      setusers(
        users.map((u, i) => (i === tempRemark.index ? { ...u, ...res } : u))
      )
    }
    settempRemark({ ...tempRemark, value: null })
  }
  window.document.onkeydown = (event) => {
    if (event.key === 'Enter' && !event.isComposing) editRemark()
  }

  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
    hasFooter: true,
    warn: (
      <span className="text-center text-lucaDark">
        <div className="d-flex mb-3">
          <FontAwesomeIcon
            className="mx-auto display-1 mb-2 text-luca"
            icon={faExclamationTriangle}
          />
        </div>
        <h5>尚未儲存, 確定放棄修改嗎？</h5>
      </span>
    ),
  })
  const handleShowWarn = () =>
    setshowWarn({
      ...showWarn,
      show: true,
    })

  const handleWarnClose = (value) => {
    if (value) settempRemark({ ...tempRemark, value: null })
    setshowWarn({
      ...showWarn,
      show: false,
    })
  }

  const [tab, settab] = useState('Users')
  const names = {
    Users: '使用者管理',
    Accounts: '廣告帳號管理',
    Logs: '歷史變更紀錄',
  }

  // const accountStatus = {
  //   1: 'ACTIVE',
  //   2: 'DISABLED',
  //   3: 'UNSETTLED',
  //   7: 'PENDING_RISK_REVIEW',
  //   8: 'PENDING_SETTLEMENT',
  //   9: 'IN_GRACE_PERIOD',
  //   100: 'PENDING_CLOSURE',
  //   101: 'CLOSED',
  //   201: 'ANY_ACTIVE',
  //   202: 'ANY_CLOSED',
  // }
  const accountStatus = {
    0: '已啟用',
    1: '已啟用',
    2: '已停用',
    3: '未設定',
    7: '審核中',
    8: '審核中',
    9: '審核中',
    100: '審核中',
    101: '已關閉',
    201: '已啟用',
    202: '已關閉',
  }

  const bms = useMemo(
    () =>
      adaccounts
        ? adaccounts
            .reduce(
              (prev, cur) =>
                cur.setting.bm && !prev.includes(cur.setting.bm)
                  ? [...prev, cur.setting.bm]
                  : prev,
              []
            )
            .map((b) => ({ name: b, value: b }))
        : [],
    [adaccounts]
  )

  const filteredAccounts = useMemo(
    () =>
      adaccounts
        ? adaccounts
            .filter(
              (adaccount) =>
                (wordSearch(adaccount.name, search) ||
                  wordSearch(adaccount.email, search) ||
                  wordSearch(adaccount.setting.remark || '', search)) &&
                (permit === '' || adaccount.setting[permit]) &&
                (status === '' ||
                  accountStatus[adaccount.setting.account_status] === status) &&
                (media === '' || adaccount.provider === media) &&
                (bm === '' || adaccount.setting.bm === bm)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(accountSort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : [],

    [search, media, adaccounts, status, accountSort, bm]
  )

  const accountLog = useMemo(
    () =>
      adaccounts
        .filter(
          (adaccount) =>
            adaccount.setting.changeDate &&
            adaccount.setting.account_status &&
            adaccount.setting.old_status &&
            adaccount.setting.account_status !== adaccount.setting.old_status &&
            (wordSearch(adaccount.name, search) ||
              wordSearch(adaccount.email, search) ||
              wordSearch(adaccount.setting.remark || '', search)) &&
            (permit === '' || adaccount.setting[permit])
        )
        .sort((a, b) => {
          const { field, order } = JSON.parse(accountSort)
          return order === 'aesc'
            ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
            : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
        }),
    [adaccounts]
  )

  const deleteAdAccounts = async (account_id) => {
    await apiService.data({
      path: 'luca/account',
      method: 'delete',
      data: [account_id],
    })
    setadaccounts(adaccounts.filter((a) => a.account_id !== account_id))
  }

  useEffect(() => {
    if (!adaccounts) return
    const fix = async () => {
      const exist = []
      const shouldDelete = []
      adaccounts.forEach((adaccount) => {
        if (exist.includes(adaccount.setting.account_id)) {
          shouldDelete.push(adaccount.account_id)
        } else {
          exist.push(adaccount.setting.account_id)
        }
      })
      if (shouldDelete.length > 0) {
        const deleted = await apiService.data({
          path: 'luca/account',
          method: 'delete',
          data: shouldDelete,
        })
        if (deleted.length > 0) {
          setadaccounts(
            adaccounts.filter((a) => !shouldDelete.includes(a.account_id))
          )
        }
      }
      await apiService.data({
        path: 'luca/account/fix',
        method: 'get',
      })
    }
    fix()
  }, [adaccounts])

  return (
    <Container fluid className="authManage card px-2">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1">廣 告 帳 戶 與 成 員 權 限 設 定</h4>
        <p>ADM account and members authority setting</p>
        <p className="pageSubtitle pt-4">
          企 業 平 台 帳 戶 (ADM) 管 理 員 可 在 此 處 搜 尋 或 下 滑 並 分 配
          調 整 各 使 用 者 之 管 理 權 限
        </p>
      </Row>
      <Row className="pb-0 px-4">
        {isAdmin && (
          <Col xs={4} className="pe-0">
            <Nav
              variant="pills"
              className="w-100 flex-nowrap flex-row w-100 overflow-hidden"
            >
              {['Users', 'Accounts', 'Logs'].map((name) => (
                <Nav.Item
                  key={name}
                  style={{
                    width: '33%',
                  }}
                  className={`flex-shrink-1 overflow-hidden text-nowrap ${
                    name === tab ? 'selectedReportTabs' : 'budgetTabs'
                  }`}
                >
                  <Nav.Link
                    className="App-oneLineEllipsis px-2"
                    style={{ height: '2.75rem' }}
                    onClick={() => settab(name)}
                  >
                    {names[name]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
        )}
        <Col
          xs={isAdmin ? 8 : 12}
          className={`d-flex pe-0 justify-content-end ${isAdmin ? '' : 'mb-2'}`}
        >
          {tab === 'Users' && (
            <>
              <Col xs="3" className="ms-auto">
                <SelectBar
                  setting={{
                    name: 'permit',
                    method: handleFilterChange,
                    placeholder: '選擇權限類型',
                    content: [
                      { name: 'Admin', value: 'isAdmin' },
                      { name: 'AdsManager', value: 'isAdsManager' },
                      { name: 'Insights', value: 'isAnalyst' },
                      { name: 'Finance', value: 'isFinance' },
                    ],
                  }}
                />
              </Col>
              <Col xs="3" className="ps-2">
                <SelectBar
                  setting={{
                    method: (e) =>
                      handleFilterChange({
                        target: {
                          name: 'sort',
                          value:
                            e.target.value ||
                            JSON.stringify({ field: 'name', order: 'desc' }),
                        },
                      }),
                    value: sort,
                    placeholder: '選擇排序',
                    content: [
                      {
                        name: '排序 － 由最新至最舊',
                        value: JSON.stringify({
                          field: 'created_on',
                          order: 'desc',
                        }),
                      },
                      {
                        name: '排序 － 由最舊至最新',
                        value: JSON.stringify({
                          field: 'created_on',
                          order: 'aesc',
                        }),
                      },
                    ],
                  }}
                />
              </Col>
            </>
          )}
          {tab === 'Accounts' && (
            <>
              <Col xs="2" className="ms-auto pe-2">
                <SelectBar
                  setting={{
                    name: 'bm',
                    method: handleFilterChange,
                    placeholder: '選擇媒體BM',
                    content: bms,
                  }}
                />
              </Col>
              <Col xs="2" className="ms-auto pe-2">
                <SelectBar
                  setting={{
                    name: 'media',
                    method: handleFilterChange,
                    placeholder: '選擇媒體平台',
                    content: [
                      { name: 'Meta', value: 'facebook' },
                      { name: 'Google', value: 'google' },
                      { name: 'Twitter', value: 'twitter' },
                      { name: 'Criteo', value: 'criteo' },
                      // { name: 'TTD', value: 'TTD' },
                      { name: 'LinkedIn', value: 'linkedin' },
                    ],
                  }}
                />
              </Col>
              <Col xs="2" className="ms-auto">
                <SelectBar
                  setting={{
                    name: 'status',
                    method: handleFilterChange,
                    placeholder: '選擇帳戶狀態',
                    content: [
                      { name: '已啟用', value: '已啟用' },
                      { name: '已停用', value: '已停用' },
                      { name: '審核中', value: '審核中' },
                      { name: '已關閉', value: '已關閉' },
                    ],
                  }}
                />
              </Col>
            </>
          )}
          <Col xs="auto" className="ps-2 flex-grow-1">
            <SearchBar
              setting={{
                title: '請輸入搜尋關鍵字...',
                name: 'search',
                // action: <FontAwesomeIcon icon={faSearch} />,
                tempSearch,
                settempSearch,
                method: () =>
                  handleFilterChange({
                    target: {
                      name: 'search',
                      value: tempSearch,
                    },
                  }),
              }}
            />
          </Col>
          {tab === 'Accounts' && (
            <Col xs="auto" className="ps-2 pe-3">
              <Button
                className="d-flex w-100 my-auto"
                variant="luca"
                onClick={() => setshowCBO(true)}
              >
                最佳化狀態
              </Button>
            </Col>
          )}
        </Col>
      </Row>
      {tab === 'Users' && filteredUser && (
        <Row className="px-4 pt-0">
          <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {filteredUser.map((user, i) => (
              <UserCard
                key={user.user_id}
                handleUserChange={handleUserChange}
                setting={{
                  ...user,
                  id: user.user_id,
                  btnText: '指派廣告帳號',
                  tempRemark: tempRemark.index === i ? tempRemark.value : null,
                  handleEditRemark: (value) => {
                    if (tempRemark.index !== i && tempRemark.value)
                      handleShowWarn()
                    else settempRemark({ index: i, value })
                  },
                  editRemark,
                  handleShowWarn,
                  btnFunc: () => getManaged(user.user_id, 'users'),
                }}
              />
            ))}
          </div>
        </Row>
      )}
      {tab === 'Accounts' && adaccounts && (
        <Row className="px-4 pt-0 flex-fill">
          <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <Datatable
              setting={{
                hasCheckBox: false,
                hasButton: false,
                hasPagination: true,
                pageSize: 5,
                headers: [
                  '媒 體 平 台',
                  '帳 號 名 稱',
                  '建 立 日 期',
                  '最 後 更 新 日 期',
                  '已 指 派 ADM 數',
                  '狀 態',
                  '備 註',
                  '功 能 列',
                ],
                content: filteredAccounts
                  .concat(
                    Array(
                      filteredAccounts.length % 5 > 0 ||
                        filteredAccounts.length === 0
                        ? 5 - (filteredAccounts.length % 5)
                        : 0
                    ).fill({
                      fill: true,
                    })
                  )
                  .map((m) =>
                    m.fill
                      ? {
                          type: { value: '- -' },
                          name: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          updateDate: { value: '- -' },
                          adm: { value: '- -' },
                          status: { value: '- -' },
                          remark: { value: '- -' },
                          funs: { value: '- -' },
                        }
                      : {
                          type: {
                            value: (
                              <span className="d-flex">
                                {getIcon(m.setting.provider, '20px', '20px')}
                                <p className="me-auto my-auto">
                                  {m.setting.bm}
                                </p>
                              </span>
                            ),
                          },
                          name: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto overflow-hidden">
                                <span className="m-auto App-oneLineEllipsis">
                                  {m.name}
                                </span>
                              </div>
                            ),
                          },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">
                                  {moment(m.created_on).format('yyyy-MM-DD')}
                                </span>
                              </div>
                            ),
                          },
                          updateDate: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">
                                  {m.setting.changeDate
                                    ? moment(m.setting.changeDate).format(
                                        'yyyy-MM-DD'
                                      )
                                    : moment(
                                        m.updated_on || m.created_on
                                      ).format('yyyy-MM-DD')}
                                </span>
                              </div>
                            ),
                          },
                          adm: {
                            value: (
                              <OverlayTrigger
                                placement="right"
                                delay={{
                                  show: m.admNumber !== 0 ? 100 : 10000,
                                  hide: 300,
                                }}
                                overlay={
                                  <Tooltip
                                    className="quesTip"
                                    style={{
                                      zIndex: '9999',
                                    }}
                                  >
                                    {m.adm.map((a) => (
                                      <p>{a}</p>
                                    ))}
                                  </Tooltip>
                                }
                              >
                                <div className="formsTool w-75 d-flex mx-auto">
                                  <span className="m-auto">{m.admNumber}</span>
                                </div>
                              </OverlayTrigger>
                            ),
                          },
                          status: {
                            value:
                              accountStatus[m.setting.account_status] ||
                              '已啟用',
                          },
                          remark: {
                            value: (
                              <div className="formsTool">
                                <Remark
                                  setting={{
                                    remark: '',
                                  }}
                                />
                              </div>
                            ),
                          },
                          funs: {
                            value: (
                              <div className="formsTool">
                                <Button
                                  variant="lucaIcon"
                                  onClick={() => deleteAdAccounts(m.account_id)}
                                >
                                  <FontAwesomeIcon
                                    className="ms-auto align-self-center h5"
                                    icon={faTrashAlt}
                                    title="刪 除"
                                  />
                                </Button>
                                <Button
                                  variant="lucaIcon"
                                  onClick={() =>
                                    getManaged(m.account_id, 'accounts')
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="ms-auto align-self-center h5"
                                    icon={faUserPlus}
                                    title="明 細"
                                  />
                                </Button>
                              </div>
                            ),
                          },
                        }
                  ),
              }}
            />
          </div>
        </Row>
      )}
      {tab === 'Logs' && adaccounts && (
        <Row className="px-4 pt-0">
          <div style={{ minHeight: '50vh', overflowY: 'auto' }}>
            <Datatable
              setting={{
                hasCheckBox: false,
                hasButton: false,
                hasPagination: true,
                pageSize: 5,
                headers: [
                  '媒 體 平 台',
                  '帳 號 名 稱',
                  '最 後 更 新 日 期',
                  '狀 態',
                ],
                content: accountLog
                  .concat(
                    Array(
                      accountLog.length % 5 > 0 || accountLog.length === 0
                        ? 5 - (accountLog.length % 5)
                        : 0
                    ).fill({
                      fill: true,
                    })
                  )
                  .map((m) =>
                    m.fill
                      ? {
                          type: { value: '- -' },
                          name: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          updateDate: { value: '- -' },
                          status: { value: '- -' },
                        }
                      : {
                          type: {
                            value: (
                              <span className="d-flex">
                                {getIcon(m.setting.provider, '20px', '20px')}
                                <p className="me-auto my-auto">
                                  {t(m.provider)}
                                </p>
                              </span>
                            ),
                          },
                          name: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto overflow-hidden">
                                <span className="m-auto App-oneLineEllipsis">
                                  {m.name}
                                </span>
                              </div>
                            ),
                          },
                          updateDate: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">
                                  {moment(m.update_on || m.created_on).format(
                                    'yyyy-MM-DD'
                                  )}
                                </span>
                              </div>
                            ),
                          },
                          status: {
                            value:
                              `${accountStatus[m.setting.old_status]} -> ${
                                accountStatus[m.setting.account_status]
                              }` || '- -',
                          },
                        }
                  ),
              }}
            />
          </div>
        </Row>
      )}
      {managed && (
        <PermissionDialog
          setting={{
            ...permissionProfile,
            size: 'xl',
            show: showPermissionForm,
            managed,
            assigned: assigned.assigned,
            handleAddAccount,
            handleDeleteAccount,
            handleClose: handlePermissionFormClose,
            handlePermissionChange,
            settingType: 'select',
          }}
        />
      )}
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          title: '確 定 修 改',
          titleEng: 'Profile Edit Confirm',
          warning: showWarn.warn,
          handleClose: handleWarnClose,
        }}
      />
      <SummaryModal
        setting={{
          show: showCBO,
          handleClose: () => setshowCBO(false),
        }}
      />
    </Container>
  )
}

Remark.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SummaryModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Users.propTypes = {
  auth: PropTypes.shape().isRequired,
}

export default Users
