import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function InvoiceModal(props) {
  const { setting } = props
  const { show, handleClose, formData } = setting

  const { date, platform, adaccount, level, account, currency, amount } =
    formData
  const names = {
    date: '日期',
    adaccount: '帳戶名稱',
    level: '層級',
    account: '繳費帳戶',
    type: '申請類型',
    platform: '媒體平台',
    amount: '待繳金額',
    currency: '幣別',
    remark: '備註',
  }
  const values = {
    date,
    account,
    adaccount,
    level,
    platform,
    currency,
    amount,
    remark: '請於3日內至指定虛擬帳戶進行繳費，謝謝。',
  }
  const tips = {
    type: '說明',
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="lg"
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h4>廣告花費明細</h4>
          <p>Advance Ads Invoice</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column px-4 pb-5">
        <h1 className="text-luca text-center fw-bold py-3">
          {amount < 0
            ? `+ $ ${numeral(amount * -1).format('0,0')}`
            : `- $ ${numeral(amount).format('0,0')}`}
        </h1>
        <div className="d-flex w-100 flex-wrap px-3">
          {[
            'date',
            'platform',
            'adaccount',
            'level',
            'account',
            'amount',
            'currency',
            'remark',
          ].map((key) => (
            <React.Fragment key={key}>
              <p
                style={
                  key === 'type'
                    ? { display: 'flex' }
                    : {
                        textAlign: 'justify',
                        textAlignLast: 'justify',
                      }
                }
                className="mb-auto w-15 px-2 py-2"
              >
                {names[key]}
                {tips[key] && <Question setting={{ remark: tips[key] }} />}
              </p>
              <Form.Control
                size="sm"
                className="my-auto w-35 text-center flex-fill"
                name=""
                type="text"
                value={values[key]}
                readOnly
              />
            </React.Fragment>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

InvoiceModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Question.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InvoiceModal
