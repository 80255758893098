/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import InfoCard from './InfoCard'
import SelectBar from './SelectBar'
import { AuthContext } from './ContextProvider'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function BudgetModal(props) {
  const { setting } = props
  const { show, handleClose, formData } = setting
  const auth = useContext(AuthContext)
  const initForm = {
    // budget_id: '',
    // created_on: '',
    adaccount: '',
    status: '',
    type: '',
    platform: '',
    currency: '',
    amount: '',
    fee: '',
    remark: '',
  }
  const [form, setform] = useState(initForm)
  useEffect(() => {
    if (show) setform(formData || initForm)
  }, [show, formData])
  const handleDataChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value })

  const {
    budget_id,
    created_on,
    status,
    type,
    platform,
    adaccount,
    currency,
    amount,
    fee,
    remark,
    action,
  } = form

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="md"
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h4>
            {action === 'review'
              ? '儲值金入帳審核'
              : !formData
              ? '儲值申請單'
              : '儲值申請明細'}
          </h4>
          <p>Budget application receipt</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column AccformCard p-4 mb-1">
        <div className="border rounded pb-3 mb-3 bg-light">
          <InfoCard
            setting={{
              nohover: true,
              form: { ...auth.account, ...auth.account.setting },
              tip: '',
            }}
          />
        </div>
        {formData && (
          <>
            {created_on && (
              <div className="d-flex px-3 pt-2 justify-content-center">
                <p
                  style={{ textAlign: 'justify', textAlignLast: 'justify' }}
                  className="my-auto w-25 pe-3"
                >
                  單號
                </p>
                <Form.Control
                  size="sm"
                  className="my-auto w-75"
                  name="status"
                  type="text"
                  value={`${created_on
                    .substring(4, 16)
                    .replaceAll('-', '')
                    .replaceAll(':', '')}${budget_id}`}
                  readOnly
                />
              </div>
            )}
            <div className="d-flex px-3 pt-2 justify-content-center">
              <p
                style={{ textAlign: 'justify', textAlignLast: 'justify' }}
                className="my-auto w-25 pe-3"
              >
                申請狀態
              </p>
              <Form.Control
                size="sm"
                className="my-auto w-75"
                name="status"
                type="text"
                value={status}
                readOnly
              />
            </div>
          </>
        )}
        <div className="d-flex px-3 pt-2 justify-content-center">
          <span className="d-flex my-auto w-25 pe-2">
            申請類型
            <Question setting={{ remark: '說明' }} />
          </span>
          {formData ? (
            <Form.Control
              size="sm"
              className="my-auto w-75"
              name="type"
              type="text"
              value={type}
              readOnly
            />
          ) : (
            <SelectBar
              setting={{
                id: 'defaultBorder',
                className: 'w-75',
                name: 'type',
                method: handleDataChange,
                value: type,
                placeholder: '選擇申請類型',
                content: [
                  {
                    name: '預付型',
                    value: '預付型',
                  },
                  {
                    name: '預支型',
                    value: '預支型',
                  },
                  {
                    name: '預付＋預支型',
                    value: '預付＋預支型',
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="d-flex w-100 px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            媒體平台
          </p>
          {formData ? (
            <Form.Control
              size="sm"
              className="my-auto w-75"
              name="platform"
              type="text"
              value={platform}
              readOnly
            />
          ) : (
            <SelectBar
              setting={{
                id: 'defaultBorder',
                className: 'w-75',
                name: 'platform',
                method: handleDataChange,
                value: platform,
                placeholder: '選擇媒體平台',
                content: [
                  {
                    name: 'Meta',
                    value: 'Meta',
                  },
                  {
                    name: 'Google',
                    value: 'Google',
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="d-flex w-100 px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            儲值帳戶
          </p>
          {formData ? (
            <Form.Control
              size="sm"
              className="my-auto w-75"
              name="platform"
              type="text"
              value={adaccount}
              readOnly
            />
          ) : (
            <SelectBar
              setting={{
                id: 'defaultBorder',
                className: 'w-75',
                name: 'platform',
                method: handleDataChange,
                value: adaccount,
                placeholder: '選擇儲值帳戶',
                content: [
                  {
                    name: 'Meta',
                    value: 'Meta',
                  },
                  {
                    name: 'Google',
                    value: 'Google',
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="d-flex px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            儲值金額
          </p>
          <Form.Control
            size="sm"
            className="my-auto w-75"
            name="amount"
            type="number"
            value={amount}
            onChange={handleDataChange}
            readOnly={formData}
            // disabled={!editing}
            // isRequired
            // isInvalid={!value}
          />
        </div>
        <div className="d-flex px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            其他費用
          </p>
          <Form.Control
            size="sm"
            className="my-auto w-75"
            name="fee"
            type="number"
            value={fee}
            onChange={handleDataChange}
            readOnly={formData}
            // disabled={!editing}
            // isRequired
            // isInvalid={!value}
          />
        </div>
        <div className="d-flex px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            匯款總額
          </p>
          <Form.Control
            size="sm"
            className="my-auto w-75"
            name="amount"
            type="number"
            value={parseInt(amount, 10) + parseInt(fee, 10)}
            readOnly
            // disabled={!editing}
            // isRequired
            // isInvalid={!value}
          />
        </div>
        <div className="d-flex px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="my-auto w-25 pe-3"
          >
            幣別
          </p>
          {formData ? (
            <Form.Control
              size="sm"
              className="my-auto w-75"
              name="currency"
              type="text"
              value={currency}
              readOnly
            />
          ) : (
            <SelectBar
              setting={{
                id: 'defaultBorder',
                className: 'w-75',
                name: 'currency',
                method: handleDataChange,
                value: currency,
                placeholder: '選擇幣別',
                content: [
                  {
                    name: '美金',
                    value: 'USD',
                  },
                  {
                    name: '台幣',
                    value: 'TWD',
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="d-flex px-3 pt-2 justify-content-center">
          <p
            style={{ textAlign: 'justify', textAlignLast: 'justify' }}
            className="mb-auto w-25 pe-3 pt-1"
          >
            備註
          </p>
          {formData ? (
            <Form.Control
              as="textarea"
              size="sm"
              className="my-auto w-75"
              name="remark"
              type="text"
              value={remark}
              readOnly
            />
          ) : (
            <textarea
              className="w-75"
              style={{
                color: '#212529',
                backgroundColor: '#fff',
                borderColor: '#86b7fe',
                outline: '0',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
              }}
              name="remark"
              value={remark}
              placeholder=""
              onChange={handleDataChange}
            />
          )}
        </div>
      </Modal.Body>
      {!formData && (
        <Modal.Footer className="sendForm justify-content-center py-3">
          <Button variant="secondary" onClick={() => handleClose()}>
            取 消
          </Button>
          <Button
            variant="luca"
            onClick={() =>
              handleClose({
                ...form,
                action: 'send',
              })
            }
          >
            送 出
          </Button>
        </Modal.Footer>
      )}
      {action === 'review' && (
        <Modal.Footer className="sendForm justify-content-center py-4">
          <Button
            variant="danger"
            onClick={() =>
              handleClose({
                ...formData,
                status: '已退回',
                action: 'reject',
              })
            }
          >
            退 件
          </Button>
          <Button
            variant="luca"
            onClick={() =>
              handleClose({
                ...formData,
                status: '已通過',
                action: 'approve',
              })
            }
          >
            批 准
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

BudgetModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Question.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BudgetModal
