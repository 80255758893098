const FacebookInsightFields = [
  // Result
  { name: 'reach' },
  { name: 'impressions' },
  { name: 'frequency' },
  { name: 'spend' },
  { name: 'clicks' },
  { name: 'cpc' },
  // { name: 'cpp' },
  { name: 'cpm' },
  { name: 'ctr' },
  // Interactive
  // { name: 'actions:page_engagement' },
  // { name: 'actions:like' },
  // { name: 'actions:comment' },
  // { name: 'actions:post_engagement' },
  // { name: 'actions:post_reaction' },
  // { name: 'actions:onsite_conversion.post_save' },
  // { name: 'actions:post' },
  // { name: 'actions:rsvp' },
  // { name: 'actions:checkin' },
  // { name: 'cost_per_action_type:page_engagement' },
  // { name: 'cost_per_action_type:like' },
  // { name: 'cost_per_action_type:post_engagement' },
  // { name: 'cost_per_action_type:rsvp' },
  // { name: 'actions:onsite_conversion.messaging_first_reply' },
  // { name: 'actions:onsite_conversion.messaging_block' },
  // { name: 'cost_per_action_type:onsite_conversion.messaging_first_reply' },
  // { name: 'unique_video_continuous_2_sec_watched_actions:video_view' },
  // { name: 'video_continuous_2_sec_watched_actions:video_view' },
  // { name: 'actions:video_view' },
  // { name: 'video_thruplay_watched_actions:video_view' },
  { name: 'video_p25_watched_actions:video_view' },
  { name: 'video_p50_watched_actions:video_view' },
  { name: 'video_p75_watched_actions:video_view' },
  { name: 'video_p95_watched_actions:video_view' },
  { name: 'video_p100_watched_actions:video_view' },
  { name: 'video_avg_time_watched_actions:video_view' },
  { name: 'video_play_actions:video_view' },
  // { name: 'canvas_avg_view_time' },
  // { name: 'canvas_avg_view_percent' },
  // { name: 'cost_per_2_sec_continuous_video_view:video_view' },
  { name: 'cost_per_action_type:video_view' },
  // { name: 'cost_per_thruplay:video_view' },
  { name: 'actions:link_click' },
  // { name: 'unique_actions:link_click' },
  { name: 'outbound_clicks:outbound_click' },
  // { name: 'unique_outbound_clicks:outbound_click' },
  // { name: 'website_ctr:link_click' },
  // { name: 'unique_link_clicks_ctr' },
  // { name: 'outbound_clicks_ctr:outbound_click' },
  // { name: 'unique_outbound_clicks_ctr:outbound_click' },
  // { name: 'unique_clicks' },
  // { name: 'unique_ctr' },
  // { name: 'cost_per_action_type:link_click' },
  // { name: 'cost_per_unique_action_type:link_click' },
  { name: 'cost_per_outbound_click:outbound_click' },
  // { name: 'cost_per_unique_outbound_click:outbound_click' },
  // { name: 'cost_per_unique_click' },
  // { name: 'estimated_ad_recallers' },
  // { name: 'estimated_ad_recall_rate' },
  // { name: 'cost_per_estimated_ad_recallers' },
  // Conversions
  // { name: 'actions:omni_view_content' },
  // { name: 'cost_per_action_type:omni_view_content' },
  // { name: 'action_values:omni_view_content' },
  // { name: 'unique_actions:omni_view_content' },
  // { name: 'cost_per_unique_action_type:omni_view_content' },
  // { name: 'actions:offline_conversion.other' },
  // { name: 'cost_per_action_type:offline_conversion.other' },
  // { name: 'action_values:offline_conversion.other' },
  { name: 'actions:add_to_cart' },
  { name: 'cost_per_action_type:add_to_cart' },
  // { name: 'action_values:add_to_cart' },
  // { name: 'unique_actions:add_to_cart' },
  // { name: 'cost_per_unique_action_type:add_to_cart' },
  // { name: 'actions:add_to_wishlist' },
  // { name: 'cost_per_action_type:add_to_wishlist' },
  // { name: 'action_values:add_to_wishlist' },
  // { name: 'unique_actions:add_to_wishlist' },
  // { name: 'cost_per_unique_action_type:add_to_wishlist' },
  // { name: 'actions:omni_activate_app' },
  // { name: 'cost_per_action_type:omni_activate_app' },
  // { name: 'action_values:omni_activate_app' },
  // { name: 'unique_actions:omni_activate_app' },
  // { name: 'cost_per_unique_action_type:omni_activate_app' },
  // { name: 'conversions:find_location_total' },
  // { name: 'cost_per_conversion:find_location_total' },
  // { name: 'conversion_values:find_location_total' },
  // { name: 'actions:onsite_conversion.flow_complete' },
  // { name: 'cost_per_action_type:onsite_conversion.flow_complete' },
  // { name: 'action_values:onsite_conversion.flow_complete' },
  // { name: 'actions:omni_tutorial_completion' },
  // { name: 'cost_per_action_type:omni_tutorial_completion' },
  // { name: 'action_values:omni_tutorial_completion' },
  // { name: 'unique_actions:omni_tutorial_completion' },
  // { name: 'cost_per_unique_action_type:omni_tutorial_completion' },
  { name: 'actions:omni_complete_registration' },
  { name: 'cost_per_action_type:omni_complete_registration' },
  { name: 'cost_per_action_type:offsite_conversion.fb_pixel_purchase' },
  // { name: 'action_values:omni_complete_registration' },
  // { name: 'unique_actions:omni_complete_registration' },
  // { name: 'cost_per_unique_action_type:omni_complete_registration' },
  // { name: 'actions:omni_rate' },
  // { name: 'cost_per_action_type:omni_rate' },
  // { name: 'action_values:omni_rate' },
  // { name: 'unique_actions:omni_rate' },
  // { name: 'cost_per_unique_action_type:omni_rate' },
  // { name: 'actions:omni_spend_credits' },
  // { name: 'cost_per_action_type:omni_spend_credits' },
  // { name: 'action_values:omni_spend_credits' },
  // { name: 'unique_actions:omni_spend_credits' },
  // { name: 'cost_per_unique_action_type:omni_spend_credits' },
  // { name: 'conversions:ad_impression_mobile_app' },
  // { name: 'cost_per_conversion:ad_impression_mobile_app' },
  // { name: 'conversions:ad_click_mobile_app' },
  // { name: 'cost_per_conversion:ad_click_mobile_app' },
  // { name: 'actions:omni_app_install' },
  // { name: 'cost_per_action_type:omni_app_install' },
  // { name: 'actions:omni_achievement_unlocked' },
  // { name: 'cost_per_action_type:omni_achievement_unlocked' },
  // { name: 'action_values:omni_achievement_unlocked' },
  // { name: 'unique_actions:omni_achievement_unlocked' },
  // { name: 'cost_per_unique_action_type:omni_achievement_unlocked' },
  // { name: 'conversions:donate_total' },
  // { name: 'cost_per_conversion:donate_total' },
  // { name: 'conversion_values:donate_total' },
  // { name: 'actions:omni_search' },
  // { name: 'cost_per_action_type:omni_search' },
  // { name: 'action_values:omni_search' },
  // { name: 'unique_actions:omni_search' },
  // { name: 'cost_per_unique_action_type:omni_search' },
  // { name: 'actions:add_payment_info' },
  // { name: 'cost_per_action_type:add_payment_info' },
  // { name: 'action_values:add_payment_info' },
  // { name: 'unique_actions:add_payment_info' },
  // { name: 'cost_per_unique_action_type:add_payment_info' },
  // { name: 'actions:app_engagement' },
  // { name: 'cost_per_action_type:app_engagement' },
  // { name: 'actions:app_use' },
  // { name: 'cost_per_action_type:app_use' },
  // { name: 'actions:app_story' },
  // { name: 'cost_per_action_type:app_story' },
  // { name: 'actions:lead' },
  // { name: 'cost_per_action_type:lead' },
  // { name: 'action_values:lead' },
  // { name: 'actions:games.plays' },
  // { name: 'cost_per_action_type:games.plays' },
  // { name: 'conversions:submit_application_total' },
  // { name: 'cost_per_conversion:submit_application_total' },
  // { name: 'conversion_values:submit_application_total' },
  { name: 'actions:offsite_conversion.fb_pixel_add_to_cart' },
  // { name: 'actions:app_custom_event.fb_mobile_add_to_cart' },
  { name: 'actions:offsite_conversion.fb_pixel_purchase' },
  // { name: 'actions:app_custom_event.fb_mobile_purchase' },
  // { name: 'actions:omni_level_achieved' },
  // { name: 'cost_per_action_type:omni_level_achieved' },
  // { name: 'action_values:omni_level_achieved' },
  // { name: 'unique_actions:omni_level_achieved' },
  // { name: 'cost_per_unique_action_type:omni_level_achieved' },
  // { name: 'conversions:contact_total' },
  // { name: 'cost_per_conversion:contact_total' },
  // { name: 'conversion_values:contact_total' },
  // { name: 'actions:omni_custom' },
  // { name: 'cost_per_action_type:omni_custom' },
  // { name: 'conversions:customize_product_total' },
  // { name: 'cost_per_conversion:customize_product_total' },
  // { name: 'conversion_values:customize_product_total' },
  // { name: 'actions:app_custom_event.fb_mobile_d2_retention' },
  // { name: 'cost_per_action_type:app_custom_event.fb_mobile_d2_retention' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_d2_retention' },
  // {
  //   name: 'cost_per_unique_action_type:app_custom_event.fb_mobile_d2_retention',
  // },
  // { name: 'actions:app_custom_event.fb_mobile_d7_retention' },
  // { name: 'cost_per_action_type:app_custom_event.fb_mobile_d7_retention' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_d7_retention' },
  // {
  //   name: 'cost_per_unique_action_type:app_custom_event.fb_mobile_d7_retention',
  // },
  // { name: 'conversions:subscribe_total' },
  // { name: 'cost_per_conversion:subscribe_total' },
  // { name: 'conversion_values:subscribe_total' },
  // { name: 'actions:omni_purchase' },
  // { name: 'cost_per_action_type:omni_purchase' },
  // { name: 'action_values:omni_purchase' },
  // { name: 'unique_actions:omni_purchase' },
  // { name: 'cost_per_unique_action_type:omni_purchase' },
  { name: 'purchase_roas:omni_purchase' },
  // { name: 'actions:landing_page_view' },
  // { name: 'cost_per_action_type:landing_page_view' },
  // { name: 'unique_actions:landing_page_view' },
  // { name: 'cost_per_unique_action_type:landing_page_view' },
  // { name: 'actions:omni_initiated_checkout' },
  // { name: 'cost_per_action_type:omni_initiated_checkout' },
  // { name: 'action_values:omni_initiated_checkout' },
  // { name: 'unique_actions:omni_initiated_checkout' },
  // { name: 'cost_per_unique_action_type:omni_initiated_checkout' },
  // { name: 'conversions:start_trial_total' },
  // { name: 'cost_per_conversion:start_trial_total' },
  // { name: 'conversion_values:start_trial_total' },
  // { name: 'conversions:schedule_total' },
  // { name: 'cost_per_conversion:schedule_total' },
  // { name: 'conversion_values:schedule_total' },
  // { name: 'actions:app_custom_event.fb_mobile_content_view' },
  // { name: 'actions:offsite_conversion.fb_pixel_view_content' },
  // { name: 'actions:offline_conversion.view_content' },
  // { name: 'actions:onsite_conversion.view_content' },
  // { name: 'action_values:app_custom_event.fb_mobile_content_view' },
  // { name: 'action_values:offsite_conversion.fb_pixel_view_content' },
  // { name: 'action_values:offline_conversion.view_content' },
  // { name: 'action_values:onsite_conversion.view_content' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_content_view' },
  // { name: 'actions:offline_conversion.add_to_cart' },
  // { name: 'actions:onsite_conversion.add_to_cart' },
  // { name: 'action_values:app_custom_event.fb_mobile_add_to_cart' },
  // { name: 'action_values:offsite_conversion.fb_pixel_add_to_cart' },
  // { name: 'action_values:offline_conversion.add_to_cart' },
  // { name: 'action_values:onsite_conversion.add_to_cart' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_add_to_cart' },
  // { name: 'actions:app_custom_event.fb_mobile_add_to_wishlist' },
  // { name: 'actions:offsite_conversion.fb_pixel_add_to_wishlist' },
  // { name: 'actions:offline_conversion.add_to_wishlist' },
  // { name: 'action_values:app_custom_event.fb_mobile_add_to_wishlist' },
  // { name: 'action_values:offsite_conversion.fb_pixel_add_to_wishlist' },
  // { name: 'action_values:offline_conversion.add_to_wishlist' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_add_to_wishlist' },
  // { name: 'actions:app_custom_event.fb_mobile_activate_app' },
  // { name: 'action_values:app_custom_event.fb_mobile_activate_app' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_activate_app' },
  // { name: 'conversions:find_location_mobile_app' },
  // { name: 'conversions:find_location_website' },
  // { name: 'conversions:find_location_offline' },
  // { name: 'conversion_values:find_location_mobile_app' },
  // { name: 'conversion_values:find_location_website' },
  // { name: 'conversion_values:find_location_offline' },
  // { name: 'actions:app_custom_event.fb_mobile_tutorial_completion' },
  // { name: 'action_values:app_custom_event.fb_mobile_tutorial_completion' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_tutorial_completion' },
  // { name: 'actions:app_custom_event.fb_mobile_complete_registration' },
  // { name: 'actions:offsite_conversion.fb_pixel_complete_registration' },
  // { name: 'actions:offline_conversion.complete_registration' },
  // { name: 'action_values:app_custom_event.fb_mobile_complete_registration' },
  // { name: 'action_values:offsite_conversion.fb_pixel_complete_registration' },
  // { name: 'action_values:offline_conversion.complete_registration' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_complete_registration' },
  // { name: 'actions:app_custom_event.fb_mobile_rate' },
  // { name: 'action_values:app_custom_event.fb_mobile_rate' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_rate' },
  // { name: 'actions:app_custom_event.fb_mobile_spent_credits' },
  // { name: 'actions:credit_spent' },
  // { name: 'action_values:app_custom_event.fb_mobile_spent_credits' },
  // { name: 'action_values:credit_spent' },
  // {
  //   name: 'cost_per_unique_action_type:app_custom_event.fb_mobile_spent_credits',
  // },
  // { name: 'unique_actions:app_custom_event.fb_mobile_spent_credits' },
  // { name: 'actions:mobile_app_install' },
  // { name: 'actions:app_install' },
  // { name: 'actions:app_custom_event.fb_mobile_achievement_unlocked' },
  // { name: 'action_values:app_custom_event.fb_mobile_achievement_unlocked' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_achievement_unlocked' },
  // { name: 'conversions:donate_mobile_app' },
  // { name: 'conversions:donate_website' },
  // { name: 'conversions:donate_offline' },
  // { name: 'conversion_values:donate_mobile_app' },
  // { name: 'conversion_values:donate_website' },
  // { name: 'conversion_values:donate_offline' },
  // { name: 'actions:app_custom_event.fb_mobile_search' },
  // { name: 'actions:offsite_conversion.fb_pixel_search' },
  // { name: 'action_values:app_custom_event.fb_mobile_search' },
  // { name: 'actions:offline_conversion.search' },
  // { name: 'action_values:offsite_conversion.fb_pixel_search' },
  // { name: 'action_values:offline_conversion.search' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_search' },
  // { name: 'actions:app_custom_event.fb_mobile_add_payment_info' },
  // { name: 'actions:offsite_conversion.fb_pixel_add_payment_info' },
  // { name: 'actions:offline_conversion.add_payment_info' },
  // { name: 'action_values:app_custom_event.fb_mobile_add_payment_info' },
  // { name: 'action_values:offsite_conversion.fb_pixel_add_payment_info' },
  // { name: 'action_values:offline_conversion.add_payment_info' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_add_payment_info' },
  // { name: 'actions:offsite_conversion.fb_pixel_lead' },
  // { name: 'actions:offline_conversion.lead' },
  // { name: 'action_values:offsite_conversion.fb_pixel_lead' },
  // { name: 'action_values:offline_conversion.lead' },
  // { name: 'conversions:submit_application_mobile_app' },
  // { name: 'conversions:submit_application_website' },
  // { name: 'conversions:submit_application_offline' },
  // { name: 'conversion_values:submit_application_mobile_app' },
  // { name: 'conversion_values:submit_application_website' },
  // { name: 'conversion_values:submit_application_offline' },
  // { name: 'actions:app_custom_event.fb_mobile_level_achieved' },
  // { name: 'action_values:app_custom_event.fb_mobile_level_achieved' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_level_achieved' },
  // { name: 'conversions:contact_mobile_app' },
  // { name: 'conversions:contact_website' },
  // { name: 'conversions:contact_offline' },
  // { name: 'conversion_values:contact_mobile_app' },
  // { name: 'conversion_values:contact_website' },
  // { name: 'conversion_values:contact_offline' },
  // { name: 'actions:app_custom_event.other' },
  // { name: 'conversions:customize_product_mobile_app' },
  // { name: 'conversions:customize_product_website' },
  // { name: 'conversions:customize_product_offline' },
  // { name: 'conversion_values:customize_product_mobile_app' },
  // { name: 'conversion_values:customize_product_website' },
  // { name: 'conversion_values:customize_product_offline' },
  // { name: 'conversions:subscribe_mobile_app' },
  // { name: 'conversions:subscribe_website' },
  // { name: 'conversions:subscribe_offline' },
  // { name: 'conversion_values:subscribe_mobile_app' },
  // { name: 'conversion_values:subscribe_website' },
  // { name: 'conversion_values:subscribe_offline' },
  { name: 'actions:offline_conversion.purchase' },
  // { name: 'actions:onsite_conversion.purchase' },
  // { name: 'action_values:app_custom_event.fb_mobile_purchase' },
  // { name: 'action_values:offsite_conversion.fb_pixel_purchase' },
  // { name: 'action_values:offline_conversion.purchase' },
  // { name: 'action_values:onsite_conversion.purchase' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_purchase' },
  { name: 'website_purchase_roas:offsite_conversion.fb_pixel_purchase' },
  // { name: 'mobile_app_purchase_roas:app_custom_event.fb_mobile_purchase' },
  // { name: 'actions:app_custom_event.fb_mobile_initiated_checkout' },
  // { name: 'actions:offsite_conversion.fb_pixel_initiate_checkout' },
  // { name: 'actions:offline_conversion.initiate_checkout' },
  // { name: 'action_values:app_custom_event.fb_mobile_initiated_checkout' },
  // { name: 'action_values:offsite_conversion.fb_pixel_initiate_checkout' },
  // { name: 'action_values:offline_conversion.initiate_checkout' },
  // { name: 'unique_actions:app_custom_event.fb_mobile_initiated_checkout' },
  // { name: 'conversions:start_trial_mobile_app' },
  // { name: 'conversions:start_trial_website' },
  // { name: 'conversions:start_trial_offline' },
  // { name: 'conversion_values:start_trial_mobile_app' },
  // { name: 'conversion_values:start_trial_website' },
  // { name: 'conversion_values:start_trial_offline' },
  // { name: 'conversions:schedule_mobile_app' },
  // { name: 'conversions:schedule_website' },
  // { name: 'conversions:schedule_offline' },
  // { name: 'conversion_values:schedule_mobile_app' },
  // { name: 'conversion_values:schedule_website' },
  // { name: 'conversion_values:schedule_offline' },
  // Setting
  // { name: 'adset_bid_value' },
  // { name: 'adset_budget_value' },
]

export default FacebookInsightFields
