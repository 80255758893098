import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { successImg2 } from '../assets'
import {
  FormDialog,
  ConfirmForm,
  InfoDialog,
  Datatable,
  SearchBar,
  SelectBar,
} from '../components'
import { ToastContext } from '../components/ContextProvider'
import apiService from '../services/apiService'
import {
  wordSearch,
  getIcon,
  getForm,
  getFixedForm,
} from '../services/lucaFunctions'

function Forms(props) {
  const { auth } = props
  const { settoast } = useContext(ToastContext)
  const navigate = useNavigate()

  const [showInfo, setshowInfo] = useState(false)
  const handleCloseInfo = () => {
    setshowInfo(false)
  }

  // set form fields and extrafields if necessaray
  const [showForm, setshowForm] = useState(false)
  const [form, setform] = useState({
    pageLimit: 2,
    content: [],
  })

  // get managed
  const [managed, setmanaged] = useState([])
  useEffect(() => {
    if (!auth.auth.user_id) return
    const getManaged = async () => {
      const res = await apiService.data({
        path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
        method: 'get',
      })
      setmanaged(res)
    }
    getManaged()
  }, [auth])
  // set form data when show a form
  const [formData, setformData] = useState({})
  // const handleDataChange = (event) => {
  //   setformData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   })
  // }

  const { formId } = useParams()
  // get accessable forms
  const [forms, setforms] = useState([])
  const handleShowForm = (value) => {
    const target = getForm(value.type, value.setting.platform, managed)
    const status = value.status || value.setting.status
    setformData({ content: { ...value.content }, ...value.extraFields })
    let extraFields = [
      {
        name: '審核狀態',
        label: '審核狀態',
        type: 'fixed',
        value: '審核中',
      },
    ]
    if (status === '已 退 回')
      extraFields = [
        {
          name: '審核狀態',
          label: '審核狀態',
          type: 'fixed',
          value: '不通過',
        },
        {
          name: '退件原因',
          label: '退件原因',
          type: 'fixed',
          value: value.extraFields['退件原因'],
        },
      ]
    else if (status === '已 完 成')
      extraFields = [
        {
          name: '審核狀態',
          label: '審核狀態',
          type: 'fixed',
          value: '通過',
        },
        ...Object.keys(value.extraFields)
          .filter((key) => key !== '退件原因')
          .map((key) => ({
            name: key,
            label: key,
            type: 'fixed',
            value: value.extraFields[key],
          })),
      ]
    setform({
      ...target,
      form_id: value.form_id || formId,
      replys: value.replys || [],
      fixedContent: getFixedForm(auth, { ...value.content }),
      content: target.content.map((c) => ({
        ...c,
        disabled: status !== '已 退 回',
        type:
          status === '已 退 回' ||
          c.type === 'multimail' ||
          c.type === 'textarea' ||
          c.type === 'check'
            ? c.type
            : 'fixed',
        value: value.content[c.name],
      })),
      extraFields,
      manager: undefined,
      hasFooter: status === '已 退 回',
    })
    setshowForm(true)
  }
  useEffect(() => {
    if (!auth.account.business_id) return
    const getForms = async () => {
      const res = await apiService.data({
        path: `luca/form/${auth.account.business_id}/${auth.auth.user_id}`,
        method: 'get',
      })
      if (!res.error) setforms(res)
      if (formId) {
        const target = res.find((f) => f.form_id === parseInt(formId, 10))
        if (target) handleShowForm(target)
        navigate('/UserForms', { replace: true })
      }
    }
    getForms()
  }, [auth.account, auth.auth, formId])

  const handleShowInfo = () => setshowInfo(true)
  const handleSendForm = async (value) => {
    const data = {}
    Object.keys(value.content)
      .filter((key) => {
        const content = form.content.find((f) => f.name === key)
        if (content) {
          return (
            !content.dependency ||
            value.content[content.dependency.name] === content.dependency.value
          )
        }
        return true
      })
      .forEach((key) => {
        data[key] = value.content[key]
      })
    const res = await apiService.data({
      path: `luca/form/${form.form_id}`,
      method: 'put',
      data: {
        ...value,
        setting: { ...form.setting, status: '待 處 理' },
        status: '待 處 理',
        content: data,
        user: auth.auth.user_id,
        business: auth.account.business_id,
      },
    })
    if (!res.error) {
      setforms(
        forms.map((f) => (f.form_id === res.form_id ? { ...f, ...res } : f))
      )
      handleShowInfo(true)
    }
    // const res = await apiService.form('put', {
    //   ...value,
    //   setting: { ...value.setting, status: '待 處 理' },
    //   content: data,
    //   user: auth.auth.user_id,
    //   business: auth.account.business_id,
    // })
    // if (!res.error) handleShowInfo(true)
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
  })
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      const getArrayBuffer = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            resolve({ name: file.name, result: reader.result })
          })
          reader.readAsArrayBuffer(file)
        })
      const files = []
      Object.keys(formData.content).forEach((key) => {
        if (
          typeof formData.content[key] === 'object' &&
          formData.content[key][0] instanceof Blob
        ) {
          files.push(getArrayBuffer(formData.content[key][0]))
        }
      })
      const buffered = await Promise.all(files)
      const arrayed = buffered.map((buffer) => ({
        name: buffer.name,
        data: Array.from(new Uint8Array(buffer.result)),
      }))
      handleSendForm({ ...formData, files: JSON.stringify(arrayed) })
    } else setshowForm(true)
  }
  const handleShowWarn = (value) => {
    setshowWarn({
      ...value,
      show: true,
      shouldReturn: true,
    })
  }

  const handleCloseForm = (value) => {
    setshowForm(false)
    if (value)
      handleShowWarn({
        title: '表 單 送 出 確 認',
        titleEng: 'Form Sending Confirmation',
        text: (
          <h5 className="text-danger py-3">
            【注意】您正在為 {auth.account.name} 提出{value.type}
            表單修改。
            <br />
          </h5>
        ),
        warn: (
          <>
            {form.content
              .filter(
                (f) =>
                  !f.dependency ||
                  formData.content[f.dependency.name] === f.dependency.value
              )
              .map((f) => (
                <p className="lh-lg" key={f.name}>{`${f.name}: ${
                  formData.content[f.name]
                }`}</p>
              ))}
          </>
        ),
        handleClose: handleWarnClose,
      })
  }

  const handleDataChange = (event) => {
    setformData({
      ...formData,
      content: {
        ...formData.content,
        [event.target.name]: event.target.files || event.target.value,
      },
    })
  }

  // reply
  const [replyData, setreplyData] = useState({ content: '', file: '' })
  const handleReplyDataChange = (event) => {
    setreplyData({ ...replyData, [event.target.name]: event.target.value })
  }
  const handleReplySend = async () => {
    const getArrayBuffer = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          resolve(reader.result)
        })
        reader.readAsArrayBuffer(file)
      })
    const data = {
      content: replyData.content,
      user: `${auth.auth.name}`,
      user_id: auth.auth.user_id,
      date: moment().format('YYYY/MM/DD HH:mm'),
    }
    if (replyData.file !== '') {
      const files = []
      for (let i = 0; i < replyData.file.length; i += 1) {
        files.push(getArrayBuffer(replyData.file[i]))
      }
      const buffered = await Promise.all(files)
      const arrayed = buffered.map((buffer, i) => ({
        name: replyData.file[i].name,
        data: Array.from(new Uint8Array(buffer)),
      }))
      const uploaded = await apiService.data({
        path: `file`,
        method: 'post',
        data: {
          files: JSON.stringify(arrayed),
        },
      })
      if (uploaded.error) {
        if (
          uploaded.error.response &&
          uploaded.error.response.status === '413'
        ) {
          settoast({ show: true, text: '上 傳 失 敗 ， 檔 案 過 大' })
        } else {
          settoast({ show: true, text: '上 傳 失 敗 ， 請 重 試' })
        }
        return
      }
      data.files = uploaded
      console.log(uploaded)
    }
    const res = await apiService.data({
      path: `luca/form/${form.form_id}/reply`,
      method: 'post',
      data,
    })
    setreplyData({ content: '', file: '' })
    if (!res.error) {
      setforms(forms.map((f) => (f.form_id === res.form_id ? res : f)))
      setform({ ...form, replys: res.replys })
    }
  }

  const getFile = async (name) => {
    let res = await apiService.data({
      path: `static/${name}`,
      method: 'get',
      responseType: 'blob',
    })
    if (res.type === 'application/json') {
      res = await apiService.data({
        path: `file/${name}`,
        method: 'get',
        responseType: 'blob',
      })
    }
    const fileName = name.substring(0, name.lastIndexOf('_'))
    const url = URL.createObjectURL(res)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()
  }

  const types = {
    OpenAccount: '開 戶',
    Recharge: '儲 值',
    Permission: '權 限',
  }

  // filter
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    platform: '',
    search: '',
    status: '',
  })
  const { platform, search, status } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  const filteredForms = forms.filter(
    (f) =>
      f.status !== '已 刪 除' &&
      (platform === '' || f.setting.platform === platform) &&
      (status === '' || f.status === status || f.setting.status === status) &&
      (wordSearch(f.content['填表人'], search) ||
        wordSearch(f.content['填表人所屬單位'], search) ||
        wordSearch(f.content['客戶類型'], search) ||
        wordSearch(f.content['帳戶類型'], search) ||
        wordSearch(f.content['代理商工商登記公司名稱'], search) ||
        wordSearch(f.content['需求單位'], search) ||
        wordSearch(f.content['統一編號'], search))
  )

  return (
    <Container fluid className="card px-2">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1"> 我 的 媒 體 服 務 申 請 </h4>
        <p>My Media platform services application</p>
        <p className="pageSubtitle pt-4">
          此 頁 可 查 看 您 已 提 出 的 各 式 媒 體 平 台 服 務 及 儲 值 申 請
        </p>
      </Row>
      <Row className="pt-3 pb-0 px-4">
        <Col xs="3">
          <SelectBar
            setting={{
              name: 'platform',
              method: handleFilterChange,
              placeholder: '選擇媒體平台',
              content: [
                { name: 'Facebook', value: 'Facebook' },
                { name: 'Google', value: 'Google' },
                { name: 'X', value: 'Twitter' },
                { name: 'LinkedIn', value: 'LinkedIn' },
                // { name: 'YahooDSP', value: 'YahooDSP' },
                { name: 'Taboola', value: 'Taboola' },
                { name: 'YahooMA', value: 'YahooMA' },
                { name: 'Line', value: 'Line' },
                { name: 'TTD', value: 'TTD' },
                { name: 'Criteo', value: 'Criteo' },
                { name: 'Dcard', value: 'Dcard' },
                { name: 'Tiktok', value: 'Tiktok' },
              ],
            }}
          />
        </Col>
        <Col xs={3} className="ps-0">
          <SelectBar
            setting={{
              name: 'status',
              placeholder: '所有狀態...',
              method: handleFilterChange,
              content: ['待 處 理', '處 理 中', '已 完 成', '已 退 回'].map(
                (s) => ({
                  name: s,
                  value: s,
                })
              ),
            }}
          />
        </Col>
        <Col xs="6" className="ps-0">
          <SearchBar
            setting={{
              title: '請輸入搜尋關鍵字...',
              name: 'search',
              tempSearch,
              settempSearch,
              method: () =>
                handleFilterChange({
                  target: {
                    name: 'search',
                    value: tempSearch,
                  },
                }),
            }}
          />
        </Col>
      </Row>
      <Row className="px-4 pt-3 pb-2 h-100 overflow-auto">
        <Datatable
          setting={{
            hasCheckBox: false,
            hasButton: false,
            hasPagination: true,
            pageSize: 5,
            headers: ['平 台', '申 請 時 間', '狀 態', '功 能'],
            content: filteredForms
              .concat(
                Array(
                  filteredForms.length % 5 > 0
                    ? 5 - (filteredForms.length % 5)
                    : 0
                ).fill({
                  fill: true,
                })
              )
              .map((f) =>
                f.fill
                  ? {
                      type: { value: '- -' },
                      time: { value: '- -' },
                      status: { value: '- -' },
                      toollist: { value: '- -' },
                    }
                  : {
                      type: {
                        value: (
                          <span className="d-flex">
                            {getIcon(f.setting.platform, '20px', '20px')}
                            <p className="me-auto my-auto">{types[f.type]}</p>
                          </span>
                        ),
                      },
                      time: {
                        value: moment(f.created_on).format('YY-MM-DD HH:mm'),
                      },
                      status: { value: f.status || f.setting.status },
                      toollist: {
                        value: (
                          <div className="formsTool">
                            <Button onClick={() => handleShowForm(f)}>
                              <FontAwesomeIcon
                                icon={faEnvelopeOpenText}
                                title="查 看 申 請 內 容"
                              />
                            </Button>
                          </div>
                        ),
                      },
                      method: () => {},
                    }
              ),
          }}
        />
      </Row>
      <FormDialog
        setting={{
          size: 'md',
          show: showForm,
          form: {
            ...form,
            size: 'xl',
            title: '帳 戶 申 請',
            titleEng: 'Account approve',
          },
          formData,
          handleClose: handleCloseForm,
          btnText: '確 定',
          replyAble: true,
          replyData,
          handleReplyDataChange,
          handleReplySend,
          handleDataChange,
          auth,
          download: (name) => getFile(name),
          hasFooter: form.hasFooter,
        }}
      />
      <InfoDialog
        show={showInfo}
        handleClose={handleCloseInfo}
        setting={{
          imgSrc: successImg2,
          content: '修 改 已 儲 存 ! ',
          contentEng: 'Success Saved ! ',
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'lg',
          warning: (
            <div className="text-center">
              {showWarn.img && (
                <div className="my-3">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={showWarn.img}
                  />
                </div>
              )}
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: showWarn.handleClose,
        }}
      />
    </Container>
  )
}

Forms.propTypes = {
  auth: PropTypes.shape().isRequired,
}

export default Forms
