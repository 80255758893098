import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import Dropdown from 'react-bootstrap/Dropdown'
import InfiniteScroll from 'react-infinite-scroll-component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTimes,
  faAngleRight,
  faMinus,
} from '@fortawesome/free-solid-svg-icons'
import SelectBar from './SelectBar'
import { departs, wordSearch, getAvatar } from '../services/lucaFunctions'
import { accTypeGoogle, accTypeMeta } from '../assets'

function Tips(props) {
  const { setting } = props
  const { name, email, avatar_id, facebook, google, createdAt } = setting

  return (
    <Container className="BmsManager-Hover text-light py-3">
      <Row>
        <Col xs={4} className="py-4 px-1 my-auto">
          <Row>
            <Image
              src={getAvatar(avatar_id)}
              className="mx-auto w-50"
              alt="頭 像"
            />
          </Row>
          <Row className="d-flex justify-content-center">{name}</Row>
        </Col>
        <Col xs={8} className="text-start my-auto py-2">
          <Row className="mb-2">
            電子郵件
            <br />
            {email}
          </Row>
          <Row>
            建立時間
            <br /> {moment(createdAt).format('YYYY-MM-DD')}
          </Row>
          <Row className="AccType-sm">
            {facebook ? <Image src={accTypeMeta} /> : ''}
            {google ? <Image src={accTypeGoogle} /> : ''}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

function ManagedList(props) {
  const { setting } = props
  const {
    itemTitle,
    assignedTitle,
    items,
    assigned,
    filterField,
    handleAdd,
    handleDelete,
    handlePermissionChange,
    permissionType,
  } = setting
  const [checked, setchecked] = useState([])

  // filter
  const [filter, setfilter] = useState('')
  const [assignedFilter, setassignedFilter] = useState('')
  const filterSetting = {
    account_id: {
      func: (i) => filter === '' || i.provider === filter,
      select: {
        placeholder: '選擇媒體平台類型',
        content: [
          { name: 'Facebook', value: 'facebook' },
          { name: 'Google', value: 'google' },
        ],
      },
      style: 'default',
    },
    business_id: {
      func: (f, i) =>
        f === '' || i.setting['潮網銷售顧問*ERP業務專員/部門'] === f,
      select: {
        name: 'depart',
        placeholder: '選擇部門',
        content: departs.concat({
          name: '其他',
          value: '',
        }),
      },
      style: 'default',
    },
    user_id: {
      func: () => true,
      select: {
        name: 'depart',
        placeholder: '選擇部門',
        content: departs.concat({
          name: '其他',
          value: '',
        }),
      },
      style: 'usercard',
    },
  }

  // search
  const [search, setsearch] = useState('')
  const [assignedSearch, setassignedSearch] = useState('')
  const assignedIds = assigned.map((a) => a[filterField])

  const [loaded, setloaded] = useState(20)

  const filteredItems = items.filter(
    (i) =>
      wordSearch(i.name, search) &&
      !assignedIds.includes(i[filterField]) &&
      filterSetting[filterField].func(filter, i)
  )
  const filteredAssigned = assigned.filter(
    (a) =>
      wordSearch(a.name, assignedSearch) &&
      (assignedFilter === '' || a.provider === assignedFilter) &&
      filterSetting[filterField].func(assignedFilter, a)
  )

  const permissions = {
    isAdmin: 'ADM管理者',
    isAdsManager: '投放優化師',
    isAnalyst: '成效分析師',
    isFinance: '財務人員',
  }

  return (
    <Row className="px-0 mx-0">
      <Col style={{ width: '48.5%' }}>
        <Modal.Dialog className="h-100 d-flex my-0 py-3">
          <Modal.Header
            id="form-dialog-title"
            className="m-auto border-0 h6 text-lucaDark"
          >
            {itemTitle}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={4} className="BmsAccountSelector">
                <SelectBar
                  setting={{
                    method: (event) => {
                      setfilter(event.target.value)
                    },
                    ...filterSetting[filterField].select,
                  }}
                />
              </Col>
              <Col xs={4} className="ps-0">
                <Form.Control
                  type="text"
                  className="mb-3"
                  value={search}
                  placeholder="輸入關鍵字搜尋..."
                  onChange={(event) => setsearch(event.target.value)}
                />
              </Col>
              <Col xs={2} className="px-1">
                <Button
                  className="w-100"
                  variant="luca"
                  onClick={() => {
                    setchecked(
                      checked.length === filteredItems.length
                        ? []
                        : filteredItems.map((f) => f[filterField])
                    )
                  }}
                >
                  全選
                </Button>
              </Col>
              <Col xs={2} className="px-1">
                <Button
                  className="w-100"
                  variant="luca"
                  onClick={() => {
                    checked.forEach((c) => handleAdd(c))
                    setchecked([])
                  }}
                >
                  加入
                </Button>
              </Col>
            </Row>
            <ListGroup
              id="scrollTarget"
              className="selectGroup BmsSelector scrollbarShow"
              style={{ maxHeight: '22rem', overflowY: 'auto' }}
            >
              <InfiniteScroll
                dataLength={loaded}
                next={() => setloaded(loaded + 20)}
                hasMore={loaded < filteredItems.length}
                loader={<h4>Loading...</h4>}
                endMessage={<p />}
                scrollableTarget="scrollTarget"
              >
                {filteredItems.slice(0, loaded).map((i) =>
                  filterSetting[filterField].style === 'default' ? (
                    <ListGroupItem
                      onClick={() =>
                        setchecked(
                          checked.includes(i[filterField])
                            ? checked.filter((c) => c !== i[filterField])
                            : [...checked, i[filterField]]
                        )
                      }
                      className="d-flex my-auto"
                      style={{ minHeight: '3rem', height: '3rem' }}
                      key={i[filterField]}
                      title={i.name}
                      active={checked.includes(i[filterField])}
                    >
                      {i.provider === 'facebook' && (
                        <span>
                          <Image
                            height="20px"
                            width="20px"
                            className="me-2"
                            src={accTypeMeta}
                          />
                        </span>
                      )}
                      {i.provider === 'google' && (
                        <span>
                          <Image
                            height="20px"
                            width="20px"
                            className="me-2"
                            src={accTypeGoogle}
                          />
                        </span>
                      )}
                      <span className="my-auto App-oneLineEllipsis">
                        {i.name}
                      </span>
                      <FontAwesomeIcon
                        style={{ color: 'transparent' }}
                        className="ms-auto align-self-center"
                        icon={
                          checked.includes(i[filterField]) ? faMinus : faPlus
                        }
                        title="新 增"
                      />
                    </ListGroupItem>
                  ) : (
                    <OverlayTrigger
                      key={i.user_id}
                      placement="right"
                      delay={{ show: 350, hide: 350 }}
                      overlay={
                        <Tooltip
                          className="userTip"
                          style={{
                            zIndex: '9999',
                          }}
                        >
                          <Tips setting={i} />
                        </Tooltip>
                      }
                    >
                      <Row
                        onClick={() => handleAdd(i[filterField])}
                        className="p-2 px-3"
                      >
                        <Col xs={3} className="px-1">
                          <Row className="pt-2 d-flex justify-content-center w-100 m-0">
                            <Image
                              className="d-flex justify-content-center align-self-center p-0 m-0 w-45"
                              src={getAvatar(i.avatar_id)}
                              alt="頭 像"
                            />
                          </Row>
                          <Row
                            title={i.name}
                            className="d-block text-center pt-1 text-lucaDark small App-oneLineEllipsis w-100 m-0"
                          >
                            {i.name}
                          </Row>
                        </Col>
                        <Col xs={8} className="d-flex text-lucaDark p-0 m-0">
                          <p
                            title={i.email}
                            className="d-block my-auto px-1 small App-oneLineEllipsis w-100"
                          >
                            {i.email}
                          </p>
                        </Col>
                        <Col xs={1} className="ms-auto ps-0 d-flex">
                          <FontAwesomeIcon
                            style={{ color: 'transparent' }}
                            className="m-auto ms-0 align-self-center"
                            icon={faPlus}
                            title="新 增"
                          />
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  )
                )}
              </InfiniteScroll>
            </ListGroup>
          </Modal.Body>
        </Modal.Dialog>
      </Col>
      <Col xs={1} className="d-flex" style={{ width: '3%' }}>
        <FontAwesomeIcon
          className="m-auto fs-2 text-luca"
          icon={faAngleRight}
        />
      </Col>
      <Col style={{ width: '48.5%' }}>
        <Modal.Dialog className="h-100 d-flex my-0 py-3">
          <Modal.Header
            id="form-dialog-title"
            className="m-auto border-0 h6 text-lucaDark"
          >
            {assignedTitle} （ {assigned.length} ）
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={6} className="BmsAccountSelector">
                <SelectBar
                  setting={{
                    method: (event) => {
                      setassignedFilter(event.target.value)
                    },
                    ...filterSetting[filterField].select,
                  }}
                />
              </Col>
              <Col xs={6} className="ps-0">
                <Form.Control
                  type="text"
                  className="mb-3"
                  value={assignedSearch}
                  placeholder="輸入關鍵字搜尋..."
                  onChange={(event) => setassignedSearch(event.target.value)}
                />
              </Col>
            </Row>

            <ListGroup
              className="selectGroup scrollbarShow"
              style={{
                maxHeight: '22rem',
                minHeight: '22rem',
                overflowY: 'auto',
              }}
            >
              {filteredAssigned.map((a) =>
                filterSetting[filterField].style === 'default' ? (
                  <ListGroupItem
                    className="d-flex"
                    style={{
                      minHeight: '3rem',
                      height: '3rem',
                      overflow: 'unset',
                    }}
                    key={a[filterField]}
                    title={a.name}
                  >
                    {a.provider === 'facebook' && (
                      <span>
                        <Image
                          height="20px"
                          width="20px"
                          className="me-2"
                          src={accTypeMeta}
                        />
                      </span>
                    )}
                    {a.provider === 'google' && (
                      <span>
                        <Image
                          height="20px"
                          width="20px"
                          className="me-2"
                          src={accTypeGoogle}
                        />
                      </span>
                    )}
                    <span
                      style={{ maxWidth: '65%' }}
                      className="my-auto  App-oneLineEllipsis"
                    >
                      {a.name}
                    </span>
                    <div className="align-self-center ms-auto px-3">
                      {permissionType === 'select' && (
                        <SelectBar
                          setting={{
                            id: 'AuthTypeAccount',
                            name: '權限類型',
                            value: a.permission,
                            method: (e) =>
                              handlePermissionChange(
                                a.account_id,
                                e.target.value
                              ),
                            content: [
                              { name: '管 理', value: 'Admin' },
                              { name: '編 輯', value: 'Write' },
                              { name: '唯 讀', value: 'Read' },
                            ],
                            py: 'py-1',
                          }}
                        />
                      )}
                      {permissionType === 'adm' && (
                        <Dropdown className="my-auto ms-auto">
                          <Dropdown.Toggle
                            className="btn-outline-lucaLight px-1 fs-8"
                            id="dropdown-basic"
                            size="sm"
                          >
                            權限類型
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="px-3">
                            {[
                              'isAdmin',
                              'isAdsManager',
                              'isAnalyst',
                              'isFinance',
                            ].map((key) => (
                              <Form.Switch
                                className="small"
                                key={key}
                                label={permissions[key]}
                                checked={a.setting[key] || a.setting.isAdmin}
                                disabled={
                                  a.setting.isAdmin && key !== 'isAdmin'
                                }
                                onChange={() =>
                                  handlePermissionChange(a.business_id, {
                                    ...a.setting,
                                    [key]: !a.setting[key],
                                  })
                                }
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <FontAwesomeIcon
                      onClick={() => handleDelete(a[filterField])}
                      className="align-self-center btn-lucaIcon3"
                      icon={faTimes}
                      title="取 消"
                    />
                  </ListGroupItem>
                ) : (
                  <OverlayTrigger
                    key={a.user_id}
                    placement="left"
                    delay={{ show: 350, hide: 350 }}
                    overlay={
                      <Tooltip
                        className="userTip"
                        style={{
                          zIndex: '9999',
                        }}
                      >
                        <Tips setting={a} />
                      </Tooltip>
                    }
                  >
                    <Row key={a.user_id} className="p-2 px-3">
                      <Col xs={3} className="px-1">
                        <Row className="pt-2 d-flex justify-content-center w-100 m-0">
                          <Image
                            className="d-flex justify-content-center align-self-center p-0 m-0 w-45"
                            src={getAvatar(a.avatar_id)}
                            alt="頭 像"
                          />
                        </Row>
                        <Row
                          title={a.name}
                          className="d-block text-center pt-1 text-lucaDark small App-oneLineEllipsis w-100 m-0"
                        >
                          {a.name}
                        </Row>
                      </Col>
                      <Col xs={8} className="d-flex text-lucaDark p-0 m-0">
                        <Row
                          title={a.email}
                          className="d-block my-auto px-1 small App-oneLineEllipsis w-100"
                        >
                          {a.email}
                        </Row>
                      </Col>
                      <Col xs={1} className="d-flex p-0">
                        <FontAwesomeIcon
                          onClick={() => handleDelete(a[filterField])}
                          className="m-auto align-self-center btn-lucaIcon3"
                          icon={faTimes}
                          title="取 消"
                        />
                      </Col>
                    </Row>
                  </OverlayTrigger>
                )
              )}
            </ListGroup>
          </Modal.Body>
        </Modal.Dialog>
      </Col>
    </Row>
  )
}

ManagedList.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Tips.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ManagedList
