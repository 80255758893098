const GoogleInsightFields = [
  { name: 'Clicks', api: 'metrics.clicks' },
  { name: 'Impressions', api: 'metrics.impressions' },
  { name: 'Cost', api: 'metrics.cost_micros' },
  { name: 'CTR', api: 'metrics.ctr' },
  { name: 'Avg. CPC', api: 'metrics.average_cpc' },
  { name: 'Avg. CPM', api: 'metrics.average_cpm' },
  { name: 'Invalid click rate', api: 'metrics.invalid_click_rate' },
  { name: 'Avg. CPE', api: 'metrics.average_cpe' },
  { name: 'Avg. CPV', api: 'metrics.average_cpv' },
  { name: 'Interactions', api: 'metrics.interactions' },
  { name: 'Interaction Rate', api: 'metrics.interaction_rate' },
  { name: 'Avg. Cost', api: 'metrics.average_cost' },
  { name: 'Views', api: 'metrics.video_views' },
  { name: 'View rate', api: 'metrics.video_view_rate' },
  { name: 'Engagements', api: 'metrics.engagements' },
  { name: 'Engagement rate', api: 'metrics.engagement_rate' },
  { name: 'Viewable impr.', api: 'metrics.active_view_impressions' },
  {
    name: 'Measurable impr.',
    api: 'metrics.active_view_measurable_impressions',
  },
  {
    name: 'Measurable cost',
    api: 'metrics.active_view_measurable_cost_micros',
  },
  { name: 'Measurable rate', api: 'metrics.active_view_measurability' },
  { name: 'Viewable rate', api: 'metrics.active_view_viewability' },
  { name: 'Conversions', api: 'metrics.conversions' },
  { name: 'Cost / conv.', api: 'metrics.cost_per_conversion' },
  { name: 'Conv. rate', api: 'metrics.conversions_from_interactions_rate' },
  { name: 'View-through conv.', api: 'metrics.view_through_conversions' },
  { name: 'All conv.', api: 'metrics.all_conversions' },
  {
    name: 'All conv. rate',
    api: 'metrics.all_conversions_from_interactions_rate',
  },
  { name: 'Cost / all conv.', api: 'metrics.cost_per_all_conversions' },
  { name: 'All conv. value', api: 'metrics.all_conversions_value' },
  { name: 'Value / all conv.', api: 'metrics.value_per_all_conversions' },
  {
    name: 'All conv. value / Cost',
    api: 'metrics.all_conversions_value_per_cost',
  },
  { name: 'Cross-device conv.', api: 'metrics.cross_device_conversions' },
  { name: 'Conv. value', api: 'metrics.conversions_value' },
  { name: 'Value / conv.', api: 'metrics.value_per_conversion' },
  { name: 'Search Impr. share', api: 'metrics.search_impression_share' },
  {
    name: 'Search Exact match IS',
    api: 'metrics.search_exact_match_impression_share',
  },
  {
    name: 'Search Lost IS (rank)',
    api: 'metrics.search_rank_lost_impression_share',
  },
  {
    name: 'Search Lost IS (budget)',
    api: 'metrics.search_budget_lost_impression_share',
  },
  { name: 'Display Impr. share', api: 'metrics.content_impression_share' },
  {
    name: 'Display Lost IS (rank)',
    api: 'metrics.content_rank_lost_impression_share',
  },
  {
    name: 'Display Lost IS (budget)',
    api: 'metrics.content_budget_lost_impression_share',
  },
]

export default GoogleInsightFields
