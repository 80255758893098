import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { userImgDefault } from '../assets'

function BusinessCard(props) {
  const { setting } = props
  const { name, createdAt } = setting
  return (
    <Card className="text-start p-0 mb-3">
      <Card.Body>
        <Container className="p-0">
          <Row>
            <Col xs={3}>
              <Row className="mt-2">
                <Image
                  style={{ margin: '0 auto' }}
                  className="d-flex justify-content-center align-self-center w-35 py-2"
                  src={userImgDefault}
                  alt="頭 像"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-2 text-lucaDark">
                {name}
              </Row>
            </Col>
            <Col xs="3" className="py-1 text-lucaDark py-3">
              <Row>建立時間： {moment(createdAt).format('YYYY-MM-DD')}</Row>
            </Col>
            <Col xs="3" className="px-5 py-3">
              <Row className="text-lucaDark">
                備註內容
                <FontAwesomeIcon
                  className="btn-lucaIcon fs-3 p-1 pb-2 m-0 mb-2"
                  icon={faEdit}
                  title="編 輯 備 註"
                />
              </Row>
              <Row style={{ fontSize: '0.9rem' }}>
                聽說這是一位VIP用戶。註解可以寫在這個地方，不過如果你輸入太多字的話，系統可能會自動幫你省略喔。
              </Row>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

BusinessCard.propTypes = {
  setting: PropTypes.shape(),
}

BusinessCard.defaultProps = {
  setting: {
    name: '',
  },
}

export default BusinessCard
