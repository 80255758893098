import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

function Slide(props) {
  const { setting } = props
  const { interval, content } = setting

  return (
    <Card className="p-0 h-100">
      <Carousel indicators={false} className="h-100">
        {content.map &&
          content.map((c, i) => (
            <Carousel.Item
              key={i}
              interval={interval}
              className="position-relative h-100"
            >
              <Image
                style={{
                  borderRadius: '0.25rem',
                  height: '100%',
                  opacity: '1',
                  zIndex: '80',
                  pointerEvents: 'none',
                }}
                className="d-block w-100"
                src={c.imgSrc}
                alt={c.label}
              />
              <div
                className="position-absolute h-100 w-100"
                style={{
                  top: '0px',
                  zIndex: '100',
                  backgroundColor: '#21515a',
                  opacity: '0.7',
                  pointerEvents: 'none',
                  borderRadius: '0.25rem',
                }}
              />
              <Carousel.Caption
                style={{
                  top: '0rem',
                  zIndex: '120',
                  cursor: 'pointer',
                }}
                className="d-flex flex-column h-100"
                // eslint-disable-next-line no-restricted-globals
                onClick={() => window.open(c.href)}
              >
                <p
                  className="App-oneLineEllipsis fs-5 mt-auto mb-0"
                  title={c.label}
                >
                  {c.label}
                </p>
                <p className="App-threeEllipsis mb-auto pt-2" title={c.content}>
                  {c.content}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    </Card>
  )
}

Slide.propTypes = {
  setting: PropTypes.shape(),
}

Slide.defaultProps = {
  setting: {
    interval: 8000,
    content: [
      {
        label: '2022 潮網 New SaaS 數據驅動服務',
        content:
          '在 PUNWAVE 的技術架構下，Nautilus 報表系統承襲跨媒體的優勢，媒體人員能透過Nautilus 系統直接抓取Google 及Facebook 上行銷活動的成效數據。',
        imgSrc:
          'https://www.wavenet.com.tw/wp-content/uploads/2021/11/首頁-Banner-Slider-1920x600.001-min.png',
      },
      {
        label: '對抗演算法！降觸及也不怕的互動攻略',
        content:
          '在 PUNWAVE 的技術架構下，Nautilus 報表系統承襲跨媒體的優勢，媒體人員能透過Nautilus 系統直接抓取Google 及Facebook 上行銷活動的成效數據。',
        imgSrc:
          'https://www.wavenet.com.tw/wp-content/uploads/2021/11/首頁-Banner-Slider-1920x600.001-min.png',
      },
      {
        label: 'Ｔ潮網數據洞察平台協助企業挖掘商機',
        content:
          '在 PUNWAVE 的技術架構下，Nautilus 報表系統承襲跨媒體的優勢，媒體人員能透過Nautilus 系統直接抓取Google 及Facebook 上行銷活動的成效數據。',
        imgSrc:
          'https://www.wavenet.com.tw/wp-content/uploads/2021/11/首頁-Banner-Slider-1920x600.001-min.png',
      },
    ],
  },
}

export default Slide
