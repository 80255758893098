import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LoginForm from './LoginForm'
import RequestResetForm from './RequestResetForm'
import LoadingButton from '../LoadingButton'

function Form(props) {
  const [action, setAction] = useState('login')
  const { setting } = props
  const {
    show,
    handleClose,
    handleRequestReset,
    handleGoRegister,
    requestError,
    setrequestError,
  } = setting

  const [data, setdata] = useState({ email: '', password: '' })
  const onDataChange = (event) => {
    setrequestError(false)
    setdata({ ...data, [event.target.name]: event.target.value })
  }

  // window.document.onkeydown = (event) => {
  //   if (event.key === 'Enter' && !event.isComposing)
  //     handleClose({ action, data })
  // }

  const forms = {
    login: {
      title: '登 入',
      engTitle: 'Login',
      content: (
        <LoginForm
          setting={{
            fields: [
              {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter Email...',
              },
              {
                label: 'Password',
                name: 'password',
                type: 'password',
                placeholder: 'Enter Password...',
              },
            ],
            onDataChange,
          }}
        />
      ),
      btnText: '登入',
      link: '忘記密碼？',
      linkFunc: () => setAction('resetPassword'),
      handleClose: () => handleClose({ action, data }),
      keys: ['email', 'password'],
    },
    resetPassword: {
      title: '忘 記 密 碼',
      engTitle: 'Forget password',
      content: (
        <RequestResetForm
          setting={{
            fields: [
              {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter Email...',
              },
            ],
            onDataChange,
            error: requestError,
          }}
        />
      ),
      btnText: '寄發驗證信',
      link: '登入',
      linkFunc: () => setAction('login'),
      handleClose: () => handleRequestReset({ action, data }),
      keys: ['email'],
    },
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="p-2"
    >
      <Modal.Body id="LoginModal">
        <Modal.Title>{forms[action].title}</Modal.Title>
        <p>{forms[action].engTitle}</p>
        {forms[action].content}
        <LoadingButton
          variant="luca"
          onClick={forms[action].handleClose}
          disabled={forms[action].keys.some((key) => data[key] === '')}
          btnText={forms[action].btnText}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ boxShadow: 'none', color: '#317985' }}
          variant="link"
          onClick={handleGoRegister}
        >
          註冊
        </Button>
        <Button
          style={{ boxShadow: 'none', color: '#317985' }}
          variant="link"
          onClick={forms[action].linkFunc}
        >
          {forms[action].link}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

Form.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Form
