const numeral = require('numeral')

const funcs = {
  reach: (value) => numeral(value).format('0,0'),
  impressions: (value) => numeral(value).format('0,0'),
  clicks: (value) => numeral(value).format('0,0'),
  frequency: (value) => numeral(value).format('0,0.00'),
  spend: (value) => numeral(value).format('$0,0.00'),
  cpp: (value) => numeral(value).format('$0,0.00'),
  cpc: (value) => numeral(value).format('$0,0.00'),
  cpm: (value) => numeral(value).format('$0,0.00'),
  ctr: (value) => numeral(value / 100).format('0.00%'),
  'actions:link_click': (value) => numeral(value).format('0,0'),
  'actions:add_to_cart': (value) => numeral(value).format('0,0'),
  'actions:omni_complete_registration': (value) => numeral(value).format('0,0'),
  'actions:offsite_conversion.fb_pixel_purchase': (value) =>
    numeral(value).format('0,0'),
  'cost_per_action_type:offsite_conversion.fb_pixel_purchase': (value) =>
    numeral(value).format('$0,0.00'),
  'actions:offsite_conversion.fb_pixel_add_to_cart': (value) =>
    numeral(value).format('0,0'),
  'actions:offline_conversion.purchase': (value) =>
    numeral(value).format('0,0'),
  'video_p25_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_p50_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_p75_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_p95_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_p100_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_avg_time_watched_actions:video_view': (value) =>
    numeral(value).format('0,0'),
  'video_play_actions:video_view': (value) => numeral(value).format('0,0'),
  'cost_per_action_type:like': (value) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:page_engagement': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:post_engagement': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_2_sec_continuous_video_view:video_view': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:video_view': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_thruplay:video_view': (value) => numeral(value).format('$0,0.00'),
  'website_ctr:link_click': (value) => numeral(value / 100).format('0.00%'),
  unique_link_clicks_ctr: (value) => numeral(value / 100).format('0.00%'),
  'outbound_clicks_ctr:outbound_click': (value) =>
    numeral(value / 100).format('0.00%'),
  'unique_outbound_clicks_ctr:outbound_click': (value) =>
    numeral(value / 100).format('0.00%'),
  unique_ctr: (value) => numeral(value / 100).format('0.00%'),
  'cost_per_action_type:link_click': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:link_click': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_outbound_click:outbound_click': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_outbound_click:outbound_click': (value) =>
    numeral(value).format('$0,0.00'),
  cost_per_unique_click: (value) => numeral(value).format('$0,0.00'),
  cost_per_estimated_ad_recallers: (value) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_view_content': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_view_content': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:offline_conversion.other': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:add_to_cart': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:add_to_cart': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:add_to_wishlist': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:add_to_wishlist': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_activate_app': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_activate_app': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:find_location_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:onsite_conversion.flow_complete': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_tutorial_completion': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_tutorial_completion': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_complete_registration': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_complete_registration': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_rate': (value) => numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_rate': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_spend_credits': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_spend_credits': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:ad_impression_mobile_app': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:ad_click_mobile_app': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_app_install': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_achievement_unlocked': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_achievement_unlocked': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:donate_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_search': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_search': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:add_payment_info': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:add_payment_info': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:app_engagement': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:app_use': (value) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:app_story': (value) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:lead': (value) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:games.plays': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:submit_application_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_level_achieved': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_level_achieved': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:contact_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_custom': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:customize_product_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:app_custom_event.fb_mobile_d2_retention': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d2_retention': (
    value
  ) => numeral(value).format('$0,0.00'),
  'cost_per_action_type:app_custom_event.fb_mobile_d7_retention': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d7_retention': (
    value
  ) => numeral(value).format('$0,0.00'),
  'cost_per_conversion:subscribe_total': (value) =>
    numeral(value).format('$0,0.00'),
  'offsite_conversion.fb_pixel_purchase': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_purchase': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_purchase': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:landing_page_view': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:landing_page_view': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_action_type:omni_initiated_checkout': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:omni_initiated_checkout': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:start_trial_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_conversion:schedule_total': (value) =>
    numeral(value).format('$0,0.00'),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_spent_credits': (
    value
  ) => numeral(value).format('$0,0.00'),
  'purchase_roas:omni_purchase': (value) => numeral(value).format('0,0.00'),
  'website_purchase_roas:offsite_conversion.fb_pixel_purchase': (value) =>
    numeral(value).format('0,0.00'),
  'mobile_app_purchase_roas:app_custom_event.fb_mobile_purchase': (value) =>
    numeral(value).format('0,0.00'),
}

module.exports = (name) => (funcs[name] ? funcs[name] : (value) => value)
