const geoTargets = {
  2156: 'China',
  2158: 'Taiwan',
  2392: 'Japan',
  2608: 'Philippines',
  21096: 'Chiayi County',
  21097: 'Hsinchu County',
  21098: 'Hualien County',
  21099: 'Yilan County',
  21101: 'Penghu County',
  21102: 'Taoyuan City',
  21105: 'Taitung County',
  21300: 'Miaoli County',
  9040299: 'Nantou County',
  9040307: 'Changhua County',
  9040310: 'Kinmen County',
  9040318: 'Yunlin County',
  9040321: 'Pingtung County',
  9069534: 'Fujian Province',
  9075967: 'Taiwan Province',
  20648: 'Shiga',
  20649: 'Kyoto',
  20650: 'Osaka',
  20835: 'Metro Manila',
  9061340: 'Calabarzon',
  1012808: 'Chiayi City',
  1012809: 'Zhudong Township',
  1012810: 'Hsinchu City',
  1012812: 'Yilan City',
  1012816: 'Magong City',
  1012817: 'Zhongli District',
  1012818: 'Tainan City',
  1012819: 'Keelung City',
  1012825: 'New Taipei City',
  1012826: 'Taoyuan District',
  1012827: 'Taitung City',
  1028790: 'Zhunan Township',
  9040284: 'Jincheng Township',
  9040285: 'Luzhu District',
  9040286: 'Luodong Township',
  9040287: 'Daxi District',
  9040288: 'Bade District',
  9040289: 'Guishan District',
  9040290: 'Dayuan District',
  9040291: 'Pingzhen District',
  9040292: 'Hukou Township',
  9040293: 'Zhubei City',
  9040294: 'Yangmei District',
  9040295: 'Longtan District',
  9040296: 'Toufen City',
  9040297: 'Shitan Township',
  9040298: 'Hualien City',
  9040300: 'Puli Township',
  9040301: 'Caotun Township',
  9040302: 'Nantou City',
  9040303: 'Yuanlin City',
  9040304: 'Changhua City',
  9040305: 'Hemei Township',
  9040306: 'Lukang Township',
  9040308: 'Puyan Township',
  9040309: 'Miaoli City',
  9040312: 'Chaozhou Township',
  9040313: 'Neipu Township',
  9040314: 'Kaohsiung City',
  9040315: 'Beigang Township',
  9040316: 'Huwei Township',
  9040317: 'Dounan Township',
  9040322: 'Donggang Township',
  9040380: 'Taichung City',
  9051478: 'Beidou Township',
  9051479: 'Dacun Township',
  9051480: 'Guanyin District',
  9051481: 'Hengchun Township',
  9051482: 'Jiaoxi Township',
  9051483: 'Mailiao Township',
  9051484: 'Minxiong Township',
  9051485: 'Puzi City',
  9051486: 'Sanxing Township',
  9051487: 'Shoufeng Township',
  9051488: 'Tianzhong Township',
  9051489: 'Xihu Township',
  9051490: 'Xinwu District',
  9051491: 'Zaoqiao Township',
  1009501: 'Otsu',
  1009515: 'Uji',
  1009523: 'Hirakata',
  1009540: 'Osaka',
  1011165: 'Cabuyao',
  1011173: 'Quezon City',
  9060944: 'Calamba',
  9060957: 'Binan',
  2012: 'Algeria',
  2024: 'Saskatchewan',
  2032: 'Fribourg',
  2036: 'Jura',
  2040: 'Obwalden',
  2048: 'Vaud',
  2050: 'Bangladesh',
  2056: 'Belgium',
  2068: 'Bolivia',
  2076: 'Brazil',
  2090: 'Solomon Islands',
  2100: 'Bulgaria',
  2104: 'Myanmar (Burma)',
  2116: 'Cambodia',
  2124: 'Canada',
  2140: 'Central African Republic',
  2144: 'Sri Lanka',
  2152: 'Chile',
  2162: 'Christmas Island',
  2166: 'Cocos (Keeling) Islands',
  2170: 'Colombia',
  2174: 'Comoros',
  2175: 'Mayotte',
  2178: 'Republic of the Congo',
  2180: 'Democratic Republic of the Congo',
  2184: 'Cook Islands',
  2188: 'Costa Rica',
  2191: 'Croatia',
  2196: 'Cyprus',
  2203: 'Czechia',
  2204: 'Benin',
  2208: 'Denmark',
  2212: 'Dominica',
  2214: 'Dominican Republic',
  2218: 'Ecuador',
  2222: 'El Salvador',
  2226: 'Equatorial Guinea',
  2231: 'Ethiopia',
  2232: 'Eritrea',
  2233: 'Estonia',
  2234: 'Faroe Islands',
  2238: 'Falkland Islands (Islas Malvinas)',
  2239: 'South Georgia and the South Sandwich Islands',
  2242: 'Fiji',
  2246: 'Finland',
  2250: 'France',
  2254: 'French Guiana',
  2258: 'French Polynesia',
  2260: 'French Southern and Antarctic Lands',
  2262: 'Djibouti',
  2266: 'Gabon',
  2268: 'Georgia',
  2270: 'The Gambia',
  2275: 'Palestine',
  2276: 'Germany',
  2288: 'Ghana',
  2292: 'Gibraltar',
  2296: 'Kiribati',
  2300: 'Greece',
  2304: 'Greenland',
  2308: 'Grenada',
  2312: 'Guadeloupe',
  2316: 'Guam',
  2320: 'Guatemala',
  2324: 'Guinea',
  2328: 'Guyana',
  2332: 'Haiti',
  2334: 'Heard Island and McDonald Islands',
  2336: 'Vatican City',
  2340: 'Honduras',
  2344: 'Hong Kong',
  2348: 'Hungary',
  2352: 'Iceland',
  2356: 'India',
  2360: 'Indonesia',
  2368: 'Iraq',
  2372: 'Ireland',
  2376: 'Israel',
  2380: 'Italy',
  2384: "Cote d'Ivoire",
  2388: 'Jamaica',
  2398: 'Kazakhstan',
  2414: 'Kuwait',
  2417: 'Kyrgyzstan',
  2418: 'Laos',
  2422: 'Lebanon',
  2426: 'Lesotho',
  2428: 'Latvia',
  2430: 'Liberia',
  2434: 'Libya',
  2438: 'Liechtenstein',
  2440: 'Lithuania',
  2442: 'Luxembourg',
  2446: 'Macau',
  2450: 'Madagascar',
  2454: 'Malawi',
  2458: 'Malaysia',
  2462: 'Maldives',
  2466: 'Mali',
  2470: 'Malta',
  2474: 'Martinique',
  2478: 'Mauritania',
  2480: 'Mauritius',
  2484: 'Mexico',
  2492: 'Monaco',
  2496: 'Mongolia',
  2498: 'Moldova',
  2499: 'Montenegro',
  2500: 'Montserrat',
  2504: 'Morocco',
  2508: 'Mozambique',
  2512: 'Oman',
  2516: 'Namibia',
  2520: 'Nauru',
  2524: 'Nepal',
  2528: 'Netherlands',
  2530: 'Netherlands Antilles',
  2531: 'Curacao',
  2533: 'Aruba',
  2534: 'Sint Maarten',
  2535: 'Caribbean Netherlands',
  2540: 'New Caledonia',
  2548: 'Vanuatu',
  2554: 'New Zealand',
  2558: 'Nicaragua',
  2562: 'Niger',
  2566: 'Nigeria',
  2570: 'Niue',
  2574: 'Norfolk Island',
  2578: 'Norway',
  2580: 'Northern Mariana Islands',
  2581: 'United States Minor Outlying Islands',
  2583: 'Federated States of Micronesia',
  2584: 'Marshall Islands',
  2585: 'Palau',
  2586: 'Pakistan',
  2591: 'Panama',
  2598: 'Papua New Guinea',
  2600: 'Paraguay',
  2604: 'Peru',
  2612: 'Pitcairn Islands',
  2616: 'Poland',
  2630: 'Puerto Rico',
  2634: 'Qatar',
  2638: 'Reunion',
  2642: 'Romania',
  2643: 'Russia',
  2646: 'Rwanda',
  2654: 'Saint Helena, Ascension and Tristan da Cunha',
  2659: 'Saint Kitts and Nevis',
  2660: 'Anguilla',
  2662: 'Saint Lucia',
  2666: 'Saint Pierre and Miquelon',
  2670: 'Saint Vincent and the Grenadines',
  2674: 'San Marino',
  2678: 'Sao Tome and Principe',
  2682: 'Saudi Arabia',
  2686: 'Senegal',
  2688: 'Serbia',
  2690: 'Seychelles',
  2694: 'Sierra Leone',
  2702: 'Singapore',
  2703: 'Slovakia',
  2704: 'Vietnam',
  2705: 'Slovenia',
  2706: 'Somalia',
  2710: 'South Africa',
  2716: 'Zimbabwe',
  2724: 'Spain',
  2732: 'Western Sahara',
  2740: 'Suriname',
  2744: 'Svalbard and Jan Mayen',
  2748: 'Eswatini',
  2752: 'Sweden',
  2756: 'Switzerland',
  2762: 'Tajikistan',
  2764: 'Thailand',
  2768: 'Togo',
  2772: 'Tokelau',
  2776: 'Tonga',
  2780: 'Trinidad and Tobago',
  2784: 'United Arab Emirates',
  2788: 'Tunisia',
  2792: 'Turkey',
  2795: 'Turkmenistan',
  2796: 'Turks and Caicos Islands',
  2798: 'Tuvalu',
  2800: 'Uganda',
  2804: 'Ukraine',
  2807: 'North Macedonia',
  2818: 'Egypt',
  2826: 'United Kingdom',
  2831: 'Guernsey',
  2832: 'Jersey',
  2834: 'Tanzania',
  2840: 'United States',
  2850: 'U.S. Virgin Islands',
  2854: 'Burkina Faso',
  2858: 'Uruguay',
  2860: 'Uzbekistan',
  2862: 'Venezuela',
  2876: 'Wallis and Futuna',
  2882: 'Samoa',
  2887: 'Yemen',
  2894: 'Zambia',
  2900: 'Kosovo',
  9069683: 'Oran Province',
  9069691: 'Ain Temouchent Province',
  9070100: 'Cabinda Province',
  9070431: 'Luanda Province',
  20010: 'Buenos Aires',
  20026: 'Cordoba',
  20035: 'New South Wales',
  20037: 'Queensland',
  20038: 'South Australia',
  20040: 'Victoria',
  20041: 'Western Australia',
  20043: 'Carinthia',
  20044: 'Lower Austria',
  20045: 'Upper Austria',
  20046: 'Salzburg',
  20050: 'Vienna',
  9069830: 'Capital Governorate',
  9070356: 'Dhaka Division',
  9070359: 'Chittagong Division',
  20052: 'Brussels',
  20084: 'La Paz Department',
  20087: 'State of Amazonas',
  20094: 'State of Minas Gerais',
  20101: 'State of Parana',
  20106: 'State of Sao Paulo',
  9040109: 'Plovdiv Province',
  9040127: 'Veliko Tarnovo Province',
  9047101: 'Sofia City Province',
  9065352: 'Mandalay Region',
  9065353: 'Yangon Region',
  9065356: 'Shan',
  9069937: 'Siem Reap Province',
  9069941: 'Svay Rieng Province',
  9069946: 'Pursat Province',
  9069949: 'Kep Province',
  9069950: 'Sihanoukville',
  9070397: 'Kampot Province',
  9070398: 'Phnom Penh',
  20113: 'Alberta',
  20114: 'British Columbia',
  20118: 'Nova Scotia',
  20121: 'Ontario',
  20122: 'Prince Edward Island',
  20123: 'Quebec',
  9069783: 'Western Province',
  20154: 'Bio Bio Region',
  20160: 'Santiago Metropolitan Region',
  20163: 'Beijing',
  20164: 'Tianjin',
  20166: 'Shanxi',
  20167: 'Inner Mongolia',
  20168: 'Liaoning',
  20169: 'Jilin',
  20170: 'Heilongjiang',
  20171: 'Shanghai',
  20172: 'Jiangsu',
  20173: 'Zhejiang',
  20174: 'Anhui',
  20175: 'Fujian',
  20176: 'Jiangxi',
  20177: 'Shandong',
  20178: 'Henan',
  20179: 'Hubei',
  20180: 'Hunan',
  20181: 'Guangdong',
  20182: 'Guangxi',
  20183: 'Hainan',
  20184: 'Chongqing',
  20185: 'Sichuan',
  20186: 'Guizhou',
  20187: 'Yunnan',
  20189: 'Shaanxi',
  20190: 'Gansu',
  20191: 'Qinghai',
  20192: 'Ningxia',
  20193: 'Xinjiang',
  9070296: 'San Jose Province',
  20408: 'Primorje-Gorski Kotar County',
  20409: 'Zadar County',
  20412: 'Split-Dalmatia County',
  20413: 'Dubrovnik-Neretva County',
  21493: 'City of Zagreb',
  20218: 'South Bohemian Region',
  20219: 'South Moravian Region',
  21496: 'Karlovy Vary Region',
  21502: 'Prague',
  20243: 'Capital Region of Denmark',
  9069760: 'Distrito Nacional',
  9069772: 'Santo Domingo Province',
  20264: 'Pichincha',
  9069897: 'San Salvador Department',
  9061562: 'Harju County',
  9075506: 'Uusimaa',
  9075510: 'North Karelia',
  20316: 'Brittany',
  20319: 'Corsica',
  20321: 'Ile-de-France',
  20332: "Provence-Alpes-Cote d'Azur",
  9068565: 'Normandy',
  9068896: 'Grand Est',
  9068897: 'Occitanie',
  9068898: 'Hauts-de-France',
  9069525: 'Auvergne-Rhone-Alpes',
  20226: 'Berlin',
  20228: 'Baden-Wurttemberg',
  20229: 'Bavaria',
  20231: 'Hesse',
  20232: 'Hamburg',
  20235: 'North Rhine-Westphalia',
  20236: 'Rhineland-Palatinate',
  20239: 'Saxony',
  20241: 'Thuringia',
  9069837: 'Greater Accra Region',
  9069538: 'Decentralized Administration of Attica',
  9069535: 'New Territories',
  9069536: 'Kowloon',
  20418: 'Budapest',
  9069814: 'Southern Peninsula Region',
  9069815: 'Capital Region',
  9070365: 'Southern Region',
  20453: 'Andhra Pradesh',
  20456: 'Delhi',
  20457: 'Gujarat',
  20458: 'Haryana',
  20459: 'Jammu and Kashmir',
  20460: 'Karnataka',
  20461: 'Kerala',
  20462: 'Maharashtra',
  20464: 'Madhya Pradesh',
  20468: 'Rajasthan',
  20471: 'Uttar Pradesh',
  20472: 'West Bengal',
  21335: 'Himachal Pradesh',
  21342: 'Chandigarh',
  9061642: 'Telangana',
  20436: 'Bali',
  20438: 'West Java',
  20439: 'East Java',
  20440: 'Jakarta',
  20441: 'Central Java',
  20444: 'Lampung Province',
  20445: 'West Nusa Tenggara',
  20448: 'South Sulawesi',
  20450: 'North Sumatra',
  20451: 'Special Region of Yogyakarta',
  9056637: 'Banten',
  9056646: 'Riau Islands',
  9056648: 'West Kalimantan',
  9056651: 'West Sumatra',
  9069530: 'County Dublin',
  20518: 'Tel Aviv District',
  20600: 'Province of Siena',
  9047203: 'Province of Monza and Brianza',
  9053428: 'Campania',
  9053429: 'Emilia-Romagna',
  9053431: 'Lazio',
  9053432: 'Liguria',
  9053433: 'Lombardy',
  9053438: 'Sicily',
  9053440: 'Tuscany',
  9053442: 'Veneto',
  20624: 'Hokkaido',
  20626: 'Iwate',
  20627: 'Miyagi',
  20631: 'Ibaraki',
  20633: 'Gunma',
  20634: 'Saitama',
  20635: 'Chiba',
  20636: 'Tokyo',
  20637: 'Kanagawa',
  20638: 'Niigata',
  20641: 'Fukui',
  20642: 'Yamanashi',
  20643: 'Nagano',
  20644: 'Gifu',
  20645: 'Shizuoka',
  20646: 'Aichi',
  20647: 'Mie',
  20651: 'Hyogo',
  20652: 'Nara',
  20656: 'Okayama',
  20657: 'Hiroshima',
  20659: 'Tokushima',
  20661: 'Ehime',
  20663: 'Fukuoka',
  20665: 'Nagasaki',
  20666: 'Kumamoto',
  20667: 'Oita',
  20668: 'Miyazaki',
  20669: 'Kagoshima',
  20670: 'Okinawa',
  9070290: 'Almaty Province',
  9069818: 'Amman Governorate',
  9069738: 'Nairobi County',
  21318: 'Seoul',
  21319: 'Busan',
  21320: 'Daegu',
  21321: 'Incheon',
  21324: 'Ulsan',
  21325: 'Gyeonggi-do',
  21326: 'Gangwon-do',
  21328: 'Chungcheongnam-do',
  21329: 'Jeollabuk-do',
  21330: 'Jeollanam-do',
  21331: 'Gyeongsangbuk-do',
  21332: 'Gyeongsangnam-do',
  21333: 'Jeju-do',
  9070192: 'Luang Prabang Province',
  9070201: 'Vientiane Prefecture',
  9062318: 'Riga',
  20737: 'Perak',
  20738: 'Selangor',
  20739: 'Pahang',
  20740: 'Kelantan',
  20741: 'Johor',
  20742: 'Kedah',
  20744: 'Malacca',
  20745: 'Negeri Sembilan',
  20746: 'Penang',
  20748: 'Sabah',
  20749: 'Sarawak',
  20751: 'Federal Territory of Kuala Lumpur',
  20703: 'Mexico City',
  20706: 'Guanajuato',
  20708: 'Jalisco',
  20713: 'Nuevo Leon',
  9075799: 'Ulaanbaatar',
  9073769: 'Casablanca-Settat',
  9073773: 'Tangier-Tetouan-Al Hoceima',
  9073775: 'Marrakesh-Safi',
  9069735: 'Muscat Governorate',
  9070016: 'Central Development Region',
  9070019: 'Western Development Region',
  20759: 'Drenthe',
  20760: 'Flevoland',
  20765: 'North Brabant',
  20766: 'North Holland',
  20770: 'South Holland',
  20791: 'Auckland',
  21302: 'Canterbury',
  21310: 'Southland',
  9070006: 'Managua',
  21564: 'Lagos',
  20772: 'Akershus',
  20782: 'Hordaland',
  20783: 'Sogn og Fjordane',
  9069751: 'Panama',
  9075819: 'Morobe Province',
  9075825: 'National Capital District',
  9069962: 'Itapua Department',
  9069969: 'Alto Parana Department',
  20801: 'Cusco',
  20808: 'Lima Region',
  20820: 'Batangas',
  20838: 'Negros Oriental',
  20844: 'Zambales',
  9061341: 'Central Luzon',
  9061342: 'Western Visayas',
  9061343: 'Davao Region',
  9061344: 'Central Visayas',
  9061345: 'Ilocos Region',
  9061347: 'Region XII',
  9061350: 'MIMAROPA',
  9061352: 'Caraga',
  9061353: 'Cordillera Administrative Region',
  20848: 'Kuyavian-Pomeranian Voivodeship',
  20850: 'Lodz Voivodeship',
  20853: 'Masovian Voivodeship',
  20855: 'Podlaskie Voivodeship',
  20857: 'Pomeranian Voivodeship',
  20861: 'Greater Poland Voivodeship',
  20879: 'Viana do Castelo District',
  20889: 'Bucharest',
  20895: 'Brasov County',
  20897: 'Cluj County',
  20899: 'Constanta County',
  20908: 'Sibiu',
  20921: 'Buryatia',
  20928: 'Kamchatka Krai',
  20939: 'Kaluga Oblast',
  20950: 'Moscow',
  20959: 'Primorsky Krai',
  20968: 'Saint Petersburg',
  20971: 'Tatarstan',
  20987: 'Riyadh Province',
  20988: 'Makkah Province',
  20989: 'Eastern Province',
  9070424: 'Dakar Region',
  9040324: 'An Giang Province',
  9040326: 'Djong Thap Province',
  9040329: 'Tien Giang',
  9040331: 'Hanoi',
  9040336: 'Thai Binh',
  9040337: 'Nam Dinh',
  9040338: 'Ninh Binh Province',
  9040340: 'Thanh Hoa',
  9040342: 'Ha Tinh',
  9040345: 'Nghe An',
  9040353: 'Hai Phong',
  9040354: 'Hai Duong',
  9040364: 'Khanh Hoa Province',
  9040370: 'Djak Lak Province',
  9040372: 'Dong Nai',
  9040373: 'Ho Chi Minh City',
  9040374: 'Ba Ria - Vung Tau',
  9040376: 'Binh Thuan Province',
  9040377: 'Can Tho',
  9047161: 'Bac Giang',
  9047163: 'Bac Lieu',
  9047164: 'Bac Ninh Province',
  9047165: 'Ben Tre',
  9047166: 'Binh Duong',
  9047167: 'Binh Phuoc',
  9047170: 'Da Nang',
  9047174: 'Ha Nam',
  9047175: 'Hau Giang',
  9047177: 'Hung Yen',
  9047181: 'Long An Province',
  9047182: 'Phu Tho Province',
  9047183: 'Soc Trang',
  9047185: 'Tay Ninh Province',
  9047186: 'Tra Vinh',
  9047188: 'Vinh Long',
  9047189: 'Vinh Phuc Province',
  9075989: 'Quang Ninh',
  9075874: 'Ljubljana',
  21216: 'Gauteng',
  21219: 'KwaZulu-Natal',
  20278: 'Catalonia',
  20294: 'Tarragona',
  21388: 'Valencian Community',
  9047032: 'Lleida',
  9047036: 'Seville',
  9047045: 'Madrid',
  9047053: 'La Rioja',
  9047060: 'Cordoba',
  9047061: 'Granada',
  9047063: 'Malaga',
  9070267: 'Hhohho Region',
  21005: 'Ostergotland County',
  21012: 'Halland County',
  20126: 'Aargau',
  20129: 'Canton of Bern',
  20133: 'Geneva',
  20137: 'Lucerne',
  20148: 'Vaud',
  20151: 'Zurich',
  21025: 'Bangkok',
  21026: 'Nonthaburi',
  21030: 'Chon Buri',
  21031: 'Chachoengsao',
  21035: 'Khon Kaen',
  21036: 'Chiang Mai',
  21039: 'Chiang Rai',
  21041: 'Nakhon Si Thammarat',
  21042: 'Phuket',
  21044: 'Songkhla',
  21486: 'Prachuap Khiri Khan',
  21487: 'Samut Sakhon',
  21488: 'Nakhon Pathom',
  9047113: 'Buri Ram',
  9047116: 'Chanthaburi',
  9047122: 'Lamphun',
  9047141: 'Prachin Buri',
  9047142: 'Ratchaburi',
  9047143: 'Rayong',
  9047147: 'Samut Prakan',
  9047153: 'Suphan Buri',
  9047157: 'Trat',
  9041083: 'Dubai',
  21055: 'Ankara',
  21057: 'Aydin Province',
  21058: 'Balikesir Province',
  21065: 'Eskisehir Province',
  21068: 'Mersin Province',
  21069: 'Istanbul',
  21070: 'Izmir',
  21075: 'Konya',
  21076: 'Kutahya',
  21078: 'Manisa',
  21082: 'Nevsehir',
  21085: 'Samsun',
  21088: 'Trabzon',
  21363: 'Denizli',
  21373: 'Siirt Province',
  21578: 'Karabuk Province',
  21118: 'Kyiv city',
  21465: 'Giza Governorate',
  21468: 'Cairo Governorate',
  20339: 'England',
  20342: 'Scotland',
  21136: 'Arizona',
  21137: 'California',
  21138: 'Colorado',
  21140: 'District of Columbia',
  21141: 'Delaware',
  21142: 'Florida',
  21143: 'Georgia',
  21144: 'Hawaii',
  21145: 'Iowa',
  21147: 'Illinois',
  21152: 'Massachusetts',
  21153: 'Maryland',
  21154: 'Maine',
  21155: 'Michigan',
  21156: 'Minnesota',
  21157: 'Missouri',
  21160: 'North Carolina',
  21163: 'New Hampshire',
  21164: 'New Jersey',
  21166: 'Nevada',
  21167: 'New York',
  21168: 'Ohio',
  21170: 'Oregon',
  21171: 'Pennsylvania',
  21173: 'South Carolina',
  21175: 'Tennessee',
  21176: 'Texas',
  21177: 'Utah',
  21178: 'Virginia',
  21180: 'Washington',
  21182: 'Wisconsin',
  9073451: 'San Francisco Bay Area',
  9073452: 'Silicon Valley',
  21188: 'Capital District',
  21204: 'Tachira',
  1000003: 'Luanda',
  1000073: 'Buenos Aires',
  1000286: 'Sydney',
  1000287: 'Tamworth',
  1000339: 'Brisbane',
  1000346: 'Caboolture',
  1000347: 'Cairns',
  1000422: 'Adelaide',
  1000567: 'Melbourne',
  1000571: 'Mildura',
  1000676: 'Perth',
  1000776: 'Schwechat',
  1000827: 'Linz',
  1000878: 'Salzburg',
  1000997: 'Vienna',
  1001478: 'Manama',
  1001440: 'Chittagong',
  1001004: 'Brussels',
  9040068: 'Sint-Jans-Molenbeek',
  1001511: 'Manaus',
  1001566: 'Belo Horizonte',
  1001772: 'Sao Jose dos Campos',
  1001773: 'Sao Paulo',
  1031634: 'Foz do Iguacu',
  1012098: 'Honiara',
  1001448: 'Sofia',
  1001459: 'Veliko Tarnovo',
  1001464: 'Plovdiv',
  1010216: 'Yangon',
  9065344: 'Mandalay',
  1009829: 'Phnom Penh',
  1001801: 'Calgary',
  1001803: 'Canmore',
  1001820: 'Hinton',
  1001887: 'Courtenay',
  1001909: 'Kelowna',
  1001926: 'Mission',
  1001949: 'Richmond',
  1001964: 'Surrey',
  1001970: 'Vancouver',
  1001973: 'Victoria',
  1002347: 'Milton',
  1002451: 'Toronto',
  1002483: 'Charlottetown',
  1002717: 'Vaudreuil-Dorion',
  1009919: 'Colombo',
  9069439: 'Katunayake',
  1003325: 'Santiago',
  9048036: 'Chillan',
  1003334: 'Beijing',
  1003338: 'Tianjin',
  1003362: 'Taiyuan',
  1003371: 'Dalian',
  1003378: 'Shenyang',
  1003382: 'Changchun',
  1003390: 'Harbin',
  1003391: 'Heihe',
  1003397: 'Qiqihar',
  1003401: 'Shanghai',
  1003404: 'Changzhou',
  1003405: "Huai'an",
  1003406: 'Lianyungang',
  1003407: 'Nanjing',
  1003411: 'Suzhou',
  1003413: 'Wuxi',
  1003414: 'Xuzhou',
  1003415: 'Yancheng',
  1003416: 'Yangzhou',
  1003420: 'Hangzhou',
  1003423: 'Jiaxing',
  1003424: 'Jinhua',
  1003427: 'Ningbo',
  1003430: 'Taizhou',
  1003431: 'Wenzhou',
  1003433: 'Zhoushan',
  1003434: 'Anqing',
  1003437: 'Chuzhou',
  1003441: 'Hefei',
  1003449: 'Fuzhou',
  1003452: 'Nanping',
  1003455: 'Putian',
  1003456: 'Quanzhou',
  1003457: 'Sanming',
  1003459: 'Xiamen',
  1003461: 'Zhangzhou',
  1003464: 'Ganzhou',
  1003475: 'Dezhou',
  1003478: 'Heze',
  1003485: 'Linyi',
  1003487: 'Qingdao',
  1003489: 'Weifang',
  1003491: 'Yantai',
  1003503: 'Luoyang',
  1003508: 'Xinxiang',
  1003511: 'Zhengzhou',
  1003533: 'Wuhan',
  1003535: 'Xianning',
  1003537: 'Xiaogan',
  1003538: 'Yichang',
  1003540: 'Changde',
  1003541: 'Changsha',
  1003542: 'Chenzhou',
  1003544: 'Hengyang',
  1003553: 'Yiyang',
  1003557: 'Dongguan',
  1003558: 'Foshan',
  1003559: 'Guangzhou',
  1003562: 'Huizhou',
  1003563: 'Jiangmen',
  1003568: 'Shantou',
  1003571: 'Shenzhen',
  1003577: 'Zhaoqing',
  1003578: 'Zhuhai',
  1003580: 'Guilin',
  1003582: 'Nanning',
  1003585: 'Yulin',
  1003587: 'Haikou',
  1003591: 'Chongqing',
  1003592: 'Chengdu',
  1003605: 'Guiyang',
  1003609: 'Dali',
  1003610: 'Kunming',
  1003622: "Xi'an",
  1003623: 'Xianyang',
  1003629: 'Lanzhou',
  1003633: 'Xining',
  1003638: 'Yinchuan',
  1003641: 'Urumqi',
  1032148: 'Baoshan',
  1032164: 'Fangchenggang',
  1032174: 'Huangshan',
  1032178: "Ji'an",
  1032183: 'Kashgar',
  1032187: 'Lijiang',
  1032192: "Ma'anshan",
  1032194: 'Meizhou',
  1032199: "Pu'er",
  1032200: 'Qiandongnan',
  1032208: 'Sanya',
  1032217: 'Taizhou',
  1032224: 'Xiangxi',
  1032237: 'Zhangjiajie',
  1032240: 'Zhenjiang',
  1032241: 'Zhongshan',
  9048050: 'Aba',
  1003683: 'San Jose',
  1003685: 'Uruca',
  1007612: 'Zagreb',
  1007614: 'Rijeka',
  1007616: 'Zadar',
  1003705: 'Cesky Krumlov',
  1003717: 'Brno',
  1003803: 'Prague',
  1003839: 'Cheb',
  1003842: 'Karlovy Vary',
  1005010: 'Copenhagen',
  9069516: 'Quito',
  1012108: 'San Salvador',
  9061552: 'Tallinn',
  1005780: 'Suva',
  9072483: 'Helsinki',
  1005876: 'Rennes',
  1005930: 'Ajaccio',
  1005989: 'Choisy-le-Roi',
  1006094: 'Paris',
  1006104: 'Roissy-en-France',
  1006191: 'Metz',
  1006219: 'Toulouse',
  1006222: 'Arras',
  1006249: 'Caen',
  1006327: 'Aix-en-Provence',
  1006331: 'Avignon',
  1006335: 'Cannes',
  1006389: 'Chamonix',
  1006410: 'Lyon',
  9049261: 'Aix-les-Bains',
  9049473: 'Clichy',
  1003854: 'Berlin',
  1003968: 'Karlsruhe',
  1003992: 'Mannheim',
  1004169: 'Gefrees',
  1004234: 'Munich',
  1004246: 'Nuremberg',
  1004330: 'Wurzburg',
  1004363: 'Frankfurt',
  1004416: 'Raunheim',
  1004437: 'Hamburg',
  1004597: 'Bonn',
  1004611: 'Dortmund',
  1004625: 'Essen',
  1004681: 'Leopoldshohe',
  1004806: 'Koblenz',
  1004916: 'Dresden',
  9048555: 'Meiningen',
  9062594: 'Sudlohn',
  9067654: 'Accra',
  9061577: 'Kallithea',
  9061578: 'Athens',
  1007593: 'Barrigada',
  9067726: 'Dededo',
  9067727: 'Tamuning',
  1007633: 'Budapest',
  1007959: 'Reykjanesbaer',
  1007962: 'Reykjavik',
  1007740: 'Hyderabad',
  1007751: 'New Delhi',
  1007753: 'Ahmedabad',
  1007765: 'Gurgaon',
  1007768: 'Bengaluru',
  1007779: 'Thiruvananthapuram',
  1007785: 'Mumbai',
  1007788: 'Pune',
  1007801: 'Chandigarh',
  1007820: 'Ghaziabad',
  1007828: 'Kolkata',
  9040176: 'Kangra',
  9040219: 'Chittoor',
  9040229: 'Pimpri-Chinchwad',
  9040245: 'Thane',
  1007697: 'Denpasar',
  1007700: 'Bandung',
  1007710: 'Tangerang',
  1007711: 'Gresik',
  1007714: 'Malang',
  1007715: 'Surabaya',
  1007737: 'Medan',
  1007738: 'Yogyakarta',
  9056653: 'Serang',
  9056654: 'South Tangerang',
  9056667: 'Kediri',
  9056672: 'Pontianak',
  9056681: 'Batam',
  9056683: 'Bandar Lampung',
  9056690: 'Makassar',
  9056698: 'Padang',
  9072522: 'Amlapura',
  9072528: 'Gianyar',
  9072532: 'Kuta',
  9072579: 'Purwakarta',
  9072584: 'Bekasi Regency',
  9072591: 'Gunung Sindur',
  9072592: 'South Jakarta',
  9072594: 'Central Jakarta',
  9072595: 'North Jakarta',
  9072596: 'West Jakarta',
  9072628: 'Demak',
  9074336: 'Kuta Selatan',
  9074347: 'Caringin',
  1007850: 'Dublin',
  1008006: 'Tel Aviv-Yafo',
  1008066: 'Arezzo',
  1008141: 'Bologna',
  1008279: 'Catania',
  1008311: 'Florence',
  1008337: 'Genoa',
  1008463: 'Milan',
  1008530: 'Nonantola',
  1008551: 'Capri',
  1008645: 'Pisa',
  1008736: 'Rome',
  1008771: 'Siena',
  1008957: 'Mestre',
  1008966: 'Venice',
  9050627: 'Fiumicino',
  9050665: 'Peschiera del Garda',
  1009025: 'Asahikawa',
  1009041: 'Hakodate',
  1009054: 'Kushiro',
  1009055: 'Kutchan',
  1009068: 'Noboribetsu',
  1009076: 'Sapporo',
  1009120: 'Morioka',
  1009129: 'Sendai',
  1009165: 'Hokota',
  1009205: 'Naganohara',
  1009210: 'Takasaki',
  1009228: 'Kuki',
  1009247: 'Chiba',
  1009250: 'Funabashi',
  1009255: 'Kashiwa',
  1009265: 'Narita',
  1009268: 'Sakura',
  1009278: 'Arakawa City',
  1009279: 'Bunkyo City',
  1009280: 'Chiyoda City',
  1009282: 'Edogawa City',
  1009283: 'Fuchu',
  1009285: 'Hachioji',
  1009293: 'Kita City',
  1009298: 'Koto City',
  1009300: 'Meguro City',
  1009304: 'Nerima City',
  1009307: 'Setagaya City',
  1009308: 'Shibuya City',
  1009309: 'Shinagawa City',
  1009310: 'Shinjuku City',
  1009311: 'Suginami City',
  1009312: 'Sumida City',
  1009314: 'Taito City',
  1009317: 'Tokyo',
  1009318: 'Toshima City',
  1009333: 'Kawasaki',
  1009343: 'Yokohama',
  1009379: 'Fukui',
  1009405: 'Omachi',
  1009410: 'Suwa',
  1009429: 'Takayama',
  1009435: 'Hamamatsu',
  1009461: 'Nagoya',
  1009467: 'Seto',
  1009492: 'Yokkaichi',
  1009507: 'Kyoto',
  1009522: 'Higashiosaka',
  1009528: 'Izumisano',
  1009538: 'Neyagawa',
  1009541: 'Sakai',
  1009544: 'Tajiri',
  1009551: 'Ako',
  1009565: 'Kobe',
  1009567: 'Nishinomiya',
  1009630: 'Kurashiki',
  1009637: 'Okayama',
  1009647: 'Higashihiroshima',
  1009685: 'Tokushima',
  1009698: 'Matsuyama',
  1009700: 'Niihama',
  1009717: 'Fukuoka',
  1009721: 'Kitakyushu',
  1009745: 'Nagasaki',
  1009746: 'Sasebo',
  1009749: 'Aso',
  1009752: 'Kumamoto',
  1009766: 'Kusu',
  1009769: 'Oita',
  1009776: 'Miyazaki',
  1009783: 'Kagoshima',
  1009789: 'Satsumasendai',
  1009797: 'Nago',
  1009798: 'Naha',
  1028825: 'Saitama',
  1028829: 'Minamiuonuma',
  1028833: 'Kyotango',
  1028849: 'Uruma',
  1028851: 'Chuo City',
  1028852: 'Itabashi City',
  1028853: 'Minato City',
  9053266: 'Fuefuki',
  9053267: 'Fujikawaguchiko',
  9053271: 'Fukuroi',
  9053296: 'Ishigaki',
  9053305: 'Karuizawa',
  9053306: 'Kashiba',
  9053366: 'Onna',
  9053390: 'Tenri',
  9053416: 'Yomitan',
  9063099: 'Almaty',
  1009824: 'Nairobi',
  1009833: 'Ansan-si',
  1009837: 'Jeju-si',
  1009838: 'Cheonan-si',
  1009840: 'Jinhae-gu',
  1009846: 'Incheon',
  1009850: 'Gimpo-si',
  1009864: 'Pohang-si',
  1009866: 'Busan',
  1009867: 'Pyeongtaek-si',
  1009871: 'Seoul',
  1009874: 'Sokcho-si',
  1009875: 'Seongnam-si',
  1009876: 'Suncheon-si',
  1009877: 'Suwon-si',
  1009879: 'Daegu',
  1009887: 'Ulsan',
  1009893: 'Yongin-si',
  1030709: 'Gimje-si',
  1030714: 'Gongju-si',
  1030718: 'Goyang-si',
  1030725: 'Gyeongju-si',
  1030726: 'Gyeryong-si',
  1030734: 'Hoengseong-gun',
  1030771: 'Sinan-gun',
  1030785: 'Yeongam-gun',
  1009899: 'Vientiane',
  9067734: 'Maseru',
  9062307: 'Riga',
  1009972: 'Macau',
  1010227: 'Ipoh',
  1010233: 'Kajang',
  1010234: 'Klang',
  1010237: 'Shah Alam',
  1010239: 'Kuantan',
  1010242: 'Kota Bharu',
  1010243: 'Batu Pahat',
  1010244: 'Johor Bahru',
  1010248: 'Muar',
  1010255: 'Sungai Petani',
  1010257: 'Malacca',
  1010260: 'Seremban',
  1010261: 'Butterworth',
  1010262: 'George Town',
  1010264: 'Kota Kinabalu',
  1010268: 'Kuching',
  1010274: 'Kuala Lumpur',
  1010275: 'Petaling Jaya',
  1029491: 'Ampang Jaya',
  1029498: 'Bayan Lepas',
  1029507: 'Cyberjaya',
  1029509: 'Gelugor',
  1029516: 'Kluang',
  1029524: 'Langkawi',
  1029535: 'Port Dickson',
  1029539: 'Raub District',
  1029543: 'Seri Kembangan',
  1029545: 'Subang Jaya',
  1029554: 'Ulu Tiram',
  9050745: 'Cheras 9 Miles',
  9050749: 'Kepala Batas',
  9050757: 'Simpang Ampat',
  9065421: 'Pekan Sibu',
  9072711: 'Jelutong',
  9072719: 'Balik Pulau',
  9072977: 'Bukit Beruang',
  1010224: 'Port Louis',
  1010058: 'Leon',
  1010079: 'Guadalajara',
  1010088: 'Zapopan',
  1010132: 'Monterrey',
  1010217: 'Ulaanbaatar',
  1009974: 'Casablanca',
  1009979: 'Marrakesh',
  1009987: 'Tangier',
  1011034: 'Kathmandu',
  1010318: 'Almere',
  1010543: 'Amsterdam',
  1010561: 'Haarlem',
  1010751: 'Rotterdam',
  9051244: 'Zaandam',
  9063178: 'Bergeijk',
  9063464: 'Amstelveen',
  9063486: 'Eindhoven',
  9063488: 'Haarlemmermeer',
  9063491: 'Government of Rotterdam',
  9063492: 'Government of Amsterdam',
  9067921: 'Nieuw-Dordrecht',
  1011036: 'Auckland',
  1011065: 'Christchurch',
  1010302: 'Managua',
  1010294: 'Lagos',
  1010814: 'Sandvika',
  1010218: 'Garapan',
  1011189: 'Koror',
  1011192: 'Port Moresby',
  9067756: 'Lae',
  1011783: 'Encarnacion',
  1011146: 'Butuan City',
  1011149: 'Baguio',
  1011153: 'Mariveles',
  1011154: 'Meycauayan',
  1011159: 'Cebu City',
  1011160: 'Lapu-Lapu City',
  1011162: 'Davao City',
  1011163: 'Iloilo City',
  1011169: 'Makati',
  1011170: 'Mandaluyong',
  1011171: 'Manila',
  1011172: 'Pasay',
  1011179: 'Angeles',
  1011185: 'Paranaque',
  1011186: 'Pasig',
  1011187: 'Olongapo',
  9060945: 'Antipolo',
  9060946: 'Dasmarinas',
  9060948: 'Santa Rosa',
  9060951: 'General Santos City',
  9060958: 'Tarlac City',
  9060960: 'General Trias',
  9060965: 'Mabalacat',
  1011251: 'Bydgoszcz',
  1011419: 'Warsaw',
  1011435: 'Bialystok',
  1011475: 'Gdansk',
  1011615: 'Poznan',
  1011785: 'Doha',
  1011795: 'Bucharest',
  1011804: 'Brasov',
  1011806: 'Cluj-Napoca',
  1011814: 'Constanta',
  1011838: 'Sibiu',
  1011870: 'Ulan-Ude',
  1011928: 'Kaluga',
  1011969: 'Moscow',
  1012008: 'Vladivostok',
  1012040: 'Saint Petersburg',
  1012054: 'Kazan',
  1012113: 'Sao Tome',
  1012088: 'Riyadh',
  9051476: 'Al Khobar',
  9067846: 'Dakar',
  1028580: 'Hanoi',
  1028581: 'Ho Chi Minh City',
  1028805: 'Hai Phong',
  1028807: 'Vung Tau',
  1028808: 'Nha Trang',
  1028809: 'Da Nang',
  9040327: 'Long Xuyen',
  9040328: 'My Tho',
  9040333: 'Hai Duong',
  9040335: 'Thai Binh',
  9040339: 'Ninh Binh',
  9040343: 'Ha Tinh',
  9040346: 'Vinh',
  9040371: 'Bien Hoa',
  9053233: 'Can Tho',
  9053236: 'Soc Trang',
  9053238: 'Tra Vinh',
  9053240: 'Vi Thanh',
  9074062: 'Ha Long',
  9074070: 'Vinh Long',
  9074112: 'Tay Ninh',
  9062551: 'Ljubljana',
  1028665: 'Johannesburg',
  1028712: 'Durban',
  1005411: 'Cordoba',
  1005414: 'Granada',
  1005419: 'Malaga',
  1005421: 'Seville',
  1005424: 'Barcelona',
  1005428: "L'Hospitalet de Llobregat",
  1005485: 'Logrono',
  1005493: 'Madrid',
  1005496: 'Torrejon de Ardoz',
  9049167: 'Picassent',
  9049190: 'Salou',
  1012721: 'Mbabane',
  1012324: 'Motala',
  1012475: 'Halmstad',
  1002875: 'Bern',
  1002992: 'Geneva',
  1003056: 'Lucerne',
  1003202: 'Montreux',
  1003245: 'Bulach',
  1003261: 'Hinwil',
  1003297: 'Zurich',
  9047989: 'Lenk im Simmental',
  1012728: 'Bangkok',
  1012729: 'Hua Hin',
  1012730: 'Nakhon Pathom',
  9073357: 'Hat Yai',
  9073360: 'Phuket',
  9073361: 'Nakhon Si Thammarat',
  9073366: 'Chiang Mai',
  9073371: 'Pak Kret',
  9073373: 'Samut Prakan',
  9073374: 'Samut Sakhon',
  9073375: 'Nakhon Pathom',
  9073376: 'Pattaya City',
  9073377: 'Laem Chabang',
  9073378: 'Rayong',
  9073379: 'Chanthaburi',
  1000013: 'Dubai',
  1012763: 'Ankara',
  1012776: 'Eskisehir',
  1012781: 'Mersin',
  1012782: 'Istanbul',
  1012783: 'Izmir',
  1012788: 'Konya',
  1012789: 'Kutahya',
  1012791: 'Manisa',
  1012797: 'Samsun',
  1012801: 'Trabzon',
  9056725: 'Avanos',
  9056744: 'Burhaniye',
  9056761: 'Denizli',
  9056824: 'Kusadasi',
  9056854: 'Safranbolu',
  9056863: 'Seydisehir',
  9056865: 'Siirt',
  9056894: 'Urgup',
  1012852: 'Kyiv',
  1005390: 'Cairo',
  1005394: 'Giza',
  9048953: '6th of October City',
  9048955: 'New Cairo City',
  1006502: 'Bath',
  1006524: 'Birmingham',
  1006565: 'Brighton',
  1006567: 'Bristol',
  1006653: 'Corby',
  1006702: 'Epsom',
  1006886: 'London',
  1006976: 'Oxford',
  1006988: 'Plymouth',
  1007015: 'Richmond',
  1007080: 'Slough',
  1007204: 'Watford',
  1007266: 'York',
  1007326: 'Edinburgh',
  1012831: 'Dar es Salaam',
  1013445: 'Mesa',
  1013462: 'Phoenix',
  1013502: 'Tempe',
  1013509: 'Tucson',
  1013552: 'Arcadia',
  1013585: 'Berkeley',
  1013613: 'Brea',
  1013709: 'Covina',
  1013715: 'Cupertino',
  1013802: 'Fremont',
  1013883: 'Irvine',
  1013909: 'La Habra',
  1013915: 'La Puente',
  1013962: 'Los Angeles',
  1014010: 'Millbrae',
  1014012: 'Milpitas',
  1014052: 'Newark',
  1014059: 'Newport Coast',
  1014080: 'Oakland',
  1014155: 'Pomona',
  1014169: 'Rancho Palos Verdes',
  1014197: 'Riverside',
  1014199: 'Rocklin',
  1014207: 'Rowland Heights',
  1014218: 'San Diego',
  1014221: 'San Francisco',
  1014222: 'San Gabriel',
  1014226: 'San Jose',
  1014235: 'San Marino',
  1014237: 'San Mateo',
  1014240: 'San Pedro',
  1014243: 'San Ramon',
  1014249: 'Santa Clara',
  1014255: 'Santa Monica',
  1014293: 'South Lake Tahoe',
  1014294: 'South Pasadena',
  1014315: 'Sunnyvale',
  1014339: 'Torrance',
  1014357: 'Union City',
  1014376: 'Walnut',
  1014425: 'Yucaipa',
  1014485: 'Denver',
  1014895: 'Washington',
  1014915: 'Hockessin',
  1015094: 'Leesburg',
  1015117: 'Miami Beach',
  1015150: 'Orlando',
  1015254: 'Atlanta',
  1015464: 'Peachtree City',
  1015579: 'Honolulu',
  1015640: 'Ames',
  1016367: 'Chicago',
  1016441: 'Elk Grove Village',
  1016709: 'Murphysboro',
  1016916: 'Urbana',
  1018127: 'Boston',
  1018138: 'Brookline',
  1018516: 'Bethesda',
  1019013: 'Readfield',
  1019192: 'Canton',
  1019659: 'Westland',
  1019973: 'Minneapolis',
  1020268: 'Columbia',
  1021048: 'Charlotte',
  1021129: 'Greensboro',
  1021160: 'Indian Trail',
  1021988: 'Windham',
  1022079: 'Clifton',
  1022099: 'East Brunswick',
  1022116: 'Essex Fells',
  1022257: 'Metuchen',
  1022305: 'North Bergen',
  1022374: 'Ridgewood',
  1022464: 'Wayne',
  1022639: 'Las Vegas',
  1023037: 'Ithaca',
  1023094: 'Liverpool',
  1023191: 'New York',
  1023289: 'Purchase',
  1023744: 'Hilliard',
  1023838: 'Middletown',
  1023910: 'Oberlin',
  1024543: 'Portland',
  1024734: 'Chester Springs',
  1025149: 'Newtown',
  1025197: 'Philadelphia',
  1025555: 'Fort Mill',
  1026036: 'Knoxville',
  1026069: 'Memphis',
  1026083: 'Nashville',
  1026201: 'Austin',
  1026334: 'Cypress',
  1026339: 'Dallas',
  1026481: 'Houston',
  1026622: 'Midland',
  1026630: 'Missouri City',
  1026702: 'Port Lavaca',
  1026759: 'San Antonio',
  1026825: 'Sugar Land',
  1026865: 'Victoria',
  1026990: 'Salt Lake City',
  1027260: 'Pulaski',
  1027517: 'Bothell',
  1027583: 'Everett',
  1027634: 'Lake Stevens',
  1027713: 'Port Townsend',
  1027726: 'Renton',
  1027744: 'Seattle',
  1027790: 'Vancouver',
  1027809: 'Woodinville',
  9051554: 'Bay Lake',
  9051759: 'Commerce',
  9051807: 'Darnestown',
  9051947: 'Fitchburg',
  9052206: 'Johns Creek',
  9052350: 'Los Altos Hills',
  9052492: 'Mountain Park',
  9052756: 'Riviera Beach',
  9052821: 'SeaTac',
  9052828: 'Shaker Heights',
  9052859: 'Sleepy Hollow',
  1028528: 'Caracas',
  1028585: "Sana'a",
}

module.exports = geoTargets
