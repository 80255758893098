import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'

function StatisticBox(props) {
  const { setting } = props
  const { title, titleEng, data1, data2, data3, data4, data5, link, method } =
    setting
  return (
    <Card
      className="statistic-card p-2 fs-lg-3 fs-7 lh-md h-100"
      title="帳 戶 總 覽"
    >
      <Card.Body className="d-flex flex-column py-2">
        <div className="my-0 ps-3 text-start">
          {title && <Card.Title className="mb-0">{title}</Card.Title>}
          {titleEng && (
            <Card.Text className="titleEng mb-0">{titleEng}</Card.Text>
          )}
          {/* <hr /> */}
        </div>
        <div className="lh-md my-auto h-75 ps-3 pe-0 pb-3 pt-4 text-start fs-7 d-flex flex-column">
          {data1 && <Card.Text className="flex-fill">{data1}</Card.Text>}
          {data2 && <Card.Text className="flex-fill">{data2}</Card.Text>}
          {data3 && <Card.Text className="flex-fill">{data3}</Card.Text>}
          {data4 && <Card.Text className="flex-fill">{data4}</Card.Text>}
          {data5 && <Card.Text className="flex-fill">{data5}</Card.Text>}
        </div>
      </Card.Body>
      {link && (
        <Card.Footer>
          <Card.Link href={link}>Link</Card.Link>
        </Card.Footer>
      )}
      {method && (
        <Card.Footer>
          <Card.Link onClick={method}>method</Card.Link>
        </Card.Footer>
      )}
    </Card>
  )
}

StatisticBox.propTypes = {
  setting: PropTypes.shape(),
}

StatisticBox.defaultProps = {
  setting: {
    title: '帳 戶 總 覽',
    titleEng: 'Account Overview',
    data1: '您 有 3 則 未 讀 通 知',
    data2: '總 金 額 為 $ 12,000 元',
    data3: '廣 告 帳 戶 總 數 為 2 個',
    data4: '執 行 中 的 廣 告 為 6 則',
    data5: '已 完 成 的 廣 告 為 0 則',
  },
}

export default StatisticBox
