import React, { useState, useEffect, useMemo, createContext } from 'react'
import PropTypes from 'prop-types'
import { Manager } from 'socket.io-client'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

function ContextProvider(props) {
  const { children, setting } = props
  const { auth } = setting

  const [socket, setSocket] = useState(null)
  useEffect(() => {
    if (!auth.auth.user_id) return false
    const manager = new Manager(window.location.origin)
    const newSocket = manager.socket('/', {
      auth: {
        auth: auth.auth.user_id,
      },
    })
    setSocket(newSocket)
    return () => newSocket.close()
  }, [setSocket, auth.auth.user_id])

  const [notification, setnotification] = useState([])
  const insertNoti = (oldNoti, newNoti) => {
    let result = [...oldNoti]
    newNoti.forEach((n) => {
      if (n.deleted) {
        result = result.filter((r) => r.notification_id !== n.notification_id)
      } else if (n.updated) {
        result = result.map((r) =>
          r.notification_id !== n.notification_id ? r : n
        )
      } else {
        if (!result.every((r) => r.notification_id !== n.notification_id))
          return
        result = [n].concat(result)
      }
    })
    return result
  }
  useEffect(() => {
    if (socket !== null) {
      socket.on('message', (message) => {
        setnotification((prevState) =>
          (message.length
            ? insertNoti(prevState, message)
            : insertNoti(prevState, [message])
          ).map((n) => ({
            ...n,
            id: n.notification_id,
            name: n.content,
          }))
        )
      })
    }
  }, [socket])

  const [toast, settoast] = useState({ show: false, text: '' })
  const toastValue = useMemo(() => ({ toast, settoast }), [toast])

  return (
    <>
      <NotiContext.Provider value={notification}>
        <ToastContext.Provider value={toastValue}>
          <AuthContext.Provider value={auth}>
            <SocketContext.Provider value={socket}>
              {children}
            </SocketContext.Provider>
          </AuthContext.Provider>
        </ToastContext.Provider>
      </NotiContext.Provider>
      <ToastContainer className="p-3" position="bottom-end">
        <Toast
          onClose={() => settoast({ ...toast, show: false })}
          show={toast.show}
          delay={3000}
          autohide
          style={{ width: '100%' }}
        >
          <Toast.Body>{toast.text}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

ContextProvider.propTypes = {
  children: PropTypes.shape().isRequired,
  setting: PropTypes.shape().isRequired,
}

export default ContextProvider

export const SocketContext = createContext(null)
export const NotiContext = createContext([])
export const AuthContext = createContext(null)
export const ToastContext = createContext({
  toast: { show: false, text: '' },
  settoast: () => {},
})
