import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from 'react-bootstrap/Button'

function Loginform(props) {
  const { setting } = props
  const { fields, onDataChange } = setting
  const [reveal, setreveal] = useState(false)
  return (
    <Form className="py-2">
      {fields.map((field) => (
        <Form.Group key={field.name} className="mb-3">
          {field.type === 'password' ? (
            <InputGroup
              id="defaultBorder"
              className="rounded input-group-transparent-addon"
            >
              <Form.Control
                name={field.name}
                type={reveal ? 'text' : field.type}
                onChange={onDataChange}
                placeholder={field.placeholder}
              />
              <InputGroup.Text>
                <FontAwesomeIcon
                  className="fs-7 btn-lucaIcon"
                  style={{ right: '10', top: '50', bottom: '50' }}
                  title={reveal ? '點擊以隱藏密碼' : '點擊以顯示密碼'}
                  icon={reveal ? faEye : faEyeSlash}
                  onClick={() => setreveal(!reveal)}
                />
              </InputGroup.Text>
            </InputGroup>
          ) : (
            <>
              <Form.Label>{field.label}</Form.Label>
              <Form.Control
                name={field.name}
                type={field.type}
                onChange={onDataChange}
                placeholder={field.placeholder}
              />
            </>
          )}
        </Form.Group>
      ))}
    </Form>
  )
}

Loginform.propTypes = {
  setting: PropTypes.shape(),
}

Loginform.defaultProps = {
  setting: {
    fields: [
      {
        label: 'Email address',
        name: 'email',
        type: 'email',
        placeholder: 'Enter Email...',
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
        placeholder: 'Enter Password...',
      },
    ],
    onDataChange: () => {},
  },
}

export default Loginform
