import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ExcelJS from 'exceljs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileCirclePlus,
  faEnvelopeOpenText,
  faTrashAlt,
  faDownload,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { AuthContext, ToastContext } from '../components/ContextProvider'
import { successImg2 } from '../assets'
import {
  FormDialog,
  InfoDialog,
  Datatable,
  SearchBar,
  SelectBar,
  ConfirmForm,
  KPIModal,
} from '../components'
import apiService from '../services/apiService'
import {
  wordSearch,
  getIcon,
  getForm,
  getFixedForm,
  CriteoForm,
  t,
} from '../services/lucaFunctions'

function Forms() {
  const auth = useContext(AuthContext)
  const { settoast } = useContext(ToastContext)
  const navigate = useNavigate()

  const [showInfo, setshowInfo] = useState(false)
  const handleShowInfo = () => {
    setshowInfo(true)
  }
  const handleCloseInfo = () => {
    setshowInfo(false)
  }

  const [managed, setmanaged] = useState([])
  useEffect(() => {
    if (!auth.auth.user_id) return
    const getManaged = async () => {
      const res = await apiService.data({
        path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
        method: 'get',
      })
      setmanaged(res)
    }
    getManaged()
  }, [auth])

  // set form fields and extrafields if necessaray
  const [showForm, setshowForm] = useState(false)
  const [form, setform] = useState({
    pageLimit: 2,
    content: [],
    extraFields: [],
  })
  const pageSetting = {
    OpenAccount: {
      title: '廣 告 帳 戶 申 請',
      titleEng: 'Advertising account creation',
    },
    Recharge: {
      title: '廣 告 帳 戶 儲 值',
      titleEng: 'Recharge for Ads account',
    },
    Permission: {
      title: '權 限 申 請',
      titleEng: 'Apply for permission',
    },
  }
  // set form data when show a form
  const [formData, setformData] = useState({})
  const handleDataChange = (event) => {
    setformData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  // download csv file
  const createCsvFile = async (f) => {
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet('Form', {
      headerFooter: {
        firstHeader: '開戶工單 （後台需求單）',
      },
    })
    if (f.setting.platform === 'Criteo') {
      CriteoForm({ ...f.content, ...f }).forEach((row) => sheet.addRow(row))
      sheet.getColumn(1).width = 15
      sheet.getColumn(2).width = 50
      sheet.mergeCells('A1:B1')
      sheet.mergeCells('A2:B2')
      sheet.mergeCells('A12:B12')
      sheet.mergeCells('A22:B22')
    } else {
      Object.keys(f.content).forEach((key) => {
        sheet.addRow([key, f.content[key]])
      })
    }
    const fileName = `${f.form_id} export.xlsx`
    const file = await workbook.xlsx.writeBuffer({
      base64: true,
    })
    const data = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()
  }

  const { formId } = useParams()

  // set form field when show a form
  const handleShowForm = (value) => {
    const target = getForm(value.type, value.setting.platform, managed)
    setformData({ content: { ...value.content }, ...value.extraFields })
    setform({
      ...target,
      form_id: value.form_id || formId,
      replys: value.replys || [],
      fixedContent: getFixedForm(auth, { ...value.content }),
      content: target.content.map((c) => ({
        ...c,
        disabled: true,
        type:
          c.type === 'multimail' || c.type === 'textarea' || c.type === 'check'
            ? c.type
            : 'fixed',
        value: value.content[c.name],
      })),
      extraFields: value.extraFields
        ? Object.keys(value.extraFields).map((key) => ({
            name: key,
            label: key,
            type: 'text',
            value: value.extraFields[key],
          }))
        : false,
      manager: value.manager,
    })
    setshowForm(true)
  }

  // get accessable forms
  const [forms, setforms] = useState([])
  const [managers, setmanagers] = useState([])
  useEffect(() => {
    if (!auth.account.business_id) return
    const getForms = async () => {
      const m = await apiService.data({
        path: `luca/user/managers`,
        method: 'get',
      })
      if (!m.error) setmanagers(m)
      const res = await apiService.data({
        path: `luca/form/${auth.account.business_id}`,
        method: 'get',
      })
      if (!res.error) setforms(res)
      if (formId) {
        const target = res.find((f) => f.form_id === parseInt(formId, 10))
        if (target) handleShowForm(target)
        navigate('/Forms', { replace: true })
      }
    }
    getForms()
  }, [auth.account, formId])
  const handleEditForm = async (id, data) => {
    const res = await apiService.data({
      path: `luca/form/${id}`,
      method: 'put',
      data,
    })
    if (!res.error)
      setforms(
        forms.map((f) => (f.form_id === res.form_id ? { ...f, ...res } : f))
      )
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
    hasFooter: true,
    warn: (
      <span className="text-center text-lucaDark">
        <div className="d-flex mb-3">
          <FontAwesomeIcon
            className="mx-auto display-1 mb-2 text-luca"
            icon={faExclamationTriangle}
          />
        </div>
        <h5>確定要刪除表單嗎？</h5>
      </span>
    ),
  })
  const handleShowWarn = (warn) => {
    setshowWarn({
      ...showWarn,
      ...warn,
      shouldReturn: warn.shouldReturn,
      show: true,
    })
  }

  const handleCloseForm = async (value) => {
    setshowForm(false)
    const selectedManager = Object.prototype.hasOwnProperty.call(
      formData,
      'manager'
    )
      ? formData.manager
      : null
    delete formData.manager

    if (value) {
      const data = { extraFields: { ...formData } }
      delete data.extraFields.content
      if (selectedManager) data.manager = selectedManager
      data.status = value
      if (value === '已 完 成')
        data.completed_on = moment().format('yyyy-MM-DD HH:mm')

      if (
        form.extraFields.length !== 0 &&
        form.extraFields.every((f) => formData[f.name] === f.value)
      ) {
        handleShowWarn({
          hasFooter: true,
          warn: (
            <span className="text-center text-lucaDark">
              <div className="d-flex mb-3">
                <FontAwesomeIcon
                  className="mx-auto display-1 mb-2 text-luca"
                  icon={faExclamationTriangle}
                />
              </div>
              <h5>表單資料沒有變動，確定要結單嗎?</h5>
            </span>
          ),
          shouldReturn: {
            type: 'complete',
            data,
          },
        })
      } else {
        const res = await apiService.data({
          path: `luca/form/${form.form_id}`,
          method: 'put',
          data,
        })
        if (!res.error) {
          setforms(
            forms.map((f) => (f.form_id === res.form_id ? { ...f, ...res } : f))
          )
          handleShowInfo()
        }
      }
    }
  }

  // reply
  const [replyData, setreplyData] = useState({ content: '', file: '' })
  const handleReplyDataChange = (event) => {
    setreplyData({ ...replyData, [event.target.name]: event.target.value })
  }
  const handleReplySend = async () => {
    const getArrayBuffer = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          resolve(reader.result)
        })
        reader.readAsArrayBuffer(file)
      })
    const data = {
      content: replyData.content,
      user: `系統管理員`,
      user_id: auth.auth.user_id,
      date: moment().format('YYYY/MM/DD HH:mm'),
    }
    if (replyData.file !== '') {
      const files = []
      for (let i = 0; i < replyData.file.length; i += 1) {
        files.push(getArrayBuffer(replyData.file[i]))
      }
      const buffered = await Promise.all(files)
      const arrayed = buffered.map((buffer, i) => ({
        name: replyData.file[i].name,
        data: Array.from(new Uint8Array(buffer)),
      }))
      const uploaded = await apiService.data({
        path: `file`,
        method: 'post',
        data: {
          files: JSON.stringify(arrayed),
        },
      })
      if (uploaded.error) {
        if (
          uploaded.error.response &&
          uploaded.error.response.status === '413'
        ) {
          settoast({ show: true, text: '上 傳 失 敗 ， 檔 案 過 大' })
        } else {
          settoast({ show: true, text: '上 傳 失 敗 ， 請 重 試' })
        }
        return
      }
      data.files = uploaded
    }
    const res = await apiService.data({
      path: `luca/form/${form.form_id}/reply`,
      method: 'post',
      data,
    })
    setreplyData({ content: '', file: '' })
    if (!res.error) {
      setforms(forms.map((f) => (f.form_id === res.form_id ? res : f)))
      setform({ ...form, replys: res.replys })
    }
  }

  const getFile = async (name) => {
    let res = await apiService.data({
      path: `static/${name}`,
      method: 'get',
      responseType: 'blob',
    })
    if (res.type === 'application/json') {
      res = await apiService.data({
        path: `file/${name}`,
        method: 'get',
        responseType: 'blob',
      })
    }
    const fileName = name.substring(0, name.lastIndexOf('_'))
    const url = URL.createObjectURL(res)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()
  }

  // filter
  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    type: '',
    platform: '',
    manager: '',
    sys_manager: '',
    status: '',
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    search: '',
  })
  const { type, platform, manager, sys_manager, status, sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  const filteredForms = forms
    .filter(
      (f) =>
        (type === '' || f.type === type) &&
        (manager === '' || (f.manager && f.manager === manager)) &&
        (sys_manager === '' ||
          (f.extraFields && f.extraFields.sys_manager === sys_manager) ||
          (sys_manager === 'not assigned' && !f.extraFields.sys_manager)) &&
        (platform === '' || f.setting.platform === platform) &&
        (status === '' ||
          f.status === status ||
          (!f.status && f.setting.status === status)) &&
        f.status !== '已 刪 除' &&
        (search === '' ||
          wordSearch(f.content['填表人'], search) ||
          wordSearch(f.content['填表人所屬單位'], search) ||
          wordSearch(f.content['客戶類型'], search) ||
          wordSearch(f.content['帳戶類型'], search) ||
          wordSearch(f.content['代理商工商登記公司名稱'], search) ||
          wordSearch(f.content['需求單位'], search) ||
          wordSearch(f.content['統一編號'], search) ||
          wordSearch(f.extraFields['帳戶ID'], search) ||
          wordSearch(f.extraFields['帳戶名稱'], search))
    )
    .sort((a, b) => {
      const { field, order } = JSON.parse(sort)
      return order === 'aesc'
        ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
        : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
    })

  const managerKeys = {}
  managers.forEach((m) => {
    managerKeys[m.user_id] = m.name
  })

  console.log(filteredForms)

  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value.type === 'delete')
      handleEditForm(value.id, { status: '已 刪 除' })
    else if (value.type === 'reject') handleCloseForm('已 退 回')
    else if (value.type === 'complete') {
      const res = await apiService.data({
        path: `luca/form/${form.form_id}`,
        method: 'put',
        data: value.data,
      })
      if (!res.error) {
        setforms(
          forms.map((f) => (f.form_id === res.form_id ? { ...f, ...res } : f))
        )
        handleShowInfo()
      }
    }
  }

  const [showKpiModal, setshowKpiModal] = useState(false)
  const handleKpiModalShow = () => setshowKpiModal(true)
  const handleKpiModalClose = () => setshowKpiModal(false)

  return (
    <Container fluid className="card px-2">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1"> 媒 體 申 請 總 表 管 理 </h4>
        <p>Media platform application management</p>
        <p className="pageSubtitle pt-4">
          管 理 員 可 在 此 頁 查 看 與 核 准 新 加 入 的 共 同 使 用 者 及 儲
          值 申 請
        </p>
      </Row>
      <Row className="pt-3 pb-0 px-4">
        <Col xs={1}>
          <SelectBar
            setting={{
              method: (e) =>
                handleFilterChange({
                  target: {
                    name: 'sort',
                    value:
                      e.target.value ||
                      JSON.stringify({ field: 'name', order: 'desc' }),
                  },
                }),
              name: 'sort',
              value: sort,
              placeholder: '排序',
              content: [
                {
                  name: '排序 － 由最新至最舊',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'desc',
                  }),
                },
                {
                  name: '排序 － 由最舊至最新',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'aesc',
                  }),
                },
              ],
            }}
          />
        </Col>
        <Col className="ps-0">
          <SelectBar
            setting={{
              name: 'type',
              method: handleFilterChange,
              placeholder: '選擇類型',
              content: [
                { name: t('OpenAccount'), value: 'OpenAccount' },
                { name: t('Recharge'), value: 'Recharge' },
                { name: t('Permission'), value: 'Permission' },
              ],
            }}
          />
        </Col>
        <Col className="ps-0">
          <SelectBar
            setting={{
              name: 'platform',
              method: handleFilterChange,
              placeholder: '媒體平台',
              content: [
                { name: 'Facebook', value: 'Facebook' },
                { name: 'Google', value: 'Google' },
                { name: 'X', value: 'Twitter' },
                { name: 'LinkedIn', value: 'LinkedIn' },
                // { name: 'YahooDSP', value: 'YahooDSP' },
                { name: 'Taboola', value: 'Taboola' },
                { name: 'YahooMA', value: 'YahooMA' },
                { name: 'Line', value: 'Line' },
                { name: 'TTD', value: 'TTD' },
                { name: 'Criteo', value: 'Criteo' },
                { name: 'Dcard', value: 'Dcard' },
                { name: 'Tiktok', value: 'Tiktok' },
              ],
            }}
          />
        </Col>
        <Col className="ps-0">
          <SelectBar
            setting={{
              name: 'status',
              placeholder: '所有狀態',
              method: handleFilterChange,
              content: ['待 處 理', '處 理 中', '已 完 成', '已 退 回'].map(
                (s) => ({
                  name: s,
                  value: s,
                })
              ),
            }}
          />
        </Col>
        <Col className="ps-0">
          <SelectBar
            setting={{
              name: 'manager',
              placeholder: '所有負責人',
              method: handleFilterChange,
              content: managers
                .map((m) => ({
                  name: m.name,
                  value: m.user_id,
                }))
                .concat([
                  {
                    name: '未指派',
                    value: 'not assigned',
                  },
                ]),
            }}
          />
        </Col>
        <Col className="ps-0">
          <SelectBar
            setting={{
              name: 'sys_manager',
              placeholder: '所有系統負責人',
              method: handleFilterChange,
              content: managers
                .map((m) => ({
                  name: m.name,
                  value: m.user_id,
                }))
                .concat([
                  {
                    name: '未指派',
                    value: 'not assigned',
                  },
                ]),
            }}
          />
        </Col>
        <Col className="px-0 flex-grow-1">
          <SearchBar
            setting={{
              title: '關鍵字...',
              name: 'search',
              // action: <FontAwesomeIcon icon={faSearch} />,
              tempSearch,
              settempSearch,
              method: () =>
                handleFilterChange({
                  target: {
                    name: 'search',
                    value: tempSearch,
                  },
                }),
            }}
          />
        </Col>
        <Col className="ps-2 pe-3 flex-grow-1 d-flex">
          <Button
            className="d-flex w-100 text-center"
            variant="luca"
            onClick={handleKpiModalShow}
          >
            產 出 報 表&ensp;
            <FontAwesomeIcon className="m-auto" icon={faFileCirclePlus} />
          </Button>
        </Col>
      </Row>
      <Row className="px-4 pt-3 pb-2 h-100 tableLayout-adj overflow-auto">
        <Datatable
          setting={{
            hasCheckBox: false,
            hasButton: false,
            hasPagination: true,
            pageSize: 5,
            headers: [
              // '平 台',
              '申 請 類 別',
              '申 請 時 間',
              '結 單 時 間',
              '申 請 人',
              '需 求 單 位',
              '負 責 人',
              '系 統 處 理 人',
              '狀 態',
              '功 能',
            ],
            content: filteredForms
              .concat(
                Array(
                  filteredForms.length % 5 > 0
                    ? 5 - (filteredForms.length % 5)
                    : 0
                ).fill({
                  fill: true,
                })
              )
              .map((f) =>
                f.fill
                  ? {
                      type: { value: '- -' },
                      time: { value: '- -' },
                      time2: { value: '- -' },
                      bm: { value: '- -' },
                      class: { value: '- -' },
                      manager: { value: '- -' },
                      sys_manager: { value: '- -' },
                      status: { value: '- -' },
                      toollist: { value: '- -' },
                    }
                  : {
                      type: {
                        value: (
                          <span className="d-flex">
                            {getIcon(f.setting.platform, '20px', '20px')}
                            <p className="me-auto my-auto">{t(f.type)}</p>
                          </span>
                        ),
                      },
                      time: {
                        value: moment(f.created_on).format('YY-MM-DD HH:mm'),
                      },
                      time2: {
                        value: f.completed_on
                          ? moment(f.completed_on).format('YY-MM-DD HH:mm')
                          : '- -',
                      },
                      bm: {
                        // value: `${f.business_name || '- -'}(${f.user_name})`,
                        value: f.user_name,
                      },
                      class: {
                        value: f.content['需求單位'] || '- -',
                        title: f.content['需求單位'] || '- -',
                      },
                      manager: {
                        value: managerKeys[f.manager] || '未 指 派',
                      },
                      sys_manager: {
                        value:
                          managerKeys[f.extraFields?.sys_manager] || '未 指 派',
                      },
                      status: { value: f.status || f.setting.status },
                      toollist: {
                        value: (
                          <div className="formsTool">
                            <Button
                              variant="lucaIcon"
                              onClick={() => createCsvFile(f)}
                            >
                              <FontAwesomeIcon
                                title="表 單 下 載"
                                icon={faDownload}
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() => {
                                handleShowForm(f)
                              }}
                            >
                              <FontAwesomeIcon
                                title="表 單 內 容"
                                icon={faEnvelopeOpenText}
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() => {
                                handleShowWarn({
                                  title: '刪 除 表 單',
                                  titleEng: 'Delete Business Manager',
                                  img: faExclamationTriangle,
                                  text: <h6>確定刪除表單？</h6>,
                                  warn: (
                                    <h6 className="text-danger">
                                      已刪除的表單仍會留存在紀錄中。
                                    </h6>
                                  ),
                                  shouldReturn: {
                                    id: f.form_id,
                                    type: 'delete',
                                  },
                                })
                              }}
                            >
                              <FontAwesomeIcon
                                title="刪 除 申 請"
                                icon={faTrashAlt}
                              />
                            </Button>
                          </div>
                        ),
                      },
                      method: () => {},
                    }
              ),
          }}
        />
      </Row>
      <FormDialog
        setting={{
          size: 'md',
          show: showForm,
          form: {
            ...form,
            size: 'xl',
            ...pageSetting[form.type],
          },
          formData,
          handleDataChange,
          handleClose: handleCloseForm,
          btnText: '儲 存',
          managers,
          auth,
          replyAble: true,
          replyData,
          handleReplyDataChange,
          handleReplySend,
          hasComplete: true,
          hasFooter: true,
          download: (name) => getFile(name),
          footer: (
            <div className="w-100 d-flex">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => handleCloseForm()}
              >
                取 消
              </Button>
              <Button
                className="me-2 me-auto"
                variant="luca"
                onClick={() => handleCloseForm('處 理 中')}
              >
                儲 存
              </Button>
              <Button
                className="ms-auto me-2 btn-danger"
                // variant="danger"
                onClick={() => {
                  setshowForm(false)
                  setform({ ...form, content: [], extraFields: [] })
                  setformData({ 退件原因: '' })
                  handleShowWarn({
                    title: '拒 絕 申 請',
                    titleEng: 'Reject Apply',
                    img: faExclamationTriangle,
                    text: <h6>確定拒絕申請？</h6>,
                    warn: (
                      <h6 className="text-danger">
                        退件原因：
                        <Form.Control
                          name="退件原因"
                          defaultValue={formData['退件原因']}
                          onChange={(e) => handleDataChange(e)}
                        />
                      </h6>
                    ),
                    shouldReturn: { type: 'reject' },
                  })
                }}
              >
                拒 絕
              </Button>
              <Button
                className="me-2"
                variant="green"
                onClick={() => handleCloseForm('已 完 成')}
                disabled={
                  Object.keys(formData).length !== 0 &&
                  !Object.keys(formData).every(
                    (key) => key === 'content' || formData[key] !== ''
                  )
                }
              >
                結 單
              </Button>
            </div>
          ),
        }}
      />
      <InfoDialog
        show={showInfo}
        handleClose={handleCloseInfo}
        setting={{
          imgSrc: successImg2,
          content: '修 改 已 儲 存 ! ',
          contentEng: 'Success Saved! ',
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          title: '確 定 修 改',
          titleEng: 'Profile Edit Confirm',
          warning: showWarn.warn,
          handleClose: handleWarnClose,
        }}
      />
      <KPIModal
        setting={{
          show: showKpiModal,
          forms,
          size: 'xl',
          handleClose: handleKpiModalClose,
        }}
      />
    </Container>
  )
}

export default Forms
