import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faTrashAlt,
  faEdit,
  faFileImport,
  faUserPlus,
  faTasks,
  // faDownload,
} from '@fortawesome/free-solid-svg-icons'
import { faFlushed } from '@fortawesome/free-regular-svg-icons'
import {
  SearchBar,
  SelectBar,
  Datatable,
  BMManager,
  FormDialog,
  ConfirmForm,
  AccountImport,
  VirtualAccount,
  // BmsModal,
} from '../components'
import apiService from '../services/apiService'
import { departs, wordSearch } from '../services/lucaFunctions'

function Bms() {
  const [managed, setmanaged] = useState(false)
  useEffect(() => {
    apiService.data({ path: `luca/business`, method: 'get' }).then((value) => {
      setmanaged(value)
    })
  }, [])
  const [vas, setvas] = useState([])
  useEffect(() => {
    if (managed && vas.length > 0)
      setmanaged(
        managed.map((m) => {
          const target = vas.find((va) => va.name === m.name)
          return {
            ...m,
            accountNumber: target
              ? target.va.virtualAccounts[0].accountNumber
              : '',
          }
        })
      )
  }, [vas])

  // bmmanager
  const [show, setshow] = useState(false)
  const [adaccounts, setadaccounts] = useState({
    managed: [],
    all: [],
    managedList: {},
  })
  const [users, setusers] = useState({ managed: [], all: [], managedList: {} })
  const [business, setbusiness] = useState('')
  const [action, setaction] = useState('accounts')
  const [admin, setadmin] = useState({})
  useEffect(() => {
    // if (!auth.account._id) return
    const getAll = async () => {
      const allUsers = await apiService.data({
        path: `luca/user`,
        method: 'get',
      })
      const allAdAccounts = await apiService.data({
        path: `luca/account`,
        method: 'get',
      })
      const wavenetUsers = await apiService.data({
        path: `luca/business/1/users`,
        method: 'get',
      })
      setusers({
        ...users,
        all: allUsers.users,
        managedList: allUsers.managedList,
      })
      setadaccounts({
        ...adaccounts,
        all: allAdAccounts.accounts,
        managedList: allAdAccounts.managedList,
      })
      const tempAdmin = {}
      wavenetUsers.forEach((wu) => {
        const target = allUsers.users.find((u) => u.user_id === wu.user_id)
        if (!target) return
        if (tempAdmin[target.setting.depart]) {
          tempAdmin[target.setting.depart].push(target.name)
        } else {
          tempAdmin[target.setting.depart] = [target.name]
        }
      })
      setadmin(tempAdmin)
    }
    const getVas = async () => {
      const res = await apiService.data({
        path: `luca/budget/account`,
        method: 'get',
      })
      setvas(res)
    }
    getAll()
    getVas()
  }, [])
  const handleShow = async (value, type) => {
    setbusiness(value)
    setaction(type)
    const managedUsers = await apiService.data({
      path: `luca/business/${value}/users`,
      method: 'get',
    })
    const managedAdAccounts = await apiService.data({
      path: `luca/account/${value}`,
      method: 'get',
    })
    setusers({ ...users, managed: managedUsers })
    setadaccounts({ ...adaccounts, managed: managedAdAccounts })
    setshow(true)
  }
  const handleClose = () => {
    setshow(false)
  }

  // add and delete features
  const handleAddAccount = async (id) => {
    const res = await apiService.data({
      path: `luca/account/${business}/${id}`,
      method: 'post',
      data: { permission: 'Write' },
    })
    if (!res.error) {
      setadaccounts({
        ...adaccounts,
        managed: adaccounts.managed.concat(
          adaccounts.all.find((account) => account.account_id === id)
        ),
        managedList: {
          ...adaccounts.managedList,
          [business]: parseInt(adaccounts.managedList[business], 10) + 1,
        },
      })
    }
  }
  const handleAddUser = async (id) => {
    const res = await apiService.data({
      path: `luca/businessUser/${id}/${business}`,
      method: 'post',
      data: {
        isAdmin: true,
        isAdsManager: false,
        isAnalyst: false,
        isFinance: false,
      },
    })
    if (!res.error) {
      setusers({
        ...users,
        managed: users.managed.concat({
          ...users.all.find((u) => u.user_id === id),
          setting: res.setting,
        }),
        managedList: {
          ...users.managedList,
          [business]: parseInt(users.managedList[business], 10) + 1,
        },
      })
    }
  }

  const handleDeleteAccount = async (id) => {
    const res = await apiService.data({
      path: `luca/account/${business}/${id}`,
      method: 'delete',
    })
    if (!res.error) {
      setadaccounts({
        ...adaccounts,
        managed: adaccounts.managed.filter((a) => a.account_id !== id),
        managedList: {
          ...adaccounts.managedList,
          [business]: parseInt(adaccounts.managedList[business], 10) - 1,
        },
      })
    }
  }
  const handleDeleteUser = async (id) => {
    const res = await apiService.data({
      path: `luca/businessUser/${id}/${business}`,
      method: 'delete',
    })
    if (!res.error) {
      setusers({
        ...users,
        managed: users.managed.filter((u) => u.user_id !== id),
        managedList: {
          ...users.managedList,
          [business]: parseInt(users.managedList[business], 10) - 1,
        },
      })
    }
  }
  const handleUserChange = async (user, setting) => {
    const saved = await apiService.data({
      path: `luca/businessUser/${user}/${business}`,
      method: 'put',
      data: { setting },
    })
    if (saved && !saved.error) {
      setusers({
        ...users,
        managed: users.managed.map((u) =>
          u.user_id !== user ? u : { ...u, setting: saved.setting }
        ),
      })
    }
  }

  // form for add and delete business
  const [form, setform] = useState({
    pageLimit: 1,
    content: [],
  })
  const [formData, setformData] = useState({ content: {} })
  const [showForm, setshowForm] = useState(false)

  const addBMForm = {
    title: '新 增 A D M 帳 戶',
    titleEng: 'Add a new BM account',
    size: 'lg',
    pageLimit: 2,
    type: 'New BM',
    setting: { platform: 'Luca' },
    content: [
      {
        name: 'ADM名稱',
        label: 'ADM名稱',
        placeholder: '輸入名稱...',
        type: 'text',
        required: true,
      },
      {
        name: '客戶編號',
        label: '客戶編號',
        placeholder: '輸入編號...',
        type: 'text',
      },
      {
        name: '客戶公司工商登記中文名稱',
        label: '客戶公司工商登記中文名稱',
        placeholder: '輸入名稱...',
        type: 'text',
      },
      {
        name: '客戶公司工商登記英文名稱',
        label: '客戶公司工商登記英文名稱',
        placeholder: '輸入名稱...',
        type: 'text',
      },
      {
        name: '統一編號',
        label: '統一編號',
        placeholder: '輸入名稱...',
        type: 'text',
      },
      {
        name: '客戶類型',
        label: '客戶類型',
        placeholder: '選擇客戶類型',
        type: 'select',
        content: [
          { name: '代理商', value: '代理商' },
          { name: '直客', value: '直客' },
        ],
      },
      {
        name: '潮網銷售顧問*ERP業務專員/部門',
        label: '潮網銷售顧問*ERP業務專員/部門',
        placeholder: '選擇部門',
        type: 'select',
        content: departs,
      },
      ...Object.keys(admin)
        .filter((key) => key !== 'undefined')
        .map((key) => ({
          name: '潮網銷售顧問*ERP業務專員',
          label: '潮網銷售顧問*ERP業務專員',
          placeholder: '選擇業務專員',
          type: 'select',
          dependency: {
            name: '潮網銷售顧問*ERP業務專員/部門',
            value: key,
          },
          content: admin[key].map((u) => ({
            name: u,
            value: u,
          })),
        })),
    ],
    handleSendForm: async (value) => {
      const res = await apiService.data({
        path: `luca/business`,
        method: 'post',
        data: value.content,
      })
      if (!res.error) {
        setmanaged(managed.concat(res))
      }
    },
  }

  const EditBMForm = (BM) => ({
    title: '修 改 A D M 資 料',
    titleEng: 'Edit ADM profile',
    size: 'lg',
    pageLimit: 2,
    type: 'New BM',
    setting: { platform: 'Luca' },
    content: [
      {
        name: 'ADM名稱',
        label: 'ADM名稱',
        placeholder: '輸入名稱...',
        type: 'text',
        value: BM.setting['ADM名稱'] || BM.name,
      },
      {
        name: '客戶編號',
        label: '客戶編號',
        placeholder: '輸入編號...',
        type: 'text',
        value: BM.setting['客戶編號'],
      },
      {
        name: '客戶公司工商登記中文名稱',
        label: '客戶公司工商登記中文名稱',
        placeholder: '輸入名稱...',
        type: 'text',
        value: BM.setting['客戶公司工商登記中文名稱'],
      },
      {
        name: '客戶公司工商登記英文名稱',
        label: '客戶公司工商登記英文名稱',
        placeholder: '輸入名稱...',
        type: 'text',
        value: BM.setting['客戶公司工商登記英文名稱'],
      },
      {
        name: '統一編號',
        label: '統一編號',
        placeholder: '輸入名稱...',
        type: 'text',
        value: BM.setting['統一編號'],
      },
      {
        name: '客戶類型',
        label: '客戶類型',
        placeholder: '選擇客戶類型',
        type: 'select',
        content: [
          { name: '代理商', value: '代理商' },
          { name: '直客', value: '直客' },
        ],
        value: BM.setting['客戶類型'],
      },
      {
        name: '潮網銷售顧問*ERP業務專員/部門',
        label: '潮網銷售顧問*ERP業務專員/部門',
        placeholder: '選擇部門',
        type: 'select',
        content: departs,
        value: BM.setting['潮網銷售顧問*ERP業務專員/部門'],
      },
      ...Object.keys(admin)
        .filter((key) => key !== 'undefined')
        .map((key) => ({
          name: '潮網銷售顧問*ERP業務專員',
          label: '潮網銷售顧問*ERP業務專員',
          placeholder: '選擇業務專員',
          type: 'select',
          dependency: {
            name: '潮網銷售顧問*ERP業務專員/部門',
            value: key,
          },
          value: BM.setting['潮網銷售顧問*ERP業務專員'],
          content: admin[key].map((u) => ({
            name: u,
            value: u,
          })),
        })),
    ],
    handleSendForm: async (value) => {
      const res = await apiService.data({
        path: `luca/business/${BM.business_id}`,
        method: 'put',
        data: value.content,
      })
      if (!res.error) {
        setmanaged(
          managed.map((m) => (m.business_id === res.business_id ? res : m))
        )
      }
    },
  })

  const handleShowForm = (value) => {
    const content = {}
    value.content.forEach((c) => {
      content[c.name] = c.value ? c.value : ''
    })
    setformData({ ...value, content })
    setform({
      ...value,
      // fixedContent: [...value.content].filter((c) => c.type === 'fixed'),
      content: [...value.content],
    })
    setshowForm(true)
  }
  const handleDataChange = (event) => {
    setformData({
      ...formData,
      content: { ...formData.content, [event.target.name]: event.target.value },
    })
  }
  const handleCloseForm = (value) => {
    setshowForm(false)
    if (value) form.handleSendForm({ ...formData })
  }

  const [showImport, setshowImport] = useState(false)
  const [provided, setprovided] = useState(false)
  const [selected, setselected] = useState([])
  const handleAccountImport = async () => {
    setshowImport(true)
    if (provided) return
    const facebook = await apiService.data({
      path: `luca/insight/list/facebook`,
      method: 'get',
    })
    const google = await apiService.data({
      path: `luca/insight/list/google`,
      method: 'get',
    })
    setprovided([
      ...facebook.map((f) => ({ ...f, provider: 'facebook' })),
      ...google.map((g) => ({ ...g, provider: 'google' })),
    ])
  }
  const handleImportClose = () => setshowImport(false)

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      if (showWarn.target === 'business') {
        const res = await apiService.data({
          path: `luca/business/${value}`,
          method: 'delete',
        })
        if (!res.error)
          setmanaged(managed.filter((m) => m.business_id !== value))
      }
      if (showWarn.target === 'account') {
        const facebookSync = await apiService.data({
          path: `luca/insight/sync/facebook`,
          method: 'post',
          data: selected,
        })
        const googleSync = await apiService.data({
          path: `luca/insight/sync/google`,
          method: 'post',
          data: selected,
        })
        if (!facebookSync.error && !googleSync.error)
          setadaccounts({
            ...adaccounts,
            all: [...adaccounts.all, ...facebookSync, ...googleSync],
          })
        setselected([])
      }
    } else if (showWarn.target === 'account') {
      setshowImport(true)
    }
  }

  // Register
  const [registerData, setregisterData] = useState({
    name: '',
    department: '',
    email: '',
    password: '',
  })
  const onRegisterDataChange = (event) => {
    setregisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    })
  }
  const [status, setstatus] = useState({
    time: 0,
    waiting: false,
    result: '',
    success: false,
  })
  const handleRegister = async (value) => {
    setregisterData({
      name: '',
      department: '',
      email: '',
      password: '',
    })
    setstatus({ ...status, waiting: true })
    const res = await apiService.register(value)
    if (res && !res.error) {
      setusers({
        ...users,
        all: users.all.concat(res),
      })
      setstatus({
        ...status,
        waiting: false,
        time: 1.5,
        result: '新 增 完 成',
        success: true,
      })
    } else {
      setstatus({
        ...status,
        waiting: false,
        time: 1.5,
        result: res.error,
        success: false,
      })
    }
  }
  useEffect(() => {
    const count = (time) =>
      setTimeout(() => {
        setstatus({ ...status, time })
      }, 100)
    if (status.time > 0) count(status.time - 0.1)
    if (status.time < 0) setstatus({ ...status, result: '', success: false })
  }, [status.time])

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    search: '',
  })
  const { sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })
  const filteredManaged = useMemo(
    () =>
      managed
        ? managed
            .filter(
              (m) =>
                search === '' ||
                wordSearch(m.name, search) ||
                wordSearch(m.accountNumber || '', search)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : [],
    [managed, vas, search, sort]
  )

  // 匯出csv
  // const createFile = async () => {
  //   const workbook = new ExcelJS.Workbook()
  //   const sheet = workbook.addWorksheet('廣告帳號對照表', {
  //     headerFooter: {
  //       firstHeader: '廣告帳號對照表',
  //     },
  //   })
  //   sheet.addRow(['ADM名稱', '廣告帳戶'])
  //   sheet.getColumn(1).width = 65
  //   sheet.getColumn(2).width = 15
  //   filteredManaged.forEach((fm) => {
  //     sheet.addRow([
  //       fm.name,
  //       adaccounts.managedList && adaccounts.managedList[fm.business_id]
  //         ? adaccounts.managedList[fm.business_id].map((a) => a.name).join('/n')
  //         : 0,
  //     ])
  //   })

  //   // medias.forEach((media) => {
  //   //   const sheet = workbook.addWorksheet(media, {
  //   //     headerFooter: {
  //   //       firstHeader: `${media}消耗量一覽`,
  //   //     },
  //   //   })
  //   //   sheet.getColumn(1).width = 65
  //   //   sheet.getColumn(2).width = 15
  //   //   const accounts = formattedCosts(
  //   //     media,
  //   //     'account',
  //   //     search,
  //   //     costs ? costs[media].data : false
  //   //   )
  //   //   const projects = formattedCosts(
  //   //     media,
  //   //     'project',
  //   //     search,
  //   //     costs ? costs[media].data : false
  //   //   )
  //   //   console.log(accounts)
  //   // sheet.addRow(['帳戶名稱', '花費', '貨幣'])
  //   // sheet.addRow(['總計', numeral(accounts.total).format('$0,0.00'), 'USD'])
  //   // accounts.result.forEach((a) => {
  //   //   sheet.addRow([a.account, numeral(a.cost).format('$0,0.00'), a.currency])
  //   // })
  //   // sheet.addRow()
  //   // sheet.addRow(['案號', '花費', '貨幣'])
  //   // sheet.addRow(['總計', numeral(projects.total).format('$0,0.00'), 'USD'])
  //   // projects.result.forEach((a) => {
  //   //   sheet.addRow([a.project, numeral(a.cost).format('$0,0.00'), a.currency])
  //   // })

  //   // sheet.addRow()
  //   // sheet.addRow(['案號', '花費', '貨幣'])
  //   // console.log(projects.problems)
  //   // projects.problems.forEach((a) => {
  //   //   sheet.addRow([
  //   //     a.campaign_name || a.name,
  //   //     numeral(a.cost || a.spend).format('$0,0.00'),
  //   //     a.currency,
  //   //   ])
  //   // })
  //   // })
  //   const file = await workbook.xlsx.writeBuffer({
  //     base64: true,
  //   })
  //   const data = new Blob([file], {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   })
  //   const url = URL.createObjectURL(data)
  //   const link = document.createElement('a')
  //   link.setAttribute('href', url)
  //   link.setAttribute('download', `廣告帳戶對照表.xlsx`)
  //   document.body.appendChild(link)

  //   link.click()
  //   // const fileName = `business export.csv`
  //   // const csvData = vas.map(
  //   //   (va) =>
  //   //     `${va.partner_id},"${va.name}",${
  //   //       va.va.virtualAccounts[0].accountNumber
  //   //     },"${
  //   //       adaccounts.managedList[va.business_id]
  //   //         ? adaccounts.managedList[va.business_id]
  //   //             .map((a) => a.name)
  //   //             .join('\n')
  //   //         : ''
  //   //     }"`
  //   // )
  //   // const csvData = await Promise.all(
  //   //   filteredManaged.map(async (fm) => {
  //   //     const va = vas.find((v) => v.business_id === fm.business_id)
  //   //     const vaAccount =
  //   //       va && va.virtualAccounts.find((a) => a.bankCode === '012')
  //   //         ? va.virtualAccounts.find((a) => a.bankCode === '012').accountNumber
  //   //         : '- -'
  //   //     return `${fm.setting['客戶編號'] || '- -'},"${fm.name}",${vaAccount}`
  //   //   })
  //   // )
  //   // const csvContent = `data:application/csv;charset=utf-8,\ufeff客戶編號,ADM名稱,虛擬帳號\n${csvData.join(
  //   //   '\n'
  //   // )}`
  //   // const encodedUri = encodeURI(csvContent)
  //   // const link = document.createElement('a')
  //   // link.setAttribute('href', encodedUri)
  //   // link.setAttribute('download', fileName)
  //   // document.body.appendChild(link)

  //   // link.click()
  // }

  // const [showModal, setshowModal] = useState(false)

  return (
    <Container fluid className="authManage card px-2">
      <Row className="p-4 pageTitle">
        <h4 className="pt-4 pb-1">ADM 創 建 與 管 理</h4>
        <p>ADM account creation and management</p>
        <p className="pageSubtitle pt-4">
          潮 網 人 員 可 在 此 創 建 客 戶 企 業 平 台 及 配 發 資 源 權 限
        </p>
      </Row>
      <Row className="pt-3 pb-0 px-4">
        <Col xs="4">
          <SelectBar
            setting={{
              method: (e) =>
                handleFilterChange({
                  target: {
                    name: 'sort',
                    value:
                      e.target.value ||
                      JSON.stringify({ field: 'name', order: 'desc' }),
                  },
                }),
              value: sort,
              placeholder: '選擇排序',
              content: [
                {
                  name: '排序 － 由最新至最舊',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'desc',
                  }),
                },
                {
                  name: '排序 － 由最舊至最新',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'aesc',
                  }),
                },
              ],
            }}
          />
        </Col>
        <Col xs="4" className="ps-0">
          <SearchBar
            setting={{
              title: '請輸入搜尋關鍵字...',
              name: 'search',
              tempSearch,
              settempSearch,
              method: () =>
                handleFilterChange({
                  target: {
                    name: 'search',
                    value: tempSearch,
                  },
                }),
            }}
          />
        </Col>
        <Col xs="2" className="ps-0">
          <Button
            className="w-100"
            variant="luca"
            title="建 立 新 A D M 帳 戶"
            onClick={() => handleShowForm(addBMForm)}
          >
            新增 ADM
            <FontAwesomeIcon icon={faPlusCircle} className="fs-6 ms-2" />
          </Button>
        </Col>
        <Col xs="2" className="ps-0">
          <Button
            className="w-100"
            variant="luca"
            title="匯 入 廣 告 帳 戶"
            onClick={handleAccountImport}
          >
            匯入廣告帳戶
            <FontAwesomeIcon icon={faFileImport} className="fs-6 ms-2" />
          </Button>
        </Col>
        {/* <Col xs="2" className="ps-0">
          <Button
            className="w-100"
            variant="luca"
            title="下 載"
            onClick={() => setshowModal(true)}
          >
            總表下載
            <FontAwesomeIcon icon={faDownload} className="fs-6 ms-2" />
          </Button>
        </Col> */}
      </Row>
      <Row className="px-4 pt-3 pb-2 h-100 tableLayout-adj tableW-adm-adj overflow-auto">
        <Datatable
          setting={{
            hasCheckBox: false,
            hasButton: false,
            hasPagination: true,
            pageSize: 5,
            headers: [
              '企 業 平 台 (ADM)',
              '虛 擬 帳 號',
              '建 立 日 期',
              '所 屬 公 司',
              '廣 告 帳 號 數',
              '成 員 總 數',
              '功 能',
            ],
            content: filteredManaged
              .concat(
                Array(
                  filteredManaged.length % 5 > 0
                    ? 5 - (filteredManaged.length % 5)
                    : 0
                ).fill({
                  fill: true,
                })
              )
              .map((m) =>
                m.fill
                  ? {
                      type: { value: '- -' },
                      va: { value: '- -' },
                      createdAt: { value: '- -' },
                      businessName: { value: '- -' },
                      accounts: { value: '- -' },
                      users: { value: '- -' },
                      delete: { value: '- -' },
                    }
                  : {
                      type: { value: m.name, title: m.name },
                      va: {
                        value: m.accountNumber ? (
                          m.accountNumber
                        ) : (
                          <VirtualAccount
                            setting={{
                              business_id: m.business_id,
                              bankCode: '012',
                            }}
                          />
                        ),
                      },
                      createdAt: {
                        value: moment(m.created_on).format('YYYY-MM-DD'),
                      },
                      businessName: {
                        value: m.setting['客戶公司工商登記中文名稱'] || m.name,
                        title: m.setting['客戶公司工商登記中文名稱'] || m.name,
                      },
                      accounts: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {adaccounts.managedList &&
                              adaccounts.managedList[m.business_id]
                                ? adaccounts.managedList[m.business_id].length
                                : 0}
                            </span>
                          </div>
                        ),
                      },
                      users: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {(users.managedList &&
                                users.managedList[m.business_id]) ||
                                0}
                            </span>
                          </div>
                        ),
                      },
                      delete: {
                        value: (
                          <div className="formsTool">
                            <Button
                              variant="lucaIcon"
                              onClick={() =>
                                handleShow(m.business_id, 'accounts')
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faTasks}
                                title="廣 告 帳 戶 管 理"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() => handleShow(m.business_id, 'users')}
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faUserPlus}
                                title="新 增 人 員"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() => handleShowForm(EditBMForm(m))}
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faEdit}
                                title="編 輯"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              onClick={() =>
                                handleShowWarn(
                                  {
                                    ...m,
                                    title: '刪 除 A D M 帳 戶',
                                    titleEng: 'Delete Business Manager',
                                    img: faFlushed,
                                    text: (
                                      <h6>
                                        是否刪除「{m.name}」企業平台帳戶？
                                      </h6>
                                    ),
                                    warn: (
                                      <h6 className="text-danger py-2">
                                        【警告】此動作無法復原，輸入ADM名稱並確認。
                                      </h6>
                                    ),
                                    shouldMatch: m.name,
                                    shouldReturn: m.business_id,
                                  },
                                  'business'
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faTrashAlt}
                                title="刪 除"
                              />
                            </Button>
                          </div>
                        ),
                      },
                      method: () => {},
                    }
              ),
          }}
        />
      </Row>
      <BMManager
        setting={{
          show,
          handleClose,
          dialogClassName: action === 'accounts' ? '' : 'w-97 mw-100',
          size: 'xl',
          title:
            action === 'accounts'
              ? '廣 告 帳 戶 管 理'
              : '使 用 者 帳 戶 管 理',
          titleEng:
            action === 'accounts'
              ? 'Advertising account management'
              : 'User account management',
          managed,
          items: action === 'accounts' ? adaccounts.all : users.all,
          assigned: action === 'accounts' ? adaccounts.managed : users.managed,
          handleAdd: action === 'accounts' ? handleAddAccount : handleAddUser,
          handleDelete:
            action === 'accounts' ? handleDeleteAccount : handleDeleteUser,
          handleUserChange,
          registerData,
          onRegisterDataChange,
          handleRegister,
          action,
          status,
        }}
      />
      <FormDialog
        setting={{
          size: 'md',
          show: showForm,
          form,
          formData,
          handleDataChange,
          handleClose: handleCloseForm,
          btnText: '送 出',
          hasFooter: true,
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="my-4">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
      <AccountImport
        setting={{
          size: provided ? 'xl' : 'md',
          provided: provided || [],
          prepared: provided,
          show: showImport,
          handleClose: handleImportClose,
          all: adaccounts.all,
          selected,
          setselected,
          handleImport: () => {
            const filteredProvided = provided.filter((p) =>
              selected.includes(p.account_id)
            )
            setshowImport(false)
            handleShowWarn(
              {
                title: '匯 入 帳 號',
                titleEng: 'Import advertise account',
                img: faFileImport,
                text: (
                  <h5 className="mb-3 text-lucaDark">
                    確定要匯入以下廣告帳號嗎？
                  </h5>
                ),
                warn: (
                  <>
                    {filteredProvided.slice(0, 10).map((p) => (
                      <p key={p.account_id} className="text-luca">
                        {p.name}
                      </p>
                    ))}
                    {filteredProvided.length > 10 && (
                      <p className="text-luca">
                        ...and {filteredProvided.length - 10} accounts
                      </p>
                    )}
                  </>
                ),
                shouldReturn: true,
              },
              'account'
            )
          },
        }}
      />
      {/* {showModal && (
        <BmsModal
          setting={{
            show: showModal,
            bms: filteredManaged,
            adaccounts,
            users,
            handleClose: () => setshowModal(false),
            createFile: () => {},
          }}
        />
      )} */}
    </Container>
  )
}

export default Bms
