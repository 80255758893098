import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faPlusCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { accTypeGoogle, accTypeMeta } from '../assets'
import { getAvatar } from '../services/lucaFunctions'

function UserCard(props) {
  const { setting, handleUserChange } = props
  const {
    id,
    name,
    avatar_id,
    email,
    facebook,
    google,
    created_on,
    setting: permission,
    btnFunc,
    btnText,
    tempRemark,
    handleEditRemark,
    editRemark,
    handleShowWarn,
  } = setting

  // permission key name
  const permissions = {
    isAdmin: 'ADM管理者',
    isAdsManager: '投放優化師',
    isAnalyst: '成效分析師',
    isFinance: '財務人員',
  }

  return (
    <Card className="text-start p-0 mb-3">
      <Card.Body>
        <Container className="p-0">
          <Row>
            <Col xs={2}>
              <Row className="mt-2">
                <Image
                  style={{ margin: '0 auto' }}
                  className="d-flex justify-content-center align-self-center w-50 h-50 py-2"
                  src={getAvatar(avatar_id)}
                  alt="頭 像"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-2 text-lucaDark">
                {name}
              </Row>
            </Col>
            <Col xs={4} className="py-1 text-lucaDark py-3">
              <Row>電子郵件： {email}</Row>
              <Row>建立時間： {moment(created_on).format('YYYY-MM-DD')}</Row>
              <Row className="AccType">
                {facebook ? (
                  <Image
                    title="該 使 用 者 擁 有 Facebook 廣 告 帳 戶 權 限"
                    src={accTypeMeta}
                  />
                ) : (
                  ''
                )}
                {google ? (
                  <Image
                    title="該 使 用 者 擁 有 Google 廣 告 帳 戶 權 限"
                    src={accTypeGoogle}
                  />
                ) : (
                  ''
                )}
              </Row>
            </Col>
            <Col xs={3} className="px-3 mx-auto py-3">
              <Row className="text-lucaDark">
                <h6
                  className="d-flex btn-lucaIcon ps-0 m-0 my-auto"
                  onMouseDown={(e) => {
                    if (tempRemark !== null) e.preventDefault()
                  }}
                  onClick={() => {
                    if (tempRemark !== null) {
                      editRemark()
                    } else handleEditRemark(permission.remark || '')
                  }}
                  aria-hidden
                >
                  {tempRemark !== null ? '送出修改' : '備註內容'}
                  <FontAwesomeIcon
                    className="btn-lucaIcon ps-2 mx-0"
                    icon={tempRemark !== null ? faCheckCircle : faEdit}
                    title="編 輯 備 註"
                  />
                </h6>
              </Row>
              <Row className="w-100 pt-2" style={{ fontSize: '0.9rem' }}>
                {tempRemark !== null ? (
                  <Form.Control
                    as="textarea"
                    placeholder=" "
                    value={tempRemark}
                    onChange={(e) => handleEditRemark(e.target.value)}
                    onBlur={handleShowWarn}
                    rows={2}
                  />
                ) : (
                  <p
                    className="App-threeEllipsis px-0"
                    title={permission.remark}
                  >
                    {!permission.remark && '- -'}
                    {permission.remark}
                  </p>
                )}
              </Row>
            </Col>
            <Col xs={3} className="px-3 ps-lg-5 ps-3 mx-auto">
              <Button
                variant="lucaWhite py-1 px-2 mb-2 fs-7"
                onClick={btnFunc}
                style={{ fontWeight: '500' }}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="fs-6 me-2" />
                {btnText}
              </Button>
              <Form>
                {['isAdmin', 'isAdsManager', 'isAnalyst', 'isFinance'].map(
                  (key) => (
                    <Form.Switch
                      key={key}
                      label={permissions[key]}
                      checked={permission[key] || permission.isAdmin}
                      disabled={permission.isAdmin && key !== 'isAdmin'}
                      onChange={() =>
                        handleUserChange(id, {
                          ...permission,
                          [key]: !permission[key],
                        })
                      }
                    />
                  )
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

UserCard.propTypes = {
  handleUserChange: PropTypes.func.isRequired,
  setting: PropTypes.shape(),
}

UserCard.defaultProps = {
  setting: {
    name: '',
    permission: {
      adsManager: false,
      insights: true,
      admin: true,
    },
  },
}

export default UserCard
