import React, { useState, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useScreenshot } from 'use-react-screenshot'
import Modal from 'react-bootstrap/Modal'
import ExcelJS from 'exceljs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import KPITools from './KPITools'
import KPITable from './KPITable'
import KPISummary from './KPISummary'
import { t, departs, platforms } from '../../services/lucaFunctions'
import PieChart from '../Insights/PieChart'

function Chart({ setting }) {
  const { title, breakdowns, labels, selectedField } = setting
  const pieRef = useRef(null)
  const [svgSize, setsvgSize] = useState(false)
  const getSize = () => {
    if (pieRef.current) {
      const style = getComputedStyle(pieRef.current)
      const height =
        pieRef.current.clientHeight -
        parseFloat(style.paddingTop) -
        parseFloat(style.paddingBottom)
      const width = pieRef.current.clientWidth
      return { width, height }
    }
    return false
  }
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const size = getSize()
      if (size.width !== svgSize.width || size.height !== svgSize.height)
        setsvgSize(size)
    })
    observer.observe(pieRef.current)
    return () => pieRef.current && observer.unobserve(pieRef.current)
  }, [])

  const datas =
    setting.datas[0].length === 0
      ? [
          [
            {
              [labels[0]]: '無',
              [breakdowns[0]]: '無',
              [selectedField]: '1',
            },
          ],
        ]
      : setting.datas

  return (
    <div className="d-flex text-lucaDark fs-5 h-100">
      <div className="d-flex flex-column w-50 h-100">
        <div className="h-90 pt-3" ref={pieRef}>
          <PieChart
            setting={{
              ...svgSize,
              hasLabels: false,
              breakdowns,
              labels,
              datas,
              showControl: false,
              selectedField,
              padding: 0,
              donutThickness: 40,
              colors: [
                '#168AAD',
                '#34A0A4',
                '#52B69A',
                '#76C893',
                '#99D98C',
                '#d9ed92',
              ],
              backgroundColor: 'transparent',
              margin: { top: 10, right: 10, bottom: 0, left: 10 },
            }}
          />
        </div>
        <p className="fs-7 ps-4 text-white">{title}</p>
      </div>
      <div className="d-flex flex-column flex-fill flex-wrap overflow-auto mt-auto">
        {datas[0].map((d, i) => {
          const total = datas[0].reduce(
            (prev, cur) =>
              parseInt(prev, 10) + parseInt(cur[selectedField], 10),
            0
          )
          return (
            <div
              className="d-flex lh-md fw-normal text-lucaDark text-start ps-3 fs-7 App-oneLineEllipsis"
              style={{ maxWidth: '100%', minHeight: '3vh' }}
              key={i}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className="my-auto m-2"
                style={{
                  fontSize: '.8rem',
                  color: [
                    '#168AAD',
                    '#34A0A4',
                    '#52B69A',
                    '#76C893',
                    '#99D98C',
                    '#d9ed92',
                  ][i],
                }}
              />
              <div className="flex-fill overflow-hidden App-oneLineEllipsis">
                {d[breakdowns[0]]}:{' '}
              </div>
              <p className="ms-auto w-20 text-end">
                {Math.round((d[selectedField] / total) * 100)}%
              </p>
              <p
                className="text-end"
                style={{
                  width: '13%',
                }}
              >{`(${d[selectedField]})`}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function KPIModal(props) {
  const { setting } = props
  const { show, size, forms, handleClose } = setting
  const initTools = {
    platform: {
      Facebook: true,
      Google: true,
      Twitter: true,
      LinkedIn: true,
      YahooDSP: true,
      YahooMA: true,
      Line: true,
      TTD: true,
      Criteo: true,
    },
    type: {
      OpenAccount: true,
      Recharge: true,
      Permission: true,
    },
    format: 'all',
    depart: departs.reduce((prev, cur) => ({ ...prev, [cur.value]: true }), {}),
    since: moment().startOf('year').toDate(),
    until: moment().toDate(),
    preset: 6,
    fileName: '',
    active: 'Facebook',
  }
  const [tools, settools] = useState(initTools)
  const handleToolChange = (tool) => {
    settools({ ...tools, ...tool })
  }
  const { platform, type, depart, since, until, fileName } = tools
  const getPlatformForm = (active) => {
    const filtered = forms.filter(
      (f) =>
        platform[f.setting.platform] &&
        f.setting.platform === active &&
        type[f.type] &&
        depart[f.content['填表人所屬單位']] &&
        moment(f.created_on).isBetween(moment(since), moment(until))
    )
    return filtered.reduce(
      (prev, cur) => ({
        ...prev,
        [cur.type]: {
          ...prev[cur.type],
          departs: prev[cur.type].departs.find(
            (f) => f.name === cur.content['填表人所屬單位']
          )
            ? prev[cur.type].departs.map((f) =>
                f.name === cur.content['填表人所屬單位']
                  ? {
                      ...f,
                      forms: f.forms.find(
                        (ff) => ff.name === cur.content['填表人']
                      )
                        ? f.forms.map((ff) =>
                            ff.name === cur.content['填表人']
                              ? { ...ff, total: ff.total + 1 }
                              : ff
                          )
                        : [
                            ...f.forms,
                            { name: cur.content['填表人'], total: 1 },
                          ],
                      total: f.total + 1,
                    }
                  : f
              )
            : [
                ...prev[cur.type].departs,
                {
                  name: cur.content['填表人所屬單位'],
                  total: 1,
                  forms: [{ name: cur.content['填表人'], total: 1 }],
                },
              ],
          forms: prev[cur.type].forms.find(
            (f) => f.name === cur.content['填表人']
          )
            ? prev[cur.type].forms.map((f) =>
                f.name === cur.content['填表人']
                  ? {
                      ...f,
                      depart: cur.content['填表人所屬單位'],
                      total: f.total + 1,
                    }
                  : f
              )
            : [
                ...prev[cur.type].forms,
                {
                  name: cur.content['填表人'],
                  depart: cur.content['填表人所屬單位'],
                  total: 1,
                },
              ],
          total: prev[cur.type].total + 1,
        },
      }),
      {
        OpenAccount: {
          departs: departs.map((d) => ({ name: d.name, forms: [], total: 0 })),
          forms: [],
          total: 0,
        },
        Recharge: {
          departs: departs.map((d) => ({ name: d.name, forms: [], total: 0 })),
          forms: [],
          total: 0,
        },
        Permission: {
          departs: departs.map((d) => ({ name: d.name, forms: [], total: 0 })),
          forms: [],
          total: 0,
        },
      }
    )
  }

  const departsForm = useMemo(
    () => getPlatformForm(tools.active),
    [forms, platform, type, depart, since, until, tools.active]
  )

  const getTypedForm = (func) => {
    const filteredForm = forms.filter(
      (f) =>
        platform[f.setting.platform] &&
        type[f.type] &&
        depart[f.content['填表人所屬單位']] &&
        moment(f.created_on).isBetween(moment(since), moment(until))
    )
    return filteredForm.reduce(
      (prev, cur) => {
        const ty = func(cur)
        if (prev[cur.type][ty]) {
          return {
            ...prev,
            [cur.type]: {
              ...prev[cur.type],
              [ty]: prev[cur.type][ty] + 1,
            },
            total: {
              ...prev.total,
              [ty]: prev.total[ty] + 1,
            },
          }
        }
        return {
          ...prev,
          [cur.type]: {
            ...prev[cur.type],
            [ty]: 1,
          },
          total: {
            ...prev.total,
            [ty]: 1,
          },
        }
      },
      {
        OpenAccount: {},
        Recharge: {},
        Permission: {},
        total: {},
        length: filteredForm.length,
      }
    )
  }
  const charts = useMemo(() => {
    const platformedForm = getTypedForm((c) => c.setting.platform)
    const departedForm = getTypedForm((c) =>
      depart[c.content['填表人所屬單位']] ? c.content['填表人所屬單位'] : '其他'
    )
    const timedForm = getTypedForm(
      (c) =>
        `${moment(c.created_on).year()}年${moment(c.created_on).month() + 1}`
    )
    const typedForm = getTypedForm((c) => c.type)
    return { departedForm, timedForm, platformedForm, typedForm }
  }, [forms, platform, type, depart, since, until, tools.active])
  const { platformedForm, timedForm, departedForm, typedForm } = charts

  // ref for takeScreen
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const refs = {
    ref1,
    ref2,
    ref3,
    ref4,
  }
  const [images, setimages] = useState([])
  const [image, takeScreenshot] = useScreenshot()
  useEffect(() => {
    if (image === null) return
    setimages([...images, image])
  }, [image])
  useEffect(() => {
    if (images.length < 4 && images.length > 0)
      takeScreenshot(refs[`ref${images.length + 1}`].current)
  }, [images])

  // download file
  const createFile = async () => {
    const workbook = new ExcelJS.Workbook()
    if (images.length > 0) {
      const summarySheet = workbook.addWorksheet(`概覽`, {
        headerFooter: {
          firstHeader: `概覽`,
        },
      })
      summarySheet.mergeCells(`B1`, `N1`)
      summarySheet.mergeCells(`B2`, `N2`)
      summarySheet.mergeCells(`B3`, `N3`)
      summarySheet.mergeCells(`B4`, `N4`)
      summarySheet.getCell(`${String.fromCharCode(65)}1`).value = '時間'
      summarySheet.getCell(`${String.fromCharCode(66)}1`).value = `${moment(
        since
      ).format('yyyy-MM-DD')} - ${moment(until).format('yyyy-MM-DD')}`
      summarySheet.getCell(`${String.fromCharCode(65)}2`).value = '平台'
      summarySheet.getCell(
        `${String.fromCharCode(66)}2`
      ).value = `${Object.keys(platform)
        .filter((p) => platform[p])
        .map((p) => t(p))
        .join()}`
      summarySheet.getCell(`${String.fromCharCode(65)}3`).value = '類型'
      summarySheet.getCell(
        `${String.fromCharCode(66)}3`
      ).value = `${Object.keys(type)
        .filter((ty) => type[ty])
        .map((ty) => t(ty))
        .join()}`
      summarySheet.getCell(`${String.fromCharCode(65)}4`).value = '部門'
      summarySheet.getCell(
        `${String.fromCharCode(66)}4`
      ).value = `${Object.keys(depart)
        .filter((d) => depart[d])
        .map((d) => d)
        .join()}`
      for (let i = 1; i < 80; i += 1) summarySheet.getRow(i).height = 40
      summarySheet.getRow(5).height = 180
      summarySheet.getRow(6).height = 180
      summarySheet.mergeCells(`A5`, `G5`)
      summarySheet.mergeCells(`A6`, `G6`)
      summarySheet.mergeCells(`H5`, `N5`)
      summarySheet.mergeCells(`H6`, `N6`)
      images.forEach((c, i) => {
        const inserted = workbook.addImage({
          base64: c,
          extension: 'png',
        })
        summarySheet.addImage(inserted, {
          tl: { row: 4 + parseInt(i / 2, 10), col: (i % 2) * 7 },
          ext: { width: 460, height: 200 },
        })
      })
      const getMonths = () => {
        const start = moment(since)
        const end = moment(until)
        const interim = start.clone()
        const timeValues = []

        while (end > interim || interim.format('M') === end.format('M')) {
          timeValues.push(interim.format('YY年MM'))
          interim.add(1, 'month')
        }
        return timeValues
      }
      const months = getMonths()
      months.forEach((m, i) => {
        summarySheet.getCell(`${String.fromCharCode(67 + i)}7`).value = m
        if (i + 1 === months.length) {
          summarySheet.getCell(`${String.fromCharCode(67 + i + 1)}7`).value =
            '總計'
        }
      })
      Object.keys({ all: true, ...tools.platform })
        .filter((p) => tools.platform[p] || p === 'all')
        .forEach((p, i) => {
          const filteredForm = forms.filter(
            (f) =>
              (f.setting.platform === p || p === 'all') &&
              depart[f.content['填表人所屬單位']] &&
              type[f.type] &&
              moment(f.created_on).isBetween(moment(since), moment(until))
          )
          const monthedForm = filteredForm.reduce(
            (prev, cur) => {
              const month = moment(cur.created_on).format('YY年MM')
              if (prev[cur.type][month]) {
                return {
                  ...prev,
                  [cur.type]: {
                    ...prev[cur.type],
                    [month]: prev[cur.type][month] + 1,
                  },
                  total: {
                    ...prev.total,
                    [month]: prev.total[month] + 1,
                  },
                }
              }
              return {
                ...prev,
                [cur.type]: {
                  ...prev[cur.type],
                  [month]: 1,
                },
                total: {
                  ...prev.total,
                  [month]: 1,
                },
              }
            },
            {
              OpenAccount: {},
              Recharge: {},
              Permission: {},
              total: {},
            }
          )
          summarySheet.mergeCells(`A${7 + (i * 4 + 1)}`, `A${7 + (i * 4 + 4)}`)
          summarySheet.getCell(`A${7 + (i * 4 + 1)}`).value = p
          summarySheet.getCell(`B${7 + (i * 4 + 1)}`).value = '開戶'
          summarySheet.getCell(`B${7 + (i * 4 + 2)}`).value = '儲值'
          summarySheet.getCell(`B${7 + (i * 4 + 3)}`).value = '權限'
          summarySheet.getCell(`B${7 + (i * 4 + 4)}`).value = '總計'
          months.forEach((m, j) => {
            summarySheet.getCell(
              `${String.fromCharCode(67 + j)}${7 + (i * 4 + 1)}`
            ).value = monthedForm.OpenAccount[m] || 0
            if (j + 1 === months.length) {
              const total = Object.entries(monthedForm.OpenAccount).reduce(
                (prev, cur) => prev + cur[1],
                0
              )
              summarySheet.getCell(
                `${String.fromCharCode(67 + j + 1)}${7 + (i * 4 + 1)}`
              ).value = total
            }
          })
          months.forEach((m, j) => {
            summarySheet.getCell(
              `${String.fromCharCode(67 + j)}${7 + (i * 4 + 2)}`
            ).value = monthedForm.Recharge[m] || 0
            if (j + 1 === months.length) {
              const total = Object.entries(monthedForm.Recharge).reduce(
                (prev, cur) => prev + cur[1],
                0
              )
              summarySheet.getCell(
                `${String.fromCharCode(67 + j + 1)}${7 + (i * 4 + 2)}`
              ).value = total
            }
          })
          months.forEach((m, j) => {
            summarySheet.getCell(
              `${String.fromCharCode(67 + j)}${7 + (i * 4 + 3)}`
            ).value = monthedForm.Permission[m] || 0
            if (j + 1 === months.length) {
              const total = Object.entries(monthedForm.Permission).reduce(
                (prev, cur) => prev + cur[1],
                0
              )
              summarySheet.getCell(
                `${String.fromCharCode(67 + j + 1)}${7 + (i * 4 + 3)}`
              ).value = total
            }
          })
          months.forEach((m, j) => {
            summarySheet.getCell(
              `${String.fromCharCode(67 + j)}${7 + (i * 4 + 4)}`
            ).value = monthedForm.total[m] || 0
            if (j + 1 === months.length) {
              const total = Object.entries(monthedForm.total).reduce(
                (prev, cur) => prev + cur[1],
                0
              )
              summarySheet.getCell(
                `${String.fromCharCode(67 + j + 1)}${7 + (i * 4 + 4)}`
              ).value = total
            }
          })
        })
    }

    const datas = platforms.reduce(
      (prev, cur) => ({
        ...prev,
        [cur.name]: getPlatformForm(cur.value),
      }),
      {}
    )
    let departsMax = 0
    let personMax = 0
    Object.keys(datas).forEach((key) => {
      const types = ['OpenAccount', 'Recharge', 'Permission']
      const sheet = workbook.addWorksheet(key, {
        headerFooter: {
          firstHeader: `${key}表單一覽`,
        },
      })
      sheet.getCell(`${String.fromCharCode(65)}1`).value = `${moment(
        since
      ).format('yyyy-MM-DD')} - ${moment(until).format('yyyy-MM-DD')}`
      sheet.getCell(`${String.fromCharCode(65)}2`).value = '篩選條件'
      sheet.getCell(`${String.fromCharCode(66)}2`).value = '平台'
      sheet.getCell(`${String.fromCharCode(67)}2`).value = `${Object.keys(
        platform
      )
        .filter((p) => platform[p])
        .map((p) => t(p))
        .join()}`
      sheet.getCell(`${String.fromCharCode(66)}3`).value = '類型'
      sheet.getCell(`${String.fromCharCode(67)}3`).value = `${Object.keys(type)
        .filter((ty) => type[ty])
        .map((ty) => t(ty))
        .join()}`
      sheet.getCell(`${String.fromCharCode(66)}4`).value = '部門'
      sheet.getCell(`${String.fromCharCode(67)}4`).value = `${Object.keys(
        depart
      )
        .filter((d) => depart[d])
        .map((d) => d)
        .join()}`
      const cells = types.filter((ty) => type[ty]).length - 1
      sheet.mergeCells(
        `${String.fromCharCode(65)}1`,
        `${String.fromCharCode(70 + cells * 4)}1`
      )
      sheet.mergeCells(
        `${String.fromCharCode(65)}2`,
        `${String.fromCharCode(65)}4`
      )
      sheet.mergeCells(
        `${String.fromCharCode(67)}2`,
        `${String.fromCharCode(70 + cells * 4)}2`
      )
      sheet.mergeCells(
        `${String.fromCharCode(67)}3`,
        `${String.fromCharCode(70 + cells * 4)}3`
      )
      sheet.mergeCells(
        `${String.fromCharCode(67)}4`,
        `${String.fromCharCode(70 + cells * 4)}4`
      )

      sheet.getCell(`${String.fromCharCode(65)}5`).value = t(key)
      types
        .filter((ty) => type[ty])
        .forEach((ty, i) => {
          sheet.getCell(`${String.fromCharCode(67 + i * 4)}5`).value = `${t(
            ty
          )}(${datas[key][ty].total})`
          sheet.mergeCells(
            `${String.fromCharCode(67 + i * 4)}5`,
            `${String.fromCharCode(70 + i * 4)}5`
          )
          let offset = 5
          datas[key][ty].departs
            .filter(({ name }) => depart[name])
            .forEach((f, j) => {
              const start = j + 1
              const end = Math.max(f.forms.length - 1, 0)
              if (f.total !== 0) {
                sheet.getCell(
                  `${String.fromCharCode(67 + i * 4)}${start + offset}`
                ).value = f.name
                sheet.getCell(
                  `${String.fromCharCode(70 + i * 4)}${start + offset}`
                ).value = f.total
                sheet.mergeCells(
                  `${String.fromCharCode(67 + i * 4)}${start + offset}`,
                  `${String.fromCharCode(67 + i * 4)}${start + end + offset}`
                )
                sheet.mergeCells(
                  `${String.fromCharCode(70 + i * 4)}${start + offset}`,
                  `${String.fromCharCode(70 + i * 4)}${start + end + offset}`
                )
                f.forms.forEach((ff, k) => {
                  sheet.getCell(
                    `${String.fromCharCode(68 + i * 4)}${start + k + offset}`
                  ).value = ff.name
                  sheet.getCell(
                    `${String.fromCharCode(69 + i * 4)}${start + k + offset}`
                  ).value = ff.total
                })
                offset += end
              } else {
                sheet.getCell(
                  `${String.fromCharCode(67 + i * 4)}${start + offset}`
                ).value = f.name
                sheet.getCell(
                  `${String.fromCharCode(68 + i * 4)}${start + offset}`
                ).value = '- -'
                sheet.getCell(
                  `${String.fromCharCode(69 + i * 4)}${start + offset}`
                ).value = '- -'
                sheet.getCell(
                  `${String.fromCharCode(70 + i * 4)}${start + offset}`
                ).value = '0'
              }
            })
          departsMax = Math.max(
            offset +
              departsForm[ty].departs.filter(({ name }) => depart[name]).length,
            departsMax
          )
          datas[key][ty].forms.forEach((f, j) => {
            const start = departsMax + 1
            sheet.getCell(
              `${String.fromCharCode(67 + i * 4)}${start + j}`
            ).value = f.name
            sheet.getCell(
              `${String.fromCharCode(68 + i * 4)}${start + j}`
            ).value = f.depart
            sheet.mergeCells(
              `${String.fromCharCode(68 + i * 4)}${start + j}`,
              `${String.fromCharCode(69 + i * 4)}${start + j}`
            )
            sheet.getCell(
              `${String.fromCharCode(70 + i * 4)}${start + j}`
            ).value = f.total
          })
          personMax = Math.max(
            departsMax + datas[key][ty].forms.length,
            personMax
          )
        })
      sheet.getCell(`${String.fromCharCode(66)}6`).value = '部門'
      sheet.getCell(`${String.fromCharCode(66)}${departsMax + 1}`).value =
        '個人'
      try {
        sheet.mergeCells(
          `${String.fromCharCode(66)}6`,
          `${String.fromCharCode(66)}${departsMax}`
        )
      } catch (e) {
        console.log(e)
      }
      try {
        sheet.mergeCells(
          `${String.fromCharCode(66)}${departsMax + 1}`,
          `${String.fromCharCode(66)}${personMax}`
        )
      } catch (e) {
        console.log(e)
      }
      try {
        sheet.mergeCells(
          `${String.fromCharCode(65)}5`,
          `${String.fromCharCode(65)}${personMax}`
        )
      } catch (e) {
        console.log(e)
      }
      for (let i = 1; i <= personMax; i += 1) {
        sheet.getRow(i).alignment = { vertical: 'middle', horizontal: 'center' }
        sheet.getRow(i).height = 22.5
      }
      sheet.getRow(1).height = 37.5
      sheet.getRow(2).height = 22.5
      sheet.getRow(3).height = 22.5
      sheet.getRow(4).height = 22.5
      sheet.getRow(5).height = 37.5
      sheet.getCell(`${String.fromCharCode(67)}2`).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      }
      sheet.getCell(`${String.fromCharCode(67)}3`).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      }
      sheet.getCell(`${String.fromCharCode(67)}4`).alignment = {
        vertical: 'middle',
        horizontal: 'left',
      }
    })
    const file = await workbook.xlsx.writeBuffer({
      base64: true,
    })
    const data = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)

    link.click()
  }
  useEffect(() => {
    if (images.length !== 4) return
    createFile()
    setimages([])
  }, [images])

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      size={size}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="text-lucaDark h5">
        <span className="fw-bold">KPI 檢核表</span>
      </Modal.Header>
      <Modal.Body className="px-5 pt-3 pb-5">
        <h3 className="my-auto text-luca fw-bolder">OVERVIEW / 概 覽</h3>
        <KPITools
          setting={{
            ...setting,
            ...tools,
            active: null,
            createSummary: () => takeScreenshot(ref1.current),
            createFile,
            handleToolChange,
          }}
        />
        <div
          className="h-100 d-flex text-lucaDark py-3 ps-4 pe-0 fs-5 text-start"
          style={{
            width: '100%',
          }}
        >
          <div className="w-50 d-flex flex-column pe-2" ref={ref1}>
            <h5 className="my-auto text-luca fw-bolder">
              各媒體平台佔比{`(${platformedForm.length})`}
            </h5>
            <div className="w-100 flex-fill">
              <Chart
                setting={{
                  title: '媒體平台各佔比',
                  breakdowns: ['platform'],
                  labels: ['platform'],
                  datas: [
                    Object.entries(platformedForm.total).map(
                      ([key, value]) => ({
                        platform: `${key}`,
                        num: value,
                      })
                    ),
                  ],
                  selectedField: 'num',
                }}
              />
            </div>
          </div>
          <div className="w-50 d-flex flex-column ps-2" ref={ref2}>
            <h5 className="my-auto text-luca fw-bolder">
              各月份佔比{`(${timedForm.length})`}
            </h5>
            <div className="w-100 flex-fill">
              <Chart
                setting={{
                  title: '月份各佔比',
                  breakdowns: ['month'],
                  labels: ['month'],
                  datas: [
                    Object.entries(timedForm.total).map(([key, value]) => ({
                      month: `${key}月`,
                      num: value,
                    })),
                  ],
                  selectedField: 'num',
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="h-100 d-flex text-lucaDark py-3 ps-4 pe-0 fs-5 text-start"
          style={{
            width: '100%',
          }}
        >
          <div className="w-50 d-flex flex-column pe-2" ref={ref3}>
            <h5 className="my-auto text-luca fw-bolder">
              各部門佔比{`(${departedForm.length})`}
            </h5>
            <div className="w-100 flex-fill">
              <Chart
                setting={{
                  title: '各部門佔比',
                  breakdowns: ['depart'],
                  labels: ['depart'],
                  datas: [
                    Object.entries(departedForm.total).map(([key, value]) => ({
                      depart: `${key}`,
                      num: value,
                    })),
                  ],
                  selectedField: 'num',
                }}
              />
            </div>
          </div>
          <div className="w-50 d-flex flex-column ps-2" ref={ref4}>
            <h5 className="my-auto text-luca fw-bolder">
              案件別佔比{`(${typedForm.length})`}
            </h5>
            <div className="w-100 flex-fill">
              <Chart
                setting={{
                  title: '案件別佔比',
                  breakdowns: ['type'],
                  labels: ['type'],
                  datas: [
                    Object.entries(typedForm.total).map(([key, value]) => ({
                      type: `${
                        {
                          OpenAccount: '開戶',
                          Permission: '權限',
                          Recharge: '儲值',
                        }[key]
                      }`,
                      num: value,
                    })),
                  ],
                  selectedField: 'num',
                }}
              />
            </div>
          </div>
        </div>
        <KPISummary
          setting={{
            ...setting,
            ...tools,
            forms,
          }}
        />
        <KPITools
          setting={{
            ...setting,
            ...tools,
            since: null,
            until: null,
            createFile,
            handleToolChange,
            hasDownload: false,
          }}
        />
        <KPITable
          setting={{
            ...setting,
            ...tools,
            forms: departsForm,
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

Chart.propTypes = {
  setting: PropTypes.shape().isRequired,
}

KPIModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default KPIModal
