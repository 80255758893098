const FacebookInsightFields = [
  { name: 'reach' },
  { name: 'impressions' },
  { name: 'frequency' },
  { name: 'spend' },
  { name: 'clicks' },
  { name: 'cpc' },
  { name: 'cpm' },
  { name: 'ctr' },
  { name: 'video_p25_watched_actions:video_view' },
  { name: 'video_p50_watched_actions:video_view' },
  { name: 'video_p75_watched_actions:video_view' },
  { name: 'video_p95_watched_actions:video_view' },
  { name: 'video_p100_watched_actions:video_view' },
  { name: 'video_avg_time_watched_actions:video_view' },
  { name: 'video_play_actions:video_view' },
  { name: 'cost_per_action_type:video_view' },
  { name: 'actions:link_click' },
  { name: 'outbound_clicks:outbound_click' },
  { name: 'cost_per_outbound_click:outbound_click' },
  { name: 'actions:add_to_cart' },
  { name: 'cost_per_action_type:add_to_cart' },
  { name: 'actions:omni_complete_registration' },
  { name: 'cost_per_action_type:omni_complete_registration' },
  { name: 'actions:offsite_conversion.fb_pixel_purchase' },
  { name: 'cost_per_action_type:offsite_conversion.fb_pixel_purchase' },
  { name: 'actions:offsite_conversion.fb_pixel_add_to_cart' },
  { name: 'purchase_roas:omni_purchase' },
  { name: 'actions:offline_conversion.purchase' },
  { name: 'website_purchase_roas:offsite_conversion.fb_pixel_purchase' },
]

const FacebookAdInsihgtsMoneyFields = new Set([
  'spend',
  'cpc',
  'cost_per_result',
  'cpp',
  'cpm',
  'cost_per_action_type:page_engagement',
  'cost_per_action_type:like',
  'cost_per_action_type:post_engagement',
  'cost_per_action_type:rsvp',
  'cost_per_action_type:onsite_conversion.messaging_first_reply',
  'cost_per_2_sec_continuous_video_view:video_view',
  'cost_per_action_type:video_view',
  'cost_per_thruplay:video_view',
  'cost_per_action_type:link_click',
  'cost_per_unique_action_type:link_click',
  'cost_per_outbound_click:outbound_click',
  'cost_per_unique_outbound_click:outbound_click',
  'cost_per_unique_click',
  'cost_per_estimated_ad_recallers',
  'cost_per_action_type:omni_view_content',
  'cost_per_unique_action_type:omni_view_content',
  'cost_per_action_type:offline_conversion.other',
  'cost_per_action_type:add_to_cart',
  'cost_per_unique_action_type:add_to_cart',
  'cost_per_action_type:add_to_wishlist',
  'cost_per_unique_action_type:add_to_wishlist',
  'cost_per_action_type:omni_activate_app',
  'cost_per_unique_action_type:omni_activate_app',
  'cost_per_conversion:find_location_total',
  'cost_per_action_type:onsite_conversion.flow_complete',
  'cost_per_action_type:omni_tutorial_completion',
  'cost_per_unique_action_type:omni_tutorial_completion',
  'cost_per_action_type:omni_complete_registration',
  'cost_per_unique_action_type:omni_complete_registration',
  'cost_per_action_type:omni_rate',
  'cost_per_unique_action_type:omni_rate',
  'actions:omni_spend_credits',
  'cost_per_action_type:omni_spend_credits',
  'action_values:omni_spend_credits',
  'unique_actions:omni_spend_credits',
  'cost_per_unique_action_type:omni_spend_credits',
  'cost_per_conversion:ad_impression_mobile_app',
  'cost_per_conversion:ad_click_mobile_app',
  'cost_per_action_type:omni_app_install',
  'cost_per_action_type:omni_achievement_unlocked',
  'cost_per_unique_action_type:omni_achievement_unlocked',
  'cost_per_conversion:donate_total',
  'cost_per_action_type:omni_search',
  'cost_per_unique_action_type:omni_search',
  'cost_per_action_type:add_payment_info',
  'cost_per_unique_action_type:add_payment_info',
  'cost_per_action_type:app_engagement',
  'cost_per_action_type:app_use',
  'cost_per_action_type:app_story',
  'cost_per_action_type:lead',
  'cost_per_action_type:games.plays',
  'cost_per_conversion:submit_application_total',
  'cost_per_action_type:omni_level_achieved',
  'cost_per_unique_action_type:omni_level_achieved',
  'cost_per_conversion:contact_total',
  'cost_per_action_type:omni_custom',
  'cost_per_conversion:customize_product_total',
  'cost_per_action_type:app_custom_event.fb_mobile_d2_retention',
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d2_retention',
  'cost_per_action_type:app_custom_event.fb_mobile_d7_retention',
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d7_retention',
  'cost_per_conversion:subscribe_total',
  'cost_per_action_type:omni_purchase',
  'cost_per_unique_action_type:omni_purchase',
  'cost_per_action_type:landing_page_view',
  'cost_per_unique_action_type:landing_page_view',
  'cost_per_action_type:omni_initiated_checkout',
  'cost_per_unique_action_type:omni_initiated_checkout',
  'cost_per_conversion:start_trial_total',
  'cost_per_conversion:schedule_total',
  'cost_per_unique_action_type:app_custom_event.fb_mobile_spent_credits',
  'unique_actions:app_custom_event.fb_mobile_spent_credits',
  'adset_budget_value',
  'adset_bid_value',
])

const arraytypes = new Set([
  'actions',
  'conversions',
  'unique_actions',
  'video_play_actions',
  'purchase_roas',
  'website_purchase_roas',
  'mobile_app_purchase_roas',
  'action_values',
  'conversion_values',
  'cost_per_action_type',
  'cost_per_outbound_click',
  'video_p25_watched_actions',
  'video_p50_watched_actions',
  'video_p75_watched_actions',
  'video_p95_watched_actions',
  'video_p100_watched_actions',
  'video_avg_time_watched_actions',
  'cost_per_action_type',
  'outbound_clicks',
  'cost_per_outbound_click',
])

const prefixs = new Set([
  'campaign_id',
  'campaign_name',
  'adset_id',
  'adset_name',
  'ad_id',
  'ad_name',
])

const getValue = (item) => {
  if (item && item.value) {
    return parseFloat(item.value)
  }
  return 0
}

const getActionValue = (item, name, actionType) => {
  if (item && item[name]) {
    return item[name].find((action) => action.action_type === actionType)
  }
  return {}
}

const roas = {
  'purchase_roas:omni_purchase': (value) =>
    getValue(getActionValue(value, 'action_values', 'omni_purchase')) /
    parseFloat(value.spend),
  'website_purchase_roas:offsite_conversion.fb_pixel_purchase': (value) =>
    getValue(
      getActionValue(
        value,
        'action_values',
        'offsite_conversion.fb_pixel_purchase'
      )
    ) / parseFloat(value.spend),
  'mobile_app_purchase_roas:app_custom_event.fb_mobile_purchase': (value) =>
    getValue(
      getActionValue(
        value,
        'action_values',
        'app_custom_event.fb_mobile_purchase'
      )
    ) / parseFloat(value.spend),
}

const recalculator = {
  cpp(value) {
    return parseFloat(value.spend) / (parseFloat(value.reach) / 1000)
  },
  cpc(value) {
    return parseFloat(value.spend) / parseFloat(value.clicks)
  },
  cpm(value) {
    return parseFloat(value.spend) / (parseFloat(value.impressions) / 1000)
  },
  ctr(value) {
    return parseFloat(value.clicks) / (parseFloat(value.impressions) / 100)
  },
  'cost_per_action_type:like': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'like')),
  'cost_per_action_type:page_engagement': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'page_engagement')),
  'cost_per_action_type:post_engagement': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'post_engagement')),
  'cost_per_action_type:video_view': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'video_view')),
  'cost_per_action_type:link_click': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'link_click')),
  'cost_per_action_type:omni_view_content': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_view_content')),
  'cost_per_action_type:offline_conversion.other': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'offline_conversion.other')),
  'cost_per_action_type:add_to_cart': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'add_to_cart')),
  'cost_per_action_type:add_to_wishlist': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'add_to_wishlist')),
  'cost_per_action_type:omni_activate_app': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_activate_app')),
  'cost_per_conversion:find_location_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'find_location_total')),
  'cost_per_action_type:onsite_conversion.flow_complete': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(value, 'actions', 'onsite_conversion.flow_complete')
    ),
  'cost_per_action_type:omni_tutorial_completion': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_tutorial_completion')),
  'cost_per_action_type:omni_complete_registration': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_complete_registration')),
  'cost_per_action_type:omni_rate': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_rate')),
  'cost_per_action_type:omni_spend_credits': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_spend_credits')),
  'cost_per_conversion:ad_impression_mobile_app': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'ad_impression_mobile_app')),
  'cost_per_conversion:ad_click_mobile_app': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'ad_click_mobile_app')),
  'cost_per_action_type:omni_app_install': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_app_install')),
  'cost_per_action_type:omni_achievement_unlocked': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_achievement_unlocked')),
  'cost_per_conversion:donate_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'donate_total')),
  'cost_per_action_type:omni_search': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_search')),
  'cost_per_action_type:add_payment_info': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'add_payment_info')),
  'cost_per_action_type:app_engagement': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'app_engagement')),
  'cost_per_action_type:app_use': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'app_use')),
  'cost_per_action_type:app_story': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'app_story')),
  'cost_per_action_type:lead': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'lead')),
  'cost_per_action_type:games.plays': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'games.plays')),
  'cost_per_conversion:submit_application_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'submit_application_total')),
  'cost_per_action_type:omni_level_achieved': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_level_achieved')),
  'cost_per_conversion:contact_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'contact_total')),
  'cost_per_action_type:omni_custom': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_custom')),
  'cost_per_conversion:customize_product_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'customize_product_total')),
  'cost_per_action_type:app_custom_event.fb_mobile_d2_retention': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(
        value,
        'actions',
        'app_custom_event.fb_mobile_d2_retention'
      )
    ),
  'cost_per_action_type:app_custom_event.fb_mobile_d7_retention': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(
        value,
        'actions',
        'app_custom_event.fb_mobile_d7_retention'
      )
    ),
  'cost_per_conversion:subscribe_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'subscribe_total')),
  'cost_per_action_type:omni_purchase': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_purchase')),
  'cost_per_action_type:landing_page_view': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'landing_page_view')),
  'cost_per_action_type:omni_initiated_checkout': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'omni_initiated_checkout')),
  'cost_per_conversion:start_trial_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'start_trial_total')),
  'cost_per_conversion:schedule_total': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'conversions', 'schedule_total')),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_spent_credits': (
    value
  ) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(
        value,
        'unique_actions',
        'app_custom_event.fb_mobile_spent_credits'
      )
    ),
  'cost_per_unique_action_type:link_click': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'link_click')),
  'cost_per_unique_action_type:omni_view_content': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_view_content')),
  'cost_per_unique_action_type:omni_purchase': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_purchase')),
  'cost_per_unique_action_type:landing_page_view': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'landing_page_view')),
  'cost_per_unique_action_type:omni_initiated_checkout': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(value, 'unique_actions', 'omni_initiated_checkout')
    ),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d7_retention': (
    value
  ) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(
        value,
        'unique_actions',
        'app_custom_event.fb_mobile_d7_retention'
      )
    ),
  'cost_per_unique_action_type:app_custom_event.fb_mobile_d2_retention': (
    value
  ) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(
        value,
        'unique_actions',
        'app_custom_event.fb_mobile_d2_retention'
      )
    ),
  'cost_per_unique_action_type:omni_level_achieved': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_level_achieved')),
  'cost_per_unique_action_type:add_payment_info': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'add_payment_info')),
  'cost_per_unique_action_type:omni_search': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_search')),
  'cost_per_unique_action_type:omni_achievement_unlocked': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(value, 'unique_actions', 'omni_achievement_unlocked')
    ),
  'cost_per_unique_action_type:omni_spend_credits': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_spend_credits')),
  'cost_per_unique_action_type:omni_rate': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_rate')),
  'cost_per_unique_action_type:omni_complete_registration': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(value, 'unique_actions', 'omni_complete_registration')
    ),
  'cost_per_unique_action_type:omni_tutorial_completion': (value) =>
    parseFloat(value.spend) /
    getValue(
      getActionValue(value, 'unique_actions', 'omni_tutorial_completion')
    ),
  'cost_per_unique_action_type:omni_activate_app': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'omni_activate_app')),
  'cost_per_unique_action_type:add_to_wishlist': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'add_to_wishlist')),
  'cost_per_unique_action_type:add_to_cart': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'add_to_cart')),
  'cost_per_2_sec_continuous_video_view:video_view': (value) =>
    parseFloat(value.spend) /
    getValue(value.unique_video_continuous_2_sec_watched_actions),
  'cost_per_thruplay:video_view': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'actions', 'video_view')),
  'cost_per_outbound_click:outbound_click': (value) =>
    parseFloat(value.spend) / parseFloat(value.outbound_click),
  'cost_per_unique_outbound_click:outbound_click': (value) =>
    parseFloat(value.spend) /
    getValue(getActionValue(value, 'unique_actions', 'outbound_click')),
  cost_per_unique_click(value) {
    return (
      parseFloat(value.spend) /
      getValue(getActionValue(value, 'unique_actions', 'clicks'))
    )
  },
  cost_per_estimated_ad_recallers(value) {
    return parseFloat(value.spend) / parseFloat(value.estimated_ad_recallers)
  },
  'purchase_roas:omni_purchase': (value) =>
    getValue(getActionValue(value, 'action_values', 'omni_purchase')) /
    parseFloat(value.spend),
  'website_purchase_roas:offsite_conversion.fb_pixel_purchase': (value) =>
    getValue(
      getActionValue(
        value,
        'action_values',
        'offsite_conversion.fb_pixel_purchase'
      )
    ) / parseFloat(value.spend),
  'mobile_app_purchase_roas:app_custom_event.fb_mobile_purchase': (value) =>
    getValue(
      getActionValue(
        value,
        'action_values',
        'app_custom_event.fb_mobile_purchase'
      )
    ) / parseFloat(value.spend),
}

const dependency = {
  cpp() {
    return ['reach']
  },
  cpc() {
    return ['clicks']
  },
  cpm() {
    return ['impressions']
  },
  ctr() {
    return ['clicks', 'impressions']
  },
}

module.exports = {
  FacebookAdInsihgtsMoneyFields,
  FacebookInsightFields,
  roas,
  recalculator,
  dependency,
  arraytypes,
  prefixs,
}
