import React from 'react'
import PropTypes from 'prop-types'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faEdit,
  faCopy,
  faChartBar,
  faStar,
  faPlay,
  faPause,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { toolbarAll } from '../assets'

function ToolBar(props) {
  const { setting } = props
  const { leftTools, rightTools, search, afterTools } = setting
  return (
    <ButtonToolbar
      aria-label="Toolbar with button groups"
      className="p-2 px-3 AdsToolbar"
      style={{
        backgroundColor: 'rgb(255, 255, 255, 0.3) ',
        borderRadius: '.25rem',
      }}
    >
      <ButtonGroup className="me-auto">
        {leftTools.map((tool) => (
          <Button key={tool.id} onClick={tool.method}>
            {tool.icon}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup className="ms-auto">
        {rightTools.map((tool) => (
          <Button key={tool.id} onClick={tool.method}>
            {tool.icon}
          </Button>
        ))}
      </ButtonGroup>
      {search}
      <ButtonGroup className="ms-1" size="sm">
        {afterTools.map((tool) => (
          <div
            className="d-flex AdsShowingMode"
            key={tool.id}
            onClick={tool.method}
            aria-hidden
          >
            {tool.icon}
          </div>
        ))}
      </ButtonGroup>
    </ButtonToolbar>
  )
}

ToolBar.propTypes = {
  setting: PropTypes.shape(),
}

ToolBar.defaultProps = {
  setting: {
    leftTools: [
      {
        id: 'create',
        type: 'button',
        icon: <FontAwesomeIcon title="新 建" icon={faPlus} />,
        method: () => {},
      },
      {
        id: 'edit',
        type: 'button',
        icon: <FontAwesomeIcon title="編 輯" icon={faEdit} />,
        method: () => {},
      },
      {
        id: 'duplicate',
        type: 'button',
        icon: <FontAwesomeIcon title="複 製" icon={faCopy} />,
        method: () => {},
      },
      {
        id: 'all',
        type: 'button',
        icon: <Image className="selecAll" title="全 選" src={toolbarAll} />,
        method: () => {},
      },
    ],
    rightTools: [
      {
        id: 'data',
        type: 'button',
        icon: <FontAwesomeIcon title="數 據 模 式" icon={faChartBar} />,
        method: () => {},
      },
      {
        id: 'tag',
        type: 'button',
        icon: <FontAwesomeIcon title="標 記" icon={faStar} />,
        method: () => {},
      },
      {
        id: 'start',
        type: 'button',
        icon: <FontAwesomeIcon title="開 始 投 放" icon={faPlay} />,
        method: () => {},
      },
      {
        id: 'pause',
        type: 'button',
        icon: <FontAwesomeIcon title="暫 停 投 放" icon={faPause} />,
        method: () => {},
      },
      {
        id: 'delete',
        type: 'button',
        icon: <FontAwesomeIcon title="刪 除" icon={faTrashAlt} />,
        method: () => {},
      },
    ],
  },
}

export default ToolBar
