import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartBar,
  faCircleExclamation,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons'
import {
  SelectBar,
  SearchBar,
  Datatable,
  AdsManager,
  ConfirmForm,
  AccountImport,
  MediaCost,
  MediaRecords,
} from '../components'
import apiService from '../services/apiService'
import { wordSearch } from '../services/lucaFunctions'

function Accounts(props) {
  const { auth, type } = props
  const navigate = useNavigate()

  const [showCost, setshowCost] = useState(false)
  const [accounts, setaccounts] = useState([])
  useEffect(() => {
    if (auth.auth.user_id !== undefined) {
      const getData = async () => {
        const data =
          auth.account.type === 'BM'
            ? await apiService.data({
                path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
                method: 'get',
              })
            : await apiService.data({
                path: `luca/accountUser/${auth.auth.user_id}`,
                method: 'get',
              })
        setaccounts(data)
      }
      getData()
    }
  }, [auth])

  const [filteredAccounts, setfilteredAccounts] = useState([])

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    platform: '',
    sort: JSON.stringify({ field: 'name', order: 'desc' }),
    search: '',
  })
  const { platform, sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })
  useEffect(() => {
    setfilteredAccounts(
      accounts
        ? accounts
            .filter(
              (account) =>
                wordSearch(account.name, search) &&
                (platform === '' || account.provider === platform)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : []
    )
  }, [platform, sort, search, accounts])

  const [account, setaccount] = useState({ account_id: '' })

  const [showImport, setshowImport] = useState(false)
  const [provided, setprovided] = useState(false)
  const [selected, setselected] = useState([])

  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      if (showWarn.target === 'account') {
        const sync = await apiService.data({
          path: `luca/insight/sync/user/${auth.account.business_id}/${auth.auth.user_id}`,
          method: 'post',
          data: provided.filter((a) => selected.includes(a.account_id)),
        })
        if (!sync.error) {
          setaccounts(
            accounts.concat(
              sync.map((s) => ({ ...s, provider: s.setting.provider }))
            )
          )
        }
        setselected([])
      }
    } else if (showWarn.target === 'account') {
      setshowImport(true)
    }
  }

  const handleImport = async () => {
    if (provided) {
      setshowImport(true)
      return
    }
    const providers = await apiService.data({
      path: `luca/insight/list/user/${auth.auth.user_id}`,
      method: 'get',
    })
    if (providers.length === 0) {
      handleShowWarn(
        {
          title: ' ',
          img: faCircleExclamation,
          closeButton: true,
          text: (
            <h5 className=" lh-md text-lucaDark">尚未綁定任何媒體平台帳號</h5>
          ),
          warn: <h5 className="lh-md text-lucaDark">請前往會員中心綁定</h5>,
          footer: (
            <>
              <Button variant="secondary" onClick={handleWarnClose}>
                取 消
              </Button>
              <Button
                variant="luca"
                onClick={() => {
                  navigate('/Info?action=edit')
                }}
              >
                前 往
              </Button>
            </>
          ),
        },
        'import'
      )
    } else {
      setshowImport(true)
      setprovided(providers.accounts)
    }
  }
  const handleImportClose = () => setshowImport(false)

  const [showRecords, setshowRecords] = useState(false)

  return (
    <Container fluid className="card px-2 d-flex flex-column">
      {account.account_id === '' && (
        <>
          <Row className="p-4 pageTitle">
            {type === 'AdsManager' ? (
              <>
                <h4 className="pt-4 pb-1">
                  廣 告 投 放 與 優 化
                  <span style={{ color: '#b42936' }}>&ensp;βeta</span>
                </h4>
                <p>Advertising and optimization management</p>
              </>
            ) : (
              <>
                <h4 className="pt-4 pb-1">
                  廣 告 成 效
                  <span style={{ color: '#b42936' }}>&ensp;βeta</span>
                </h4>
                <p>Advertising and insight management</p>
              </>
            )}
            {account.account_id === '' && (
              <p className="pageSubtitle pt-4">
                使 用 搜 尋 過 濾 工 具 能 更 快 速 的 找 到 您 的 廣 告 帳 戶
              </p>
            )}
          </Row>
          <Row className="pt-3 pb-0 px-4">
            <Col xs={2}>
              <SelectBar
                setting={{
                  method: handleFilterChange,
                  name: 'platform',
                  value: platform,
                  placeholder: '選擇媒體平台',
                  content: [
                    { name: 'Facebook', value: 'facebook' },
                    { name: 'Google', value: 'google' },
                    { name: 'Yahoo', value: 'yahoo' },
                    { name: 'Twitter', value: 'twitter' },
                  ],
                }}
              />
            </Col>
            <Col xs={2} className="ps-0">
              <SelectBar
                setting={{
                  method: (e) =>
                    handleFilterChange({
                      target: {
                        name: 'sort',
                        value:
                          e.target.value ||
                          JSON.stringify({ field: 'name', order: 'desc' }),
                      },
                    }),
                  name: 'sort',
                  value: sort,
                  placeholder: '選擇排序',
                  content: [
                    {
                      name: '排序 － 由最新至最舊',
                      value: JSON.stringify({
                        field: 'created_on',
                        order: 'desc',
                      }),
                    },
                    {
                      name: '排序 － 由最舊至最新',
                      value: JSON.stringify({
                        field: 'created_on',
                        order: 'aesc',
                      }),
                    },
                  ],
                }}
              />
            </Col>
            <Col className="ps-0">
              <SearchBar
                setting={{
                  title: '請輸入搜尋關鍵字...',
                  name: 'search',
                  // action: <FontAwesomeIcon icon={faSearch} />,
                  tempSearch,
                  settempSearch,
                  method: () =>
                    handleFilterChange({
                      target: {
                        name: 'search',
                        value: tempSearch,
                      },
                    }),
                }}
              />
            </Col>
            {[1, 2001, 2002, 2003, 2004, 2005].includes(
              auth.account.business_id
            ) && (
              <Col xs="2" className="ps-0">
                <Button
                  className="w-100"
                  variant="luca"
                  title="媒體消耗量"
                  onClick={() => setshowCost(true)}
                >
                  媒體消耗量&ensp;
                  <FontAwesomeIcon className="m-auto" icon={faChartBar} />
                </Button>
              </Col>
            )}
            {[1, 2001, 2002, 2003, 2004, 2005].includes(
              auth.account.business_id
            ) && (
              <Col xs="2" className="ps-0">
                <Button
                  className="w-100"
                  variant="luca"
                  title="活動紀錄"
                  onClick={() => setshowRecords(true)}
                >
                  活動紀錄&ensp;
                  <FontAwesomeIcon className="m-auto" icon={faChartBar} />
                </Button>
              </Col>
            )}
            <Col xs="2" className="ps-0">
              <Button
                className="w-100"
                variant="luca"
                title="匯入帳號"
                onClick={handleImport}
              >
                匯入帳號&ensp;
                <FontAwesomeIcon className="m-auto" icon={faFileImport} />
              </Button>
            </Col>
          </Row>
          <Row className="px-4 pt-3 pb-2 h-100 rounded-lg">
            <Datatable
              setting={{
                pageSize: 5,
                hasPagination: true,
                headers: ['類 型', '帳 戶 列 表', '創 建 日 期'],
                content: filteredAccounts
                  .concat(
                    Array(
                      filteredAccounts.length % 5 > 0
                        ? 5 - (filteredAccounts.length % 5)
                        : 0
                    ).fill({
                      fill: true,
                    })
                  )
                  .map((a) =>
                    a.fill
                      ? {
                          type: { value: '- -' },
                          name: { value: '- -' },
                          date: { value: '- -' },
                        }
                      : {
                          type: { value: a.provider },
                          name: {
                            value: a.name,
                            method: () => setaccount(a),
                          },
                          date: {
                            value: moment(a.created_on).format('YYYY-MM-DD'),
                          },
                        }
                  ),
              }}
            />
          </Row>
        </>
      )}
      {account.account_id !== '' && (
        <AdsManager
          setting={{
            auth,
            account,
            setaccount,
            hasToolBar: type === 'AdsManager',
          }}
        />
      )}
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="my-4">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
      <AccountImport
        setting={{
          size: provided ? 'xl' : 'md',
          provided: provided || [],
          prepared: provided,
          show: showImport,
          handleClose: handleImportClose,
          all: accounts,
          selected,
          setselected,
          handleImport: () => {
            const filteredProvided = provided.filter((p) =>
              selected.includes(p.account_id)
            )
            setshowImport(false)
            handleShowWarn(
              {
                title: '匯 入 帳 號',
                titleEng: 'Import advertise account',
                img: faFileImport,
                text: (
                  <h5 className="mb-3 text-lucaDark">
                    確定要匯入以下廣告帳號嗎？
                  </h5>
                ),
                warn: (
                  <>
                    {filteredProvided.slice(0, 10).map((p) => (
                      <p key={p.account_id} className="text-luca">
                        {p.name}
                      </p>
                    ))}
                    {filteredProvided.length > 10 && (
                      <p className="text-luca">
                        ...and {filteredProvided.length - 10} accounts
                      </p>
                    )}
                  </>
                ),
                shouldReturn: true,
              },
              'account'
            )
          },
        }}
      />
      {[1, 2001, 2002, 2003, 2004, 2005].includes(auth.account.business_id) && (
        <MediaCost
          setting={{
            auth,
            show: showCost,
            handleClose: () => setshowCost(false),
          }}
        />
      )}
      {[1, 2001, 2002, 2003, 2004, 2005].includes(auth.account.business_id) && (
        <MediaRecords
          setting={{
            show: showRecords,
            setshowRecords,
            handleClose: () => setshowRecords(false),
          }}
        />
      )}
    </Container>
  )
}

Accounts.propTypes = {
  auth: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
}

export default Accounts
