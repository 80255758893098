import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import SelectBar from '../SelectBar'

function AdsCopyDialog(props) {
  const { show, setting } = props
  const {
    handleCopyClose,
    selectSet,
    handleSetChange,
    activetab,
    checkedList,
    datas,
  } = setting
  const names = {
    campaign: '廣告活動',
    adset: '廣告組合',
    ad: '廣告',
  }

  const checked = checkedList.map(
    (c) => datas[activetab].find((i) => i.id === c).name
  )

  return (
    <Modal
      size="md"
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleCopyClose(false)}
    >
      <Modal.Header
        className="p-3 text-lucaDark h5"
        closeButton
      >{`複製${names[activetab]}`}</Modal.Header>
      <Modal.Body className="px-4">
        <div className="mb-3">
          <Form.Label className="mb-0 h6 text-lucaDark">
            複製以下{names[activetab]}
          </Form.Label>
          {checked.slice(0, 10).map((c, i) => (
            <p key={i} className="text-luca">
              {c}
            </p>
          ))}
          {checked.length > 10 && (
            <p className="text-luca">
              ...and {checked.length - 10} {names[activetab]}
            </p>
          )}
        </div>
        {activetab !== 'campaign' && (
          <div className="borderSelect">
            <Form.Label className="mb-0 h6 text-lucaDark">{`選擇${
              activetab === 'adset' ? '廣告活動' : '廣告組合'
            }`}</Form.Label>
            <SelectBar
              setting={{
                method: handleSetChange,
                placeholder: `原${
                  activetab === 'adset' ? '廣告活動' : '廣告組合'
                }`,
                name: activetab === 'adset' ? 'campaignId' : 'adsetId',
                value:
                  activetab === 'adset'
                    ? selectSet.campaignId
                    : selectSet.adsetId,
                content:
                  activetab === 'adset'
                    ? datas.campaign.map((campaign) => ({
                        name: campaign.name,
                        value: campaign.id,
                      }))
                    : datas.adset.map((adset) => ({
                        name: adset.name,
                        value: adset.id,
                      })),
              }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => handleCopyClose(false)} variant="secondary">
          取 消
        </Button>
        <Button onClick={() => handleCopyClose(checked)} variant="luca">
          確 定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AdsCopyDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

export default AdsCopyDialog
