const FacebookInsightFields = [
  { name: 'reach' },
  { name: 'impressions' },
  { name: 'frequency' },
  { name: 'spend' },
  { name: 'clicks' },
  { name: 'cpc' },
  { name: 'cpm' },
  { name: 'ctr' },
  { name: 'video_p25_watched_actions:video_view' },
  { name: 'video_p50_watched_actions:video_view' },
  { name: 'video_p75_watched_actions:video_view' },
  { name: 'video_p95_watched_actions:video_view' },
  { name: 'video_p100_watched_actions:video_view' },
  { name: 'video_avg_time_watched_actions:video_view' },
  { name: 'video_play_actions:video_view' },
  { name: 'cost_per_action_type:video_view' },
  { name: 'actions:link_click' },
  { name: 'outbound_clicks:outbound_click' },
  { name: 'cost_per_outbound_click:outbound_click' },
  { name: 'actions:add_to_cart' },
  { name: 'cost_per_action_type:add_to_cart' },
  { name: 'actions:omni_complete_registration' },
  { name: 'cost_per_action_type:offsite_conversion.fb_pixel_purchase' },
  { name: 'cost_per_action_type:omni_complete_registration' },
  { name: 'actions:offsite_conversion.fb_pixel_purchase' },
  { name: 'actions:offsite_conversion.fb_pixel_add_to_cart' },
  { name: 'purchase_roas:omni_purchase' },
  { name: 'actions:offline_conversion.purchase' },
  { name: 'website_purchase_roas:offsite_conversion.fb_pixel_purchase' },
]

export default FacebookInsightFields
