import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown } from '@fortawesome/free-regular-svg-icons'
import LucaDatePicker from '../LucaDatePicker'
import {
  t,
  platforms,
  departs,
  getTimeRanges,
} from '../../services/lucaFunctions'

function KPITools(props) {
  const { setting } = props
  const {
    platform,
    type,
    depart,
    since,
    until,
    preset,
    createSummary,
    createFile,
    handleToolChange,
    active,
    hasDownload = true,
  } = setting

  return (
    <div>
      <Row
        className="d-flex"
        style={{
          height: '4rem',
        }}
      >
        <Col className="d-flex flex-shrink-1 px-1 h-100">
          <DropdownButton
            className="w-100 ms-auto DropdownFullWidth text-lucaLink my-auto border rounded"
            style={{
              width: 'auto',
            }}
            align="start"
            title="所有媒體&ensp;"
            id="dropmenu"
            variant="light"
            size="sm"
          >
            <div
              className="overflow-auto scrollbarShow selectGroup"
              style={{ height: '35vh' }}
            >
              {platforms.map((option) => (
                <ListGroupItem
                  key={option.value}
                  className="py-1 my-auto App-textSmall"
                  style={{
                    height: '29px',
                    color: '#21515a',
                  }}
                  title={t(option.name)}
                  onClick={() =>
                    handleToolChange({
                      platform: {
                        ...platform,
                        [option.value]: !platform[option.value],
                      },
                    })
                  }
                  active={platform[option.value]}
                >
                  {t(option.name)}
                </ListGroupItem>
              ))}
            </div>
          </DropdownButton>
        </Col>
        <Col className="d-flex flex-shrink-1 px-1 h-100">
          <DropdownButton
            className="w-100 ms-auto DropdownFullWidth text-lucaLink my-auto border rounded"
            style={{
              width: 'auto',
            }}
            align="start"
            title="所有類型&ensp;"
            id="dropmenu"
            variant="light"
            size="sm"
          >
            <div
              className="overflow-auto scrollbarShow selectGroup"
              style={{ height: '35vh' }}
            >
              {[
                { name: t('OpenAccount'), value: 'OpenAccount' },
                { name: t('Recharge'), value: 'Recharge' },
                { name: t('Permission'), value: 'Permission' },
              ].map((option) => (
                <ListGroupItem
                  className="py-1 my-auto App-textSmall"
                  style={{
                    height: '29px',
                    color: '#21515a',
                  }}
                  key={option.value}
                  title={t(option.name)}
                  onClick={() =>
                    handleToolChange({
                      type: { ...type, [option.value]: !type[option.value] },
                    })
                  }
                  active={type[option.value]}
                >
                  {t(option.name)}
                </ListGroupItem>
              ))}
            </div>
          </DropdownButton>
        </Col>
        <Col className="d-flex flex-shrink-1 px-1 h-100">
          <DropdownButton
            className="w-100 ms-auto DropdownFullWidth text-lucaLink my-auto border rounded"
            style={{
              width: 'auto',
            }}
            align="start"
            title="所有部門&ensp;"
            id="dropmenu"
            variant="light"
            size="sm"
          >
            <div
              className="overflow-auto scrollbarShow selectGroup"
              style={{ height: '35vh' }}
            >
              {departs
                .concat({
                  name: '其他',
                  value: '',
                })
                .map((option) => (
                  <ListGroupItem
                    className="py-1 my-auto App-textSmall"
                    style={{
                      height: '29px',
                      color: '#21515a',
                    }}
                    key={option.value}
                    title={t(option.name)}
                    onClick={() =>
                      handleToolChange({
                        depart: {
                          ...depart,
                          [option.value]: !depart[option.value],
                        },
                      })
                    }
                    active={depart[option.value]}
                  >
                    {t(option.name)}
                  </ListGroupItem>
                ))}
            </div>
          </DropdownButton>
        </Col>
        {since && until && (
          <>
            <Col xs={5} className="ps-4 py-3 ms-auto datePicker_kpi h-100">
              <LucaDatePicker
                setting={{
                  since: moment(since).toDate(),
                  until: moment(until).toDate(),
                  setSince: (date) => {
                    handleToolChange({ since: date, preset: -1 })
                  },
                  setUntil: (date) =>
                    handleToolChange({ until: date, preset: -1 }),
                }}
              />
            </Col>
            <Col className="d-flex my-auto pe-2 ps-0 h-100">
              <DropdownButton
                className="DropdownFullWidth w-100 my-auto border rounded"
                align="start"
                title={t(
                  getTimeRanges()[preset]
                    ? getTimeRanges()[preset].label
                    : '- -'
                )}
                id="dropmenu"
                variant="light"
                size="sm"
              >
                {getTimeRanges().map((tr, i) => (
                  <Dropdown.Item
                    key={i}
                    size="sm"
                    className="py-0"
                    onClick={() =>
                      handleToolChange({ ...tr.timeRange, preset: i })
                    }
                  >
                    {t(tr.label)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </>
        )}
        {hasDownload && (
          <Col xs={1} className="d-flex px-0 h-100">
            <DropdownButton
              className="DropdownFullWidth w-100 my-auto border rounded"
              align="start"
              title={
                <>
                  <FontAwesomeIcon icon={faCircleDown} />
                  &ensp;下 載
                </>
              }
              id="dropmenu"
              variant="lucaMid"
              size="sm"
            >
              <Dropdown.Item
                size="sm"
                className="py-0"
                onClick={() => createSummary()}
              >
                概覽
              </Dropdown.Item>
              <Dropdown.Item
                size="sm"
                className="py-0"
                onClick={() => createFile()}
              >
                僅媒體資料
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        )}
      </Row>
      {active && (
        <Row
          style={{
            height: '3rem',
          }}
        >
          <Col className="overflow-hidden px-0">
            <Nav
              variant="pills"
              className="w-100 d-flex flex-nowrap flex-row w-100 h-100 overflow-hidden"
            >
              {platforms
                .filter(({ name }) => platform[name])
                .map(({ name }, i) => (
                  <Nav.Item
                    key={i}
                    style={{
                      width: '11%',
                    }}
                    className={`flex-shrink-1 overflow-hidden text-nowrap h-75 mt-auto
                ${name === active ? 'selectedReportTabs_kpi' : 'reportTabs_kpi'}
                  `}
                  >
                    <Nav.Link
                      className="App-oneLineEllipsis px-2 h-100 fs-7"
                      title={i.reportName}
                      onClick={() => handleToolChange({ active: name })}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </Col>
        </Row>
      )}
    </div>
  )
}

KPITools.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default KPITools
