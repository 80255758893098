const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

export default {
  validate(form, formData) {
    if (!form) return []
    if (!formData) return []
    return Object.keys(formData).map((key) => {
      const config = form.find((f) => f.name === key)
      if (!config) return { key }
      if (config.type === 'fixed') return { key }
      if (formData[key] === undefined || formData[key] === null)
        return { key, error: ' 缺 少 欄 位 ' }
      if (formData[key] === '') {
        if (
          config.dependency &&
          formData[config.dependency.name] === config.dependency.value &&
          config.required
        )
          return { key, error: ' 必 填 欄 位 ' }
        if (!config.dependency && config.required)
          return { key, error: ' 必 填 欄 位 ' }
        return { key }
      }
      if (config.type === 'email') {
        if (validateEmail(formData[key])) return { key }
        return {
          key,
          error: ' 請 填 入 有 效 email ',
        }
      }
      if (config.constraint) {
        if (config.constraint.method(formData[key], formData)) return { key }
        return {
          key,
          error: config.constraint.text,
        }
      }
      return { key }
    })
  },
}
