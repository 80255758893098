import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt,
  faCirclePlus,
  faTimes,
  faCheck,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import apiService from '../services/apiService'

function ClockInput({ setting }) {
  const { onChange } = setting
  const value = `${setting.value}${'.'.repeat(
    Math.max(0, 3 - setting.value.split('.').length)
  )}`

  return (
    <div className="d-flex w-100 px-1">
      <Form.Control
        size="sm"
        className="rounded-0 rounded-start border-end-0"
        style={{ width: '32%' }}
        placeholder="版本號"
        value={value.split('.')[0]}
        onClick={(e) => {
          if (e && e.stopPropagation) e.stopPropagation()
        }}
        type="number"
        onChange={(e) =>
          onChange(
            `${e.target.value}.${value.split('.')[1]}.${value.split('.')[2]}`
          )
        }
      />
      <p
        className="border-top border-bottom"
        style={{ backgroundColor: 'white', borderColor: '#ced4da' }}
        onClick={(e) => {
          if (e && e.stopPropagation) e.stopPropagation()
        }}
        aria-hidden
      >
        .
      </p>
      <Form.Control
        size="sm"
        className="rounded-0 border-start-0 border-end-0"
        style={{ width: '32%' }}
        placeholder="版本號"
        value={value.split('.')[1]}
        onClick={(e) => {
          if (e && e.stopPropagation) e.stopPropagation()
        }}
        type="number"
        onChange={(e) =>
          onChange(
            `${value.split('.')[0]}.${e.target.value}.${value.split('.')[2]}`
          )
        }
      />
      <p
        className="border-top border-bottom"
        style={{ backgroundColor: 'white', borderColor: '#ced4da' }}
        onClick={(e) => {
          if (e && e.stopPropagation) e.stopPropagation()
        }}
        aria-hidden
      >
        .
      </p>
      <Form.Control
        size="sm"
        className="rounded-0 rounded-end border-start-0"
        style={{ width: '32%' }}
        placeholder="版本號"
        value={value.split('.')[2]}
        onClick={(e) => {
          if (e && e.stopPropagation) e.stopPropagation()
        }}
        type="number"
        onChange={(e) =>
          onChange(
            `${value.split('.')[0]}.${value.split('.')[1]}.${e.target.value}`
          )
        }
      />
    </div>
  )
}

function EditorComponent(props) {
  const { setting } = props
  const { editorState, seteditorState } = setting

  const toolOption = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      // 'embedded',
      // 'emoji',
      'image',
      // 'remove',
      // 'history',
    ],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: [
        'bold',
        'italic',
        'underline',
        // 'strikethrough',
        // 'monospace',
        // 'superscript',
        // 'subscript',
      ],
      // bold: { icon: bold, className: undefined },
      // italic: { icon: italic, className: undefined },
      // underline: { icon: underline, className: undefined },
      // strikethrough: { icon: strikethrough, className: undefined },
      // monospace: { icon: monospace, className: undefined },
      // superscript: { icon: superscript, className: undefined },
      // subscript: { icon: subscript, className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: [
        'Normal',
        'H1',
        'H2',
        'H3',
        'H4',
        'H5',
        'H6',
        'Blockquote',
        'Code',
      ],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered'],
    },
    image: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: async (file) => {
        const buffered = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            resolve({ name: file.name, result: reader.result })
          })
          reader.readAsArrayBuffer(file)
        })
        const arrayed = {
          name: buffered.name,
          data: Array.from(new Uint8Array(buffered.result)),
        }

        const res = await apiService.data({
          path: `file`,
          method: 'post',
          data: { files: JSON.stringify([arrayed]) },
        })
        return new Promise((resolve) => {
          resolve({ data: { link: `/api/file/${res[0].name}` } })
        })
      },
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
  }
  return (
    <Editor
      toolbar={toolOption}
      editorState={editorState}
      onEditorStateChange={(e) => seteditorState(e)}
    />
  )
}

function Changelog(props) {
  const { setting } = props
  const {
    // size,
    show,
    handleClose,
    handleLogsChange,
    isAdmin,
    logs = [
      {
        version: '4.2.0',
        title: '版本更新',
        content:
          'LUCA v4.0.2 版本更新內容說明: 修正部分bug、潮金流新增多層次下拉式選單，可多層次看見各ADM和各媒體之預算花費。',
      },
      {
        version: '4.0.0',
        title: '版本更新',
        content:
          'LUCA v4.0.0 版本更新內容說明:  預算與花費總覽頁 新增預算分配比例圖餅圖（含:各媒體花費佔比、自動擷取花費排名前五之廣告花費佔比）、新增報表訂閱至通知功能、報表產生完成通知及內容',
      },
      {
        version: '3.7.1',
        title: '版本更新',
        content: '內文',
      },
      {
        version: '3.6.0',
        title: '版本更新',
        content: '內文',
      },
    ],
  } = setting

  const [action, setaction] = useState('reading')
  const [activeKey, setactiveKey] = useState(
    logs[0] ? logs[0].setting.version : 0
  )
  const [editorState, seteditorState] = useState(EditorState.createEmpty())
  const initForm = {
    version: '0.0.0',
    title: '',
    content: '',
  }
  const [form, setform] = useState(initForm)
  const getEsFromHtml = (html) => {
    const blocks = convertFromHTML(html)
    seteditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocks.contentBlocks,
          blocks.entityMap
        )
      )
    )
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="lg"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title className="d-flex w-100">
          <h5>版本更新資訊</h5>
        </Modal.Title>
      </Modal.Header>
      {action === 'delete' ? (
        <Modal.Body className="d-flex AccformCard">
          <div className="assPermis w-100 d-flex flex-column">
            <Form className="px-2 Form-card flex-grow-1">
              <Form.Group className="px-5 lh-md text-center">
                <Form.Label className="w-100">刪除更新公告</Form.Label>
                確定要刪除版本
                {logs[activeKey] ? logs[activeKey].setting.version : ''}
                嗎？
              </Form.Group>
            </Form>
            <div className="d-flex mx-auto mt-5">
              <Button className="me-3" variant="secondary" onClick={() => {}}>
                取 消
              </Button>
              <Button variant="luca" onClick={() => {}}>
                確 定
              </Button>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="AccformCard py-4">
          {isAdmin && (
            <Row className="d-flex border w-100 py-2 mx-auto btn-lucaLighter2 rounded">
              <Col
                xs={12}
                className="h7 align-self-center text-center"
                onClick={() => {
                  setactiveKey('none')
                  setaction('creating')
                  setform(initForm)
                }}
              >
                建立新公告&emsp;
                <FontAwesomeIcon
                  className="align-self-center"
                  icon={faCirclePlus}
                  title="新 增"
                />
              </Col>
            </Row>
          )}

          {action === 'creating' && (
            <Accordion.Item>
              <Accordion.Header className="d-flex border ">
                <Form.Control
                  size="sm"
                  placeholder="標題"
                  value={form.title}
                  onClick={(e) => {
                    if (e && e.stopPropagation) e.stopPropagation()
                  }}
                  onChange={(e) => setform({ ...form, title: e.target.value })}
                />
                <ClockInput
                  setting={{
                    value: form.version,
                    onChange: (value) => setform({ ...form, version: value }),
                  }}
                />
                <FontAwesomeIcon
                  onClick={(e) => {
                    if (e && e.stopPropagation) e.stopPropagation()
                    setaction('reading')
                  }}
                  className="ms-auto my-auto align-self-center btn-lucaIcon3"
                  icon={faTimes}
                  title="取 消"
                />
                <FontAwesomeIcon
                  onClick={(e) => {
                    if (e && e.stopPropagation) e.stopPropagation()
                    handleLogsChange({
                      value: {
                        ...form,
                        html: draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        ),
                      },
                      action: 'post',
                    })
                    setaction('reading')
                  }}
                  className="ms-2 my-auto align-self-center btn-lucaIcon3"
                  icon={faCheck}
                  title="確 定"
                />
              </Accordion.Header>
              <Accordion.Body>
                <EditorComponent
                  setting={{
                    editorState,
                    seteditorState,
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion
            className="w-100 text-lucaDark"
            activeKey={activeKey}
            onSelect={(eventKey) => {
              if (eventKey !== activeKey) {
                setaction('reading')
              }
              setactiveKey(eventKey)
            }}
          >
            {logs.length !== 0 ? (
              logs.map((l) => {
                const { version, title, html } = l.setting
                return (
                  <Accordion.Item key={version} eventKey={version}>
                    <Accordion.Header className="d-flex border ">
                      {activeKey === version && action === 'editing' ? (
                        <>
                          <Form.Control
                            size="sm"
                            placeholder="標題"
                            value={form.title}
                            onClick={(e) => {
                              if (e && e.stopPropagation) e.stopPropagation()
                            }}
                            onChange={(e) =>
                              setform({ ...form, title: e.target.value })
                            }
                          />
                          <ClockInput
                            setting={{
                              value: form.version,
                              onChange: (value) =>
                                setform({ ...form, version: value }),
                            }}
                          />
                        </>
                      ) : (
                        <h6 className="text-lucaDark">{`${title}   ${version}`}</h6>
                      )}
                      {isAdmin &&
                        (activeKey === version && action === 'editing' ? (
                          <>
                            <FontAwesomeIcon
                              onClick={(e) => {
                                if (e && e.stopPropagation) e.stopPropagation()
                                setaction('reading')
                              }}
                              className="ms-auto my-auto align-self-center btn-lucaIcon3"
                              icon={faTimes}
                              title="取 消"
                            />
                            <FontAwesomeIcon
                              onClick={(e) => {
                                if (e && e.stopPropagation) e.stopPropagation()
                                handleLogsChange({
                                  id: l.changelog_id,
                                  value: {
                                    ...form,
                                    html: draftToHtml(
                                      convertToRaw(
                                        editorState.getCurrentContent()
                                      )
                                    ),
                                  },
                                  action: 'put',
                                })
                                setaction('reading')
                              }}
                              className="ms-2 my-auto align-self-center btn-lucaIcon3"
                              icon={faCheck}
                              title="確 定"
                            />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              onClick={(e) => {
                                if (e && e.stopPropagation) e.stopPropagation()
                                if (activeKey !== 'version')
                                  setactiveKey(version)
                                setform({
                                  title,
                                  version,
                                })
                                getEsFromHtml(html)
                                setaction('editing')
                              }}
                              className="ms-auto my-auto align-self-center btn-lucaIcon3"
                              icon={faEdit}
                              title="編 輯"
                            />
                            <FontAwesomeIcon
                              onClick={(e) => {
                                if (e && e.stopPropagation) e.stopPropagation()
                                handleLogsChange({
                                  id: l.changelog_id,
                                  action: 'delete',
                                })
                                setaction('reading')
                              }}
                              className="ms-2 my-auto align-self-center btn-lucaIcon3"
                              icon={faTrashAlt}
                              title="刪 除"
                            />
                          </>
                        ))}
                      <FontAwesomeIcon
                        className="ms-2 my-auto align-self-center"
                        icon={
                          activeKey === version ? faChevronUp : faChevronDown
                        }
                      />
                    </Accordion.Header>
                    <Accordion.Body style={{ overflow: 'scroll' }}>
                      {activeKey === version && action === 'editing' ? (
                        <EditorComponent
                          setting={{
                            editorState,
                            seteditorState,
                          }}
                        />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })
            ) : (
              <p className="text-center">Oops! 目前沒有任何版本更新公告。</p>
            )}
          </Accordion>
        </Modal.Body>
      )}
    </Modal>
  )
}

Changelog.propTypes = {
  setting: PropTypes.shape().isRequired,
}

EditorComponent.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ClockInput.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Changelog
