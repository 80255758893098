import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getOriginIcon } from '../../services/lucaFunctions'

function KPISummary(props) {
  const { setting } = props
  const { forms, type, depart, since, until } = setting

  const months = useMemo(() => {
    const start = moment(since)
    const end = moment(until)
    const interim = start.clone()
    const timeValues = []

    while (end > interim || interim.format('M') === end.format('M')) {
      timeValues.push(interim.format('YY年MM'))
      interim.add(1, 'month')
    }
    return timeValues
  }, [since, until])

  const names = {
    Recharge: '儲值',
    Permission: '權限',
    OpenAccount: '開戶',
  }

  return (
    <>
      <Row
        className="colBorder text-lucaDark"
        style={{
          height: '50vh',
        }}
      >
        <Col className="d-flex flex-column h-100 px-0">
          <Row className="mx-0">
            <Col
              className="colBorder lh-lg d-flex justify-content-center bg-lucaLighter"
              style={{
                writingMode: 'vertical-lr',
                textAlign: 'center',
                maxWidth: '5%',
              }}
            />
            <Col className="d-flex px-0">
              <Col
                className="colBorder text-center py-2 bg-lucaLighter"
                style={{
                  width: '8%',
                  maxWidth: '8%',
                }}
              />
              {months.map((m) => (
                <Col
                  key={m}
                  className="colBorder fw-bold text-center py-2 bg-lucaLighter"
                >
                  <p>{m}月</p>
                </Col>
              ))}
            </Col>
          </Row>
          <div className="overflow-scroll">
            {Object.keys({ all: true, ...setting.platform })
              .filter((p) => setting.platform[p] || p === 'all')
              .map((p) => {
                const filteredForm = forms.filter(
                  (f) =>
                    (f.setting.platform === p || p === 'all') &&
                    depart[f.content['填表人所屬單位']] &&
                    type[f.type] &&
                    moment(f.created_on).isBetween(moment(since), moment(until))
                )
                const timedForm = filteredForm.reduce(
                  (prev, cur) => {
                    const month = moment(cur.created_on).format('YY年MM')
                    if (prev[cur.type][month]) {
                      return {
                        ...prev,
                        [cur.type]: {
                          ...prev[cur.type],
                          [month]: prev[cur.type][month] + 1,
                        },
                        total: {
                          ...prev.total,
                          [month]: prev.total[month] + 1,
                        },
                      }
                    }
                    return {
                      ...prev,
                      [cur.type]: {
                        ...prev[cur.type],
                        [month]: 1,
                      },
                      total: {
                        ...prev.total,
                        [month]: 1,
                      },
                    }
                  },
                  {
                    OpenAccount: {},
                    Recharge: {},
                    Permission: {},
                    total: {},
                  }
                )
                return (
                  <Row key={p} className="mx-0">
                    <Col
                      className="colBorder lh-lg d-flex justify-content-center bg-lucaLighter"
                      style={{
                        writingMode: 'vertical-lr',
                        textAlign: 'center',
                        maxWidth: '5%',
                      }}
                    >
                      {p === 'all' ? (
                        '總計'
                      ) : (
                        <>
                          <span
                            className="d-flex mb-3"
                            style={{
                              width: '30px',
                              height: '30px',
                              cursor: 'pointer',
                              paddingRight: '15px',
                            }}
                            aria-hidden
                          >
                            {getOriginIcon(p, '30px', '30px')}
                          </span>
                          {p}
                        </>
                      )}
                    </Col>
                    <Col>
                      {Object.keys(type)
                        .filter((t) => type[t])
                        .map((t) => (
                          <Row className="flex-grow-1">
                            <Col
                              className="colBorder text-center py-2 bg-lucaLighter"
                              style={{
                                width: '8%',
                                maxWidth: '8%',
                              }}
                            >
                              <p>
                                {names[t]}（
                                {
                                  filteredForm.filter((f) => f.type === t)
                                    .length
                                }
                                ）
                              </p>
                            </Col>
                            {months.map((m) => (
                              <Col
                                key={m}
                                className="d-flex colBorder fw-regular text-center py-2"
                              >
                                <p>{timedForm[t][m] || 0}</p>
                              </Col>
                            ))}
                          </Row>
                        ))}
                      <Row className="flex-grow-1 bg-lucaLighter2">
                        <Col
                          className="colBorder text-center py-2"
                          style={{
                            width: '8%',
                            maxWidth: '8%',
                          }}
                        >
                          <p className="fw-bold">
                            總計（{filteredForm.length}）
                          </p>
                        </Col>
                        {months.map((m) => (
                          <Col
                            key={m}
                            className="d-flex colBorder fw-regular text-center py-2"
                          >
                            <p className="fw-bold text-luca">
                              {timedForm.total[m] || 0}
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                )
              })}
          </div>
        </Col>
      </Row>
      <hr className="hrClass-dotted" style={{ margin: '2rem -0.75rem' }} />
    </>
  )
}

KPISummary.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default KPISummary
