/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as emptyMark } from '@fortawesome/free-regular-svg-icons'
import { Form } from 'react-bootstrap'
// import Form from 'react-bootstrap/Form'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBookmark } from '@fortawesome/free-solid-svg-icons'
// import { faBookmark as emptyMark } from '@fortawesome/free-regular-svg-icons'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const initFields = [
  {
    from: 'media',
    label: '帳戶id',
    show: false,
    name: 'account_id',
    type: 'text',
    xs: 3,
    sticky: true,
  },
  {
    from: 'media',
    label: '帳戶名稱',
    show: true,
    type: 'text',
    name: 'account_name',
    xs: 3,
    sticky: true,
  },
  {
    from: 'erp',
    label: 'AM',
    show: true,
    type: 'text',
    xs: 1,
    name: 'PDL02C',
    sticky: false,
  },
  {
    from: 'erp',
    label: 'AE',
    show: true,
    type: 'text',
    xs: 1,
    name: 'PDL01C',
    sticky: false,
  },
  {
    from: 'media',
    label: '初次上線年',
    show: true,
    type: 'text',
    name: 'fy',
    xs: 1,
    sticky: false,
  },
  {
    from: 'media',
    label: '初次上線月',
    show: true,
    type: 'text',
    name: 'fm',
    xs: 1,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案代號',
    show: true,
    type: 'text',
    name: 'PROJECT_ID',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '專案名稱',
    show: true,
    type: 'text',
    name: 'PROJECT_NAME',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: '客戶名稱',
    show: true,
    type: 'text',
    name: 'PARTNER_NAME',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: '客戶來源',
    show: true,
    type: 'text',
    name: 'PDL08C',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '案型',
    show: false,
    type: 'text',
    name: 'PDL06C',
    xs: 2,
    sticky: false,
  },
  {
    from: 'erp',
    label: '進案型態',
    show: true,
    type: 'text',
    name: 'PDL07C',
    xs: 2,
    sticky: false,
  },
  {
    from: 'media',
    label: '媒體',
    show: true,
    type: 'text',
    name: 'provider',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: 'AC%',
    show: true,
    type: 'text',
    name: 'AC',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: 'Markup',
    show: true,
    type: 'text',
    name: 'Markup',
    xs: 3,
    sticky: false,
  },
  {
    from: 'erp',
    label: '合約金額',
    show: true,
    type: 'currency',
    name: 'CONTRACT_AMOUNT',
    xs: 2,
    sticky: false,
  },
].map((item, index) => ({
  ...item,
  index,
}))

const getMackuped = (spend = 0, Markup = 0, AC = 0) => {
  const m = Number.isFinite(parseFloat(Markup)) ? parseFloat(Markup) : 0
  const a = Number.isFinite(parseFloat(AC)) ? parseFloat(AC) : 0
  return parseFloat(spend) * m + parseFloat(spend) * (1 + m) * a
}

function TrueCostsTable({ setting }) {
  const { revenue, costSetting, since, until } = setting
  const { ctp } = costSetting
  const [fields, setfields] = useState(initFields)

  const months = useMemo(() => {
    const temp = []
    const start = moment(since)
    const end = moment(until)
    while (start.isBefore(end)) {
      temp.push(`${start.year()}_${start.month() + 1}`)
      start.add(1, 'month')
    }
    temp.push(`${end.year()}_${end.month() + 1}`)
    return Array.from(new Set(temp))
  }, [since, until])

  const refined = useMemo(
    () =>
      Object.keys(revenue)
        .filter((key) => key !== 'np')
        .reduce((prev, cur) => {
          const accounts = {}
          revenue[cur].campaigns.forEach((campaign) => {
            if (accounts[campaign.account_id]) {
              accounts[campaign.account_id].campaigns = (
                accounts[campaign.account_id].campaigns || []
              ).concat([campaign])
              //   Object.keys(accounts[campaign.account_id]).forEach((key) => {
              //     if (['spend', 'impressions', 'clicks', 'reach'].includes(key))
              //       accounts[campaign.account_id][key] =
              //         parseFloat(accounts[campaign.account_id][key]) +
              //         parseFloat(campaign[key] || 0)
              //   })
              const fy = moment(campaign.date_start).year()
              const fm = moment(campaign.date_start).month() + 1
              accounts[campaign.account_id].fy = Math.min(
                accounts[campaign.account_id].fy || fy + 1,
                fy
              )
              accounts[campaign.account_id].fm = Math.min(
                accounts[campaign.account_id].fm || fm + 1,
                fm
              )
              const AC =
                ctp[campaign.campaign_id] &&
                ctp[campaign.campaign_id].find &&
                ctp[campaign.campaign_id].find((c) => c.type === 'AC')
              const Markup =
                ctp[campaign.campaign_id] &&
                ctp[campaign.campaign_id].find &&
                ctp[campaign.campaign_id].find((c) => c.type === 'Markup')
              if (AC) accounts[campaign.account_id].AC = AC.value
              else
                accounts[campaign.account_id].AC =
                  accounts[campaign.account_id].AC || ''
              if (Markup) accounts[campaign.account_id].Markup = Markup.value
              else
                accounts[campaign.account_id].Markup =
                  accounts[campaign.account_id].Markup || ''
            } else {
              accounts[campaign.account_id] = {
                ...campaign,
                ...revenue[cur],
                campaigns: [campaign],
              }
            }
          })
          const arrayed = Object.keys(accounts).reduce(
            (prevA, curA) => [
              ...prevA,
              {
                // ...revenue[cur],
                ...accounts[curA],
              },
            ],
            []
          )
          return [...prev, ...arrayed]
        }, []),
    [revenue]
  )

  const [hand, sethand] = useState(false)

  return (
    <div
      className="w-100 overflow-scroll"
      style={{
        height: '70vh',
      }}
    >
      <Table className="text-lucaDark ps-1 costtable overflow-scroll">
        <thead>
          <tr>
            <th colSpan="2">媒體</th>
            <th colSpan="14">專案資料</th>
            <th colSpan={months.length + 1}>預計花費</th>
            <th colSpan={months.length + 1}>廣告花費</th>
            <th className="d-flex" colSpan={months.length + 1}>
              毛利 手填
              <Form.Check
                type="checkbox"
                className="d-flex text-start align-content-start mb-0 fs-7"
                title="全 選"
              >
                <Form.Check.Input
                  style={{ cursor: 'pointer' }}
                  className="my-auto"
                  type="checkbox"
                  title="手填"
                  onChange={() => sethand(!hand)}
                />
              </Form.Check>
            </th>
            <th colSpan={months.length + 2}>實際委刊金額</th>
          </tr>
          <tr>
            {fields.map(({ label, sticky, index }) => (
              <th
                style={{
                  position: sticky ? 'sticky' : '',
                  left: `${
                    fields
                      .filter((f) => f.sticky)
                      .findIndex((f) => f.index === index) * 6
                  }rem`,
                  zIndex: sticky ? 2 : 1,
                }}
              >
                {label}
                <FontAwesomeIcon
                  style={{
                    color: '#317985',
                    cursor: 'pointer',
                  }}
                  className="ms-3 align-self-center"
                  icon={sticky ? faBookmark : emptyMark}
                  title={sticky ? '取 消 釘 選' : '釘 選'}
                  onClick={() =>
                    setfields((prevState) =>
                      prevState.map((p) =>
                        p.index === index ? { ...p, sticky: !p.sticky } : p
                      )
                    )
                  }
                />
              </th>
            ))}
            {months.map((m) => (
              <th>
                <p className="m-auto text-nowrap">{m}</p>
              </th>
            ))}
            <th>小計</th>
            {months.map((m) => (
              <th>
                <p className="m-auto text-nowrap">{m}</p>
              </th>
            ))}
            <th>小計</th>
            {months.map((m) => (
              <th>
                <p className="m-auto text-nowrap">{m}</p>
              </th>
            ))}
            <th>小計</th>
            {months.map((m) => (
              <th>
                <p className="m-auto text-nowrap">{m}</p>
              </th>
            ))}
            <th>小計</th>
            <th>消耗餘額</th>
          </tr>
        </thead>
        <tbody>
          {refined.map((r) => (
            <tr>
              {fields.map(({ name, sticky, type, index }) => (
                <td
                  style={{
                    position: sticky ? 'sticky' : '',
                    left: `${
                      fields
                        .filter((f) => f.sticky)
                        .findIndex((f) => f.index === index) * 6
                    }rem`,
                    zIndex: sticky ? 1 : 0,
                  }}
                >
                  {['account_name', 'PROJECT_NAME'].includes(name) ? (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip
                          className="quesTip"
                          style={{
                            zIndex: '9999',
                          }}
                        >
                          ＊{r[name]}
                        </Tooltip>
                      }
                    >
                      <p className="w-100 m-auto ps-1 App-oneLineEllipsis">
                        {type === 'currency'
                          ? numeral(r[name]).format('$0,0')
                          : r[name]}
                      </p>
                    </OverlayTrigger>
                  ) : (
                    <p className="m-auto text-nowrap">
                      {type === 'currency'
                        ? numeral(r[name]).format('$0,0')
                        : r[name]}
                    </p>
                  )}
                </td>
              ))}
              {months.map(() => (
                <td>
                  <Form.Control className="m-auto text-nowrap" />
                </td>
              ))}
              <td>
                <p className="m-auto text-nowrap" />
              </td>
              {months.map((m) => {
                const total = r.campaigns
                  .filter((campaign) => {
                    const year = parseInt(m.split('_')[0], 10)
                    const month = parseInt(m.split('_')[1], 10) - 1
                    return (
                      moment(campaign.date_start).year() === year &&
                      moment(campaign.date_start).month() === month
                    )
                  })
                  .reduce((prev, cur) => prev + parseInt(cur.spend, 10), 0)
                return (
                  <td>
                    <p className="m-auto text-nowrap">
                      {total ? numeral(total).format('$0,0') : ''}
                    </p>
                  </td>
                )
              })}
              <td>
                <p className="m-auto text-nowrap">
                  {numeral(
                    r.campaigns.reduce(
                      (prev, cur) => prev + parseInt(cur.spend, 10),
                      0
                    )
                  ).format('$0,0')}
                </p>
              </td>
              {months.map((m) => {
                const total = r.campaigns
                  .filter((campaign) => {
                    const year = parseInt(m.split('_')[0], 10)
                    const month = parseInt(m.split('_')[1], 10) - 1
                    return (
                      moment(campaign.date_start).year() === year &&
                      moment(campaign.date_start).month() === month
                    )
                  })
                  .reduce(
                    (prev, cur) =>
                      prev + getMackuped(cur.spend, r.Markup, r.AC),
                    0
                  )
                return (
                  <td>
                    <p className="m-auto text-nowrap">
                      {total ? numeral(total).format('$0,0') : ''}
                    </p>
                  </td>
                )
              })}
              <td>
                <p className="m-auto text-nowrap">
                  {numeral(
                    r.campaigns.reduce(
                      (prev, cur) =>
                        prev + getMackuped(cur.spend, r.Markup, r.AC),
                      0
                    )
                  ).format('$0,0')}
                </p>
              </td>
              {months.map((m) => {
                const total = r.campaigns
                  .filter((campaign) => {
                    const year = parseInt(m.split('_')[0], 10)
                    const month = parseInt(m.split('_')[1], 10) - 1
                    return (
                      moment(campaign.date_start).year() === year &&
                      moment(campaign.date_start).month() === month
                    )
                  })
                  .reduce(
                    (prev, cur) =>
                      prev +
                      getMackuped(cur.spend, r.Markup, r.AC) +
                      parseFloat(cur.spend),
                    0
                  )
                return (
                  <td>
                    <p className="m-auto text-nowrap">
                      {total ? numeral(total).format('$0,0') : ''}
                    </p>
                  </td>
                )
              })}
              <td>
                <p className="m-auto text-nowrap">
                  {numeral(
                    r.campaigns.reduce(
                      (prev, cur) =>
                        prev +
                        getMackuped(cur.spend, r.Markup, r.AC) +
                        parseFloat(cur.spend),
                      0
                    )
                  ).format('$0,0')}
                </p>
              </td>
              <td>
                <p className="m-auto text-nowrap">
                  {numeral(
                    parseFloat(r.CONTRACT_AMOUNT) -
                      r.campaigns.reduce(
                        (prev, cur) =>
                          prev +
                          getMackuped(cur.spend, r.Markup, r.AC) +
                          parseFloat(cur.spend),
                        0
                      )
                  ).format('$0,0')}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

TrueCostsTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TrueCostsTable
