import React from 'react'
import PropTypes from 'prop-types'
import ProgressBar from 'react-bootstrap/ProgressBar'

function ReportProcessor(props) {
  const { setting } = props
  const { progress } = setting
  return (
    <ProgressBar className="w-100 rounded-pill fs-6" style={{ height: '30px' }}>
      <ProgressBar
        animated
        style={{
          backgroundColor: progress < 100 ? '#FCBF49' : '#32a6af',
          transition: '0.5s',
        }}
        now={progress}
        label={`${progress}%`}
      />
      <ProgressBar className="bg-grey text-lucaLight" now={100 - progress} />
    </ProgressBar>
  )
}

ReportProcessor.propTypes = {
  setting: PropTypes.shape(),
}

ReportProcessor.defaultProps = {
  setting: {
    title: '儲 值 金 額',
    titleEng: 'Total amount',
    data: { spend: 12000, total: 18000 },
  },
}

export default ReportProcessor
