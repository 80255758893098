/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faEdit,
  faCopy,
  faPlay,
  faPause,
  faTrashAlt,
  faAngleRight,
  faDownload,
  faReply,
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faCircleChevronLeft,
  faRecycle,
} from '@fortawesome/free-solid-svg-icons'
import { CircleBothside } from '../../assets'
import { ReactComponent as ToolbarAll } from '../../assets/images/toolbar-all.svg'
import ConfirmForm from '../ConfirmForm'
import Lists from './Lists'
import Insights from '../Insights'
import FieldsDialog from './FieldsDialog'
import BreakdownDialog from './BreakdownDialog'
import Dropmenu from '../Dropmenu'
import ToolBar from '../ToolBar'
import AccountRule from './AccountRule'
import AdsRule from './AdsRule'
import AdsDialogs from './AdsDialogs'
import SearchBar from '../SearchBar'
import LucaDatePicker from '../LucaDatePicker'
import apiService from '../../services/apiService'
// import GoogleInsihgtsEnums from '../../configure/GoogleInsightsEnums'
import { wordSearch } from '../../services/lucaFunctions'
import { processData } from '../../services/insightProcesser'

function AdsManager(props) {
  const { setting } = props
  const { auth, account, setaccount, hasToolBar } = setting
  const provider = useMemo(() => account.provider, [account])
  const [showInsight, setshowInsight] = useState(!hasToolBar)

  const [datas, setdatas] = useState({
    campaign: [],
    adset: [],
    ad: [],
    init: false,
    prepared: false,
  })

  const [tabStatus, settabStatus] = useState({
    campaign: 'active',
    adset: 'show',
    ad: 'show',
  })
  const activetab = useMemo(
    () => Object.keys(tabStatus).find((key) => tabStatus[key] === 'active'),
    [tabStatus]
  )
  const [selected, setselected] = useState({
    id: '',
    tab: '',
  })
  const active = useMemo(
    () =>
      datas[selected.tab] &&
      datas[selected.tab].find(
        (a) => selected.id === (a[`${selected.tab}_id`] || a.id)
      ),
    [selected]
  )

  const defaultFields = {
    facebook: ['impressions', 'clicks', 'reach', 'cpp'],
    google: ['metrics.impressions', 'metrics.clicks', 'metrics.cost_micros'],
    twitter: [''],
  }

  const [query, setquery] = useState({
    since: moment().subtract(7, 'days').toDate(),
    until: moment().toDate(),
    dateBreakdown: 'all_days',
    breakdown: '',
    fields: defaultFields[provider],
  })
  const handleQueryChange = (newQuery) => setquery({ ...query, ...newQuery })
  const { since, until, dateBreakdown, breakdown, fields } = query
  const breakdowns = useMemo(
    () => breakdown.split(',').filter((b) => b !== ''),
    [breakdown]
  )

  const [selectedField, setselectedField] = useState(fields[0])

  // insight type
  const [insightType, setinsightType] = useState('Data')
  const insightTypes =
    provider === 'facebook'
      ? [
          { name: '趨勢圖模式', value: 'StackChart' },
          { name: '折線圖模式', value: 'LineChart' },
          { name: '數據模式', value: 'Data' },
          { name: '圓餅圖模式', value: 'PieChart' },
        ]
      : [
          { name: '趨勢圖模式', value: 'StackChart' },
          { name: '折線圖模式', value: 'LineChart' },
          { name: '數據模式', value: 'Data' },
        ]

  const [timeRange, settimeRange] = useState({
    since: moment().subtract(7, 'days').toDate(),
    until: moment().toDate(),
  })

  const getDatas = async (init) => {
    if (!datas.init || init) {
      const data = await processData({
        ...account,
        business_id: auth.account.business_id,
        briefly: !hasToolBar,
        time_range: {
          since: moment(timeRange.since).format('yyyy-MM-DD'),
          until: moment(timeRange.until).format('yyyy-MM-DD'),
        },
      })
      setdatas({
        ...datas,
        ...data,
        init: true,
        prepared: true,
      })
    } else {
      setdatas({
        ...datas,
        prepared: true,
      })
    }
  }

  useEffect(() => {
    setdatas({
      ...datas,
      prepared: false,
    })
    getDatas(true)
  }, [timeRange])
  const { campaign, adset, ad, prepared } = datas

  const emptySet = {
    campaignId: '',
    purpose: 'BRAND_AWARENESS',
    promoted_object: { page_id: 'empty' },
    adsetId: '',
    creativeId: '',
    name: '',
    adId: '',
    adImageHash: '',
    adVideoId: '',
    link: '',
    message: '',
    objectStoryId: '',
    postId: '',
    lifetime_budget: '',
    daily_budget: '',
    bid_strategy: '',
    bid_amount: '',
    start_date: null,
    end_date: null,
    video_id: '',
    image_url: '',
    billing_event: 'IMPRESSIONS',
    call_to_action: { type: '', target: '', value: '' },
    optimization_goal: '',
    max: {},
    selectedObject: 'impressions',
    recommendState: '',
    budgetRecommendState: '',
  }
  const [selectSet, setSelectSet] = useState(emptySet)
  const handleSetChange = (event, values) => {
    if (!event.target) {
      setSelectSet((prevState) => ({ ...prevState, ...event }))
    } else if (values && values.id)
      setSelectSet((prevState) => ({
        ...prevState,
        promoted_object: { page_id: values.id },
      }))
    else if (event.target.name.includes('.'))
      setSelectSet((prevState) => ({
        ...prevState,
        [event.target.name.split('.')[0]]: {
          ...prevState[event.target.name.split('.')[0]],
          [event.target.name.split('.')[1]]: event.target.value,
        },
      }))
    else
      setSelectSet((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }))
  }

  const [modalState, setmodalState] = useState({
    breakdownOpen: false,
    fieldSelectOpen: false,
    createOpen: false,
    editOpen: false,
    copyOpen: false,
  })
  const handleModalState = (key, value) =>
    setmodalState({ ...modalState, [key]: value })
  const { breakdownOpen, fieldSelectOpen, editOpen } = modalState

  const types = {
    facebook: 'marketingApi',
    google: 'googleAdsApi',
    twitter: 'twitter',
    criteo: 'criteo',
    linkedin: 'linkedin',
  }

  // breakdown setting
  const handleBreakdownClose = (value) => {
    if (value) {
      handleQueryChange({ ...value })
    }
    handleModalState('breakdownOpen', false)
  }

  const [presets, setpresets] = useState([
    { name: 'default', values: defaultFields[provider] },
  ])
  const [selectedPreset, setselectedPreset] = useState(-1)
  const handleFieldSelectClose = async (value) => {
    if (value !== -1) {
      await apiService.data({
        path: `luca/preset/${auth.auth.user_id}`,
        method: 'post',
        data: presets
          .slice(1)
          .filter((p) => p.provider === provider)
          .map((p, i) =>
            i === value - 1 ? { ...p, default: true } : { ...p, default: false }
          )
          .concat(presets.filter((p) => p.provider !== provider)),
      })
      handleQueryChange({
        fields: presets.filter((p) => p.provider === provider)[value].values,
      })
      setselectedPreset(value)
    }
    handleModalState('fieldSelectOpen', false)
  }

  useEffect(() => {
    const getPresets = async () => {
      const res = await apiService.data({
        path: `luca/preset/${auth.auth.user_id}`,
        method: 'get',
      })
      if (res) {
        setpresets([
          {
            name: '預設欄位',
            values: defaultFields[provider],
            provider,
          },
          ...res,
        ])
        const target = res
          .filter((r) => r.provider === provider)
          .findIndex((r) => r.default)
        if (target !== -1) {
          handleQueryChange({
            fields: res.filter((r) => r.provider === provider)[target].values,
          })
          setselectedPreset(target + 1)
        } else {
          handleQueryChange({
            fields: defaultFields[provider],
          })
        }
      }
    }
    if (auth.auth && auth.auth.user_id) getPresets()
  }, [auth])

  // edit preset
  const createPreset = async (value) => {
    const res = await apiService.data({
      path: `luca/preset/${auth.auth.user_id}`,
      method: 'post',
      data: presets.slice(1).concat(value),
    })
    setpresets([
      {
        name: '預設欄位',
        values: defaultFields[provider],
        provider,
      },
      ...res,
    ])
  }
  const deletePreset = async (index) => {
    const res = await apiService.data({
      path: `luca/preset/${auth.auth.user_id}`,
      method: 'delete',
      data: presets
        .slice(1)
        .filter((p) => p.provider === provider)
        .filter((p, i) => i !== index - 1)
        .concat(presets.filter((p) => p.provider !== provider)),
    })
    if (index === selectedPreset) {
      setselectedPreset(-1)
      handleQueryChange({ fields: presets[0].values })
    }
    setpresets([
      {
        name: '預設欄位',
        values: defaultFields[provider],
        provider,
      },
      ...res,
    ])
  }

  // create
  const handleCreateClose = async (completed) => {
    handleModalState('createOpen', false)
    if (!completed) return
    const type = selectSet.campaignId
      ? selectSet.adsetId
        ? 'ad'
        : 'adset'
      : 'campaign'
    await apiService.data({
      path: `luca/insight/data?account_id=${account.account_id}&business_id=${auth.account.business_id}&type=${type}&api=${types[provider]}`,
      method: 'post',
      data: selectSet,
    })
    getDatas(true)
  }

  // check list
  const [checkedList, setcheckedList] = useState([])
  const handleCheck = (id) => {
    if (checkedList.includes(id))
      setcheckedList(checkedList.filter((c) => c !== id))
    else setcheckedList([...checkedList, id])
  }

  // edit
  const handleEditShow = () => {
    setSelectSet({ ...selectSet, ...active })
    handleModalState('editOpen', true)
  }
  const handleEditClose = async (value) => {
    if (value) {
      await apiService.data({
        path: `luca/insight/data?account_id=${account.account_id}&business_id=${auth.account.business_id}&id=${checkedList[0]}&type=${activetab}&api=${types[provider]}`,
        method: 'put',
        data: selectSet,
      })
      getDatas(true)
    }
    handleModalState('editOpen', false)
    setSelectSet(emptySet)
  }

  // preview
  const [preview, setpreview] = useState(null)
  const getPreview = async (adId) => {
    const res = await apiService.data({
      path: `luca/insight/preview?api=${types[provider]}&account_id=${account.account_id}&adId=${adId}&provider=${provider}`,
      method: 'get',
    })
    if (res && !res.error) {
      setpreview(res.datas)
    }
  }

  // deliveryEstimate
  const [deliveryEstimate, setdeliveryEstimate] = useState(null)
  const getDeliveryEstimate = async (adsetId) => {
    const res = await apiService.data({
      path: `luca/insight/deliveryEstimate?api=${types[provider]}&account_id=${account.account_id}&adsetId=${adsetId}`,
      method: 'get',
    })
    if (res && !res.error) {
      const de = {}
      Object.keys(res).forEach((key) => {
        const last =
          res[key].data.daily_outcomes_curve[
            res[key].data.daily_outcomes_curve.length - 1
          ]
        de[key] = {
          ...res[key],
          max_estimate: {
            ...last,
          },
        }
      })
      const max = {
        reach: {
          number: 0,
          key: '',
        },
        impressions: {
          number: 0,
          key: '',
        },
        actions: {
          number: 0,
          key: '',
        },
      }
      Object.keys(res).forEach((key) => {
        const last =
          res[key].data.daily_outcomes_curve[
            res[key].data.daily_outcomes_curve.length - 1
          ]
        Object.keys(max).forEach((mk) => {
          if (last[mk] > max[mk].number)
            max[mk] = {
              number: last[mk],
              key,
            }
        })
        handleSetChange({
          target: { name: 'optimization_goal', value: max.impressions.key },
        })
      })
      de.max = max
      setdeliveryEstimate(de)
    }
  }
  useEffect(() => {
    if (editOpen && activetab === 'ad') getPreview(checkedList[0])
    if (editOpen && activetab === 'adset') getDeliveryEstimate(checkedList[0])
  }, [editOpen])

  // copy
  const handleCopyClose = async (value) => {
    if (value) {
      await apiService.data({
        path: `luca/insight/copy?account_id=${account.account_id}&business_id=${auth.account.business_id}&type=${activetab}&api=${types[provider]}`,
        method: 'post',
        data: { checkedList, ...selectSet },
      })
    }
    handleModalState('copyOpen', false)
  }

  // Account rule setting
  const [showRule, setshowRule] = useState(false)
  const [rules, setrules] = useState({ account: [], campaign: [] })
  // new rule setting
  const [newRule, setNewRule] = useState({
    start: moment(Date.now()).format('yyyy-MM-DD'),
    end: moment(Date.now()).format('yyyy-MM-DD'),
    id: '',
    value: '1',
    show: false,
  })
  const handleResetNewRule = () => {
    setNewRule({
      start: moment(Date.now()).format('yyyy-MM-DD'),
      end: moment(Date.now()).format('yyyy-MM-DD'),
      id: '',
      value: '1',
      show: false,
    })
  }
  const handleRuleShow = async () => {
    const res = await apiService.data({
      path: `luca/accountRule/${account.account_id}/${auth.account.business_id}`,
      method: 'get',
    })
    if (!res.error) setrules(res)
    setshowRule(true)
  }
  const handleRuleClose = async () => setshowRule(false)
  const handleRuleEdit = async (action, type, index) => {
    let data = {}
    if (action === 'add')
      if (newRule.id !== '')
        data = {
          account: [...rules.account],
          campaign: [...rules.campaign, newRule],
        }
      else
        data = {
          account: [...rules.account, newRule],
          campaign: [...rules.campaign],
        }
    else if (action === 'delete')
      data = {
        account: rules.account.filter(
          (a, i) => type !== 'account' || i !== index
        ),
        campaign: rules.campaign.filter(
          (a, i) => type !== 'campaign' || i !== index
        ),
      }
    let method = 'put'
    if (
      rules.account.length === 0 &&
      rules.campaign.length === 0 &&
      action === 'add'
    )
      method = 'post'
    if (
      data.account.length === 0 &&
      data.campaign.length === 0 &&
      action === 'delete'
    )
      method = 'delete'
    const res = await apiService.data({
      path: `luca/accountRule/${account.account_id}/${auth.account.business_id}`,
      method,
      data,
    })
    if (!res.error) setrules(res)
  }
  const handleRuleDelete = async (data) => {
    const res = await apiService.data({
      path: `luca/accountRule/${account.account_id}/${auth.account.business_id}`,
      method: 'delete',
      data,
    })
    if (!res.error) setrules(res)
  }

  // Ads Rule
  const [showAdsRule, setshowAdsRule] = useState(false)
  const [adsRules, setadsRules] = useState([])
  // new rule setting
  const emptyNewAdsRule = {
    type: 'optimize',
    rule: [
      {
        target: '',
        operator: '',
        value: '',
      },
    ],
    ads: [
      {
        campaignId: '',
        adsetId: '',
        adId: '',
      },
    ],
    since: new Date(),
    until: new Date(),
    target: '',
    operator: '',
    value: '',
    action: '',
  }
  const [newAdsRule, setNewAdsRule] = useState(emptyNewAdsRule)
  const handleAddAdsRule = (key, index) =>
    setNewAdsRule({
      ...newAdsRule,
      [key]: newAdsRule[key]
        .slice(0, index + 1)
        .concat(emptyNewAdsRule[key])
        .concat(newAdsRule[key].slice(index + 1)),
    })
  const handleResetNewAdsRule = () => {
    setSelectSet(emptySet)
    setNewAdsRule(emptyNewAdsRule)
  }
  const handleAdsRuleShow = async () => {
    const res = await apiService.data({
      path: `luca/adsRule/${account.account_id}/${auth.account.business_id}`,
      method: 'get',
    })
    if (!res.error) setadsRules(res.rule)
    setshowAdsRule(true)
  }
  const handleAdsRuleClose = () => {
    setSelectSet(emptySet)
    setNewAdsRule(emptyNewAdsRule)
    setshowAdsRule(false)
  }
  const handleAdsRuleCreate = async () => {
    const level = selectSet.adId
      ? 'ad'
      : selectSet.adsetId
      ? 'adset'
      : 'campaign'
    const res = await apiService.data({
      path: `luca/adsRule/${account.account_id}/${auth.account.business_id}`,
      method: adsRules.length === 0 ? 'post' : 'put',
      data: {
        rule: adsRules.concat([
          {
            level,
            id: selectSet[`${level}Id`],
            ...newAdsRule,
          },
        ]),
      },
    })
    if (!res.error) setadsRules(res.rule)
  }
  const handleAdsRuleEdit = (event, key, index) => {
    if (index !== 0 && !index) {
      setNewAdsRule({
        ...newAdsRule,
        [event.target.name]: event.target.value,
      })
    } else {
      setNewAdsRule({
        ...newAdsRule,
        [key]: newAdsRule[key].map((rule, i) => {
          if (i === index)
            return { ...rule, [event.target.name]: event.target.value }
          return rule
        }),
      })
    }
  }
  const handleAdsRuleDelete = async (i) => {
    const res = await apiService.data({
      path: `luca/adsRule/${account.account_id}/${auth.account.business_id}`,
      method: adsRules.length === 1 ? 'delete' : 'put',
      data: {
        rule: adsRules.filter((r, index) => i !== index),
      },
    })
    if (!res.error) setadsRules(res.rule)
  }

  // 匯出csv
  const [csvData, setcsvData] = useState('')
  const createCsvFile = () => {
    const fileName = `${account.name} export.csv`
    const csvContent = `data:application/csv;charset=utf-8,\ufeff${csvData}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    switch (value) {
      case 'delete':
        await Promise.all(
          checkedList.map(async (id) => {
            await apiService.data({
              path: `luca/insight/data?&account_id=${account.account_id}&id=${id}&business_id=${auth.account.business_id}&type=${activetab}&api=${types[provider]}`,
              method: 'delete',
            })
          })
        )
        setcheckedList([])
        getDatas(true)
        break
      case 'start':
        await Promise.all(
          checkedList.map(async (id) => {
            await apiService.data({
              path: `luca/insight/data?&account_id=${account.account_id}&id=${id}&business_id=${auth.account.business_id}&type=${activetab}&api=${types[provider]}`,
              method: 'put',
              data: { status: 'ACTIVE' },
            })
          })
        )
        getDatas(true)
        break
      case 'pause':
        await Promise.all(
          checkedList.map(async (id) => {
            await apiService.data({
              path: `luca/insight/data?&account_id=${account.account_id}&id=${id}&business_id=${auth.account.business_id}&type=${activetab}&api=${types[provider]}`,
              method: 'put',
              data: { status: 'PAUSED' },
            })
          })
        )
        getDatas(true)
        break
      case 'copy':
        await Promise.all(
          checkedList.map(async (id) => {
            await apiService.data({
              path: `luca/insight/data?&account_id=${account.account_id}&id=${id}&business_id=${auth.account.business_id}&type=${activetab}&api=${types[provider]}`,
              method: 'delete',
            })
          })
        )
        getDatas(true)
        break
      case 'csv':
        createCsvFile()
        break
      case 'reset':
        await apiService.data({
          path: `luca/insight/insights/${account.account_id}`,
          method: 'delete',
          data: {
            since: moment(since).subtract(1, 'days').toDate(),
            until,
          },
        })
        break
      default:
        break
    }
  }
  const noSelectedError = () => {
    handleShowWarn(
      {
        img: faExclamationCircle,
        title: '刪 除 廣 告',
        titleEng: 'Delete the Advertisting(s)',
        warn: <h6 className="text-danger">請勾選至少一項內容</h6>,
      },
      'warn'
    )
  }

  const handleDateBreakdownClose = (value) => {
    if (value) handleQueryChange({ datebreakdown: value })
  }

  // sort setting
  const [sort, setsort] = useState({ name: '', order: true })

  // show list
  const [showList, setshowList] = useState(hasToolBar)

  //  white back height
  const whiteRef = useRef(null)
  const [whiteHeight, setwhiteHeight] = useState({ height: 0, marginBottom: 0 })
  const getWhiteHeight = () => {
    if (whiteRef.current) {
      const style = getComputedStyle(whiteRef.current)
      return {
        height: whiteRef.current.clientHeight,
        marginBottom: parseFloat(style.marginBottom),
      }
    }
    return { height: 0, marginBottom: 0 }
  }
  useEffect(() => {
    const height = getWhiteHeight()
    if (
      height.height !== 0 &&
      (height.height !== whiteHeight.height ||
        height.marginBottom !== whiteHeight.marginBottom)
    )
      setwhiteHeight(height)
  }, [getWhiteHeight])

  // check associated
  const isAssociated = (data, level, alternate) => {
    if (!alternate && !active) return false
    const target = alternate || active
    if (provider === 'twitter') {
      if (data.line_item_id) {
        const as = adset.find((a) => a.id === data.line_item_id)
        if (as) return isAssociated(as)
        return false
      }
      if (!alternate && active.line_item_id) {
        const as = adset.find((a) => a.id === active.line_item_id)
        if (as) return isAssociated(data, '', as)
        return false
      }
    }
    if (!level || level === 'campaign') {
      const campaignId = target.campaign
        ? target.campaign.id
        : target.campaign_id || target.id
      const id = data.campaign ? data.campaign.id : data.campaign_id || data.id
      return campaignId === id
    }
    if (level === 'adset') {
      const adsetId = target.ad_group
        ? target.ad_group.id
        : target.adset_id || target.id
      const id = data.ad_group ? data.ad_group.id : data.adset_id || data.id
      return adsetId === id
    }
    return false
  }

  const associatedInfo = useMemo(() => {
    if (!prepared)
      return {
        associated: {
          campaign: [],
          adset: [],
          ad: [],
        },
        campaign: [],
        adset: [],
        ad: [],
      }
    return {
      associated: {
        campaign: campaign
          .filter((i) => isAssociated(i))
          .map((i) => ({ ...i, associated: true })),
        adset: adset
          .filter((i) => isAssociated(i))
          .map((i) => ({ ...i, associated: true })),
        ad: ad
          .filter((i) => isAssociated(i))
          .map((i) => ({ ...i, associated: true })),
      },
      campaign: campaign.filter((i) => !isAssociated(i)),
      adset: adset.filter((i) => !isAssociated(i)),
      ad: ad.filter((i) => !isAssociated(i)),
    }
  }, [selected, campaign, adset, ad])

  const path = useMemo(() => {
    if (!active)
      return {
        campaign: false,
        adset: false,
        ad: false,
      }
    switch (selected.tab) {
      case 'campaign':
        return {
          campaign: active,
          adset:
            activetab === 'adset' || activetab === 'ad'
              ? { name: '廣告組合' }
              : false,
          ad: activetab === 'ad' ? { name: '廣告內容' } : false,
        }
      case 'adset':
        return {
          campaign: campaign.find((c) => isAssociated(c))
            ? campaign.find((c) => isAssociated(c))
            : { name: '廣告活動' },
          adset: active,
          ad: activetab === 'ad' ? { name: '廣告內容' } : false,
        }
      case 'ad':
        return {
          campaign: campaign.find((c) => isAssociated(c))
            ? campaign.find((c) => isAssociated(c))
            : { name: '廣告活動' },
          adset: adset.find((a) => isAssociated(a, 'adset'))
            ? adset.find((a) => isAssociated(a, 'adset'))
            : { name: '廣告設定' },
          ad: active,
        }
      default:
        return {
          campaign: false,
          adset: false,
          ad: false,
        }
    }
  }, [active, activetab, campaign, adset, ad])

  const titleWidth = useMemo(() => {
    if (activetab === 'campaign' && active) return 99 / 2
    if (activetab === 'adset' && active) return 98 / 3
    if (activetab === 'ad' && active) return 97 / 4
    return 100
  }, [active, activetab])

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    search: '',
  })
  const { search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })

  return (
    <>
      <Row className="px-4 pt-4">
        <Col
          xs={2}
          role="button"
          className="my-auto btn btn-lucaWhite"
          onClick={() => setaccount({ account_id: '' })}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faReply} className="my-auto fs-7 px-0" />
          &ensp;回帳戶列表
        </Col>
        <Col xs={8} className="d-flex px-5">
          <div
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: `${titleWidth - 1}%`,
              cursor: 'pointer',
            }}
            onClick={() => {
              setselected({
                id: '',
                tab: '',
              })
              settabStatus({
                ...tabStatus,
                [activetab]: 'show',
                campaign: 'active',
              })
            }}
            aria-hidden
            className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
          >
            {account.name}
          </div>
          <FontAwesomeIcon
            className="fs-5 my-auto mx-2 text-lucaLighter"
            icon={faAngleRight}
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: path.campaign ? '1%' : 0,
            }}
          />
          <div
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: `${path.campaign ? titleWidth - 1 : 0}%`,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (path.campaign && path.campaign.id)
                setselected({
                  id: path.campaign.id,
                  tab: 'campaign',
                })
              settabStatus({
                ...tabStatus,
                [activetab]: 'show',
                campaign: 'active',
              })
            }}
            aria-hidden
            className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
          >
            {path.campaign && path.campaign.name}
          </div>
          <FontAwesomeIcon
            className="fs-5 my-auto mx-2 text-lucaLighter"
            icon={faAngleRight}
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: path.adset ? '1%' : 0,
            }}
          />
          <div
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: `${path.adset ? titleWidth - 1 : 0}%`,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (path.adset && path.adset.id)
                setselected({
                  id: path.adset.id,
                  tab: 'adset',
                })
              settabStatus({
                ...tabStatus,
                [activetab]: 'show',
                adset: 'active',
              })
            }}
            aria-hidden
            className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
          >
            {path.adset && path.adset.name}
          </div>
          <FontAwesomeIcon
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: path.ad ? '1%' : 0,
            }}
            className="fs-5 my-auto mx-2 text-lucaLighter"
            icon={faAngleRight}
          />
          <div
            style={{
              transition: 'width .5s',
              transitionTimingFunction: 'ease-in',
              width: `${path.ad ? titleWidth - 1 : 0}%`,
              cursor: 'pointer',
            }}
            onClick={() => {
              settabStatus({
                ...tabStatus,
                [activetab]: 'show',
                ad: 'active',
              })
            }}
            aria-hidden
            className="my-auto text-lucaDark fw-bold App-oneLineEllipsis"
          >
            {path.ad && path.ad.name}
          </div>
        </Col>
        <Col
          xs={2}
          className="btn btn-lucaWhite ms-auto"
          role="button"
          onClick={() => {
            setshowInsight(!showInsight)
          }}
          aria-hidden
        >
          {showInsight ? (
            <>
              <FontAwesomeIcon
                icon={faEyeSlash}
                className="my-auto fs-7 px-0"
              />
              &ensp;隱藏成效
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faEye} className="my-auto fs-7 px-0" />
              &ensp;顯示成效
            </>
          )}
        </Col>
      </Row>
      <Row className="p-2 px-3 pb-4 h-75 flex-grow-1">
        <Container
          fluid
          className="h-100 d-flex flex-column"
          style={{ overflowY: 'hidden' }}
        >
          {account.account_id && (
            <>
              <Row className="p-2">
                <ToolBar
                  setting={{
                    leftTools: hasToolBar
                      ? [
                          {
                            id: 'create',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon title="新 建" icon={faPlus} />
                            ),
                            method: () => handleModalState('createOpen', true),
                          },
                          {
                            id: 'edit',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon title="編 輯" icon={faEdit} />
                            ),
                            method: () => {
                              if (checkedList.length === 1) handleEditShow()
                              else {
                                handleShowWarn(
                                  {
                                    title: '系 統 提 示',
                                    titleEng: 'The system prompt',
                                    img: faExclamationCircle,
                                    warn: (
                                      <h5 className="text-danger">
                                        {checkedList.length === 0
                                          ? '提示：請勾選至少一項內容'
                                          : '提示：請勿勾選超過一項內容'}
                                      </h5>
                                    ),
                                  },
                                  'warn'
                                )
                              }
                            },
                          },
                          {
                            id: 'duplicate',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon title="複 製" icon={faCopy} />
                            ),
                            method: () => {
                              if (checkedList.length !== 0)
                                handleModalState('copyOpen', true)
                              else noSelectedError()
                            },
                          },
                          {
                            id: 'all',
                            type: 'button',
                            icon: (
                              <ToolbarAll className="selecAll" title="全 選" />
                            ),
                            method: () => {
                              if (
                                checkedList.length === datas[activetab].length
                              )
                                setcheckedList([])
                              else
                                setcheckedList(
                                  datas[activetab].map((i) => i.id)
                                )
                            },
                          },
                        ]
                      : [],
                    rightTools: hasToolBar
                      ? [
                          {
                            id: 'start',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon
                                title="開 始 投 放"
                                icon={faPlay}
                              />
                            ),
                            method: () => {
                              if (checkedList.length === 0) noSelectedError()
                              else {
                                handleShowWarn(
                                  {
                                    img: faExclamationCircle,
                                    title: '開 始 投 放',
                                    titleEng: 'Start the Advertisting(s)',
                                    text: (
                                      <h5 className="text-lucaDark">
                                        是否開始投放？
                                      </h5>
                                    ),
                                    warn: (
                                      <>
                                        <h5 className="text-danger lh-lg">
                                          提示：開始廣告投放將產生相關費用。
                                        </h5>
                                        {datas[activetab]
                                          .filter((i) =>
                                            checkedList.includes(i.id)
                                          )
                                          .map((i) => (
                                            <p key={i.id}>{i.name}</p>
                                          ))}
                                      </>
                                    ),
                                    shouldReturn: true,
                                  },
                                  'start'
                                )
                              }
                            },
                          },
                          {
                            id: 'pause',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon
                                title="暫 停 投 放"
                                icon={faPause}
                              />
                            ),
                            method: () => {
                              if (checkedList.length === 0) noSelectedError()
                              else {
                                handleShowWarn(
                                  {
                                    img: faExclamationCircle,
                                    title: '暫 停 投 放',
                                    titleEng: 'Pause the Advertisting(s)',
                                    text: (
                                      <h5 className="text-lucaDark">
                                        是否暫停投放？
                                      </h5>
                                    ),
                                    warn: (
                                      <>
                                        <h5 className="text-danger lh-lg">
                                          提示：暫停投放將重置廣告學習效果。
                                        </h5>
                                        {datas[activetab]
                                          .filter((i) =>
                                            checkedList.includes(i.id)
                                          )
                                          .map((i) => (
                                            <p key={i.id}>{i.name}</p>
                                          ))}
                                      </>
                                    ),
                                    shouldReturn: true,
                                  },
                                  'pause'
                                )
                              }
                            },
                          },
                          {
                            id: 'delete',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon
                                title="刪 除"
                                icon={faTrashAlt}
                              />
                            ),
                            method: () => {
                              if (checkedList.length === 0) noSelectedError()
                              else {
                                handleShowWarn(
                                  {
                                    img: faExclamationCircle,
                                    title: '刪 除 廣 告',
                                    titleEng: 'Delete the Advertisting(s)',
                                    text: (
                                      <h5 className="text-lucaDark">
                                        是否刪除廣告？
                                      </h5>
                                    ),
                                    warn: (
                                      <h5 className="text-danger lh-lg">
                                        提示：廣告一經刪除將無法復原。
                                      </h5>
                                    ),
                                    shouldReturn: true,
                                  },
                                  'delete'
                                )
                              }
                            },
                          },
                          {
                            id: 'reset',
                            type: 'button',
                            disabled: true,
                            icon: (
                              <FontAwesomeIcon title="重 置" icon={faRecycle} />
                            ),
                            method: () => {
                              handleShowWarn(
                                {
                                  img: faExclamationCircle,
                                  title: '重 置 成 效',
                                  titleEng: 'Reset Insights',
                                  text: (
                                    <h5 className="text-lucaDark">
                                      是否重置成效？
                                    </h5>
                                  ),
                                  warn: (
                                    <h5 className="text-danger lh-lg">
                                      提示：成效重置後將重新抓取，會耗費較長時間
                                    </h5>
                                  ),
                                  shouldReturn: true,
                                },
                                'reset'
                              )
                            },
                          },
                        ]
                      : [],
                    search: (
                      <SearchBar
                        setting={{
                          title: '輸入關鍵字搜尋廣告...',
                          name: 'search',
                          size: 'sm',
                          extraClass: ` ${
                            hasToolBar ? 'h-75' : 'h-100'
                          } align-self-center`,
                          // action: <FontAwesomeIcon icon={faSearch} />,
                          tempSearch,
                          settempSearch,
                          method: () =>
                            handleFilterChange({
                              target: {
                                name: 'search',
                                value: tempSearch,
                              },
                            }),
                        }}
                      />
                    ),
                    afterTools: showInsight
                      ? [
                          {
                            id: 'delete',
                            type: 'menu',
                            icon: (
                              <Dropmenu
                                setting={{
                                  name: insightTypes.find(
                                    (i) => i.value === insightType
                                  ).name,
                                  content: insightTypes,
                                  handleClick: (value) => {
                                    setinsightType(insightTypes[value].value)
                                  },
                                  disabled: !showInsight,
                                  title: '切 換 模 式',
                                }}
                              />
                            ),
                            method: () => {},
                          },
                        ]
                      : [],
                  }}
                />
              </Row>
              {showInsight && (
                <Row>
                  <Col
                    xs={4}
                    className="d-flex justify-content-left my-auto datePicker-noBorder"
                    style={{ zIndex: '150' }}
                    title="選 擇 日 期 區 間"
                  >
                    <LucaDatePicker
                      setting={{
                        iconClassName: 'text-light',
                        since,
                        until,
                        setSince: (date) => {
                          settimeRange({ ...timeRange, since: date })
                          handleQueryChange({ since: date })
                        },
                        setUntil: (date) => {
                          settimeRange({ ...timeRange, until: date })
                          handleQueryChange({ until: date })
                        },
                      }}
                    />
                  </Col>
                  <Col xs={8} className="d-flex justify-content-end my-auto">
                    {auth.accounts.find((a) => a.business_id === 1) &&
                      hasToolBar && (
                        <Button
                          className="btn-lucaWhite3 me-2 my-2"
                          onClick={handleAdsRuleShow}
                          disabled
                        >
                          廣告優化
                        </Button>
                      )}
                    {auth.accounts.find((a) => a.business_id === 1) &&
                      hasToolBar && (
                        <Button
                          className="btn-lucaWhite3 me-2 my-2"
                          onClick={handleRuleShow}
                          disabled
                        >
                          含潤調節
                        </Button>
                      )}
                    <Button
                      className="btn-lucaWhite3 me-2 my-2"
                      onClick={() => handleModalState('breakdownOpen', true)}
                    >
                      分析項目
                    </Button>
                    <Button
                      className="btn-lucaWhite3 my-2"
                      onClick={() => handleModalState('fieldSelectOpen', true)}
                    >
                      設定欄位組合
                    </Button>
                    <div className="w-drop-limit my-auto">
                      <Dropmenu
                        setting={{
                          name:
                            selectedPreset !== -1
                              ? presets.filter((p) => p.provider === provider)[
                                  selectedPreset
                                ].name
                              : '預設欄位',
                          title: '切 換 欄 位 組 合',
                          content: presets.filter(
                            (p) => p.provider === provider
                          ),
                          handleClick: (value) => {
                            handleFieldSelectClose(value)
                          },
                        }}
                      />
                    </div>
                    {!hasToolBar && (
                      <Dropmenu
                        setting={{
                          name: insightTypes.find(
                            (i) => i.value === insightType
                          ).name,
                          content: insightTypes,
                          handleClick: (value) => {
                            setinsightType(insightTypes[value].value)
                          },
                          disabled: !showInsight,
                          title: '切 換 模 式',
                        }}
                      />
                    )}
                    <Button
                      className="btn-lucaWhite3 my-2"
                      onClick={() => {
                        handleShowWarn(
                          {
                            title: '系 統 提 示',
                            titleEng: 'The system prompt',
                            img: faExclamationCircle,
                            warn: (
                              <h5 className="text-lucaDark">
                                確定下載當前資料嗎
                              </h5>
                            ),
                            footer: (
                              <>
                                <Button
                                  variant="secondary"
                                  onClick={() => handleWarnClose(false)}
                                >
                                  取 消
                                </Button>
                                <Button
                                  variant="luca"
                                  onClick={() => handleWarnClose('csv')}
                                >
                                  確 定
                                </Button>
                              </>
                            ),
                          },
                          'csv'
                        )
                      }}
                      title="匯 出 資 料"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </Col>
                </Row>
              )}
              <Row
                className="pt-2 AdsManagerForm w-100 flex-nowrap"
                style={{ height: 'auto' }}
              >
                <Col
                  style={{ height: '60px' }}
                  xs={tabStatus.campaign === 'active' && showInsight && 8}
                >
                  <Card
                    className={`py-2 ms-2 tab1 ${
                      tabStatus.campaign === 'active' && 'AdsManagerActive'
                    }`}
                    style={{ height: '60px' }}
                    onClick={() => {
                      settabStatus({
                        ...tabStatus,
                        [activetab]: 'show',
                        campaign: 'active',
                      })
                      setcheckedList([])
                    }}
                    title="點 擊 可 切 換 廣 告 目 錄"
                  >
                    <Card.Title>廣 告 活 動</Card.Title>
                    <p>Campaign</p>
                  </Card>
                </Col>
                <Col
                  style={{ height: '60px' }}
                  xs={tabStatus.adset === 'active' && showInsight && 8}
                >
                  <Card
                    className={`py-2 tab2 ${
                      tabStatus.adset === 'active' && 'AdsManagerActive'
                    }`}
                    style={{ height: '60px' }}
                    onClick={() => {
                      settabStatus({
                        ...tabStatus,
                        [activetab]: 'show',
                        adset: 'active',
                      })
                      setcheckedList([])
                    }}
                    title="點 擊 可 切 換 廣 告 目 錄"
                  >
                    <Card.Title>廣 告 設 定</Card.Title>
                    <p>Advertising Set</p>
                  </Card>
                </Col>
                <Col
                  style={{ height: '60px' }}
                  xs={tabStatus.ad === 'active' && showInsight && 8}
                >
                  <Card
                    className={`py-2 me-2 tab3 ${
                      tabStatus.ad === 'active' && 'AdsManagerActive'
                    }`}
                    style={{ height: '60px' }}
                    onClick={() => {
                      settabStatus({
                        ...tabStatus,
                        [activetab]: 'show',
                        ad: 'active',
                      })
                      setcheckedList([])
                    }}
                    title="點 擊 可 切 換 廣 告 目 錄"
                  >
                    <Card.Title>廣 告 內 容</Card.Title>
                    <p>Advertising</p>
                  </Card>
                </Col>
              </Row>
              <Row
                className="pt-3 pb-4 AdsManagerForm w-100 overflow-hidden flex-grow-1 flex-nowrap"
                style={{ oveflowY: 'scroll' }}
              >
                {prepared ? (
                  <>
                    {['campaign', 'adset', 'ad']
                      .filter(
                        (level) =>
                          tabStatus[level] === 'active' ||
                          (tabStatus[level] === 'show' && !showInsight)
                      )
                      .map((level) => (
                        <div
                          key={level}
                          className="position-relative"
                          style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            // width: '33%',
                            width: !showInsight || showList ? '33%' : '20px',
                            transition: 'width .5s',
                            // transitionTimingFunction: 'ease-in',
                          }}
                        >
                          {associatedInfo[level] &&
                          (!showInsight || showList) ? (
                            <>
                              {selected.id &&
                                associatedInfo.associated[level].length > 0 && (
                                  <div
                                    className="w-95 mx-2 position-absolute"
                                    style={{
                                      height: `${
                                        (whiteHeight.height +
                                          whiteHeight.marginBottom) *
                                          associatedInfo.associated[level]
                                            .length -
                                        whiteHeight.marginBottom
                                      }px`,
                                      transition: 'height .3s',
                                      transitionTimingFunction: 'ease-in',
                                      backgroundColor: 'transparent',
                                      border: '3px solid #fff',
                                      borderRadius: '0.25rem',
                                      opacity: '0.8',
                                      zIndex: '100',
                                      pointerEvents: 'none',
                                    }}
                                  />
                                )}
                              <Lists
                                setting={{
                                  isActive: tabStatus[level] === 'active',
                                  datas: associatedInfo.associated[level]
                                    .concat(associatedInfo[level])
                                    .filter((a) => wordSearch(a.name, search)),
                                  setactiveid: (id) => {
                                    settabStatus({
                                      ...tabStatus,
                                      [activetab]: 'show',
                                      [level]: 'active',
                                    })
                                    setcheckedList([])
                                    setselected({
                                      id,
                                      tab: id ? level : '',
                                    })
                                  },
                                  active,
                                  activeid: selected.id,
                                  ref: whiteRef,
                                  whiteHeight,
                                  setwhiteHeight,
                                  checkedList,
                                  handleCheck,
                                }}
                              />
                            </>
                          ) : (
                            <div
                              className="w-100 mx-2 card"
                              style={{
                                minHeight: '100%',
                              }}
                            />
                          )}
                        </div>
                      ))}
                    {showInsight && (
                      <div
                        className="position-relative h-100"
                        style={{
                          width: '1px',
                        }}
                      >
                        <div
                          className="d-flex h-100 position-absolute"
                          style={{
                            left: showList ? '-0.1rem' : '-1rem',
                            zIndex: '1000',
                            cursor: showList ? 'default' : 'pointer',
                          }}
                          title={
                            showList ? '收 合 廣 告 列 表' : '展 開 廣 告 列 表'
                          }
                          onClick={() => !showList && setshowList(!showList)}
                          aria-hidden
                        >
                          {showList ? (
                            <FontAwesomeIcon
                              // width={width}
                              className="m-auto align-self-center text-luca"
                              src={CircleBothside}
                              style={{
                                cursor: 'pointer',
                                // color: '#fff',
                                height: '1.25rem',
                              }}
                              onClick={() => setshowList(!showList)}
                              title="收 合 廣 告 列 表"
                              icon={faCircleChevronLeft}
                            />
                          ) : (
                            <Image
                              // width={width}
                              className="m-auto align-self-center"
                              src={CircleBothside}
                              style={{
                                cursor: 'pointer',
                                height: '1.25rem',
                                fill: '#317985',
                              }}
                              onClick={() => setshowList(!showList)}
                              title="展 開 廣 告 列 表"
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {showInsight && (
                      <Insights
                        setting={{
                          auth,
                          account_id: account.setting.account_id,
                          fields,
                          selectedField,
                          setselectedField,
                          breakdowns,
                          dateBreakdown,
                          datas: datas[activetab],
                          activetab,
                          activeid: selected.id,
                          // adClusters: .map((d) => ({
                          //         level: activetab,
                          //         id:
                          //           d[`${activetab}_id`] ||
                          //           d[`${activetab}.id`] ||
                          //           d.id,
                          //       })),
                          insightType,
                          sort,
                          search,
                          setsort,
                          setcsvData,
                          provider,
                          checkedList,
                          selected,
                          timeRange,
                          setactiveid: (id) => {
                            setcheckedList([])
                            setselected({
                              id,
                              tab: activetab,
                            })
                          },
                          hasWheel: true,
                          labelType: 'hide',
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="h-100 m-auto d-flex justify-content-center">
                    <Spinner
                      className="my-auto"
                      animation="border"
                      variant="luca"
                      size="sm"
                      style={{
                        animation: 'spinner-border 1.5s linear infinite',
                      }}
                    />
                    <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
                  </div>
                )}
              </Row>
              {/* )} */}
            </>
          )}
        </Container>
        <FieldsDialog
          show={fieldSelectOpen}
          setting={{
            handleClose: handleFieldSelectClose,
            provider,
            userRole: 'admin',
            size: 'xl',
            createPreset,
            deletePreset,
            presets,
          }}
        />
        <BreakdownDialog
          show={breakdownOpen}
          setting={{
            handleClose: handleBreakdownClose,
            handleDateBreakdownClose,
            dateBreakdown,
            provider,
            size: 'md',
            breakdown,
          }}
        />
        <AccountRule
          setting={{
            size: 'xl',
            show: showRule,
            rules,
            newRule,
            setNewRule,
            handleResetNewRule,
            handleRuleDelete,
            handleRuleEdit,
            handleClose: handleRuleClose,
            campaigns: campaign
              ? campaign.map((c) => ({
                  name: c.name,
                  id: c.id,
                  value: c.id,
                }))
              : [],
          }}
        />
        {showAdsRule && (
          <AdsRule
            setting={{
              size: 'xl',
              show: showAdsRule,
              rules: adsRules,
              newRule: newAdsRule,
              handleAddRule: handleAddAdsRule,
              setNewRule: setNewAdsRule,
              handleResetNewRule: handleResetNewAdsRule,
              handleRuleCreate: handleAdsRuleCreate,
              handleRuleDelete: handleAdsRuleDelete,
              handleClose: handleAdsRuleClose,
              selectSet,
              handleSetChange,
              handleChange: handleAdsRuleEdit,
              datas,
              provider,
            }}
          />
        )}
        {hasToolBar && (
          <AdsDialogs
            setting={{
              ...modalState,
              datas,
              checkedList,
              provider,
              activetab,
              preview,
              deliveryEstimate,
              selectSet,
              handleSetChange,
              handleEditClose,
              handleCreateClose,
              handleCopyClose,
            }}
          />
        )}
        <ConfirmForm
          setting={{
            ...showWarn,
            size: 'md',
            warning: (
              <div className="text-center">
                <div className="mb-4 mt-2">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={showWarn.img}
                  />
                </div>
                {showWarn.text}
                {showWarn.warn}
              </div>
            ),
            handleClose: () => handleWarnClose(showWarn.target),
          }}
        />
      </Row>
    </>
  )
}

AdsManager.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AdsManager
