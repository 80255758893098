import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import LoadingButton from './LoadingButton'

function ConfirmForm(props) {
  const { setting } = props
  const {
    size,
    title,
    titleEng,
    closeButton,
    warning,
    show,
    shouldMatch,
    shouldReturn,
    handleClose,
    timer,
    hasFooter,
    footer,
    formClassName = 'pt-3 pb-5',
  } = setting

  const [text, settext] = useState('')
  const returnable = !shouldMatch || shouldMatch === text
  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose(false)}
    >
      {title && (
        <Modal.Header
          className="AccFormModal justify-content-center text-center pt-4"
          {...{ closeButton }}
        >
          <Modal.Title>
            <h4>{title}</h4>
            <p>{titleEng}</p>
          </Modal.Title>
        </Modal.Header>
      )}
      {warning && (
        <Modal.Body className="d-flex AccformCard">
          <div className="assPermis w-100">
            <Form className={`px-2 Form-card flex-grow-1 ${formClassName}`}>
              <Form.Group className="px-5 lh-md">
                <Form.Label className="w-100">{warning}</Form.Label>
                {shouldMatch && (
                  <Form.Control
                    type="text"
                    value={text}
                    onChange={(event) => settext(event.target.value)}
                  />
                )}
              </Form.Group>
            </Form>
            {timer > 0 && (
              <h3 className="text-center py-4">{timer.toFixed(2)}</h3>
            )}
          </div>
        </Modal.Body>
      )}

      {(hasFooter === undefined || hasFooter) && (
        <Modal.Footer className="sendForm justify-content-center py-3">
          {footer || (
            <>
              <Button variant="secondary" onClick={() => handleClose(false)}>
                返 回
              </Button>
              <LoadingButton
                variant="luca"
                disabled={!returnable}
                onClick={() => handleClose(shouldReturn)}
                btnText="送 出"
              />
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

ConfirmForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ConfirmForm
