import React, { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faTrashAlt,
  faFlushed,
  faCopy,
  faEdit,
  faReply,
  faSpinner,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faCircleUp } from '@fortawesome/free-regular-svg-icons'
import { ListGroup, ListGroupItem, Modal, Spinner } from 'react-bootstrap'
import { SelectBar, SearchBar, Datatable, ConfirmForm } from '..'
import { SocketContext } from '../ContextProvider'
import { processReport } from '../../services/insightProcesser'
import apiService from '../../services/apiService'
// import { wordSearch } from '../services/lucaFunctions'
import importreports from './reports.json'

function ImportList({ setting }) {
  const { show, handleClose } = setting
  const [importing, setimporting] = useState([])
  useEffect(() => {
    const getImporting = async () => {
      const res = await apiService.getContent(
        'https://nautilus.punwave.com/dev/report/bybusiness?id=5df1e884033ac478a23d0257'
      )
      console.log(res)
      setimporting(importreports)
    }
    getImporting()
  }, [])
  console.log(importing)
  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      size="xl"
      onHide={handleClose}
    >
      <Modal.Header closeButton>匯入報表</Modal.Header>
      <Modal.Body>
        {importing.length ? (
          <div className="m-auto d-flex flex-column overflow-scroll my-5">
            <Row>
              <Col xs={8}>名稱</Col>
              {/* <Col>日期</Col>
              <Col>頻率</Col>
              <Col>狀態</Col> */}
              <Col>建立者</Col>
            </Row>
            <ListGroup>
              {importing.slice(0, 100).map(
                ({
                  name,
                  // startDate,
                  // endDate,
                  // language,
                  // frequency,
                  // emails,
                  // views: ['621ddef330ed14004b38cbcf'],
                  // isActive: false,
                  // autostart: false,
                  // autostop: false,
                  // business: '5df1e884033ac478a23d0257',
                  createdBy,
                  // status,
                  // deleted: false,
                  // id,
                }) => (
                  <ListGroupItem action>
                    <Row className="text-nowrap">
                      <Col xs={8}>{name}</Col>
                      {/* <Col>
                      {startDate} - {endDate}
                    </Col>
                    <Col>{frequency}</Col> */}
                      {/* <Col>{status}</Col> */}
                      <Col>{createdBy}</Col>
                    </Row>
                  </ListGroupItem>
                )
              )}
            </ListGroup>
          </div>
        ) : (
          <div className="m-auto d-flex my-5 w-100 h-100">
            <span className="text-luca m-auto ms-2">
              <Spinner
                className="m-auto"
                animation="border"
                variant="luca"
                size="sm"
                style={{
                  animation: 'spinner-border 1.5s linear infinite',
                }}
              />
              資料載入中...
            </span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

function ReportList(props) {
  const { setting } = props
  const { auth, handleActionChange } = setting

  const [reports, setreports] = useState(false)
  const [reportStatus, setreportStatus] = useState({})
  console.log(reportStatus)
  const socket = useContext(SocketContext)
  useEffect(() => {
    if (socket !== null) {
      console.log('send message')
      socket.emit('reportStatus', {
        id: auth.auth.user_id,
      })
      socket.on('reportStatus', ({ results }) => {
        if (results) setreportStatus(results)
      })
    }
  }, [socket])

  const [tempSearch, settempSearch] = useState('')
  const [filter, setfilter] = useState({
    subscirbe: '',
    sort: JSON.stringify({ field: 'created_on', order: 'desc' }),
    search: '',
  })
  const { sort, search } = filter
  const handleFilterChange = (e) =>
    setfilter({
      ...filter,
      [e.target.name]: e.target.value,
      search: tempSearch,
    })
  useEffect(() => {
    if (!auth.account.business_id) return
    const getReports = async () => {
      const res = await apiService.data({
        path: `luca/report/business/${auth.account.business_id}`,
        method: 'get',
      })
      if (!res.error) setreports(res)
    }
    getReports()
  }, [])
  const filteredReports = useMemo(
    () =>
      reports
        ? reports
            .filter(
              (r) =>
                !filter.subscirbe ||
                (r.setting && r.setting.subscribeStatus === filter.subscirbe)
            )
            .sort((a, b) => {
              const { field, order } = JSON.parse(sort)
              return order === 'aesc'
                ? a[field] - b[field] || new Date(a[field]) - new Date(b[field])
                : b[field] - a[field] || new Date(b[field]) - new Date(a[field])
            })
        : [],
    [search, sort, reports]
  )

  const handleDownload = async (id) => {
    const res = await apiService.data({
      path: `luca/report/generate/${id}`,
      method: 'get',
    })
    console.log(res)
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value) => {
    setshowWarn({
      ...value,
      show: true,
    })
  }
  const handleWarnClose = async (value, action) => {
    if (value !== false) {
      if (action === 'delete') {
        const res = await apiService.data({
          path: `luca/report/${value}`,
          method: 'delete',
        })
        if (!res.error) setreports(reports.filter((r) => r.report_id !== value))
      } else if (action === 'duplicate') {
        const target = reports.find((r) => r.report_id === value)
        if (target) {
          const res = await processReport({
            report: target.setting,
            auth,
          })
          if (!res.error) setreports(reports.concat(res))
        }
      }
    }
    setshowWarn({
      ...showWarn,
      show: false,
    })
  }

  const [showImport, setshowImport] = useState(false)

  return (
    <>
      <Row className="pb-1 px-2 ps-3">
        <Col
          xs={{ span: '2', offset: '2' }}
          role="button"
          className="mt-auto btn btn-lucaWhite ms-1 mb-2"
          onClick={() => handleActionChange({ action: 'ADMReporter' })}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faReply} className="my-auto fs-7 px-0" />
          &ensp;投放數據總覽
        </Col>
        <Col xs={{ span: '4', offset: '2' }} className="p-4 pageTitle my-auto">
          <h4 className="pt-4 pb-1">成 效 報 表 匯 出 列 表</h4>
          <p>Advertising Reports List</p>
          <p className="pageSubtitle pt-4">
            此 頁 為 各 式 媒 體 平 台 投 放 預 算 申 請 及 紀 錄
          </p>
        </Col>
        <Col
          xs={2}
          role="button"
          className="mt-auto btn btn-lucaWhite ms-auto me-3 ps-1 mb-2"
          aria-hidden="true"
          title="建 立 新 報 表"
          onClick={() => handleActionChange({ action: 'AdsReporter' })}
        >
          建立新報表&ensp;
          <FontAwesomeIcon
            icon={faReply}
            style={{ transform: 'scaleX(-1)' }}
            className="my-auto fs-7 px-0"
          />
        </Col>
      </Row>

      <Row className="mx-auto pb-0 px-2 w-100">
        <Col xs="3" className="ps-0">
          <SelectBar
            setting={{
              method: (e) => handleFilterChange(e.target.value),
              value: sort,
              placeholder: '訂閱狀態',
              content: [
                {
                  name: '已訂閱',
                  value: JSON.stringify({
                    field: 'subscribe',
                    order: true,
                  }),
                },
                {
                  name: '未訂閱',
                  value: JSON.stringify({
                    field: 'subscribe',
                    order: false,
                  }),
                },
              ],
            }}
          />
        </Col>
        <Col xs="3" className="ps-0">
          <SelectBar
            setting={{
              method: (e) =>
                handleFilterChange({
                  target: {
                    name: 'sort',
                    value:
                      e.target.value ||
                      JSON.stringify({ field: 'name', order: 'desc' }),
                  },
                }),
              value: sort,
              placeholder: '選擇排序',
              content: [
                {
                  name: '排序 － 由最新至最舊',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'desc',
                  }),
                },
                {
                  name: '排序 － 由最舊至最新',
                  value: JSON.stringify({
                    field: 'created_on',
                    order: 'aesc',
                  }),
                },
              ],
            }}
          />
        </Col>
        <Col xs="4" className="px-0">
          <SearchBar
            setting={{
              title: '請輸入搜尋關鍵字...',
              name: 'search',
              tempSearch,
              settempSearch,
              method: () =>
                handleFilterChange({
                  target: {
                    name: 'search',
                    value: tempSearch,
                  },
                }),
            }}
          />
        </Col>
        <Col xs="2" className="d-flex px-0 ps-2">
          <Button
            className="d-flex w-100 justify-content-center"
            variant="luca"
            title="建 立 新 報 表"
            onClick={() => setshowImport(true)}
            // onClick={() => handleActionChange({ action: 'AdsReporter' })}
          >
            匯入報表
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="fs-6 my-auto ms-2"
            />
          </Button>
        </Col>
      </Row>
      <Row className="AdsReportList_table px-2 pt-3 pb-2 h-100 tableLayout-adj tableW-adj overflow-auto">
        <Datatable
          setting={{
            hasCheckBox: false,
            hasButton: false,
            hasPagination: true,
            pageSize: 5,
            headers: [
              '報 表 名 稱',
              '建 立 日 期',
              '建 立 者',
              '分 頁 數 量',
              '訂 閱',
              '最 後 更 新 時 間',
              '功 能',
            ],
            content: filteredReports
              .concat(
                Array(
                  filteredReports.length % 5 > 0
                    ? 5 - (filteredReports.length % 5)
                    : 0
                ).fill({
                  fill: true,
                })
              )
              .map((m) => {
                const updating =
                  reportStatus[m.report_id] &&
                  reportStatus[m.report_id].status === 'updating'
                const scheduled = m.setting && m.setting.subscribeStatus
                return m.fill
                  ? {
                      name: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      createdAt: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      user: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      count: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      subscirbe: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      last_updated: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                      func: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">- -</span>
                          </div>
                        ),
                      },
                    }
                  : {
                      name: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">{m.setting.name}</span>
                          </div>
                        ),
                      },
                      createdAt: {
                        value: moment(m.created_on).format('YYYY-MM-DD'),
                      },
                      user: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">{m.user_name}</span>
                          </div>
                        ),
                      },
                      count: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {m.setting.views.length}
                            </span>
                          </div>
                        ),
                      },
                      subscirbe: {
                        value: (
                          <div className="formsTool w-75 d-flex mx-auto">
                            <span className="m-auto">
                              {/* <FontAwesomeIcon
                                className={`ms-auto align-self-center fs-5 ${
                                  scheduled ? 'text-lucaMid' : 'text-lucaMid'
                                }`}
                                icon={scheduled ? 'faCalendarCheck' : 'faMinus'}
                                title="訂閱中"
                              /> */}
                              <span
                                className={`ms-auto align-self-center fs-6 ${
                                  scheduled ? 'text-lucaMid' : 'text-lucaMid'
                                }`}
                                title="訂閱狀態"
                              >
                                <Form.Switch
                                  className="my-auto"
                                  // label="啟用報表訂閱"
                                  checked={scheduled}
                                  onChange={() => {}}
                                />
                                {/* {scheduled ? '是' : '否'} */}
                              </span>
                            </span>
                          </div>
                        ),
                      },
                      last_updated: {
                        value: updating ? (
                          <div className="m-auto d-flex px-3 justify-content-center">
                            <span className="fs-6 text-luca my-auto">
                              更新中
                            </span>
                            <h5 className="text-luca my-auto fw-bolder fs-6 px-2">
                              {reportStatus[m.report_id].progress}%
                            </h5>
                            <div className="d-flex flex-column spinner-border border-0 LoadingIcon justify-content-center">
                              <FontAwesomeIcon
                                className="fs-6 text-luca align-self-center"
                                icon={faSpinner}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <span className="m-auto">
                              {moment(m.created_on).format('YYYY-MM-DD')}
                              &ensp;
                              <FontAwesomeIcon
                                title="更 新 報 表"
                                icon={faCircleUp}
                                style={{ cursor: 'pointer' }}
                                className="fs-5 text-luca align-self-center btn-lucaIcon2"
                                onClick={() => handleDownload(m.report_id)}
                              />
                            </span>
                          </div>
                        ),
                      },
                      func: {
                        value: (
                          <div
                            className="formsTool"
                            style={
                              updating
                                ? {
                                    cursor: 'wait',
                                  }
                                : {}
                            }
                            title={
                              updating ? '報表目前更新中，請等待更新完成。' : ''
                            }
                          >
                            <Button
                              variant="lucaIcon"
                              disabled={updating}
                              onClick={() => handleDownload(m.report_id)}
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faDownload}
                                title="下 載"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              disabled={updating}
                              onClick={() =>
                                handleActionChange({
                                  action: 'AdsReporter',
                                  selected: m,
                                })
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faEdit}
                                title="編 輯"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              disabled={updating}
                              onClick={() =>
                                handleShowWarn({
                                  ...m,
                                  title: '複 製 報 表',
                                  titleEng: 'Duplicate Report',
                                  img: faFlushed,
                                  text: <h6>是否複製「{m.setting.name}」？</h6>,
                                  shouldReturn: m.report_id,
                                  footer: (
                                    <>
                                      <Button
                                        variant="secondary"
                                        onClick={() => handleWarnClose(false)}
                                      >
                                        取 消
                                      </Button>
                                      <Button
                                        variant="luca"
                                        onClick={() =>
                                          handleWarnClose(
                                            m.report_id,
                                            'duplicate'
                                          )
                                        }
                                      >
                                        確 定
                                      </Button>
                                    </>
                                  ),
                                })
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faCopy}
                                title="複 製"
                              />
                            </Button>
                            <Button
                              variant="lucaIcon"
                              disabled={updating}
                              onClick={() =>
                                handleShowWarn({
                                  ...m,
                                  title: '刪 除 報 表',
                                  titleEng: 'Delete Report',
                                  img: faFlushed,
                                  text: <h6>是否刪除「{m.setting.name}」？</h6>,
                                  shouldReturn: m.report_id,
                                  footer: (
                                    <>
                                      <Button
                                        variant="secondary"
                                        onClick={() => handleWarnClose(false)}
                                      >
                                        取 消
                                      </Button>
                                      <Button
                                        variant="luca"
                                        onClick={() =>
                                          handleWarnClose(m.report_id, 'delete')
                                        }
                                      >
                                        確 定
                                      </Button>
                                    </>
                                  ),
                                })
                              }
                            >
                              <FontAwesomeIcon
                                className="ms-auto align-self-center h5"
                                icon={faTrashAlt}
                                title="刪 除"
                              />
                            </Button>
                          </div>
                        ),
                      },
                      method: () => {},
                    }
              }),
          }}
        />
      </Row>
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="mb-4 mt-2">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
      {showImport && (
        <ImportList
          setting={{
            show: showImport,
            handleClose: () => {
              setshowImport(false)
            },
          }}
        />
      )}
    </>
  )
}

ReportList.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ImportList.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportList
