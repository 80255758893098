import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Dropmenu from '../Dropmenu'

function Percent(props) {
  const { setting } = props
  const { header, target, currency, formatter, names, rate } = setting
  const data = Math.round(setting.data)
  const percent = Math.round(
    ((data * (currency === 'USD' ? rate || 31 : 1)) / target) * 100
  )

  return (
    <div className="d-flex text-start my-2">
      <Col
        xs={4}
        title={header}
        className="text-lucaDark fw-bold text-nowrap App-oneLineEllipsis overflow-hidden pe-3"
      >
        {names ? names[header] || header : header}
      </Col>
      <Col xs={5} className="d-flex ms-auto pe-2">
        <ProgressBar
          className="w-100 rounded-pill fs-8 my-auto"
          style={{ height: '14px' }}
        >
          <ProgressBar
            style={{
              backgroundColor: data < 100 ? '#44778D' : '#3fc3ca',
              transition: '0.5s',
            }}
            now={percent}
            // label={`${percent}%`}
          />
          <ProgressBar className="bg-grey text-lucaLight" now={100 - percent} />
        </ProgressBar>
      </Col>
      <Col xs={1} className="my-auto text-end fs-5 text-luca pe-3 fw-bolder">
        {percent}%
      </Col>
      <Col xs={1} className="my-auto text-end text-nowrap fs-5">
        {formatter(setting.data) && formatter(setting.data).split
          ? formatter(setting.data).split('.')[0]
          : formatter(setting.data)}
      </Col>
      {currency && (
        <Col xs={1} className="my-auto text-end fs-5">
          ({currency})
        </Col>
      )}
    </div>
  )
}

function ReportPercent(props) {
  const { setting } = props
  const { title, header, criterion, datas, formatter, names, rate } = setting
  const [currency, setcurrency] = useState('TWD')

  const target =
    datas.reduce(
      (prev, cur) =>
        prev + cur[header] * (cur.currency === 'USD' ? rate || 31 : 1),
      0
    ) / (currency === 'USD' ? rate || 31 : 1)
  return (
    <Row className="px-1 py-1 h-100">
      <Col className="px-4 py-3 card h-100">
        {title && (
          <h6 className="pt-3 pb-1 text-lucaDark fw-bolder text-start">
            {title}
          </h6>
        )}
        <div className="d-flex text-start my-2">
          <h4 className="pt-3 pb-1 text-lucaDark fw-bolder text-start">
            總花費
          </h4>
          <h3 className="mt-auto mb-0 ms-auto text-end text-nowrap">
            {formatter(target).split
              ? formatter(target).split('.')[0]
              : formatter(target)}
          </h3>
          <Dropmenu
            setting={{
              name: `(${currency})`,
              className: 'mt-auto mb-0',
              title: '切 換 幣 種',
              content: [
                { name: 'TWD', value: 'TWD' },
                { name: 'USD', value: 'USD' },
              ],
              handleClick: (e) => {
                setcurrency(e === 1 ? 'USD' : 'TWD')
              },
            }}
          />
        </div>
        <hr className="hrClass-dashed" />
        {datas.map((data, i) => (
          <Percent
            key={i}
            setting={{
              header: data[criterion],
              data: data[header],
              names,
              currency: data.currency,
              rate,
              target: datas.reduce(
                (prev, cur) =>
                  prev +
                  cur[header] * (cur.currency === 'USD' ? rate || 31 : 1),
                0
              ),
              formatter,
            }}
          />
        ))}
      </Col>
    </Row>
  )
}

Percent.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ReportPercent.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportPercent
