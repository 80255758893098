import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import apiService from '../services/apiService'

function Course() {
  // const { auth, handleLoginShow } = props
  // const navigate = useNavigate()
  // const handleWelcomeClick = () => {
  //   if (auth.status === 'authed') navigate('/')
  //   else handleLoginShow()
  // }
  const [blogs, setblogs] = useState([])
  const getBlogs = async () => {
    const res = await apiService.data({
      path: 'luca/insight/blog',
      method: 'get',
    })
    setblogs(res)
  }
  useEffect(() => {
    getBlogs()
  }, [])
  console.log(blogs)
  return (
    <Container
      fluid
      className="welcomePage d-flex h-100"
      // onClick={handleWelcomeClick}
      style={{ cursor: 'pointer' }}
    >
      <Row className="h-100 w-100">
        {blogs.map((blog) => (
          <Col className="h-75 overflow-hidden mb-3" xs={6}>
            <Card className="p-3 h-100">
              <Card.Img src={blog.imgSrc} />
              <Card.Header>{blog.label}</Card.Header>

              <Card.Body>{blog.content}</Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* <iframe
        width="100%"
        title="course"
        src="https://www.wavenet.com.tw/blog/"
      /> */}
    </Container>
  )
}
// Error.propTypes = {
//   auth: PropTypes.shape().isRequired,
//   handleLoginShow: PropTypes.func.isRequired,
// }

export default Course
