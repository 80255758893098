import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { t } from '../../services/lucaFunctions'

function DateBreakdownDialog(props) {
  const { setting } = props
  const { handledatebreakdownSelect, provider, dateBreakdown, size } = setting

  let breakdowns = []
  switch (provider) {
    case 'facebook':
      breakdowns = ['all_days', 'daily']
      break
    case 'googleads':
      breakdowns = ['all_days', 'daily']
      break
    default:
      break
  }

  return (
    <>
      <Modal.Header
        id="form-dialog-title"
        className="m-auto border-0 h6 text-lucaDark"
      />
      <Modal.Dialog
        className="selectType"
        style={{ zIndex: '1501' }}
        size={size}
      >
        <h6 className="text-center pt-4 text-lucaDark">選擇日期格式</h6>
        <Modal.Body>
          {breakdowns.map((b, index) => (
            <Row key={index}>
              <Col>
                <Form.Check
                  type="radio"
                  value={b}
                  label={t(b)}
                  id={b}
                  checked={dateBreakdown === b}
                  onChange={handledatebreakdownSelect}
                />
              </Col>
            </Row>
          ))}
        </Modal.Body>
        {/* <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => handleClose(false)} variant="secondary">
          取 消
        </Button>
        <Button onClick={() => handleClose(select)} variant="luca">
          確 定
        </Button>
      </Modal.Footer> */}
      </Modal.Dialog>
    </>
  )
}

function BreakdownDialog(props) {
  const { show, setting } = props
  const { handleClose, dateBreakdown, provider, breakdown, size } = setting

  let breakdowns = []
  switch (provider) {
    case 'facebook':
      breakdowns = ['age', 'gender', 'age,gender']
      break
    case 'googleads':
      breakdowns = []
      break
    default:
      break
  }

  const [select, setSelect] = useState(breakdown)
  const handleSelect = (event) => {
    setSelect(event.target.value)
  }
  const [datebreakdownSelect, setdatebreakdownSelect] = useState(dateBreakdown)
  const handledatebreakdownSelect = (event) => {
    setdatebreakdownSelect(event.target.value)
  }
  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose(false)}
    >
      {/* <Modal.Header id="form-dialog-title">選擇分析項目</Modal.Header> */}
      <Modal.Body>
        <Row>
          {provider === 'facebook' && (
            <Col xs={6} className="mx-auto">
              <Modal.Header
                id="form-dialog-title"
                className="m-auto border-0 h6 text-lucaDark"
                closeButton
              />
              <Modal.Dialog className="selectType">
                <h6 className="text-center pt-4 text-lucaDark">選擇分析項目</h6>
                <Modal.Body>
                  <Form.Check
                    type="radio"
                    value=""
                    label="default"
                    id="default"
                    checked={select === ''}
                    onChange={handleSelect}
                  />
                  {breakdowns.map((b, index) => (
                    <Row key={index}>
                      <Col>
                        <Form.Check
                          type="radio"
                          value={b}
                          label={t(b)}
                          id={b}
                          checked={select === b}
                          onChange={handleSelect}
                        />
                      </Col>
                    </Row>
                  ))}
                </Modal.Body>
              </Modal.Dialog>
            </Col>
          )}
          <Col xs={6} className="mx-auto">
            <DateBreakdownDialog
              setting={{
                handledatebreakdownSelect,
                provider: 'facebook',
                dateBreakdown: datebreakdownSelect,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => handleClose(false)} variant="secondary">
          取 消
        </Button>
        <Button
          onClick={() =>
            handleClose({
              breakdown: select,
              dateBreakdown: datebreakdownSelect,
            })
          }
          variant="luca"
        >
          確 定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

BreakdownDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

DateBreakdownDialog.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BreakdownDialog
