import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FlipMove from 'react-flip-move'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faAngleRight,
  faMinusCircle,
  faTimes,
  faShare,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import SelectBar from '../SelectBar'
import Datatable from '../DataTable'
import FacebookInsightFields from './enums/FacebookInsightFields'
import GoogleInsightFields from './enums/GoogleInsightFields'
import { t } from '../../services/lucaFunctions'

const NewRule = forwardRef((props, ref) => {
  const { setting } = props
  const { target, operator, value } = setting
  const { fields, hasHeader, isInvalid, handleChange, handleAddRule } = setting

  return (
    <div ref={ref}>
      {hasHeader && (
        <Row>
          <Col xs={6} className="bg-select-default">
            <small className="text-lucaDark">項目指標</small>
          </Col>
          <Col xs={3} className="bg-select-default ps-0">
            <small className="text-lucaDark">條件</small>
          </Col>
          <Col xs={3} className="bg-select-default ps-0">
            <small className="text-lucaDark">數值</small>
          </Col>
        </Row>
      )}
      <Row className="mb-2 position-relative showPlusIcon">
        <div
          className="position-absolute h-100 d-flex"
          style={{
            left: '-35px',
            width: '35PX',
          }}
        >
          <FontAwesomeIcon
            className="fs-5 me-1 text-luca my-auto invisible"
            title="新增項目指標"
            icon={faPlusCircle}
            onClick={handleAddRule}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
        <Col xs={6} className="bg-select-default">
          {!isInvalid ? (
            <SelectBar
              setting={{
                method: handleChange,
                placeholder: '選擇項目指標',
                name: 'target',
                value: target,
                content: fields.map((f) => ({
                  name: t(f.api || f.name),
                  value: f.name,
                })),
              }}
            />
          ) : (
            <h6
              id="borderHideForm"
              className="m-auto fw-normal text-grey"
              style={{ cursor: 'not-allowed' }}
            >
              - -
            </h6>
          )}
        </Col>
        <Col xs={3} className="bg-select-default ps-0">
          {!isInvalid ? (
            <SelectBar
              setting={{
                method: handleChange,
                placeholder: '選擇條件',
                name: 'operator',
                value: operator,
                content: ['大於', '等於', '小於'].map((o) => ({
                  name: o,
                  value: o,
                })),
              }}
            />
          ) : (
            <h6
              id="borderHideForm"
              className="m-auto fw-normal text-grey"
              style={{ cursor: 'not-allowed' }}
            >
              - -
            </h6>
          )}
        </Col>
        <Col xs={3} className="bg-select-default ps-0">
          {!isInvalid ? (
            <Form.Control
              id="borderHideForm"
              onChange={handleChange}
              name="value"
              value={value}
              placeholder="請輸入數值..."
            />
          ) : (
            <h6
              id="borderHideForm"
              className="m-auto fw-normal text-grey"
              style={{ cursor: 'not-allowed' }}
            >
              - -
            </h6>
          )}
        </Col>
      </Row>
    </div>
  )
})

function Group(props) {
  const { setting } = props
  const {
    newRule,
    handleResetNewRule,
    datas,
    handleChange,
    handleRuleCreate,
    handleAddRule,
    fields,
  } = setting
  const { campaign, adset, ad } = datas
  const { since, until, target, operator, value } = newRule

  return (
    <>
      <Row className="mb-2">
        <h6 className="my-auto text-lucaDark">建立新規則</h6>
      </Row>
      <Row
        className="bg-lucaLighter p-3 py-4 w-100 m-0 scrollbarShow"
        style={{
          maxHeight: '41.5vh',
          overflowY: 'auto',
        }}
      >
        <Row className="ps-5 pb-2 pt-3 text-luca">
          <Col xs={12} className="d-flex px-0">
            <h6 className="text-luca ps-0 my-auto">選擇日期與條件</h6>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className="quesTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  提示：截止時間為當日午夜00點00分
                </Tooltip>
              }
            >
              <div className="my-auto ms-2 h-100">
                <FontAwesomeIcon
                  className="fs-7 btn-lucaIcon"
                  style={{ right: '10', top: '50', bottom: '50' }}
                  icon={faExclamationCircle}
                />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
        {/* <Row className="ps-5 pb-3 text-luca h6">選擇條件</Row> */}
        <Row className="ps-5 mb-2">
          <Col xs={4} className="bg-select-default">
            <small className="text-lucaDark">日期區間</small>
            <div
              className="d-flex justify-content-left my-auto"
              style={{ zIndex: '150' }}
              title="選 擇 日 期 區 間"
            >
              <DatePicker
                selected={since}
                onChange={(date) =>
                  handleChange({ target: { name: 'since', value: date } })
                }
              />
              <FontAwesomeIcon
                className="fs-5 my-auto mx-2 text-luca"
                icon={faAngleRight}
              />
              <DatePicker
                selected={until}
                onChange={(date) =>
                  handleChange({ target: { name: 'until', value: date } })
                }
              />
            </div>
          </Col>
          <Col xs={4} className="bg-select-default">
            <small className="text-lucaDark">項目指標</small>
            <SelectBar
              setting={{
                method: handleChange,
                placeholder: '選擇項目指標',
                name: 'target',
                value: target,
                content: fields.map((f) => ({
                  name: t(f.api || f.name),
                  value: f.name,
                })),
              }}
            />
          </Col>
          <Col xs={2} className="bg-select-default ps-0">
            <small className="text-lucaDark">條件</small>
            <SelectBar
              setting={{
                method: handleChange,
                placeholder: '選擇條件',
                name: 'operator',
                value: operator,
                content: ['最大', '最小'].map((o) => ({
                  name: o,
                  value: o,
                })),
              }}
            />
          </Col>
          <Col xs={2} className="bg-select-default ps-0">
            <small className="text-lucaDark">動作</small>
            <SelectBar
              setting={{
                method: handleChange,
                placeholder: '選擇動作',
                name: 'value',
                value,
                content: ['關閉', '暫停'].map((o) => ({
                  name: o,
                  value: o,
                })),
              }}
            />
          </Col>
        </Row>

        <Row className="ps-5 pb-2 pt-3 text-luca">
          <Col xs={12} className="d-flex px-0">
            <h6 className="text-luca ps-0 my-auto">新增群組廣告</h6>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className="quesTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  提示：系統將自動過濾未填寫完整之欄位
                </Tooltip>
              }
            >
              <div className="my-auto ms-2 h-100">
                <FontAwesomeIcon
                  className="fs-7 btn-lucaIcon"
                  style={{ right: '10', top: '50', bottom: '50' }}
                  icon={faExclamationCircle}
                />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="pe-2">
          <Col className="pe-0">
            <FlipMove
              easing="ease-in"
              enterAnimation="accordionVertical"
              leaveAnimation="none"
            >
              {newRule.ads.map((a, i) => (
                <Row
                  key={i}
                  className="pb-3 justify-content-center w-100 position-relative showPlusIcon"
                >
                  <div
                    className="position-absolute h-100 d-flex"
                    style={{
                      left: '-5px',
                      width: '35PX',
                    }}
                  >
                    <FontAwesomeIcon
                      className="fs-5 me-1 text-luca my-auto invisible"
                      title="新增群組廣告"
                      icon={faPlusCircle}
                      onClick={() => handleAddRule('ads', i)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>

                  <Col className="ps-5 pe-0 bg-select-default">
                    <small className="text-lucaDark">廣告活動</small>
                    <SelectBar
                      setting={{
                        method: (event) => handleChange(event, 'ads', i),
                        placeholder: '選擇廣告活動...',
                        name: 'campaignId',
                        value: a.campaignId,
                        content: campaign.map((item) => ({
                          name: item.name,
                          value: item.id,
                        })),
                      }}
                    />
                  </Col>
                  <FontAwesomeIcon
                    className="fs-5 my-auto text-luca mx-3 pt-4"
                    icon={faAngleRight}
                    style={{
                      transition: '.5s',
                      transitionTimingFunction: 'ease-in',
                      width: '2%',
                    }}
                  />
                  <Col className="d-flex px-0">
                    <div className="w-100 bg-select-default">
                      <small className="text-lucaDark">廣告設定</small>
                      {a.campaignId === '' ? (
                        <h6
                          id="borderHideForm"
                          className="m-auto fw-normal text-grey"
                          style={{ cursor: 'not-allowed' }}
                        >
                          - -
                        </h6>
                      ) : (
                        <SelectBar
                          setting={{
                            method: (event) => handleChange(event, 'ads', i),
                            placeholder: '- -',
                            name: 'adsetId',
                            value: a.adsetId,
                            content: adset.map((item) => ({
                              name: item.name,
                              value: item.id,
                            })),
                          }}
                        />
                      )}
                    </div>
                  </Col>
                  <FontAwesomeIcon
                    className="fs-5 my-auto text-luca mx-3 pt-4"
                    icon={faAngleRight}
                    style={{
                      transition: '.5s',
                      transitionTimingFunction: 'ease-in',
                      width: '2%',
                    }}
                  />
                  <Col className="d-flex px-0">
                    <div className="w-100 bg-select-default">
                      <small className="text-lucaDark">廣告內容</small>
                      {a.campaignId === '' || a.adsetId === '' ? (
                        <h6
                          id="borderHideForm"
                          className="m-auto fw-normal text-grey"
                          style={{ cursor: 'not-allowed' }}
                        >
                          - -
                        </h6>
                      ) : (
                        <SelectBar
                          setting={{
                            method: (event) => handleChange(event, 'ads', i),
                            placeholder: '- -',
                            name: 'adId',
                            value: a.adId,
                            content: ad.map((item) => ({
                              name: item.name,
                              value: item.id,
                            })),
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
            </FlipMove>
          </Col>
        </Row>
        <div
          style={{ borderTop: '1px solid #ccc' }}
          className="d-flex justify-content-center mt-2 pt-3"
        >
          <Button
            size="sm"
            variant="secondary"
            className="me-2"
            onClick={handleResetNewRule}
          >
            清 除
          </Button>
          <Button size="sm" variant="luca" onClick={handleRuleCreate}>
            建 立
          </Button>
        </div>
      </Row>
    </>
  )
}

function Optimize(props) {
  const { setting } = props
  const {
    newRule,
    handleAddRule,
    handleResetNewRule,
    selectSet,
    handleSetChange,
    datas,
    handleChange,
    handleRuleCreate,
    fields,
  } = setting
  const { campaign, adset, ad } = datas

  return (
    <>
      <Row className="mb-2">
        <h6 className="my-auto text-lucaDark">建立新規則</h6>
      </Row>
      <Row
        className="bg-lucaLighter p-3 py-4 w-100 m-0 scrollbarShow"
        style={{
          maxHeight: '41.5vh',
          overflowY: 'auto',
        }}
      >
        <Row className="ps-5 pb-2 text-luca h6">選擇廣告層級</Row>
        <Row className="pb-3 justify-content-center w-100">
          <Col className="ps-5 pe-0 bg-select-default">
            <small className="text-lucaDark">廣告活動</small>
            <SelectBar
              setting={{
                method: handleSetChange,
                placeholder: '選擇廣告活動...',
                name: 'campaignId',
                value: selectSet.campaignId,
                content: campaign.map((item) => ({
                  name: item.name,
                  value: item.id,
                })),
              }}
            />
          </Col>
          <FontAwesomeIcon
            className="fs-5 my-auto text-luca mx-3 pt-4"
            icon={faAngleRight}
            style={{
              transition: '.5s',
              transitionTimingFunction: 'ease-in',
              width: '2%',
            }}
          />
          <Col className="d-flex px-0">
            <div className="w-100 bg-select-default">
              <small className="text-lucaDark">廣告設定</small>
              {selectSet.campaignId === '' ? (
                <h6
                  id="borderHideForm"
                  className="m-auto fw-normal text-grey"
                  style={{ cursor: 'not-allowed' }}
                >
                  - -
                </h6>
              ) : (
                <SelectBar
                  setting={{
                    method: handleSetChange,
                    placeholder: '- -',
                    name: 'adsetId',
                    value: selectSet.adsetId,
                    content: adset.map((item) => ({
                      name: item.name,
                      value: item.id,
                    })),
                  }}
                />
              )}
            </div>
          </Col>
          <FontAwesomeIcon
            className="fs-5 my-auto text-luca mx-3 pt-4"
            icon={faAngleRight}
            style={{
              transition: '.5s',
              transitionTimingFunction: 'ease-in',
              width: '2%',
            }}
          />
          <Col className="d-flex px-0">
            <div className="w-100 bg-select-default">
              <small className="text-lucaDark">廣告內容</small>
              {selectSet.campaignId === '' || selectSet.adsetId === '' ? (
                <h6
                  id="borderHideForm"
                  className="m-auto fw-normal text-grey"
                  style={{ cursor: 'not-allowed' }}
                >
                  - -
                </h6>
              ) : (
                <SelectBar
                  setting={{
                    method: handleSetChange,
                    placeholder: '- -',
                    name: 'adId',
                    value: selectSet.adId,
                    content: ad.map((item) => ({
                      name: item.name,
                      value: item.id,
                    })),
                  }}
                />
              )}
            </div>
          </Col>
        </Row>

        <Row className="ps-5 pb-2 pt-3 text-luca">
          <Col xs={12} className="d-flex px-0">
            <h6 className="text-luca ps-0 my-auto">新增條件項目</h6>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className="quesTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  提示：系統將自動過濾未填寫完整之欄位
                </Tooltip>
              }
            >
              <div className="my-auto ms-2 h-100">
                <FontAwesomeIcon
                  className="fs-7 btn-lucaIcon"
                  style={{ right: '10', top: '50', bottom: '50' }}
                  icon={faExclamationCircle}
                />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="ps-5">
          <Col xs={9}>
            <FlipMove
              easing="ease-in"
              enterAnimation="accordionVertical"
              leaveAnimation="none"
            >
              {newRule.rule.map((rule, i) => (
                <NewRule
                  key={i}
                  setting={{
                    ...rule,
                    index: i,
                    handleAddRule: () => handleAddRule('rule', i),
                    handleChange: (event) => handleChange(event, 'rule', i),
                    hasHeader: i === 0,
                    isInvalid: selectSet.campaignId === '',
                    fields,
                  }}
                />
              ))}
            </FlipMove>
          </Col>
          <Col xs={3} className="pe-0 bg-select-default ps-0">
            <small className="text-lucaDark">執行動作</small>
            {selectSet.campaignId !== '' ? (
              <SelectBar
                setting={{
                  method: (event) => handleChange(event, false),
                  placeholder: '選擇動作',
                  name: 'action',
                  value: newRule.action,
                  content: ['達成任意條件即停止', '達成全部條件即停止'].map(
                    (o) => ({
                      name: o,
                      value: o,
                    })
                  ),
                }}
              />
            ) : (
              <h6
                id="borderHideForm"
                className="m-auto fw-normal text-grey"
                style={{ cursor: 'not-allowed' }}
              >
                - -
              </h6>
            )}
          </Col>
        </Row>
        <div
          style={{ borderTop: '1px solid #ccc' }}
          className="d-flex justify-content-center mt-2 pt-3"
        >
          <Button
            size="sm"
            variant="secondary"
            className="me-2"
            onClick={handleResetNewRule}
          >
            清 除
          </Button>
          <Button size="sm" variant="luca" onClick={handleRuleCreate}>
            建 立
          </Button>
        </div>
      </Row>
    </>
  )
}

function AdsRule(props) {
  const { setting } = props
  const {
    size,
    show,
    rules,
    newRule,
    handleClose,
    handleAddRule,
    handleRuleDelete,
    handleResetNewRule,
    selectSet,
    handleSetChange,
    datas,
    handleChange,
    handleRuleCreate,
    provider,
  } = setting
  const { campaign, adset, ad } = datas

  const names = {
    campaign: '廣告活動',
    adset: '廣告組合',
    ad: '廣告內容',
  }

  const enums = {
    facebook: {
      fields: FacebookInsightFields,
    },
    google: {
      fields: GoogleInsightFields,
    },
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4 position-relative">
        <Modal.Title>
          <h4>廣 告 優 化 設 定</h4>
          <p>Advertise rule settings</p>
        </Modal.Title>
        <div
          className="position-absolute h-100 d-flex"
          style={{
            top: '5px',
            right: '45px',
            width: '35PX',
          }}
        >
          <FontAwesomeIcon
            className="fs-5 w-100 me-1 text-lucaLight my-auto"
            title="關閉"
            icon={faTimes}
            onClick={handleClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      </Modal.Header>
      {campaign ? (
        <Modal.Body className="AccountRule mx-4 my-3">
          <Row className="mb-3">
            <Col className="d-flex">
              <h6 className="my-auto">
                {newRule.type === 'optimize'
                  ? `單體規則（${
                      rules.filter((r) => r.type === 'optimize').length
                    }）`
                  : `群組規則（${
                      rules.filter((r) => r.type === 'group').length
                    }）`}
              </h6>
            </Col>
            <Col className="d-flex ms-auto">
              <Button
                variant="luca"
                className="ms-auto my-auto"
                onClick={() => {
                  handleChange(
                    {
                      target: {
                        name: 'type',
                        value:
                          newRule.type === 'optimize' ? 'group' : 'optimize',
                      },
                    },
                    false
                  )
                }}
              >
                <FontAwesomeIcon icon={faShare} className="me-2" />
                {newRule.type === 'optimize'
                  ? `群組規則（${
                      rules.filter((r) => r.type === 'group').length
                    }）`
                  : `單體規則（${
                      rules.filter((r) => r.type === 'optimize').length
                    }）`}
              </Button>
            </Col>
          </Row>
          {newRule.type === 'optimize' && (
            <>
              <Row className="mb-2">
                <h6 className="my-auto text-lucaDark">自定義規則</h6>
              </Row>
              <Datatable
                setting={{
                  pageSize: 3,
                  hasPagination: true,
                  headers: ['廣 告 層 級', '名 稱', '條 件', '動 作', '取 消'],
                  content: rules
                    .concat(
                      rules.filter((r) => r.type === 'optimize').length === 0
                        ? [
                            {
                              fill: true,
                              type: 'optimize',
                            },
                          ]
                        : []
                    )
                    .concat(
                      Array(
                        rules.filter((r) => r.type === 'optimize').length % 3 >
                          0
                          ? 3 -
                              (rules.filter((r) => r.type === 'optimize')
                                .length %
                                3)
                          : 0
                      ).fill({
                        fill: true,
                      })
                    )
                    .map((rule, i) => {
                      if (rule.type !== 'optimize' && !rule.fill)
                        return { filtered: true }
                      const data = !rule.fill
                        ? datas[rule.level].find(
                            (levelData) => levelData.id === rule.id
                          )
                        : {}
                      const ruleData =
                        !rule.fill &&
                        rule.rule.map(
                          (r) => `${t(r.target)} ${r.operator} ${r.value}`
                        )
                      return rule.fill
                        ? {
                            level: { value: '- -' },
                            name: { value: '- -' },
                            rule: { value: '- -' },
                            action: { value: '- -' },
                            cancel: { value: '- -' },
                          }
                        : {
                            level: { value: names[rule.level] },
                            name: {
                              value: data && data.name,
                            },
                            rule: {
                              value: (
                                <OverlayTrigger
                                  placement="bottom-start"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip
                                      className="listTip"
                                      style={{
                                        zIndex: '9999',
                                      }}
                                    >
                                      {ruleData.map((r) => (
                                        <p>{r}</p>
                                      ))}
                                    </Tooltip>
                                  }
                                >
                                  <div className="w-100 App-oneLineEllipsis">
                                    {ruleData.join(',')}
                                  </div>
                                </OverlayTrigger>
                              ),
                            },
                            action: { value: rule.action },
                            cancel: {
                              value: (
                                <div className="formsTool">
                                  <FontAwesomeIcon
                                    className="text-lucaLink"
                                    icon={faMinusCircle}
                                    title="刪除規則"
                                    onClick={() => handleRuleDelete(i)}
                                  />
                                </div>
                              ),
                            },
                          }
                    }),
                }}
              />
              <Optimize
                setting={{
                  newRule,
                  handleAddRule,
                  handleResetNewRule,
                  selectSet,
                  handleSetChange,
                  datas,
                  handleChange,
                  handleRuleCreate,
                  fields: enums[provider].fields,
                }}
              />
            </>
          )}
          {newRule.type === 'group' && (
            <>
              <Row className="mb-2">
                <h6 className="my-auto text-lucaDark">自定義規則</h6>
              </Row>
              <Datatable
                setting={{
                  pageSize: 3,
                  hasPagination: true,
                  headers: ['日 期', '名 稱', '動 作', '取 消'],
                  content: rules
                    .concat(
                      rules.filter((r) => r.type === 'group').length === 0
                        ? [
                            {
                              fill: true,
                            },
                          ]
                        : []
                    )
                    .concat(
                      Array(
                        rules.filter((r) => r.type === 'group').length % 3 > 0
                          ? 3 -
                              (rules.filter((r) => r.type === 'group').length %
                                3)
                          : 0
                      ).fill({
                        fill: true,
                      })
                    )
                    .map((rule, i) => {
                      if (rule.type !== 'group' && !rule.fill)
                        return { filtered: true }
                      const name =
                        !rule.fill &&
                        rule.ads.map((id) => {
                          const target = campaign
                            .concat(adset)
                            .concat(ad)
                            .find(
                              (element) =>
                                element.id ===
                                (id.adId || id.adsetId || id.campaignId)
                            )
                          return target ? target.name : ''
                        })
                      return rule.fill
                        ? {
                            date: { value: '- -' },
                            name: { value: '- -' },
                            action: { value: '- -' },
                            cancel: { value: '- -' },
                          }
                        : {
                            date: {
                              value: `${moment(rule.since).format(
                                'YYYY/MM/DD'
                              )} ~ ${moment(rule.until).format('YYYY/MM/DD')}`,
                            },
                            name: {
                              value: (
                                <OverlayTrigger
                                  placement="bottom-start"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip
                                      className="listTip"
                                      style={{
                                        zIndex: '9999',
                                      }}
                                    >
                                      {name.map((n) => (
                                        <p>{n}</p>
                                      ))}
                                    </Tooltip>
                                  }
                                >
                                  <div className="w-100 App-oneLineEllipsis">
                                    {name.join(',')}
                                  </div>
                                </OverlayTrigger>
                              ),
                            },
                            action: {
                              value: `${rule.target} ${rule.operator} ${rule.value}`,
                            },
                            cancel: {
                              value: (
                                <div className="formsTool">
                                  <FontAwesomeIcon
                                    className="text-lucaLink"
                                    icon={faMinusCircle}
                                    title="刪除規則"
                                    onClick={() => handleRuleDelete(i)}
                                  />
                                </div>
                              ),
                            },
                          }
                    }),
                }}
              />
              <Group
                setting={{
                  newRule,
                  handleAddRule,
                  handleResetNewRule,
                  selectSet,
                  handleSetChange,
                  datas,
                  handleChange,
                  handleRuleCreate,
                  fields: enums[provider].fields,
                }}
              />
            </>
          )}
        </Modal.Body>
      ) : (
        <div />
      )}
    </Modal>
  )
}

NewRule.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Optimize.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Group.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AdsRule.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AdsRule
