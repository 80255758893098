import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

function ListBox(props) {
  const { setting } = props
  const { title, titleEng, content } = setting
  return (
    <Card className="text-start px-0">
      <Card.Body>
        <Card.Title className="text-center mb-0">{title}</Card.Title>
        <Card.Text className="text-center">{titleEng}</Card.Text>
        <hr />
        <ListGroup variant="flush">
          {content.map((c, i) => (
            <ListGroup.Item
              key={i}
              as="a"
              className="App-oneLineEllipsis px-2"
              active={false}
              href={c.link}
            >
              <span className="text-luca">○ </span>
              {c.text}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

ListBox.propTypes = {
  setting: PropTypes.shape(),
}

ListBox.defaultProps = {
  setting: {
    title: '公 告 欄',
    titleEng: 'Announcement',
    content: [
      {
        text: 'New! 系統更新公告 新增快捷側欄',
        link: 'https://nautilus.punwave.com/',
      },
      {
        text: '系統更新公告v3.1版功能說明',
        link: 'https://nautilus.punwave.com/',
      },
      {
        text: '系統更新公告v3.0 詳細說明請按我',
        link: 'https://nautilus.punwave.com/',
      },
      {
        text: '系統更新公告v2.9 首頁UI更新',
        link: 'https://nautilus.punwave.com/',
      },
      {
        text: '系統更新公告v2.8 首頁UI更新',
        link: 'https://nautilus.punwave.com/',
      },
    ],
  },
}

export default ListBox
