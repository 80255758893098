import React from 'react'
import PropTypes from 'prop-types'
import FlipMove from 'react-flip-move'
import Blocks from './Blocks'

function Lists(props) {
  const { setting } = props
  const {
    isActive,
    disableCheckBox,
    activeid,
    setactiveid,
    datas,
    ref,
    whiteHeight,
    setwhiteHeight,
    checkedList,
    handleCheck,
  } = setting

  return (
    <FlipMove>
      {datas.map((data, i) => (
        <Blocks
          key={data.id || data.ad.id}
          setting={{
            hasCheckBox: isActive && !disableCheckBox,
            active: activeid === data.id,
            method: () => {
              setactiveid(activeid === data.id ? '' : data.id)
            },
            whiteRef: ref !== undefined && i === 0 ? ref : undefined,
            whiteHeight,
            setwhiteHeight,
            checked: checkedList.includes && checkedList.includes(`${data.id}`),
            handleCheck: () => handleCheck(data.id),
            ...data,
          }}
        />
      ))}
    </FlipMove>
  )
}

Lists.propTypes = {
  setting: PropTypes.shape(),
}

Lists.defaultProps = {
  setting: {
    isActive: true,
    datas: [
      {
        content: '活動名稱',
      },
      {
        content: '活動名稱',
      },
      {
        content: '活動名稱',
      },
      {
        content: '活動名稱',
      },
    ],
  },
}

export default Lists
