import React from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'
import { t } from '../../services/lucaFunctions'
import { enums } from '../../services/insightProcesser'
import ReportPreview from '../AdsReporter/ReportPreview'

function DataView(props) {
  const { setting } = props
  const {
    hasPreview,
    account_id,
    provider,
    onHeadClick,
    onNameClick,
    data,
    total,
    names,
    nameField,
    backgroundColor = '#a8d7d3',
  } = setting

  const headers = (
    account_id && hasPreview ? ['name', 'preview'] : ['name']
  ).concat(setting.headers)

  return (
    <div
      className="AdsBlocks h-100"
      style={{
        overflow: 'auto',
      }}
    >
      <Table
        className="h-100 w-100 transpTable AdsTable mb-0"
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0',
          border: '1px solid #7db8b2',
        }}
      >
        <thead>
          <tr>
            {headers.map((h, i) => (
              <th
                className=""
                title={h}
                key={i}
                name={h}
                style={{
                  position: 'sticky',
                  top: '0',
                  backgroundColor,
                  opacity: '1',
                  border: '0px',
                  borderBottom: '1px solid #7db8b2',
                  ...(h === 'name'
                    ? {
                        left: '0',
                        zIndex: '3',
                        borderRight: '1px solid #7db8b2',
                      }
                    : { zIndex: '1' }),
                  ...(h === 'preview'
                    ? {
                        borderRight: '1px solid #7db8b2',
                      }
                    : {}),
                }}
                onClick={onHeadClick}
              >
                {t(h)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            border: '0px',
          }}
        >
          {data.length > 0 ? (
            <>
              {data.map((f, i) => (
                <tr key={i}>
                  {headers.map((h) => (
                    <td
                      key={h}
                      title={h === 'name' ? f.name || '' : ''}
                      name={f[nameField]}
                      onClick={() => {
                        if (h === 'name') onNameClick(f[nameField])
                      }}
                      style={{
                        backgroundColor,
                        opacity: '1',
                        border: '0px',
                        ...(h === 'name'
                          ? {
                              cursor: 'pointer',
                              position: 'sticky',
                              left: '0',
                              zIndex: '2',
                              borderRight: '1px solid #7db8b2',
                            }
                          : {}),
                        ...(h === 'preview'
                          ? {
                              borderRight: '1px solid #7db8b2',
                            }
                          : {}),
                      }}
                      aria-hidden
                    >
                      {h === 'preview' ? (
                        <ReportPreview
                          setting={{
                            account_id,
                            provider,
                            size: {
                              width: 540,
                              height: 760,
                            },
                            id: f.ad_id,
                          }}
                        />
                      ) : (
                        <div className="h-100 d-flex">
                          {h === 'name' ? (
                            <p
                              className="my-auto"
                              style={{
                                whiteSpace: 'normal',
                              }}
                            >
                              {names[f[nameField]]}
                            </p>
                          ) : (
                            <p className="m-auto">
                              {enums[provider].formatter(h)(f[h])}
                            </p>
                          )}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              {headers.map((f) => (
                <td key={f}>- -</td>
              ))}
            </tr>
          )}
        </tbody>
        {total && (
          <thead style={{ border: '0px', height: '2.5rem' }}>
            <tr>
              <th
                style={{
                  position: 'sticky',
                  bottom: '0',
                  left: '0',
                  zIndex: '2',
                  backgroundColor,
                  opacity: '1',
                  border: '0px',
                  borderRight: '1px solid #7db8b2',
                  borderTop: '1px solid #7db8b2',
                }}
              >
                總計
              </th>
              {headers
                .filter((h) => h !== 'name')
                .map((h) => (
                  <th
                    style={{
                      position: 'sticky',
                      bottom: '0',
                      zIndex: '1',
                      backgroundColor,
                      opacity: '1',
                      border: '0px',
                      borderTop: '1px solid #7db8b2',

                      ...(h === 'preview'
                        ? {
                            borderRight: '1px solid #7db8b2',
                          }
                        : {}),
                    }}
                    key={h}
                  >
                    {total && total[0]
                      ? enums[provider].formatter(h)(total[0][h])
                      : '- -'}
                  </th>
                ))}
            </tr>
          </thead>
        )}
      </Table>
    </div>
  )
}

DataView.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DataView
