import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

function LucaDatePicker(props) {
  const { setting } = props
  const {
    iconClassName,
    since = Date.now(),
    until = Date.now(),
    setSince,
    setUntil,
    disabled = false,
  } = setting
  console.log(since)

  return (
    <div className="d-flex reportDatePicker my-auto">
      <FontAwesomeIcon
        className={`fs-5 my-auto mx-2 text-lucaLight ps-1 ms-0 ${
          iconClassName || ''
        }`}
        icon={faCalendarAlt}
      />
      <DatePicker
        disabled={disabled}
        selected={since}
        onChange={(date) => {
          setSince(date)
        }}
      />
      <FontAwesomeIcon
        className="fs-5 my-auto mx-2 text-luca"
        icon={faAngleRight}
      />
      <DatePicker
        disabled={disabled}
        selected={until}
        onChange={(date) => setUntil(date)}
      />
    </div>
  )
}

LucaDatePicker.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default LucaDatePicker
