import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  InputGroup,
  Form,
  Accordion,
  DropdownButton,
  Dropdown,
  Spinner,
} from 'react-bootstrap'
// import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  // faCheckCircle,
  // faExclamationCircle,
  faXmark,
  faReply,
  faTimesCircle,
  faCheckCircle,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import ConfirmForm from '../ConfirmForm'
import ReportFloatButton from './ReportFloatButton'
import ReportProcessor from './ReportProcessor'
import Insights from '../Insights'
import ReportTools from './ReportTools'
import ReportTable from './ReportTable'
import ReportBlock from './ReportBlock'
import ReportSettings from './ReportSettings'
import { wordSearch } from '../../services/lucaFunctions'
import apiService from '../../services/apiService'
import {
  fetchAccounts,
  // arrangeData,
  // getCriterions,
  // toInsights,
  processData,
  // processReport,
} from '../../services/insightProcesser'
import { AuthContext } from '../ContextProvider'
import ReportPreview from './ReportPreview'

function AdsReporter(props) {
  const { setting } = props
  const { selected, handleActionChange } = setting
  const auth = useContext(AuthContext)
  const [report, setreport] = useState(
    selected || {
      created_on: moment(),
      setting: {
        autostart: false,
        autostop: false,
        emails: [auth.auth.email],
        startDate: moment().subtract('7', 'days'),
        endDate: moment(),
        frequency: 'daily',
        isActive: false,
        name: '',
        views: [],
        user_name: '',
      },
    }
  )
  if (false) console.log(setreport)

  const [search, setsearch] = useState('')
  const [accounts, setaccounts] = useState([])
  useEffect(() => {
    if (auth.auth.user_id !== undefined) {
      const getData = async () => {
        const data = await fetchAccounts({
          user_id: auth.auth.user_id,
          business_id: auth.account.business_id,
        })
        setaccounts(data)
      }
      getData()
    }
  }, [auth])

  const [views, setviews] = useState(false)
  const [viewId, setviewId] = useState('')
  useEffect(() => {
    const getViews = async () => {
      const res = await Promise.all(
        report.setting.views.map(async (view) => {
          const api = await apiService.data({
            path: `luca/report/view/${view}`,
            method: 'get',
          })
          return api
        })
      )
      setviews(res)
    }
    if (!views && report.setting.views && report.setting.views.length)
      getViews()
  }, [report])
  const handleViewChange = (value) => {
    if (!viewId) return
    setviews(
      views.map((v) =>
        v.view_id === viewId
          ? {
              ...v,
              setting: {
                ...v.setting,
                ...value,
              },
            }
          : v
      )
    )
  }

  const handleAddView = async () => {
    const res = await apiService.data({
      path: `luca/report/view/${auth.account.business_id}/${auth.auth.user_id}`,
      method: 'post',
    })
    setviews([res, ...(views || [])])
    setviewId(res.view_id)
    setreport({
      ...report,
      setting: {
        ...report.setting,
        views: [res.view_id, ...report.setting.views],
      },
    })
  }

  // const emptyViews = {}
  const selectedView = useMemo(() => {
    if (!views || !viewId) return { setting: {} }
    return views.find(({ view_id }) => view_id === viewId)
  }, [views, viewId])

  const [acting, setacting] = useState({})

  // finish setting
  const [progress, setprogress] = useState(0)
  const exportReport = async () => {
    // await processReport({
    //   report,
    //   auth,
    //   reportId: selected ? selected.report_id : '',
    // })
    const interval = setInterval(() => {
      setprogress((oldvalue) => {
        const newValue = oldvalue + 1
        if (newValue > 99) {
          clearInterval(interval)
        }
        return newValue
      })
    }, 50)
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldMatch: '',
    shouldReturn: '',
  })
  const handleShowWarn = (value, target) => {
    setshowWarn({
      ...value,
      show: true,
      target,
    })
  }
  const handleWarnClose = async (value) => {
    setshowWarn({
      ...showWarn,
      show: false,
    })
    if (value) {
      // clear this view
      // setreport({
      //   report: {
      //     insightSettings: [getEmptyReport({ id: 1 })],
      //   },
      // })
    }
  }

  const [datas, setdatas] = useState({
    campaign: [],
    adset: [],
    ad: [],
    init: false,
    prepared: false,
  })
  const { campaign, adset, ad } = datas
  const [clusterName, setclusterName] = useState('新群集')
  const initCheckedList = {
    campaign: {},
    adset: {},
    ad: {},
  }
  const [checkedList, setcheckedList] = useState(initCheckedList)

  const getDatas = async (init) => {
    if (!datas.init || init) {
      if (!selectedView.account) return
      const data = await processData({
        ...selectedView.account.setting,
        account_id: selectedView.account.account_id,
        business_id: auth.account.business_id,
        briefly: true,
        time_range: {
          since: moment(selectedView.setting.startDate).format('yyyy-MM-DD'),
          until: moment(selectedView.setting.endDate).format('yyyy-MM-DD'),
        },
      })
      setdatas({
        ...datas,
        ...data,
        init: true,
        prepared: true,
      })
    } else {
      setdatas({
        ...datas,
        prepared: true,
      })
    }
  }

  useEffect(() => {
    if (!selectedView.setting || !selectedView.account_id) return
    console.log('this is triggered when account change')
    // if (datas.prepared) return
    setdatas({
      ...datas,
      prepared: false,
    })
    getDatas(true)
  }, [selectedView])
  console.log(selectedView)

  //  white back height
  const whiteRef = useRef(null)
  const [whiteHeight, setwhiteHeight] = useState({ height: 0, marginBottom: 0 })
  const getWhiteHeight = () => {
    if (whiteRef.current) {
      const style = getComputedStyle(whiteRef.current)
      return {
        height: whiteRef.current.clientHeight,
        marginBottom: parseFloat(style.marginBottom),
      }
    }
    return { height: 0, marginBottom: 0 }
  }
  useEffect(() => {
    const height = getWhiteHeight()
    if (
      height.height !== 0 &&
      (height.height !== whiteHeight.height ||
        height.marginBottom !== whiteHeight.marginBottom)
    )
      setwhiteHeight(height)
  }, [getWhiteHeight])

  const handleCheck = (id, type) =>
    setcheckedList({
      ...checkedList,
      [type]: { ...checkedList[type], [id]: !checkedList[type][id] },
    })
  const [associatedInfo, setassociatedInfo] = useState({
    associatedCampaign: [],
    associatedAdset: [],
    associatedAd: [],
    campaign: [],
    adset: [],
    ad: [],
  })
  useEffect(() => {
    if (!campaign || !adset || !ad) return
    const isAssociated = (x) =>
      checkedList.campaign[x.campaign_id] ||
      checkedList.campaign[x.id] ||
      checkedList.adset[x.adset_id] ||
      checkedList.adset[x.id] ||
      checkedList.ad[x.ad_id] ||
      checkedList.ad[x.id] ||
      (x.ad && checkedList.ad[x.ad.id])
    setassociatedInfo({
      associatedCampaign: campaign
        .filter((i) => isAssociated(i))
        .map((i) => ({ ...i, associated: true })),
      associatedAdset: adset
        .filter((i) => isAssociated(i))
        .map((i) => ({ ...i, associated: true })),
      associatedAd: ad
        .filter((i) => isAssociated(i))
        .map((i) => ({ ...i, associated: true })),
      campaign: campaign
        .filter((i) => !isAssociated(i))
        .map((i) => ({ ...i, associated: false })),
      adset: adset
        .filter((i) => !isAssociated(i))
        .map((i) => ({ ...i, associated: false })),
      ad: ad
        .filter((i) => !isAssociated(i))
        .map((i) => ({ ...i, associated: false })),
    })
  }, [checkedList, datas])

  const groupOptions = [
    {
      label: '按廣告建立群集',
      value: 'byAds',
    },
    {
      label: '按廣告名稱建立群集',
      value: 'ad_name',
    },
    {
      label: '按廣告標籤建立群集',
      value: 'ad_label',
    },
    {
      label: '按廣告活動建立群集',
      value: 'byCampaigns',
    },
    {
      label: '按廣告組合/廣告群組建立群集',
      value: 'byAdSets',
    },
    {
      label: '按廣告組合/廣告群組建立群集(總表)',
      value: 'adset_all',
    },
  ]

  console.log(selectedView.setting)
  const [breakdown, setbreakdown] = useState('')

  return (
    <Container className="px-4 pb-4 w-100" style={{ overflowY: 'auto' }}>
      <Row className="pt-3 ps-2">
        <Col
          xs={{ span: '2', offset: '2' }}
          role="button"
          className="mt-auto btn btn-lucaWhite ms-1 mb-2"
          onClick={() => handleActionChange({ action: 'ADMReporter' })}
          aria-hidden="true"
        >
          <FontAwesomeIcon icon={faReply} className="my-auto fs-7 px-0" />
          &ensp;投放數據總覽
        </Col>
        <Col xs={{ span: '4', offset: '2' }} className="p-4 pageTitle my-auto">
          <h4 className="pt-4 pb-1">報 表 匯 出 設 定</h4>
          <p>Advertising Report Settings</p>
        </Col>
        <Col
          xs={2}
          role="button"
          className="mt-auto btn btn-lucaWhite ms-auto me-3 mb-2"
          aria-hidden="true"
          title="報 表 管 理"
          onClick={() => handleActionChange({ action: 'ReportList' })}
        >
          匯出列表與管理&ensp;
          <FontAwesomeIcon
            icon={faReply}
            style={{ transform: 'scaleX(-1)' }}
            className="my-auto fs-7 px-0"
          />
        </Col>
      </Row>
      <ReportBlock
        setting={{
          content: <Row className="text-lucaDark ps-3">展開以顯示報表預覽</Row>,
        }}
      >
        <Row>
          <Col className="overflow-hidden">
            <Nav
              variant="pills"
              className="w-100 flex-nowrap flex-row w-100 overflow-hidden"
            >
              <Nav.Item className="reportTabs_new">
                <Nav.Link onClick={handleAddView}>
                  <FontAwesomeIcon
                    className="text-light ms-auto align-self-center fs-6"
                    icon={faPlusCircle}
                    title="新 增"
                  />
                </Nav.Link>
              </Nav.Item>
              {views &&
                views
                  .reduce((prev, cur) => {
                    const target = cur.setting.breakdowns
                      ? [
                          cur,
                          ...(cur.setting.breakdowns.length
                            ? cur.setting.breakdowns.map((b) => ({
                                ...cur,
                                breakdown: b,
                              }))
                            : Object.keys(cur.setting.breakdowns)
                                .filter((key) => cur.setting.breakdowns[key])
                                .map((key) => ({
                                  ...cur,
                                  breakdown: key,
                                }))),
                        ]
                      : [cur]
                    return [...prev, ...target]
                  }, [])
                  .map((view) => (
                    <Nav.Item
                      key={view.view_id}
                      style={{
                        width: `${
                          views.length < 12 ? 12 : Math.ceil(120 / views.length)
                        }%`,
                      }}
                      className={`flex-shrink-1 overflow-hidden text-nowrap ${
                        view.view_id === viewId &&
                        ((!view.breakdown && !breakdown) ||
                          view.breakdown === breakdown)
                          ? 'selectedReportTabs'
                          : 'reportTabs'
                      }`}
                    >
                      <Nav.Link
                        className="App-oneLineEllipsis px-2"
                        title={view.setting?.name || ''}
                        onClick={() => {
                          setviewId(view.view_id)
                          setbreakdown(view.breakdown || '')
                        }}
                      >
                        {view.view_id === viewId && views.length > 1 ? (
                          <span style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="text-lucaLink"
                              onClick={(e) => {
                                e.stopPropagation()
                                console.log('TODO: WARNING BEFORE DELETE VIEW')
                                // handleReportDelete(i.id)
                              }}
                            />
                            &ensp;{view.breakdown || view.setting?.name || ''}
                          </span>
                        ) : (
                          view.breakdown || view.setting?.name || ''
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
            </Nav>
          </Col>
          <Col className="d-flex" xs={1}>
            <h6 className="text-start my-auto ms-auto pb-2 text-lucaDark fw-bolder">
              預 覽
            </h6>
          </Col>
        </Row>
        <Card
          className="pb-4 pt-0 scrollbarShow"
          style={{
            borderRadius: '0.25rem 0rem 0.25rem 0rem',
            overflowY: 'auto',
            maxHeight: '75vh',
          }}
        >
          {selectedView.account ? (
            <>
              <Row className="d-flex w-100 text-start h6 text-lucaDark pt-2 mx-0 px-0 pt-3">
                <Col xs={2} className="h6 text-start">
                  報表名稱
                </Col>
                <Col
                  xs={10}
                  className="text-luca App-oneLineEllipsis"
                  title={report.setting?.name}
                >
                  {report.setting?.name}
                </Col>
              </Row>
              <Row className="d-flex w-100 text-start h6 text-lucaDark pt-2 mx-0 px-0 pt-3">
                <Col xs={2} className="h6 text-start">
                  資料檢視名稱
                </Col>
                <Col
                  xs={10}
                  className="text-luca App-oneLineEllipsis"
                  title={selectedView.setting?.name}
                >
                  {selectedView.setting?.name}
                </Col>
              </Row>
              {/* <Row className="d-flex w-100 text-start text-lucaDark mx-0 px-0 pb-3">
                <Col xs={2} className="h6 text-center">
                  描&emsp;&emsp;述&ensp;｜
                </Col>
                <Col
                  xs={10}
                  className="text-luca App-oneLineEllipsis"
                  title={description}
                >
                  {description}
                </Col>
              </Row> */}
              <hr className="hrClass-dashed my-0" />
              <Row
                className={`pt-2 px-3 w-100 m-auto overflow-scroll ${
                  selectedView.setting.views?.horizonal ? '' : 'flex-nowrap'
                }`}
                style={{
                  minHeight: '35vh',
                }}
              >
                {selectedView.setting.adclusters.map((cluster) => (
                  <Col xs={12} key={cluster.name} className="px-1">
                    <h6 className="w-50 text-start ps-1 py-3 my-auto text-lucaDark fw-bolder">
                      {cluster.name}
                    </h6>
                    {/* <Row
                      className="ps-3 w-100"
                      style={{
                        height: '760px',
                      }}
                    >
                      <Col className="h-100 w-100">
                        <ReportPreview
                          setting={{
                            account_id: selectedView.account.setting.account_id,
                            provider:
                              selectedView.account.setting.provider || 'google',
                            size: {
                              width: 540,
                              height: 380,
                            },
                            scale: 1,
                            id: cluster.ads[0].id,
                          }}
                        />
                      </Col>
                    </Row> */}
                    {Object.entries({
                      LineChart: selectedView.setting.views?.LineChart,
                      StackChart: selectedView.setting.views?.StackChart,
                      PieChart: selectedView.setting.views?.PieChart,
                      Data: selectedView.setting.views?.Data,
                    }).map(
                      ([key, value]) =>
                        value && (
                          <Insights
                            setting={{
                              auth,
                              hasPreview: selectedView.setting.views?.preview,
                              account_id:
                                selectedView.account.setting.account_id,
                              datas: datas.ad,
                              activetab: 'ad',
                              breakdowns: breakdown ? [breakdown] : [],
                              criterion: breakdown ? [breakdown] : undefined,
                              dateBreakdown: breakdown
                                ? 'all_days'
                                : selectedView.setting.format,
                              fields: selectedView.setting.fields,
                              provider:
                                selectedView.account.setting.provider ||
                                'google',
                              // insight: admReport.Data,
                              insightType: key,
                              labelType: 'hide',
                              xs: 12,
                              backgroundColor: '#d6ece9',
                              currency: selectedView.setting.currency,
                              timeRange: {
                                since: selectedView.setting.startDate,
                                until: selectedView.setting.endDate,
                              },
                              selectedField: 'spend',
                              // adClusters: .map((d) => ({
                              //         level: activetab,
                              //         id:
                              //           d[`${activetab}_id`] ||
                              //           d[`${activetab}.id`] ||
                              //           d.id,
                              //       })),
                              search: '',
                              // selected: cluster.ads.map((id) => ({
                              //   tab: 'ad',
                              //   id,
                              // })),
                              setactiveid: () => {},
                              hasWheel: false,
                            }}
                          />
                        )
                    )}
                  </Col>
                ))}
                {selectedView.setting.views?.total && (
                  <Col xs={12} className="px-1">
                    <h6
                      className={`w-50 text-start ps-1 py-3 text-lucaDark fw-bolder ${
                        selectedView.setting.views?.horizonal ? 'mt-5' : ''
                      }`}
                    >
                      總計
                    </h6>
                    <Insights
                      setting={{
                        auth,
                        hasPreview: selectedView.setting.views?.preview,
                        account_id: selectedView.account.setting.account_id,
                        datas: datas.ad,
                        activetab: 'ad',
                        breakdowns: [],
                        dateBreakdown: selectedView.setting.format,
                        fields: selectedView.setting.fields,
                        provider:
                          selectedView.account.setting.provider || 'google',
                        // insight: admReport.Data,
                        insightType: 'Data',
                        labelType: 'hide',
                        xs: 12,
                        backgroundColor: '#d6ece9',
                        currency: selectedView.setting.currency,
                        timeRange: {
                          since: selectedView.setting.startDate,
                          until: selectedView.setting.endDate,
                        },
                        // adClusters: .map((d) => ({
                        //         level: activetab,
                        //         id:
                        //           d[`${activetab}_id`] ||
                        //           d[`${activetab}.id`] ||
                        //           d.id,
                        //       })),
                        search: '',
                        selected: selectedView.setting.adclusters.reduce(
                          (prev, { ads }) =>
                            prev.concat(
                              ads.map((id) => ({
                                tab: 'ad',
                                id,
                              }))
                            ),
                          []
                        ),
                        setactiveid: () => {},
                        hasWheel: true,
                      }}
                    />
                  </Col>
                )}
              </Row>
              {/* ) : (
                <div className="m-auto d-flex my-5">
                  <Spinner
                    className="m-auto"
                    animation="border"
                    variant="luca"
                    size="sm"
                    style={{
                      animation: 'spinner-border 1.5s linear infinite',
                    }}
                  />
                  <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
                </div>
              )} */}
            </>
          ) : (
            <Row
              className="AdsManagerForm w-100 flex-nowrap"
              style={{ minHeight: '25vh' }}
            >
              <h5 className="m-auto text-luca">請 選 擇 廣 告 帳 號</h5>
            </Row>
          )}
        </Card>
      </ReportBlock>

      <hr className="hrClass-dotted my-4" />

      {selectedView.setting && (
        <>
          <Row className="d-flex mt-4 mb-2">
            <Col className="mt-auto">
              <h6 className="text-start text-lucaDark fw-bolder">
                STEP ❶ 選 擇 廣 告（可 複 選）
              </h6>
            </Col>
            <Col className="d-flex mb-auto">
              <DropdownButton
                className="ms-auto my-auto"
                align="start"
                title={
                  selectedView.account
                    ? selectedView.account.setting.name
                    : '選擇廣告帳號...'
                }
                id="dropmenu"
                variant="light"
                size="sm"
              >
                <InputGroup
                  style={{ borderRadius: '.25rem' }}
                  className="m-2 w-90 border rounded-lg"
                  size="sm"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search AdAccount..."
                    aria-label="Search AdAccount..."
                    aria-describedby="btnGroupAddon"
                    title="搜尋廣告帳戶"
                    value={search}
                    onChange={(event) => setsearch(event.target.value)}
                  />
                </InputGroup>
                {accounts
                  .filter((a) => wordSearch(a.name, search))
                  .map((a, i) => (
                    <Dropdown.Item
                      key={i}
                      size="sm"
                      className="py-0"
                      onClick={() => {
                        setviews(
                          views.map((v) =>
                            v.view_id === viewId
                              ? {
                                  ...v,
                                  account: a,
                                  account_id: a.account_id,
                                  setting: {
                                    ...v.setting,
                                    adclusters: [],
                                  },
                                }
                              : v
                          )
                        )
                        setcheckedList(initCheckedList)
                      }}
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </Col>
          </Row>
          <ReportBlock
            setting={{
              content: (
                <Row className="text-lucaDark ps-3">
                  已建立{selectedView.setting.adclusters?.length || '0'}
                  個廣告群組
                </Row>
              ),
            }}
          >
            <Card
              style={{
                height: '50vh',
              }}
            >
              <Row
                className="d-flex py-2"
                style={{
                  height: '12%',
                }}
              >
                <Col xs={4} className="d-flex">
                  {acting.adcluster ? (
                    <>
                      <h6 className="w-50 ps-3 my-auto text-lucaDark fw-bolder">
                        群集名稱
                      </h6>
                      <Form.Control
                        value={clusterName}
                        onChange={(e) => setclusterName(e.target.value)}
                      />
                    </>
                  ) : (
                    <h6 className="ps-3 my-auto text-lucaDark fw-bolder">
                      已 建 立 的 廣 告 群 集
                    </h6>
                  )}
                </Col>
                <Col className="ms-auto" />
                <Col xs={2}>
                  <DropdownButton
                    align="start"
                    title="自動配置..."
                    id="dropmenu"
                    variant="light"
                    size="sm"
                    className="flex-column"
                  >
                    {groupOptions.map((option) => (
                      <Dropdown.Item
                        size="sm"
                        className="py-0"
                        // disabled={presetStatus !== 'preset'}
                      >
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Col>
                {acting.adcluster && (
                  <Col
                    xs={1}
                    role="button"
                    className="btn h-100 btn-lucaWhite d-flex me-3 justify-content-center"
                    aria-hidden="true"
                    title="取消"
                    onClick={() =>
                      setacting({
                        ...acting,
                        adcluster: !acting.adcluster,
                      })
                    }
                  >
                    <span className="my-auto fs-7">取消&ensp;</span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      // style={{ transform: 'scaleX(-1)' }}
                      className="my-auto fs-7 px-0"
                    />
                  </Col>
                )}
                <Col
                  xs={1}
                  role="button"
                  className="btn h-100 btn-lucaWhite d-flex me-3 justify-content-center"
                  aria-hidden="true"
                  title="新增"
                  onClick={() => {
                    if (acting.adcluster) {
                      setviews(
                        views.map((v) =>
                          v.view_id === viewId
                            ? {
                                ...v,
                                setting: {
                                  ...v.setting,
                                  adclusters: [
                                    {
                                      name: clusterName || '新群集',
                                      label: '',
                                      ads: Object.keys(checkedList.ad).filter(
                                        (key) => checkedList.ad[key]
                                      ),
                                    },
                                    ...selectedView.setting.adclusters,
                                  ],
                                },
                              }
                            : v
                        )
                      )
                    }
                    setacting({
                      ...acting,
                      adcluster: !acting.adcluster,
                    })
                    setcheckedList(initCheckedList)
                    setclusterName('新群集')
                  }}
                >
                  <span className="my-auto fs-7">
                    {acting.adcluster ? '確定' : '新增'}&ensp;
                  </span>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    // style={{ transform: 'scaleX(-1)' }}
                    className="my-auto fs-7 px-0"
                  />
                </Col>
              </Row>
              <Row
                className="d-flex flex-column flex-nowrap"
                style={{
                  height: '88%',
                }}
              >
                {acting.adcluster ? (
                  <div className="w-100 h-100 d-flex flex-column">
                    {datas.prepared ? (
                      <ReportTable
                        setting={{
                          datas,
                          associatedInfo,
                          whiteHeight,
                          whiteRef,
                          setwhiteHeight,
                          checkedList,
                          handleCheck,
                        }}
                      />
                    ) : (
                      <div className="m-auto d-flex my-5">
                        <Spinner
                          className="m-auto"
                          animation="border"
                          variant="luca"
                          size="sm"
                          style={{
                            animation: 'spinner-border 1.5s linear infinite',
                          }}
                        />
                        <h5 className="text-luca my-auto ms-2">
                          資料載入中...
                        </h5>
                      </div>
                    )}
                  </div>
                ) : (
                  <Accordion
                    defaultActiveKey="0"
                    className="h-100 overflow-scroll"
                  >
                    {(selectedView.setting.adclusters || []).map(
                      (cluster, i) => (
                        <Accordion.Item key={i} eventKey={i}>
                          <Accordion.Header
                            className="d-flex"
                            title={cluster.name}
                          >
                            <Form.Control
                              className="w-25 border-0"
                              value={cluster.name}
                              onChange={() => {}}
                            />
                            <span className="ms-auto">
                              包含{cluster.ads.length}個廣告
                            </span>
                            <FontAwesomeIcon
                              onClick={() =>
                                setviews(
                                  views.map((v) =>
                                    v.view_id === viewId
                                      ? {
                                          ...v,
                                          setting: {
                                            ...v.setting,
                                            adclusters:
                                              selectedView.setting.adclusters.filter(
                                                (c, index) => index !== i
                                              ),
                                          },
                                        }
                                      : v
                                  )
                                )
                              }
                              className="ms-2 align-self-center btn-lucaIcon3"
                              icon={faTimes}
                              title="取 消"
                            />
                          </Accordion.Header>
                          <Accordion.Body
                            className="overflow-scroll"
                            style={{
                              height: '800px',
                            }}
                          >
                            {cluster.ads.map((item) => {
                              if (!ad) return <Row className="ps-3">{item}</Row>
                              const target = ad.find((a) => a.id === item)
                              if (!target)
                                return (
                                  <>
                                    <Row className="ps-3 w-100">
                                      <Col xs={8}>{item}</Col>
                                      <Col className="ms-auto">
                                        <ReportPreview
                                          setting={{
                                            account_id:
                                              selectedView.account.setting
                                                .account_id,
                                            provider:
                                              selectedView.account.setting
                                                .provider || 'google',
                                            size: {
                                              width: 1080,
                                              height: 760,
                                            },
                                            scale: 1,
                                            id: item,
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <hr />
                                  </>
                                )
                              return (
                                <>
                                  <Row className="ps-3 w-100">
                                    <Col xs={4}>{target.name}</Col>
                                    <Col xs={4}>
                                      <ReportPreview
                                        setting={{
                                          account_id:
                                            selectedView.account.setting
                                              .account_id,
                                          provider:
                                            selectedView.account.setting
                                              .provider || 'google',
                                          size: {
                                            width: 1080,
                                            height: 760,
                                          },
                                          scale: 1,
                                          id: item,
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                </>
                              )
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    )}
                  </Accordion>
                )}
              </Row>
            </Card>
          </ReportBlock>
          <h6 className="text-start mb-2 mt-4 text-lucaDark fw-bolder">
            STEP ❷ 內 容 設 定
          </h6>
          <ReportBlock
            setting={{
              content: (
                <Row className="text-lucaDark ps-3">
                  已選擇顯示 廣告預覽圖、 廣告走期 /
                  數據、折線圖、趨勢圖、圓餅圖。
                </Row>
              ),
            }}
          >
            <div
              className="p-0 position-relative"
              style={{
                height: '38vh',
              }}
            >
              <ReportTools
                setting={{
                  provider: selectedView.account
                    ? selectedView.account.setting.provider
                    : 'facebook',
                  views: selectedView.setting.views || {
                    preview: false,
                    Data: true,
                    LineChart: false,
                    StackChart: false,
                    PieChart: false,
                  },
                  // groups,
                  timeRanges: {
                    since: selectedView.setting.startDate,
                    until: selectedView.setting.endDate,
                  },
                  breakdown: selectedView.setting.breakdown,
                  dateBreakdown: selectedView.setting.format,
                  fields: selectedView.setting.fields,
                  reportName: selectedView.setting.name,
                  description: '',
                  extraData: selectedView.setting.extraData,
                  currency: selectedView.setting.currency,
                  rate: selectedView.setting.currencyExchange,
                  handleReportChange: (value) => handleViewChange(value),
                  handleAdClusterChange: () => {},
                }}
              />
            </div>
          </ReportBlock>
        </>
      )}

      <h6 className="text-start mb-2 mt-4 text-lucaDark fw-bolder">
        STEP ❸ 匯 出 與 訂 閱 設 定
      </h6>
      <ReportBlock
        setting={{
          content: (
            <Row className="text-lucaDark ps-3">
              已啟用訂閱 每日寄送報表至3位訂閱者信箱。
            </Row>
          ),
        }}
      >
        <div
          className="p-0 position-relative"
          style={{
            height: '38vh',
          }}
        >
          <ReportSettings
            setting={{
              name: report.setting.name,
              subscribeStatus: report.setting.isActive,
              frequency: report.setting.frequency,
              subscribeTimeRange: {
                since: report.setting.startDate,
                until: report.setting.endDate,
              },
              emails: report.setting.emails,
              handleReportSetting: (value) => {
                setreport({
                  ...report,
                  setting: {
                    ...report.setting,
                    ...value,
                  },
                })
              },
            }}
          />
        </div>
      </ReportBlock>
      <h6 className="text-start mb-2 mt-4 text-lucaDark fw-bolder">
        STEP ❹ 匯 出 報 表
      </h6>
      <ReportBlock
        setting={{
          content: (
            <Row className="text-lucaDark ps-3">
              目前匯出進度為 95% ， 檔案大小約為 15.11 MB 。
            </Row>
          ),
        }}
      >
        <>
          {!progress && (
            <Row
              className="d-flex m-0 w-100 py-2 px-3"
              style={{ flexDirection: 'row' }}
            >
              <Col xs="auto" className="h6 px-0 my-auto text-lucaDark py-4">
                提示：請於報表匯出前，確認各報表設定內容及上方預覽是否正確。
              </Col>
              <Col xs="auto" className="px-0 ms-auto my-auto">
                <Button
                  onClick={() =>
                    handleShowWarn({
                      img: faExclamationCircle,
                      title: ' ',
                      titleEng: '',
                      text: (
                        <h5 className="text-lucaDark">
                          注意： 確定要清除所有設定嗎
                          <br />
                          未匯出的內容將會消失
                        </h5>
                      ),
                      shouldReturn: true,
                      footer: (
                        <>
                          <Button
                            variant="secondary"
                            onClick={() => handleWarnClose(false)}
                          >
                            取 消
                          </Button>
                          <Button
                            variant="luca"
                            onClick={() => handleWarnClose(true)}
                          >
                            確 定
                          </Button>
                        </>
                      ),
                    })
                  }
                  variant="secondary"
                >
                  清 除
                </Button>
                <Button
                  onClick={exportReport}
                  variant="lucaMid"
                  className="ms-2"
                >
                  儲 存
                </Button>
                <Button onClick={exportReport} variant="luca" className="ms-2">
                  儲 存 並 下 載
                </Button>
              </Col>
            </Row>
          )}
          {progress !== 0 && (
            <Row className="mx-0 w-100 p-3" style={{ flexDirection: 'row' }}>
              {progress === 100 ? (
                <div className="d-flex justify-content-center mb-3">
                  <h6>已成功匯出此報表！</h6>
                  <h6
                    className="text-luca"
                    onClick={() => setprogress(0)}
                    aria-hidden
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    點擊此處
                  </h6>
                  <h6>點擊此處</h6>
                  <h6>以重新匯出</h6>
                </div>
              ) : (
                <h6 className="text-center mb-3">報表匯出中，請稍候...</h6>
              )}
              <div className="d-flex position-relative mx-auto w-75">
                <ReportProcessor
                  setting={{
                    progress,
                  }}
                />
                {progress === 100 && (
                  <div className="fs-4">
                    <ReportFloatButton
                      setting={{
                        icon: faCheckCircle,
                        iconStyle: 'm-auto align-self-center text-luca',
                        title: '',
                        onClick: () => {},
                        style: {
                          width: '35px',
                          height: '100%',
                          right: '-35px',
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </Row>
          )}
        </>
      </ReportBlock>
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: (
            <div className="text-center">
              <div className="mb-4 mt-2">
                <FontAwesomeIcon
                  className="mx-auto display-1 text-luca"
                  icon={showWarn.img}
                />
              </div>
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: handleWarnClose,
        }}
      />
    </Container>
  )
}

AdsReporter.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AdsReporter
