import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import Image from 'react-bootstrap/esm/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import ReplyForm from './ReplyForm'
import { getStaticPath } from '../services/lucaFunctions'

function Reply(props) {
  const { setting } = props
  const { user, content, date, direction, files, download } = setting

  return (
    <Card className={`${direction} text-start my-3 px-5 py-4 mx-0 border-0`}>
      <Row className="fw-bolder text-luca h6 mb-0">
        <Col xs={6}>{user}</Col>
        <Col xs={6} className="text-end text-lucaLight">
          {date}
        </Col>
      </Row>
      <pre className="fs-7 text-lucaDark pt-3 pb-2 lh-lg">
        {content}
        {files &&
          files.map((f, i) => (
            <span
              key={`${i}_${f.fileName}`}
              className="row h7 fw-bolder mb-0 py-1 d-block"
            >
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="imgPreviewTips"
                    style={{ zIndex: '999999' }}
                  >
                    {f.fileName && (
                      <>
                        <Image
                          src={getStaticPath(f.fileName)}
                          className="p-2"
                          style={{
                            maxWidth: '300px',
                          }}
                        />
                        <br />
                      </>
                    )}
                    點 擊 下 載
                  </Tooltip>
                }
              >
                <span
                  style={{ cursor: 'pointer' }}
                  // title={`點 擊 下 載 ${f.fileName.split('_')[0]}`}
                  onClick={() => download(f.fileName)}
                  className="text-lucaLink"
                  aria-hidden
                >
                  <FontAwesomeIcon icon={faPaperclip} className="me-1" />
                  {f.fileName
                    ? f.fileName.substring(0, f.fileName.lastIndexOf('_'))
                    : 'error'}
                </span>
              </OverlayTrigger>
            </span>
          ))}
      </pre>
    </Card>
  )
}

function ReplyHistory(props) {
  const { setting } = props
  const { auth, replys, data, handleDataChange, handleSend, download } = setting
  return (
    <Card className="text-center w-100 px-6 py-3 border-0">
      <Accordion defaultActiveKey="0" className="mb-5 MessageRecord">
        <Accordion.Item eventKey="0">
          <Accordion.Header title="點 擊 以 收 合 留 言 區">
            <div className="d-flex w-100">
              <div className="mx-auto">
                <h6 className="text-lucaDark mb-1 text-center">留 言 紀 錄</h6>
                <p className="text-lucaDark my-0 fs-8">Message record</p>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <hr className="hrClass-dotted" />
            {/* <p className="text-lucaDark m-0 fs-7">Message record</p> */}
            {/* <Card.Body className="pt-0"> */}
            {replys &&
              replys.map((r, i) => (
                <Reply
                  key={i}
                  setting={{
                    ...r,
                    download,
                    direction:
                      r.user_id === auth.auth.user_id
                        ? 'bg-greyLight'
                        : 'bg-lucaLighter',
                  }}
                />
              ))}
            <ReplyForm
              setting={{
                data,
                handleDataChange,
                handleSend,
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* </Card.Body> */}
    </Card>
  )
}

Reply.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ReplyHistory.propTypes = {
  setting: PropTypes.shape(),
}

ReplyHistory.defaultProps = {
  setting: {
    replys: [
      {
        user: '系統管理員',
        title: '請回覆',
        content: '您好，請儘速回覆此通知，謝謝。',
      },
      {
        user: '{user.name}',
        title: '附檔寄送',
        content: '您好，請參閱附檔。',
      },
      {
        user: '系統管理員',
        title: '已收到您的回覆',
        content: '已收到您的回覆，請稍待幾日工作天並留意後續通知，謝謝。',
      },
    ],
  },
}

export default ReplyHistory
