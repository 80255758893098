import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faStar,
  faCircle,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons'
import LineChart from '../Insights/LineChart'

function Detail(props) {
  const { setting } = props
  const {
    key,
    data,
    preview,
    deliveryEstimate,
    ref,
    selectSet,
    handleSetChange,
    size,
  } = setting
  const scale = ref.current && size && ref.current.clientWidth / size.width
  const targetBudget = useMemo(() => {
    if (deliveryEstimate && deliveryEstimate[data]) {
      const last = deliveryEstimate[data].data.daily_outcomes_curve.filter(
        (doc) => doc.spend <= selectSet.lifetime_budget
      ).length
      return deliveryEstimate[data].data.daily_outcomes_curve[last - 1]
    }
    return {
      spend: 0,
      impressions: 0,
      reach: 0,
      actions: 0,
    }
  }, [deliveryEstimate])

  switch (key) {
    case 'promoted_object':
      return (
        <>
          {data.page_id && <p>{`粉絲專頁：${data.page_id}`}</p>}
          {data.pixel_id && <p>{`像素專頁：${data.pixel_id}`}</p>}
          {data.custom_event_type && (
            <p>{`事件類型：${data.custom_event_type}`}</p>
          )}
        </>
      )
    case 'targeting':
      return (
        <ListGroup
          className="text-targeting scrollbarShow"
          style={{ maxHeight: '76.7vh', overflowY: 'auto' }}
        >
          <p>自訂受眾</p>
          <ListGroup>
            {data.custom_audiences &&
              data.custom_audiences.map((audience, i) => (
                <ListGroupItem key={i}>{audience.name}</ListGroupItem>
              ))}
          </ListGroup>
          <hr />
          <p>排除自訂受眾</p>
          <ListGroup>
            {data.excluded_custom_audiences &&
              data.excluded_custom_audiences.map((audience, i) => (
                <ListGroupItem key={i}>{audience.name}</ListGroupItem>
              ))}
          </ListGroup>
          <hr />
          <p>目標客群</p>
          <span>年齡: {`${data.age_min} - ${data.age_max}`}</span>
          {data.device_platforms && (
            <span>
              `裝置：${data.device_platforms.map((element) => element)}`
            </span>
          )}
          {data.flexible_spec &&
            data.flexible_spec.map((spec, i) => (
              <React.Fragment key={i}>
                {Object.keys(spec).map((specKey, j) => (
                  <React.Fragment key={`${i}_${j}`}>
                    <p>{`${specKey}:`}</p>
                    <ListGroup>
                      {spec[specKey].map((element, k) => (
                        <ListGroupItem key={`${i}_${j}_${k}`}>
                          <p>{element.name}</p>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
        </ListGroup>
      )
    case 'creative':
      return <p>廣告創意:{data.id || '廣告規格'}</p>
    case 'pacing_type':
      return (
        <>
          <Form.Label>{key}</Form.Label>
          <Form.Control
            name={key}
            value={selectSet[key].join('/')}
            onChange={handleSetChange}
          />
        </>
      )
    case 'configured_status':
    case 'effective_status':
    case 'id':
    case 'name':
    case 'status':
    case 'objective':
    case 'billing_event':
    case 'campaign_id':
    case 'adset_id':
    case 'daily_budget':
    case 'lifetime_budget':
    case 'optimization_goal':
    case 'end_time':
    case 'start_time':
    case 'stop_time':
    case 'bid_strategy':
      return (
        <>
          <Form.Label className="pt-2 mb-1 text-luca fw-bold">{key}</Form.Label>
          <Form.Control
            name={key}
            value={selectSet[key]}
            onChange={handleSetChange}
          />
        </>
      )
    case 'preview':
      return ref.current ? (
        <iframe
          title="preview"
          src={preview}
          width={size.width}
          height={size.height}
          style={{
            transform: `scale(${scale}) translate(${
              (size.width * scale - size.width) / 2
            }px, ${(size.height * scale - size.height) / 2}px)`,
          }}
        />
      ) : (
        <div />
      )
    case 'positivity':
      return (
        <>
          <Row style={{ borderTop: 'unset' }}>
            <ButtonGroup size="md" className="px-5 mb-2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 350, hide: 150 }}
                overlay={
                  <Tooltip
                    className="quesTip"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <span>
                      以最大化曝光人數或是觸及次數為主進行推播，將廣告投遞給最有可能記住品牌或該廣告的受眾。
                    </span>
                  </Tooltip>
                }
              >
                <Button
                  className="rounded-pill rounded-end"
                  variant={
                    selectSet.selectedObject === 'impressions'
                      ? 'lucaSwitch-active'
                      : 'lucaSwitch'
                  }
                  value="impressions"
                  name="selectedObject"
                  onClick={() =>
                    handleSetChange({
                      selectedObject: 'impressions',
                      recommendState: '',
                    })
                  }
                >
                  品牌認知
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 350, hide: 150 }}
                overlay={
                  <Tooltip
                    className="quesTip"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <span>
                      以投放流量為主進行推播，含括使用者互動，按讚、留言、分享、活動回覆、應用程式安裝等。
                    </span>
                  </Tooltip>
                }
              >
                <Button
                  variant={
                    selectSet.selectedObject === 'reach'
                      ? 'lucaSwitch-active'
                      : 'lucaSwitch'
                  }
                  value="reach"
                  name="selectedObject"
                  onClick={() =>
                    handleSetChange({
                      selectedObject: 'reach',
                      recommendState: '',
                    })
                  }
                >
                  觸動考量
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 350, hide: 150 }}
                overlay={
                  <Tooltip
                    className="quesTip"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <span>
                      主要目的為促使受眾執行特定行為，如：加入購物車、開始結帳、購買等行為，並以此為目標進行廣告投遞（注意：部分功能需解鎖並埋設Facebook
                      Pixel）。
                    </span>
                  </Tooltip>
                }
              >
                <Button
                  className="rounded-pill rounded-start"
                  variant={
                    selectSet.selectedObject === 'actions'
                      ? 'lucaSwitch-active'
                      : 'lucaSwitch'
                  }
                  value="actions"
                  name="selectedObject"
                  onClick={() =>
                    handleSetChange({
                      selectedObject: 'actions',
                      recommendState: '',
                    })
                  }
                >
                  轉換行動
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Row>
          {deliveryEstimate && (
            <>
              <Row className="d-flex" style={{ borderTop: 'unset' }}>
                <Col xs={6} className="px-3 text-center">
                  <h6 className="pt-3 text-lucaDark">當 前 選 擇</h6>
                  <LineChart
                    setting={{
                      ...size,
                      showControl: false,
                      data: deliveryEstimate[
                        selectSet.optimization_goal || data
                      ].data.daily_outcomes_curve.map((d, index) => ({
                        ...d,
                        spend: d.spend / 1000,
                        impressions: d.impressions / 1000,
                        reach: d.reach / 1000,
                        actions: d.actions / 1000,
                        date: index,
                      })),
                      selectedField: [
                        'spend',
                        'reach',
                        'impressions',
                        'actions',
                      ],
                    }}
                  />
                </Col>
                <Col xs={6} className="px-3 text-center">
                  <h6 className="pt-3 text-lucaDark">
                    <FontAwesomeIcon className="text-warning" icon={faStar} />
                    <FontAwesomeIcon className="text-warning" icon={faStar} />
                    <FontAwesomeIcon className="text-warning" icon={faStar} />
                    &ensp;系 統 建 議
                  </h6>
                  <LineChart
                    setting={{
                      ...size,
                      showControl: false,
                      data: deliveryEstimate[
                        deliveryEstimate.max[selectSet.selectedObject].key ||
                          selectSet.optimization_goal ||
                          data
                      ].data.daily_outcomes_curve.map((d, index) => ({
                        ...d,
                        spend: d.spend / 1000,
                        impressions: d.impressions / 1000,
                        reach: d.reach / 1000,
                        actions: d.actions / 1000,
                        date: index,
                      })),
                      selectedField: [
                        'spend',
                        'reach',
                        'impressions',
                        'actions',
                      ],
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ borderTop: 'unset' }}>
                <Col className="pt-2 px-5 text-lucaDark lh-lg" xs={6}>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#3fc3cab3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">最大花費</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[selectSet.optimization_goal || data]
                          .max_estimate.spend / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">＄</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#b961a9b3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估曝光</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[selectSet.optimization_goal || data]
                          .max_estimate.impressions / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">次</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#88bc58b3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估觸及</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[selectSet.optimization_goal || data]
                          .max_estimate.reach / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">人</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#6d74aeb3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估轉換</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[selectSet.optimization_goal || data]
                          .max_estimate.actions / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">次</h6>
                  </div>
                </Col>
                <Col className="pt-2 px-5 text-lucaDark lh-lg" xs={6}>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#3fc3cab3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">最大花費</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[
                          deliveryEstimate.max[selectSet.selectedObject].key ||
                            selectSet.optimization_goal ||
                            data
                        ].max_estimate.spend / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">＄</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#b961a9b3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估曝光</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[
                          deliveryEstimate.max[selectSet.selectedObject].key ||
                            selectSet.optimization_goal ||
                            data
                        ].max_estimate.impressions / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">次</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#88bc58b3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估觸及</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[
                          deliveryEstimate.max[selectSet.selectedObject].key ||
                            selectSet.optimization_goal ||
                            data
                        ].max_estimate.reach / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">人</h6>
                  </div>
                  <div className="d-flex my-auto">
                    <FontAwesomeIcon
                      style={{ color: '#6d74aeb3' }}
                      icon={faCircle}
                      className="fs-8 pt-1"
                    />
                    <h6 className="ms-2">預估轉換</h6>
                    <h4 className="ms-auto">
                      {(
                        deliveryEstimate[
                          deliveryEstimate.max[selectSet.selectedObject].key ||
                            selectSet.optimization_goal ||
                            data
                        ].max_estimate.actions / 1000
                      ).toFixed(0)}
                    </h4>
                    <h6 className="ps-2 my-auto">次</h6>
                  </div>
                </Col>
              </Row>
              <hr className="mb-1" style={{ border: '1px dashed #dbfffc' }} />
              {selectSet.recommendState.includes('confirmed') && (
                <Row
                  className="d-flex px-5 py-2"
                  style={{ borderTop: 'unset' }}
                >
                  <Col className="d-flex fs-6 text-luca ms-auto my-auto">
                    <h3 className="my-auto">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </h3>
                    <h6 className="my-auto ps-3 py-3 lh-md">
                      成功套用系統建議之投放設定！已修改最佳化目標為
                      {selectSet.recommendState.split('_')[0]}。
                    </h6>
                  </Col>
                </Row>
              )}
              {selectSet.recommendState.includes('rejected') && (
                <Row
                  className="d-flex px-5 py-2"
                  style={{ borderTop: 'unset' }}
                >
                  <Col className="d-flex fs-6 text-luca ms-auto my-auto">
                    <h3 className="my-auto">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </h3>
                    <h6 className="my-auto ps-3 py-3 lh-md">
                      未套用系統建議之投放設定，若要再次選擇，請重新點選上方投放目標。
                    </h6>
                  </Col>
                </Row>
              )}
              {!selectSet.recommendState && (
                <Row
                  className="d-flex px-5 py-2"
                  style={{ borderTop: 'unset' }}
                >
                  <Col
                    xs={10}
                    className="d-flex fs-6 text-luca ms-auto my-auto"
                  >
                    <h3 className="my-auto">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </h3>
                    <h6 className="my-auto ps-3 py-3 lh-md">
                      系統建議之投放設定最佳化比率為&ensp;
                      {deliveryEstimate[selectSet.optimization_goal || data]
                        .max_estimate[selectSet.selectedObject]
                        ? (
                            (deliveryEstimate[
                              deliveryEstimate.max[selectSet.selectedObject].key
                            ].max_estimate[selectSet.selectedObject] /
                              deliveryEstimate[
                                selectSet.optimization_goal || data
                              ].max_estimate[selectSet.selectedObject]) *
                            100
                          ).toFixed(2)
                        : 0}
                      % ，是否採用此設定?（注意: 此操作將改變投放目標內容）
                    </h6>
                  </Col>
                  <Col
                    xs={1}
                    className="text-lucaYes h6 my-auto"
                    onClick={() =>
                      handleSetChange({
                        recommendState: `${selectSet.selectedObject}_confirmed`,
                      })
                    }
                  >
                    是 <FontAwesomeIcon icon={faCircleCheck} />
                  </Col>
                  <Col
                    xs={1}
                    className="text-lucaNo h6 my-auto"
                    onClick={() => {
                      handleSetChange({ recommendState: 'rejected' })
                    }}
                  >
                    否 <FontAwesomeIcon icon={faCircleXmark} />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )
    case 'budget_estimate':
      return deliveryEstimate && targetBudget.spend !== 0 ? (
        <>
          <Form.Label className="pb-2 mb-1 text-danger fw-bold h6 text-center">
            注意：當前成效受到預算限制，建議進行調配以達到最佳化成效。
          </Form.Label>
          <Row className="d-flex flex-column text-lucaDark py-3">
            <Col className="d-flex my-2">
              <h6 className="ms-2">提昇預算</h6>
              <h4 className="ms-auto">
                {(
                  deliveryEstimate[selectSet.optimization_goal || data]
                    .max_estimate.spend / selectSet.lifetime_budget
                ).toFixed(2)}
              </h4>
              <h6 className="ps-2 my-auto">％</h6>
            </Col>
            <Col className="d-flex my-2">
              <h6 className="ms-2">可提升曝光率</h6>
              <h4 className="ms-auto">
                {(
                  deliveryEstimate[selectSet.optimization_goal || data]
                    .max_estimate.impressions / targetBudget.impressions
                ).toFixed(2)}
              </h4>
              <h6 className="ps-2 my-auto">％</h6>
            </Col>
            <Col className="d-flex my-2">
              <h6 className="ms-2">可提升觸擊人數</h6>
              <h4 className="ms-auto">
                {(
                  deliveryEstimate[selectSet.optimization_goal || data]
                    .max_estimate.reach / targetBudget.reach
                ).toFixed(2)}
              </h4>
              <h6 className="ps-2 my-auto">％</h6>
            </Col>
            <Col className="d-flex my-2">
              <h6 className="ms-2">可提升轉換次數</h6>
              <h4 className="ms-auto">
                {(
                  deliveryEstimate[selectSet.optimization_goal || data]
                    .max_estimate.actions /
                  (targetBudget.actions * 10)
                ).toFixed(2)}
              </h4>
              <h6 className="ps-2 my-auto">％</h6>
            </Col>
          </Row>
          {selectSet.budgetRecommendState.includes('confirmed') && (
            <Row className="d-flex px-5 py-2">
              <Col className="d-flex fs-6 text-luca ms-auto my-auto">
                <h3 className="my-auto">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </h3>
                <h6 className="my-auto ps-3 py-3 lh-md">
                  成功套用系統建議之投放設定！已修改預算為
                  {
                    deliveryEstimate[selectSet.optimization_goal || data]
                      .max_estimate.spend
                  }
                </h6>
              </Col>
            </Row>
          )}
          {selectSet.budgetRecommendState.includes('rejected') && (
            <Row className="d-flex px-5 py-2">
              <Col className="d-flex fs-6 text-luca ms-auto my-auto">
                <h3 className="my-auto">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </h3>
                <h6 className="my-auto ps-3 py-3 lh-md">
                  未套用系統建議之投放設定。
                </h6>
                <h6
                  className="my-auto ps-3 py-3 lh-md"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    handleSetChange({ budgetRecommendState: '' })
                  }}
                  aria-hidden
                >
                  按一下這裡重試
                </h6>
              </Col>
            </Row>
          )}
          {!selectSet.budgetRecommendState && (
            <Row>
              <Col xs={12} className="d-flex fs-6 text-luca ms-auto my-auto">
                <h3 className="my-auto">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </h3>
                <h6 className="my-auto ps-3 py-3 lh-md">
                  系統建議提高預算{' '}
                  {(
                    deliveryEstimate[selectSet.optimization_goal || data]
                      .max_estimate.spend / selectSet.lifetime_budget
                  ).toFixed(2)}
                  % （約＿＿＿＿美元）以提高成效 ，是否採用此設定?（注意:
                  此操作將改變投入預算）
                </h6>
              </Col>
              <Col
                className="text-lucaYes h6 d-flex justify-content-center"
                onClick={() =>
                  handleSetChange({
                    lifetime_budget:
                      deliveryEstimate[selectSet.optimization_goal || data]
                        .max_estimate.spend,
                    budgetRecommendState: `confirmed`,
                  })
                }
              >
                <p>
                  是 <FontAwesomeIcon icon={faCircleCheck} />
                </p>
              </Col>
              <Col
                className="text-lucaNo h6 d-flex justify-content-center"
                onClick={() => {
                  handleSetChange({ budgetRecommendState: 'rejected' })
                }}
              >
                <p>
                  否 <FontAwesomeIcon icon={faCircleXmark} />
                </p>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Form.Label className="pt-2 mb-1 text-luca fw-bold">
          提示：目前無預算調配建議。
        </Form.Label>
      )
    default:
      return <div />
  }
}

function DetailCard(props) {
  const { setting } = props
  const {
    title,
    headers,
    data,
    preview,
    deliveryEstimate,
    show,
    subData,
    size,
    selectSet,
    handleSetChange,
  } = setting

  const ref = useRef(null)

  return (
    <Modal.Dialog
      className="w-100"
      style={{
        maxWidth: '100%',
      }}
    >
      <Modal.Header
        id="form-dialog-title"
        className="m-auto border-0 h5 text-lucaDark"
      >
        {title}
      </Modal.Header>
      <Modal.Body className="w-100">
        <ListGroup
          ref={ref}
          className="selectGroup w-100"
          style={{
            height:
              show && ref.current && size
                ? `${size.height * (ref.current.clientWidth / size.width)}px`
                : '',
          }}
        >
          {headers
            .filter((key) => show || data[key])
            .map((key) => (
              <Detail
                key={key}
                setting={{
                  key,
                  data: data[key] || data[subData],
                  preview,
                  deliveryEstimate,
                  ref,
                  selectSet,
                  handleSetChange,
                  size,
                }}
              />
            ))}
        </ListGroup>
      </Modal.Body>
    </Modal.Dialog>
  )
}

function AdsEditDialog(props) {
  const { show, setting } = props
  const {
    size,
    active,
    provider,
    activetab,
    preview,
    deliveryEstimate,
    handleClose,
    selectSet,
    handleSetChange,
  } = setting

  const cards = {
    facebook: {
      campaign: [
        [
          {
            title: '行銷活動名稱',
            headers: ['id', 'name'],
          },
          {
            title: '行銷活動詳情',
            headers: ['objective', 'status'],
          },
        ],
        [
          {
            title: '行銷活動預算最佳化',
            headers: ['bid_strategy', 'lifetime_budget', 'pacing_type'],
          },
        ],
      ],
      adset: [
        [
          {
            title: '廣告組合名稱',
            headers: ['id', 'name'],
          },
          {
            title: '廣告組合詳情',
            headers: ['promoted_object', 'status'],
          },
          {
            title: '廣告受眾',
            headers: ['targeting'],
          },
          {
            title: '最佳化與投遞',
            headers: ['optimization_goal'],
          },
        ],
        [
          {
            title: '預算與排程',
            headers: [
              'billing_event',
              'daily_budget',
              'lifetime_budget',
              'start_time',
              'stop_time',
            ],
            deliveryEstimate,
          },
          {
            title: '預算調配與建議',
            headers: ['budget_estimate'],
            show: true,
            subData: ['optimization_goal'],
            deliveryEstimate,
          },
        ],
        [
          {
            title: '投放目標與預測',
            headers: ['positivity'],
            show: true,
            deliveryEstimate,
            size: {
              width: 540,
              height: 300,
            },
            subData: ['optimization_goal'],
          },
        ],
      ],
      ad: [
        [
          {
            title: '廣告名稱',
            headers: ['id', 'name'],
          },
          {
            title: '廣告詳情',
            headers: ['status'],
          },
          {
            title: '廣告創意',
            headers: ['creative'],
          },
          // {
          //   title: '廣告預覽',
          //   headers: [],
          // },
        ],
        [
          {
            title: '廣告預覽',
            headers: ['preview'],
            preview,
            show: preview !== null && preview !== undefined,
            size: {
              width: 540,
              height: 760,
            },
          },
        ],
      ],
    },
    google: {
      campaign: [],
      adset: [],
      ad: [],
    },
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header id="form-dialog-title" className="h5 text-lucaDark">
        編輯廣告內容
      </Modal.Header>
      <Modal.Body className="d-flex flex-wrap justify-content-center">
        {active &&
          cards[provider][activetab].map((cardGroup, i) => (
            <div
              className="mx-3 flex-grow-1"
              style={{
                width: `${
                  90 / Math.min(cards[provider][activetab].length, 2)
                }%`,
              }}
              key={i}
            >
              {cardGroup
                .filter(
                  (card) => card.show || card.headers.some((h) => active[h])
                )
                .map((card, j) => (
                  <DetailCard
                    key={`${i}_${j}`}
                    setting={{
                      ...card,
                      data: active,
                      selectSet,
                      handleSetChange,
                    }}
                  />
                ))}
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => handleClose(false)} variant="secondary">
          取 消
        </Button>
        <Button
          onClick={() => handleClose(true)}
          variant="luca"
          // disabled={select >= -1}
        >
          完 成
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

Detail.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DetailCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AdsEditDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

export default AdsEditDialog
