/* eslint-disable no-promise-executor-return */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faReply,
  faTrashAlt,
  faStar as solidStar,
} from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { NotiContext } from './ContextProvider'
import apiService from '../services/apiService'
import {
  LUCAAuthChanged,
  LUCABmAuthChanged,
  LUCASended,
  LUCAApplyAgree,
  LUCAApplyReject,
  LUCAChatting,
  LUCABudgetAgree,
  LUCABudgetSended,
  LUCASub,
  LUCAWarning,
  LUCAUpgrade,
  bellSolid,
} from '../assets'

function Blocks(props) {
  const { setting } = props
  const {
    auth,
    hasCheckBox,
    checked,
    handleUpdate,
    handleSelect,
    active,
    method,
    content,
  } = setting
  const { flag, status, name, notification_id, created_on } = content
  const targetBM =
    (content.form_setting &&
      auth.accounts.find(
        (a) => a.business_id === content.form_setting.business_id
      )) ||
    auth.accounts.find((a) => a.business_id === content.setting.business_id)
  return (
    <Container
      className={`AdsBlocks py-3 px-0 mb-0 NoticeSelector ${
        active ? 'w-95 bg-luca2 text-light h6' : 'w-95 text-lucaDark h6'
      }`}
      fluid
    >
      <Row>
        <Col xs={1} className="d-flex pe-0">
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className="m-auto align-self-center btn-lucaIcon2"
            title="標 記"
            onClick={() => handleUpdate({ flag: !flag }, notification_id)}
            icon={flag ? solidStar : faStar}
          />
        </Col>
        <Col xs={11}>
          <Form.Check
            type="checkbox"
            className={`d-flex text-start align-content-start mb-0 fs-7 ${
              status === 'Read' && 'text-lucaLight'
            }`}
            title="全 選"
          >
            {hasCheckBox && (
              <Form.Check.Input
                style={{ cursor: 'pointer' }}
                className="my-auto"
                type="checkbox"
                title="選 取"
                checked={checked}
                onChange={() => handleSelect(notification_id)}
              />
            )}
            <Form.Check.Label
              style={{ cursor: 'pointer' }}
              className="my-auto ms-1 App-oneLineEllipsis px-2"
              onClick={() => method(notification_id)}
              title={name.title}
            >
              {name.title}
            </Form.Check.Label>
            {targetBM && (
              <span className="my-auto pe-2 ms-auto">
                {targetBM.name.length > 4
                  ? `${targetBM.name.substring(0, 4)}...`
                  : targetBM.name}
              </span>
            )}
            <span className={`my-auto pe-2 ${targetBM ? '' : 'ms-auto'}`}>
              {created_on.split('T')[0].substring(2)}
            </span>
            <span
              style={{
                fontSize: '.5rem',
                opacity: status === 'Unread' ? '1' : '0',
              }}
              className="my-auto text-danger pe-2"
            >
              ●
            </span>
          </Form.Check>
        </Col>
      </Row>
    </Container>
  )
}

function Tools(props) {
  const { setting } = props
  const {
    leftTools,
    rightTools,
    hasCheckBox,
    active,
    handleSelectAll,
    reading,
    handleReturn,
    selectedAll,
  } = setting
  return (
    <Container
      className={`AdsBlocks mb-0 py-1 d-grid ${
        active ? 'w-95 bg-luca2 text-light h6' : 'w-95 text-lucaDark h6'
      }`}
      fluid
    >
      <Row>
        <Col xs={1} className="ps-0">
          {reading.show && (
            <Button className="btn-outline-lucaLight px-1 py-0">
              <FontAwesomeIcon
                icon={faReply}
                onClick={handleReturn}
                title="返 回 通 知 列 表"
              />
            </Button>
          )}
        </Col>
        <Col xs={11} className="pe-0">
          <Form.Check
            type="checkbox"
            className="d-flex text-start AdsToolbar align-content-center mb-0"
          >
            {hasCheckBox && (
              <Form.Check.Input
                onChange={handleSelectAll}
                checked={selectedAll}
                className="my-auto"
                type="checkbox"
                title="全 選"
              />
            )}
            {leftTools.map((tool) => (
              <Button
                className="ms-3 py-0 fs-7"
                variant="outline-lucaLight"
                key={tool.id}
                onClick={tool.method}
              >
                {tool.name || tool.icon}
              </Button>
            ))}
            <div className="my-auto ms-auto" />
            {rightTools.map((tool) => (
              <Button
                className="ms-3 py-0 px-2 fs-7"
                variant="outline-lucaLight"
                key={tool.id}
                onClick={tool.method}
              >
                {tool.name || tool.icon}
              </Button>
            ))}
          </Form.Check>
        </Col>
      </Row>
    </Container>
  )
}

function Detail(props) {
  const { setting } = props
  const { auth, handleAuthChange, handleReturn, name, link, img, business_id } =
    setting
  const imgRoute = {
    LUCAAuthChanged,
    LUCABmAuthChanged,
    LUCASended,
    LUCAApplyAgree,
    LUCAApplyReject,
    LUCAChatting,
    LUCABudgetAgree,
    LUCABudgetSended,
    LUCASub,
    LUCAWarning,
    LUCAUpgrade,
  }

  const targetBM = auth.accounts.find((a) => a.business_id === business_id)

  return (
    <Card className="px-0">
      <Card.Header
        className="w-100 text-center fw-bold text-lucaDark py-3 border-0 App-oneLineEllipsis px-5"
        style={{ backgroundColor: '#c9eee95c' }}
        title={name.title}
      >
        <FontAwesomeIcon icon={faEnvelope} className="mx-2" /> {name.title}
      </Card.Header>
      <Card.Body
        style={{ whiteSpace: 'break-spaces' }}
        className="py-4 px-5 lh-md text-center"
      >
        <Image src={imgRoute[img || 'LUCAAuthChanged']} fluid />
        {name.html ? (
          <div dangerouslySetInnerHTML={{ __html: name.html }} />
        ) : (
          <Card.Text className="text-lucaDark py-3">{name.text}</Card.Text>
        )}

        {name.reason && (
          <Card.Text className="text-danger fs-6 fw-bolder pt-2 pb-3">
            退件原因： {name.reason}
          </Card.Text>
        )}
        <Button
          title={name.btnText}
          id="dropdown-autoclose-false"
          variant="lucaLight ropdown-toggled"
          onClick={() => {
            if (link.includes(':')) handleAuthChange('', link)
            if (targetBM) handleAuthChange(business_id, link)
            handleReturn()
          }}
        >
          {name.btnText}
        </Button>

        <Card.Text
          style={{ lineHeight: '0', fontSize: '0.7rem' }}
          className="mt-5 mb-3 text-grey"
        >
          From Automated Notification System of LUCA.
          <hr className="my-2" />
          Copyright © 2023 Wavenet. all rights reserved.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

function Notification(props) {
  const { setting } = props
  const { auth, handleAuthChange } = setting

  const notification = useContext(NotiContext)

  // handle notifications
  const [selected, setselected] = useState([])
  const handleDelete = async (id) => {
    await apiService.data({
      path: `luca/notification`,
      method: 'delete',
      data: { ids: id ? [id] : selected },
    })
    setselected([])
  }
  const handleUpdate = async (value, id) => {
    await apiService.data({
      path: `luca/notification`,
      method: 'put',
      data: { ids: id ? [id] : selected, value },
    })
    setselected([])
  }

  const [notiState, setnotiState] = useState({
    reading: {
      show: false,
      id: '',
    },
    flagOnly: false,
    desc: false,
  })

  const { reading, flagOnly, desc } = notiState

  const handleRead = (id) => {
    const target = notification.find((c) => c.notification_id === id)
    if (target) {
      setnotiState({
        ...notiState,
        reading: {
          show: true,
          id,
        },
      })
      handleUpdate({ status: 'Read' }, id)
    }
  }
  const readingData = notification.find((c) => c.notification_id === reading.id)
  const handleReturn = () => {
    setnotiState({ ...notiState, reading: { ...reading, show: false } })
  }

  const filteredContent = notification
    .filter((c) => !flagOnly || c.flag)
    .sort((a, b) =>
      moment(b.created_on).isBefore(moment(a.created_on)) || desc ? -1 : 1
    )

  const handleSelect = (id) =>
    selected.includes(id)
      ? setselected(selected.filter((s) => s !== id))
      : setselected([...selected, id])
  const selectedAll = selected.length === filteredContent.length

  const handleSelectAll = () => {
    setselected(
      selectedAll
        ? []
        : [
            ...selected,
            ...filteredContent
              .filter((c) => !selected.includes(c.id))
              .map((c) => c.id),
          ]
    )
    return filteredContent.filter((id) => !selected.includes(id))
  }
  return (
    <Navbar.Collapse id="navbar-nav-bell">
      <Nav className="nav-icons ms-auto me-3 noDropdownIcon">
        <NavDropdown
          title={<Image src={bellSolid} alt="User" title="通 知" />}
          align="end"
          id="nav-dropdown-bell"
          autoClose
        >
          <Container className="App-oneLineEllipsis overflow-hidden">
            <Row>
              <Tools
                setting={{
                  hasCheckBox: !reading.show,
                  handleSelectAll,
                  selectedAll,
                  leftTools: !reading.show
                    ? [
                        {
                          id: 'read',
                          type: 'button',
                          name: '標示為已讀',
                          method: () => handleUpdate({ status: 'Read' }),
                        },
                        {
                          id: 'unread',
                          type: 'button',
                          name: '標示為未讀',
                          method: () => handleUpdate({ status: 'Unread' }),
                        },
                      ]
                    : [],
                  rightTools:
                    reading.show && readingData
                      ? [
                          {
                            id: 'flag',
                            type: 'button',
                            name: readingData.flag ? '取消標記' : '標記此通知',
                            method: () =>
                              handleUpdate(
                                { flag: !readingData.flag },
                                reading.id
                              ),
                          },
                          {
                            id: 'trash',
                            type: 'button',
                            icon: (
                              <FontAwesomeIcon
                                title="刪 除"
                                icon={faTrashAlt}
                              />
                            ),
                            method: () => {
                              handleDelete(reading.id)
                              handleReturn()
                            },
                          },
                        ]
                      : [
                          {
                            id: 'flag',
                            type: 'button',
                            name: flagOnly ? '顯示全部' : '顯示星號',
                            method: () =>
                              setnotiState({
                                ...notiState,
                                flagOnly: !flagOnly,
                              }),
                          },
                          {
                            id: 'sort',
                            type: 'button',
                            name: `依時間 ${desc ? '↑' : '↓'}`,
                            method: () =>
                              setnotiState({ ...notiState, desc: !desc }),
                          },
                          {
                            id: 'trash',
                            type: 'button',
                            icon: (
                              <FontAwesomeIcon
                                title="刪 除"
                                icon={faTrashAlt}
                              />
                            ),
                            method: () => handleDelete(),
                          },
                        ],
                  reading,
                  handleReturn,
                }}
              />
            </Row>
            {/* <Dropdown.Divider className="mb-0" /> */}
            <Row
              className="mt-2 h-100 overflow-auto border-top"
              style={{ width: '45vw', maxHeight: '70vh', overflowY: 'auto' }}
            >
              {reading.show && readingData ? (
                <Detail
                  setting={{
                    auth,
                    handleAuthChange,
                    handleReturn,
                    ...readingData,
                    ...readingData.setting,
                  }}
                />
              ) : (
                <Col>
                  {filteredContent.map((c, i) => (
                    <Blocks
                      key={`${i}_${c.name}`}
                      setting={{
                        auth,
                        hasStar: true,
                        hasCheckBox: true,
                        checked: selected.includes(c.id),
                        handleUpdate,
                        handleSelect,
                        method: handleRead,
                        content: c,
                      }}
                    />
                  ))}
                </Col>
              )}
            </Row>
          </Container>
        </NavDropdown>
      </Nav>
      {/* Notice amount */}
      {notification.filter((n) => n.status === 'Unread').length > 0 && (
        <Badge className="bellBadge" bg="danger" pill>
          {notification.filter((n) => n.status === 'Unread').length}
        </Badge>
      )}
    </Navbar.Collapse>
  )
}

Blocks.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Tools.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Detail.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Notification.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Notification
