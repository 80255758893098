import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import DataView from './DataView'
import LineChart from './LineChart'
import CrossChart from './CrossChart'
import StackChart from './StackChart'
import PieChart from './PieChart'
import Wheel from './Wheel'
import {
  enums,
  getCriterions,
  toInsights,
  arrangeData,
} from '../../services/insightProcesser'
import { t } from '../../services/lucaFunctions'

function Insights(props) {
  const { setting } = props
  const {
    hasPreview,
    datas,
    auth,
    account_id = '',
    // adClusters,
    breakdowns,
    dateBreakdown,
    timeRange,
    fields,
    activetab,
    selected,
    insightType,
    sort,
    search = '',
    setsort,
    currency = '$',
    // setcsvData,
    provider,
    checkedList,
    setactiveid,
    selectedField,
    setselectedField,
    criterion,
    labelType,
    hasWheel,
    colors,
    hideAxis,
    backgroundColor,
    // xs,
  } = setting

  const criterionLabels = {
    facebook: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'ad_name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    google: {
      campaign: 'campaign.name',
      adset: 'ad_group.name',
      ad: 'ad_group_ad.ad.name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'segments.date',
      age: 'age',
      gender: 'gender',
    },
    twitter: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'ad_name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    criteo: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'ad_name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    linkedin: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'ad_name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
  }

  const [insightData, setinsightData] = useState({
    insight: null,
    total: null,
    currentType: insightType,
  })
  const { insight, total, currentType } = insightData

  const headers = (
    dateBreakdown === 'daily' ? [criterionLabels[provider].date] : []
  ).concat(breakdowns.concat(fields))

  const timestamp = useRef(Date.now())
  useEffect(() => {
    if (fields.length === 0) return
    if (setting.insight) {
      setinsightData({
        ...insightData,
        ...setting.insight,
      })
      return
    }
    const getInsight = async (ts) => {
      const res = await Promise.all(
        insightType === 'PieChart'
          ? [
              await arrangeData({
                account_id,
                business_id: auth.account.business_id,
                provider,
                since: moment(timeRange.since).format('yyyy-MM-DD'),
                until: moment(timeRange.until).format('yyyy-MM-DD'),
                level: activetab,
                breakdowns: ['age'],
                selected,
                fields,
                criterions: ['age'],
              }),
              await arrangeData({
                account_id,
                business_id: auth.account.business_id,
                provider,
                since: moment(timeRange.since).format('yyyy-MM-DD'),
                until: moment(timeRange.until).format('yyyy-MM-DD'),
                level: activetab,
                breakdowns: ['gender'],
                selected,
                fields,
                criterions: ['gender'],
              }),
            ]
          : [
              await arrangeData({
                account_id,
                business_id: auth.account.business_id,
                provider,
                since: moment(timeRange.since).format('yyyy-MM-DD'),
                until: moment(timeRange.until).format('yyyy-MM-DD'),
                level: activetab,
                breakdowns,
                selected,
                fields,
                criterions:
                  criterion ||
                  getCriterions({
                    type: insightType,
                    dateBreakdown,
                    provider,
                    activetab,
                    breakdowns,
                  }),
              }),
            ]
      )

      if (!res.error && ts === timestamp.current)
        setinsightData({
          ...insightData,
          ...toInsights({
            type: insightType,
            account_id,
            activetab,
            provider,
            origin: res,
            criterion,
            gt: res[0],
          }),
          currentType: insightType,
          ts,
        })
      // const getCsvData = () => {
      //   const header = ['name', ...headers].map((h) => t(h)).join()
      //   const data = res[0].datas[account_id]
      //     .map((i) =>
      //       [criterionLabels[provider][activetab], ...headers]
      //         .map((h) => `"${enums[provider].formatter(h)(i[h])}"` || '--')
      //         .join()
      //     )
      //     .join('\n')
      //   const footer = ['總計']
      //     .concat(
      //       [...headers].map(
      //         (h) =>
      //           `"${enums[provider].formatter(h)(res[0].datas.total[0][h])}"` ||
      //           '--'
      //       )
      //     )
      //     .join()

      //   return `${header}\n${data}\n${footer}`
      // }
      // if (insightType === 'Data') setcsvData(getCsvData())
    }
    setinsightData({
      ...insightData,
      insight: null,
      total: null,
      currentType: insightType,
    })
    const ts = Date.now()
    timestamp.current = ts
    getInsight(ts)
  }, [
    timeRange,
    fields,
    insightType,
    dateBreakdown,
    breakdowns,
    selected,
    activetab,
  ])

  const dataNames = useMemo(
    () =>
      datas
        ? datas.reduce(
            (prev, cur) => ({
              ...prev,
              [cur[`${activetab}.id`] || cur[`${activetab}_id`] || cur.id]:
                cur[`${activetab}.name`] ||
                cur[`${activetab}_name`] ||
                cur.name,
            }),
            {}
          )
        : {},
    [datas]
  )

  const names = useMemo(
    () =>
      insight
        ? insight.reduce(
            (prev, cur) => ({
              ...prev,
              [cur[`${activetab}.id`] || cur[`${activetab}_id`] || cur.id]:
                cur[`${activetab}.name`] ||
                cur[`${activetab}_name`] ||
                cur.name,
            }),
            {}
          )
        : {},
    [insight]
  )

  // svg size
  const ref = useRef(null)
  const [svgSize, setsvgSize] = useState(false)
  const getSize = () => {
    if (ref.current) {
      const style = getComputedStyle(ref.current)
      const height =
        (ref.current.clientHeight -
          parseFloat(style.paddingTop) -
          parseFloat(style.paddingBottom)) *
        (labelType === 'hide' ? 1 : 0.625)
      const width = ref.current.clientWidth
      return { width, height }
    }
    return false
  }
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const size = getSize()
      if (size.width !== svgSize.width || size.height !== svgSize.height)
        setsvgSize(size)
    })
    observer.observe(ref.current)
    return () => ref.current && observer.unobserve(ref.current)
  }, [])

  const filteredInsight = useMemo(() => {
    if (!search) return insight
    const n = { ...names, ...dataNames }
    return insight
      ? insight.filter((i) => {
          const name = n[i[enums[provider].criterions[activetab]]]
          return name && name.includes(search)
        })
      : []
  }, [insight, search])

  return (
    <>
      <Col
        ref={ref}
        className={`bottom-0 position-relative overflow-hidden h-100 w-100 me-0 ${
          insightType !== 'Data' && 'bg-transp'
        }`}
        style={{
          alignContent: 'start',
          cursor: 'col-resize',
        }}
      >
        {insight && currentType === insightType ? (
          <div
            style={{
              height: labelType === 'hide' ? '100%' : '62.5%',
            }}
          >
            {insightType === 'Data' && (
              <DataView
                setting={{
                  hasPreview,
                  account_id,
                  provider,
                  onHeadClick: (e) =>
                    setsort({
                      name: e.target.name,
                      order: sort.name === e.target.name && sort.order,
                    }),
                  onNameClick: (id) => setactiveid(id),
                  data: filteredInsight,
                  total,
                  headers,
                  names: { ...names, ...dataNames },
                  nameField: enums[provider].criterions[activetab],
                  backgroundColor,
                  currency,
                }}
              />
            )}
            {insightType === 'StackChart' && ref.current && (
              <StackChart
                setting={{
                  ...svgSize,
                  showControl: false,
                  datas: insight,
                  selectedField,
                  checkedList,
                  colors,
                  hideLabels: hideAxis,
                  provider,
                  nameField: criterionLabels[provider][activetab],
                }}
              />
            )}
            {insightType === 'LineChart' && ref.current && (
              <LineChart
                setting={{
                  ...svgSize,
                  showControl: false,
                  data: insight,
                  provider,
                  selectedField: [selectedField],
                }}
              />
            )}
            {insightType === 'CrossChart' && (
              <CrossChart
                setting={{
                  ...svgSize,
                  showControl: false,
                  data:
                    insight.length !== 0
                      ? insight
                      : [{ date: '1911-01-01', close: 0 }],
                  selectedField: [selectedField],
                  provider,
                  barFields: [fields[0], fields[1], fields[2]],
                }}
              />
            )}
            {insightType === 'PieChart' && ref.current && (
              <PieChart
                setting={{
                  ...svgSize,
                  breakdowns: criterion || ['age', 'gender'],
                  labels: criterion
                    ? criterion.map(() => 'percent')
                    : ['age', 'gender'],
                  datas: insight.map((a) => {
                    const percented = a.map((x) => ({
                      ...x,
                      pvalue:
                        (x[selectedField] / total[0][selectedField]) * 100,
                      percent: `${Math.round(
                        (x[selectedField] / total[0][selectedField]) * 100
                      )}%`,
                    }))
                    return percented
                      .filter((p) => p.pvalue > 2)
                      .concat([
                        percented
                          .filter((p) => p.pvalue <= 2)
                          .reduce(
                            (prev, cur) => ({
                              [selectedField]:
                                prev[selectedField] + cur[selectedField],
                              pvalue: prev.pvalue + cur.pvalue,
                              percent: `${Math.round(
                                prev.pvalue + cur.pvalue
                              )}%`,
                            }),
                            { [selectedField]: 0, pvalue: 0, percent: '0%' }
                          ),
                      ])
                  }),
                  showControl: false,
                  selectedField,
                  padding: 12,
                  donutThickness: 40,
                  color: [
                    '#FCBF49',
                    '#D62828',
                    '#247BA0',
                    '#59C3C3',
                    '#e88631',
                    '#B5AF8D',
                  ],
                }}
              />
            )}
          </div>
        ) : (
          <div className="h-100 m-auto d-flex justify-content-center">
            <Spinner
              className="my-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        )}
        {insight && labelType !== 'hide' && currentType === insightType && (
          <>
            {insightType === 'PieChart' && (
              <div
                className="d-flex card border-0 flex-row px-3 pb-2"
                style={{
                  borderRadius: '0 0 0.25rem 0.25rem',
                  height: '15vh',
                }}
              >
                {(criterion || ['age', 'gender']).map((b, i) => (
                  <div
                    key={i}
                    className={`d-flex flex-column flex-wrap overflow-auto h-100 ${labelType}`}
                    style={{
                      width: `${
                        Math.round(1 / (criterion ? criterion.length : 2)) * 100
                      }%`,
                    }}
                  >
                    {insight[i].map((a, j) => (
                      <span
                        className="lh-md fw-normal text-lucaDark text-start ps-3 fs-7 App-oneLineEllipsis"
                        style={{ maxWidth: '100%', minHeight: '3vh' }}
                        key={j}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="my-auto m-2"
                          style={{
                            fontSize: '.8rem',
                            color: [
                              '#B10905',
                              '#F7CC72',
                              '#44778D',
                              '#59C3C3',
                              '#e88631',
                              '#B5AF8D',
                            ][j],
                          }}
                        />
                        {a[criterionLabels[provider][b]] || '其他'}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            )}
            {insightType === 'LineChart' && (
              <div
                className={`card border-0 px-3 flex-grow-1 d-flex flex-column flex-wrap overflow-auto ${labelType}`}
                style={{
                  borderRadius: '0 0 0.25rem 0.25rem',
                  height: '15vh',
                }}
              >
                <span
                  className="lh-md fw-normal text-lucaDark text-start pt-2 ps-4 fs-7"
                  style={{ maxWidth: '100%', minHeight: '3vh' }}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="my-auto me-2"
                    style={{
                      fontSize: '.8rem',
                      color: [
                        '#FCBF49',
                        '#D62828',
                        '#247BA0',
                        '#59C3C3',
                        '#e88631',
                        '#B5AF8D',
                      ][3],
                    }}
                  />
                  {t(selectedField)}:{total[0][selectedField]}
                </span>
              </div>
            )}
            {insightType === 'CrossChart' && (
              <div
                className={`card border-0 px-3 flex-grow-1 d-flex flex-column flex-wrap overflow-auto ${labelType}`}
                style={{
                  borderRadius: '0 0 0.25rem 0.25rem',
                  height: '15vh',
                }}
              >
                {[fields[0], fields[1], fields[2]].map((f, i) => (
                  <span
                    key={i}
                    className="lh-md fw-normal text-lucaDark text-start pt-2 ps-4 fs-7"
                    style={{ maxWidth: '100%', minHeight: '3vh' }}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="my-auto me-2"
                      style={{
                        fontSize: '.8rem',
                        color: ['#3fc3ca', '#F7CC72', '#6d74aedb', '#59C3C3'][
                          i
                        ],
                      }}
                    />
                    {t(f)}:{total[0][f]}
                  </span>
                ))}
              </div>
            )}
            {insightType === 'StackChart' && (
              <div
                className={`card border-0 text-start px-3 pt-2 pb-3 ps-4 d-flex flex-column flex-wrap overflow-auto ${labelType}`}
                style={{
                  borderRadius: '0 0 0.25rem 0.25rem',
                  height: '15vh',
                }}
              >
                {insight.map((a, i) => (
                  <span
                    className="lh-md fw-normal text-lucaDark text-start ps-3 fs-7 App-oneLineEllipsis"
                    style={{ maxWidth: '100%', minHeight: '3vh' }}
                    key={i}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="my-auto me-2"
                      style={{
                        fontSize: '.8rem',
                        color: ['#F2907B', '#72C5D1', '#F2D57B'][i % 3],
                      }}
                    />
                    {a[0].account_name || a[0].campaign_name || i}
                  </span>
                ))}
              </div>
            )}
          </>
        )}
      </Col>
      {insightType !== 'Data' && hasWheel && (
        <Col xs={2} className="card GradiWheel ms-2" style={{ zIndex: '0' }}>
          <Wheel
            setting={{
              fields,
              selectedField,
              setselectedField,
              style: {
                top: '90px',
                right: '0%',
                zIndex: '100',
                width: '100%',
              },
            }}
          />
        </Col>
      )}
    </>
  )
}

Insights.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Insights
