import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import apiService from '../services/apiService'

function VirtualAccount({ setting }) {
  const { bankCode, business_id } = setting
  const [va, setva] = useState({
    virtualAccounts: [],
  })
  const { virtualAccounts } = va

  useEffect(() => {
    const getVA = async () => {
      const res = await apiService.data({
        path: `luca/budget/account/${business_id}`,
        method: 'get',
      })
      setva(res)
    }
    getVA()
  }, [])
  const prepared = useMemo(
    () =>
      (virtualAccounts &&
        virtualAccounts.find((a) => a.bankCode === bankCode)) ||
      false,
    [virtualAccounts, bankCode]
  )

  return prepared ? prepared.accountNumber : '取得中...'
}

VirtualAccount.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default VirtualAccount
