import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

function Searchbar(props) {
  const { setting } = props
  const {
    id,
    size,
    extraClass,
    title,
    action,
    tempSearch,
    settempSearch,
    method,
    hasIcon = true,
  } = setting
  const [focus, setfocus] = useState(false)

  return (
    <InputGroup id={id} className={`searchBar${extraClass || ''}`} size={size}>
      <FormControl
        placeholder={title}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        value={tempSearch}
        onChange={(event) => settempSearch(event.target.value)}
        onFocus={() => setfocus(true)}
        onBlur={() => setfocus(false)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.nativeEvent.isComposing && focus)
            method()
        }}
      />
      {hasIcon && (
        <Button
          variant="luca"
          id="button-addon2"
          title="搜 尋"
          onClick={() => method()}
        >
          {action || <FontAwesomeIcon icon={faSearch} />}
        </Button>
      )}
    </InputGroup>
  )
}

Searchbar.propTypes = {
  setting: PropTypes.shape(),
}

Searchbar.defaultProps = {
  setting: {
    title: '請輸入廣告帳戶名稱...',
    action: <FontAwesomeIcon icon={faSearch} />,
    method: () => {},
  },
}

export default Searchbar
