import React, { useState, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { AdsReporter, ADMReporter, ReportList } from '../components/AdsReporter'
import { AuthContext } from '../components/ContextProvider'

function Reports() {
  const auth = useContext(AuthContext)
  const [action, setaction] = useState(
    auth.account.business_id === 1 ? 'ADMReporter' : 'ReportList'
  )
  const [selected, setselected] = useState(null)
  const handleActionChange = (params) => {
    setaction(params.action)
    setselected(params.selected || null)
  }
  const actions = {
    ADMReporter: <ADMReporter setting={{ auth, handleActionChange }} />,
    ReportList: <ReportList setting={{ auth, handleActionChange }} />,
    AdsReporter: (
      <AdsReporter setting={{ auth, selected, handleActionChange }} />
    ),
  }

  return (
    <Container className="bg-lucaWhite d-flex flex-column px-4">
      {actions[action]}
    </Container>
  )
}

export default Reports
