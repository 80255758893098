import Gi from '../configure/GoogleInsightsMoneyMetrics'
import GoogleInsihgtsEnums from '../configure/GoogleInsightsEnums'
import FacebookFormatter from '../configure/FacebookFormatter'
import GoogleFormatter from '../configure/GoogleFormatter'
import Fi from '../configure/FacebookInsightsMoneyFields'
import apiService from './apiService'
import { t } from './lucaFunctions'

const fetchAccounts = async ({ user_id, business_id }) => {
  const accounts = await apiService.data({
    path: `luca/accountUser/${user_id}/${business_id}`,
    method: 'get',
  })
  return accounts
}

const googleAdColumn = (ad) => {
  const adType = GoogleInsihgtsEnums['ad_group_ad.ad.type'][ad.type]
  if (!adType) return ad.name
  switch (adType) {
    case 'Expanded_text_ad':
      return `${ad.expanded_text_ad.description || ''}
            ${ad.expanded_text_ad.description2 || ''}
            ${ad.expanded_text_ad.headline_part1 || ''}
            ${ad.expanded_text_ad.headline_part2 || ''}
            ${ad.expanded_text_ad.headline_part3 || ''}
            ${ad.expanded_text_ad.path1 || ''}
            ${ad.expanded_text_ad.path2 || ''}`
    case 'Video_ad':
      return ad.name
    case 'Gmail_ad':
      return ''
    case 'Image_ad':
      // return (
      //   <div className="h-100 d-flex">
      //     {/* <Image src={ad.image_ad.image_url} height="20px" /> */}
      //     <p>{ad.name}</p>
      //   </div>
      // )
      return ad.name
    case 'Responsive_search_ad':
      return `${
        ad.responsive_search_ad.descriptions &&
        ad.responsive_search_ad.descriptions
          .map((description) => description.text)
          .join(' ')
      }${
        ad.responsive_search_ad.headlines &&
        ad.responsive_search_ad.headlines
          .map((headline) => headline.text)
          .join(' ')
      } ${ad.responsive_search_ad.path1}
                ${ad.responsive_search_ad.path2}`
    case 'Legacy_responsive_display_ad':
      return `${ad.legacy_responsive_display_ad.description} ${ad.legacy_responsive_display_ad.long_headline} ${ad.legacy_responsive_display_ad.short_headline}`
    case 'Responsive_display_ad':
      return ad.responsive_display_ad.business_name
    case 'Html5_ad':
      return ad.name
    default:
      return ad.name
  }
}

// enums
const enums = {
  facebook: {
    criterions: {
      campaign: 'campaign_id',
      adset: 'adset_id',
      ad: 'ad_id',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    criterionLabels: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'ad_name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    money: 'spend',
    campaign: 'campaign',
    adset: 'adset',
    ad: 'ad',
    callbacks: {
      campaign: (campaign) => campaign,
      adset: (adset) => adset,
      ad: (ad) => ad,
    },
    api: 'marketingApi',
    getSort: (sort) =>
      sort.name === undefined || sort.name === ''
        ? ''
        : `${sort.name}_${sort.order ? 'ascending' : 'descending'}`,
    fields: [
      'spend',
      'reach',
      'clicks',
      'impressions',
      'actions',
      'frequency',
      'unique_actions',
      'conversions',
      'action_values',
      'video_30_sec_watched_actions',
      'video_avg_time_watched_actions',
      'video_p25_watched_actions',
      'video_p50_watched_actions',
      'video_p75_watched_actions',
      'video_p95_watched_actions',
      'video_p100_watched_actions',
      'video_play_actions',
    ],
    breakdowns: 'age,gender',
    formatter: FacebookFormatter,
    date: 'date_start',
    options: Fi.FacebookInsightFields,
    ...Fi,
  },
  twitter: {
    criterions: {
      campaign: 'campaign_id',
      adset: 'adset_id',
      ad: 'ad_id',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    criterionLabels: {
      campaign: 'campaign_name',
      adset: 'adset_name',
      ad: 'line_item_id',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'date_start',
      age: 'age',
      gender: 'gender',
    },
    money: 'spend',
    campaign: 'campaigns',
    adset: 'line_items',
    ad: 'promoted_tweets',
    callbacks: {
      campaign: (campaign) => campaign,
      adset: (adset) => adset,
      ad: (ad) => ad.map((a) => ({ ...a, name: a.line_item_id })),
    },
    api: 'twitter',
    getSort: (sort) =>
      sort.name === undefined || sort.name === ''
        ? ''
        : `${sort.name}_${sort.order ? 'ascending' : 'descending'}`,
    fields: [
      'spend',
      'reach',
      'clicks',
      'impressions',
      'actions',
      'frequency',
      'unique_actions',
      'conversions',
      'action_values',
      'video_30_sec_watched_actions',
      'video_avg_time_watched_actions',
      'video_p25_watched_actions',
      'video_p50_watched_actions',
      'video_p75_watched_actions',
      'video_p95_watched_actions',
      'video_p100_watched_actions',
      'video_play_actions',
    ],
    breakdowns: 'age,gender',
    // formatter: FacebookFormatter,
    formatter: (e) => e,
    date: 'date_start',
    options: [],
    // ...Fi,
  },
  google: {
    criterions: {
      ad: 'ad_group_ad.ad.id',
      campaign: 'campaign.id',
      adset: 'ad_group.id',
      date: 'segments.date',
      age: 'age',
      gender: 'gender',
    },
    criterionLabels: {
      campaign: 'campaign.name',
      adset: 'ad_group.name',
      ad: 'ad_group_ad.ad.name',
      campaign_id: 'campaign_name',
      adset_id: 'adset_name',
      ad_id: 'ad_name',
      date: 'segments.date',
      age: 'age',
      gender: 'gender',
    },
    money: 'cosot_micros',
    campaign: 'campaign',
    adset: 'ad_group',
    ad: 'ad_group_ad',
    callbacks: {
      campaign: (campaign) => campaign.map((c) => c.campaign),
      adset: (adset) =>
        adset.map((a) => ({
          ...a.ad_group,
          campaign: a.campaign,
        })),
      ad: (ad) =>
        ad.map((a) => ({
          ...a.ad_group_ad.ad,
          name: googleAdColumn(a.ad_group_ad.ad) || a.ad_group.name,
          campaign: a.campaign,
          ad_group: a.ad_group,
        })),
    },
    api: 'googleAdsApi',
    getSort: (sort) =>
      sort.name === undefined || sort.name === ''
        ? ''
        : ` ORDER BY ${sort.name} ${sort.order ? 'ASC' : 'DESC'}`,
    fields:
      'campaign.id, campaign.name, metrics.cost_micros, metrics.clicks, metrics.impressions, metrics.engagements, metrics.video_views, metrics.interactions, metrics.all_conversions, metrics.all_conversions_value',
    breakdowns: '',
    formatter: GoogleFormatter,
    date: 'date',
    options: Gi.GoogleInsightFields,
    ...Gi,
  },
}

const getData = async (setting, retry) => {
  const { provider, type } = setting
  const res = await apiService.data({
    path: `luca/insight/data`,
    method: 'get',
    params: {
      ...setting,
      type: enums[provider][type],
      entity: enums[provider][type],
      api: enums[provider].api,
    },
  })
  if (!res) {
    if (!retry || retry < 3) {
      const retried = getData(setting, retry ? retry + 1 : 1)
      return retried
    }
    return []
  }
  return enums[provider].callbacks[type](res.datas || res.data)
}

const processData = async (setting) => {
  const campaign = await getData({ ...setting, type: 'campaign' })
  const adset = await getData({ ...setting, type: 'adset' })
  const ad = await getData({ ...setting, type: 'ad' })
  return {
    campaign,
    adset,
    ad,
  }
}

const arrangeData = async (setting) => {
  const {
    account_id,
    provider,
    business_id,
    since,
    until,
    targets,
    selected,
    criterions = [[]],
    level,
    breakdowns,
  } = setting
  const { fields, api } = enums[provider]
  const res = await apiService.data({
    path: 'luca/insight/insight',
    method: 'get',
    params: {
      account_id,
      provider,
      business_id,
      since,
      until,
      level,
      targets,
      selected,
      fields: setting.fields || fields,
      criterions,
      api,
      breakdowns,
      timeIncrement:
        criterions &&
        criterions[0] &&
        criterions[0].includes(enums[provider].criterions.date)
          ? '1'
          : '',
    },
  })
  return res
}

const processReport = async ({ report, auth, reportId }) => {
  if (reportId) {
    const res = await apiService.data({
      path: `luca/report/${reportId}`,
      method: 'put',
      data: report,
    })
    return res
  }
  const res = await apiService.data({
    path: `luca/report/${auth.account.business_id}/${auth.auth.user_id}`,
    method: 'post',
    data: report,
  })
  return res
}

const processInsight = async (setting, origin) => {
  const { account_id, provider, business_id, since, until, criterions, level } =
    setting
  const { fields, api } = enums[provider]
  const res = await apiService.data({
    path: 'luca/insight/accountsInsight',
    method: 'get',
    params: {
      account_id,
      provider,
      business_id,
      since,
      until,
      level,
      fields: setting.fields || fields,
      criterions,
      api,
    },
  })
  if (origin)
    Object.keys(origin).forEach((key) => {
      res.datas[key] = res.datas[key]
        ? res.datas[key].concat(origin[key])
        : origin[key]
    })
  return res
}

const getCriterions = ({
  type,
  dateBreakdown,
  provider,
  activetab,
  breakdowns,
}) => {
  switch (type) {
    case 'Data':
      return dateBreakdown === 'daily'
        ? [enums[provider].criterions.date].concat([
            ...breakdowns,
            enums[provider].criterions[activetab],
          ])
        : [...breakdowns, enums[provider].criterions[activetab]]
    case 'StackChart':
      return activetab
        ? [
            enums[provider].criterions.date,
            enums[provider].criterions[activetab],
          ]
        : [enums[provider].criterions.date]
    case 'LineChart':
    case 'CrossChart':
      return [enums[provider].criterions.date]
    case 'PieChart':
      return [
        [enums[provider].criterions.age],
        [enums[provider].criterions.gender],
      ]
    default:
      return []
  }
}

const toInsights = ({
  type,
  account_id,
  activetab,
  provider,
  origin,
  criterion,
  gt,
}) => {
  let insightData = {
    insight: null,
    total: null,
  }
  let idList = []
  switch (type) {
    case 'Data':
      if (account_id) {
        insightData = {
          insight: origin[0].datas[account_id],
          total: gt.datas.total,
        }
      } else {
        insightData = {
          insight: origin[0].datas.total,
          total: gt.datas.total,
        }
      }
      break
    case 'StackChart':
      if (account_id) {
        idList = origin[0].datas[account_id].reduce((prev, cur) => {
          const id = cur[enums[provider].criterions[activetab]]
          return prev.includes(id) ? prev : [...prev, id]
        }, [])
        insightData = {
          ...insightData,
          insight: idList.map((id) =>
            origin[0].datas[account_id].filter(
              (o) => o[enums[provider].criterions[activetab]] === id
            )
          ),
          total: gt.datas[account_id],
        }
      } else {
        insightData = {
          ...insightData,
          // insight: Object.keys(origin[0].datas).map((key) =>
          //   origin[0].datas[key].map((o) => ({
          //     ...o,
          //     date: o[enums[provider].date],
          //   }))
          // ),
          insight: [origin[0].datas.total],
          total: gt.datas.total,
        }
      }
      break
    case 'LineChart':
    case 'CrossChart':
      if (account_id) {
        insightData = {
          ...insightData,
          insight: origin[0].datas[account_id],
          total: gt.datas[account_id],
        }
      } else {
        insightData = {
          ...insightData,
          insight: origin[0].datas.total,
          total: gt.datas.total,
        }
      }
      break
    case 'PieChart':
      if (account_id) {
        insightData = {
          ...insightData,
          insight: criterion
            ? criterion.map((c, i) => origin[i].datas[account_id])
            : [
                origin[0] && origin[0].datas[account_id],
                origin[1] && origin[1].datas[account_id],
              ],
          total: gt.datas[account_id],
        }
      } else {
        insightData = {
          ...insightData,
          insight: [
            origin[0] && origin[0].datas.total,
            origin[1] && origin[1].datas.total,
          ],
          total: gt.datas.total,
        }
      }
      break
    default:
      break
  }
  return insightData
}

const getCsvData = ({ headers, account_id, insight, activetab, provider }) => {
  const header = ['name', ...headers].map((h) => t(h)).join()
  const data = insight[1].datas[account_id]
    .map((i) =>
      [enums[provider].criterionLabels[activetab], ...headers]
        .map((h) => `"${enums[provider].formatter(h)(i[h])}"` || '--')
        .join()
    )
    .join('\n')
  const footer = ['總計']
    .concat(
      [...headers].map(
        (h) =>
          `"${enums[provider].formatter(h)(insight[1].datas.total[0][h])}"` ||
          '--'
      )
    )
    .join()

  return `${header}\n${data}\n${footer}`
}

export {
  enums,
  fetchAccounts,
  processData,
  processInsight,
  processReport,
  arrangeData,
  getCriterions,
  toInsights,
  getCsvData,
  // getAccountsInsight,
}
