/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown } from '@fortawesome/free-regular-svg-icons'
import Form from 'react-bootstrap/Form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getIcon } from '../../services/lucaFunctions'

function InvoiceTable({ setting }) {
  const { invoices } = setting
  //   const filteredrecord = invoices || []
  const [percents, setpercents] = useState({})
  const filteredrecord = useMemo(() => {
    if (!invoices) return {}
    return invoices.reduce((prev, cur) => {
      const accountId = cur.ad_account_ids[0]
      return {
        ...prev,
        [accountId]: {
          download_uri: cur.download_uri,
          platform: cur.platform,
          accountName: cur.adaccount,
          amount: prev[accountId]
            ? prev[accountId].amount + cur.amount
            : cur.amount,
          invoices: [
            ...(prev[accountId]?.invoices || []),
            ...cur.campaigns.data,
          ],
        },
      }
    }, {})
  }, [invoices])

  const total = useMemo(
    () =>
      Object.keys(filteredrecord).reduce((prev, cur) => {
        let temp = prev
        filteredrecord[cur].invoices.forEach((invoice) => {
          temp +=
            parseInt(invoice.billed_amount_details.total_amount, 10) *
            (percents[invoice.campaign_id] || 1) *
            (invoice.billed_amount_details.currency === 'TWD' ? 1 : 30)
        })
        return temp
      }, 0),
    [filteredrecord, percents]
  )

  const [loaded, setloaded] = useState(100)

  return (
    <>
      <Row
        className="text-lucaDark ps-1"
        style={{
          height: '62vh',
        }}
      >
        <Col className="h-100 d-flex flex-column px-2 overflow-scroll position-relative">
          <Row
            className="text-nowrap fs-7 fw-bold text-center py-2"
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              borderBottom: '1px solid #317985',
            }}
          >
            <Col xs={3}>廣告帳號名稱</Col>
            <Col xs={3}>活動名稱</Col>
            <Col>花費</Col>
            <Col>收益比率</Col>
            <Col>粗估收益</Col>
            <Col xs={1}>帳號總花費</Col>
            <Col xs={1} className="text-center">
              下載
            </Col>
          </Row>
          <Row id="scrollTarget" className="h-100">
            <InfiniteScroll
              dataLength={loaded}
              next={() => setloaded(loaded + 20)}
              hasMore={loaded < Object.keys(filteredrecord).length}
              loader={<div />}
              endMessage={<p />}
              scrollableTarget="scrollTarget"
            >
              {Object.keys(filteredrecord)
                .slice(0, 100)
                .map((key) => (
                  <Row
                    className="colBorder overflow-hidden"
                    style={{
                      minHeight: '250px',
                      height: '250px',
                      maxHeight: '250px',
                    }}
                    key={key}
                  >
                    <Col className="d-flex" xs={3}>
                      <span className="my-auto App-oneLineEllipsis">
                        {getIcon(
                          filteredrecord[key].platform,
                          '20px',
                          '20px',
                          1,
                          'ms-auto'
                        )}
                        {filteredrecord[key].accountName}
                      </span>
                    </Col>
                    <Col
                      xs={7}
                      className="h-100 d-flex flex-column overflow-scroll"
                    >
                      {filteredrecord[key].invoices.map((invoice, i) => (
                        <Row
                          className="colBorder flex-fill"
                          key={i}
                          style={{
                            minHeight: '40px',
                          }}
                        >
                          <Col
                            xs={5}
                            title={invoice.campaign_name}
                            className="App-oneLineEllipsis my-auto"
                          >
                            {invoice.campaign_name}
                          </Col>
                          <Col className="my-auto text-end">
                            {invoice.billed_amount_details.total_amount}{' '}
                            {invoice.billed_amount_details.currency}
                          </Col>
                          <Col className="my-auto text-end">
                            <Form.Control
                              className="text-end my-auto py-0 fw-regular fs-7 text-dai"
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                              }}
                              value={percents[invoice.campaign_id] || 1}
                              onChange={(e) =>
                                setpercents((prevState) => ({
                                  ...prevState,
                                  [invoice.campaign_id]: e.target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col className="my-auto text-end">
                            {numeral(
                              parseInt(
                                invoice.billed_amount_details.total_amount.replaceAll(
                                  ',',
                                  ''
                                ),
                                10
                              ) * (percents[invoice.campaign_id] || 1)
                            ).format('0,0')}{' '}
                            {invoice.billed_amount_details.currency}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    <Col className="d-flex pe-0" xs={1}>
                      <span className="my-auto ms-auto fw-bold fs-5 text-luca">
                        {numeral(filteredrecord[key].amount).format('0,0')}
                      </span>
                    </Col>
                    <Col className="d-flex" xs={1}>
                      <div className="formsTool my-auto mx-auto">
                        <Button
                          as="a"
                          href={filteredrecord[key].download_uri}
                          variant="lucaIcon"
                          onClick={() => {}}
                        >
                          <FontAwesomeIcon
                            className="ms-auto align-self-center h5"
                            icon={faCircleDown}
                            title="下 載"
                          />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
            </InfiniteScroll>
          </Row>
        </Col>
      </Row>
      <Row
        className="text-lucaDark ps-1"
        style={{
          height: '9vh',
          borderTop: '1px solid #317985',
        }}
      >
        <Col className="my-auto">
          <h4 className="text-lucaDark fw-bolder text-start">總花費</h4>
        </Col>
        <Col className="my-auto">
          <h4 className="ms-auto text-end text-nowrap">
            {numeral(total.toFixed(0)).format('0,0')} TWD
          </h4>
        </Col>
      </Row>
    </>
  )
}

InvoiceTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InvoiceTable
