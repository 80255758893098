import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getOriginIcon } from '../../services/lucaFunctions'

function KPITable(props) {
  const { setting } = props
  const { forms } = setting

  return (
    <Row
      className="colBorder text-lucaDark"
      style={{
        height: '73vh',
      }}
    >
      <Col
        className="colBorder h-100 lh-lg d-flex justify-content-center bg-lucaLighter"
        style={{
          writingMode: 'vertical-lr',
          textAlign: 'center',
          maxWidth: '5%',
        }}
      >
        <span
          className="d-flex mb-3"
          // title={platform}
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            paddingRight: '15px',
          }}
          aria-hidden
        >
          {getOriginIcon(setting.active, '30px', '30px')}
        </span>
        {setting.active}
      </Col>
      <Col className="d-flex flex-column h-100">
        <Row>
          <Col
            className="colBorder bg-lucaLighter"
            style={{
              maxWidth: '5%',
            }}
          />
          {setting.type.OpenAccount && (
            <Col className="colBorder fw-bold text-center py-2 bg-lucaLighter">
              開戶（{forms.OpenAccount.total}）
            </Col>
          )}
          {setting.type.Recharge && (
            <Col className="colBorder fw-bold text-center py-2 bg-lucaLighter">
              儲值（{forms.Recharge.total}）
            </Col>
          )}
          {setting.type.Permission && (
            <Col className="colBorder fw-bold text-center py-2 bg-lucaLighter">
              權限（{forms.Permission.total}）
            </Col>
          )}
        </Row>
        <Row className="h-67">
          <Col
            className="colBorder h-100 lh-md bg-lucaLightest"
            xs={1}
            style={{
              writingMode: 'vertical-lr',
              textAlign: 'center',
              maxWidth: '5%',
            }}
          >
            部門
          </Col>
          <Col className="h-100 overflow-auto">
            <Row className="h-100">
              {['OpenAccount', 'Recharge', 'Permission']
                .filter((type) => setting.type[type])
                .map((type, i) => (
                  <Col className="colBorder" key={i}>
                    {forms[type].departs
                      .filter(({ name }) => setting.depart[name])
                      .map((f, j) =>
                        f.total !== 0 ? (
                          <Row key={`${i}_${j}`}>
                            <Col
                              xs={4}
                              className="App-oneLineEllipsis d-flex"
                              title={f.name}
                            >
                              <span className="my-auto">{f.name}</span>
                            </Col>
                            <Col>
                              {f.forms.map((ff) => (
                                <Row key={`${i}_${j}_${f.name}`}>
                                  <Col
                                    className="colBorder App-oneLineEllipsis"
                                    title={ff.name}
                                  >
                                    {ff.name}
                                  </Col>
                                  <Col className="colBorder text-end">
                                    {ff.total}
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                            <Col
                              xs={2}
                              className="App-oneLineEllipsis colBorder d-flex text-end"
                              title={f.total}
                            >
                              <span className="w-100 text-end my-auto">
                                {f.total}
                              </span>
                            </Col>
                          </Row>
                        ) : (
                          <Row key={`${i}_${j}`}>
                            <Col
                              xs={4}
                              className="colBorder App-oneLineEllipsis"
                            >
                              {f.name}
                            </Col>
                            <Col>
                              <Row>
                                <Col className="colBorder text-center">- -</Col>
                                <Col className="colBorder text-center">- -</Col>
                              </Row>
                            </Col>
                            <Col
                              xs={2}
                              className="App-oneLineEllipsis colBorder d-flex text-end"
                              title={f.total}
                            >
                              <span className="w-100 text-end my-auto">
                                {f.total}
                              </span>
                            </Col>
                          </Row>
                        )
                      )}
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row className="flex-grow-1 overflow-hidden">
          <Col
            className="colBorder overflow-auto lh-md bg-lucaLightest"
            xs={1}
            style={{
              writingMode: 'vertical-lr',
              textAlign: 'center',
              maxWidth: '5%',
            }}
          >
            個人
          </Col>
          <Col className="h-100 overflow-auto colBorder">
            <Row className="h-100">
              {['OpenAccount', 'Recharge', 'Permission'].map((type, i) => (
                <Col className="colBorder" key={i}>
                  {forms[type].forms.map((f) => (
                    <Row key={`${i}_${f.name}`}>
                      <Col xs={4} className="colBorder d-flex" title={f.name}>
                        <span className="my-auto  App-oneLineEllipsis">
                          {f.name}
                        </span>
                      </Col>
                      <Col
                        xs={4}
                        className="colBorder  d-flex"
                        title={f.depart}
                      >
                        <span className="my-auto  App-oneLineEllipsis">
                          {f.depart}
                        </span>
                      </Col>
                      <Col xs={4} className="colBorder text-end">
                        {f.total}
                      </Col>
                    </Row>
                  ))}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

KPITable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default KPITable
