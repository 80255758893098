import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import apiService from '../services/apiService'
import SideBar from './SideBar'

function AppWraper(props) {
  const { children, setting } = props
  const { auth, checkToken, setcontent, content } = setting
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  // check token
  useEffect(() => {
    if (auth.status === 'unauthed') {
      checkToken()
    }
  }, [auth])

  const [sidebarStatus, setsidebarStatus] = useState(true)
  // content
  useEffect(() => {
    if (!auth.auth.user_id) return
    const getcontent = async () => {
      const res = await apiService.data({
        path: `luca/content/${auth.auth.user_id}/${auth.account.business_id}`,
        method: 'get',
      })
      if (!res.error) {
        setcontent(res)
        if (auth.auth.setting.SidebarStatus !== undefined)
          setsidebarStatus(auth.auth.setting.SidebarStatus)
      }
    }
    getcontent()
  }, [auth.account, setcontent, auth.auth.user_id])

  return (
    <div
      className="px-4 d-flex"
      style={{ overflowY: 'hidden', minHeight: '84vh', height: '84vh' }}
    >
      <div
        style={{
          transition: 'width .15s ease-in',
          width:
            window.location.pathname === '/Welcome' ||
            window.location.pathname.startsWith('/ResetPassword/') ||
            !auth.accounts
              ? '100%'
              : `${sidebarStatus ? 85 : 95}%`,
          overflowX: 'hidden',
        }}
        className="mx-0 d-flex h-100"
      >
        {children}
      </div>
      {window.location.pathname !== '/Welcome' &&
        !window.location.pathname.startsWith('/ResetPassword/') &&
        auth.accounts && (
          <div
            className="ps-0 h-100 ms-3"
            style={{
              transition: 'width .15s ease-in',
              width: `${sidebarStatus ? 15 : 5}%`,
            }}
          >
            <SideBar
              setting={{
                auth,
                content,
                sidebarStatus,
                setsidebarStatus: (value) => {
                  setsidebarStatus(value)
                  apiService.data({
                    path: `luca/user/content/${auth.auth.user_id}`,
                    method: 'put',
                    data: { SidebarStatus: value },
                  })
                },
              }}
            />
          </div>
        )}
    </div>
  )
}

AppWraper.propTypes = {
  children: PropTypes.shape().isRequired,
  setting: PropTypes.shape().isRequired,
}

export default AppWraper
