import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { t } from '../../services/lucaFunctions'

function Wheel(props) {
  const { setting } = props
  const { fields, selectedField, setselectedField, style } = setting
  const [moving, setmoving] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (moving) setmoving(false)
    }, 200)
  }, [moving])

  const pivot = useMemo(
    () => fields.findIndex((f) => f === selectedField),
    [fields, selectedField]
  )

  const [mousedown, setmousedown] = useState(false)

  return (
    <div
      className="position-absolute d-flex h-100"
      style={style}
      onMouseLeave={() => {
        if (mousedown) setmousedown(false)
      }}
      onMouseUp={() => {
        if (mousedown) setmousedown(false)
      }}
      aria-hidden
    >
      <div
        className="position-absolute d-flex"
        style={{ top: 0, left: 0, height: '38px' }}
      >
        <FontAwesomeIcon
          icon={faCaretRight}
          className="my-auto fs-5 px-0 text-luca"
        />
      </div>
      <div
        className="position-absolute w-100"
        style={{
          transition: '.3s',
          transitionTimingFunction: 'ease-in',
          top: `${-35 * pivot}px`,
          height: `${fields.length * 35}px`,
          zIndex: '100',
          overflowX: 'hidden',
        }}
      >
        {fields.map((field, i) => (
          <div
            key={field}
            className="w-100 d-flex"
            style={{
              transition: '.3s',
              transitionTimingFunction: 'ease-in',
              height: '35px',
              color: '#21515a',
              opacity: `${0.85 - Math.abs((i - pivot) / 3)}`,
            }}
            // onClick={() => setselectedField(i)}
            onMouseDown={() => {
              setmousedown(true)
              setselectedField(field)
            }}
            onMouseEnter={() => {
              if (mousedown) setselectedField(field)
            }}
            aria-hidden
          >
            <p
              style={{
                opacity: '.4',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              className="position-absolute"
            >
              ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
            </p>
            <p className="m-auto App-oneLineEllipsis fw-bold" title={t(field)}>
              {t(field)}
            </p>
          </div>
        ))}
      </div>
      <div
        className="position-absolute d-flex"
        style={{ top: 0, right: 0, height: '38px' }}
      >
        <FontAwesomeIcon
          icon={faCaretLeft}
          className="my-auto fs-5 px-0 text-luca"
        />
      </div>
    </div>
  )
}

Wheel.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Wheel
