import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'

function LinkBox(props) {
  const { setting } = props
  const { title, titleEng, content } = setting
  return (
    <Card className="p-3">
      <Card.Title className="mb-0">{title}</Card.Title>
      <Card.Text className="mb-0 titleEng">{titleEng}</Card.Text>
      {/* <hr /> */}
      <Card.Body className="d-flex justify-content-around">
        {content.map((c, i) => (
          <Card key={i} className="my-2">
            <Row>
              <Card.Img
                className="projImgSize"
                src={c.imgSrc}
                alt="Card image"
              />
              <Card.ImgOverlay className="App-blackPanel" href={c.link}>
                <div className="text-start py-4 px-1">
                  <Card.Link
                    href={c.link}
                    className="text-white App-textEllipsis App-notextDecoration"
                    target="_blanck"
                  >
                    {c.content}
                  </Card.Link>
                </div>
              </Card.ImgOverlay>
            </Row>
          </Card>
        ))}
      </Card.Body>
    </Card>
  )
}

LinkBox.propTypes = {
  setting: PropTypes.shape(),
}

LinkBox.defaultProps = {
  setting: {
    title: '潮 網 案 例',
    titleEng: 'Project Sharing',
    content: [],
  },
}

export default LinkBox
