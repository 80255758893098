const numeral = require('numeral')

const funcs = {
  cost_micros: (value) => numeral(value / 1000000).format('$0,0.00'),
  impressions: (value) => numeral(value).format('0,0'),
  clicks: (value) => numeral(value).format('0,0'),
  interactions: (value) => numeral(value).format('0,0'),
  video_views: (value) => numeral(value).format('0,0'),
  engagements: (value) => numeral(value).format('0,0'),
  average_cpc: (value) => numeral(value).format('$0,0.00'),
  average_cpm: (value) => numeral(value).format('$0,0.00'),
  average_cpe: (value) => numeral(value).format('$0,0.00'),
  average_cpv: (value) => numeral(value).format('$0,0.00'),
  ctr: (value) => numeral(value).format('0,0.00%'),
  conversions: (value) => numeral(value).format('0,0.00'),
  all_conversions: (value) => numeral(value).format('0,0.00'),
  all_conversions_value: (value) => numeral(value).format('$0,0.00'),
  invalid_click_rate: (value) => numeral(value).format('0,0.00%'),
  interaction_rate: (value) => numeral(value).format('0,0.00%'),
  average_cost: (value) => numeral(value).format('$0,0.00'),
  video_view_rate: (value) => numeral(value).format('0,0.00%'),
  engagement_rate: (value) => numeral(value).format('0,0.00%'),
  active_view_measurable_cost_micros: (value) =>
    numeral(value).format('$0,0.00'),
  active_view_measurability: (value) => numeral(value).format('0,0.00%'),
  active_view_viewability: (value) => numeral(value).format('0,0.00%'),
  cost_per_conversion: (value) => numeral(value).format('$0,0.00'),
  conversions_from_interactions_rate: (value) =>
    numeral(value).format('0,0.00%'),
  all_conversions_from_interactions_rate: (value) =>
    numeral(value).format('0,0.00%'),
  cost_per_all_conversions: (value) => numeral(value).format('$0,0.00'),
  all_conversion_value_per_cost: (value) => numeral(value).format('0,0.00'),
  cross_device_conversions: (value) => numeral(value).format('0,0.00'),
  value_per_all_conversions: (value) => numeral(value).format('$0,0.00'),
  conversions_value: (value) => numeral(value).format('$0,0.00'),
  value_per_conversion: (value) => numeral(value).format('$0,0.00'),
  search_impression_share: (value) => numeral(value).format('0,0.00%'),
  search_exact_match_impression_share: (value) =>
    numeral(value).format('0,0.00%'),
  search_rank_lost_impression_share: (value) =>
    numeral(value).format('0,0.00%'),
  search_budget_lost_impression_share: (value) =>
    numeral(value).format('0,0.00%'),
  content_impression_share: (value) => numeral(value).format('0,0.00%'),
  content_rank_lost_impression_share: (value) =>
    numeral(value).format('0,0.00%'),
  content_budget_lost_impression_share: (value) =>
    numeral(value).format('0,0.00%'),
  'ad_group_criterion.effective_cpm_bid_micros': (value) =>
    numeral(value).format('$0,0.00'),
  'ad_group.cpc_bid_micros': (value) => numeral(value).format('$0,0.00'),
  'ad_group.cpm_bid_micros': (value) => numeral(value).format('$0,0.00'),
  'ad_group.cpv_bid_micros': (value) => numeral(value).format('$0,0.00'),
  'ad_group.effective_target_cpa_micros': (value) =>
    numeral(value).format('$0,0.00'),
  'ad_group_criterion.effective_cpc_bid_micros': (value) =>
    numeral(value).format('$0,0.00'),
  'ad_group_criterion.position_estimates.top_of_page_cpc_micros': (value) =>
    numeral(value).format('$0,0.00'),
  'ad_group_criterion.position_estimates.first_page_cpc_micros': (value) =>
    numeral(value).format('$0,0.00'),
  'ad_group_criterion.position_estimates.first_position_cpc_micros': (value) =>
    numeral(value).format('$0,0.00'),
}

module.exports = (name) =>
  funcs[name] || funcs[name.replace('metrics.', '')]
    ? funcs[name] || funcs[name.replace('metrics.', '')]
    : (value) => value
