const numeral = require('numeral')

const GoogleInsightFields = [
  { name: 'Clicks', api: 'metrics.clicks' },
  { name: 'Impressions', api: 'metrics.impressions' },
  { name: 'Cost', api: 'metrics.cost_micros' },
  { name: 'CTR', api: 'metrics.ctr' },
  { name: 'Avg. CPC', api: 'metrics.average_cpc' },
  { name: 'Avg. CPM', api: 'metrics.average_cpm' },
  { name: 'Invalid click rate', api: 'metrics.invalid_click_rate' },
  { name: 'Avg. CPE', api: 'metrics.average_cpe' },
  { name: 'Avg. CPV', api: 'metrics.average_cpv' },
  { name: 'Interactions', api: 'metrics.interactions' },
  { name: 'Interaction Rate', api: 'metrics.interaction_rate' },
  { name: 'Avg. Cost', api: 'metrics.average_cost' },
  { name: 'Views', api: 'metrics.video_views' },
  { name: 'View rate', api: 'metrics.video_view_rate' },
  { name: 'Engagements', api: 'metrics.engagements' },
  { name: 'Engagement rate', api: 'metrics.engagement_rate' },
  { name: 'Viewable impr.', api: 'metrics.active_view_impressions' },
  {
    name: 'Measurable impr.',
    api: 'metrics.active_view_measurable_impressions',
  },
  {
    name: 'Measurable cost',
    api: 'metrics.active_view_measurable_cost_micros',
  },
  { name: 'Measurable rate', api: 'metrics.active_view_measurability' },
  { name: 'Viewable rate', api: 'metrics.active_view_viewability' },
  { name: 'Conversions', api: 'metrics.conversions' },
  { name: 'Cost / conv.', api: 'metrics.cost_per_conversion' },
  { name: 'Conv. rate', api: 'metrics.conversions_from_interactions_rate' },
  { name: 'View-through conv.', api: 'metrics.view_through_conversions' },
  { name: 'All conv.', api: 'metrics.all_conversions' },
  {
    name: 'All conv. rate',
    api: 'metrics.all_conversions_from_interactions_rate',
  },
  { name: 'Cost / all conv.', api: 'metrics.cost_per_all_conversions' },
  { name: 'All conv. value', api: 'metrics.all_conversions_value' },
  { name: 'Value / all conv.', api: 'metrics.value_per_all_conversions' },
  {
    name: 'All conv. value / Cost',
    api: 'metrics.all_conversions_value_per_cost',
  },
  { name: 'Cross-device conv.', api: 'metrics.cross_device_conversions' },
  { name: 'Conv. value', api: 'metrics.conversions_value' },
  { name: 'Value / conv.', api: 'metrics.value_per_conversion' },
  { name: 'Search Impr. share', api: 'metrics.search_impression_share' },
  {
    name: 'Search Exact match IS',
    api: 'metrics.search_exact_match_impression_share',
  },
  {
    name: 'Search Lost IS (rank)',
    api: 'metrics.search_rank_lost_impression_share',
  },
  {
    name: 'Search Lost IS (budget)',
    api: 'metrics.search_budget_lost_impression_share',
  },
  { name: 'Display Impr. share', api: 'metrics.content_impression_share' },
  {
    name: 'Display Lost IS (rank)',
    api: 'metrics.content_rank_lost_impression_share',
  },
  {
    name: 'Display Lost IS (budget)',
    api: 'metrics.content_budget_lost_impression_share',
  },
]

const GoogleInsightsMoneyMetrics = new Set([
  'cost_micros',
  'average_cpc',
  'average_cpm',
  'average_cpe',
  'average_cpv',
  'average_cost',
  'active_view_measurable_cost_micros',
  'cost_per_conversion',
  'cost_per_all_conversions',
  'all_conversions_value_per_cost',
  'conversions_value_per_cost',
  'cost_per_current_model_attributed_conversion',
  'current_model_attributed_conversions_value_per_cost',
])

const arraytypes = new Set([])

const prefixs = new Set([
  'campaign.id',
  'campaign.name',
  'ad_group.id',
  'ad_group.name',
  'ad_group_ad.ad.id',
  'metrics.engagements',
  'metrics.interactions',
  'metrics.video_views',
  'metrics.all_conversions',
  'metrics.conversions',
  'ad_group_ad.ad.name',
  'ad_group_ad.ad.id',
  'ad_group_ad.ad.type',
  // 'ad_group_ad.ad.shopping_smart_ad',
  // 'ad_group_ad.ad.smart_campaign_ad.descriptions',
  // 'ad_group_ad.ad.image_ad.image_url',
  // 'ad_group_ad.ad.expanded_text_ad.description',
  // 'ad_group_ad.ad.expanded_text_ad.description2',
  // 'ad_group_ad.ad.expanded_text_ad.headline_part1',
  // 'ad_group_ad.ad.expanded_text_ad.headline_part2',
  // 'ad_group_ad.ad.expanded_text_ad.headline_part3',
  // 'ad_group_ad.ad.expanded_text_ad.path1',
  // 'ad_group_ad.ad.expanded_text_ad.path2',
  // 'ad_group_ad.ad.legacy_responsive_display_ad.description',
  // 'ad_group_ad.ad.legacy_responsive_display_ad.long_headline',
  // 'ad_group_ad.ad.legacy_responsive_display_ad.short_headline',
  // 'ad_group_ad.ad.responsive_display_ad.business_name',
  // 'ad_group_ad.ad.responsive_search_ad.descriptions',
  // 'ad_group_ad.ad.responsive_search_ad.headlines',
  // 'ad_group_ad.ad.responsive_search_ad.path1',
  // 'ad_group_ad.ad.responsive_search_ad.path2',
  // 'ad_group_ad.ad.smart_campaign_ad.descriptions',
  // 'ad_group_ad.ad.smart_campaign_ad.headlines',
])

const recalculator = {
  average_cpc: (value) => value.cost_micros / value.clicks,
  average_cpm: (value) => value.cost_micros / (value.impressions / 1000),
  average_cpe: (value) => value.cost_micros / value.engagements,
  average_cpv: (value) => value.cost_micros / value.video_views,
  average_cost: (value) => value.cost_micros / value.interactions,
  active_view_measurable_cost_micros: (value) =>
    numeral(value / 1000000).format('$0,0.00'),
  cost_per_conversion: (value) => value.cost_micros / value.conversions,
  cost_per_all_conversions: (value) =>
    value.cost_micros / value.all_conversions,
  all_conversions_value_per_cost: (value) =>
    (value.all_conversions_value / value.cost_micros).toFixed(2),
}

module.exports = {
  GoogleInsightsMoneyMetrics,
  GoogleInsightFields,
  recalculator,
  arraytypes,
  prefixs,
}
