import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ProgressBar from 'react-bootstrap/ProgressBar'

function Progress(props) {
  const { setting } = props
  const { title, titleEng, data } = setting
  return (
    <Card className="p-2 text-start recharge-card">
      <Card.Body>
        <Row>
          <Col xs={2} className="text-center">
            {title && <Card.Title className="mb-0">{title}</Card.Title>}
            {titleEng && <Card.Text>{titleEng}</Card.Text>}
          </Col>
          <Col xs={10} className="align-self-center">
            <ProgressBar
              xs={10}
              className="rounded-pill fs-6"
              style={{ height: '30px' }}
            >
              <ProgressBar
                animated
                now={(data.spend / data.total) * 100}
                label={`$${data.spend}`}
                title="剩 餘 預 算"
              />
              <ProgressBar
                className="bg-grey text-lucaLight"
                now={100 - (data.spend / data.total) * 100}
                label={`$${data.total}`}
                title="總 預 算"
              />
            </ProgressBar>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

Progress.propTypes = {
  setting: PropTypes.shape(),
}

Progress.defaultProps = {
  setting: {
    title: '儲 值 金 額',
    titleEng: 'Total amount',
    data: { spend: 12000, total: 18000 },
  },
}

export default Progress
