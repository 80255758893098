import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ReportFloatButton(props) {
  const { setting } = props
  const { icon, iconStyle, content, title, style, onClick } = setting

  return (
    <div className="d-flex p-0 position-absolute" style={style}>
      {icon ? (
        <FontAwesomeIcon
          className={iconStyle || 'm-auto align-self-center text-light'}
          style={{
            cursor: 'pointer',
          }}
          icon={icon}
          title={title}
          onClick={onClick}
        />
      ) : (
        content
      )}
    </div>
  )
}

ReportFloatButton.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportFloatButton
