import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function RequestResetForm(props) {
  const { setting } = props
  const { fields, onDataChange, error } = setting
  return (
    <Form className="py-2">
      {fields.map((field) => (
        <Form.Group key={field.name} className="mb-3">
          <Form.Label>{field.label}</Form.Label>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              error ? (
                <Tooltip
                  className="userTip"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  {error.content}
                </Tooltip>
              ) : (
                <Tooltip
                  className="userTip"
                  style={{
                    zIndex: '9999',
                    display: 'none',
                  }}
                />
              )
            }
          >
            <Form.Control
              name={field.name}
              type={field.type}
              onChange={onDataChange}
              placeholder={field.placeholder}
              isInvalid={error}
            />
          </OverlayTrigger>
        </Form.Group>
      ))}
    </Form>
  )
}

RequestResetForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default RequestResetForm
