import React, { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faEdit,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from './ContextProvider'
import apiService from '../services/apiService'
import LoadingButton from './LoadingButton'
// import { faLightbulb } from '@fortawesome/free-regular-svg-icons'

function ReplyForm(props) {
  const { setting } = props
  const { data, handleSend, handleDataChange } = setting
  const auth = useContext(AuthContext)

  const inputRef = useRef(null)
  useEffect(() => {
    if (data.file === '') inputRef.current.value = null
  }, [data.file])

  const [editing, setediting] = useState(false)

  // text templates
  const [textTemps, settextTemps] = useState(
    auth.auth.setting.replyTexts || {
      temp1: '您好，附件為您的委刊單，請點擊下載查收，謝謝。',
      temp2: '您好，已收到您的問題回報，請稍候，我們會盡快為您處理。',
      temp3: '點擊右方編輯按鍵，可修改此留言文字模板內文。',
    }
  )
  const handleTextChange = (e) =>
    settextTemps({ ...textTemps, [e.target.name]: e.target.value })

  return (
    <Card className="text-start text-lucaDark border-0 w-100 px-2 py-3">
      <Card.Title className="d-flex">
        <FontAwesomeIcon icon={faPlusCircle} className="lucaIcon mx-2 ms-0" />
        <h6 className="mb-0">新 增 回 覆</h6>
      </Card.Title>
      <Card.Body className="w-100 px-0">
        <Row
          className="d-flex w-100 pb-2 mx-0 px-0 ms-auto"
          style={{ overflowX: 'auto' }}
        >
          <div className="overflow-hidde px-0 mx-0" style={{ height: '35px' }}>
            <div className="d-flex w-100 h-100">
              {editing ? (
                <>
                  <Form.Control
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    name="temp1"
                    style={{
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                    }}
                    value={textTemps.temp1}
                    onChange={handleTextChange}
                  />
                  <Form.Control
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    name="temp2"
                    style={{
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                    }}
                    value={textTemps.temp2}
                    onChange={handleTextChange}
                  />
                  <Form.Control
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    name="temp3"
                    style={{
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                    }}
                    value={textTemps.temp3}
                    onChange={handleTextChange}
                  />
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="outline-lucaLighter"
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    title={textTemps.temp1}
                    onClick={() =>
                      handleDataChange({
                        target: {
                          name: 'content',
                          value: `${data.content}${textTemps.temp1}`,
                        },
                      })
                    }
                  >
                    {textTemps.temp1}
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-lucaLighter"
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    title={textTemps.temp2}
                    onClick={() =>
                      handleDataChange({
                        target: {
                          name: 'content',
                          value: `${data.content}${textTemps.temp2}`,
                        },
                      })
                    }
                  >
                    {textTemps.temp2}
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-lucaLighter"
                    className="w-35 text-start App-oneLineEllipsis ms-auto rounded me-1"
                    title={textTemps.temp3}
                    onClick={() =>
                      handleDataChange({
                        target: {
                          name: 'content',
                          value: `${data.content}${textTemps.temp3}`,
                        },
                      })
                    }
                  >
                    {textTemps.temp3}
                  </Button>
                </>
              )}
              <Button
                size="sm"
                variant="outline-lucaLighter"
                className="ms-auto rounded"
              >
                <FontAwesomeIcon
                  icon={editing ? faCheck : faEdit}
                  title="編 輯 文 字 模 板 內 容"
                  onClick={() => {
                    setediting(!editing)
                    apiService.data({
                      path: `luca/user/content/${auth.auth.user_id}`,
                      method: 'put',
                      data: { replyTexts: textTemps },
                    })
                  }}
                />
              </Button>
            </div>
          </div>
        </Row>
        <Row className="d-flex w-100 pb-2 mx-0 px-0 ms-auto">
          <Col xs={3} className="px-0" title="提 示 ： 附 檔 可 多 選">
            <Form.Control
              type="file"
              ref={inputRef}
              multiple
              onChange={(event) => {
                handleDataChange({
                  target: {
                    name: 'file',
                    value: event.target.files,
                  },
                })
              }}
            />
          </Col>
          <Col xs={7}>
            <Form.Control
              as="textarea"
              rows={1}
              name="content"
              value={data.content}
              onChange={handleDataChange}
              placeholder="請輸入留言內容..."
            />
          </Col>
          <Col className="mb-auto px-0 d-flex">
            <Button
              variant="secondary"
              className="fs-7 me-2 align-self-center w-100"
              onClick={() =>
                handleDataChange({
                  target: {
                    name: 'content',
                    value: '',
                  },
                })
              }
              disabled={data.content === ''}
            >
              清 除
            </Button>
            <LoadingButton
              variant="lucaLighter"
              className="fs-7 me-auto align-self-center w-100"
              onClick={handleSend}
              disabled={
                (data.content === '' || data.content.trim() === '') &&
                data.file === ''
              }
            >
              送 出
            </LoadingButton>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

ReplyForm.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReplyForm
