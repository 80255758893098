/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faQuestionCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import SelectBar from './SelectBar'
import ReplyHistory from './ReplyHistory'
import fv from '../services/formValidation'
import { getIcon, t, getAvatar } from '../services/lucaFunctions'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function FormDialog(props) {
  const { setting } = props
  const {
    auth,
    show,
    handleDataChange,
    handleClose,
    form,
    formData,
    btnText,
    managers,
    replyData,
    handleReplyDataChange,
    handleReplySend,
    replyAble,
    hasComplete,
    hasFooter,
    download,
    footer,
  } = setting
  const {
    imgSrc,
    title,
    titleEng,
    // content,
    extraFields,
    manager,
    sys_manager,
    size,
    pageLimit,
    fixedContent,
    replys,
  } = form
  const content = form.content.filter(
    (c) =>
      !c.dependency ||
      formData.content[c.dependency.name] === c.dependency.value
  )
  const pageSize = Math.ceil(content.length / pageLimit)

  const fvError = fv.validate(content, formData.content)
  const disabled = fvError.every((e) => !e.error)
  const [touched, settouched] = useState([])

  const [reveal, setreveal] = useState(false)

  const handleFormClose = (value) => {
    settouched([])
    handleClose(value)
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleFormClose()}
    >
      {(title || imgSrc) && (
        <Modal.Header
          closeButton
          className="AccFormModal justify-content-center text-center pt-4 position-relative"
        >
          {form.setting && form.setting.platform && (
            <span className="position-absolute" style={{ left: '30px' }}>
              {getIcon(form.setting.platform, '50px', '50px', '0.45')}
            </span>
          )}
          {imgSrc && <Image src={imgSrc} fluid />}
          {title && (
            <Modal.Title>
              <h4>{title}</h4>
              <p>{titleEng}</p>
            </Modal.Title>
          )}
        </Modal.Header>
      )}
      <Modal.Body className="AccformCard">
        {extraFields &&
          extraFields.filter((c) => c.type === 'fixed').length > 0 && (
            <Row className="px-5 py-2">
              <Form className="px-5 py-3 Form-card card bg-lucaLighter text-lucaDark fw-bold">
                <>
                  {extraFields
                    .filter(
                      (c) => c.type === 'fixed' && c.label !== 'sys_manager'
                    )
                    .map((c) => (
                      <Form.Group key={c.name} className="mb-1 px-4 lh-md">
                        {`${t(c.label)} : ${c.value}`}
                      </Form.Group>
                    ))}
                </>
              </Form>
            </Row>
          )}
        <Row className="px-3 py-0">
          {fixedContent && (
            <Col className="px-0">
              <Form className="py-3">
                {fixedContent
                  .filter(
                    (c) =>
                      !c.dependency ||
                      formData.content[c.dependency.name] === c.dependency.value
                  )
                  .map((c) => (
                    <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                      <Form.Label className="my-3 mb-1 d-flex">
                        {t(c.label)}
                      </Form.Label>
                      <Form.Control
                        name={c.name}
                        type={c.type}
                        value={c.value}
                        disabled
                        placeholder={c.placeholder}
                      />
                    </Form.Group>
                  ))}
              </Form>
            </Col>
          )}
          {Array.from({ length: pageLimit }, (v, i) => i).map((page) => (
            <Col key={page} className="px-0">
              <Form className="py-3">
                {content
                  .slice(pageSize * page, pageSize * (page + 1))
                  .map((c, i) => {
                    const error = fvError.find((e) => e.key === c.name)
                    const isInvalid =
                      error && error.error && touched.includes(`${page}_${i}`)
                    switch (c.type) {
                      case 'fixed':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1">
                              {t(c.label)}
                            </Form.Label>
                            <InputGroup className="position-relative">
                              <Form.Control
                                id="defaultBorder"
                                name={c.name}
                                type={c.type}
                                value={(c.value && c.value.fileName) || c.value}
                                disabled
                                placeholder={c.placeholder}
                              />
                              {c.value && c.value.fileName && (
                                <FontAwesomeIcon
                                  className="fs-7 my-auto position-absolute btn-lucaIcon"
                                  style={{
                                    right: '10',
                                    top: '50',
                                    bottom: '50',
                                  }}
                                  icon={faDownload}
                                  title="下 載"
                                  onClick={() => download(c.value.fileName)}
                                />
                              )}
                            </InputGroup>
                          </Form.Group>
                        )
                      case 'textarea':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1 d-flex">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                isInvalid ? (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                    }}
                                  >
                                    {error && error.error}
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                      display: 'none',
                                    }}
                                  />
                                )
                              }
                            >
                              <Form.Control
                                as="textarea"
                                className="w-100"
                                style={{
                                  color: '#212529',
                                  backgroundColor: '#fff',
                                  borderColor: '#86b7fe',
                                  outline: '0',
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  height: 'auto',
                                }}
                                name={c.name}
                                disabled={c.disabled}
                                rows={
                                  formData.content[c.name].split(/\r\n|\r|\n/)
                                    .length
                                }
                                value={formData.content[c.name]}
                                placeholder={c.placeholder}
                                onChange={handleDataChange}
                                onFocus={() =>
                                  settouched([...touched, `${page}_${i}`])
                                }
                                isInvalid={isInvalid}
                              />
                            </OverlayTrigger>
                          </Form.Group>
                        )
                      case 'select':
                        return (
                          <Form.Group
                            key={c.name}
                            className="mb-1 px-5 lh-md FormSelectGroup"
                          >
                            {c.subtype === 'avatar' ? (
                              <>
                                <Image
                                  className="d-flex m-auto mt-4"
                                  src={getAvatar({
                                    avatarId: formData[c.name],
                                  })}
                                  width="35%"
                                  height="35%"
                                />
                                <div className="text-center">
                                  <Form.Label className="mt-4">
                                    選 擇 使 用 者 頭 像
                                  </Form.Label>
                                </div>
                                <Image />
                              </>
                            ) : (
                              <Form.Label className="d-flex my-3 mb-1">
                                {t(c.label)}
                                {c.remark && (
                                  <Question setting={{ remark: c.remark }} />
                                )}
                              </Form.Label>
                            )}
                            <SelectBar
                              setting={{
                                id: 'defaultBorder',
                                disabled: c.disabled,
                                name: c.name,
                                subtype: c.subtype,
                                placeholder: c.placeholder,
                                value: formData[c.name],
                                method: handleDataChange,
                                content: c.content,
                                onFocus: () =>
                                  settouched([...touched, `${page}_${i}`]),
                                isInvalid,
                              }}
                            />
                          </Form.Group>
                        )
                      case 'file':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="d-flex my-3 mb-1">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                isInvalid ? (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                    }}
                                  >
                                    {error && error.error}
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                      display: 'none',
                                    }}
                                  />
                                )
                              }
                            >
                              <Form.Control
                                name={c.name}
                                type={c.type}
                                disabled={c.disabled}
                                // value={formData.content[c.name]}
                                placeholder={c.placeholder}
                                onChange={handleDataChange}
                                onFocus={() =>
                                  settouched([...touched, `${page}_${i}`])
                                }
                                isInvalid={isInvalid}
                              />
                            </OverlayTrigger>
                          </Form.Group>
                        )
                      case 'multimail':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1 d-flex">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                isInvalid ? (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                    }}
                                  >
                                    {error && error.error}
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                      display: 'none',
                                    }}
                                  />
                                )
                              }
                            >
                              {c.disabled ? (
                                <div className="fixed-multi-email" disabled>
                                  {formData.content[c.name]
                                    ? formData.content[c.name]
                                        .split(',')
                                        .filter((s) => s !== '')
                                        .map((email) => <span>{email}</span>)
                                    : '無'}
                                </div>
                              ) : (
                                <ReactMultiEmail
                                  placeholder="輸入信箱(可複填)..."
                                  emails={
                                    formData.content[c.name]
                                      ? formData.content[c.name]
                                          .split(',')
                                          .filter((s) => s !== '')
                                      : []
                                  }
                                  onChange={(emails) => {
                                    handleDataChange({
                                      target: {
                                        name: c.name,
                                        value: emails.join(),
                                      },
                                    })
                                  }}
                                  validateEmail={
                                    (email) =>
                                      isEmail(email) &&
                                      (!c.constraint ||
                                        c.constraint.method(email)) // return boolean
                                  }
                                  getLabel={(email, index, removeEmail) => (
                                    <div data-tag key={index}>
                                      <div data-tag-item>{email}</div>
                                      <span
                                        data-tag-handle
                                        onClick={() => removeEmail(index)}
                                        aria-hidden
                                      >
                                        ×
                                      </span>
                                    </div>
                                  )}
                                />
                              )}
                            </OverlayTrigger>
                          </Form.Group>
                        )
                      case 'checkgroup':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1 d-flex">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            {c.content.map((field) => (
                              <Form.Check
                                key={field.name}
                                inline
                                label={field.name}
                                name={c.name}
                                type="checkbox"
                                disabled={c.disabled}
                                checked={formData.content[c.name]
                                  .split('/')
                                  .includes(field.value)}
                                onChange={() => {
                                  handleDataChange({
                                    target: {
                                      name: c.name,
                                      value: formData.content[c.name]
                                        .split('/')
                                        .includes(field.value)
                                        ? formData.content[c.name]
                                            .split('/')
                                            .filter(
                                              (s) =>
                                                s !== field.value && s !== ''
                                            )
                                            .join('/')
                                        : formData.content[c.name]
                                            .split('/')
                                            .filter((s) => s !== '')
                                            .concat([field.value])
                                            .join('/'),
                                    },
                                  })
                                }}
                                isInvalid={isInvalid}
                              />
                            ))}
                          </Form.Group>
                        )
                      case 'check':
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1 d-flex">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            {c.content.map((field) => (
                              <Form.Check
                                key={field.name}
                                inline
                                disabled={c.disabled}
                                label={field.name}
                                name={c.name}
                                type="checkbox"
                                value={field.value}
                                checked={
                                  formData.content[c.name] === field.value
                                }
                                onChange={handleDataChange}
                                isInvalid={isInvalid}
                              />
                            ))}
                          </Form.Group>
                        )
                      default:
                        return (
                          <Form.Group key={c.name} className="mb-1 px-5 lh-md">
                            <Form.Label className="my-3 mb-1 d-flex">
                              {t(c.label)}
                              {c.remark && (
                                <Question setting={{ remark: c.remark }} />
                              )}
                            </Form.Label>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                isInvalid ? (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                    }}
                                  >
                                    {error && error.error}
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    className="userTip"
                                    style={{
                                      zIndex: '9999',
                                      display: 'none',
                                    }}
                                  />
                                )
                              }
                            >
                              {c.type === 'password' ? (
                                <InputGroup
                                  id="defaultBorder"
                                  className="rounded input-group-transparent-addon"
                                >
                                  <Form.Control
                                    name={c.name}
                                    type={reveal ? 'text' : c.type}
                                    disabled={c.disabled}
                                    value={formData.content[c.name]}
                                    placeholder={c.placeholder}
                                    onChange={handleDataChange}
                                    onFocus={() =>
                                      settouched([...touched, `${page}_${i}`])
                                    }
                                    isInvalid={isInvalid}
                                  />
                                  <InputGroup.Text>
                                    <FontAwesomeIcon
                                      className="fs-7 btn-lucaIcon"
                                      style={{
                                        right: '10',
                                        top: '50',
                                        bottom: '50',
                                      }}
                                      title={
                                        reveal
                                          ? '點擊以隱藏密碼'
                                          : '點擊以顯示密碼'
                                      }
                                      icon={reveal ? faEye : faEyeSlash}
                                      onClick={() => setreveal(!reveal)}
                                    />
                                  </InputGroup.Text>
                                </InputGroup>
                              ) : (
                                <Form.Control
                                  name={c.name}
                                  type={c.type}
                                  disabled={c.disabled}
                                  value={formData.content[c.name]}
                                  placeholder={c.placeholder}
                                  onChange={handleDataChange}
                                  onFocus={() =>
                                    settouched([...touched, `${page}_${i}`])
                                  }
                                  isInvalid={isInvalid}
                                />
                              )}
                            </OverlayTrigger>
                          </Form.Group>
                        )
                    }
                  })}
              </Form>
            </Col>
          ))}
        </Row>
        {extraFields && (
          <Row className="px-3 py-0">
            {Array.from({ length: pageLimit }, (v, i) => i).map((page) => (
              <Form key={page} className="px-4 pb-2 Form-card">
                {extraFields
                  .slice(pageSize * page, pageSize * (page + 1))
                  .filter((c) => c.type !== 'fixed')
                  .map((c) => (
                    <Form.Group key={c.name} className="mb-1 px-4 lh-md">
                      <Form.Label className="my-3 mb-1">
                        {t(c.label)}
                      </Form.Label>
                      {c.type === 'select' ? (
                        <SelectBar
                          setting={{
                            name: c.name,
                            subtype: c.subtype,
                            placeholder: c.placeholder,
                            value: formData[c.name],
                            method: handleDataChange,
                            content: c.content,
                          }}
                        />
                      ) : (
                        <Form.Control
                          name={c.name}
                          type={c.type}
                          value={formData[c.name]}
                          placeholder={c.placeholder}
                          onChange={handleDataChange}
                        />
                      )}
                    </Form.Group>
                  ))}
              </Form>
            ))}
            {manager !== undefined && (
              <>
                {/* <hr /> */}
                <Form className="px-4 Form-card ManagerChoser">
                  <Form.Group className="mb-1 px-4 lh-md">
                    <Form.Label className="my-3 mb-1">負責人</Form.Label>
                    <SelectBar
                      setting={{
                        name: 'manager',
                        placeholder: '選擇負責人...',
                        value: formData.manager || manager,
                        method: handleDataChange,
                        content: managers.map((m) => ({
                          name: m.name,
                          value: m.user_id,
                        })),
                      }}
                    />
                  </Form.Group>
                </Form>
                <Form className="px-4 Form-card ManagerChoser">
                  <Form.Group className="mb-1 px-4 lh-md">
                    <Form.Label className="my-3 mb-1">系統處理人</Form.Label>
                    <SelectBar
                      setting={{
                        name: 'sys_manager',
                        placeholder: '選擇負責人...',
                        value: formData.sys_manager || sys_manager,
                        method: handleDataChange,
                        content: managers.map((m) => ({
                          name: m.name,
                          value: m.user_id,
                        })),
                      }}
                    />
                  </Form.Group>
                </Form>
              </>
            )}
          </Row>
        )}
        {replyAble && (
          <Row>
            <ReplyHistory
              setting={{
                auth,
                replys,
                data: replyData,
                handleDataChange: handleReplyDataChange,
                handleSend: handleReplySend,
                download,
              }}
            />
          </Row>
        )}
      </Modal.Body>
      {hasFooter && (
        <Modal.Footer className="sendForm justify-content-center py-4">
          {footer || (
            <>
              <Button variant="secondary" onClick={() => handleFormClose()}>
                取&emsp;消
              </Button>
              <Button
                variant="luca"
                onClick={() => handleFormClose('處 理 中')}
                disabled={!formData || !disabled}
              >
                {btnText || '下一步'}
              </Button>

              {hasComplete && (
                <Button
                  variant="green"
                  onClick={() => handleFormClose('已 完 成')}
                  disabled={!formData || !disabled}
                >
                  結 單
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

Question.propTypes = {
  setting: PropTypes.shape().isRequired,
}

FormDialog.propTypes = {
  setting: PropTypes.shape(),
}

FormDialog.defaultProps = {
  setting: {
    title: '申請廣告帳戶',
    titleEng: 'Open the advertising account',
    pageLimit: 2,
    content: [
      { name: '用戶名稱', type: 'fixed', value: 'Kevin' },
      { name: '帳戶名稱', type: 'text', value: '' },
      { name: '公司統編', type: 'text', value: '' },
      { name: '電子信箱', type: 'email', value: '' },
    ],
  },
}

export default FormDialog
