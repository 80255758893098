import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import FacebookInsightFields from './enums/FacebookInsightFields'
import GoogleInsightFields from './enums/GoogleInsightFields'
import { t } from '../../services/lucaFunctions'

function FieldsSelect(props) {
  const { setting } = props
  const { provider, fields, setfields } = setting
  // const [select, setSelect] = useState([])
  const handleSelect = (value) => {
    setfields(
      fields.includes(value)
        ? [...fields.filter((s) => s !== value)]
        : [...fields, value]
    )
  }
  const [value, setValue] = useState('')
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  const filterFields = () => {
    if (provider === 'facebook') {
      return FacebookInsightFields.filter(
        (f) => !fields.includes(f.name)
      ).filter(
        (f) =>
          f.name.includes(value) || (t(f.name) && t(f.name).includes(value))
      )
    }
    if (provider === 'google') {
      return GoogleInsightFields.filter((f) => !fields.includes(f.api)).filter(
        (f) => f.api.includes(value) || (t(f.api) && t(f.api).includes(value))
      )
    }
    return true
  }

  return (
    <Modal.Dialog style={{ zIndex: '1501' }}>
      <Modal.Header
        id="form-dialog-title"
        className="m-auto border-0 h6 text-lucaDark"
      >
        選擇欄位項目
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          className="mb-3"
          type="text"
          value={value}
          placeholder="輸入關鍵字搜尋..."
          onChange={handleChange}
        />
        <ListGroup
          className="selectGroup scrollbarShow"
          style={{ maxHeight: '22rem', overflowY: 'auto' }}
        >
          {provider === 'facebook' &&
            filterFields().map((field) => (
              <ListGroupItem
                className=""
                style={{ minHeight: '2.5rem' }}
                key={field.name}
                title={field.name}
                onClick={() => handleSelect(field.name)}
                active={fields.includes(field.name)}
              >
                {t(field.name)}
              </ListGroupItem>
            ))}
          {provider === 'google' &&
            filterFields().map((field) => (
              <ListGroupItem
                className=""
                style={{ minHeight: '2.5rem' }}
                key={field.api}
                title={field.api}
                onClick={() => handleSelect(field.api)}
                active={fields.includes(field.api)}
              >
                {t(field.api)}
              </ListGroupItem>
            ))}
        </ListGroup>
      </Modal.Body>
    </Modal.Dialog>
  )
}

function PresetStatus(props) {
  const { setting } = props
  const { name, setname, fields, setfields, provider, handleClose } = setting
  const handleSelect = (value) => {
    setfields(
      fields.includes(value)
        ? [...fields.filter((s) => s !== value)]
        : [...fields, value]
    )
  }
  const handleNameChange = (event) => {
    setname(event.target.value)
  }

  return (
    <Modal.Dialog style={{ zIndex: '1501' }}>
      <Modal.Header
        id="form-dialog-title"
        className="m-auto border-0 h6 text-lucaDark"
      >
        欄位組合內容
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <Form.Control
            className="mb-3"
            type="text"
            value={name}
            placeholder="欄位組合名稱"
            onChange={handleNameChange}
          />
          <FontAwesomeIcon
            icon={faPlusCircle}
            title="建 立"
            className="m-auto my-1 mx-1 fs-3 w-auto btn-lucaIcon"
            onClick={() => handleClose({ name, values: fields, provider })}
            disabled={name === '' || fields.length === 0}
          />
        </div>
        <ListGroup
          className="selectGroup scrollbarShow"
          style={{ maxHeight: '22rem', overflowY: 'auto' }}
        >
          {fields.map((field) => (
            <ListGroupItem
              className="d-flex"
              style={{ minHeight: '2.5rem' }}
              key={field}
              title={field}
              onClick={() => {}}
            >
              <span>{t(field)}</span>
              <Button
                title="取 消"
                variant="lucaIcon3"
                className="align-self-center ms-auto p-0"
                onClick={() => handleSelect(field)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal.Dialog>
  )
}

function FieldsDialog(props) {
  const { show, setting } = props
  const {
    handleClose,
    provider,
    // userRole,
    presets,
    createPreset,
    deletePreset,
    size,
  } = setting

  const [filteredPresets, setfilteredPresets] = useState([])
  useEffect(() => {
    setfilteredPresets(
      presets.filter(
        (p) =>
          (!p.provider && provider === 'facebook') || p.provider === provider
      )
    )
  }, [presets, provider])

  // select fields
  const [fields, setfields] = useState([])
  const [name, setname] = useState('')

  const [select, setSelect] = useState(-1)
  const handleSelect = (value) => {
    setSelect(value)
    const selected = filteredPresets[value]
    if (selected) {
      setname(selected.name)
      setfields(selected.values)
    }
  }
  const handleSelectClose = (value) => {
    if (value) createPreset(value)
    // setSelectOpen(false)
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose(-1)}
    >
      {/* <Modal.Header id="form-dialog-title">選擇欄位組合</Modal.Header> */}
      <Modal.Body>
        <Row>
          <Col xs={4}>
            <FieldsSelect
              setting={{
                handleClose: handleSelectClose,
                provider,
                fields,
                setfields,
              }}
            />
          </Col>
          <Col xs={4}>
            <PresetStatus
              setting={{
                handleClose: handleSelectClose,
                provider,
                fields,
                setfields,
                name,
                setname,
              }}
            />
          </Col>
          <Col xs={4}>
            <Modal.Dialog>
              <Modal.Header
                id="form-dialog-title"
                className="m-auto border-0 h6 text-lucaDark"
              >
                欄位組合列表
              </Modal.Header>
              <Modal.Body>
                <ListGroup
                  className="selectGroup scrollbarShow"
                  style={{ maxHeight: '22rem', overflowY: 'auto' }}
                >
                  {filteredPresets.map((p, index) => (
                    <ListGroupItem
                      className="d-flex"
                      style={{ minHeight: '2.5rem' }}
                      key={`${p.name} ${index}`}
                      onClick={() => handleSelect(index)}
                      active={select === index}
                    >
                      <span>{p.name}</span>
                      <Button
                        title="取 消"
                        variant="lucaIcon3"
                        className="align-self-center ms-auto p-0"
                        onClick={() => deletePreset(index)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Modal.Body>
            </Modal.Dialog>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={() => handleClose(-1)} variant="secondary">
          取 消
        </Button>
        <Button
          onClick={() => handleClose(select)}
          variant="luca"
          // disabled={select >= -1}
        >
          完 成
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

FieldsDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

FieldsSelect.propTypes = {
  // show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

PresetStatus.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default FieldsDialog
