import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  faPlus,
  faAngleRight,
  faMinus,
} from '@fortawesome/free-solid-svg-icons'
import { wordSearch, getOriginIcon } from '../services/lucaFunctions'
import SelectBar from './SelectBar'

function AccountImport(props) {
  const { setting } = props
  const {
    size,
    show,
    handleClose,
    provided,
    prepared,
    selected,
    all,
    setselected,
    handleImport,
  } = setting

  const [search, setsearch] = useState('')
  const [assignedSearch, setassignedSearch] = useState('')
  const [platform, setplatform] = useState('')
  const [assignedPlatform, setassignedPlatform] = useState('')

  const ids = all.map((a) => a.setting.account_id)
  const items = provided.filter((p) => !ids.includes(p.account_id))
  const filteredItems = items.filter(
    (p) =>
      p.name &&
      wordSearch(p.name, search) &&
      (platform === '' || p.provider === platform)
  )
  const filteredAssigned = provided.filter(
    (p) =>
      ids.includes(p.account_id) &&
      p.name &&
      wordSearch(p.name, assignedSearch) &&
      (assignedPlatform === '' || p.provider === assignedPlatform)
  )
  // dot control
  // const [dot, setdot] = useState(1)
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (dot === 3) setdot(1)
  //     else setdot(dot + 1)
  //   }, 300)
  // }, [dot])

  const handleSelectAll = () => {
    if (selected.length === items.length) setselected([])
    else setselected(items.map((p) => p.account_id))
  }

  const [loaded, setloaded] = useState(20)
  const [loadedImported, setloadedImported] = useState(20)
  console.log(prepared)

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        <Modal.Title>
          <h4>匯 入 廣 告 帳 戶</h4>
          <p>Import the data of advertise accounts</p>
        </Modal.Title>
      </Modal.Header>
      {prepared ? (
        <Modal.Body className="text-center">
          <Container className="App-oneLineEllipsis overflow-hidden">
            <Row
              className="d-flex mt-2 h-100 w-100 overflow-auto"
              style={{ maxHeight: '70vh', overflowY: 'auto' }}
            >
              <Col style={{ width: '48.5%' }}>
                <Modal.Dialog>
                  <Modal.Header
                    id="form-dialog-title"
                    className="m-auto border-0 h6 text-lucaDark"
                  >
                    未 匯 入
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col xs={4} className="BmsAccountSelector">
                        <SelectBar
                          setting={{
                            method: (e) => {
                              setplatform(e.target.value)
                            },
                            placeholder: '選擇媒體平台類型',
                            content: [
                              { name: 'Facebook', value: 'facebook' },
                              { name: 'Google', value: 'google' },
                            ],
                          }}
                        />
                      </Col>
                      <Col xs={3} className="px-0 ms-0">
                        <Form.Control
                          type="text"
                          className="mb-3"
                          value={search}
                          placeholder="輸入關鍵字搜尋..."
                          onChange={(event) => setsearch(event.target.value)}
                        />
                      </Col>
                      <Col xs={2} className="pe-0">
                        <Button
                          variant="luca"
                          className="w-100"
                          onClick={handleSelectAll}
                        >
                          全 選
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <Button
                          variant="lucaLight"
                          className="w-100 border-0"
                          title="點 擊 此 處 將 選 取 之 廣 告 帳 戶 匯 入"
                          onClick={() => {
                            // 'Have to show a handleShowWarn on here!'()
                            handleImport()
                          }}
                          disabled={selected.length === 0}
                        >
                          匯 入 ({selected.length})
                        </Button>
                      </Col>
                    </Row>

                    <ListGroup
                      id="scrollTarget"
                      className="selectGroup BmsSelector scrollbarShow"
                      style={{ maxHeight: '22rem', overflowY: 'auto' }}
                    >
                      <InfiniteScroll
                        dataLength={loaded}
                        next={() => setloaded(loaded + 20)}
                        hasMore={loaded < filteredItems.length}
                        loader={<h4>Loading...</h4>}
                        endMessage={<p />}
                        scrollableTarget="scrollTarget"
                      >
                        {filteredItems.map((p) => (
                          <ListGroup.Item
                            onClick={() =>
                              setselected([...selected, p.account_id])
                            }
                            className={`d-flex my-auto ${
                              selected.includes(p.account_id) && 'bg-lucaLight'
                            }`}
                            style={{ minHeight: '3rem', height: '3rem' }}
                            key={p.account_id}
                            title={p.name}
                          >
                            <span className="my-auto me-2">
                              {getOriginIcon(p.provider, '22px', 'auto')}
                            </span>
                            <span className="my-auto">{p.name}</span>
                            <FontAwesomeIcon
                              style={{ color: 'transparent' }}
                              className="ms-auto align-self-center"
                              icon={
                                selected.includes(p.account_id)
                                  ? faMinus
                                  : faPlus
                              }
                              title="新 增"
                            />
                          </ListGroup.Item>
                        ))}
                      </InfiniteScroll>
                    </ListGroup>
                  </Modal.Body>
                </Modal.Dialog>
              </Col>
              <Col xs="auto" className="d-flex" style={{ width: '3%' }}>
                <FontAwesomeIcon
                  className="m-auto fs-2 text-luca"
                  icon={faAngleRight}
                />
              </Col>
              <Col style={{ width: '48.5%' }}>
                <Modal.Dialog>
                  <Modal.Header
                    id="form-dialog-title"
                    className="m-auto border-0 h6 text-lucaDark"
                  >
                    已 匯 入 （{all.length}）
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col xs={6} className="BmsAccountSelector">
                        <SelectBar
                          setting={{
                            method: (e) => {
                              setassignedPlatform(e.target.value)
                            },
                            placeholder: '選擇媒體平台類型',
                            content: [
                              { name: 'Facebook', value: 'facebook' },
                              { name: 'Google', value: 'google' },
                            ],
                          }}
                        />
                      </Col>
                      <Col xs={6} className="ps-0">
                        <Form.Control
                          type="text"
                          className="mb-3"
                          value={assignedSearch}
                          placeholder="輸入關鍵字搜尋..."
                          onChange={(event) =>
                            setassignedSearch(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                    <ListGroup
                      id="imported"
                      style={{ maxHeight: '22rem', overflowY: 'auto' }}
                    >
                      <InfiniteScroll
                        dataLength={loadedImported}
                        next={() => setloadedImported(loadedImported + 20)}
                        hasMore={loadedImported < filteredAssigned.length}
                        loader={<h4>Loading...</h4>}
                        endMessage={<p />}
                        scrollableTarget="imported"
                      >
                        {filteredAssigned.map((p, i) => (
                          <ListGroup.Item
                            // onClick={() => handleClick(p.account_id)}
                            className="d-flex my-auto"
                            style={{ minHeight: '3rem', height: '3rem' }}
                            key={`${i}_${p.account_id}`}
                            title={p.name}
                          >
                            <span className="my-auto me-2">
                              {getOriginIcon(p.provider, '22px', 'auto')}
                            </span>
                            <span className="my-auto">{p.name}</span>
                            <FontAwesomeIcon
                              style={{ color: 'transparent' }}
                              className="ms-auto align-self-center"
                              icon={faPlus}
                              title="新 增"
                            />
                          </ListGroup.Item>
                        ))}
                      </InfiniteScroll>
                    </ListGroup>
                  </Modal.Body>
                </Modal.Dialog>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      ) : (
        <Modal.Body className="d-flex">
          <div className="m-auto d-flex my-5">
            <Spinner
              className="m-auto"
              animation="border"
              variant="luca"
              size="sm"
              style={{
                animation: 'spinner-border 1.5s linear infinite',
              }}
            />
            <h5 className="text-luca my-auto ms-2">資料載入中...</h5>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="justify-content-center m-2">
        <Button variant="secondary" onClick={() => handleClose()}>
          取 消
        </Button>
        <Button variant="luca" onClick={() => handleClose(true)}>
          確 定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AccountImport.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AccountImport
