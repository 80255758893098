import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/Alert'

function AlertBar(props) {
  const { setting, show, setshow } = props
  const { content } = setting
  return (
    <Alert
      show={show}
      style={{ zIndex: 1 }}
      variant="danger"
      onClose={() => setshow(false)}
      dismissible
    >
      {content}
    </Alert>
  )
}

AlertBar.propTypes = {
  show: PropTypes.bool.isRequired,
  setshow: PropTypes.func.isRequired,
  setting: PropTypes.shape(),
}

AlertBar.defaultProps = {
  setting: {
    content: '登 入 失 敗 ， 請 確 認 帳 號 和 密 碼 是 否 輸 入 正 確 。',
  },
}

export default AlertBar
