/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import DatePicker from 'react-datepicker'
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faRobot,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons'
import { SocketContext } from '../ContextProvider'
import SelectBar from '../SelectBar'
// import moment from 'moment'
import {
  customEventType,
  bidStrategy,
  billingEvent,
  //   billingEvent,
  callToActionType,
  callToActionTarget,
} from './enums/AdsEnums'
import apiService from '../../services/apiService'

function InputField(props) {
  const { setting } = props
  const { title, name, onChange, value } = setting
  return (
    <>
      <Form.Label>{title}</Form.Label>
      <Form.Group className="text-start">
        <Form.Control onChange={onChange} name={name} value={value} />
      </Form.Group>
    </>
  )
}

function AdsCreateDialog(props) {
  const { show, setting } = props
  const {
    datas,
    handleCreateClose,
    // creativePreview,
    // setCreativePreview,
    // setcreativePreviewUpdated,
    // getCreativePreview,
    uploadedImage,
    handleUploadImage,
    uploadImage,
    uploadedVideo,
    handleUploadVideo,
    uploadVideo,
    handleSetChange,
    selectSet,
    // setSelectSet,
  } = setting
  const selectTab =
    // eslint-disable-next-line no-nested-ternary
    selectSet.campaignId === ''
      ? 'campaign'
      : selectSet.adsetId === ''
      ? 'adset'
      : 'ad'

  const [gptOption, setgptOption] = useState({
    wordLimit: 150,
    otherInfo: '',
    style: '',
  })
  const handleGptOptionChange = (e) =>
    setgptOption({ ...gptOption, [e.target.name]: e.target.value })

  const [generating, setgenerating] = useState(false)
  const [gptMessage, setgptMessage] = useState('')
  const getChatgpt = async () => {
    const { wordLimit, otherInfo, style } = gptOption
    handleSetChange({
      target: {
        name: 'message',
        value: '',
      },
    })
    setgptMessage('')
    setgenerating(true)
    apiService.data({
      path: `chatgpt?wordLimit=${wordLimit}&otherInfo=${otherInfo}&style=${style}`,
      method: 'get',
    })
    // handleSetChange({
    //   target: {
    //     name: 'message',
    //     value: message,
    //   },
    // })
    // setgenerating(false)
  }

  const socket = useContext(SocketContext)
  useEffect(() => {
    if (socket !== null) {
      socket.on('gpt', (message) => {
        if (message === 'done') {
          setgenerating(false)
          handleSetChange({
            target: {
              name: 'message',
              value: gptMessage,
            },
          })
          return
        }
        if (message) setgptMessage((prevState) => `${prevState}${message}`)
      })
    }
  }, [socket])

  // dot control
  const [dot, setdot] = useState(' ·')
  useEffect(() => {
    if (!generating) return
    setTimeout(() => {
      if (dot.length === 3) setdot('')
      else setdot(`${dot}·`)
    }, 550)
  }, [dot, generating])

  // word limiter
  // const [wordValue, setWordValue] = useState('')
  const words = [
    { name: '150字', value: 150 },
    { name: '300字', value: 300 },
    { name: '500字', value: 500 },
  ]

  // get pages
  const [pages, setpages] = useState([])
  useEffect(() => {
    const getData = async () => {
      const res = await apiService.data({
        path: `luca/insight/datas?targets=page&provider=facebook`,
        method: 'get',
      })
      setpages(res.page)
      return res
    }
    getData()
  }, [])

  //   useEffect(() => {
  //     if (selectSet.creativeId !== '') {
  //       getCreativePreview({ creative_id: selectSet.creativeId })
  //       setcreativePreviewUpdated(true)
  //     } else {
  //       setCreativePreview(null)
  //     }
  //   }, [selectSet.creativeId])

  //   useEffect(() => {
  //     if (selectSet.objectStoryId !== '') {
  //       getCreativePreview({ object_story_id: selectSet.objectStoryId })
  //       setcreativePreviewUpdated(true)
  //     } else {
  //       setCreativePreview(null)
  //     }
  //   }, [selectSet.objectStoryId])

  //   const getObjectStorySpec = () => {
  //     if (selectSet.adImageHash !== '')
  //       return {
  //         object_story_spec: {
  //           page_id: selectSet.promoted_object.page_id,
  //           link_data: {
  //             image_hash: selectSet.adImageHash,
  //             link: selectSet.link,
  //             message: selectSet.message,
  //           },
  //         },
  //       }
  //     if (selectSet.video_id !== '')
  //       return {
  //         object_story_spec: {
  //           page_id: selectSet.promoted_object.page_id,
  //           video_data: {
  //             image_url: selectSet.image_url,
  //             video_id: selectSet.video_id,
  //             call_to_action: {
  //               type: 'LIKE_PAGE',
  //               value: { page: selectSet.promoted_object.page_id },
  //             },
  //           },
  //         },
  //       }
  //     return null
  //   }
  //   useEffect(() => {
  //     const event = { target: {} }
  //     const video =
  //       datas.adVideos && datas.adVideos.find((v) => v.id === selectSet.video_id)
  //     if (video) {
  //       event.target.name = 'image_url'
  //       event.target.value = video.thumbnails.data[0].uri
  //       handleSetChange(event)
  //     }
  //   }, [selectSet.video_id])

  //   useEffect(() => {
  //     if (getObjectStorySpec() !== null) {
  //       getCreativePreview(JSON.stringify(getObjectStorySpec()))
  //       setcreativePreviewUpdated(true)
  //     } else {
  //       setCreativePreview(null)
  //     }
  //   }, [
  //     selectSet.image_url,
  //     selectSet.adImageHash,
  //     selectSet.link,
  //     selectSet.message,
  //   ])

  const [useCBO, setUseCBO] = useState(true)
  const [budgetType, setBudgetType] = useState('lifeTime')
  const handleLifeTime = (event) => {
    if (event.target.value === 'daily') delete selectSet.lifetime_budget
    else delete selectSet.dailt_budget
    setBudgetType(event.target.value)
  }

  //   const [createAdType, setCreateAdType] = useState('id')
  //   const handleAdType = (event) => {
  //     if (event.target.value !== 'id') {
  //       setSelectSet({ ...selectSet, postId: '', objectStoryId: '' })
  //     }
  //     if (event.target.value !== 'new') {
  //       setSelectSet({
  //         ...selectSet,
  //         adImageHash: '',
  //         video_id: '',
  //         image_url: '',
  //         link: '',
  //         message: '',
  //       })
  //     }
  //     if (event.target.value !== 'creative') {
  //       setSelectSet({ ...selectSet, creativeId: '' })
  //     }
  //     setCreateAdType(event.target.value)
  //   }

  const selectedCampaign = () =>
    datas.campaign.find((element) => element.id === selectSet.campaignId)

  const setStartDate = (date) => {
    const event = { target: {} }
    event.target.name = 'start_date'
    event.target.value = date
    handleSetChange(event)
  }
  const setEndDate = (date) => {
    const event = { target: {} }
    event.target.name = 'end_date'
    event.target.value = date
    handleSetChange(event)
  }

  //   const paper = useRef()
  //   const getWidth = () => (paper.current ? paper.current.offsetWidth / 2 : 500)

  const purpose = {
    品牌認知: [
      { value: 'BRAND_AWARENESS', label: '品牌知名度' },
      { value: 'REACH', label: '觸及人數' },
    ],
    觸動考量: [
      { value: 'LINK_CLICKS', label: '流量' },
      { value: 'POST_ENGAGEMENT', label: '貼文互動' },
      { value: 'EVENT_RESPONSES', label: '事件響應' },
      { value: 'PAGE_LIKES', label: '粉絲專頁的讚' },
      { value: 'APP_INSTALLS', label: '應用程式安裝' },
      { value: 'VIDEO_VIEWS', label: '觀看影片' },
      { value: 'LEAD_GENERATION', label: '開發潛在客戶' },
      { value: 'MESSAGES', label: '發送訊息' },
    ],
    轉換行動: [
      { value: 'CONVERSIONS', label: '轉換次數' },
      { value: 'PRODUCT_CATALOG_SALES', label: '目錄銷售' },
      { value: 'STORE_VISITS', label: '來店客流量' },
    ],
  }

  // const movieItems = [
  //   {
  //     id: 0,
  //     title: 'Titanic',
  //     description: 'A movie about love',
  //   },
  //   {
  //     id: 1,
  //     title: 'Dead Poets Society',
  //     description: 'A movie about poetry and the meaning of life',
  //   },
  //   {
  //     id: 2,
  //     title: 'Terminator 2',
  //     description: 'A robot from the future is sent back in time',
  //   },
  //   {
  //     id: 3,
  //     title: 'Alien 2',
  //     description: 'Ripley is back for a new adventure',
  //   },
  // ]

  // const handleOnSearch = (string, results) => {
  //   console.log(string, results)
  // }

  // const handleOnHover = (result) => {
  //   console.log(result)
  // }

  // const handleOnSelect = (item) => {
  //   console.log(item)
  // }

  // const handleOnFocus = () => {
  //   console.log('Focused')
  // }

  // const handleOnClear = () => {
  //   console.log('Cleared')
  // }

  const [preview, setpreview] = useState(null)
  const getPreview = async (message) => {
    const object_story_spec = {
      link_data: {
        description: 'Description',
        link: 'www.google.com',
        message: message.trim().replaceAll('&', ''),
        name: 'Name',
      },
      page_id: pages[0].id,
    }
    const res = await apiService.data({
      path: `luca/insight/preview?api=facebook&account_id=16088&object_story_spec=${JSON.stringify(
        object_story_spec
      )}&provider=facebook`,
      method: 'get',
    })
    if (res && !res.error) {
      setpreview(res.datas)
    }
  }
  const size = {
    width: 540,
    height: 760,
  }
  const scale = 1

  return (
    <Modal
      size="xl"
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleCreateClose(false)}
      className="text-lucaDark"
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        <Modal.Title className="mx-auto">
          <h4>建 立 新 廣 告</h4>
          <p className="fs-6">Add the new Advertisting</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Row className="ps-4 text-luca h6">選擇廣告層級</Row>
        <div className="d-flex">
          <Row className="py-3 justify-content-center w-100">
            <Col className="ps-5 pe-0">
              <small>廣告活動</small>
              <SelectBar
                setting={{
                  id: 'defaultBorder',
                  method: handleSetChange,
                  placeholder: '新行銷活動',
                  name: 'campaignId',
                  value: selectSet.campaignId,
                  content:
                    datas.campaign &&
                    datas.campaign.map((campaign) => ({
                      name: campaign.name,
                      value: campaign.id,
                    })),
                }}
              />
            </Col>
            <FontAwesomeIcon
              className="fs-5 my-auto text-luca mx-3 pt-4"
              icon={faAngleRight}
              style={{
                transition: '.5s',
                transitionTimingFunction: 'ease-in',
                width: '2%',
              }}
            />
            <Col className="d-flex px-0">
              <div className="w-100">
                <small>廣告設定</small>
                {selectSet.campaignId === '' ? (
                  <h6
                    id="defaultForm"
                    className="m-auto fw-normal text-grey"
                    style={{ cursor: 'not-allowed' }}
                  >
                    - -
                  </h6>
                ) : (
                  <SelectBar
                    setting={{
                      id: 'defaultBorder',
                      method: handleSetChange,
                      placeholder: '建立新廣告組合',
                      name: 'adsetId',
                      value: selectSet.adsetId,
                      content:
                        datas.adset &&
                        datas.adset.map((adset) => ({
                          name: adset.name,
                          value: adset.adset_id,
                        })),
                    }}
                  />
                )}
              </div>
            </Col>
            <FontAwesomeIcon
              className="fs-5 my-auto text-luca mx-3 pt-4"
              icon={faAngleRight}
              style={{
                transition: '.5s',
                transitionTimingFunction: 'ease-in',
                width: '2%',
              }}
            />
            <Col className="d-flex px-0">
              <div className="w-100">
                <small>廣告內容</small>
                <h6
                  id="defaultForm"
                  className="m-auto fw-normal text-grey"
                  style={{ cursor: 'not-allowed' }}
                >
                  {selectSet.campaignId !== '' && selectSet.adsetId !== ''
                    ? '建立新廣告'
                    : '- -'}
                </h6>
              </div>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
        {selectTab === 'campaign' && (
          <Row className="pt-3">
            <Col xs={6} className="ps-4">
              <Card className="mb-3">
                <h6 className="text-luca mb-2">行銷活動名稱</h6>
                <Form.Control
                  onChange={handleSetChange}
                  name="name"
                  value={selectSet.name}
                />
              </Card>
              <h6 className="text-luca mb-2">設定活動目標</h6>
              <Row className="px-5 py-3">
                {Object.keys(purpose).map((key) => (
                  <Col key={key} xs={4} className="ps-3">
                    <h6 className="pb-2 text-luca fw-bold">{key}</h6>
                    {purpose[key].map((p) => (
                      <Form.Check
                        key={p.value}
                        name="purpose"
                        type="radio"
                        value={p.value}
                        label={p.label}
                        checked={selectSet.purpose === p.value}
                        onChange={handleSetChange}
                      />
                    ))}
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={6} className="px-5">
              <Row className="mb-3">
                <Col className="text-luca mb-2 h6">啟用行銷活動預算最佳化</Col>
                <Col className="d-flex">
                  <Form.Check
                    className="ms-auto"
                    type="switch"
                    checked={useCBO}
                    onChange={() => setUseCBO(!useCBO)}
                  />
                </Col>
              </Row>

              {useCBO && (
                <>
                  <Card className="pt-3">
                    <h6 className="text-luca mb-2">設定預算</h6>
                    <Row>
                      <Col xs={5}>
                        <SelectBar
                          setting={{
                            id: 'defaultBorder',
                            method: handleLifeTime,
                            placeholder: '選擇預算形式',
                            content: [
                              { name: '日預算', value: 'daily' },
                              { name: '總預算', value: 'lifeTime' },
                            ],
                          }}
                        />
                      </Col>
                      <Col xs={7}>
                        {budgetType === 'lifeTime' && (
                          <Form.Control
                            onChange={handleSetChange}
                            name="lifetime_budget"
                            value={selectSet.lifetime_budget}
                            label="總預算"
                            placeholder="請輸入金額..."
                          />
                        )}
                        {budgetType === 'daily' && (
                          <Form.Control
                            onChange={handleSetChange}
                            name="daily_budget"
                            value={selectSet.daily_budget}
                            label="日預算"
                            placeholder="請輸入金額..."
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>

                  <Card className="pt-3">
                    <h6 className="text-luca mb-2">設定最佳化目標</h6>
                    <Row>
                      <Col>
                        <SelectBar
                          setting={{
                            id: 'defaultBorder',
                            method: () => {},
                            placeholder: '選擇最佳化目標',
                            content: Object.keys(bidStrategy)
                              .filter((key) =>
                                bidStrategy[key].includes(selectSet.purpose)
                              )
                              .map((key) => ({
                                name: key,
                                value: key,
                              })),
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        )}
        {selectTab === 'adset' && (
          <Row>
            <Col xs={6} className="px-4">
              <Card className="mb-3">
                <h6 className="text-luca mb-2">廣告組合名稱</h6>
                <Form.Group className="text-start">
                  <Form.Control
                    onChange={handleSetChange}
                    name="name"
                    value={selectSet.name}
                  />
                </Form.Group>
              </Card>
              {/* <Card className="mb-3">
                <ReactSearchAutocomplete
                  items={movieItems}
                  fuseOptions={{ keys: ['title', 'description'] }} // Search on both fields
                  resultStringKeyName="title" // String to display in the results
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  onClear={handleOnClear}
                  showIcon={false}
                  styling={{
                    height: '34px',
                    border: '1px solid',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    hoverBackgroundColor: 'lightgreen',
                    color: 'darkgreen',
                    fontSize: '12px',
                    fontFamily: 'Courier',
                    iconColor: 'green',
                    lineColor: 'lightgreen',
                    placeholderColor: 'darkgreen',
                    clearIconMargin: '3px 8px 0 0',
                    zIndex: 2,
                  }}
                />
              </Card> */}
              {selectedCampaign() &&
                (selectedCampaign().objective === 'PAGE_LIKES' ||
                  selectedCampaign().objective === 'OFFER_CLAIMS') && (
                  <Row className="mb-3">
                    <h6 className="text-luca mb-2" component="legend">{`選擇${
                      selectedCampaign().objective
                    }推廣目標`}</h6>
                    {/* <Autocomplete
                    id="page_select"
                    name="promoted_object"
                    filterSelectedOptions
                    options={getFetchedData('pages')}
                    onChange={handleSetChange}
                    getOptionLabel={(option) => option.name || ''}
                    renderOption={(option) => (
                      <>
                        {option.picture && (
                          <Avatar
                            variant="square"
                            src={option.picture.data.url}
                          />
                        )}
                        {option.name}
                      </>
                    )}
                    renderInput={(params) => (
                      <Form.Control
                        {...params}
                        variant="outlined"
                        placeholder="select page"
                      />
                    )}
                  /> */}
                  </Row>
                )}
              {selectedCampaign() &&
                selectedCampaign().objective === 'CONVERSIONS' && (
                  <>
                    <Row className="mb-3">
                      <h6 className="text-luca mb-2">{`選擇${
                        selectedCampaign().objective
                      }推廣目標`}</h6>
                      <SelectBar
                        setting={{
                          id: 'defaultBorder',
                          method: handleSetChange,
                          placeholder: '未選擇',
                          name: 'promoted_object.pixel_id',
                          value: selectSet.promoted_object.pixel_id,
                          content: [].map((adsPixels) => ({
                            name: adsPixels.name,
                            value: adsPixels.id,
                          })),
                        }}
                      />
                    </Row>
                    <Row className="mb-3">
                      <h6 className="text-luca mb-2">選擇事件類型</h6>
                      <SelectBar
                        setting={{
                          id: 'defaultBorder',
                          method: handleSetChange,
                          placeholder: '未選擇',
                          name: 'promoted_object.custom_event_type',
                          value: selectSet.promoted_object.custom_event_type,
                          content: Array.from(customEventType).map(
                            (eventType) => ({
                              name: eventType,
                              value: eventType,
                            })
                          ),
                        }}
                      />
                    </Row>
                  </>
                )}
              <Row className="mb-3">
                <h6 className="text-luca mb-2">設定計價事件</h6>
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    name: 'billing_event',
                    method: handleSetChange,
                    placeholder: '選擇計價事件',
                    value: selectSet.billing_event,
                    content: Array.from(billingEvent).map((event) => ({
                      name: event,
                      value: event,
                    })),
                  }}
                />
              </Row>
            </Col>
            <Col xs={6}>
              {selectedCampaign() &&
                selectedCampaign().dailt_budget === undefined &&
                selectedCampaign().lifetime_budget === undefined && (
                  <Card className="mb-3">
                    <h6 className="text-luca mb-2">設定預算</h6>
                    <Row>
                      <Col xs={5}>
                        <SelectBar
                          setting={{
                            id: 'defaultBorder',
                            method: handleLifeTime,
                            placeholder: '選擇預算形式',
                            content: [
                              { name: '日預算', value: 'daily' },
                              { name: '總預算', value: 'lifeTime' },
                            ],
                          }}
                        />
                      </Col>
                      <Col xs={7}>
                        {budgetType === 'lifeTime' && (
                          <Form.Control
                            onChange={handleSetChange}
                            name="lifetime_budget"
                            value={selectSet.lifetime_budget}
                            label="總預算"
                            placeholder="請輸入金額..."
                          />
                        )}
                        {budgetType === 'daily' && (
                          <Form.Control
                            onChange={handleSetChange}
                            name="daily_budget"
                            value={selectSet.daily_budget}
                            label="日預算"
                            placeholder="請輸入金額..."
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>
                )}
              {selectedCampaign() &&
                selectedCampaign().bid_strategy !==
                  'LOWEST_COST_WITHOUT_CAP' && (
                  <Row className="mb-3">
                    <h6 className="text-luca mb-2" component="legend">
                      出價上限
                    </h6>
                    <Form.Group className="text-start">
                      <Form.Control
                        onChange={handleSetChange}
                        name="bid_amount"
                        value={selectSet.bid_amount}
                        label="bid_amount"
                      />
                    </Form.Group>
                  </Row>
                )}
              <Row>
                <Col>
                  <h6 className="text-luca mb-2">開始日期</h6>
                  <DatePicker
                    variant="inline"
                    // format="yyyy/MM/dd HH:mm"
                    margin="normal"
                    id="defaultBorder"
                    label="Date"
                    value={selectSet.start_date || Date.now()}
                    onChange={setStartDate}
                    disablePast
                  />
                </Col>
                <Col>
                  <h6 className="text-luca mb-2 d-flex">
                    {selectSet.end_date === null && '設定'}結束日期
                    <span className="d-flex ms-auto">
                      <Form.Check
                        className="ms-auto"
                        type="switch"
                        checked={selectSet.end_date !== null}
                        onChange={() =>
                          selectSet.end_date === null
                            ? setEndDate(new Date())
                            : setEndDate(null)
                        }
                      />
                    </span>
                  </h6>
                  <div
                    className={`${selectSet.end_date === null && 'notAllowed'}`}
                  >
                    <DatePicker
                      variant="inline"
                      // format="yyyy/MM/dd HH:mm"
                      id="defaultBorder"
                      margin="normal"
                      label="Date"
                      disabled={selectSet.end_date === null}
                      value={selectSet.end_date}
                      onChange={setEndDate}
                      disablePast
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {selectTab === 'ad' && (
          <Row
            style={{
              height: '55vh',
            }}
          >
            <Col className="h-100 scrollbarShow overflow-auto me-3 ps-4">
              <Card className="mb-3">
                <h6 className="text-luca mb-2">廣告名稱</h6>
                <Form.Group className="text-start">
                  <Form.Control
                    onChange={handleSetChange}
                    name="name"
                    value={selectSet.name}
                    label="為這個廣告命名"
                  />
                </Form.Group>
              </Card>
              <Card>
                <h6 className="text-luca mb-2" component="legend">
                  選擇粉絲專頁
                </h6>
                <SelectBar
                  setting={{
                    id: 'defaultBorder',
                    // method: handleAdType,
                    placeholder: '未選擇',
                    // value: createAdType,
                    content: pages.map((p) => ({
                      name: p.name,
                      value: p.id,
                    })),
                  }}
                />
              </Card>
              {selectSet.promoted_object.page_id && (
                <>
                  <Card className="mb-3">
                    <SelectBar
                      setting={{
                        id: 'defaultBorder',
                        // method: handleAdType,
                        placeholder: '未選擇',
                        // value: createAdType,
                        content: [
                          { name: '使用貼文編號', value: 'id' },
                          { name: '建立廣告貼文', value: 'new' },
                          { name: '使用廣告創意', value: 'creative' },
                        ],
                      }}
                    />
                  </Card>
                  <Card className="mb-3">
                    <h6 className="text-luca mb-2">輸入貼文編號</h6>
                    <Form.Group className="text-start">
                      <Form.Control
                        onChange={handleSetChange}
                        name="postId"
                        value={selectSet.postId}
                        label="輸入貼文編號"
                      />
                    </Form.Group>
                  </Card>
                  <Card className="mb-3">
                    <h6 className="text-luca mb-2" component="legend" mt={3}>
                      選擇廣告創意
                    </h6>
                    <SelectBar
                      setting={{
                        id: 'defaultBorder',
                        name: 'creativeId',
                        method: handleSetChange,
                        placeholder: '未選擇',
                        value: selectSet.creativeId,
                        content: [].map((adCreative) => ({
                          name: adCreative.name,
                          value: adCreative.id,
                        })),
                      }}
                    />
                  </Card>
                  {selectSet.objectStoryId === '' && (
                    <>
                      {selectSet.video_id === '' && (
                        <Card className="mb-3">
                          <h6
                            className="text-luca mb-1"
                            component="legend"
                            mt={3}
                          >
                            選擇圖片
                          </h6>
                          <SelectBar
                            setting={{
                              id: 'defaultBorder',
                              name: 'adImageHash',
                              method: handleSetChange,
                              placeholder: '未選擇',
                              value: selectSet.creativeId,
                              content: [].map((adImage) => ({
                                name: adImage.name,
                                value: adImage.hash,
                              })),
                            }}
                          />
                        </Card>
                      )}
                      {selectSet.adImageHash === '' && (
                        <Card className="mb-3">
                          <h6
                            className="text-luca mb-1"
                            component="legend"
                            mt={3}
                          >
                            選擇影片
                          </h6>
                          <SelectBar
                            setting={{
                              id: 'defaultBorder',
                              name: 'video_id',
                              method: handleSetChange,
                              placeholder: '未選擇',
                              value: selectSet.video_id,
                              content: [].map((adVideo) => ({
                                name: adVideo.name,
                                value: adVideo.id,
                              })),
                            }}
                          />
                        </Card>
                      )}
                      <Card className="mb-3">
                        <h6 className="text-luca mb-1" mt={3}>
                          上傳圖片
                        </h6>
                        <Row>
                          <Col xs={10}>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              id="input"
                              onChange={(event) => handleUploadImage(event)}
                            />
                          </Col>
                          <Col className="d-flex pe-3 ps-0">
                            <Button
                              variant="luca"
                              className="ms-auto w-100"
                              // disabled={uploadedImage.fileUrl === ''}
                              // onClick={uploadImage}
                            >
                              上 傳
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="mb-3">
                        <h6 className="text-luca mb-1" mt={3}>
                          上傳影片
                        </h6>
                        <Row>
                          <Col xs={10}>
                            <Form.Control
                              type="file"
                              accept="video/*"
                              id="input"
                              onChange={(event) => handleUploadVideo(event)}
                            />
                          </Col>
                          <Col className="d-flex pe-3 ps-0">
                            <Button
                              variant="luca"
                              className="ms-auto w-100"
                              // disabled={uploadedVideo.fileUrl === ''}
                              // onClick={uploadVideo}
                            >
                              上 傳
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                      {/* {uploadedVideo.fileUrl !== '' && (
                            <Row className="mb-3">
                              <video controls width={getWidth()}>
                                <source
                                  src={uploadedVideo.fileUrl}
                                  type="video/mp4"
                                />
                              </video>
                            </Row>
                          )} */}
                      {/* {uploadedImage.fileUrl !== '' && (
                          <Row className="mb-3">
                            <Image
                                width={getWidth()}
                                src={uploadedImage.fileUrl}
                              />
                          </Row>
                        )} */}

                      <Card className="mb-3">
                        <h6 className="text-luca mb-1">輸入連結</h6>
                        <Form.Group>
                          <Form.Control
                            onChange={handleSetChange}
                            name="link"
                            value={selectSet.link}
                          />
                        </Form.Group>
                      </Card>
                      <Card className="mb-3">
                        <div className="d-flex">
                          <h6 className="text-luca my-auto">輸入訊息</h6>
                        </div>
                        <div className="d-flex row py-1">
                          {/* 文案關鍵字 */}
                          <Col xs={2} className="text-end pe-1">
                            <Form.Control
                              className=""
                              size="sm"
                              type="text"
                              placeholder="關鍵字..."
                              name="otherInfo"
                              onChange={handleGptOptionChange}
                              value={gptOption.otherInfo}
                              title="請 輸 入 文 案 關 鍵 字"
                            />
                          </Col>
                          {/* 文案風格 */}
                          <Col xs={2} className="text-end px-0">
                            <Form.Select
                              className="gpt-form-select"
                              size="sm"
                              type="text"
                              placeholder="風格"
                              name="style"
                              onChange={handleGptOptionChange}
                              value={gptOption.style}
                              title="請 選 擇 文 案 撰 寫 風 格"
                            >
                              <option>簡約</option>
                              <option>清新</option>
                              <option>浮誇</option>
                              <option>質感</option>
                              <option>白話</option>
                              <option>新潮</option>
                            </Form.Select>
                          </Col>
                          {/* 字數選擇 */}
                          <Col
                            xs={4}
                            className="d-flex justify-content-end px-0"
                          >
                            <ButtonGroup>
                              {words.map((word, index) => (
                                <ToggleButton
                                  size="sm"
                                  key={index}
                                  name="wordLimit"
                                  onClick={() =>
                                    setgptOption({
                                      ...gptOption,
                                      wordLimit: word.value,
                                    })
                                  }
                                  type="radio"
                                  variant={
                                    word.value === gptOption.wordLimit
                                      ? 'luca'
                                      : 'outline-luca'
                                  }
                                  value={word.value}
                                  checked={word.value === gptOption.wordLimit}
                                >
                                  {word.name}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                          </Col>
                          {/* 文案產生功能按鈕 */}
                          <Col xs={4} className="d-flex ps-0">
                            <Button
                              size="sm"
                              variant="lucaLighter"
                              className="ms-auto"
                              onClick={getChatgpt}
                              disabled={generating}
                              title={
                                gptMessage !== '' && !generating
                                  ? '重 新 產 生 文 案'
                                  : '點 擊 此 按 鍵 ， 將 由 ChatGPT AI 自 動 生 成 廣 告 文 案。'
                              }
                            >
                              {gptMessage !== '' ? (
                                <FontAwesomeIcon icon={faRotateRight} />
                              ) : generating ? (
                                '產生中'
                              ) : (
                                'ChatGPT文案自動生成'
                              )}
                            </Button>
                            {gptMessage !== '' && (
                              <>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  className="mx-1"
                                  onClick={() => {
                                    handleSetChange({
                                      target: {
                                        name: 'message',
                                        value: '',
                                      },
                                    })
                                    setgptMessage('')
                                  }}
                                  disabled={generating}
                                  title="清除文案內容"
                                >
                                  清除
                                </Button>
                                <Button
                                  size="sm"
                                  variant="luca"
                                  className=""
                                  onClick={() => getPreview(gptMessage)}
                                  disabled={generating}
                                  title="套用文案內容"
                                >
                                  套用
                                </Button>
                              </>
                            )}
                          </Col>
                        </div>
                        {generating && false ? (
                          <div
                            id="defaultBorder"
                            className="d-flex rounded p-2 py-5 justify-content-center"
                          >
                            <FontAwesomeIcon
                              className="fs-6 text-lucaLight my-auto"
                              style={{
                                right: '10',
                                top: '50',
                                bottom: '50',
                              }}
                              icon={faRobot}
                            />
                            <p className="h6 ps-2 text-lucaLight">
                              文案產生中 {dot}
                            </p>
                          </div>
                        ) : (
                          <Form.Control
                            as="textarea"
                            style={{
                              height: 'auto',
                            }}
                            rows={
                              gptMessage ? 14 : selectSet.message.length / 31
                            }
                            onChange={(e) => {
                              if (!generating) setgptMessage(e.target.value)
                            }}
                            name="message"
                            value={
                              gptMessage.trim() || selectSet.message.trim()
                            }
                            label="輸入訊息"
                          />
                        )}
                      </Card>
                      <Card className="mb-3">
                        <h6 className="text-luca mb-2" component="legend">
                          行動呼籲類型
                        </h6>
                        <SelectBar
                          setting={{
                            id: 'defaultBorder',
                            method: handleSetChange,
                            placeholder: '無行動呼籲按鈕',
                            name: 'call_to_action.type',
                            value: selectSet.call_to_action.type,
                            content: Array.from(callToActionType).map(
                              (actionType) => ({
                                name: actionType,
                                value: actionType,
                              })
                            ),
                          }}
                        />
                      </Card>
                      <Card className="mb-3">
                        <h6 className="text-luca mb-2">行動呼籲目標</h6>
                        <SelectBar
                          setting={{
                            id: 'defaultBorder',
                            method: handleSetChange,
                            placeholder: '選擇行動呼籲目標',
                            name: 'call_to_action.target',
                            value: selectSet.call_to_action.target,
                            content: Array.from(callToActionTarget).map(
                              (target) => ({
                                name: target,
                                value: target,
                              })
                            ),
                          }}
                        />
                      </Card>
                      <Card className="mb-3">
                        <h6 className="text-luca mb-2">行動呼籲訊息</h6>
                        <Form.Control
                          onChange={handleSetChange}
                          name="call_to_action.value"
                          value={selectSet.call_to_action.value}
                          label="行動呼籲訊息"
                        />
                      </Card>
                    </>
                  )}
                </>
              )}
            </Col>
            <Col className="h-100 d-flex flex-column">
              <h6 className="text-luca mb-2" component="legend" mt={3}>
                廣告預覽
              </h6>
              <Row id="defaultBorder" className="flex-grow-1 mb-3">
                {preview ? (
                  <div
                    className="overflow-hidden"
                    style={{
                      width: size.width * scale,
                      height: size.height * scale,
                    }}
                  >
                    <iframe
                      title="preview"
                      src={preview}
                      width={size.width}
                      height={size.height}
                      style={{
                        transform: `scale(${scale}) translate(${
                          (size.width * scale - size.width) * 1.25
                        }px, ${(size.height * scale - size.height) * 1.25}px)`,
                      }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </Row>
            </Col>
          </Row>
        )}
        {selectTab === 'adCreative' && (
          <>
            {/* <Row className="mb-3">
              <h6 className="text-luca mb-2" component="legend" mt={3}>選擇影片</h6>
              <NativeSelect name="videoId" value={selectSet.videoId} onChange={handleSetChange}>
                <option value="">未選擇</option>
                {allData.adVideos.map(adVideo => {
                  return <option key={adVideo.id} value={adVideo.id}>{adVideo.name}</option>
                })}
              </NativeSelect>
            </Row> */}
            <Row className="mb-3">
              <h6 className="text-luca mb-2" component="legend">
                選擇粉絲專頁
              </h6>
              {/* <Autocomplete
                id="page_select"
                name="promoted_object"
                filterSelectedOptions
                options={getFetchedData('pages')}
                onChange={handleSetChange}
                getOptionLabel={(option) => option.name || ''}
                // renderTags={option => <Fragment>{option.picture && <Avatar variant="square" src={option.picture.data.url} />}</Fragment>}
                renderOption={(option) => (
                  <>
                    {option.picture && (
                      <Avatar variant="square" src={option.picture.data.url} />
                    )}
                    {option.name}
                  </>
                )}
                renderInput={(params) => (
                  <Form.Control
                    {...params}
                    variant="outlined"
                    placeholder="select page"
                  />
                )}
              /> */}
            </Row>
            {selectSet.promoted_object.page_id && (
              <Row className="mb-3">
                <Form.Label>輸入貼文編號</Form.Label>
                <Form.Group className="text-start">
                  <Form.Control
                    onChange={handleSetChange}
                    name="postId"
                    value={selectSet.postId}
                    label="輸入貼文編號"
                  />
                </Form.Group>
              </Row>
            )}
            {selectSet.objectStoryId === '' && (
              <>
                {selectSet.video_id === '' && (
                  <Row className="mb-3">
                    <h6 className="text-luca mb-2" component="legend" mt={3}>
                      選擇圖片
                    </h6>
                    {/* <Select
                      name="adImageHash"
                      value={selectSet.adImageHash}
                      onChange={handleSetChange}
                    >
                      <MenuItem value="">未選擇</MenuItem>
                      {getFetchedData('adImages').map((adImage) => (
                        <MenuItem key={adImage.id} value={adImage.hash}>
                          <Avatar
                            variant="square"
                            src={adImage.permalink_url}
                          />
                          {adImage.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </Row>
                )}
                {selectSet.adImageHash === '' && (
                  <Row className="mb-3">
                    <h6 className="text-luca mb-2" component="legend" mt={3}>
                      選擇影片
                    </h6>
                    {/* <Select
                      name="video_id"
                      value={selectSet.video_id}
                      onChange={handleSetChange}
                    >
                      <MenuItem value="">未選擇</MenuItem>
                      {getFetchedData('adVideos').map((adVideo) => (
                        <MenuItem key={adVideo.id} value={adVideo.id}>
                          <Avatar
                            variant="square"
                            src={adVideo.thumbnails.data[0].uri}
                          />
                          {adVideo.id}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </Row>
                )}
                <Row className="mb-3">
                  <h6 className="text-luca mb-2" component="legend" mt={3}>
                    上傳圖片
                  </h6>
                </Row>
                <Row className="mb-3" display="flex">
                  <Row>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      id="input"
                      onChange={(event) => handleUploadImage(event)}
                    />
                  </Row>
                  <Button
                    variant="contained"
                    disabled={uploadedImage.fileUrl === ''}
                    onClick={uploadImage}
                  >
                    上傳
                  </Button>
                </Row>
                <Row className="mb-3">
                  <h6 className="text-luca mb-2" component="legend" mt={3}>
                    上傳影片
                  </h6>
                </Row>
                <Row className="mb-3" display="flex">
                  <Row>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      id="input"
                      onChange={(event) => handleUploadVideo(event)}
                    />
                  </Row>
                  <Button
                    variant="contained"
                    disabled={uploadedVideo.fileUrl === ''}
                    onClick={uploadVideo}
                  >
                    上傳
                  </Button>
                </Row>
                {uploadedVideo.fileUrl !== '' && (
                  <Row className="mb-3">
                    {/* <video controls width={getWidth()}>
                      <source src={uploadedVideo.fileUrl} type="video/mp4" />
                    </video> */}
                  </Row>
                )}
                {uploadedImage.fileUrl !== '' && (
                  <Row className="mb-3">
                    {/* <img width={getWidth()} src={uploadedImage.fileUrl} /> */}
                  </Row>
                )}

                <Row className="mb-3">
                  <Form.Control
                    onChange={handleSetChange}
                    name="link"
                    value={selectSet.link}
                    label="輸入連結"
                  />
                </Row>
                <Row className="mb-3">
                  <div className="d-flex">
                    <h6 className="text-luca my-auto">輸入訊息</h6>
                    <Button>chatgpt文案自動生成</Button>
                  </div>
                  <Form.Control
                    as="textarea"
                    style={{
                      height: 'auto',
                    }}
                    onChange={handleSetChange}
                    name="message"
                    value={selectSet.message}
                    label="輸入訊息"
                  />
                </Row>
                <Row className="mb-3">
                  <h6 className="text-luca mb-2" component="legend">
                    行動呼籲類型
                  </h6>
                  {/* <Select
                    name="call_to_action.type"
                    value={selectSet.call_to_action.type}
                    onChange={handleSetChange}
                  >
                    <MenuItem calue="">無行動呼籲按鈕</MenuItem>
                    {Array.from(callToActionType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <h6 className="text-luca mb-2" component="legend">
                    行動呼籲目標
                  </h6>
                  {/* <Select
                    name="call_to_action.target"
                    value={selectSet.call_to_action.target}
                    onChange={handleSetChange}
                  >
                    {Array.from(callToActionTarget).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Form.Control
                    onChange={handleSetChange}
                    name="call_to_action.value"
                    value={selectSet.call_to_action.value}
                    label="訊息"
                  />
                </Row>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleCreateClose(false)} variant="secondary">
          取 消
        </Button>
        <Button
          onClick={() => handleCreateClose(true)}
          disabled={
            !(
              (selectTab === 'campaign' && selectSet.name !== '') ||
              (selectTab === 'adSet' &&
                selectSet.name !== '' &&
                selectSet.campaignId !== '') ||
              (selectTab === 'ad' &&
                selectSet.name !== '' &&
                selectSet.adSetId !== '' &&
                selectSet.objectStoryId !== '') ||
              (selectTab === 'ad' &&
                selectSet.name !== '' &&
                selectSet.adSetId !== '' &&
                selectSet.adCreativeId !== '') ||
              (selectTab === 'adCreative' &&
                selectSet.name !== '' &&
                selectSet.adImageHash !== '' &&
                selectSet.promoted_object.page_id) ||
              (selectTab === 'adCreative' &&
                selectSet.name !== '' &&
                selectSet.objectStoryId !== '') ||
              (selectTab === 'adCreative' &&
                selectSet.name !== '' &&
                selectSet.video_id !== '' &&
                selectSet.promoted_object.page_id)
            )
          }
          variant="luca"
        >
          完 成
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

InputField.propTypes = {
  setting: PropTypes.shape().isRequired,
}

AdsCreateDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setting: PropTypes.shape().isRequired,
}

export default AdsCreateDialog
