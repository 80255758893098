import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'react-bootstrap/Dropdown'

function Dropmenu(props) {
  const { setting } = props
  const {
    name,
    content,
    title,
    handleClick,
    disabled,
    className = '',
  } = setting
  return (
    <Dropdown align="end" className={`my-auto mx-2 ${className}`} title={title}>
      <Dropdown.Toggle
        className="btn-lucaWhite3"
        id="dropdown-basic"
        disabled={disabled}
      >
        {name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {content.map((c, i) => (
          <Dropdown.Item
            key={`${i}_${c.name}`}
            href=""
            onClick={() => handleClick(i)}
          >
            {c.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

Dropmenu.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Dropmenu
