import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'

function SpinnerPanel(props) {
  const { show } = props
  return (
    <Modal style={{ opacity: '0.5' }} show={show} fullscreen>
      <Spinner className="m-auto" animation="border" variant="luca" />
    </Modal>
  )
}

SpinnerPanel.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default SpinnerPanel
