import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'

function Datatable(props) {
  const { setting } = props
  const { hasCheckBox, hasPagination, headers, hasButton, pageSize } = setting
  const [page, setpage] = useState(1)
  const content = setting.content.filter((c) => !c.filtered)
  useEffect(() => {
    const max = Math.min(page, Math.ceil(content.length / pageSize))
    if (max && max < page) setpage(max)
  }, [content.length, page, pageSize])

  return (
    <div className="acc-data-table d-flex flex-column h-100">
      <Table bordered responsive className="mb-0 w-100 h-100">
        {headers && (
          <thead>
            <tr>
              {hasCheckBox && <th />}
              {headers.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
              {hasButton && <th />}
            </tr>
          </thead>
        )}
        <tbody>
          {content.slice(pageSize * (page - 1), pageSize * page).map((c, i) => (
            <tr key={i}>
              {hasCheckBox && (
                <td>
                  <Form.Check
                    type="checkbox"
                    className="d-flex text-start align-content-start mb-0 fs-7"
                    title="全 選"
                  >
                    {hasCheckBox && (
                      <Form.Check.Input
                        style={{ cursor: 'pointer' }}
                        className="my-auto"
                        type="checkbox"
                        title="選 取"
                        checked={c.checked}
                        onChange={c.method}
                      />
                    )}
                  </Form.Check>
                </td>
              )}
              {Object.keys(c)
                .filter(
                  (key) =>
                    key !== 'method' && key !== 'btnText' && key !== 'checked'
                )
                .map((key) =>
                  c[key].method ? (
                    <td
                      key={`${key}${i}${page}`}
                      onClick={c[key].method}
                      aria-hidden="true"
                      className="App-pointer"
                      title={c[key].value}
                    >
                      {c[key].value}
                    </td>
                  ) : (
                    <td
                      key={`${key}${i}${page}`}
                      title={c[key].title || ''}
                      colSpan={c[key].colSpan || 1}
                    >
                      {c[key].value}
                    </td>
                  )
                )}
              {hasButton && (
                <td>
                  <ButtonGroup onClick={c.method}>{c.btnText}</ButtonGroup>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {hasPagination && (
        <Pagination
          className="justify-content-center pagination-card mt-auto py-3"
          // style={{
          //   paddingLeft: 'calc(var(--bs-gutter-x) * .5)',
          // }}
        >
          <Pagination.First
            onClick={() => {
              setpage(Math.max(page - 10, 1))
            }}
            title="前十頁"
            style={{ width: '5rem', fontWeight: 'bolder' }}
          />

          <Pagination.Prev
            onClick={() => {
              if (page > 1) setpage(page - 1)
            }}
            title="上一頁"
            style={{ width: '5rem', fontWeight: 'bolder' }}
          />

          <Pagination.Item
            className="text-center pagination-card-bg flex-grow-1"
            style={{
              userSelect: 'none',
              pointerEvents: 'none',
              fontWeight: '600',
            }}
            active={false}
          >
            {page} ／ {Math.ceil(content.length / pageSize)}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => {
              if (page < content.length / pageSize) setpage(page + 1)
            }}
            title="下一頁"
            style={{ width: '5rem', fontWeight: 'bolder' }}
          />

          <Pagination.Last
            onClick={() => {
              setpage(Math.min(page + 10, Math.ceil(content.length / pageSize)))
            }}
            title="後十頁"
            style={{ width: '5rem', fontWeight: 'bolder' }}
          />
        </Pagination>
      )}
    </div>
  )
}

Datatable.propTypes = {
  setting: PropTypes.shape(),
}

Datatable.defaultProps = {
  setting: {
    hasCheckBox: false,
    hasPagination: true,
    pageSize: 5,
    headers: ['帳 戶 名 稱', '花 費 金 額'],
    content: [
      { name: { value: '泛科知識(業二)' }, amount: { value: '$ 1,330' } },
      { name: { value: '泛科知識(業一)' }, amount: { value: '$ 1,440' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,920' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,440' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,440' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,740' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 125' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,440' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,688' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 2,440' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 5,000' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 4,400' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 1,711' } },
      { name: { value: 'PanMedia' }, amount: { value: '$ 440' } },
    ],
  },
}

export default Datatable
