import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import apiService from '../../services/apiService'

function ReportPreview(props) {
  const { setting } = props
  const {
    api = 'facebook',
    account_id,
    id,
    provider,
    size = {
      width: 540,
      height: 760,
    },
    scale = 0.4,
  } = setting

  const [preview, setpreview] = useState(null)
  useEffect(() => {
    const getPreview = async (adId) => {
      const res = await apiService.data({
        path: `luca/insight/preview?api=${api}&account_id=${account_id}&adId=${adId}&provider=${provider}`,
        method: 'get',
      })
      if (res && !res.error) {
        setpreview(res.datas)
      }
    }
    if (id) getPreview(id)
  }, [id])

  return preview ? (
    <div
      className="overflow-hidden"
      style={{
        width: size.width * scale,
        height: size.height * scale,
      }}
    >
      <iframe
        title="preview"
        src={preview}
        width={size.width}
        height={size.height}
        style={{
          transform: `scale(${scale}) translate(${
            (size.width * scale - size.width) * 1.25
          }px, ${(size.height * scale - size.height) * 1.25}px)`,
        }}
      />
    </div>
  ) : (
    <div />
  )
}

ReportPreview.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ReportPreview
