import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
// import { ToastContext } from './ContextProvider'
// import VirtualAccount from './VirtualAccount'

function InfoCard(props) {
  const { setting } = props
  const {
    title,
    titleEng,
    form,
    handleEdit,
    tip,
    nohover,
    // providers,
    // showPrint,
  } = setting
  const {
    // bankCode,
    // business_id,
    name,
    email,
    password,
    phone,
    englishName,
    cellphone,
    address,
    depart,
    // bankAccount,
  } = form

  // const { settoast } = useContext(ToastContext)

  // const codes = {
  //   '012': '台北富邦（012）',
  // }
  return (
    <div
      className="h-100"
      style={{
        cursor: handleEdit ? 'pointer' : 'not-allowed',
      }}
    >
      <Card
        className={`infoCards-content ${
          !nohover ? 'infoCards-hover-content' : ''
        } h-100 p-3`}
        title={tip}
        onClick={handleEdit}
      >
        {handleEdit && <FontAwesomeIcon icon={faEdit} title="編 輯" />}
        <Card.Body className="h-100">
          <Card.Title>{title}</Card.Title>
          <Card.Text className="text-center">{titleEng}</Card.Text>
          {name && (
            <Card.Text className="text-center fs-5 fw-bloder my-2 lh-lg">
              {name}
            </Card.Text>
          )}
          {englishName && (
            <Card.Text className="lh-lg">英 文 姓 名： {englishName}</Card.Text>
          )}
          {depart && (
            <Card.Text className="lh-lg">所 屬 單 位： {depart}</Card.Text>
          )}
          {email && <Card.Text className="lh-lg">帳 號： {email}</Card.Text>}
          {password && (
            <Card.Text className="lh-lg">密 碼： {password}</Card.Text>
          )}
          {phone && <Card.Text className="lh-lg">電 話： {phone}</Card.Text>}
          {cellphone && (
            <Card.Text className="lh-lg">手 機： {cellphone}</Card.Text>
          )}
          {address && (
            <Card.Text className="lh-lg">地 址： {address}</Card.Text>
          )}
          {form['客戶編號'] && (
            <Card.Text className="lh-lg">
              客 戶 編 號： {form['客戶編號']}
            </Card.Text>
          )}
          {form['客戶公司工商登記中文名稱'] && (
            <Card.Text className="lh-lg">
              公 司 中 文 名 稱：
              {form['客戶公司工商登記中文名稱']}
            </Card.Text>
          )}
          {form['客戶公司工商登記英文名稱'] && (
            <Card.Text className="lh-lg">
              公 司 英 文 名 稱：
              {form['客戶公司工商登記英文名稱']}
            </Card.Text>
          )}
          {form['統一編號'] && (
            <Card.Text className="lh-lg">
              統 一 編 號： {form['統一編號']}
            </Card.Text>
          )}
          {/* {bankAccount && (
            <Card.Text className="lh-lg d-flex">
              虛 擬 帳 號： {codes[bankCode]}
              <VirtualAccount
                setting={{
                  business_id,
                  bankCode,
                }}
              />
              <div className="ms-auto d-flex">
                <FontAwesomeIcon
                  className="ms-auto align-self-center h5 px-3 text-lucaLink4"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText('28012922835116')
                    settoast({ show: true, text: '已 複 製' })
                  }}
                  icon={faCopy}
                  title="複 製 虛 擬 帳 號"
                />
                <FontAwesomeIcon
                  className="ms-auto align-self-center h5 text-lucaLink4"
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={faEye}
                  title="檢 視 完 整 匯 款 指 示 書"
                  // eslint-disable-next-line no-restricted-globals
                  onClick={() => showPrint()}
                />
              </div>
            </Card.Text>
          )} */}
          {/* {providers && (
            <Card.Text className="lh-lg d-flex flex-column justify-content-start">
              <span className="text-start">
                已綁定媒體平台： {providers.length === 0 && '未綁定'}
              </span>
              {providers.length !== 0 && (
                <span className="w-100 text-start App-oneLineEllipsis">
                  {providers.join(', ')}
                </span>
              )}
            </Card.Text>
          )} */}
        </Card.Body>
      </Card>
    </div>
  )
}

InfoCard.propTypes = {
  setting: PropTypes.shape(),
}

InfoCard.defaultProps = {
  setting: {
    title: '',
    name: '',
    email: '',
    phone: '',
    address: '',
  },
}

export default InfoCard
