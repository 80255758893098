import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faExclamationTriangle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { ConfirmForm, LoadingButton } from '../components'
import { lucaLogoGif } from '../assets'

function InfoEditDialog(props) {
  const { setting } = props
  const {
    size,
    show,
    form,
    formData,
    handleClose,
    handleDataChange,
    btnText,
    action,
  } = setting

  const { title, titleEng, content } = form
  const names = {
    password: '新密碼',
    confirmPass: '確認密碼',
  }
  const constrainsts = {
    password: {
      value: formData.content.password !== formData.content.confirmPass,
      message: '請確認二次輸入密碼相同',
    },
    confirmPass: {
      value: formData.content.password !== formData.content.confirmPass,
      message: '請確認二次輸入密碼相同',
    },
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size={size}
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header className="AccFormModal justify-content-center text-center pt-4">
        {title && (
          <Modal.Title>
            <h4>{title}</h4>
            <p>{titleEng}</p>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="d-flex mx-auto">
        <Form className="py-3">
          {content.map((c) => (
            <Form.Group key={c.name} className="mb-1 px-5 lh-md">
              <Form.Label className="my-2">{names[c.name]}</Form.Label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  constrainsts[c.name] && constrainsts[c.name].value ? (
                    <Tooltip
                      className="userTip"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      {constrainsts[c.name].message}
                    </Tooltip>
                  ) : (
                    <Tooltip
                      className="userTip"
                      style={{
                        zIndex: '9999',
                        display: 'none',
                      }}
                    />
                  )
                }
              >
                <Form.Control
                  name={c.name}
                  type={c.type}
                  value={formData.content[c.name]}
                  placeholder={c.placeholder}
                  onChange={handleDataChange}
                  isInvalid={constrainsts[c.name] && constrainsts[c.name].value}
                />
              </OverlayTrigger>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-4">
        <Button variant="secondary" onClick={() => handleClose()}>
          取 消
        </Button>
        <LoadingButton
          variant="luca"
          onClick={() => handleClose(true)}
          disabled={
            action !== 'data' &&
            Object.keys(constrainsts).some((key) => constrainsts[key].value)
          }
          btnText={btnText || '送 出'}
        />
      </Modal.Footer>
    </Modal>
  )
}

function Welcome(props) {
  const { setting } = props
  const { auth, handleLoginShow, handleResetPassword, handleLogOut } = setting
  const location = useLocation()
  const navigate = useNavigate()
  const { token } = useParams()

  const [show, setshow] = useState(false)
  const [form, setform] = useState({ content: [] })
  const [formData, setformData] = useState({ content: { changed: true } })
  const handleDataChange = async (event) => {
    setformData({
      content: {
        ...formData.content,
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleEdit = (keys) => {
    const tempForm = keys
    const content = {}
    tempForm.forEach((c) => {
      content[c.name] = c.value || ''
    })
    setformData({ content })
    setform({
      pageLimit: 1,
      content: tempForm,
    })
    setshow(true)
  }

  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
    hasFooter: true,
    warn: <div />,
  })
  const handleShowWarn = (value) => {
    setshowWarn({
      ...showWarn,
      ...value,
      state: 'confirm',
      show: true,
      shouldReturn: true,
    })
  }

  // timer
  const [timer, settimer] = useState(0)
  useEffect(() => {
    const count = (value) =>
      setTimeout(() => {
        settimer(value)
      }, 100)
    if (timer > 0) count(timer - 0.1)
    if (timer < 0) handleLogOut()
  }, [timer])

  const handleWarnClose = async (value) => {
    if (value) {
      await handleResetPassword(formData.content.password, token)
      settimer(3)
      setshowWarn({
        ...showWarn,
        state: 'done',
        warn: (
          <span className="text-center">
            <div className="d-flex mb-3">
              <FontAwesomeIcon
                className="mx-auto display-1 text-luca"
                icon={faCheckCircle}
              />
            </div>
            <h5>修改完成，請重新登入！</h5>
          </span>
        ),
      })
    } else {
      setshowWarn({
        ...showWarn,
        show: false,
      })
    }
  }
  const handleClose = (value) => {
    setshow(false)
    if (value)
      handleShowWarn({
        hasFooter: true,
        title: '確 定 修 改',
        titleEng: 'Password Reset Confirm',
        warn: (
          <span className="text-center text-lucaDark">
            <div className="d-flex mb-3">
              <FontAwesomeIcon
                className="mx-auto display-1 mb-2 text-luca"
                icon={faExclamationTriangle}
              />
            </div>
            <h5>確定要修改密碼嗎？</h5>
          </span>
        ),
      })
  }

  useEffect(() => {
    if (location.pathname.startsWith('/ResetPassword/')) {
      const keys = ['password', 'confirmPass']
      handleEdit(
        keys.map((key) => ({
          name: key,
          type: 'password',
          value: '',
        }))
      )
    }
  }, [])

  const handleWelcomeClick = () => {
    if (auth.status === 'authed') {
      if (auth.accounts && auth.accounts.length > 0) navigate('/')
      else {
        handleShowWarn(
          {
            title: ' ',
            closeButton: true,
            warn: (
              <div className="text-center">
                <div className="d-flex mb-3">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={faExclamationCircle}
                  />
                </div>
                <h5 className="text-luca fw-bolder pb-2">未加入ADM</h5>
                <h5 className="text-danger">您尚未加入任何企業平台</h5>
                <h5 className="text-danger">請聯繫相關人員申請權限</h5>
              </div>
            ),
            show: true,
            footer: (
              <Button variant="luca" onClick={() => handleWarnClose()}>
                確 定
              </Button>
            ),
          },
          'warn'
        )
      }
    } else if (location.pathname.startsWith('/ResetPassword/')) {
      const keys = ['password', 'confirmPass']
      handleEdit(
        keys.map((key) => ({
          name: key,
          type: 'password',
          value: '',
        }))
      )
    } else handleLoginShow()
  }

  return (
    <Container
      fluid
      className="welcomePage d-flex h-100"
      style={{ cursor: 'pointer' }}
    >
      <div className="m-auto" onClick={handleWelcomeClick} aria-hidden>
        <Row className="py-0">
          <Image id="lucaLogo" src={lucaLogoGif} />
        </Row>
        <Row className="fs-4 fw-light text-white mt-0 pt-0">
          潮&emsp;&emsp;網&emsp;&emsp;全&emsp;&emsp;球&emsp;&emsp;數&emsp;&emsp;位&emsp;&emsp;媒&emsp;&emsp;體&emsp;&emsp;領&emsp;&emsp;航&emsp;&emsp;者
        </Row>
        <Row className="fs-5 fw-light text-white pt-2">
          一&ensp;站&ensp;式&ensp;企&ensp;業&ensp;行&ensp;銷&ensp;資&ensp;源&ensp;整&ensp;合&ensp;平&ensp;台
        </Row>
      </div>
      <InfoEditDialog
        setting={{
          size: 'lg',
          show,
          form: {
            title: '重 設 密 碼',
            titleEng: 'Reset Password',
            size: 'xl',
            pageLimit: form.pageLimit,
            content: form.content,
          },
          formData,
          handleClose,
          handleDataChange,
          btnText: '確 定',
        }}
      />
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'md',
          warning: showWarn.warn,
          handleClose: handleWarnClose,
          timer,
        }}
      />
    </Container>
  )
}

InfoEditDialog.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Welcome.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Welcome
