import React from 'react'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import { Showcase } from '../components'
import { lucaLogoGif } from '../assets'

function Mobile() {
  // const { auth, handleLoginShow } = props
  // const navigate = useNavigate()
  // const handleWelcomeClick = () => {
  //   if (auth.status === 'authed') navigate('/')
  //   else handleLoginShow()
  // }

  return (
    <Container
      fluid
      className="welcomePage d-flex h-100"
      style={{ cursor: 'pointer' }}
    >
      <div className="m-auto">
        <Row className="py-0">
          <Image id="lucaLogo" src={lucaLogoGif} />
        </Row>
        <Row className="mt-1 pb-5">
          <Showcase
            setting={{
              titleEng: `Oops! 此系統目前尚不支援行動裝置，請改用電腦版重新開啟。
(LUCA doesn't support mobile devices, please use the computer or laptop and restart.)`,
            }}
          />
        </Row>
      </div>
    </Container>
  )
}

export default Mobile
