import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FormGroup from 'react-bootstrap/FormGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faCopy } from '@fortawesome/free-solid-svg-icons'
import Datatable from './DataTable'
import InfoCard from './InfoCard'
import { AuthContext, ToastContext } from './ContextProvider'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function BillModal(props) {
  const { setting } = props
  const { show, handleClose, formData } = setting
  const auth = useContext(AuthContext)

  const initForm = {
    status: '',
    type: '',
    currency: '',
    platform: '',
    paidAmount: '',
    amount: '',
    remark: '',
  }
  const [form, setform] = useState(initForm)
  const { type, account, platform, currency, paidAmount, amount } = form
  const names = {
    bank: '銀行代碼',
    account: '繳費帳戶',
    type: '申請類型',
    platform: '媒體平台',
    paidAmount: '已繳金額',
    amount: '待繳金額',
    currency: '幣別',
    remark: '備註',
  }
  const values = {
    bank: '台北富邦銀行 (012)',
    account,
    type,
    platform,
    currency,
    paidAmount: paidAmount || 0,
    amount: amount - (paidAmount || 0),
    remark: '請於3日內至指定虛擬帳戶進行繳費，謝謝。',
  }
  const tips = {
    type: '說明',
  }
  useEffect(() => {
    if (show) setform(formData || initForm)
  }, [show, formData])

  const { settoast } = useContext(ToastContext)

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="lg"
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <h4>儲值單匯款資訊</h4>
          <p>Advance payment bill statement</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column px-4">
        <h1 className="text-luca text-center fw-bold py-3">
          $ {numeral(amount - (paidAmount || 0)).format('0,0')}
        </h1>
        <div className="d-flex w-100 flex-wrap px-3">
          {[
            'bank',
            'account',
            'type',
            'platform',
            'paidAmount',
            'amount',
            'currency',
            'remark',
          ].map((key) => (
            <React.Fragment key={key}>
              <p
                style={
                  key === 'type'
                    ? { display: 'flex' }
                    : {
                        textAlign: 'justify',
                        textAlignLast: 'justify',
                      }
                }
                className="mb-auto w-15 px-2 py-2"
              >
                {names[key]}
                {tips[key] && <Question setting={{ remark: tips[key] }} />}
              </p>
              {key === 'account' ? (
                <FormGroup className="w-35 flex-fill">
                  <InputGroup
                    id="defaultBorder"
                    className="rounded input-group-transparent-addon"
                  >
                    <Form.Control
                      size="sm"
                      className="my-auto text-center"
                      name=""
                      type="text"
                      value={values[key]}
                      readOnly
                    />
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        className="fs-7 btn-lucaIcon"
                        style={{ right: '10', top: '50', bottom: '50' }}
                        title="複 製"
                        icon={faCopy}
                        onClick={() => {
                          navigator.clipboard.writeText(values[key])
                          settoast({ show: true, text: '已 複 製' })
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
              ) : (
                <Form.Control
                  size="sm"
                  className="my-auto w-35 text-center flex-fill"
                  name=""
                  type="text"
                  value={values[key]}
                  readOnly
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="mb-3 mt-3 px-3">
          <div className="border rounded pb-3">
            <InfoCard
              setting={{
                nohover: true,
                form: { ...auth.account, ...auth.account.setting },
                tip: '',
              }}
            />
          </div>
        </div>
        <hr className="hrClass-dashed my-2" />
        <Card>
          <h6 className="text-start ps-3 pt-3 pb-2 text-luca fw-bolder">
            花費明細列表
          </h6>
          <Row className="pb-2 px-4 tableLayout-adj tableW-adj">
            <div className="border rounded py-2">
              <Datatable
                setting={{
                  className: '',
                  hasCheckBox: false,
                  hasButton: false,
                  hasPagination: false,
                  pageSize: 1000,
                  headers: [
                    '媒 體 平 台',
                    '廣 告 帳 戶 名 稱',
                    '出 帳 日 期',
                    '花 費 金 額',
                  ],
                  content: [
                    {
                      platform: 'Meta',
                      adaccount: 'F001_bondee',
                      date: '2023-02-02',
                      amount: 1000,
                    },
                    {
                      platform: 'Google',
                      adaccount: 'F002_concee',
                      date: '2023-02-02',
                      amount: 1500,
                    },
                    {
                      platform: 'Meta',
                      adaccount: 'F003_donbee',
                      date: '2023-02-01',
                      amount: 1000,
                    },
                    {
                      platform: 'Meta',
                      adaccount: 'F004_eonaee',
                      date: '2023-02-02',
                      amount: 1200,
                    },
                  ].map((m) =>
                    m.fill
                      ? {
                          platform: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          adaccount: { value: '- -' },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">- -</span>
                              </div>
                            ),
                          },
                          amount: { value: '- -' },
                        }
                      : {
                          platform: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">{m.platform}</span>
                              </div>
                            ),
                          },
                          adaccount: { value: m.adaccount },
                          date: {
                            value: (
                              <div className="formsTool w-75 d-flex mx-auto">
                                <span className="m-auto">{m.date}</span>
                              </div>
                            ),
                          },
                          amount: { value: m.amount },
                        }
                  ),
                }}
              />
            </div>
          </Row>
        </Card>
      </Modal.Body>

      <Modal.Footer className="sendForm justify-content-center py-4">
        <Button
          variant="secondary"
          onClick={() =>
            handleClose({
              ...formData,
              action: 'reject',
            })
          }
        >
          取 消
        </Button>
        <Button
          variant="luca"
          onClick={() =>
            handleClose({
              ...formData,
              action: 'approve',
            })
          }
        >
          繳 費
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

BillModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Question.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BillModal
