import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForward } from '@fortawesome/free-solid-svg-icons'

const Blocks = forwardRef((props, ref) => {
  const { setting } = props
  const {
    hasCheckBox,
    active,
    method,
    name,
    effective_status,
    whiteRef,
    handleCheck,
    checked,
  } = setting
  return (
    <div
      className={`AdsBlocks w-95 ms-2 me-0 py-2 px-4 fs-6 fw-mid mb-2 ${
        active ? 'bg-luca2 text-light' : 'text-lucaDark'
      }`}
      style={{ zIndex: '120' }}
      ref={whiteRef || ref}
    >
      <Row>
        <Form.Check
          type="checkbox"
          className="text-start align-content-center my-1 d-flex"
        >
          {hasCheckBox && (
            <Form.Check.Input
              type="checkbox"
              checked={checked}
              onChange={handleCheck}
            />
          )}
          <Form.Check.Label
            style={{ cursor: 'pointer' }}
            className="w-100 ms-1 App-oneLineEllipsis"
            onClick={method}
            title={name}
          >
            {name}
          </Form.Check.Label>
          {effective_status && effective_status.includes('ACTIVE') && (
            <FontAwesomeIcon
              className="me-2 my-auto"
              style={{
                opacity: '.8',
              }}
              icon={faForward}
            />
          )}
        </Form.Check>
      </Row>
    </div>
  )
})

Blocks.propTypes = {
  setting: PropTypes.shape(),
}

Blocks.defaultProps = {
  setting: {
    hasCheckBox: true,
    name: 'name',
  },
}

export default Blocks
