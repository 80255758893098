import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Modal,
  Row,
  Col,
  Dropdown,
  InputGroup,
  Form,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleInfo,
  faExclamationTriangle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import apiService from '../../services/apiService'
import { getOriginIcon } from '../../services/lucaFunctions'
import SearchBar from '../SearchBar'
import LoadingButton from '../LoadingButton'

const eventNames = {
  ad_account_add_user_to_role: '新增人員到帳號',
  update_campaign_budget: '更新廣告活動預算',
  ad_account_billing_charge: '出帳',
  update_campaign_run_status: '更新廣告活動狀態',
  update_ad_run_status: '更新廣告狀態',
  update_ad_set_name: '更新廣告組合名稱',
  update_campaign_name: '更新廣告活動名稱',
  create_ad: '創建廣告',
  update_ad_set_target_spec: '更新廣告組合目標',
  update_ad_set_optimization_goal: '更新廣告組合最佳化目標',
  update_ad_set_bid_strategy: '更新廣告組合出價策略',
  create_ad_set: '創建廣告組合',
  create_campaign_legacy: '創建廣告活動',
  create_campaign_group: '創建廣告活動',
  ad_account_update_spend_limit: '更新花費上限',
  update_ad_run_status_to_be_set_after_review: '在廣告審查後更新廣告狀態',
  update_ad_set_run_status: '更新廣告組合狀態',
  update_ad_set_duration: '更新廣告組合排程',
  edit_images: '編輯媒體庫中的圖片',
  add_images: '新增圖片到媒體庫',
  create_audience: '創建自訂受眾',
  update_ad_set_budget: '更新廣告組合預算',
  update_ad_friendly_name: '更新廣告名稱',
  update_ad_creative: '更新廣告',
  ad_account_remove_user_from_role: '移除人員',
}

const dataNames = {
  type: '預算形式',
  'Per Day': '日預算',
  'LifeTime Budget': '總預算',
  new_value: '新預算',
  old_value: '原預算',
  currency: '幣值',
}

function Detail({ setting }) {
  const { detail, setactivities } = setting
  const {
    show = false,
    actor_name = '',
    account_name = '',
    provider,
    // object_id = '',
    object_name = '',
    // event_time = '',
    event_type = '',
    extra_data = {},
    budget_data = {},
    subject = '',
    sus = '',
    solution = '',
  } = detail
  console.log(solution)

  const [value, setvalue] = useState(solution)
  useEffect(() => setvalue(solution), [solution])

  const handleClose = async (action) => {
    if (action) {
      const res = await apiService.data({
        path: `luca/insight/activities`,
        method: 'put',
        data: {
          detail,
          value,
        },
      })
      console.log(res)
      setactivities((prevState) => ({
        ...prevState,
        [provider === 'meta' ? 'facebook' : provider]: prevState[
          provider === 'meta' ? 'facebook' : provider
        ].map((ps) => {
          const match = Object.keys(ps)
            .filter((key) => res[key])
            .every((key) => {
              console.log(ps[key])
              console.log(res[key])
              return typeof ps[key] === 'string' ? ps[key] === res[key] : true
            })
          return match ? { ...ps, ...res } : ps
        }),
      }))
    }
    setting.handleClose()
  }

  const getSusComponent = () => {
    switch (sus) {
      case 0:
        return <div />
      case 1:
        return (
          <>
            <h5 className="pb-3">警報狀況： 發信</h5>
            <div className="fs-7">
              <Row className="px-3 py-2">狀態： {value || '未處理'}</Row>
              <Row className="px-3 py-2">
                <Form.Switch
                  checked={value === '禁止投放'}
                  onChange={() =>
                    setvalue(value === '禁止投放' ? '' : '禁止投放')
                  }
                  label="禁止投放"
                />
              </Row>
              <Row className="px-3 py-2">
                <Form.Switch
                  checked={
                    value === '已確認，停止觸發警報' || value === '禁止投放'
                  }
                  onChange={() =>
                    setvalue(
                      value === '已確認，停止觸發警報'
                        ? ''
                        : '已確認，停止觸發警報'
                    )
                  }
                  disabled={value === '禁止投放'}
                  label="已確認，停止觸發警報"
                />
              </Row>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <h5 className="pb-3">警報狀況： 緊急停止</h5>
            <Row className="px-3 py-2">狀態： {value || '未處理'}</Row>
            <Row className="px-3 py-2">
              <Form.Switch
                checked={value === '允許投放'}
                onChange={() =>
                  setvalue(value === '允許投放' ? '' : '允許投放')
                }
                label="允許投放"
              />
            </Row>
            <Row className="px-3 py-2">
              <Form.Switch
                checked={
                  value === '已確認，停止觸發警報' || value === '允許投放'
                }
                onChange={() =>
                  setvalue(
                    value === '已確認，停止觸發警報'
                      ? ''
                      : '已確認，停止觸發警報'
                  )
                }
                disabled={value === '允許投放'}
                label="已確認，停止觸發警報"
              />
            </Row>
          </>
        )
      default:
        return <div />
    }
  }

  return (
    <Modal
      style={{
        zIndex: '100000',
      }}
      size="xl"
      show={show}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton>
        <h4 className="text-lucaDark">活動紀錄詳情</h4>
      </Modal.Header>
      <Modal.Body
        style={{
          minHeight: '50vh',
        }}
      >
        <Row>
          <Col xs={4} className="border-end text-lucaDark fs-7">
            <h5 className="pb-3">事件詳細</h5>
            <Row className="px-3 py-2">事件名稱：{eventNames[event_type]}</Row>
            <Row className="px-3 py-2">操作人員：{actor_name}</Row>
            <Row className="px-3 py-2">廣告帳號：{account_name}</Row>
            <Row className="px-3 py-2">對象物件：{object_name}</Row>
          </Col>
          <Col xs={4} className="border-end text-lucaDark fs-7">
            <h5 className="pb-3">事件內容</h5>
            {budget_data
              ? Object.keys(budget_data).map((key) => (
                  <Row className="px-3 py-2">
                    {dataNames[key] || key}：
                    {dataNames[budget_data[key]] || budget_data[key]}
                  </Row>
                ))
              : Object.keys(JSON.parse(extra_data)).map((key) => (
                  <Row className="px-3 py-2">
                    {key} {JSON.stringify(JSON.parse(extra_data)[key])}
                  </Row>
                ))}
          </Col>
          <Col xs={4} className="text-lucaDark">
            <h5 className="pb-3">警報內容：</h5>
            {subject && <p className="pb-3">{subject}</p>}
            {getSusComponent()}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={() => handleClose()} variant="secondary">
          返 回
        </Button>
        <LoadingButton onClick={() => handleClose(true)} variant="luca">
          確 定
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

function Select({ setting }) {
  const { title, items, selected, handleSelect } = setting

  const [search, setsearch] = useState('')

  const filteredItems = useMemo(
    () => items.filter(({ label }) => !search || label.includes(search)),
    [items, search]
  )

  return (
    <Dropdown className="my-auto">
      <Dropdown.Toggle
        className="fs-7"
        id="dropdown-basic"
        style={{
          backgroundColor: 'transparent',
          color: '#317985',
          borderColor: '#317985',
        }}
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="px-3"
        style={{
          width: '700px',
        }}
      >
        <div className="mb-2">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="my-2 w-96 border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="搜 尋..."
              aria-label="搜 尋..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋"
              value={search}
              onChange={(event) => setsearch(event.target.value)}
            />
          </InputGroup>
          <div className="d-flex w-100">
            <ListGroup
              id="scrollTarget"
              className="selectGroup scrollbarShow w-50"
              style={{ maxHeight: '22rem', overflowY: 'auto' }}
            >
              <span>已選擇</span>
              {filteredItems
                .filter((item) => selected.includes(item.value))
                .map((item) => (
                  <ListGroupItem
                    className="d-flex"
                    style={{
                      minHeight: '2.5rem',
                      width: '20rem',
                      maxWidth: '20rem',
                    }}
                    key={item.value}
                    onClick={() => handleSelect(item.value)}
                    active
                  >
                    {item.provider && (
                      <span className="my-auto me-2">
                        {getOriginIcon(item.provider, '22px', 'auto')}
                      </span>
                    )}
                    <span className="App-oneLineEllipsis">{item.label}</span>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <ListGroup
              id="scrollTarget"
              className="selectGroup scrollbarShow w-50"
              style={{ maxHeight: '22rem', overflowY: 'auto' }}
            >
              <span>未選擇</span>
              {filteredItems
                .filter((item) => !selected.includes(item.value))
                .map((item) => (
                  <ListGroupItem
                    className="d-flex"
                    style={{
                      minHeight: '2.5rem',
                      width: '20rem',
                      maxWidth: '20rem',
                    }}
                    key={item.value}
                    onClick={() => handleSelect(item.value)}
                  >
                    {item.provider && (
                      <span className="my-auto me-2">
                        {getOriginIcon(item.provider, '22px', 'auto')}
                      </span>
                    )}
                    <span className="App-oneLineEllipsis">{item.label}</span>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

function MediaRecords({ setting }) {
  const { show, setshowRecords, handleClose } = setting
  //   const [loaded, setloaded] = useState(20)
  const [modal, setmodal] = useState({})

  const [activities, setactivities] = useState({})
  useEffect(() => {
    const getActivities = async () => {
      const res = await apiService.data({
        path: `luca/insight/activities`,
        method: 'get',
      })
      if (!res.error) setactivities(res)
    }
    getActivities()
  }, [])

  const filters = useMemo(() => {
    const list = Object.keys(activities).reduce(
      (prev, cur) => [...prev, ...activities[cur]],
      []
    )
    const actors = Array.from(
      new Set(list.map(({ actor_name }) => actor_name))
    ).map((actor_name) => ({
      label: actor_name,
      value: actor_name,
    }))
    const accounts = []
    list.forEach(({ account_name, account_id, provider }) => {
      if (accounts.some(({ value }) => value === account_id)) return
      accounts.push({
        label: account_name,
        value: account_id,
        provider,
      })
    })
    const events = Array.from(
      new Set(list.map(({ event_type }) => event_type))
    ).map((event_type) => ({
      label: eventNames[event_type] || event_type,
      value: event_type,
    }))
    return {
      actors,
      accounts,
      events,
    }
  }, [activities])
  const [selected, setselected] = useState({
    actors: [],
    accounts: [],
    events: [],
  })
  const handleSelect = (name, value) => {
    setselected((prevState) =>
      prevState[name].includes(value)
        ? { ...prevState, [name]: prevState[name].filter((p) => p !== value) }
        : { ...prevState, [name]: [...prevState[name], value] }
    )
  }

  const [tempSearch, settempSearch] = useState('')
  const [search, setsearch] = useState('')

  const filteredActivities = useMemo(
    () =>
      Object.keys(activities)
        .reduce((prev, cur) => [...prev, ...activities[cur]], [])
        .filter(
          ({ actor_name, account_id, account_name, event_type }) =>
            (!selected.actors.length || selected.actors.includes(actor_name)) &&
            (!selected.accounts.length ||
              selected.accounts.includes(account_id)) &&
            (!selected.events.length || selected.events.includes(event_type)) &&
            (!search ||
              actor_name.includes(search) ||
              account_name.includes(search) ||
              eventNames[event_type].includes(search))
        )
        .sort((a, b) =>
          moment(b.event_time).isAfter(moment(a.event_time)) ? 1 : -1
        ),
    [activities, selected]
  )

  const names = {
    actors: '操作人員',
    accounts: '廣告帳號',
    events: '事件',
  }

  const [loaded, setloaded] = useState(100)

  const suss = {
    0: <div />,
    1: <FontAwesomeIcon className="pe-1" icon={faExclamationTriangle} />,
    2: <FontAwesomeIcon className="pe-1" icon={faTimesCircle} />,
  }

  const colors = {
    0: '',
    1: 'text-orange',
    2: 'text-danger',
  }

  const [showSus, setshowSus] = useState(true)
  console.log(setshowSus)
  console.log(filteredActivities.filter((f) => !showSus || f.sus))

  return (
    <>
      <Modal
        style={{
          zIndex: '99999',
        }}
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 className="text-lucaDark">活動紀錄</h4>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <Row className="d-flex w-100 px-0 py-1">
            <Form.Check
              className="ms-auto w-20"
              label="只顯示含警報的活動"
              checked={showSus}
              onChange={() => setshowSus(!showSus)}
            />
          </Row>
          <Row className="justify-content-end py-2 border-bottom">
            {['actors', 'accounts', 'events'].map((type) => (
              <Col xs={2} key={type} className="pe-1">
                <Select
                  setting={{
                    title: names[type],
                    items: filters[type],
                    selected: selected[type],
                    handleSelect: (value) => handleSelect(type, value),
                  }}
                />
              </Col>
            ))}
            <Col xs={6}>
              <SearchBar
                setting={{
                  title: '請輸入搜尋關鍵字...',
                  name: 'search',
                  id: 'defaultBorder',
                  // action: <FontAwesomeIcon icon={faSearch} />,
                  tempSearch,
                  settempSearch,
                  method: () => setsearch(tempSearch),
                }}
              />
            </Col>
          </Row>
          <Row className="border-bottom p-2">
            <Col xs={1}>操作人員</Col>
            <Col xs={3}>廣告帳號</Col>
            <Col xs={3}>對象物件</Col>
            <Col xs={2}>時間</Col>
            <Col xs={3}>
              事件內容
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="quesTip"
                    style={{
                      zIndex: '99999',
                    }}
                  >
                    點擊事件本身即可展開查看完整內容＆處理該事件。
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="ps-1 text-lucaDark"
                  style={{ cursor: 'help' }}
                />
              </OverlayTrigger>
            </Col>
          </Row>
          <div
            id="scrollTarget"
            className="w-100 overflow-scroll"
            style={{
              height: '500px',
            }}
          >
            <InfiniteScroll
              dataLength={loaded}
              next={() => setloaded(loaded + 20)}
              hasMore={loaded < filteredActivities.length}
              loader={<h4>Loading...</h4>}
              endMessage={<p />}
              scrollableTarget="scrollTarget"
            >
              {filteredActivities
                .filter((f) => !showSus || f.sus)
                .slice(0, loaded)
                .map(
                  (
                    {
                      actor_name,
                      account_name,
                      object_name,
                      event_time,
                      event_type,
                      sus,
                      solution,
                      ...e
                    },
                    i
                  ) => (
                    <Row className="border-bottom p-2" key={i}>
                      <Col xs={1} className="App-oneLineEllipsis">
                        {actor_name}
                      </Col>
                      <Col xs={3} className="App-oneLineEllipsis">
                        {account_name}
                      </Col>
                      <Col
                        xs={3}
                        title={object_name}
                        className="App-oneLineEllipsis"
                      >
                        {object_name}
                      </Col>
                      <Col xs={2} className="App-oneLineEllipsis">
                        {moment(event_time).format('yyyy-MM-DD HH:mm')}
                      </Col>
                      <Col
                        xs={3}
                        className={`App-oneLineEllipsis ${colors[sus]}`}
                        title="點擊查看事件完整內容"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setshowRecords(false)
                          setmodal({
                            show: true,
                            actor_name,
                            account_name,
                            object_name,
                            event_time,
                            event_type,
                            sus,
                            solution,
                            ...e,
                          })
                        }}
                      >
                        {suss[sus]}
                        {solution}
                        {eventNames[event_type] || event_type}
                      </Col>
                    </Row>
                  )
                )}
            </InfiniteScroll>
          </div>
        </Modal.Body>
      </Modal>
      <Detail
        setting={{
          handleClose: () => {
            setshowRecords(true)
            setmodal({
              show: false,
            })
          },
          setactivities,
          detail: modal,
        }}
      />
    </>
  )
}

Detail.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Select.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MediaRecords.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MediaRecords
