import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import ReportFloatButton from './ReportFloatButton'

function ReportBlock(props) {
  const { setting, children } = props
  const { content } = setting
  const [open, setopen] = useState(true)

  return (
    <Card className="p-3">
      <ReportFloatButton
        setting={{
          icon: open ? faMinusCircle : faPlusCircle,
          title: '建立新組合',
          onClick: () => {
            setopen(!open)
          },
          style: {
            width: '35px',
            height: '100%',
            top: '0px',
            left: '-35px',
          },
        }}
      />
      {open ? children : content}
    </Card>
  )
}

ReportBlock.propTypes = {
  setting: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
}

export default ReportBlock
